import { DateRangePicker } from 'front-library';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CheckpointIcon from 'front-library/build/img/icon-checkpoint-orange.svg';

import { Autocomplete } from '../../../../molecules/Autocomplete/Autocomplete';
import { GET_SITES_WITHOUT_OFFSITE } from '../../../../molecules/Autocomplete/graphql';
import {
  checkpointDisabledTime,
  isInvalidArrivalDate,
  isInvalidDepartureDate,
} from '../utils';
import {
  CheckpointIconContainer,
  FlexTitleRemove,
  Icon,
  ItemContainer,
} from './styled-components';

const Checkpoint = ({
  index,
  site,
  checkpointDepartureDate,
  checkpointArrivalDate,
  minDate,
  onChange,
  onRemove,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <ItemContainer>
      <CheckpointIconContainer>
        <Icon src={CheckpointIcon} />
        <FlexTitleRemove onClick={() => onRemove()}>
          {`${t('shippingOrders:modal.steps.checkpoint')} ${index + 1}`}
        </FlexTitleRemove>
      </CheckpointIconContainer>

      {/* Site */}
      <Autocomplete
        label={t('shippingOrders:modal.steps.site')}
        onChange={(data) =>
          onChange({
            site: data,
            checkpointDepartureDate,
            checkpointArrivalDate,
          })
        }
        query={GET_SITES_WITHOUT_OFFSITE}
        required={true}
        value={site}
      />

      {/* Dates */}
      <DateRangePicker
        locale={i18n.language}
        label={t('shippingOrders:modal.steps.arrivalDate')}
        required={true}
        className="DateChoiceBasic"
        placeholder={[
          t('shippingOrders:panelSections.route.arrival'),
          t('shippingOrders:panelSections.route.departure'),
        ]}
        defaultValue={[checkpointArrivalDate, checkpointDepartureDate]}
        showTime={true}
        disabledDate={(current, { from }) =>
          current && from
            ? isInvalidDepartureDate(current, from)
            : isInvalidArrivalDate(current, minDate)
        }
        disabledTime={(current, type, { from }) =>
          checkpointDisabledTime(current, type, from, minDate)
        }
        onOk={([arrivalDate, departureDate]) =>
          onChange({
            site,
            checkpointDepartureDate: departureDate,
            checkpointArrivalDate: arrivalDate,
          })
        }
      />
    </ItemContainer>
  );
};

Checkpoint.propTypes = {
  index: PropTypes.number.isRequired,
  site: PropTypes.object,
  checkpointDepartureDate: PropTypes.object,
  checkpointArrivalDate: PropTypes.object,
  minDate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export { Checkpoint };
