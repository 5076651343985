export const roleEnum = {
  assetManagement: 'asset_management',
  superAdmin: 'superAdmin',
  administrator: 'Administrator',
  operator: 'Operator',
  observer: 'Observer',
  apiAdmin: 'ApiAdmin',
  shippingOrderOption: 'shipping_order_option',
  shippingOrderAnalyticOption: 'shipping_order_analytic_option',
  cycleAnalyticOption: 'cycle_analytic_option',
  tableauExplorer: 'tableau_explorer',
  sms: 'sms',
};

export const getRoleFieldOptions = (t) => [
  { title: t('menu:administrator'), value: roleEnum.administrator },
  { title: t('menu:operator'), value: roleEnum.operator },
  { title: t('menu:observer'), value: roleEnum.observer },
];

export const getTranslationForRole = (t, role) => {
  return {
    [roleEnum.administrator]: t('menu:administrator'),
    [roleEnum.operator]: t('menu:operator'),
    [roleEnum.observer]: t('menu:observer'),
  }[role];
};

export const findMainRole = (roles) => {
  const rolesMapByName = roles.reduce((map, role) => {
    map[role.name] = role;

    return map;
  }, {});

  // Order matter
  const displayedRolesPerMainRole = [
    [roleEnum.superAdmin, roleEnum.administrator],
    [roleEnum.administrator, roleEnum.administrator],
    [roleEnum.operator, roleEnum.operator],
    [roleEnum.observer, roleEnum.observer],
  ];

  const fallback = { name: roleEnum.observer };
  for (const [mainRole, displayedRole] of displayedRolesPerMainRole) {
    if (!rolesMapByName?.[mainRole]) {
      continue;
    }

    return rolesMapByName?.[displayedRole] ?? fallback;
  }

  return fallback;
};

/**
 * List of form fields and options shown to super admin user only.
 */
export const fieldDisplayedForSuperAdmin = {
  options: {
    is_sms_enabled: true,
  },
};

/**
 * List of form fields and options shown to the user by role.
 */
export const fieldDisplayedForRole = {
  [roleEnum.administrator]: {
    password: true,
    homepage: true,
    api_token: true,
    options: {
      enable_shipping_orders: true,
      enable_api: true,
      enable_cycle_analytic: true,
    },
  },
  [roleEnum.operator]: {
    password: true,
    homepage: true,
    api_token: false,
    options: {
      enable_shipping_orders: true,
      enable_api: false,
      enable_cycle_analytic: false,
    },
  },
  [roleEnum.observer]: {
    password: false,
    homepage: false,
    api_token: false,
    options: {
      enable_shipping_orders: true,
      enable_api: false,
      enable_cycle_analytic: false,
    },
  },
};

export const defaultObserverPassword = '7=4A9password';
export const defaultFormValuesByRole = {
  [roleEnum.administrator]: {
    password: '',
    passwordConfirmation: '',
    page: '',
  },
  [roleEnum.operator]: {
    password: '',
    passwordConfirmation: '',
    page: '',
  },
  [roleEnum.observer]: {
    password: defaultObserverPassword, // Dummy password, the backend override it
    passwordConfirmation: defaultObserverPassword, // Dummy password, the backend override it
    page: 'map',
  },
};

export const companyOptionToRole = {
  enable_shipping_orders: roleEnum.shippingOrderOption,
  enable_shipping_orders_analytic: roleEnum.shippingOrderAnalyticOption,
  enable_tableau: roleEnum.tableauExplorer,
  enable_api: roleEnum.apiAdmin,
  enable_cycle_analytic: roleEnum.cycleAnalyticOption,
  is_sms_enabled: roleEnum.sms,
};

export const companyOptionTranslation = {
  enable_shipping_orders: 'user:form.options.shippingOrder',
  enable_shipping_orders_analytic: 'user:form.options.shippingOrderAnalytic',
  enable_tableau: 'user:form.options.tableauExplorer',
  enable_api: 'user:form.options.api',
  enable_cycle_analytic: 'user:form.options.cycleAnalytic',
  is_sms_enabled: 'user:form.options.sms',
};

/**
 *
 * @param {string} selectedRoleName The currently selected role on the form
 * @param {string} fieldName The field we want to display
 *
 * @returns {boolean}
 */
export function canSeeField(selectedRoleName, fieldName) {
  return fieldDisplayedForRole[selectedRoleName]?.[fieldName];
}

/**
 *
 * @param {object} currentUser The currently logged-in user
 * @param {string} optionName The option we want to display
 *
 * @returns {boolean}
 */
export function canSeeSuperAdminOption(currentUser, optionName) {
  // For super admin only options (selected role name do not matter)
  if (
    !currentUser.rolesName.includes('superAdmin') ||
    !currentUser.company[optionName]
  ) {
    return false;
  }

  return !!fieldDisplayedForSuperAdmin.options?.[optionName];
}

/**
 *
 * @param {string} selectedRoleName The currently selected role on the form
 * @param {string} optionName The option we want to display
 *
 * @returns {boolean}
 */
export function canSeeOption(selectedRoleName, optionName) {
  const displayableOptions = fieldDisplayedForRole[selectedRoleName].options;

  return displayableOptions?.[optionName];
}

/**
 *
 * @param {string} roleName To search
 * @param {string[]} roles A list of roleNames to check
 *
 * @returns {boolean}
 */
export function hasRole(roleName, roles) {
  return roles.find((role) => role === roleName) !== undefined;
}

export function toggleFormRole(formData, optionName) {
  if (formData.roles.find((role) => optionName === role) !== undefined) {
    return {
      ...formData,
      roles: formData.roles.filter((role) => optionName !== role),
    };
  }

  return { ...formData, roles: [...formData.roles, optionName] };
}
