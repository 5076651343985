import { gql } from '@apollo/client';

const EXPORT_SHIPPING_ORDERS = gql`
    mutation ExportShippingOrders(
        $user_id: Int!,
        $type: ExportType!,
        $formats: FormatsInput!,
        $filters: Mixed
    ) {
      exportData(
        input: {
          user_id: $user_id
          type: $type
          formats: $formats
          filters: $filters
        }
      )
    }
`;

export { EXPORT_SHIPPING_ORDERS };
