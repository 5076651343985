import { LinearProgress } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Button, DatePicker, TextField } from 'front-library';

import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { GET_API_USER, GET_SITES } from './graphql';
import {
  ActionsContainer,
  FormContainer,
  InfosContainer,
  SelectedSite,
  Site,
  SitesContainer,
  SitesList,
} from './styled-components';

import { useTranslation } from 'react-i18next';
import { generateUniqKey } from '../../../helpers';
import { Autocomplete } from '../../molecules/Autocomplete/Autocomplete';
import { GET_MAGS } from '../../molecules/Autocomplete/graphql';
import { array_move } from './utils';

const MagDatasSender = () => {
  const { t, i18n } = useTranslation();
  const { data: sites } = useQueryGraphQl(GET_SITES);
  const { data: apiUser } = useQueryGraphQl(GET_API_USER);

  const [data, setData] = useState({
    mag: null,
    firstTimestamp: dayjs().subtract(1, 'month'),
    timestampIncrement: '',
    networkRecordIdPrefix: generateUniqKey(),
    accuracy: 0,
    temperature: '',
    selectedSite: null,
    selectedAddedSite: null,
    uniqKey: 1,
    selectedSites: [],
    isSendingMagDatas: false,
    sentMagDatas: 0,
  });

  const onSiteSelect = (site) =>
    setData({
      ...data,
      selectedSite: site,
      selectedAddedSite: null,
    });

  const onAddedSiteSelect = (site) =>
    setData({
      ...data,
      selectedSite: null,
      selectedAddedSite: site,
    });

  const onMoveUp = () => {
    const index = data.selectedSites.findIndex(
      (s) => s.key === data.selectedAddedSite.key,
    );

    if (0 === index) {
      return;
    }

    setData({
      ...data,
      selectedSites: [...array_move(data.selectedSites, index, index - 1)],
    });
  };

  const onMoveDown = () => {
    const index = data.selectedSites.findIndex(
      (s) => s.key === data.selectedAddedSite.key,
    );

    if (data.selectedSites.length - 1 === index) {
      return;
    }

    setData({
      ...data,
      selectedSites: [...array_move(data.selectedSites, index, index + 1)],
    });
  };

  const addSite = () =>
    setData({
      ...data,
      selectedSites: [
        ...data.selectedSites,
        { key: data.uniqKey, site: data.selectedSite },
      ],
      uniqKey: data.uniqKey + 1,
    });

  const removeSiteFromSelected = () =>
    setData({
      ...data,
      selectedSites: data.selectedSites.filter(
        (s) => s.key !== data.selectedAddedSite.key,
      ),
      selectedAddedSite: null,
    });

  const onRun = async () => {
    setData({ ...data, isSendingMagDatas: true });

    let timestamp = dayjs(data.firstTimestamp);

    for (const index in data.selectedSites) {
      const site = data.selectedSites[index];

      const response = await runRequest(
        data.mag.title,
        timestamp,
        `${data.networkRecordIdPrefix}_${index}`,
        site.site.latitude || 47.386240019512876,
        site.site.longitude || 2.2900857719162175,
      );

      if (200 !== response.status) {
        toast.error(
          `An error occured while sending mag datas: ${response.statusText}`,
        );
        setData({ ...data, isSendingMagDatas: false });
        break;
      }

      setData({ ...data, sentMagDatas: data.sentMagDatas + 1 });
      timestamp = timestamp.add(data.timestampIncrement, 'minutes');
      await new Promise((r) => setTimeout(r, 2000));
    }

    setData({
      ...data,
      isSendingMagDatas: false,
      sentMagDatas: 0,
    });

    toast.success('Mag datas were all sent');
  };

  const runRequest = async (
    magId,
    timestamp,
    networkRecordId,
    latitude,
    longitude,
  ) => {
    return axios.post(
      `${window.location.origin}/api/devicedata`,
      {
        device_id: magId,
        device_timestamp: timestamp.format('YYYY-MM-DD HH:mm:ss'),
        network_record_id: networkRecordId,
        latitude,
        longitude,
        accuracy: data.accuracy,
        temperature:
          null !== data.temperature && '' !== data.temperature
            ? +data.temperature
            : null,
        shock: 0,
        snr: 9,
        rssi: -137,
        seqNumber: 90010,
        json: {
          fake: 'fake',
        },
        geolocation_source: 'GPS',
      },
      {
        headers: {
          Authorization: `Bearer ${apiUser?.apiUser?.api_token}`,
        },
      },
    );
  };

  return (
    <div>
      <h1>{t('menu:magDataGenerator')}</h1>
      <InfosContainer>
        <FormContainer>
          <Autocomplete
            label="MAG"
            onChange={(mag) => setData({ ...data, mag })}
            query={GET_MAGS}
            value={data.mag}
          />
          <TextField
            label="Network record ID prefix"
            onChange={(value) =>
              setData({ ...data, networkRecordIdPrefix: value })
            }
            required={true}
            value={data.networkRecordIdPrefix}
          />

          <TextField
            label="Accuracy"
            onChange={(value) => setData({ ...data, accuracy: value })}
            required={true}
            value={data.accuracy}
          />

          <TextField
            label="Temperature"
            onChange={(value) => setData({ ...data, temperature: value })}
            required={true}
            type="number"
            value={data.temperature}
          />

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              locale={i18n.language}
              onChange={(value) => setData({ ...data, firstTimestamp: value })}
              defaultValue={data.firstTimestamp}
              label="First timestamp"
              showTime={true}
            />
          </LocalizationProvider>

          <TextField
            label="Timestamp increment (mn)"
            onChange={(value) =>
              setData({ ...data, timestampIncrement: value })
            }
            required={true}
            type="number"
            value={data.timestampIncrement}
          />
        </FormContainer>
        <div>
          <Button
            text="Run"
            onClick={onRun}
            disabled={0 === data.selectedSites.length}
          />
        </div>
        <div>
          {data.isSendingMagDatas && (
            <>
              <p>Sending mag datas ...</p>
              <LinearProgress
                variant="determinate"
                value={(data.sentMagDatas * 100) / data.selectedSites.length}
              />
            </>
          )}
        </div>
      </InfosContainer>

      <SitesContainer>
        <SitesList>
          {sites?.sites?.data?.map((s) => {
            if (s.id === data.selectedSite?.id) {
              return <SelectedSite key={s.id}>{s.name}</SelectedSite>;
            }

            return (
              <Site key={s.id} onClick={() => onSiteSelect(s)}>
                {s.name}
              </Site>
            );
          })}
        </SitesList>
        <ActionsContainer>
          <Button
            disabled={!data.selectedSite || data.isSendingMagDatas}
            onClick={() => addSite()}
            text="Add"
          />
          <br />
          <Button
            disabled={!data.selectedAddedSite || data.isSendingMagDatas}
            onClick={() => removeSiteFromSelected()}
            text="Delete"
          />
          <br />
          <Button
            disabled={!data.selectedAddedSite || data.isSendingMagDatas}
            text="UP"
            onClick={onMoveUp}
          />
          <br />
          <Button
            disabled={!data.selectedAddedSite || data.isSendingMagDatas}
            text="DOWN"
            onClick={onMoveDown}
          />
          <br />
          <Button
            disabled={0 === data.selectedSites.length}
            text="Clear"
            onClick={() =>
              setData({
                ...data,
                selectedSites: [],
              })
            }
          />
        </ActionsContainer>
        <SitesList>
          {data.selectedSites.map((s) => {
            if (s.key === data.selectedAddedSite?.key) {
              return <SelectedSite key={s.key}>{s.site.name}</SelectedSite>;
            }

            return (
              <Site key={s.key} onClick={() => onAddedSiteSelect(s)}>
                {s.site.name}
              </Site>
            );
          })}
        </SitesList>
      </SitesContainer>
    </div>
  );
};

export { MagDatasSender };
