export function isProduction() {
  const supportedDomains = ['app.magmatechnology.io', 'm4g.io'];

  return supportedDomains.includes(window.location.hostname);
}

export function isStaging() {
  const supportedDomains = ['staging.magmatechnology.io', 'staging.m4g.io'];

  return supportedDomains.includes(window.location.hostname);
}

export function isLocal() {
  return !isStaging() && !isProduction();
}

export function getEnvironment() {
  if (isProduction()) {
    return 'production';
  }

  if (isStaging()) {
    return 'staging';
  }

  return 'development';
}
