import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// styledComponent
const Div = styled.div`
  display: flex;
  justify-content: center;
`;

const Select = styled.select`
  color: white;
  option{color: black};
`;

//graphql
const COMPANIES = gql`
{
  companies(
    first: 10000, 
    orderBy: { column: NAME, order: ASC}
  ) {
    data {
      id
      name
  }
  }
}
`;

//component
const CompanySwitch = ({ user }) => {
  const { data, loading, error } = useQuery(COMPANIES);

  if (loading) {
    return <Div>loading</Div>;
  }

  if (error) {
    return <Div>error</Div>;
  }

  let form = null;

  const submit = () => form.submit();

  return (
    <Div>
      {data.companies.data.length > 0 && (
        <form
          ref={(ref) => {
            form = ref;
          }}
          method="POST"
          action={`/user/${user.id}/changecompany`}
        >
          <Select
            name="company_id"
            onChange={submit}
            defaultValue={user.company.id}
          >
            {data.companies.data.map((c) => (
              <option value={c.id} key={`CompanySwitch-${c.id}`}>
                {c.name}
              </option>
            ))}
          </Select>
        </form>
      )}
    </Div>
  );
};

CompanySwitch.propTypes = {
  user: PropTypes.object.isRequired,
};

export { CompanySwitch };
