import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import { Button, TextField } from 'front-library';
import previous from 'front-library/build/img/icon-return.svg';

import forgotIllustration from '../../../assets/images/auth/forgot-password-01.svg';
import forgotIllustrationSent from '../../../assets/images/auth/forgot-password-02.svg';
import { HttpError } from '../../../http/HttpError';
import { statusCodes } from '../../../http/codes';
import { routes } from '../../../routes';
import { Loading } from '../../molecules/Loading/Loading';
import { AuthPage } from '../../templates/AuthPage/AuthPage';
import {
  Form,
  GraySeparator,
  Illustration,
  Links,
  Titles,
} from './styled-components';

const ForgotPassword = () => {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [formSent, setFormSent] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');

  useEffect(() => {
    i18n.changeLanguage('en');
  }, [i18n.changeLanguage]);

  const sendEmail = () => {
    setLoading(true);

    fetch(routes.forgotPassword.path, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': document
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute('content'),
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => {
        if (!response.ok) {
          if (statusCodes.UNAUTHORIZED === response.status) {
            response.json().then((data) => setError(data.email));
          }

          throw new HttpError(response.statusText, response.status);
        }

        setFormSent(true);
      })
      .catch(() => {
        setError(t('error:apiServerError'));
      })
      .finally(() => setLoading(false));
  };

  const renderFormSent = () => (
    <>
      <Illustration src={forgotIllustrationSent} alt="Password forgotten" />
      <Titles>
        <h2>{t('auth:emailSent')}</h2>
      </Titles>
    </>
  );

  const renderFormNotSent = () => (
    <>
      <Illustration src={forgotIllustration} alt="Password forgotten" />
      <Titles>
        <h1>{t('auth:forgottenPassword')}</h1>
        <h2>{t('auth:enterEmail')}</h2>
      </Titles>
      <Form onSubmit={sendEmail}>
        <TextField
          errorText={error}
          label={t('auth:email')}
          onChange={(value) => setEmail(value)}
          required={true}
          type="email"
          value={email}
        />
        <Button text={t('auth:reset')} type="submit" />
      </Form>
      <GraySeparator />
      <Links>
        <Link to={generatePath(routes.login.path)}>
          <Button
            bold={true}
            img={previous}
            text={t('auth:back')}
            variant="borderlessGreen"
          />
        </Link>
      </Links>
    </>
  );

  return (
    <AuthPage>
      {formSent ? renderFormSent() : renderFormNotSent()}

      {loading && <Loading />}
    </AuthPage>
  );
};

export { ForgotPassword };
