import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ArrivalDeparture,
  Diff,
  DiffLabel,
  HorizontalFlex,
  Pipe,
  PlannedReal,
  PlannedTimestamp,
  RealTimestamp,
  VerticalFlex,
} from './styled-components';

import { Text } from 'front-library';
import { POINT_TYPES } from '../constants';

const Timestamps = ({ type, dataDisplay }) => {
  const { t } = useTranslation();

  const { timestamp, realTimestamp, realTimestampColor, diff } = useMemo(
    () => dataDisplay,
    [dataDisplay],
  );

  return (
    <HorizontalFlex>
      <VerticalFlex>
        <HorizontalFlex>
          <ArrivalDeparture>
            {t(`shippingOrders:panelSections.route.${type}`)}
          </ArrivalDeparture>
          <PlannedReal>
            {t('shippingOrders:panelSections.route.planned', {
              context: POINT_TYPES.departure === type ? 'male' : 'female',
            })}
          </PlannedReal>
        </HorizontalFlex>
        <PlannedTimestamp>{timestamp}</PlannedTimestamp>
      </VerticalFlex>
      <Pipe>|</Pipe>
      <VerticalFlex>
        <PlannedReal>
          {t('shippingOrders:panelSections.route.real', {
            context: POINT_TYPES.departure === type ? 'male' : 'female',
          })}
        </PlannedReal>
        <RealTimestamp color={realTimestampColor}>
          {realTimestamp}
        </RealTimestamp>
      </VerticalFlex>
      <Diff>
        <DiffLabel>{t('shippingOrders:panelSections.route.diff')} : </DiffLabel>
        <p>
          <Text.Bold>{diff}</Text.Bold>
        </p>
      </Diff>
    </HorizontalFlex>
  );
};

Timestamps.propTypes = {
  type: PropTypes.oneOf([POINT_TYPES.departure, POINT_TYPES.arrival]),
  dataDisplay: PropTypes.shape({
    timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    realTimestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    realTimestampColor: PropTypes.string,
    diff: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

export { Timestamps };
