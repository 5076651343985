import { gql } from '@apollo/client';

const GET_ARRIVAL_SITES = gql`
    query GetArrivalSites($search: Mixed) {
        data: real_sites(
            first: 20,
            where: {
                AND: [
                    {
                        HAS: {
                            relation: "arrival_shipping_orders" 
                            condition: {
                                column: "id"
                                operator: IS_NOT_NULL
                                AND: [{
                                    column: "tour_order"
                                    operator: IS_NULL
                                }]
                            }
                        }
                    },
                    {
                        column: "name"
                        operator: LIKE
                        value: $search
                    }
                ]   
            },
            orderBy: { column: NAME, order: ASC }
        ) {
            data {
                value: id
                title: name
            }
        }
    }
`;

const GET_ASSETS = gql`
    query GetAssets($search: Mixed) {
        data: assets(
            first: 20,
            where: {
                AND: [
                    {
                        HAS: {
                            relation: "orders.shipping_order"
                        }
                    },
                    {
                        column: "reference"
                        operator: LIKE
                        value: $search
                    }
                ]
            },
            orderBy: { column: REFERENCE, order: ASC }
        ) {
            data {
                value: id
                title: reference
            }
        }
    }
`;

const GET_CARRIERS = gql`
    query GetCarriers($search: Mixed) {
        data: carriers(
            first: 20,
            where: {
                AND: [
                    {
                        HAS: {
                            relation: "shipping_orders"
                            condition: {
                                column: "id"
                                operator: IS_NOT_NULL
                            }
                        }
                    },
                    {
                        column: "name"
                        operator: LIKE
                        value: $search
                    }
                ]
            },
            orderBy: { column: NAME, order: ASC }
        ) {
            data {
                value: id
                title: name
            }
        }
    }
`;

const GET_CHECKPOINTS = gql`
    query GetCheckpoints($search: Mixed) {
        data: real_sites(
            first: 20,
            where: {
                AND: [
                    {
                        HAS: {
                            relation: "arrival_shipping_orders"
                            condition: {
                                column: "id"
                                operator: IS_NOT_NULL
                                AND: [{
                                    column: "tour_order"
                                    operator: IS_NOT_NULL
                                }]
                            }
                        }
                    },
                    {
                        column: "name"
                        operator: LIKE
                        value: $search
                    }
                ]
            },
            orderBy: { column: NAME, order: ASC }
        ) {
            data {
                value: id
                title: name
            }
        }
    }
`;

const GET_DEPARTURE_SITES = gql`
    query GetDepartureSites($search: Mixed) {
        data: real_sites(
            first: 20,
            where: {
                AND: [
                    {
                        HAS: {
                            relation: "departure_shipping_orders" 
                            condition: {
                                column: "id" 
                                operator: IS_NOT_NULL
                            }
                        }
                    },
                    {
                        column: "name"
                        operator: LIKE
                        value: $search
                    }
                ]
            },
            orderBy: { column: NAME, order: ASC }
        ) {
            data {
                value: id
                title: name
            }
        }
    }
`;

const GET_ORDERS = gql`
    query GetOrders($search: Mixed) {
        data: orders(
            first: 20,
            where: {
                AND: [
                    {
                        HAS: {
                            relation: "shipping_order"
                        }
                    },
                    {
                        column: "reference"
                        operator: LIKE
                        value: $search
                    }
                ]
            }
        ) {
            data {
                value: id
                title: reference
            }
        }
    }
`;

export {
  GET_ARRIVAL_SITES,
  GET_ASSETS,
  GET_CARRIERS,
  GET_CHECKPOINTS,
  GET_DEPARTURE_SITES,
  GET_ORDERS,
};
