import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { GraySeparator, Grid as LibGrid, Text } from 'front-library';
import EditIcon from 'front-library/build/img/icon-modify-green-2.svg';
import DeleteIcon from 'front-library/build/img/trash.svg';

import {
  generatePathWithQueryParams,
  routes,
} from '../../../../../../../routes';

import { GrayText, OrdersCountContainer } from '../styled-components';

import {
  ActionButtonsContainer,
  BlueLink,
  BlueText,
  BodyRow,
  HeaderRow,
  VerticalFlex,
} from './styled-components';

const Grid = ({ orders, onOrderEdit, onOrderDelete }) => {
  const { t } = useTranslation();

  return (
    <VerticalFlex>
      <HeaderRow>
        <OrdersCountContainer>
          <GrayText>{t('shippingOrders:panelSections.route.order')}</GrayText>
          <p>
            <Text.Bold>{orders?.length}</Text.Bold>
          </p>
        </OrdersCountContainer>
        <BlueText>{t('shippingOrders:panelSections.route.refAsset')}</BlueText>
        <GrayText>{t('shippingOrders:panelSections.route.magId')}</GrayText>
        <div />
      </HeaderRow>
      <GraySeparator />
      {orders?.map((order) => (
        <Fragment key={order.id}>
          <BodyRow>
            <p>
              <Text.Bold>{order.reference}</Text.Bold>
            </p>
            {order.asset && (
              <BlueLink
                to={generatePathWithQueryParams(routes.assetMap.path, {
                  id: order.asset.id,
                })}
              >
                {order.asset.reference}
              </BlueLink>
            )}
            {!order.asset && <div />}
            <GrayText>{order.asset?.mag?.mag_id}</GrayText>
            <ActionButtonsContainer>
              <LibGrid.IconButton
                imgSrc={EditIcon}
                onClick={() => onOrderEdit(order)}
              />
              <LibGrid.IconButton
                imgSrc={DeleteIcon}
                onClick={() => onOrderDelete(order)}
              />
            </ActionButtonsContainer>
          </BodyRow>
          <GraySeparator />
        </Fragment>
      ))}
    </VerticalFlex>
  );
};

Grid.propTypes = {
  orders: PropTypes.array,
  onOrderDelete: PropTypes.func,
  onOrderEdit: PropTypes.func,
};

export { Grid };
