import { GraySeparator, Panel } from 'front-library';
import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    height: 75vh;
`;

export const PanelContainer = styled.div`
    position: absolute;
`;

export const PanelWithBorderColor = styled(Panel)`
    > div {
        min-width: calc(52vw - 270px);
        border: 2px solid ${({ color }) => color};

        @media (max-width: 1695px) {
            width: 660px;
        }
    }
`;

export const WithMarginGraySeparator = styled(GraySeparator)`
    margin: 5px 15px;
`;

export const Eta = styled.div`
    text-align: right;
    font-family: 'Muli regular';
    font-size: 14px;
    color: #002b2b;
`;
