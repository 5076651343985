import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker } from 'front-library';

import * as Actions from '../../../../../stores/shippingOrder/actions';
import { Extra } from './Extra';

import { Autocomplete } from '../../../../molecules/Autocomplete/Autocomplete';
import { GET_SITES_WITHOUT_OFFSITE } from '../../../../molecules/Autocomplete/graphql';
import { arrivalDisabledTime, range } from '../utils';
import { HelpText, Title } from './styled-components';

const Arrival = ({ state, dispatch }) => {
  const { t, i18n } = useTranslation();

  const { arrivalSite, arrivalDate } = state;

  const minDate = state.checkpoints.length
    ? state.checkpoints.at(-1).checkpointDepartureDate
    : state.departureDate;

  return (
    <>
      <Title>
        {t('shippingOrders:modal.steps.step', { step: 5 })}
        {t('shippingOrders:modal.steps.arrivalTitle')}
        <HelpText color="#C81912">
          {t('shippingOrders:modal.steps.asterisk')}
        </HelpText>
      </Title>

      {/* Site */}
      <Autocomplete
        label={t('shippingOrders:modal.steps.site')}
        onChange={(data) =>
          dispatch({
            type: Actions.SET_ARRIVAL_SITE_ACTION,
            payload: data,
          })
        }
        query={GET_SITES_WITHOUT_OFFSITE}
        required={true}
        value={arrivalSite}
      />

      {/* Date */}

      <DatePicker
        className="DateChoiceBasic"
        locale={i18n.language}
        label={t('shippingOrders:modal.steps.arrivalDate')}
        defaultValue={arrivalDate}
        placeholder={t('shippingOrders:modal.steps.arrivalDate')}
        showTime={true}
        required={true}
        onChange={(date) =>
          dispatch({
            type: Actions.SET_ARRIVAL_DATE_ACTION,
            payload: date,
          })
        }
        disabledDate={(current) => current?.isBefore(minDate.startOf('day'))}
        disabledTime={(current) => {
          return arrivalDisabledTime(current, minDate);
        }}
      />

      {/* Additional info */}
      <Extra state={state} dispatch={dispatch} />
    </>
  );
};

Arrival.propTypes = {
  state: PropTypes.object,
  dispatch: PropTypes.func,
};

export { Arrival };
