import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, PageTitle, Pagination, SearchBar } from 'front-library';
import ExportIcon from 'front-library/build/img/export-icon.svg';
import IconAddGreen from 'front-library/build/img/icon-add-green.svg';
import ImportIcon from 'front-library/build/img/import-icon.svg';

import { ExportModal } from '../ExportModal/ExportModal';
import { PaginationContainer, TitleBarContainer } from './styled-components';

const TitleBar = ({
  title,
  onAdd,
  onPaginate,
  paginatorInfo,
  onRowsPerPageChange,
  onSearch,
  search,
  loading = false,
  asyncExport,
  pathExportXls,
  pathExportCsv,
  downloadFile,
  onImport,
  leftElement = null,
}) => {
  const { t } = useTranslation();

  const [showExportModal, setShowExportModal] = useState(false);

  return (
    <>
      <TitleBarContainer>
        {leftElement}
        <PageTitle>{title}</PageTitle>
        {onSearch && (
          <SearchBar
            onChange={onSearch}
            placeholder={t('common:searchbar')}
            value={search}
          />
        )}
        {onAdd && (
          <Button
            img={IconAddGreen}
            imgHoverAnimation="rotation180"
            imgOnlyOnMobile={true}
            onClick={() => onAdd(true)}
            text={t('common:add')}
            variant="borderlessGreen"
          />
        )}
        {asyncExport && (
          <Button
            onClick={() => setShowExportModal(!showExportModal)}
            text={t('common:export')}
            variant="green"
          />
        )}
        {pathExportXls && (
          <Button
            img={ExportIcon}
            imgHoverAnimation="translateDown"
            imgOnlyOnMobile={true}
            onClick={() => downloadFile(pathExportXls)}
            text={t('common:exportXls')}
            variant="borderlessGreen"
          />
        )}
        {pathExportCsv && (
          <Button
            img={ExportIcon}
            imgHoverAnimation="translateDown"
            imgOnlyOnMobile={true}
            onClick={() => downloadFile(pathExportCsv)}
            variant="borderlessGreen"
            text={t('common:exportCsv')}
          />
        )}
        {onImport && (
          <Button
            img={ImportIcon}
            imgHoverAnimation="translateUp"
            imgOnlyOnMobile={true}
            onClick={onImport}
            text={t('common:import')}
            variant="borderlessGreen"
          />
        )}
        {paginatorInfo && onPaginate && onRowsPerPageChange && (
          <PaginationContainer>
            {!loading && (
              <Pagination
                count={paginatorInfo?.total}
                currentPage={paginatorInfo?.currentPage - 1}
                onPageChange={(page) => onPaginate(page + 1)}
                rowsPerPage={paginatorInfo?.perPage}
                displayChangeRowsPerPage={true}
                onRowsPerPageChange={onRowsPerPageChange}
                labelRowsPerPage={t('common:numberPerPage')}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}–${to} ${t('common:of')} ${
                    count !== -1 ? count : `${t('common:moreThan')} ${to}`
                  }`
                }
              />
            )}
          </PaginationContainer>
        )}
      </TitleBarContainer>
      {showExportModal && (
        <ExportModal
          downloadFile={downloadFile}
          onClose={() => {
            setShowExportModal(false);
          }}
        />
      )}
    </>
  );
};

TitleBar.propTypes = {
  title: PropTypes.string,
  search: PropTypes.string,
  asyncExport: PropTypes.bool,
  pathExportXls: PropTypes.string,
  pathExportCsv: PropTypes.string,
  paginatorInfo: PropTypes.object,
  loading: PropTypes.bool,
  onAdd: PropTypes.func,
  onPaginate: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSearch: PropTypes.func,
  downloadFile: PropTypes.func,
  onImport: PropTypes.func,
  leftElement: PropTypes.element,
};

export { TitleBar };
