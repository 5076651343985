import { Map as LeafletMap } from 'react-leaflet';
import styled from 'styled-components';

export const LocationMapContainer = styled.div`
    position: relative;
    height: 100%;
`;

export const LoadingContainer = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 400;
`;

export const StyledLeafletMap = styled(LeafletMap)`
    width: 100%;
    height: 100%;
    z-index: 0;
`;
