import {
  mapSingleAssetToGeoJson,
  mapSingleSiteToGeoJson,
} from '../../components/molecules/LocationMap/utils';

export function addGeoJsonSites(state, sites) {
  const sitesToMap = state.currentSite
    ? sites.filter((s) => s.id !== state.currentSite.id)
    : sites;

  return {
    ...state.geoJsonSites,
    features: [
      ...state.geoJsonSites.features,
      ...sitesToMap.map(mapSingleSiteToGeoJson),
    ],
  };
}

export function addGeoJsonAssets(state, assets) {
  const assetsToMap = state.currentAsset
    ? assets.filter((a) => a.id !== state.currentAsset.id)
    : assets;

  return {
    ...state.geoJsonAssets,
    features: [
      ...state.geoJsonAssets.features,
      ...assetsToMap.map(mapSingleAssetToGeoJson),
    ],
  };
}

export function computeAssetsFeaturesOnOpenAssetPanel(state, asset) {
  const assetFeatures = state.geoJsonAssets.features.filter(
    (f) => f.properties.asset.id !== asset.id,
  );

  if (
    state.currentAsset &&
    null !== state.currentAsset?.mag &&
    null === state.currentAsset.group
  ) {
    assetFeatures.push(mapSingleAssetToGeoJson(state.currentAsset));
  }

  return assetFeatures;
}

export function computeAssetsFeaturesOnCloseAssetPanel(state) {
  const features = [...state.geoJsonAssets.features];

  if (null === state.currentAsset?.group && null !== state.currentAsset?.mag) {
    features.push(mapSingleAssetToGeoJson(state.currentAsset));
  }

  return features;
}

export function computeSitesFeaturesOnOpenSitePanel(state, site) {
  const sitesFeatures = state.geoJsonSites.features.filter(
    (f) => f.properties.siteId !== site.id,
  );

  if (state.currentSite && 'OFF_SITE' !== state.currentSite.type.name) {
    sitesFeatures.push(mapSingleSiteToGeoJson(state.currentSite));
  }

  return sitesFeatures;
}
