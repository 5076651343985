import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import { ColumnCheckbox, Grid } from 'front-library';
import EditIcon from 'front-library/build/img/icon-modify-green-2.svg';

import { routes } from '../../../../routes';

const DataGrid = ({ companies, onOrderBy, onOrderByRelation }) => {
  const { t } = useTranslation();

  return (
    <Grid.Grid>
      <Grid.Header>
        <Grid.Cell size="md">
          <ColumnCheckbox
            label={t('companies:form.name')}
            onClick={(order) => onOrderBy('NAME', order)}
          />
        </Grid.Cell>
        <Grid.Cell size="md">
          <ColumnCheckbox
            label={t('companies:form.users')}
            onClick={(order) =>
              onOrderByRelation('users', 'COUNT', null, order)
            }
          />
        </Grid.Cell>
        <Grid.Cell size="s">
          <ColumnCheckbox
            label={t('companies:form.assets')}
            onClick={(order) =>
              onOrderByRelation(
                'assetsWithoutGlobalScopes',
                'COUNT',
                null,
                order,
              )
            }
          />
        </Grid.Cell>
        <Grid.Cell size="s">
          <ColumnCheckbox
            label={t('companies:form.mags')}
            onClick={(order) =>
              onOrderByRelation('magsWithoutGlobalScopes', 'COUNT', null, order)
            }
          />
        </Grid.Cell>
        <Grid.Cell size="md">
          <ColumnCheckbox label={t('companies:form.address')} />
        </Grid.Cell>
        <Grid.Cell size="md">
          <ColumnCheckbox label={t('companies:form.phone')} />
        </Grid.Cell>
        <Grid.Cell size="md">
          <ColumnCheckbox
            label={t('companies:form.accuracy')}
            onClick={(order) => onOrderBy('ACCURACY', order)}
          />
        </Grid.Cell>
        <Grid.Cell size="l">
          <ColumnCheckbox
            label={t('companies:form.batteryLimit')}
            onClick={(order) => onOrderBy('BATTERY_LIFE_LIMIT', order)}
          />
        </Grid.Cell>
        <Grid.Cell size="xs" />
      </Grid.Header>

      {companies?.map((company) => (
        <GridRow key={company.id} company={company} />
      ))}
    </Grid.Grid>
  );
};

const GridRow = ({ company }) => (
  <Grid.Row>
    <Grid.Cell size="md">{company.name}</Grid.Cell>
    <Grid.Cell size="md">{company.users.paginatorInfo.total}</Grid.Cell>
    <Grid.Cell size="s">{company.assets.paginatorInfo.total}</Grid.Cell>
    <Grid.Cell size="s">{company.mags.paginatorInfo.total}</Grid.Cell>
    <Grid.Cell size="md">{company.address}</Grid.Cell>
    <Grid.Cell size="md">{company.phone_number}</Grid.Cell>
    <Grid.Cell size="md">{company.accuracy}</Grid.Cell>
    <Grid.Cell size="l">{company.battery_life_limit}</Grid.Cell>
    <Grid.Cell size="xs" alignment="right">
      <Link
        to={generatePath(routes.administrationCompanyEdition.path, {
          id: company.id,
        })}
      >
        <Grid.IconButton imgSrc={EditIcon} />
      </Link>
    </Grid.Cell>
  </Grid.Row>
);

DataGrid.propTypes = {
  companies: PropTypes.array,
  onOrderBy: PropTypes.func,
  onOrderByRelation: PropTypes.func,
};

GridRow.propTypes = {
  company: PropTypes.object,
};

export { DataGrid };
