import { useMemo } from 'react';
import { fetchAsset } from '../../http/assetsRepository';
import { useHttpQuery } from '../useHttpQuery';

function useAsset(assetId) {
  const params = useMemo(() => ({ id: assetId }), [assetId]);

  return useHttpQuery(fetchAsset, params);
}

export { useAsset };
