import { useMutation } from '@apollo/client';
import qs from 'qs';
import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { BtnInATab, Loading } from 'front-library';
import IconAddGreen from 'front-library/build/img/icon-add-green.svg';

import { useLocation } from 'react-router-dom';
import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { AutomatedActionsModal } from '../../organisms/AutomatedActionsModal/AutomatedActionsModal';
import { ListPage } from '../../templates/ListPage';
import { DataGrid } from './DataGrid';
import {
  CREATE_AUTOMATED_ACTION,
  DELETE_AUTOMATED_ACTION,
  GET_AUTOMATED_ACTIONS,
  GET_AUTOMATED_ACTION_FORM_DATA,
  UPDATE_AUTOMATED_ACTION,
} from './graphql';
import { removeDuplicate, setRelations } from './utils';

const AutomatedActions = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const parameters = qs.parse(location.search, { ignoreQueryPrefix: true });
  const addAction = Object.keys(parameters).includes('addAction');

  const [createAutomatedActionMutation] = useMutation(CREATE_AUTOMATED_ACTION);
  const [updateAutomatedActionMutation] = useMutation(UPDATE_AUTOMATED_ACTION);
  const [deleteAutomatedActionMutation] = useMutation(DELETE_AUTOMATED_ACTION);

  const { loading, data, refetch, setPagination, currentPage, setFirst } =
    useQueryGraphQl(GET_AUTOMATED_ACTIONS);
  const { loading: loadingFormData, data: formData } = useQueryGraphQl(
    GET_AUTOMATED_ACTION_FORM_DATA,
  );
  const [showModal, setShowModal] = useState(addAction);
  const [selectedAutomatedAction, setSelectedAutomatedAction] = useState(null);
  const [loadingMutation, setLoadingMutation] = useState(false);

  const columns = [
    t('automatedActions:actionName'),
    t('automatedActions:creationDate'),
    t('automatedActions:author'),
  ];

  const handleEdit = (automatedAction) => {
    setSelectedAutomatedAction(automatedAction);
    setShowModal(true);
  };

  const handleDelete = (automatedAction) =>
    confirmAlert({
      title: t('common:deletion'),
      message: t('automatedActions:askDelete'),
      buttons: [
        {
          label: t('common:yes'),
          onClick: () => handleDeleteConfirm(automatedAction),
        },
        {
          label: t('common:no'),
        },
      ],
    });

  const handleModalClose = () => {
    setSelectedAutomatedAction(null);
    setShowModal(false);
  };

  const handleSave = async (values, onSuccess, onError) => {
    setLoadingMutation(true);

    let valuesToUpload = { ...values };

    try {
      if (selectedAutomatedAction) {
        // update
        await updateAutomatedActionMutation({
          variables: {
            ...setRelations(selectedAutomatedAction, valuesToUpload),
            id: selectedAutomatedAction.id,
          },
        });
      } else {
        // create
        valuesToUpload = {
          ...valuesToUpload,
          relational_conditions: {
            create: removeDuplicate(valuesToUpload.relational_conditions),
          },
          value_conditions: {
            create: removeDuplicate(valuesToUpload.value_conditions),
          },
          actions: {
            create: removeDuplicate(valuesToUpload.actions),
          },
        };

        await createAutomatedActionMutation({ variables: valuesToUpload });
      }

      await refetch();

      toast.success(
        selectedAutomatedAction
          ? t('automatedActions:updated.success')
          : t('automatedActions:created.success'),
      );

      onSuccess();
    } catch (e) {
      onError(e?.graphQLErrors[0]?.extensions?.validation || {});
      toast.error(
        selectedAutomatedAction
          ? t('automatedActions:updated.error')
          : t('automatedActions:created.error'),
      );
    }

    setLoadingMutation(false);
  };

  const handleDeleteConfirm = async (automatedAction) => {
    setLoadingMutation(true);

    try {
      await deleteAutomatedActionMutation({
        variables: { id: automatedAction.id },
      });

      if (1 === data?.automated_actions?.data?.length) {
        setPagination(currentPage - 1);
      } else {
        await refetch();
      }

      toast.success(t('automatedActions:deleted.success'));
    } catch (_e) {
      toast.error(t('automatedActions:deleted.error'));
    }

    setLoadingMutation(false);
  };

  return (
    <ListPage
      title={t('menu:automatedActions')}
      onAdd={() => setShowModal(true)}
      onPaginate={setPagination}
      onRowsPerPageChange={setFirst}
      paginatorInfo={data?.automated_actions?.paginatorInfo}
    >
      {(loading || loadingMutation || loadingFormData) && <Loading />}
      {!loading && data && data.automated_actions && (
        <DataGrid
          columns={columns}
          data={data?.automated_actions?.data}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
      <div className="btnInATabContainer">
        <BtnInATab
          text={t('automatedActions:addAutomatedAction')}
          onClick={() => setShowModal(true)}
          imgSrc={IconAddGreen}
        />
      </div>
      {showModal && !loadingFormData && (
        <AutomatedActionsModal
          actionsConditionsOptions={formData}
          selectedAutomatedAction={selectedAutomatedAction}
          onClose={handleModalClose}
          onSave={handleSave}
        />
      )}
    </ListPage>
  );
};

export { AutomatedActions };
