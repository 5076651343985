import { gql } from '@apollo/client';

const GET_SHIPPING_ORDERS = gql`
    query GetShippingOrders(
        $first: Int,
        $page: Int,
        $search: String,
        $orderBy: [QueryShippingOrdersOrderByRelationOrderByClause!],
        $where: WhereConditions,
        $isLate: Boolean
    ) {
        shipping_orders(
            first: $first,
            page: $page,
            orderBy: $orderBy,
            search: $search,
            where: $where,
            isLate: $isLate
        ) { 
            paginatorInfo {
                total
                currentPage
                lastPage
                perPage
            }
            data {
                id
                reference
                departure_site (trashed: WITH) {
                    id
                    name
                    latitude
                    longitude
                    geofence {
                        __typename
                        ... on GeofencePolygon {
                            id
                            is_rectangle
                            points {
                                latitude
                                longitude
                            }
                        }
                        ... on GeofenceCircle {
                            id
                            latitude
                            longitude
                            radius
                        }
                    }
                    branches {
                        id
                    }
                }
                arrival_site (trashed: WITH) {
                    id
                    name
                    latitude
                    longitude
                    geofence {
                        __typename
                        ... on GeofencePolygon {
                            id
                            is_rectangle
                            points {
                                latitude
                                longitude
                            }
                        }
                        ... on GeofenceCircle {
                            id
                            latitude
                            longitude
                            radius
                        }
                    }
                    branches {
                        id
                    }
                }
                assets {
                    paginatorInfo {
                        count
                    }
                }
                is_recomputing
                geofence_updated
                are_assets_updated
                departure_timestamp
                real_departure_timestamp
                arrival_timestamp
                real_arrival_timestamp
                human_eta
                shipping_order_status {
                    id
                    name
                }
                carrier {
                    id
                    name
                }
                comment
                tour_order
                tour {
                    id
                    reference 
                    shipping_orders(first: 100000, orderBy: {column: TOUR_ORDER, order: ASC}) {
                        data {
                            id
                            reference
                            departure_site (trashed: WITH) {
                                id
                                name
                                latitude
                                longitude
                                geofence {
                                    __typename
                                    ... on GeofencePolygon {
                                        id
                                        is_rectangle
                                        points {
                                            latitude
                                            longitude
                                        }
                                    }
                                    ... on GeofenceCircle {
                                        id
                                        latitude
                                        longitude
                                        radius
                                    }
                                }
                                branches {
                                    id
                                }
                            }
                            arrival_site (trashed: WITH) {
                                id
                                name
                                latitude
                                longitude
                                geofence {
                                    __typename
                                    ... on GeofencePolygon {
                                        id
                                        is_rectangle
                                        points {
                                            latitude
                                            longitude
                                        }
                                    }
                                    ... on GeofenceCircle {
                                        id
                                        latitude
                                        longitude
                                        radius
                                    }
                                }
                                branches {
                                    id
                                }
                            }
                            departure_timestamp
                            real_departure_timestamp
                            arrival_timestamp
                            real_arrival_timestamp
                            checkpoint_departure_timestamp
                            checkpoint_real_departure_timestamp
                            tour_order
                            human_eta
                            geofence_updated
                            are_assets_updated
                            shipping_order_status {
                                id
                                name
                            }
                        }
                    }
                }
                asset_group {
                    id
                }
                mag_data_id_degraded_position
            }
        }
    }
`;

const GET_DEFAULT_GRAPHQL_FILTER = gql`
    query GetDefaultGraphQlFilter(
        $userId: Mixed
    ) {
        graphql_filters(
            where: {
                AND: [
                    { column: "page", operator: EQ, value: "shippingOrders" }
                    { column: "default_view", operator: EQ, value: true }
                    { HAS: {
                        relation: "user", condition: {
                            column: "id", operator: EQ, value: $userId
                        }
                    } }
                ]
            }
        ) {
            data {
                id
                filters
            }
        }
    }
`;

export { GET_SHIPPING_ORDERS, GET_DEFAULT_GRAPHQL_FILTER };
