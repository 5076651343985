import { findMainRole, roleEnum } from '../../../helpers/form/roles';

export function mapUserToFormData(user) {
  return {
    id: user?.id || null,
    surname: user?.surname || '',
    name: user?.name || '',
    username: user?.username || '',
    email: user?.email || '',
    phone: user?.phone || '',
    password: '',
    passwordConfirmation: '',
    isForcedPasswordUpdate: false,
    page: user?.home_page ? user.home_page : 'map',
    language: user?.lang ? user.lang : 'fr',
    branches:
      user?.branches?.map(({ id, name }) => ({ value: id, title: name })) ?? [],
    roles: user?.roles.map((role) => role.name) ?? [],
    selectedRole: user?.roles ? findMainRole(user.roles).name ?? '' : '',
    alerts: user?.alerts?.data || [],
    automations: user?.automated_actions?.data || [],
  };
}

export function mapFormDataToGraphQl(formData, user, roles) {
  // Convert from name to id
  const formRoles = formData.roles.map(
    (formRole) => +roles.find((role) => role.name === formRole).id,
  );
  const shouldUpdatePassword = formData.id
    ? user.roles.find((role) => role.name === roleEnum.observer) &&
      !formData.roles.includes(roleEnum.observer)
    : true;

  return {
    ...(formData.id && { id: formData.id }),
    surname: formData.surname,
    name: formData.name,
    username: formData.username,
    email: formData.email,
    phone: formData.phone,
    ...(shouldUpdatePassword && { password: formData.password }),
    ...(shouldUpdatePassword && {
      password_confirmation: formData.passwordConfirmation,
    }),
    password_force_update: formData.isForcedPasswordUpdate,
    home_page: formData.page || 'map',
    lang: formData.language,
    branches: { sync: formData.branches.map((b) => b.value) },
    roles: { sync: formRoles },
    alerts: {
      [formData.id ? 'sync' : 'connect']: formData.alerts.map((a) => ({
        id: a.id,
        type: a.pivot.type,
        is_allowed: a.pivot.is_allowed,
        is_active: a.pivot.is_active,
      })),
    },
    automated_actions: {
      [formData.id ? 'sync' : 'connect']: formData.automations.map((a) => ({
        id: a.id,
        type: a.pivot.type,
        is_allowed: a.pivot.is_allowed,
        is_active: a.pivot.is_active,
      })),
    },
  };
}
