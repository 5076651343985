import { gql } from '@apollo/client';

const GET_CALLBACK = gql`
    query GetCallback($id: ID!) {
        callback(id: $id) {
            id
            name
            url
            content_type
            mail
            format
            type
            itss_app_id
            headers {
                id
                key
                value
            }
        }
    }
`;

const GET_CALLBACKS = gql`
    query Callbacks($first: Int, $page: Int, $search: Mixed, $sort: [QueryCallbacksSortOrderByClause!]) {
        callbacks(first: $first, page: $page, sort: $sort,where: {
            OR: [
                {
                    column: "id"
                    operator: EQ
                    value: $search
                },
                {
                    column: "name"
                    operator: LIKE
                    value: $search
                },
                {
                    column: "type"
                    operator: LIKE
                    value: $search
                }
            ]
        }) {
            paginatorInfo {
                total
                currentPage
                lastPage
                perPage
            }
            data {
                id
                name
                type
                errors
            }
        }
    }
`;

const CREATE_CALLBACK = gql`
    mutation CreateCallback(
        $name: String
        $url: String
        $content_type: String
        $mail: String
        $format: String
        $type: String
        $headers: UpdateHeaderInput
        $itss_app_id: String
    ) {
        createCallback(
            input: {
                name: $name
                url: $url
                content_type: $content_type
                mail: $mail
                format: $format
                type: $type
                headers: $headers
                itss_app_id: $itss_app_id
            }
        ) {
            id
        }
    }
`;

const UPDATE_CALLBACK = gql`
    mutation UpdateCallback(
        $id: ID!
        $name: String
        $url: String
        $content_type: String
        $mail: String
        $format: String
        $type: String
        $headers: UpdateHeaderInput
        $itss_app_id: String
    ) {
        updateCallback(
            input: {
                id: $id
                name: $name
                url: $url
                content_type: $content_type
                mail: $mail
                format: $format
                type: $type
                headers: $headers
                itss_app_id: $itss_app_id
            }
        ) {
            id
        }
    }
`;

const DELETE_CALLBACK = gql`
    mutation DeleteCallback($id: ID!) {
        deleteCallback(id: $id) {
            id
        }
    }
`;

export {
  GET_CALLBACK,
  GET_CALLBACKS,
  CREATE_CALLBACK,
  UPDATE_CALLBACK,
  DELETE_CALLBACK,
};
