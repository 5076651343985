import styled from 'styled-components';

export const DashedBorder = styled.div`
    width: 100%;
    border: 1px #B3B3B3;
    border-style: dashed none none none;
    opacity: 0.8;
    margin-top: 10px;
    margin-bottom: 5px;
`;
