import { get, getById } from './httpClient';

export async function fetchSiteCollection(
  { queryParams, page, numberOfItemsPerPage = 100 },
  controller,
) {
  return await get(
    'sites',
    {
      page,
      per_page: numberOfItemsPerPage,
      ...queryParams,
    },
    controller,
  );
}

export async function fetchSite({ id }, controller) {
  return await getById('sites', id, controller);
}

export async function fetchSiteAssetTypesCollection(
  { id, queryParams, page = 1, numberOfItemsPerPage = 50 },
  controller,
) {
  return await get(
    `sites/${id}/asset_types`,
    {
      page,
      per_page: numberOfItemsPerPage,
      ...queryParams,
    },
    controller,
  );
}
