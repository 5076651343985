import { gql } from '@apollo/client';

const GET_MAGS = gql`
    query GetMags($id: Mixed, $search: Mixed) {
        data: mags(
            first: 20
            where: {
                AND: [
                    {
                        OR: [
                            {
                                column: "id"
                                operator: EQ
                                value: $id
                            },
                            {
                                HAS: {
                                    operator: IS_NULL
                                    relation: "asset"
                                }
                            }
                        ]
                    },
                    {
                        column: "mag_id"
                        operator: LIKE
                        value: $search
                    }
                ]
            }
        ) {
            data {
                value: id
                title: mag_id
            }
        }
    }
`;

const GET_ASSET_STATES = gql`
    query GetAssetStates {
        asset_states(first: 10000) {
            data {
                value: id
                title: name
            }
        }
    }
`;

const GET_ASSETS = gql`
    query Assets($first: Int, $page: Int, $search: Mixed, $orderBy: [QueryAssetsOrderByOrderByClause!]) {
        assets(first: $first, page: $page, orderBy: $orderBy, where: {
            OR: [
                {
                    column: "id"
                    operator: LIKE
                    value: $search
                },
                {
                    column: "reference"
                    operator: LIKE
                    value: $search
                },
                {
                    HAS: {
                        relation: "mag"
                        condition: {
                            column: "mag_id"
                            operator: LIKE
                            value: $search
                        }
                    }
                },
                {
                    HAS: {
                        relation: "asset_type"
                        condition: {
                            column: "name"
                            operator: LIKE
                            value: $search
                        }
                    }
                },
                {
                    HAS: {
                        relation: "asset_group"
                        condition: {
                            column: "reference"
                            operator: LIKE
                            value: $search
                        }
                    }
                },
                {
                    HAS: {
                        relation: "state"
                        condition: {
                            column: "name"
                            operator: LIKE
                            value: $search
                        }
                    }
                }
            ]
        }) {
            paginatorInfo {
                total
                currentPage
                lastPage
                perPage
            }
            data {
                id
                reference
                buy_value
                last_mag_data_id
                state {
                    id
                    name
                }
                mag {
                    id
                    mag_id
                    last_message_date
                }
                asset_type {
                    id
                    name
                }
                asset_group {
                    id
                    reference
                }
                branches {
                    data  {
                        id
                        name
                    }
                }
                custom_parameters {
                    data {
                        id
                        pivot {
                            value
                        }
                    }
                }
                responsibles {
                    data {
                        id
                        name
                    }
                }
            }
        }
    }
`;

const GET_CUSTOM_PARAMETERS = gql`
    {
        custom_parameters(first: 100000, where: {
            column: "model"
            operator: EQ
            value: "App\\\\Models\\\\Asset"
        }) {
            data {
                id
                name
                description
                model
                value_type
                asset_types_filters {
                    data {
                        id
                    }
                }
            }
        }
    }
`;

const CREATE_ASSET = gql`
    mutation CreateAsset(
        $mag: UpdateMagRelation,
        $branches: UpdateManyRelation,
        $asset_type: UpdateAssetTypeRelation,
        $state: UpdateRelation,
        $reference: String,
        $buy_value: Float,
        $asset_group: UpdateAssetGroupRelation,
        $responsibles: UpdateManyRelation,
        $custom_parameters: UpdateParameterizableRelations
    ) {
        createAsset(
            input: {
                mag: $mag
                branches: $branches
                asset_type: $asset_type
                state: $state
                reference: $reference
                buy_value: $buy_value
                asset_group: $asset_group
                responsibles: $responsibles
                custom_parameters: $custom_parameters
            }
        ) {
            id
        }
    }
`;

const UPDATE_ASSET = gql`
    mutation updateAsset(
        $id: ID!,
        $mag: UpdateMagRelation,
        $branches: UpdateManyRelation,
        $asset_type: UpdateAssetTypeRelation,
        $state: UpdateRelation,
        $reference: String,
        $buy_value: Float,
        $asset_group: UpdateAssetGroupRelation,
        $responsibles: UpdateManyRelation,
        $custom_parameters: UpdateParameterizableRelations
    ) {
        updateAsset(
            input: {
                id: $id
                mag: $mag
                branches: $branches
                asset_type: $asset_type
                state: $state
                reference: $reference
                buy_value: $buy_value
                asset_group: $asset_group
                responsibles: $responsibles
                custom_parameters: $custom_parameters
            }
        ) {
            id
        }
    }
`;

const GET_ASSET = gql`
    query GetAsset($id: ID) {
        asset(id: $id) {
            id
            reference
            state {
                id
                name
            }
            mag {
                id
                mag_id
                last_message_date
                last_accepted_latitude
                last_accepted_longitude
                last_accepted_device_timestamp
            }
            latestMagData {
                device_timestamp
                latitude
                longitude
            }
            custom_parameters(first: 100000) {
                data {
                    id
                    pivot {
                        value
                    }
                }
            }
            asset_type {
                id
                name
                temperature_min
                temperature_max
            }
            asset_group {
                id
            }
        }
    }
`;

const DELETE_ASSET = gql`
    mutation DeleteAsset($id: ID!) {
        deleteAsset(id: $id) {
            id
        }
    }
`;

const GET_SITE_TRANSITS = gql`
    query GetSiteTransits($assetId: Mixed) {
        site_transits(
            where: {
                AND: [
                    {
                        HAS: {
                            relation: "asset"
                            condition: {
                                column: "id"
                                operator: EQ
                                value: $assetId
                            }
                        }
                    },
                    {
                        HAS: {
                            relation: "sites.site_type"
                            condition: {
                                column: "name"
                                operator: NEQ
                                value: "OFF_SITE"
                            }
                        }
                    }
                ]
            },
            first: 10000,
            orderBy: {
                column: DATE_IN,
                order: DESC
            }
        ) {
            data {
                id
                date_in
                date_out
                sites(first: 100000) {
                    data {
                        id
                        name
                    }
                }
            }
        }
    }
`;

const GET_MAG_DATAS = gql`
    query GetMagDatas($where: WhereConditions) {
        mag_datas(where: $where, first: 10000, orderBy: { column: DEVICE_TIMESTAMP, order: ASC }) {
            data {
                id
                latitude
                longitude
                device_timestamp
                temperature
                accuracy
                is_position_accepted
            }
        }
    }
`;

const EXPORT_ASSETS = gql`
    mutation ExportAssets(
        $user_id: Int!,
        $type: ExportType!,
        $formats: FormatsInput!,
        $filters: Mixed
    ) {
      exportData(
        input: {
          user_id: $user_id
          type: $type
          formats: $formats
          filters: $filters
        }
      )
    }
`;

export {
  GET_MAGS,
  GET_ASSET_STATES,
  CREATE_ASSET,
  UPDATE_ASSET,
  GET_CUSTOM_PARAMETERS,
  GET_ASSET,
  GET_ASSETS,
  DELETE_ASSET,
  GET_MAG_DATAS,
  GET_SITE_TRANSITS,
  EXPORT_ASSETS,
};
