const SET_VARIABLES_ACTION = 'SET_VARIABLES_ACTION';
const SET_VARIABLE_ACTION = 'SET_VARIABLE_ACTION';
const SET_FILTERS_ACTION = 'SET_FILTERS_ACTION';
const SET_FILTER_ACTION = 'SET_FILTER_ACTION';
const SET_VARIABLES_AND_FILTERS_ACTION = 'SET_VARIABLES_AND_FILTERS_ACTION';
const SET_FIRST_ACTION = 'SET_FIRST_ACTION';

export {
  SET_VARIABLES_ACTION,
  SET_VARIABLE_ACTION,
  SET_FILTERS_ACTION,
  SET_FILTER_ACTION,
  SET_VARIABLES_AND_FILTERS_ACTION,
  SET_FIRST_ACTION,
};
