import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, TitleIcon } from 'front-library';
import iconAction from 'front-library/build/img/icon-action.svg';

import { getShippingOrderTranslate } from '../../../helpers';
import { TitleRemove } from '../../atoms/TitleRemove/TitleRemove';
import {
  ActionContainer,
  ConditionFieldsContainer,
  TitleContainer,
} from './styled-components';

const Action = ({
  index,
  action,
  onRemove,
  onUpdate,
  actionsOptions,
  statuses,
}) => {
  const { t } = useTranslation();

  const typeChoices = useMemo(
    () => [
      {
        value: 'ShippingOrderStatus',
        title: t('automatedActions:form.actionTypeOptions.status'),
      },
    ],
    [t],
  );

  const valueChoices = useMemo(
    () =>
      action.type
        ? 'ShippingOrderStatus' === action.type
          ? actionsOptions[action.type].data
              .filter((option) => statuses.includes(option.value))
              .map((option) => ({
                ...option,
                title: getShippingOrderTranslate(t, option.title),
              }))
          : actionsOptions[action.type].data
        : [],
    [action, actionsOptions, statuses, t],
  );

  return (
    <>
      <ActionContainer>
        <TitleContainer>
          <TitleIcon img={iconAction} />
          <TitleRemove onClick={onRemove}>
            {`${t('automatedActions:form.action')} ${index + 1}`}
          </TitleRemove>
        </TitleContainer>
        <ConditionFieldsContainer>
          <Select
            label={t('automatedActions:form.actionType')}
            onChange={(value) =>
              onUpdate({
                ...action,
                type: value,
              })
            }
            options={typeChoices}
            required={true}
            value={action?.type || ''}
          />
          <Select
            disabled={null === action.type}
            label={t('automatedActions:form.value')}
            onChange={(value) =>
              onUpdate({
                ...action,
                value: value,
              })
            }
            options={valueChoices}
            required={true}
            value={action?.value}
          />
        </ConditionFieldsContainer>
      </ActionContainer>
    </>
  );
};

export { Action };
