import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from 'front-library';

import { HelpText, Title } from '../styled-components';

const Asset = ({ formValues, setFormValues }) => {
  const { t } = useTranslation();

  return (
    <>
      <Title>{t('companies:form.assets')}</Title>

      {/* Branch assignment to assets by position */}
      <Checkbox
        label={t('companies:form.assetBranchAssignmentByPositionLabel')}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            assetBranchAssignmentByPosition: e.target.checked,
          })
        }
        checked={formValues.assetBranchAssignmentByPosition}
      />
      <HelpText $marginTop="5px">
        {t('companies:form.assetBranchAssignmentByPositionHelpText')}
      </HelpText>
    </>
  );
};

Asset.propTypes = {
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
};

export { Asset };
