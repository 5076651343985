import FileSaver from 'file-saver';
import React, { useEffect } from 'react';
import reactDom from 'react-dom';
import { useTranslation } from 'react-i18next';

import cross from 'front-library/build/img/delete-cross.svg';
import fileIcon from 'front-library/build/img/icon-file.svg';

import { CsvViewer } from './CsvViewer';

import {
  Center,
  DownloadLink,
  FileIcon,
  Iframe,
  Image,
  InnerContainer,
  ModalContainer,
  OuterContainer,
  TitleContainer,
} from './styled-components';

const extentionsMimeTypes = {
  png: 'image/png',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  bmp: 'image/bmp',
  gif: 'image/gif',
  svg: 'image/svg+xml',
  pdf: 'application/pdf',
  csv: 'text/csv',
};

const getFileType = (filename) =>
  extentionsMimeTypes[filename.split('.').pop()] || null;

const FileViewer = ({ fileType, filePath, filename = null, onClose }) => {
  const { t } = useTranslation();

  const handleClose = () => onClose();

  const downloadFile = (url, fileName) => {
    fetch(url).then(async (res) => {
      const blob = await res.blob();
      const fileUrl = URL.createObjectURL(blob);

      FileSaver(fileUrl, fileName);
    });
  };

  useEffect(() => {
    const handleEscape = (e) => {
      if ('Escape' === e.key) {
        onClose();
      }
    };

    document.addEventListener('keyup', handleEscape);

    return () => {
      document.removeEventListener('keyup', handleEscape);
    };
  }, [onClose]);

  const { view, canShow } = renderFile(fileType, filePath, filename, t);

  return reactDom.createPortal(
    <ModalContainer>
      <OuterContainer>
        <InnerContainer>
          <TitleContainer>
            <h2>{t('fileviewer:fileviewer')}</h2>
            <button type="button" onClick={handleClose}>
              <img src={cross} alt="x" />
            </button>
          </TitleContainer>
          <div>
            {view}
            {canShow && (
              <Center>
                <DownloadLink onClick={() => downloadFile(filePath, filename)}>
                  {t('fileviewer:clickToDownload')}
                  <FileIcon src={fileIcon} alt="download" />
                </DownloadLink>
              </Center>
            )}
          </div>
        </InnerContainer>
      </OuterContainer>
    </ModalContainer>,
    document.body,
  );
};

const renderFile = (fileType, filePath, filename, t) => {
  const type =
    'url' === fileType && filename ? getFileType(filename) : fileType;

  switch (type) {
    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/bmp':
    case 'image/gif':
    case 'image/svg+xml':
      return { view: renderImage(filePath), canShow: true };
    case 'application/pdf':
      return { view: renderPdf(filePath), canShow: true };
    case 'text/csv':
    case 'application/vnd.ms-excel':
      return { view: renderCsv(filePath), canShow: true };
    default:
      return { view: renderCannotShowFile(filePath, t), canShow: false };
  }
};

const renderImage = (src) => <Image src={src} alt="uploaded file" />;

const renderPdf = (src) => (
  <Iframe src={src} title="Pdf Viewer" width="700vw" height="600vh" />
);

const renderCsv = (src) => <CsvViewer url={src} />;

const renderCannotShowFile = (src, t) => (
  <p>
    {t('fileviewer:cannotDisplay')}{' '}
    <a href={src} download={true}>
      {t('fileviewer:here')}
    </a>{' '}
    {t('fileviewer:toDownload')}
  </p>
);

export { FileViewer };
