import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import { ColumnCheckbox, Grid } from 'front-library';
import EditIcon from 'front-library/build/img/icon-modify-green-2.svg';
import DeleteIcon from 'front-library/build/img/trash.svg';

import { routes } from '../../../../routes';
import { GridHeaderContainer } from '../styled-components';

const DataGrid = ({ magTypes, onDelete, onOrderBy, onOrderByRelation }) => {
  const { t } = useTranslation();

  return (
    <>
      <GridHeaderContainer>
        <Grid.Grid>
          <Grid.Header>
            <Grid.Cell size="md">
              <ColumnCheckbox
                label={t('magTypes:form.name')}
                onClick={(order) => onOrderBy('NAME', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="md">
              <ColumnCheckbox
                label={t('magTypes:form.provider')}
                onClick={(order) =>
                  onOrderByRelation('provider', 'MAX', 'NAME', order)
                }
              />
            </Grid.Cell>
            <Grid.Cell size="md">
              <ColumnCheckbox
                label={t('magTypes:form.qualification')}
                onClick={(order) => onOrderBy('QUALIFICATION', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="s">
              <ColumnCheckbox
                label={t('magTypes:mags')}
                onClick={(order) =>
                  onOrderByRelation('mags', 'COUNT', null, order)
                }
              />
            </Grid.Cell>
            <Grid.Cell size="xs" />
            <Grid.Cell size="xs" />
          </Grid.Header>
        </Grid.Grid>
      </GridHeaderContainer>

      <Grid.Grid>
        {magTypes?.map((magType) => (
          <GridRow key={magType.id} magType={magType} onDelete={onDelete} />
        ))}
      </Grid.Grid>
    </>
  );
};

const GridRow = ({ magType, onDelete }) => (
  <Grid.Row>
    <Grid.Cell size="md">{magType.name}</Grid.Cell>
    <Grid.Cell size="md">{magType.provider.name}</Grid.Cell>
    <Grid.Cell size="md">{magType.qualification}</Grid.Cell>
    <Grid.Cell size="s">{magType.mags.paginatorInfo.total}</Grid.Cell>
    <Grid.Cell size="xs" alignment="right">
      <Link
        to={generatePath(routes.administrationMagTypeEdition.path, {
          id: magType.id,
        })}
      >
        <Grid.IconButton imgSrc={EditIcon} />
      </Link>
    </Grid.Cell>
    <Grid.Cell size="xs" alignment="right">
      <Grid.IconButton
        imgSrc={DeleteIcon}
        onClick={() => onDelete(magType)}
        disabled={magType.mags.paginatorInfo.total > 0}
      />
    </Grid.Cell>
  </Grid.Row>
);

DataGrid.propTypes = {
  magTypes: PropTypes.array,
  onDelete: PropTypes.func,
  onOrderBy: PropTypes.func,
  onOrderByRelation: PropTypes.func,
};

GridRow.propTypes = {
  magType: PropTypes.object,
  onDelete: PropTypes.func,
};

export { DataGrid };
