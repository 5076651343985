import styled from 'styled-components';

export const PointContainer = styled.div`
    ${({ marginBottom }) =>
      false !== marginBottom &&
      `
        margin-bottom: 25px;
    `}
`;

export const SiteHeader = styled.div`
    display: flex;
    align-items: end;
    gap: 6px;
`;

export const SiteInfos = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 9px;
    padding-top: 5px;
    padding-left: 16px;

    ${({ isDeparture }) =>
      false === isDeparture &&
      `
        border-left: 1px dashed #b3b3b3;
        padding-bottom: 20px;
    `}
`;

export const SiteIcon = styled.img`
    width: 20px;
    height: 20px;
`;

export const SiteName = styled.p`
    font-family: 'Muli bold';
    font-size: 16px;
    color: #002B2B;
`;

export const SiteInfo = styled.p`
    font-family: 'Muli regular';
    font-size: 14px;
    color: #707070;
`;

export const Pipe = styled.span`
    color: #ef9d11;
`;

/**
 * @TODO this span will be removed when we'll
 * fill the planned departure_timestamp on
 * checkpoints, the goal is to don't break the display.
 */
export const EmptySpan = styled.span`
    display: inline-block;
    width: 139px;
`;
