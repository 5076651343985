import { get, getById } from './httpClient';

export async function fetchAssetCollection(
  { queryParams, page, numberOfItemsPerPage = 100 },
  controller,
) {
  return get(
    'assets',
    {
      page,
      per_page: numberOfItemsPerPage,
      ...queryParams,
    },
    controller,
  );
}

export async function fetchAsset({ id }, controller) {
  return getById('assets', id, controller);
}
