import { Panel, PanelTitle, Switch, TextField } from 'front-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  AlertAssetNotCummunicate,
  AlertAssetOffSite,
  AlertAssetStationary,
  AlertMagLowBattery,
  AlertReport,
} from './constants';
import { PanelContainer } from './styled-components';

const PanelAlertReport = ({ userAlerts, onChange }) => {
  const { t } = useTranslation();

  return (
    <Panel>
      <PanelContainer>
        <div className="title-alert-box">
          <PanelTitle label={t('alert:report.title')} />
        </div>
        <div className="testgrid">
          <Switch
            checked={!!userAlerts.find((a) => a.id === AlertReport)}
            onChange={(e) =>
              onChange(
                'rapport',
                AlertReport,
                null,
                e.target.checked,
                userAlerts.find((a) => a.id === AlertReport)?.pivot.parameter ||
                  '15',
              )
            }
            labelSwichtTitle={t('alert:report.alertReport')}
            className="switchInput styleLabelInput"
          />
          <TextField
            onChange={(value) =>
              onChange('rapport', AlertReport, null, true, value)
            }
            type="number"
            value={
              userAlerts.find((a) => a.id === AlertReport)?.pivot.parameter ||
              ''
            }
          />
        </div>
        <p className="second-title">{t('alert:report.subtitle')}</p>
        <div className="testgrid">
          <Switch
            checked={!!userAlerts.find((a) => a.id === AlertAssetStationary)}
            disabled={!userAlerts.find((a) => a.id === AlertReport)}
            onChange={(e) =>
              onChange(
                'rapport',
                AlertAssetStationary,
                null,
                e.target.checked,
                userAlerts.find((a) => a.id === AlertAssetStationary)?.pivot
                  .parameter || '15',
              )
            }
            labelSwichtTitle={t('alert:report.alertAssetStationary')}
            className="switchInput styleLabelInput"
          />
          <TextField
            disabled={!userAlerts.find((a) => a.id === AlertReport)}
            onChange={(value) =>
              onChange('rapport', AlertAssetStationary, null, true, value)
            }
            type="number"
            value={
              userAlerts.find((a) => a.id === AlertAssetStationary)?.pivot
                .parameter || ''
            }
          />
        </div>
        <div className="testgrid">
          <Switch
            checked={!!userAlerts.find((a) => a.id === AlertAssetOffSite)}
            disabled={!userAlerts.find((a) => a.id === AlertReport)}
            onChange={(e) =>
              onChange(
                'rapport',
                AlertAssetOffSite,
                null,
                e.target.checked,
                userAlerts.find((a) => a.id === AlertAssetOffSite)?.pivot
                  .parameter || '15',
              )
            }
            labelSwichtTitle={t('alert:report.alertAssetOffSite')}
            className="switchInput styleLabelInput"
          />
          <TextField
            disabled={!userAlerts.find((a) => a.id === AlertReport)}
            onChange={(value) =>
              onChange('rapport', AlertAssetOffSite, null, true, value)
            }
            type="number"
            value={
              userAlerts.find((a) => a.id === AlertAssetOffSite)?.pivot
                .parameter || ''
            }
          />
        </div>
        <div className="testgrid">
          <Switch
            checked={
              !!userAlerts.find((a) => a.id === AlertAssetNotCummunicate)
            }
            disabled={!userAlerts.find((a) => a.id === AlertReport)}
            onChange={(e) =>
              onChange(
                'rapport',
                AlertAssetNotCummunicate,
                null,
                e.target.checked,
                userAlerts.find((a) => a.id === AlertAssetNotCummunicate)?.pivot
                  .parameter || '15',
              )
            }
            labelSwichtTitle={t('alert:report.alertAssetNotCummunicate')}
            className="switchInput styleLabelInput"
          />
          <TextField
            disabled={!userAlerts.find((a) => a.id === AlertReport)}
            onChange={(value) =>
              onChange('rapport', AlertAssetNotCummunicate, null, true, value)
            }
            type="number"
            value={
              userAlerts.find((a) => a.id === AlertAssetNotCummunicate)?.pivot
                .parameter || ''
            }
          />
        </div>
      </PanelContainer>
    </Panel>
  );
};

export { PanelAlertReport };
