import {
  buildEmptyGeoJson,
  getBounds,
  mapAssetsToGeoJson,
  mapGeofence,
  mapShippingOrdersToGeoJson,
  mapSingleSiteToGeoJson,
  mapSitesToGeoJson,
} from '../../components/molecules/LocationMap/utils';

import * as Actions from './actions';

import {
  addGeoJsonAssets,
  addGeoJsonSites,
  computeAssetsFeaturesOnCloseAssetPanel,
  computeAssetsFeaturesOnOpenAssetPanel,
  computeSitesFeaturesOnOpenSitePanel,
} from './utils';

function mapReducer(state, action) {
  switch (action.type) {
    case Actions.PUSH_TO_ALL_SITES_ACTION:
      return {
        ...state,
        allSites: [...state.allSites, ...action.payload.sites],
        geoJsonSites: addGeoJsonSites(state, action.payload.sites),
        sitesRenderKey: state.sitesRenderKey + 1,
      };
    case Actions.PUSH_TO_ALL_ASSETS_ACTION: {
      const withoutAssetGroup = action.payload.assets?.filter(
        (a) => null === a.group,
      );

      return {
        ...state,
        allAssets: [...state.allAssets, ...action.payload.assets],
        assetsWithoutAssetGroup: [
          ...state.assetsWithoutAssetGroup,
          ...withoutAssetGroup,
        ],
        geoJsonAssets: addGeoJsonAssets(state, withoutAssetGroup),
        assetsRenderKey: state.assetsRenderKey + 1,
      };
    }
    case Actions.SET_ALL_SHIPPING_ORDERS_ACTION:
      return {
        ...state,
        allShippingOrders: action.payload.shippingOrders || [],
        geoJsonShippingOrders: mapShippingOrdersToGeoJson(
          action.payload.shippingOrders,
        ),
        shippingOrdersRenderKey: state.shippingOrdersRenderKey + 1,
      };

    case Actions.SET_GEOJSON_ASSETS_ACTION:
      return {
        ...state,
        geoJsonAssets: mapAssetsToGeoJson(action.payload.assets),
        assetsRenderKey: state.assetsRenderKey + 1,
      };
    case Actions.SET_GEOJSON_SITES_ACTION:
      return {
        ...state,
        geoJsonSites: mapSitesToGeoJson(action.payload.sites),
        sitesRenderKey: state.sitesRenderKey + 1,
      };
    case Actions.SET_GEOJSON_SHIPPING_ORDERS_ACTION:
      return {
        ...state,
        geoJsonShippingOrders: mapShippingOrdersToGeoJson(
          action.payload.shippingOrders,
        ),
        shippingOrdersRenderKey: state.shippingOrdersRenderKey + 1,
      };

    case Actions.SET_BOUNDS_ACTION:
      return {
        ...state,
        bounds: getBounds(
          action.payload.sites,
          action.payload.assets,
          action.payload.shippingOrders,
        ),
        positionForced: true,
      };
    case Actions.SET_CENTER_ACTION:
      return {
        ...state,
        center: action.payload.center,
        positionForced: true,
      };
    case Actions.SET_CENTER_ZOOM_ACTION:
      return {
        ...state,
        center: action.payload.center,
        zoom: action.payload.zoom,
      };
    case Actions.SET_ASSET_POSITIONS_ACTION:
      return {
        ...state,
        assetPositions: action.payload.assetPositions,
        bounds: action.payload.assetPositions?.allPoints
          ? action.payload.assetPositions.allPoints.map(
              ({ latitude, longitude }) => [latitude, longitude],
            )
          : state.bounds,
      };
    case Actions.SET_CURRENT_PANEL_ACTION:
      return { ...state, currentPanel: action.payload };
    case Actions.SET_LOCATION_FROM_URL_ACTION:
      return {
        ...state,
        center: action.payload.center,
        zoom: action.payload?.zoom || state.zoom,
        positionForced: true,
      };

    case Actions.SET_SHOW_GEOFENCE_ACTION:
      return { ...state, showGeofence: action.payload.showGeofence };
    case Actions.SET_SHOW_ZONES_ACTION:
      return { ...state, showZones: action.payload.showZones };

    case Actions.RESET_GEOJSON_ACTION:
      return {
        ...state,
        geoJsonAssets: mapAssetsToGeoJson(state.assetsWithoutAssetGroup),
        geoJsonSites: mapSitesToGeoJson(state.allSites),
        geoJsonShippingOrders: mapShippingOrdersToGeoJson(
          state.allShippingOrders,
        ),
        assetsRenderKey: state.assetsRenderKey + 1,
        sitesRenderKey: state.sitesRenderKey + 1,
        shippingOrdersRenderKey: state.shippingOrdersRenderKey + 1,
      };

    case Actions.TOUCH_MAP_ACTION:
      return { ...state, mapTouched: true };

    case Actions.OPEN_ASSET_PANEL_ACTION: {
      const asset = action.payload.asset;

      return {
        ...state,
        assetLocation: {
          latitude: asset.mag?.last_accepted_coordinates.latitude,
          longitude: asset.mag?.last_accepted_coordinates.longitude,
          device_timestamp: asset.mag?.last_accepted_device_timestamp,
        },
        geoJsonAssets: {
          ...state.geoJsonAssets,
          features: computeAssetsFeaturesOnOpenAssetPanel(state, asset),
        },
        assetsRenderKey: state.assetsRenderKey + 1,
        currentPanel: 'ASSET',
        currentAsset: asset,
      };
    }
    case Actions.CLOSE_ASSET_PANEL_ACTION:
      return {
        ...state,
        assetLocation: null,
        assetPositions: null,
        geoJsonAssets: {
          ...state.geoJsonAssets,
          features: computeAssetsFeaturesOnCloseAssetPanel(state),
        },
        assetsRenderKey: state.assetsRenderKey + 1,
        currentAsset: null,
      };

    case Actions.OPEN_SITE_PANEL_ACTION: {
      const site = action.payload.site;

      return {
        ...state,
        geofence: site?.geofence ? mapGeofence(site.geofence) : null,
        zones: site?.zones.map((z) => mapGeofence(z.geofence, z.name)),
        siteLocation:
          site.coordinates.latitude && site.coordinates.longitude
            ? site.coordinates
            : null,
        geoJsonSites: {
          ...state.geoJsonSites,
          features: computeSitesFeaturesOnOpenSitePanel(state, site),
        },
        currentSite: site,
        sitesRenderKey: state.sitesRenderKey + 1,
        currentPanel: 'SITE',
      };
    }
    case Actions.CLOSE_SITE_PANEL_ACTION: {
      const features = [...state.geoJsonSites.features];

      if (state.currentSite && 'OFF_SITE' !== state.currentSite.type.name) {
        features.push(mapSingleSiteToGeoJson(state.currentSite));
      }

      return {
        ...state,
        geofence: null,
        zones: null,
        showGeofence: true,
        showZones: false,
        siteLocation: null,
        geoJsonSites: {
          ...state.geoJsonSites,
          features,
        },
        sitesRenderKey: state.sitesRenderKey + 1,
        currentSite: null,
      };
    }

    /** @TODO Remove those actions from here */
    case Actions.RESET_FILTERS:
      return {
        ...state,
        filtersTouched: true,
        siteTypesFilter: action.payload.siteTypesFilter,
        assetTypesFilter: action.payload.assetTypesFilter,
        assetStatesFilter: action.payload.assetStatesFilter,
        branchesFilter: action.payload.branchesFilter,
        stationarySinceFilter: 0,
        offsiteFilter: false,
        shippingOrdersOnlyFilter: false,
        selectedGraphqlFilter: null,
      };
    case Actions.SET_ASSET_TYPES_FILTER:
      return {
        ...state,
        assetTypesFilter: action.payload,
        filtersTouched: true,
      };
    case Actions.SET_SITE_TYPES_FILTER:
      return {
        ...state,
        siteTypesFilter: action.payload,
        filtersTouched: true,
      };
    case Actions.SET_ASSET_STATES_FILTER:
      return {
        ...state,
        assetStatesFilter: action.payload,
        filtersTouched: true,
      };
    case Actions.SET_BRANCHES_FILTER:
      return {
        ...state,
        branchesFilter: action.payload,
        filtersTouched: true,
      };
    case Actions.SET_STATIONARY_SINCE_FILTER:
      return {
        ...state,
        stationarySinceFilter: action.payload,
        filtersTouched: true,
      };
    case Actions.SET_OFFSITE_FILTER:
      return {
        ...state,
        offsiteFilter: action.payload,
        filtersTouched: true,
      };
    case Actions.SET_SHIPPING_ORDERS_ONLY_FILTER:
      return {
        ...state,
        shippingOrdersOnlyFilter: action.payload,
        filtersTouched: true,
      };
    case Actions.SET_NEW_FILTER:
      return {
        ...state,
        newGraphqlFilter: action.payload,
      };
    case Actions.SET_SELECTED_FILTER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

const MAP_INITIAL_STATE = {
  allSites: [],
  allAssets: [],
  assetsWithoutAssetGroup: [],
  allShippingOrders: [],

  geoJsonAssets: buildEmptyGeoJson(),
  geoJsonSites: buildEmptyGeoJson(),
  geoJsonShippingOrders: buildEmptyGeoJson(),

  assetsRenderKey: 0,
  sitesRenderKey: 0,
  shippingOrdersRenderKey: 0,
  mapTouched: false,
  positionForced: false,

  bounds: [],
  center: [48.8402658, 2.3368785],
  zoom: 12,

  geofence: null,
  zones: [],

  showGeofence: true,
  showZones: false,

  assetLocation: null,
  siteLocation: null,

  assetPositions: null,

  currentPanel: null,
  currentSite: null,
  currentAsset: null,

  errorRadius: null,

  filtersTouched: false,
  assetTypesFilter: [],
  siteTypesFilter: [],
  assetStatesFilter: [],
  branchesFilter: [],
  stationarySinceFilter: 0,
  offsiteFilter: false,
  shippingOrdersOnlyFilter: false,
  newGraphqlFilter: { name: '', default_view: false },
  selectedGraphqlFilter: null,
};

export { mapReducer, MAP_INITIAL_STATE };
