import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PanelSection } from 'front-library';

import { EditableField } from '../EditableField/EditableField';

const MainContainer = styled.div`
  padding-bottom: 15px;
`;

const CustomParameterContainer = styled.div`
  margin-top: 15px;
`;

const CustomParametersSection = ({ customParametersValues }) => {
  const { t } = useTranslation();

  return (
    <PanelSection
      title={t('customParameters:customParameters')}
      className="standard-panel-section"
    >
      <MainContainer>
        {customParametersValues.map((parameter) => {
          return (
            <CustomParameterContainer key={parameter.id}>
              {parameter.value && (
                <EditableField
                  label={parameter.name}
                  type={parameter.value_type}
                  value={parameter.value}
                  description={parameter.description}
                />
              )}
            </CustomParameterContainer>
          );
        })}
      </MainContainer>
    </PanelSection>
  );
};

export { CustomParametersSection };
