const PUSH_TO_ALL_SITES_ACTION = 'PUSH_TO_ALL_SITES_ACTION';
const PUSH_TO_ALL_ASSETS_ACTION = 'PUSH_TO_ALL_ASSETS_ACTION';
const SET_ALL_SHIPPING_ORDERS_ACTION = 'SET_ALL_SHIPPING_ORDERS_ACTION';

const RESET_GEOJSON_ACTION = 'RESET_GEOJSON_ACTION';
const SET_GEOJSON_ASSETS_ACTION = 'SET_GEOJSON_ASSETS_ACTION';
const SET_GEOJSON_SITES_ACTION = 'SET_GEOJSON_SITES_ACTION';
const SET_GEOJSON_SHIPPING_ORDERS_ACTION = 'SET_GEOJSON_SHIPPING_ORDERS_ACTION';

const SET_BOUNDS_ACTION = 'SET_BOUNDS_ACTION';
const SET_CENTER_ACTION = 'SET_CENTER_ACTION';
const SET_CENTER_ZOOM_ACTION = 'SET_CENTER_ZOOM_ACTION';
const SET_ASSET_POSITIONS_ACTION = 'SET_ASSET_POSITIONS_ACTION';
const SET_CURRENT_PANEL_ACTION = 'SET_CURRENT_PANEL_ACTION';
const SET_LOCATION_FROM_URL_ACTION = 'SET_LOCATION_FROM_URL_ACTION';

const SET_SHOW_GEOFENCE_ACTION = 'SHOW_GEOFENCE_ACTION';
const SET_SHOW_ZONES_ACTION = 'SHOW_ZONES_ACTION';

const TOUCH_MAP_ACTION = 'TOUCH_MAP_ACTION';

const OPEN_ASSET_PANEL_ACTION = 'OPEN_ASSET_PANEL_ACTION';
const CLOSE_ASSET_PANEL_ACTION = 'CLOSE_ASSET_PANEL_ACTION';

const OPEN_SITE_PANEL_ACTION = 'OPEN_SITE_PANEL_ACTION';
const CLOSE_SITE_PANEL_ACTION = 'CLOSE_SITE_PANEL_ACTION';

/**
 * @TODO this should be in an other reducer, there is too much things here now.
 */
const RESET_FILTERS = 'RESET_FILTERS';
const SET_SHOW_FILTERS = 'SET_SHOW_FILTERS';
const SET_SITE_TYPES_FILTER = 'SET_SITE_TYPES_FILTER';
const SET_ASSET_TYPES_FILTER = 'SET_ASSET_TYPES_FILTER';
const SET_ASSET_STATES_FILTER = 'SET_ASSET_STATES_FILTER';
const SET_BRANCHES_FILTER = 'SET_BRANCHES_FILTER';
const SET_STATIONARY_SINCE_FILTER = 'SET_STATIONARY_SINCE_FILTER';
const SET_OFFSITE_FILTER = 'SET_OFFSITE_FILTER';
const SET_SHIPPING_ORDERS_ONLY_FILTER = 'SET_SHIPPING_ORDERS_ONLY_FILTER';
const SET_NEW_FILTER = 'SET_NEW_FILTER';
const SET_SAVED_NEW_FILTER = 'SET_SAVED_NEW_FILTER';
const SET_SELECTED_FILTER = 'SET_SELECTED_FILTER';

export {
  PUSH_TO_ALL_SITES_ACTION,
  PUSH_TO_ALL_ASSETS_ACTION,
  SET_ALL_SHIPPING_ORDERS_ACTION,
  RESET_GEOJSON_ACTION,
  SET_GEOJSON_ASSETS_ACTION,
  SET_GEOJSON_SITES_ACTION,
  SET_GEOJSON_SHIPPING_ORDERS_ACTION,
  SET_BOUNDS_ACTION,
  SET_CENTER_ACTION,
  SET_CENTER_ZOOM_ACTION,
  SET_ASSET_POSITIONS_ACTION,
  SET_CURRENT_PANEL_ACTION,
  SET_LOCATION_FROM_URL_ACTION,
  SET_SHOW_GEOFENCE_ACTION,
  SET_SHOW_ZONES_ACTION,
  TOUCH_MAP_ACTION,
  OPEN_ASSET_PANEL_ACTION,
  CLOSE_ASSET_PANEL_ACTION,
  OPEN_SITE_PANEL_ACTION,
  CLOSE_SITE_PANEL_ACTION,
  RESET_FILTERS,
  SET_SHOW_FILTERS,
  SET_SITE_TYPES_FILTER,
  SET_ASSET_TYPES_FILTER,
  SET_ASSET_STATES_FILTER,
  SET_BRANCHES_FILTER,
  SET_STATIONARY_SINCE_FILTER,
  SET_OFFSITE_FILTER,
  SET_SHIPPING_ORDERS_ONLY_FILTER,
  SET_NEW_FILTER,
  SET_SAVED_NEW_FILTER,
  SET_SELECTED_FILTER,
};
