import { useMemo } from 'react';
import { fetchSiteCollection } from '../../http/sitesRepository';
import { useBatchHttpQueryCollection } from '../useBatchHttpQueryCollection';

function useCompanySites(companyId) {
  const queryParams = useMemo(() => ({ company_id: companyId }), [companyId]);

  return useBatchHttpQueryCollection(fetchSiteCollection, queryParams);
}

export { useCompanySites };
