import { useEffect, useState } from 'react';

function useHttpQuery(fetcher, params) {
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      setContent(null);

      if (ignore) {
        return;
      }

      const { content: responseContent } = await fetcher(params, controller);

      setContent(responseContent);
      setIsLoading(false);
    }

    const controller = new AbortController();
    let ignore = false;
    fetchData();

    return () => {
      ignore = true;
      controller.abort('Aborted due to page/view change.');
    };
  }, [fetcher, params]);

  return { isLoading, content };
}

export { useHttpQuery };
