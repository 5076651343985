export function mapCustomParameterToFormValues(customParameter = null) {
  return {
    id: customParameter?.id || null,
    assetTypes:
      customParameter?.asset_types_filters?.data?.map(({ id, name }) => ({
        title: name,
        value: id,
      })) || [],
    model: customParameter?.model || '',
    name: customParameter?.name || '',
    description: customParameter?.description || '',
    operatorEditable: customParameter?.operator_editable || false,
    valueType: customParameter?.value_type || '',
  };
}

export function mapCustomParameterFormValuesToGraphql({
  id,
  name,
  description,
  valueType,
  model,
  operatorEditable,
  assetTypes,
}) {
  return {
    ...(id && { id }),
    name,
    description,
    value_type: valueType,
    model,
    operator_editable: operatorEditable,
    asset_types_filters: {
      sync: assetTypes.map(({ value }) => value),
    },
  };
}
