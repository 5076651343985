import { useMemo } from 'react';
import { fetchSiteAssetTypesCollection } from '../../http/sitesRepository';
import { useHttpQuery } from '../useHttpQuery';

function useSiteAssetTypes(siteId, user) {
  const companyId = user.company.id;
  const params = useMemo(
    () => ({ id: siteId, queryParams: { company_id: companyId } }),
    [siteId, companyId],
  );

  return useHttpQuery(fetchSiteAssetTypesCollection, params);
}

export { useSiteAssetTypes };
