import L from 'leaflet';
import React, { useRef, useCallback, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import styled from 'styled-components';

import { ScrollableContainer } from 'front-library';

import { ErrorRadius } from '../ErrorRadius';
import { icons } from '../icons';

const Timestamps = styled.div`
  div {
    width: 100%;
    padding: 5px;
    cursor: default;
  };

  div:not(:last-child) {
    border-bottom: 1px solid #B3B3B3;
  };
`;

const PopupOffset = new L.Point(0, 0);

/**
 * Show marker on map
 * @param {Array<string>} coords Latitude and longitude
 * @param {string} popUpTitle Popup title
 * @param {Array<string>} popUpTimestamps Popup timestamps
 * @param {boolean} isOpen Used to know if popup is open or not
 * @param {Array<number>} boldDates Indexes of dates that should be displayed in bold
 * @param {function} handleMouseOver Function to execute when mouse marker
 * @param {function} handleMouseOut Function to execute when mouse go outside marker
 * @param {function} handleClose Function to execute when closing popup
 * @param {function} handleMouseOverPopup Function to execute when mouse enter popup
 * @param {function} handleMouseOutPopup Function to execute when mouse go outside popup
 * @param {function} handleDateOver Function to execute when a date is over in a popup
 * @param {function} handleDatesOut Function to execute when mouse go out dates array in popup
 */
const AssetMarker = ({
  accuracy,
  notPrecise,
  coords,
  popUpTitle,
  popUpTimestamps,
  isOpen,
  boldDates,
  handleMouseOver,
  handleMouseOut,
  handleClose,
  handleMouseOverPopup,
  handleMouseOutPopup,
  handleDateOver,
  handleDatesOut,
  canBeOverMarker,
}) => {
  const markerRef = useRef(null);
  const popUpRef = useRef(null);

  const [showErrorRadius, setShowErrorRadius] = useState(false);

  /**
   * Thanks to useCallback we define the function once, not at all rendering
   */
  const mouseOver = useCallback(
    (e) => {
      if (notPrecise) {
        setShowErrorRadius(true);
      }

      if (!canBeOverMarker) {
        return;
      }

      if (e?.target?._popup?._wrapper?.addEventListener) {
        e.target._popup._wrapper.addEventListener(
          'mouseover',
          handleMouseOverPopup,
        );
        e.target._popup._wrapper.addEventListener(
          'mouseout',
          handleMouseOutPopup,
        );
      }

      e.target.openPopup();
      handleMouseOver?.();
    },
    [
      canBeOverMarker,
      handleMouseOutPopup,
      handleMouseOver,
      handleMouseOverPopup,
      notPrecise,
    ],
  );

  const mouseOut = useCallback(() => {
    setShowErrorRadius(false);
    handleMouseOut?.();
  }, [handleMouseOut]);

  if (isOpen && markerRef?.current?.leafletElement?.openPopup) {
    markerRef.current.leafletElement.openPopup();
  } else if (!isOpen && markerRef?.current?.leafletElement?.closePopup) {
    markerRef.current.leafletElement.closePopup();
  }

  return (
    <>
      <Marker
        position={coords}
        icon={notPrecise ? icons.assetMagDataNotPrecise : icons.assetMagData}
        pane="shadowPane"
      />
      <Marker
        position={coords}
        icon={icons.assetMagData}
        opacity={0}
        onMouseOver={mouseOver}
        onMouseOut={mouseOut}
        ref={markerRef}
      >
        {(popUpTitle || popUpTimestamps) && (
          <Popup
            autoClose={false}
            autoPan={false}
            onClose={handleClose}
            offset={PopupOffset}
            pane="markerPane"
            ref={popUpRef}
          >
            {popUpTitle}
            <ScrollableContainer
              maxHeight="150px"
              onMouseOver={handleMouseOverPopup}
            >
              <Timestamps onMouseOut={handleDatesOut}>
                {[...popUpTimestamps].reverse().map((timestamp, i) => (
                  <div
                    key={timestamp}
                    onMouseOver={() => {
                      if (popUpTimestamps.length > 1) {
                        handleDateOver(i);
                      }
                    }}
                    onFocus={() => {
                      if (popUpTimestamps.length > 1) {
                        handleDateOver(i);
                      }
                    }}
                    style={{
                      fontWeight: boldDates.includes(i) ? 'bold' : 'normal',
                    }}
                  >
                    {timestamp}
                  </div>
                ))}
              </Timestamps>
            </ScrollableContainer>
          </Popup>
        )}
      </Marker>
      {showErrorRadius && <ErrorRadius coords={coords} accuracy={accuracy} />}
    </>
  );
};

export { AssetMarker };
