import styled from 'styled-components';

import { Button, TextField } from 'front-library';

import { TitleRemove } from '../../../../atoms/TitleRemove/TitleRemove';

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 5px 10px 10px 10px;
    border: 1px solid #ef9d11;
    border-radius: 5px;
`;

export const AddButton = styled(Button)`
    > img {
        height: 15px;
    }
`;

export const CheckpointIconContainer = styled.div`
    display: flex;
    gap: 10px;
`;

export const FlexTitleRemove = styled(TitleRemove)`
    align-items: center;
    flex: 1;
`;

export const Icon = styled.img`
    width: ${({ width }) => (width ? `${width}px` : '16px')};
    margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}px` : 0)};
    margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : 0)};
    
    :hover {
        cursor: ${({ cursor }) => (cursor ? cursor : 'initial')};
    }
`;

export const ClickableIcon = styled(Icon)`
    align-self: center;
    transform: ${({ rotate }) =>
      rotate ? `rotate(${rotate}deg)` : 'rotate(0deg)'};

    :hover {
        cursor: pointer;
    }
`;

export const HelpText = styled.span`
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '0.5em')};
    color: ${({ color }) => color};
`;

export const Title = styled.div`
    display: flex;
    align-items: baseline;
    font-size: 18px;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '18px')};

    > span {
        margin-left: 3px;
    }
`;

export const TextArea = styled(TextField)`
    textarea {
        height: auto;
    }
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 0)};
`;
