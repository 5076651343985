import dayjs from 'dayjs';

const GRAPHQL_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export function mapFormValuesToGraphQl({
  deliveryReference,
  deliveryModel,
  departureSite,
  departureDate,
  arrivalSite,
  arrivalDate,
  checkpoints,
  orders,
  carrierReference,
  carrierName,
  comment,
}) {
  return {
    reference: deliveryReference.trim(),
    model_id: deliveryModel?.value ? +deliveryModel.value : null,
    departure_site: { connect: departureSite.value },
    departure_timestamp: departureDate.format(GRAPHQL_DATE_FORMAT),
    arrival_site: { connect: arrivalSite.value },
    arrival_timestamp: arrivalDate?.format(GRAPHQL_DATE_FORMAT),
    checkpoints: checkpoints.map((checkpoint) => ({
      site_id: checkpoint.site?.value ? +checkpoint.site.value : null,
      arrival_timestamp:
        checkpoint.checkpointArrivalDate.format(GRAPHQL_DATE_FORMAT),
      departure_timestamp:
        checkpoint.checkpointDepartureDate.format(GRAPHQL_DATE_FORMAT),
    })),
    orders: orders.map((order) => ({
      reference: order.reference,
      asset_id: order.asset?.value ? +order.asset.value : null,
    })),
    carrier: {
      reference: carrierReference.trim(),
      name: carrierName.trim(),
    },
    // weight: Number.parseInt(weight),
    comment: comment,
  };
}

export const range = (start, end) => {
  const result = [];

  for (let i = start; i < end; i++) {
    result.push(i);
  }

  return result;
};

export const isInvalidArrivalDate = (current, minDate) =>
  current?.isBefore(minDate.subtract(1, 'day'));

export const isInvalidDepartureDate = (current, from) =>
  current?.isBefore(from.subtract(1, 'day'));

const disableTimeBeforeDate = (current, targetDate) => {
  const currentHasSameDayAsTarget =
    current.startOf('day') <= targetDate.startOf('day');
  const currentHasSameHourAsTarget =
    currentHasSameDayAsTarget && current.hour() <= targetDate.hour();

  const hours = currentHasSameDayAsTarget ? targetDate.hour() : [];
  const minutes = currentHasSameHourAsTarget ? targetDate.minute() : [];

  return {
    disabledHours: () => range(0, hours),
    disabledMinutes: () => range(0, minutes),
  };
};

export const arrivalDisabledTime = (current, minDate) => {
  let currentDate = current;

  const now = dayjs();
  if (now.startOf('minute').isSame(current.startOf('minute'))) {
    currentDate = minDate;
  }

  return disableTimeBeforeDate(currentDate, minDate);
};

export const checkpointDisabledTime = (current, type, from, minDate) => {
  let currentDate = current;

  const now = dayjs();
  if (now.startOf('minute').isSame(current.startOf('minute'))) {
    currentDate = minDate;
  }

  if (type === 'end' && from) {
    return disableTimeBeforeDate(currentDate, from);
  }

  return disableTimeBeforeDate(currentDate, minDate);
};
