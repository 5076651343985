import React from 'react';
import { useTranslation } from 'react-i18next';

import { DateRangePicker, ErrorMessage, TextField } from 'front-library';

import {
  BasicInfosStepContainer,
  GridContainer,
  Title,
} from './styled-components';

const BasicInfosStep = ({ formData, updateField }) => {
  const { t, i18n } = useTranslation();

  return (
    <BasicInfosStepContainer>
      <TextField
        label={t('flux:form.name')}
        onChange={(name) => updateField({ name })}
        placeholder={t('flux:form.configNamePlaceholder')}
        required={true}
        value={formData.name}
      />
      <Title>{t('flux:form.period')}</Title>

      <DateRangePicker
        locale={i18n.language}
        onChange={([fromDate, toDate]) => {
          updateField({
            periodFrom: fromDate,
            periodTo: toDate,
          });
        }}
        placeholder={[t('flux:form.from'), t('flux:form.to')]}
        defaultValue={[formData.periodFrom, formData.periodTo]}
        required={true}
      />
      {formData.errors['input.period_from'] && (
        <ErrorMessage
          errorMessage={formData.errors['input.period_from'][0].replace(
            /input\.[^ ]+/,
            t('flux:form.from'),
          )}
        />
      )}
      {formData.errors['input.period_to'] && (
        <ErrorMessage
          errorMessage={formData.errors['input.period_to'][0]
            .replace(/input\.[^ ]+/, t('flux:form.to'))
            .replace('input.period_from', t('flux:form.from'))}
        />
      )}
    </BasicInfosStepContainer>
  );
};

export { BasicInfosStep };
