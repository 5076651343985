import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorMessage, Select, TextField } from 'front-library';

import { GraySeparator } from 'front-library';
import { getErrorText } from '../../../helpers';
import { Form } from '../../atoms/Form/Form';
import { Autocomplete } from '../../molecules/Autocomplete/Autocomplete';
import { GET_COMPANIES } from '../../molecules/Autocomplete/graphql';

const MagForm = ({
  formData,
  formValues,
  setFormValues,
  errors,
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const states = useMemo(
    () => [
      { value: 'OPERATIONAL', title: t('mags:form.state.OPERATIONAL') },
      { value: 'OUT_OF_SERVICE', title: t('mags:form.state.OUT_OF_SERVICE') },
      { value: 'UNKNOWN', title: t('mags:form.state.UNKNOWN') },
    ],
    [t],
  );

  const bleTypes = useMemo(
    () => [
      { value: 'none', title: t('mags:form.none') },
      { value: 'beacon', title: t('mags:form.beacon') },
      { value: 'gateway', title: t('mags:form.gateway') },
    ],
    [t],
  );

  const magTypes = useMemo(
    () =>
      formData.mag_types.data.map((type) => ({
        value: type.id,
        title: `${type.provider.name} - ${type.name}${
          type.characteristics.length > 0 &&
          type.characteristics[0]?.pivot?.parameter
            ? ` - ${type.characteristics[0].pivot.parameter}`
            : ''
        }`,
      })),
    [formData.mag_types.data],
  );

  const canSubmit =
    formValues.magId &&
    formValues.company &&
    formValues.magType &&
    formValues.providerDeviceId &&
    formValues.networkDeviceId;

  return (
    <Form disableSubmit={!canSubmit} onCancel={onCancel} onSubmit={onSubmit}>
      {/* Mag id */}
      <TextField
        errorText={getErrorText(errors, 'mag_id', t('mags:form.magId'))}
        label={t('mags:form.magId')}
        onChange={(magId) => setFormValues({ ...formValues, magId })}
        required={true}
        value={formValues.magId || ''}
      />

      {/* Company */}
      <Autocomplete
        errorText={getErrorText(
          errors,
          'company.connect',
          t('mags:form.company'),
        )}
        label={t('mags:form.company')}
        query={GET_COMPANIES}
        value={formValues.company || null}
        onChange={(company) => setFormValues({ ...formValues, company })}
        required={true}
      />

      {/* Owner */}
      <Autocomplete
        label={t('mags:form.owner')}
        query={GET_COMPANIES}
        value={formValues.owner || null}
        onChange={(owner) => setFormValues({ ...formValues, owner })}
      />

      {/* Mag type */}
      <Select
        errorText={getErrorText(
          errors,
          'mag_type.connect',
          t('mags:form.magType'),
        )}
        label={t('mags:form.magType')}
        options={magTypes}
        value={formValues.magType || ''}
        onChange={(magType) => setFormValues({ ...formValues, magType })}
        required={true}
      />

      {/* Provider id */}
      <TextField
        errorText={getErrorText(
          errors,
          'provider_device_id',
          t('mags:form.idProvider'),
        )}
        label={t('mags:form.idProvider')}
        onChange={(providerDeviceId) =>
          setFormValues({ ...formValues, providerDeviceId })
        }
        required={true}
        value={formValues.providerDeviceId || ''}
      />

      {/* Operator id */}
      <TextField
        errorText={getErrorText(
          errors,
          'network_device_id',
          t('mags:form.idOperator'),
        )}
        label={t('mags:form.idOperator')}
        onChange={(networkDeviceId) =>
          setFormValues({ ...formValues, networkDeviceId })
        }
        required={true}
        value={formValues.networkDeviceId || ''}
      />

      {/* State */}
      <Select
        label={t('mags:form.state.title')}
        options={states}
        value={formValues.state || ''}
        onChange={(state) => setFormValues({ ...formValues, state })}
      />

      {/* Network operators */}
      <Select
        label={t('mags:form.operator')}
        options={formData.network_operators.data}
        value={formValues.networkOperators || []}
        onChange={(networkOperators) =>
          setFormValues({ ...formValues, networkOperators })
        }
        multiple={true}
      />

      <GraySeparator $borderStyle="dashed" />

      {/* BLE */}
      <h3>BLE</h3>
      {/* Type */}
      <Select
        label={t('mags:form.type')}
        options={bleTypes}
        value={formValues.ble}
        onChange={(ble) =>
          setFormValues({ ...formValues, ble, isGateway: 'gateway' === ble })
        }
      />
      {/* Minor */}
      <TextField
        type="number"
        label={t('mags:form.minor')}
        onChange={(minor) =>
          setFormValues({
            ...formValues,
            beacon: { ...formValues.beacon, minor },
          })
        }
        value={formValues.beacon?.minor || ''}
      />
      {/* Major */}
      <TextField
        type="number"
        label={t('mags:form.major')}
        onChange={(major) =>
          setFormValues({
            ...formValues,
            beacon: { ...formValues.beacon, major },
          })
        }
        value={formValues.beacon?.major || ''}
      />
      {/* MAC addresse */}
      <TextField
        label={t('mags:form.macAddress')}
        onChange={(macAddress) =>
          setFormValues({
            ...formValues,
            beacon: { ...formValues.beacon, macAddress },
          })
        }
        value={formValues.beacon?.macAddress || ''}
      />
      {errors['input.beacon'] && (
        <ErrorMessage errorMessage={errors['input.beacon'][0]} />
      )}
      {errors['input.id'] && (
        <ErrorMessage errorMessage={errors['input.id'][0]} />
      )}
    </Form>
  );
};

MagForm.propTypes = {
  errors: PropTypes.object,
  formData: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { MagForm };
