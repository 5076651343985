import { BtnInATab } from 'front-library';
import RemoveIcon from 'front-library/build/img/icon-remove-red.svg';
import React from 'react';
import styled from 'styled-components';

const ConditionTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const TitleRemove = ({ onClick, children, className }) => {
  return (
    <ConditionTitleContainer className={className}>
      <span>{children}</span>
      <BtnInATab
        className="btn-remove-special"
        imgSrc={RemoveIcon}
        onClick={onClick}
      />
    </ConditionTitleContainer>
  );
};

export { TitleRemove };
