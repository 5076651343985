import { gql } from '@apollo/client';

const COUNT_SHIPPING_ORDERS_BY_REFERENCE = gql`
    query CountShippingOrdersByReference($reference: Mixed!) {
        shipping_orders(
            where: {
                column: "reference"
                operator: EQ
                value: $reference
            }
        ) {
            paginatorInfo {
                count
            }
        }
    }
`;

const CREATE_SHIPPING_ORDER = gql`
    mutation CreateShippingOrderWithCheckpoints(
        $reference: String!
        $model_id: Int
        $departure_site: UpdateRelation
        $departure_timestamp: DateTime!
        $arrival_site: UpdateRelation
        $arrival_timestamp: DateTime
        $checkpoints: [CheckpointInput]
        $orders: [OrderInput!]
        $carrier: CarrierInput
        $weight: Int
        $comment: String
    ) {
        createShippingOrderWithCheckpoints(
            input: {
                reference: $reference
                model_id: $model_id
                departure_site: $departure_site
                departure_timestamp: $departure_timestamp
                arrival_site: $arrival_site
                arrival_timestamp: $arrival_timestamp
                checkpoints: $checkpoints
                orders: $orders
                carrier: $carrier
                weight: $weight
                comment: $comment
            }
        )
    }
`;

const GET_ASSETS_WITHOUT_ASSET_GROUP = gql`
    query GetAssetsWithoutAssetGroup($search: Mixed, $alreadyUsed: Mixed) {
        data: assets(
            first: 20,
            where: {
                AND: [
                    {
                        column: "id"
                        operator: NOT_IN
                        value: $alreadyUsed
                    },
                    {
                        column: "reference"
                        operator: LIKE
                        value: $search
                    },
                    {
                        column: "asset_group_id"
                        operator: IS_NULL
                    },
                    {
                        column: "mag_id"
                        operator: IS_NOT_NULL
                    },
                    {
                        column: "mag_id"
                        operator: NEQ
                        value: 0
                    }
                ]
            }
        ) {
            data {
                value: id
                title: reference
            }
        }
    }
`;

export {
  COUNT_SHIPPING_ORDERS_BY_REFERENCE,
  CREATE_SHIPPING_ORDER,
  GET_ASSETS_WITHOUT_ASSET_GROUP,
};
