import L from 'leaflet';
import { MapControl, withLeaflet } from 'react-leaflet';
import { setupMeasure } from './leaflet.measure';

setupMeasure(L);

class MeasureControlLayer extends MapControl {
  createLeafletElement(props) {
    return L.control.measure(props);
  }
}

const MeasureControl = withLeaflet(MeasureControlLayer);

export { MeasureControl };
