import React from 'react';

import { Label, TitleIcon } from 'front-library';
import CalendarIcon from 'front-library/build/img/icon-calendar-green.svg';
import AnnotationIcon from 'front-library/build/img/icon-commentary-grey.svg';
import NutIcon from 'front-library/build/img/icon-nut-green.svg';
import RouteIcon from 'front-library/build/img/icon-route-orange.svg';

import {
  GridContainer,
  ReviewDateDisplay,
  StepTitle,
  SummaryAnnotationsContainer,
  SummaryAnnotationsTitle,
  SummaryBasicInfosContainer,
  SummaryPeriodTitle,
  SummaryRouteContainer,
  SummaryRouteTitle,
  SummaryStepContainer,
} from './styled-components';

import { SiteDisplay } from './SiteDisplay';

const SummaryStep = ({ t, formData }) => {
  return (
    <SummaryStepContainer>
      <StepTitle>{t('flux:form.summary')}</StepTitle>
      <SummaryBasicInfosContainer>
        <TitleIcon text={t('flux:form.name')} img={NutIcon} />
        <p>{formData.name}</p>
        <SummaryPeriodTitle text={t('flux:form.period')} img={CalendarIcon} />
        <GridContainer>
          <div>
            <Label>{t('flux:form.from')}</Label>
            <p>{formData?.periodFrom?.format('YYYY-MM-DD')}</p>
          </div>
          <div>
            <Label>{t('flux:form.to')}</Label>
            <p>{formData?.periodTo?.format('YYYY-MM-DD')}</p>
          </div>
        </GridContainer>
      </SummaryBasicInfosContainer>
      <SummaryRouteContainer>
        <SummaryRouteTitle
          text={t('flux:form.route')}
          img={RouteIcon}
          fontSize="20px"
        />
        {formData.fluxConfigurationSites.map((fcs, index) => (
          <SiteDisplay
            key={fcs.uniqId}
            t={t}
            index={index}
            isLast={formData.fluxConfigurationSites.length - 1 === index}
            siteConfig={fcs}
          />
        ))}
      </SummaryRouteContainer>
      {(formData.reviewDate || formData.comment) && (
        <SummaryAnnotationsContainer>
          <SummaryAnnotationsTitle
            text={t('flux:form.annotations')}
            img={AnnotationIcon}
            fontSize="20px"
          />
          {formData.reviewDate && (
            <ReviewDateDisplay>
              <Label>{t('flux:form.reviewDate')}</Label>
              <br />
              {formData.reviewDate.format('YYYY-MM-DD')}
            </ReviewDateDisplay>
          )}
          {formData.comment && (
            <div>
              <Label>{t('flux:form.comment')}</Label>
              <br />
              {formData.comment}
            </div>
          )}
        </SummaryAnnotationsContainer>
      )}
    </SummaryStepContainer>
  );
};

export { SummaryStep };
