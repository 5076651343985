const SET_STATUS_FILTER_ACTION = 'SET_STATUS_FILTER_ACTION';
const SET_REF_FILTER_ACTION = 'SET_REF_FILTER_ACTION';
const SET_ASSET_FILTER_ACTION = 'SET_ASSET_FILTER_ACTION';
const SET_ORDER_FILTER_ACTION = 'SET_ORDER_FILTER_ACTION';
const SET_CARRIER_FILTER_ACTION = 'SET_CARRIER_FILTER_ACTION';
const SET_DEPARTURE_FILTER_ACTION = 'SET_DEPARTURE_FILTER_ACTION';
const SET_ARRIVAL_FILTER_ACTION = 'SET_ARRIVAL_FILTER_ACTION';
const SET_CHECKPOINT_FILTER_ACTION = 'SET_CHECKPOINT_FILTER_ACTION';
const SET_DEPARTURE_TIMESTAMP_FILTER_ACTION =
  'SET_DEPARTURE_TIMESTAMP_FILTER_ACTION';
const SET_ARRIVAL_TIMESTAMP_FILTER_ACTION =
  'SET_ARRIVAL_TIMESTAMP_FILTER_ACTION';
const SET_IS_LATE_VARIABLE_ACTION = 'SET_IS_LATE_VARIABLE_ACTION';
const SET_PAGE_VARIABLE_ACTION = 'SET_PAGE_VARIABLE_ACTION';
const SET_SEARCH_VARIABLE_ACTION = 'SET_SEARCH_VARIABLE_ACTION';
const SET_ORDER_BY_VARIABLE_ACTION = 'SET_ORDER_BY_VARIABLE_ACTION';
const SET_SAVED_FILTERS_VARIABLES_ACTION = 'SET_SAVED_FILTERS_VARIABLES_ACTION';
const RESET_FILTERS_ACTION = 'RESET_FILTERS_ACTION';

export {
  SET_STATUS_FILTER_ACTION,
  SET_REF_FILTER_ACTION,
  SET_ASSET_FILTER_ACTION,
  SET_ORDER_FILTER_ACTION,
  SET_CARRIER_FILTER_ACTION,
  SET_DEPARTURE_FILTER_ACTION,
  SET_ARRIVAL_FILTER_ACTION,
  SET_CHECKPOINT_FILTER_ACTION,
  SET_DEPARTURE_TIMESTAMP_FILTER_ACTION,
  SET_ARRIVAL_TIMESTAMP_FILTER_ACTION,
  SET_IS_LATE_VARIABLE_ACTION,
  SET_PAGE_VARIABLE_ACTION,
  SET_SEARCH_VARIABLE_ACTION,
  SET_ORDER_BY_VARIABLE_ACTION,
  SET_SAVED_FILTERS_VARIABLES_ACTION,
  RESET_FILTERS_ACTION,
};
