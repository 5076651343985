import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Stepper } from 'front-library';

import {
  REMOVE_SITE_CONFIG_FROM_CONFIG_ACTION,
  SET_CONFIG_FORM_DATA_ACTION,
} from '../../../stores/flux/actions';
import { FLUX_INITIAL_CONFIG_SITE_STATE } from '../../../stores/flux/reducer';
import { Modal } from '../../molecules/Modal/Modal';

import { BottomButtons, StepperContainer } from './styled-components';

import { Form } from '../../atoms/Form/Form';
import { AnnotationStep } from './AnnotationStep';
import { BasicInfosStep } from './BasicInfosStep';
import { RoutesStep } from './RoutesStep';
import { SummaryStep } from './SummaryStep';

const FluxConfigModal = ({ formData, dispatch, onClose, onSave }) => {
  const { t } = useTranslation();

  const isNextEnabled = useMemo(() => {
    if (0 === formData.activeStep) {
      return (
        '' !== formData.name &&
        null !== formData.periodFrom &&
        null !== formData.periodTo
      );
    }

    if (1 === formData.activeStep) {
      return !formData.fluxConfigurationSites.find((fcs, index) => {
        if (
          null === fcs.site ||
          '00:00:00' === fcs.timeOnSite ||
          fcs.deleted_at
        ) {
          return true;
        }

        if (index < formData.fluxConfigurationSites.length - 1) {
          return '00:00:00' === fcs.transportTime;
        }

        return false;
      });
    }

    return true;
  }, [formData]);

  const updateField = useCallback(
    (fields) => {
      if (fields?.periodTo) {
        formData.errors['input.period_to'] = undefined;
      } else if (fields?.periodFrom) {
        formData.errors['input.period_from'] = undefined;
      }

      dispatch({
        type: SET_CONFIG_FORM_DATA_ACTION,
        payload: {
          formData: {
            ...formData,
            ...fields,
          },
        },
      });
    },
    [dispatch, formData],
  );

  const addIntermediateSite = useCallback(
    (index) => {
      const fcs = _.cloneDeep(formData.fluxConfigurationSites);
      fcs.splice(index, 0, {
        ..._.cloneDeep(FLUX_INITIAL_CONFIG_SITE_STATE),
        uniqId: Date.now(),
      });

      updateField({ fluxConfigurationSites: fcs });
    },
    [formData, updateField],
  );

  const removeIntermediateSite = useCallback(
    (index) => {
      dispatch({
        type: REMOVE_SITE_CONFIG_FROM_CONFIG_ACTION,
        payload: {
          index,
        },
      });
    },
    [dispatch],
  );

  const editSite = useCallback(
    (index, field, value) => {
      const fcs = _.cloneDeep(formData.fluxConfigurationSites);
      fcs[index][field] = value;
      fcs[index].deleted_at = null;

      updateField({ fluxConfigurationSites: fcs });
    },
    [formData, updateField],
  );

  return (
    <Modal
      onClose={onClose}
      title={
        (formData.id && t('flux:form.editConfig')) || t('flux:form.newConfig')
      }
    >
      <StepperContainer>
        <Stepper stepsNumber={4} activeStep={formData.activeStep} />
      </StepperContainer>
      <Form hideCancel={true} hideSubmit={true} onSubmit={onSave}>
        {0 === formData.activeStep && (
          <BasicInfosStep formData={formData} updateField={updateField} />
        )}
        {1 === formData.activeStep && (
          <RoutesStep
            t={t}
            formData={formData}
            addIntermediateSite={addIntermediateSite}
            removeIntermediateSite={removeIntermediateSite}
            editSite={editSite}
          />
        )}
        {2 === formData.activeStep && (
          <AnnotationStep formData={formData} updateField={updateField} />
        )}
        {3 === formData.activeStep && <SummaryStep t={t} formData={formData} />}
        <BottomButtons>
          {formData.activeStep > 0 && (
            <Button
              text={t('flux:form.previous')}
              onClick={() =>
                updateField({ activeStep: formData.activeStep - 1 })
              }
            />
          )}
          {formData.activeStep < 3 && (
            <Button
              text={t('flux:form.next')}
              disabled={!isNextEnabled}
              onClick={() =>
                updateField({ activeStep: formData.activeStep + 1 })
              }
            />
          )}
          {formData.activeStep === 3 && (
            <Button text={t('flux:form.save')} type="submit" />
          )}
        </BottomButtons>
      </Form>
    </Modal>
  );
};

export { FluxConfigModal };
