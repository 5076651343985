export const STEPS = {
  shippingOrder: 0,
  order: 1,
  departure: 2,
  checkpoint: 3,
  arrival: 4,
  summary: 5,
};

export function isNextAvailable(activeStep, state) {
  switch (activeStep) {
    case STEPS.order:
      return state.orders.some((order) => order.reference);
    case STEPS.departure:
      return (
        (state.deliveryModel && state.departureDate) ||
        (state.departureSite?.id && state.departureDate)
      );
    case STEPS.checkpoint:
      return (
        !state.checkpoints.length ||
        state.checkpoints.every(
          (point) =>
            point.site?.id &&
            point.checkpointDepartureDate &&
            point.checkpointArrivalDate,
        )
      );
    case STEPS.arrival:
      return (
        state.arrivalSite?.id &&
        state.arrivalDate &&
        '' !== state.carrierReference
      );
    case STEPS.summary: // Last summary step with create button
      return true;
    default:
      return false;
  }
}
