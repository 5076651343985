import { Panel } from 'front-library';
import styled from 'styled-components';

export const LegendContainer = styled.div`
    position: absolute;
    right: 0px;
    bottom: 10px;
`;

export const LegendPanel = styled(Panel)`
    > div {
        width: auto;
        padding-top: 5px;
        padding-bottom: 5px;
    }
`;

export const LegendGrid = styled.div`
    padding: 5px 15px 15px 15px;
    display: grid;
    grid-template-columns: 26px 170px;
    row-gap: 10px;
    align-items: center;
`;

export const LegendIcon = styled.img`
    width: 17px;
    height: 17px;
`;

export const LegendIconBigger = styled.img`
    width: 20px;
    height: 20px;
`;

export const LegendText = styled.p`
    font-size: 12px;
    color: #000000;
`;
