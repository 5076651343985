import React from 'react';
import { useTranslation } from 'react-i18next';

import Logo from 'front-library/build/img/logo-magma.svg';

import {
  ExpiredPageContainer,
  ExpiredPageSubTitle,
  ExpiredPageTitle,
  Image,
} from './styled-components';

const ExpiredPage = () => {
  const { t } = useTranslation();

  return (
    <ExpiredPageContainer>
      <ExpiredPageTitle>{t('smsLinkPage:expiredPageTitle')}</ExpiredPageTitle>
      <ExpiredPageSubTitle>
        {t('smsLinkPage:expiredPageSubTitle')}
      </ExpiredPageSubTitle>
      <Image src={Logo} alt="logo" />
    </ExpiredPageContainer>
  );
};

export { ExpiredPage };
