import { gql } from '@apollo/client';

const GET_ASSETS = gql`
    query GetAssets($first: Int, $references: Mixed) {
        assets(first: $first, where: {
            AND: [{
                column: "reference"
                operator: IN
                value: $references
            }]
        }) {
            data {
                id
                reference
                buy_value
                asset_type { name }
                state { name }
                mag { mag_id }
                branches { data { reference } }
                responsible { data { email } }
            }
        }
    }
`;

const GET_SITES = gql`
    query GetSites($first: Int, $names: Mixed) {
        sites(first: $first, where: {
            AND: [{
                column: "name"
                operator: IN
                value: $names
            }]
        }) {
            data {
                id
                name
                address
                reference
                latitude
                longitude
                geofence_radius
                site_type { name }
                branches { reference }
            }
        }
    }
`;

const CREATE_USERS = gql`
    mutation CreateUsers($users: [CreateUsersInput]) {
        createUsers(input: $users) {
            errors
        }
    }
`;

const CREATE_ASSETS = gql`
    mutation CreateAssets($assets: [CreateAssetsInput!]) {
        createAssets(input: $assets) {
            errors
        }
    }
`;

const UPDATE_ASSETS = gql`
    mutation UpdateAssets($assets: [UpdateAssetsInput!]) {
        updateAssets(input: $assets) {
            errors
        }
    }
`;

const DELETE_ASSETS = gql`
    mutation DeleteAssets($ids: [ID!]!) {
        deleteAssets(ids: $ids) {
            id
        }
    }
`;

const CREATE_SITES = gql`
    mutation CreateSites($sites: [CreateSitesInput!]) {
        createSites(input: $sites) {
            errors
        }
    }
`;

const UPDATE_SITES = gql`
    mutation UpdateSites($sites: [UpdateSitesInput!]) {
        updateSites(input: $sites) {
            errors
        }
    }
`;

const DELETE_SITES = gql`
    mutation DeleteSites($ids: [ID!]!) {
        deleteSites(ids: $ids) {
            id
        }
    }
`;

export {
  GET_ASSETS,
  GET_SITES,
  CREATE_USERS,
  CREATE_ASSETS,
  UPDATE_ASSETS,
  DELETE_ASSETS,
  CREATE_SITES,
  UPDATE_SITES,
  DELETE_SITES,
};
