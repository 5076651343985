import { useMutation } from '@apollo/client';
import { BtnInATab, Loading } from 'front-library';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import IconAddGreen from 'front-library/build/img/icon-add-green.svg';

import { useQueryGraphQl } from '../../../../hooks/useQueryGraphQl';
import { routes } from '../../../../routes';
import { ListPage } from '../../../templates/ListPage';
import { SiteType } from '../SiteType';
import { DELETE_SITE_TYPE, GET_SITE_TYPES } from '../graphql';
import { DataGrid } from './DataGrid';

const SiteTypeList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data,
    setPagination,
    setFirst,
    setVariable,
    setOrderBy,
    setOrderByRelation,
    loading,
    refetch,
  } = useQueryGraphQl(GET_SITE_TYPES, { search: '%' });
  const [deleteSiteType, { loading: deleteLoading }] =
    useMutation(DELETE_SITE_TYPE);

  const handleDeleteConfirm = async (id, name) => {
    try {
      await deleteSiteType({ variables: { id } });
      await refetch();

      toast.success(t('siteTypes:deleted.success', { name }));
    } catch (e) {
      console.error(e);
      toast.error(t('siteTypes:deleted.error', { name }));
    }
  };

  const handleDelete = (row) =>
    confirmAlert({
      title: t('common:deletion'),
      message: t('siteTypes:askDelete', { name: row.name }),
      buttons: [
        {
          label: t('common:yes'),
          onClick: () => handleDeleteConfirm(row.id, row.name),
        },
        {
          label: t('common:no'),
        },
      ],
    });

  const handleAdd = () => navigate(routes.administrationSiteTypeCreation.path);

  return (
    <>
      <Routes>
        <Route
          path={routes.administrationSiteTypeCreation.partialPath}
          element={<SiteType refetch={refetch} />}
        />
        <Route
          path={routes.administrationSiteTypeEdition.partialPath}
          element={<SiteType refetch={refetch} />}
        />
      </Routes>

      <ListPage
        title={t('menu:siteTypeManagment')}
        paginatorInfo={data?.site_types?.paginatorInfo}
        onPaginate={setPagination}
        onRowsPerPageChange={setFirst}
        onSearch={(search) => setVariable('search', `%${search.trim()}%`)}
        onAdd={handleAdd}
      >
        <DataGrid
          data={data?.site_types?.data}
          onDelete={handleDelete}
          onOrderBy={setOrderBy}
          onOrderByRelation={setOrderByRelation}
        />

        {(loading || deleteLoading) && <Loading />}

        <div className="btnInATabContainer">
          <BtnInATab
            text={t('siteTypes:add')}
            onClick={handleAdd}
            imgSrc={IconAddGreen}
          />
        </div>
      </ListPage>
    </>
  );
};

export { SiteTypeList };
