import { ColumnCheckbox, Grid } from 'front-library';
import EditIcon from 'front-library/build/img/icon-modify-green-2.svg';
import DeleteIcon from 'front-library/build/img/trash.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const GridHeaderContainer = styled.div`
  margin-bottom: 10px;
`;

const DataGrid = ({
  data,
  onDelete,
  onOrderBy,
  onOrderByRelation,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <GridHeaderContainer>
        <Grid.Grid>
          <Grid.Header>
            <Grid.Cell size="md">
              <ColumnCheckbox
                label={t('assetGroups:reference')}
                onClick={(order) => onOrderBy('REFERENCE', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="md">
              <ColumnCheckbox
                label={t('assetGroups:type')}
                onClick={(order) =>
                  onOrderByRelation('asset_group_type', 'MAX', 'NAME', order)
                }
              />
            </Grid.Cell>
            <Grid.Cell size="s">
              <ColumnCheckbox
                label={t('assetGroups:value')}
                onClick={(order) =>
                  onOrderByRelation('assets', 'SUM', 'BUY_VALUE', order)
                }
              />
            </Grid.Cell>
            <Grid.Cell size="md">
              <ColumnCheckbox
                label={t('assetGroups:assets')}
                onClick={(order) =>
                  onOrderByRelation('assets', 'COUNT', null, order)
                }
              />
            </Grid.Cell>
            <Grid.Cell size="md">
              <ColumnCheckbox
                label={t('assetGroups:responsible')}
                onClick={(order) =>
                  onOrderByRelation('users', 'MAX', 'SURNAME', order)
                }
              />
            </Grid.Cell>
            <Grid.Cell size="xs" />
            <Grid.Cell size="xs" />
          </Grid.Header>
        </Grid.Grid>
      </GridHeaderContainer>
      <Grid.Grid>
        {data?.map((assetGroup) => (
          <GridRow
            assetGroup={assetGroup}
            key={assetGroup.id}
            onDelete={onDelete}
            onClick={onClick}
          />
        ))}
      </Grid.Grid>
    </>
  );
};

const GridRow = ({ assetGroup, onDelete, onClick }) => (
  <Grid.Row>
    <Grid.Cell size="md">{assetGroup.reference}</Grid.Cell>
    <Grid.Cell size="md">{assetGroup?.asset_group_type?.name}</Grid.Cell>
    <Grid.Cell size="s">
      {assetGroup?.assets?.data?.reduce(
        (sum, asset) => sum + asset.buy_value,
        0,
      )}{' '}
    </Grid.Cell>
    <Grid.Cell size="md">{assetGroup?.assets?.paginatorInfo.total}</Grid.Cell>
    <Grid.Cell size="md">
      {assetGroup?.responsibles?.data.map((responsible) => (
        <div key={responsible.id}>
          {responsible.surname} {responsible.name}
        </div>
      ))}
    </Grid.Cell>
    <Grid.Cell size="xs">
      <Grid.IconButton onClick={() => onClick(assetGroup)} imgSrc={EditIcon} />
    </Grid.Cell>
    <Grid.Cell size="xs">
      <Grid.IconButton
        onClick={() => onDelete(assetGroup)}
        imgSrc={DeleteIcon}
      />
    </Grid.Cell>
  </Grid.Row>
);

export { DataGrid };
