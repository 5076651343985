import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DisabledAddIcon from 'front-library/build/img/icon-add-disabled.svg';
import AddIcon from 'front-library/build/img/icon-add-green.svg';

import * as Actions from '../../../../../stores/shippingOrder/actions';
import { Order } from './Order';

import uniqueId from 'lodash/uniqueId';
import { AddButton, HelpText, Title } from './styled-components';

const Orders = ({ orders, dispatch }) => {
  const { t } = useTranslation();

  const isButtonDisabled = () => orders.some((order) => !order.reference);

  const updateOrders = (index, order) => {
    const newOrders = [...orders];
    newOrders[index] = order;

    dispatch({
      type: Actions.SET_ORDERS_ACTION,
      payload: newOrders,
    });
  };

  const removeOrder = (index) => {
    const newOrders = [...orders];
    newOrders.splice(index, 1);

    if (0 === newOrders.length) {
      newOrders.push({ reference: '', asset: null });
    }

    dispatch({
      type: Actions.SET_ORDERS_ACTION,
      payload: newOrders,
    });
  };

  return (
    <>
      <Title>
        {t('shippingOrders:modal.steps.step', { step: 2 })}
        {t('shippingOrders:modal.steps.ordersTitle')}
        <HelpText color="#C81912">
          {t('shippingOrders:modal.steps.asterisk')}
        </HelpText>
      </Title>

      {/* Orders */}
      {orders.map((order, index) => (
        <Order
          key={order.uniqueId}
          index={index}
          reference={order.reference}
          asset={order.asset}
          usedAssetIds={orders
            .filter((_, i) => i !== index)
            .map((o) => o.asset?.value || null)
            .filter((id) => null !== id)}
          onChange={(formOrder) =>
            updateOrders(index, { ...formOrder, uniqueId: order.uniqueId })
          }
          onRemove={() => removeOrder(index)}
        />
      ))}

      <AddButton
        variant="green"
        text={t('shippingOrders:modal.steps.addOrder')}
        img={isButtonDisabled() ? DisabledAddIcon : AddIcon}
        imgPosition="left"
        onClick={() =>
          dispatch({
            type: Actions.SET_ORDERS_ACTION,
            payload: [
              ...orders,
              {
                asset: null,
                reference: '',
                uniqueId: uniqueId(),
              },
            ],
          })
        }
        disabled={isButtonDisabled()}
      />
    </>
  );
};

Orders.propTypes = {
  orders: PropTypes.array,
  dispatch: PropTypes.func,
};

export { Orders };
