import React, { useEffect, useReducer, useCallback, useContext } from 'react';
import { GeoJSON } from 'react-leaflet';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { LocationMap } from '../../molecules/LocationMap';
import { MarkerClusterGroup } from '../../molecules/LocationMap/MarkerClusterGroup';

import { routes } from '../../../routes';
import {
  PUSH_TO_ALL_ASSETS_ACTION,
  PUSH_TO_ALL_SITES_ACTION,
  SET_ALL_SHIPPING_ORDERS_ACTION,
  SET_CENTER_ZOOM_ACTION,
  SET_LOCATION_FROM_URL_ACTION,
  TOUCH_MAP_ACTION,
} from '../../../stores/map/actions';
import { MAP_INITIAL_STATE, mapReducer } from '../../../stores/map/reducer';
import { AssetPositions } from '../../molecules/LocationMap/Asset/AssetPositions';
import { Geofence } from '../../molecules/LocationMap/Geofence';
import {
  createAssetClusterIconFunc,
  createShippingOrderClusterIconFunc,
  createSiteClusterIconFunc,
  geoJsonToAssetEvent,
  geoJsonToAssetMarker,
  geoJsonToShippingOrderEvent,
  geoJsonToShippingOrderMarker,
  geoJsonToSiteEvent,
  geoJsonToSiteMarker,
} from '../../molecules/LocationMap/utils';
import { AssetPanel } from '../Assets/AssetPanel';
import { MapFilters } from '../MapFilters/MapFilters';
import { SitePanel } from '../Site/SitePanel';

import { MeContext } from '../../../contexts/MeContext';
import { useCompanyAssets } from '../../../hooks/assets/useCompanyAssets';
import { useCompanySites } from '../../../hooks/sites/useCompanySites';
import { GET_MAP_SHIPPING_ORDERS } from './graphql';
import { LocationMapContainer } from './styled-components';

let updateUrlTimeout = null;

const MapPage = () => {
  const { me: user } = useContext(MeContext);
  const [state, dispatch] = useReducer(mapReducer, MAP_INITIAL_STATE);
  const {
    geoJsonAssets,
    geoJsonSites,
    geoJsonShippingOrders,

    assetsRenderKey,
    sitesRenderKey,
    shippingOrdersRenderKey,
    mapTouched,

    bounds,
    center,
    zoom,

    geofence,
    zones,

    showGeofence,
    showZones,
    shippingOrdersOnlyFilter,

    assetLocation,
    siteLocation,

    assetPositions,

    currentPanel,
  } = state;
  const { isLoading: isAssetsLoading, items: mapAssets } = useCompanyAssets(
    user.company.id,
  );
  const { isLoading: isSitesLoading, items: mapSites } = useCompanySites(
    user.company.id,
  );

  const navigate = useNavigate();
  const location = useLocation();

  const { data: mapShippingOrdersData, loading: mapShippingOrdersDataLoading } =
    useQueryGraphQl(GET_MAP_SHIPPING_ORDERS);

  const updateMapPosition = useCallback((position) => {
    const pos = position.split(',');

    if (pos.length < 2) {
      return;
    }

    const latLng = [+pos[0], +pos[1]];
    let z = null;

    if (pos.length > 2) {
      z = pos[2].split('z')[0];
    }
    dispatch({
      type: SET_LOCATION_FROM_URL_ACTION,
      payload: {
        center: latLng,
        zoom: +z,
      },
    });
  }, []);

  const updatePath = useCallback(
    (newCenter, newZoom) => {
      clearTimeout(updateUrlTimeout);
      updateUrlTimeout = setTimeout(() => {
        const url = location.pathname;
        const baseUrl = url.split('/@')[0];

        const newUrl = `${baseUrl}/@${newCenter[0]},${newCenter[1]},${newZoom}z${location.search}`;

        if (location.pathname.includes('@')) {
          navigate(newUrl, { replace: true });
        } else {
          navigate(newUrl);
        }
      }, 300);
    },
    [location, navigate],
  );

  const onAction = useCallback(
    () => !mapTouched && dispatch({ type: TOUCH_MAP_ACTION }),
    [mapTouched],
  );

  useEffect(() => {
    if (mapAssets?.length === 0) {
      return;
    }

    dispatch({
      type: PUSH_TO_ALL_ASSETS_ACTION,
      payload: { assets: mapAssets },
    });
  }, [mapAssets]);

  useEffect(() => {
    if (mapSites?.length === 0) {
      return;
    }

    dispatch({
      type: PUSH_TO_ALL_SITES_ACTION,
      payload: { sites: mapSites },
    });
  }, [mapSites]);

  useEffect(() => {
    if (mapShippingOrdersData?.shipping_orders?.data?.length) {
      dispatch({
        type: SET_ALL_SHIPPING_ORDERS_ACTION,
        payload: {
          shippingOrders: mapShippingOrdersData?.shipping_orders?.data,
        },
      });
    }
  }, [mapShippingOrdersData]);

  /**
   * @todo To really fix this, we need to get rid of useEffect which is not appropriate here
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // It means we arrive in a panel
    const url = window.location.href.split('/@');

    if (2 === url.length) {
      updateMapPosition(url[1]);
    }
  }, [currentPanel, updateMapPosition]);

  const updateLocation = (newCenter, newZoom) => {
    if (
      newCenter.lat !== center[0] ||
      newCenter.lng !== center[1] ||
      newZoom !== zoom
    ) {
      dispatch({
        type: SET_CENTER_ZOOM_ACTION,
        payload: {
          center: [newCenter.lat, newCenter.lng],
          zoom: newZoom,
        },
      });

      mapTouched && updatePath([newCenter.lat, newCenter.lng], newZoom);
    }
  };

  return (
    <LocationMapContainer>
      <Routes>
        <Route
          path={routes.filtersMap.partialPath}
          element={<MapFilters dispatch={dispatch} user={user} {...state} />}
        />
        <Route
          path={routes.assetMap.partialPath}
          element={<AssetPanel dispatch={dispatch} user={user} {...state} />}
        />
        <Route
          path={routes.siteMap.partialPath}
          element={<SitePanel dispatch={dispatch} user={user} {...state} />}
        />
      </Routes>
      <LocationMap
        center={center}
        zoom={zoom}
        bounds={bounds}
        loading={
          isSitesLoading || mapShippingOrdersDataLoading || isAssetsLoading
        }
        onAction={onAction}
        assetLocation={assetLocation}
        geoJsonAssets={geoJsonAssets}
        siteLocation={siteLocation}
        onLocationChange={(c, z) => updateLocation(c, z)}
        removePanelOffset={true}
      >
        {showGeofence && geofence && (
          <Geofence geofence={geofence} styleType="site" />
        )}
        {showZones &&
          zones.map((z) => (
            <Geofence geofence={z} styleType="zone" key={zones.id} />
          ))}
        {!shippingOrdersOnlyFilter && (
          <>
            <MarkerClusterGroup iconCreateFunction={createSiteClusterIconFunc}>
              {geoJsonSites && (
                <GeoJSON
                  key={sitesRenderKey}
                  data={geoJsonSites}
                  pointToLayer={geoJsonToSiteMarker}
                  onEachFeature={(feature, layer) =>
                    geoJsonToSiteEvent(feature, layer, navigate)
                  }
                />
              )}
            </MarkerClusterGroup>
            <MarkerClusterGroup iconCreateFunction={createAssetClusterIconFunc}>
              {geoJsonAssets && (
                <GeoJSON
                  key={assetsRenderKey}
                  data={geoJsonAssets}
                  pointToLayer={geoJsonToAssetMarker}
                  onEachFeature={(feature, layer) =>
                    geoJsonToAssetEvent(feature, layer, navigate)
                  }
                />
              )}
            </MarkerClusterGroup>
          </>
        )}
        <MarkerClusterGroup
          iconCreateFunction={createShippingOrderClusterIconFunc}
        >
          {geoJsonShippingOrders && (
            <GeoJSON
              key={shippingOrdersRenderKey}
              data={geoJsonShippingOrders}
              pointToLayer={geoJsonToShippingOrderMarker}
              onEachFeature={(feature, layer) =>
                geoJsonToShippingOrderEvent(feature, layer, navigate)
              }
            />
          )}
        </MarkerClusterGroup>
        {assetPositions && (
          <AssetPositions
            movements={assetPositions.movements}
            singlePoints={assetPositions.singlePoints}
            allPoints={assetPositions.allPoints}
          />
        )}
      </LocationMap>
    </LocationMapContainer>
  );
};

export { MapPage };
