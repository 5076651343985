import styled from 'styled-components';

import { BtnInATab, TitleIcon } from 'front-library';

export const BottomButtons = styled.div`
    display: flex;
    gap: 10px;
    padding-top: 10px;

    > button {
        width: 100%;
    }
`;

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
`;

export const Title = styled.p`
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
`;

export const StepperContainer = styled.div`
    margin-bottom: 20px;
`;

export const StepTitle = styled.p`
    font-size: 20px;
    margin-bottom: 10px;
`;

export const SitesTitle = styled.p`
    font-size: 16px;
    margin-bottom: 15px;
    color: #002B2B;
`;

export const DashedBorder = styled.div`
    width: 100%;
    border: 1px #B3B3B3;
    border-style: dashed none none none;
    opacity: 1;
    margin-top: 25px;
    margin-bottom: 25px;
`;

export const MarginBtnInATab = styled(BtnInATab)`
    margin: 7px 0px;
`;

export const BasicInfosStepContainer = styled.div`
    margin-bottom: 10px;
`;

export const AnnotationStepContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    margin-bottom: 14px;
`;

export const ReviewDateDisplay = styled.div`
    margin-bottom: 10px;
`;

/**
 * ↓↓ Flux modal summary components below ↓↓
 */

export const SummaryStepContainer = styled.div`
    margin-bottom: 14px;
    display: grid;
    grid-gap: 12px;
`;

const SummarySectionContainer = styled.div`
    box-shadow: 0px 3px 6px #0000000D;
    border-radius: 5px;
    padding: 15px;
`;

export const SummaryBasicInfosContainer = styled(SummarySectionContainer)`
    border: 0.5px solid #6CA6AB;
`;

export const SummaryRouteContainer = styled(SummarySectionContainer)`
    border: 0.5px solid #EF9D11;
`;

export const SummaryAnnotationsContainer = styled(SummarySectionContainer)`
    border: 0.5px solid #B3B3B3;
`;

export const SummaryPeriodTitle = styled(TitleIcon)`
    margin-top: 18px;
    margin-bottom: 18px;
`;
export const SummaryRouteTitle = styled(TitleIcon)`
    margin-bottom: 18px;
`;

export const SummaryAnnotationsTitle = styled(TitleIcon)`
    margin-bottom: 18px;
`;

export const SummarySiteInfos = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    margin-bottom: 20px;
`;

/**
 * ↓↓ Flux list components below ↓↓
 */

export const ConfigContainer = styled.div`
    margin: 5px 20px 5px;
`;

export const ConfigFirstLine = styled.div`
    display: flex;
    align-items: baseline;
    gap: 20px;
    margin-bottom: 10px;
`;

export const NoConfig = styled.p`
    color: #707070;
    opacity: 0.9;
    font-size: 12px;
`;

export const ConfigRowContainer = styled.div`
    margin: 10px 20px;
`;

export const ConfigRoute = styled.div`
    margin-bottom: 25px;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: ${(props) => `repeat(${props.columns}, 250px)`};
    column-gap: 10px;
    row-gap: 25px;
`;
