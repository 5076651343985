import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from 'front-library';

import { getErrorText } from '../../../helpers';
import { Form } from '../../atoms/Form/Form';
import { CustomParametersForm } from '../CustomParameters/CustomParametersForm';

const AssetTypeForm = ({
  customParameters,
  handleUpdateCustomParametersFormValue,
  enableTemperature,
  errors = {},
  formValues,
  setFormValues,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <Form onCancel={onCancel} onSubmit={onSubmit}>
      <TextField
        errorText={getErrorText(errors, 'name', t('assetTypes:form.name'))}
        label={t('assetTypes:form.name')}
        onChange={(name) => setFormValues({ ...formValues, name })}
        required={true}
        value={formValues.name}
      />
      {enableTemperature && (
        <>
          <TextField
            errorText={getErrorText(
              errors,
              'temperature_min',
              t('assetTypes:form.temperature_min'),
            )}
            label={t('assetTypes:form.temperature_min')}
            onChange={(temperatureMin) =>
              setFormValues({ ...formValues, temperatureMin })
            }
            type="number"
            value={formValues.temperatureMin}
          />
          <TextField
            errorText={getErrorText(
              errors,
              'temperature_max',
              t('assetTypes:form.temperature_max'),
            )}
            label={t('assetTypes:form.temperature_max')}
            onChange={(temperatureMax) =>
              setFormValues({ ...formValues, temperatureMax })
            }
            type="number"
            value={formValues.temperatureMax}
          />
        </>
      )}
      {customParameters?.length > 0 && (
        <CustomParametersForm
          customParameters={customParameters}
          onUpdateValue={handleUpdateCustomParametersFormValue}
          values={formValues.customParameters || {}}
        />
      )}
    </Form>
  );
};

AssetTypeForm.propTypes = {
  customParameters: PropTypes.array,
  enableTemperature: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export { AssetTypeForm };
