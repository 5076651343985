import { useMutation } from '@apollo/client';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { BtnInATab, Loading } from 'front-library';
import IconAddGreen from 'front-library/build/img/icon-add-green.svg';

import { useQueryGraphQl } from '../../../../hooks/useQueryGraphQl';
import { routes } from '../../../../routes';
import { ListPage } from '../../../templates/ListPage';
import { MagType } from '../MagType';
import { DELETE_MAG_TYPE, GET_MAG_TYPES } from '../graphql';
import { BtnInATabContainer } from '../styled-components';
import { DataGrid } from './DataGrid';

const MagTypeList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: magTypesData,
    setPagination,
    setFirst,
    setVariable,
    setOrderBy,
    setOrderByRelation,
    loading,
    refetch,
  } = useQueryGraphQl(GET_MAG_TYPES, { first: 25, search: '%' });
  const [deleteMagType, { loading: deleteLoading }] =
    useMutation(DELETE_MAG_TYPE);

  const handleDeleteConfirm = async (id, name) => {
    try {
      await deleteMagType({ variables: { id } });
      await refetch();

      toast.success(t('magTypes:deleted.success', { name }));
    } catch (e) {
      console.error(e);
      toast.error(t('magTypes:deleted.error', { name }));
    }
  };

  const onDeleteHandler = (magType) =>
    confirmAlert({
      title: t('common:deletion'),
      message: t('magTypes:askDelete', { name: magType.name }),
      buttons: [
        {
          label: t('common:yes'),
          onClick: () => handleDeleteConfirm(magType.id, magType.name),
        },
        {
          label: t('common:no'),
        },
      ],
    });

  const handleAdd = () => navigate(routes.administrationMagTypeCreation.path);

  return (
    <>
      <Routes>
        <Route
          path={routes.administrationMagTypeCreation.partialPath}
          element={<MagType refetch={refetch} />}
        />
        <Route
          path={routes.administrationMagTypeEdition.partialPath}
          element={<MagType refetch={refetch} />}
        />
      </Routes>
      <ListPage
        title={t('menu:magType')}
        paginatorInfo={magTypesData?.mag_types?.paginatorInfo}
        onPaginate={setPagination}
        onRowsPerPageChange={setFirst}
        onSearch={(search) => setVariable('search', `%${search.trim()}%`)}
        onAdd={handleAdd}
      >
        <DataGrid
          magTypes={magTypesData?.mag_types?.data}
          onDelete={onDeleteHandler}
          onOrderBy={setOrderBy}
          onOrderByRelation={setOrderByRelation}
        />
        {(loading || deleteLoading) && <Loading />}
        <BtnInATabContainer>
          <BtnInATab
            text={t('magTypes:add')}
            onClick={handleAdd}
            imgSrc={IconAddGreen}
          />
        </BtnInATabContainer>
      </ListPage>
    </>
  );
};

export { MagTypeList };
