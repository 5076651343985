import { BtnInATab } from 'front-library';
import deadLink from 'front-library/build/img/dead-link.svg';
import styled from 'styled-components';

const colors = {
  warning: '#98731A',
  danger: '#ee130a',
  success: '#7BC769',
  info: '#A2CED2',
  help: '#8d8989',
  dior: '#002B2B',
  primary: '#264B4B',
  default: '#D7DEDE',
};

export const Container = styled.div`
    display: grid;
    min-height: 100vh;
    background-color: ${colors.dior};
    color: ${colors.default};
    padding: 1em;
`;

export const LocationMapContainer = styled.div`
    height: 100vh;
    position: relative;
    margin: -1em;
`;

export const ExpiredPageContainer = styled.div`
    height: 100%;
    position: relative;
    background-image: url(${deadLink});
    background-repeat: no-repeat;
    background-position: center;
    background-origin: content-box;
    text-align: center;
    padding: 2em;
`;

export const Section = styled.div`
    & > div {
        margin-bottom: 1.5em;
    }
`;

export const FlexSection = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5em;
`;

export const FlexColumnSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
`;

export const GridSection = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5em 0;
    margin-top: 0.7em;
`;

export const Title = styled.div`
    font-weight: 600;
    margin-bottom: 1em;
`;

export const ExpiredPageTitle = styled.div`
    font-size: 1.3em;
`;

export const ExpiredPageSubTitle = styled.div`
    margin: 1em 2.5em;
`;

export const MapButton = styled(BtnInATab)`
    border-radius: 0.3em;
    margin-left: 1em;

    & > .btn-in-tab-box {
        font-size: 1em;
        color: ${colors.default};
        border-color: ${colors.warning};
        border-radius: 0.3em;
    }
`;

export const BackButton = styled(BtnInATab)`
    position: absolute;
    z-index: 401;
    margin: 1.5em;
    background-color: #fff;
    border-radius: 0.3em;

    & > .btn-in-tab-box {
        padding: 10px 10px 10px 6px;
        align-items: center;
        font-size: 1em;
        font-weight: 600;
        border-radius: 0.3em;
        
    }
`;

export const LabelWrapper = styled.div`
    font-size: 0.7em;
    margin-bottom: 0.5em;
`;

export const Label = styled.span`
    color: ${(props) => colors[props.level]};

    &:not(:last-child) {
        margin-right: 0.3em;
    }
`;

export const Value = styled.div`
    font-size: 1.2em;
    word-break: break-all;
    color: ${(props) => colors[props.level]};

    & > span {
        font-size: 0.5em;
        margin-left: 0.5em;
    }
`;

export const CheckpointWrapper = styled.div`
    background-color: ${colors.primary};
    margin-bottom: 1em;
    padding: 0.3em;
    border: 1px solid ${(props) => colors[props.level]};
    border-radius: 0.2em;
`;

export const CheckpointTitle = styled.div`
    display: grid;
    grid-template-columns: 1.5em 1fr 1.5em;

    & img {
        cursor: pointer;
        width: 1.2em;
        transition: .3s ease;
    }

    & div {
        display: flex;
        align-items: center;
        font-size: 0.8em;
        color: ${colors.info};
        cursor: pointer;
        transition: .3s ease;
    }

    & span {
        margin-left: 0.3em;
    }
`;

export const CheckpointBody = styled.div`
    margin-top: 0.5em;
    padding: 0.5em 1.6em;

    & > div:not(:last-child) {
        margin-bottom: 1.5em;
    }

    & > div> div:not(:last-child) {
        margin-bottom: 0.3em;
    }

    & > div > span {
        font-size: 1em;
        color: ${colors.default};
    }
`;

export const Divider = styled.hr`
    margin: 1em 0;
    border: 1px solid ${(props) => colors[props.level]};
`;

export const DotWrapper = styled.div`
    display: grid;
    grid-template-columns: 1.5em 1fr;
    align-items: center;

    & > span {
        font-size: 0.7em;
    }
`;

export const Dot = styled.div`
    height: 1em;
    width: 1em;
    background-color: ${(props) => colors[props.level]};
    border-radius: 50%;
    display: inline-block;
`;

export const Image = styled.img`
    position: absolute;
    bottom: 2em;
    left: 50%;
    transform: translateX(-50%);
    height: 3em;
`;
