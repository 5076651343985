import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import reactDom from 'react-dom';
import { useTranslation } from 'react-i18next';

import { Autocomplete, Button, ErrorMessage, TextField } from 'front-library';

import { useQueryGraphQl } from '../../../../../../../hooks/useQueryGraphQl';
import { Loading } from '../../../../../../molecules/Loading/Loading';
import { Modal } from '../../../../../../molecules/Modal/Modal';

import { GET_ASSETS } from './graphql';
import {
  ButtonContainer,
  Container,
  ModalContainer,
} from './styled-components';

const OrderModal = ({ order = {}, shippingOrder, errors, onClose, onSave }) => {
  const { t } = useTranslation();
  const [reference, setReference] = useState(order?.reference || '');
  const [asset, setAsset] = useState(
    order?.asset
      ? { value: order.asset.id, title: order.asset.reference }
      : null,
  );

  const {
    data: assetsData,
    loading,
    setVariable,
  } = useQueryGraphQl(GET_ASSETS, { search: '%' });

  const assets = useMemo(() => {
    if (assetsData?.assets?.data) {
      return assetsData.assets.data.filter(
        (a) =>
          0 === a.shipping_orders.length ||
          a.shipping_orders[0].id === shippingOrder.id,
      );
    }

    return [];
  }, [assetsData, shippingOrder]);

  return reactDom.createPortal(
    <>
      {loading && <Loading />}
      <ModalContainer>
        <Modal
          onClose={onClose}
          size="small"
          title={
            order.id
              ? t('shippingOrders:order.modal.title.edit')
              : t('shippingOrders:order.modal.title.create')
          }
        >
          <Container>
            <TextField
              label={t('shippingOrders:order.modal.reference')}
              onChange={setReference}
              placeholder={t('shippingOrders:order.modal.reference')}
              value={reference}
            />
            {errors?.graphQLErrors[0]?.extensions?.validation[
              'input.reference'
            ]?.map((m) => (
              <ErrorMessage key={m} text={m} />
            ))}
            <Autocomplete
              label={t('shippingOrders:order.modal.asset')}
              onChange={(v) => setAsset(v)}
              onInputChange={(input) => setVariable('search', `%${input}%`)}
              options={assets}
              placeholder={asset?.title}
              value={asset}
            />
            <ButtonContainer>
              <Button
                onClick={onClose}
                text={t('shippingOrders:order.modal.cancel')}
                variant="red"
              />
              <Button
                onClick={() => onSave(reference, asset?.value || null)}
                text={t('shippingOrders:order.modal.save')}
                variant="green"
              />
            </ButtonContainer>
          </Container>
        </Modal>
      </ModalContainer>
    </>,
    document.body,
  );
};

OrderModal.propTypes = {
  order: PropTypes.object,
  shippingOrder: PropTypes.object,
  errors: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export { OrderModal };
