import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    gap: 8px;
    font-size: 12px;
`;

export const ArrowButton = styled.button`
    align-self: flex-start;
`;

export const ArrowImg = styled.img`
    width: 18px;
    transition: ease 0.3s;

    ${({ isOpen }) =>
      isOpen &&
      `
        transform: rotate(90deg);
    `}
`;

export const GrayText = styled.p`
    color: #707070;
    line-break: anywhere;
`;

export const OrdersCountContainer = styled.div`
    display: flex;
    gap: 5px;
    margin-top: 2px;
`;
