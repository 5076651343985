import React, { useMemo, useState } from 'react';
import { FeatureGroup, Marker } from 'react-leaflet';
import styled from 'styled-components';
import { PanelSiteForm } from '../../organisms/PanelSiteForm/PanelSiteForm';
import { EditControl } from './EditControl';

import 'leaflet-draw/dist/leaflet.draw.css';
import { convertStringToKey } from '../../../helpers';
import { Loading } from '../../molecules/Loading/Loading';
import { LocationMap } from '../../molecules/LocationMap';
import { Geofence } from '../../molecules/LocationMap/Geofence';
import { icons } from '../../molecules/LocationMap/icons';

const Container = styled.div`
  height: 100vh;
`;

const STYLE_GEOFENCE = {
  color: '#ef9d11',
  fillColor: '#b9e6eb',
};

const STYLE_ZONE = {
  color: '#93AACC',
  fillColor: '#93AACC',
};

let nextZoneId = 0;

const SiteFormContainer = ({
  onSave,
  onCancel,
  site,
  defaultEditGeofence,
  mutationLoading,
}) => {
  const [position, setPosition] = useState(
    (site && [site.latitude, site.longitude]) || [48.8706446, 2.33233],
  );
  const [center, setCenter] = useState(
    (site && [site.latitude, site.longitude]) || [48.8706446, 2.33233],
  );
  const [geofence, setGeofence] = useState(site?.geofence || null);
  const [zones, setZones] = useState(site?.zones || []);
  const [editZoneControlShow, setEditZoneControlShow] = useState(false);
  const [editSiteControlShow, setEditSiteControlShow] =
    useState(defaultEditGeofence);
  const [zoom, setZoom] = useState(15);
  const [loading, setLoading] = useState(false);

  const siteDefault = useMemo(() => {
    if (!site) {
      return null;
    }

    return {
      ...site,
      address: {
        title: site.address,
        value: -1,
      },
      branches: site.branches?.map(({ id, reference }) => ({
        value: id,
        title: reference,
      })),
      siteType: {
        value: site.site_type.id,
        title: site.site_type.name,
      },
      beacons:
        site.mags
          ?.filter((mag) => !mag.is_gateway)
          ?.map(({ id, mag_id }) => ({ title: mag_id, value: id })) || [],
      gateways:
        site.mags
          ?.filter((mag) => mag.is_gateway)
          ?.map(({ id, mag_id }) => ({ title: mag_id, value: id })) || [],
    };
  }, [site]);

  const handleGeofenceCreated = (e) => {
    switch (e.layerType) {
      case 'polygon':
      case 'rectangle':
        setGeofence({
          type: e.layerType,
          bounds: e.layer.getLatLngs()[0].map((ll) => [ll.lat, ll.lng]),
        });

        break;
      case 'circle': {
        const latLng = e.layer.getLatLng();
        setGeofence({
          type: e.layerType,
          center: [latLng.lat, latLng.lng],
          radius: e.layer.getRadius(),
        });

        break;
      }
      default:
        throw new Error('Unhandled case');
    }

    if (e.type === 'draw:created') {
      e.layer.remove();
    }
  };

  const handleGeofenceDeleted = (e) => {
    if (e.layers.getLayers().length > 0) {
      setGeofence(null);
    }
  };

  const handleGeofenceEdited = (e) => {
    const geofenceEdited = Object.keys(e.target._renderer._layers)
      .map((k) => e.target._renderer._layers[k])
      .filter((l) => 'site' === l.options.geofenceType)
      .map((l) => {
        switch (l.options.type) {
          case 'polygon':
          case 'rectangle':
            return {
              ...geofence,
              bounds: l.getLatLngs()[0].map((ll) => [ll.lat, ll.lng]),
            };
          case 'circle': {
            const latLng = l.getLatLng();

            return {
              ...geofence,
              center: [latLng.lat, latLng.lng],
              radius: l.getRadius(),
            };
          }
          default:
            return null;
        }
      });

    setGeofence(geofenceEdited[0]);
  };

  const handleZoneCreated = (e) => {
    switch (e.layerType) {
      case 'polygon':
      case 'rectangle':
        setZones([
          ...zones,
          {
            id: nextZoneId,
            name: `Zone ${zones.length + 1}`,
            type: e.layerType,
            bounds: e.layer.getLatLngs()[0].map((ll) => [ll.lat, ll.lng]),
          },
        ]);

        break;
      case 'circle': {
        const latLng = e.layer.getLatLng();
        setZones([
          ...zones,
          {
            id: nextZoneId,
            name: `Zone ${zones.length + 1}`,
            type: e.layerType,
            center: [latLng.lat, latLng.lng],
            radius: e.layer.getRadius(),
          },
        ]);

        break;
      }
      default:
        throw new Error('Unhandled case');
    }

    nextZoneId--;
    e.layer.remove();
  };

  const handleZoneEdited = (e) => {
    const zonesEdited = Object.keys(e.target._renderer._layers)
      .map((k) => e.target._renderer._layers[k])
      .filter((l) => l.options.geofenceType === 'zone')
      .map((l) => {
        switch (l.options.type) {
          case 'polygon':
          case 'rectangle':
            return {
              ...zones.find((z) => z.id === l.options.id),
              bounds: l.getLatLngs()[0].map((ll) => [ll.lat, ll.lng]),
            };
          case 'circle': {
            const latLng = l.getLatLng();

            return {
              ...zones.find((z) => z.id === l.options.id),
              center: [latLng.lat, latLng.lng],
              radius: l.getRadius(),
            };
          }
          default:
            return null;
        }
      });

    setZones(zonesEdited);
  };

  const handleZoneDeleted = (e) => {
    const layersDeleted = e.layers.getLayers().map((l) => l.options.id);
    setZones(zones.filter((z) => !layersDeleted.includes(z.id)));
  };

  const handleSave = (site, onError) => {
    setLoading(true);

    let mags = [];

    if (site.beacons) {
      mags = site.beacons.map((beacon) => ({ connect: beacon.value }));
    }

    if (site.gateways) {
      mags = [
        ...mags,
        ...site.gateways.map((gateway) => ({ connect: gateway.value })),
      ];
    }

    onSave(
      {
        ...site,
        address: site.address?.title,
        branches: {
          sync: site.branches?.map(({ value }) => value),
        },
        siteTypeId: site.siteType?.value,
        mags,
        latitude: Number.parseFloat(position[0]),
        longitude: Number.parseFloat(position[1]),
        reference: site.reference || convertStringToKey(site.name),
        geofence: geofence && {
          type: geofence.type,
          latitude: geofence.center?.[0],
          longitude: geofence.center?.[1],
          radius: Number.parseInt(geofence.radius),
          points: geofence.bounds?.map((b, i) => ({
            latitude: b[0],
            longitude: b[1],
            index: i,
          })),
        },
        zones: zones.map((z) => ({
          id: z.id,
          type: z.type,
          name: z.name,
          latitude: z.center?.[0],
          longitude: z.center?.[1],
          radius: Number.parseInt(z.radius),
          points: z.bounds?.map((b, i) => ({
            latitude: b[0],
            longitude: b[1],
            index: i,
          })),
        })),
      },
      onError,
    );

    setLoading(false);
  };

  return (
    <Container>
      <PanelSiteForm
        isUpdate={!!site}
        siteDefault={siteDefault}
        position={position}
        setPosition={(position, zoom) => {
          setCenter(position);
          setPosition(position);
          setZoom(zoom);
        }}
        onSave={handleSave}
        onCancel={onCancel}
        editSiteControlShow={editSiteControlShow}
        editZoneControlShow={editZoneControlShow}
        setEditSiteControlShow={() => {
          setEditSiteControlShow(!editSiteControlShow);
          setEditZoneControlShow(false);

          if (!editSiteControlShow) {
            setTimeout(() => {
              document
                .querySelector('.leaflet-draw')
                .classList.add('leaflet-draw-site');
            });
          }
        }}
        setEditZoneControlShow={() => {
          setEditSiteControlShow(false);
          setEditZoneControlShow(!editZoneControlShow);

          if (!editZoneControlShow) {
            setTimeout(() => {
              document
                .querySelector('.leaflet-draw')
                .classList.add('leaflet-draw-zone');
            });
          }
        }}
        mutationLoading={mutationLoading}
        geofence={geofence}
      />
      <LocationMap
        center={center}
        zoom={zoom}
        onZoomChange={(zoom) => setZoom(zoom)}
      >
        {editSiteControlShow && (
          <FeatureGroup key="featureGroup-0">
            <EditControl
              position="topright"
              onEdited={handleGeofenceEdited}
              onCreated={handleGeofenceCreated}
              onDeleted={handleGeofenceDeleted}
              draw={{
                polygon: {
                  shapeOptions: STYLE_GEOFENCE,
                },
                circle: {
                  shapeOptions: STYLE_GEOFENCE,
                },
                rectangle: {
                  shapeOptions: STYLE_GEOFENCE,
                },
                polyline: false,
                marker: false,
                circlemarker: false,
              }}
              edit={{ remove: false }}
            />

            <Geofence geofence={geofence} styleType="site" />
          </FeatureGroup>
        )}
        {editZoneControlShow && (
          <FeatureGroup key="featureGroup-1">
            <EditControl
              position="topright"
              onEdited={handleZoneEdited}
              onCreated={handleZoneCreated}
              onDeleted={handleZoneDeleted}
              draw={{
                polygon: {
                  shapeOptions: STYLE_ZONE,
                },
                circle: {
                  shapeOptions: STYLE_ZONE,
                },
                rectangle: {
                  shapeOptions: STYLE_ZONE,
                },
                polyline: false,
                marker: false,
                circlemarker: false,
              }}
            />
            {zones.map((zone, i) => (
              <Geofence
                key={`geofence-zone-edit-${zone.id}`}
                geofence={zone}
                styleType="zone"
                onChangeName={(v) => {
                  const z = [...zones];
                  z[i].name = v.target.value;
                  setZones(z);
                }}
                isEdited={true}
              />
            ))}
          </FeatureGroup>
        )}
        <Marker
          icon={icons.site}
          draggable={!editZoneControlShow && !editSiteControlShow}
          ondragend={(e) =>
            setPosition([e.target._latlng.lat, e.target._latlng.lng])
          }
          position={position}
        />
        {!editSiteControlShow && (
          <Geofence geofence={geofence} styleType="site" />
        )}
        {!editZoneControlShow &&
          zones.map((zone) => (
            <Geofence
              key={`geofence-zone-${zone.id}`}
              geofence={zone}
              styleType="zone"
            />
          ))}
      </LocationMap>

      {loading && <Loading />}
    </Container>
  );
};

export { SiteFormContainer };
