import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditableLabel from 'react-inline-editing';

import {
  Button,
  ErrorMessage,
  GraySeparator,
  Panel,
  PanelSection,
  PanelTitle,
  RequiredStar,
  Switch,
  TextField,
  TitleIcon,
} from 'front-library';
import GeofenceIcon from 'front-library/build/img/icon-geofence.svg';
import SiteIcon from 'front-library/build/img/icon-info-site.svg';

import uniqueId from 'lodash/uniqueId';
import { MeContext } from '../../../contexts/MeContext';
import { getErrorText } from '../../../helpers';
import { Form } from '../../atoms/Form/Form';
import { AddressAutocomplete } from '../../molecules/Autocomplete/AddressAutocomplete';
import { Autocomplete } from '../../molecules/Autocomplete/Autocomplete';
import {
  GET_BRANCHES,
  GET_SITE_TYPES,
} from '../../molecules/Autocomplete/graphql';
import { Loading } from '../../molecules/Loading/Loading';
import { GET_BEACONS, GET_GATEWAYS } from './graphql';
import {
  AddWifiButtonContainer,
  Container,
  Content,
  InnerFormContainer,
  TitleIconContainer,
  TitleIconMandatoryContainer,
} from './styled-components';

const siteDefaultValue = {
  bssids: [],
  address: null,
  name: '',
  latitude: 0,
  longitude: 0,
  contact: '',
  phone_number: '',
  email: '',
  reference: '',
  siteType: null,
  branches: [],
  beacons: [],
  gateways: [],
};

const PanelSiteForm = ({
  isUpdate,
  position,
  setPosition,
  setEditSiteControlShow,
  setEditZoneControlShow,
  onSave,
  onCancel,
  editZoneControlShow,
  editSiteControlShow,
  siteDefault,
  mutationLoading,
  geofence,
}) => {
  const { t } = useTranslation();
  const [site, setSite] = useState(siteDefault || siteDefaultValue);
  const [errors, setErrors] = useState({});
  const [forceOpenSectionBssids, setForceOpenSectionBssids] = useState(false);

  const context = useContext(MeContext);
  const company = context?.me?.company;

  useEffect(() => {
    forceOpenSectionBssids && setForceOpenSectionBssids(false);
  }, [forceOpenSectionBssids]);

  const addBssid = () => {
    setForceOpenSectionBssids(true);
    setSite({ ...site, bssids: [...site.bssids, 'ff:ff:ff:ff:ff:ff'] });
  };

  const editBssid = (id, value) => {
    const temp = [...site.bssids];
    temp[id] = value;
    if (value === '') {
      delete temp[id];
    }
    setSite({ ...site, bssids: temp.map((b) => b) });
  };

  const isSubmitEnabled =
    site.name && site.siteType && geofence && site.address;

  return (
    <Container>
      {mutationLoading && <Loading />}

      <Panel className="panel-create-site">
        <PanelTitle
          label={(isUpdate && t('site:update')) || t('site:create')}
        />

        <Content>
          <TitleIconContainer>
            <TitleIcon
              text={t('site:form.information')}
              img={SiteIcon}
              iconHeight={'32px'}
            />
          </TitleIconContainer>
          <Form
            onCancel={onCancel}
            onSubmit={() => onSave(site, setErrors)}
            disableSubmit={!isSubmitEnabled}
          >
            {/* Name */}
            <TextField
              errorText={getErrorText(errors, 'name', t('site:form.name'))}
              label={t('site:form.name')}
              onChange={(name) => setSite({ ...site, name })}
              required={true}
              value={site.name}
            />

            {/* Type */}
            <Autocomplete
              errorText={getErrorText(
                errors,
                'site_type.connect',
                t('site:form.siteType'),
              )}
              label={t('site:form.siteType')}
              onChange={(siteType) => setSite({ ...site, siteType })}
              query={GET_SITE_TYPES}
              required={true}
              value={site.siteType}
            />

            {/* Address */}
            <AddressAutocomplete
              errorText={getErrorText(
                errors,
                'address',
                t('site:form.address'),
              )}
              onChange={(address) => {
                setSite({ ...site, address });

                if (address.latitude && address.longitude) {
                  setPosition([address.latitude, address.longitude], 20);
                }
              }}
              value={site.address}
              onInputChange={(address) =>
                setSite({ ...site, address: { title: address, value: -1 } })
              }
            />

            {/* Branch */}
            {company?.enable_branch && (
              <Autocomplete
                errorText={getErrorText(
                  errors,
                  'branch.connect',
                  t('site:form.branch'),
                )}
                label={t('site:form.branch', { count: 1 })}
                multiple={true}
                onChange={(branches) => setSite({ ...site, branches })}
                placeholderTranslateKey="site:form.branch"
                query={GET_BRANCHES}
                value={site.branches}
              />
            )}
            {/* Latitude */}
            <TextField
              errorText={getErrorText(
                errors,
                'latitude',
                t('site:form.latitude'),
              )}
              label={t('site:form.latitude')}
              onChange={(value) => setPosition([value, position[1]])}
              type="number"
              value={position[0]}
            />
            {/* Longitude */}
            <TextField
              errorText={getErrorText(
                errors,
                'longitude',
                t('site:form.longitude'),
              )}
              label={t('site:form.longitude')}
              onChange={(value) => setPosition([position[0], value])}
              type="number"
              value={position[1]}
            />
            {/* Reference */}
            <TextField
              errorText={getErrorText(
                errors,
                'reference',
                t('site:form.reference'),
              )}
              label={t('site:form.reference')}
              onChange={(value) => setSite({ ...site, reference: value })}
              value={site.reference}
            />

            <GraySeparator />

            <div>
              {/* Geo zone */}
              <div className="box-geo-zone">
                <TitleIconMandatoryContainer>
                  <TitleIcon
                    text={t('site:form.geofence.title')}
                    img={GeofenceIcon}
                    iconHeight={'32px'}
                  />
                  <RequiredStar />
                </TitleIconMandatoryContainer>
                {errors['input.geofence']?.map((m) => (
                  <ErrorMessage
                    key={m}
                    text={m.replace(
                      /input\.[^ ]+/,
                      t('site:form.geofence.site').toLocaleLowerCase(),
                    )}
                  />
                ))}
                <p className="text-box-geo-zone text-box-geo-zone-top">
                  {t('site:form.geofence.paragraph1')}
                </p>
                <Switch
                  className="switch-geo switch-all"
                  labelSwichtTitle={t('site:form.geofence.editGeofence')}
                  checked={editSiteControlShow}
                  onChange={setEditSiteControlShow}
                />
                <Switch
                  className="switch-zone switch-all"
                  labelSwichtTitle={t('site:form.geofence.editZone')}
                  checked={editZoneControlShow}
                  onChange={setEditZoneControlShow}
                />
                <p className="text-box-geo-zone text-box-geo-zone-bottom">
                  {t('site:form.geofence.paragraph2')}
                </p>
              </div>

              <GraySeparator />

              {/* WIFI mac addresses */}
              {company.enable_shipping_orders && (
                <PanelSection
                  forceOpen={forceOpenSectionBssids}
                  title={t('site:form.bssid.title')}
                  button={
                    <AddWifiButtonContainer>
                      <Button
                        onClick={addBssid}
                        text={t('site:form.bssid.button')}
                      />
                    </AddWifiButtonContainer>
                  }
                >
                  <table>
                    <tbody>
                      {site.bssids?.map((bssid, i) => (
                        <tr key={uniqueId()}>
                          <td>
                            <EditableLabel
                              text={bssid}
                              labelClassName="myLabelClass"
                              inputClassName="myInputClass"
                              inputWidth="200px"
                              inputHeight="25px"
                              inputMaxLength="50"
                              onFocusOut={(text) => editBssid(i, text)}
                            />
                            {bssid &&
                              errors[`input.bssids.${i}`]?.map((m) => (
                                <ErrorMessage
                                  key={uniqueId()}
                                  text={m.replace(
                                    /input\.[^ ]+/,
                                    t(
                                      'site:form.bssid.title',
                                    ).toLocaleLowerCase(),
                                  )}
                                />
                              ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    {errors['input.bssids']?.map((m) => (
                      <ErrorMessage text={m} key={uniqueId()} />
                    ))}
                  </table>
                </PanelSection>
              )}

              <GraySeparator />

              {/* BLE */}
              <PanelSection title={t('site:form.ble.title')}>
                <InnerFormContainer>
                  {/* Beacons */}
                  <Autocomplete
                    errorText={getErrorText(
                      errors,
                      'beacons.connect',
                      t('site:form.ble.title'),
                    )}
                    label={t('site:form.ble.beacons')}
                    multiple={true}
                    onChange={(beacons) => setSite({ ...site, beacons })}
                    placeholderTranslateKey="site:form.mag"
                    query={GET_BEACONS}
                    value={site.beacons}
                  />

                  {/* Gateways */}
                  <Autocomplete
                    label={t('site:form.ble.gateways')}
                    multiple={true}
                    onChange={(gateways) => setSite({ ...site, gateways })}
                    placeholderTranslateKey="site:form.mag"
                    query={GET_GATEWAYS}
                    value={site.gateways}
                  />
                </InnerFormContainer>
              </PanelSection>

              <GraySeparator />

              {/* Responsable */}
              <PanelSection title={t('site:form.responsable.title')}>
                <InnerFormContainer>
                  <TextField
                    errorText={getErrorText(
                      errors,
                      'contact',
                      t('site:form.responsable.name'),
                    )}
                    label={t('site:form.responsable.name')}
                    onChange={(contact) => setSite({ ...site, contact })}
                    value={site.contact}
                  />
                  <TextField
                    errorText={getErrorText(
                      errors,
                      'phone_number',
                      t('site:form.responsable.phone'),
                    )}
                    label={t('site:form.responsable.phone')}
                    onChange={(phone_number) =>
                      setSite({ ...site, phone_number })
                    }
                    value={site.phone_number}
                  />
                  <TextField
                    errorText={getErrorText(
                      errors,
                      'email',
                      t('site:form.responsable.mail'),
                    )}
                    label={t('site:form.responsable.mail')}
                    onChange={(email) => setSite({ ...site, email })}
                    value={site.email}
                  />
                </InnerFormContainer>
              </PanelSection>
            </div>
          </Form>
          <br />
        </Content>
      </Panel>
    </Container>
  );
};

export { PanelSiteForm };
