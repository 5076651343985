import React, { Fragment, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';

import { PanelSection } from 'front-library';
import assetIcon from 'front-library/build/img/asset.svg';
import arrivalSiteDelivered from 'front-library/build/img/icon-arrival-site-delivered-legend.svg';
import arrivalSitePending from 'front-library/build/img/icon-arrival-site-waiting-legend.svg';
import checkpointWaitingIcon from 'front-library/build/img/icon-check-point-waiting.svg';
import checkpointDeliveredIcon from 'front-library/build/img/icon-check-point.svg';
import geofenceIcon from 'front-library/build/img/icon-geofence-legend.svg';
import shippingOrderIcon from 'front-library/build/img/icon-shipping-order.svg';
import magDataNotPreciseIcon from 'front-library/build/img/magdatas-not-precise.svg';
import imgMagData from 'front-library/build/img/magdatas.svg';
import siteIcon from 'front-library/build/img/site.svg';

import { MeContext } from '../../../../contexts/MeContext';
import { routes } from '../../../../routes';
import {
  LegendContainer,
  LegendGrid,
  LegendIcon,
  LegendIconBigger,
  LegendPanel,
  LegendText,
} from './styled-components';

const PAGES = {
  map: 'map',
  shippingOrders: 'shippingOrders',
};

const LEGENDS = [
  {
    renderComponent: ({ t }) => (
      <Fragment key="asset">
        <LegendIcon src={assetIcon} alt="Asset" />
        <LegendText>{t('map:legend.asset')}</LegendText>
      </Fragment>
    ),
    shouldBeDisplayed: (page) => PAGES.map === page,
  },
  {
    renderComponent: ({ t, page }) => (
      <Fragment key="site">
        <LegendIcon src={siteIcon} alt="Site" />
        <LegendText>
          {PAGES.map === page
            ? t('map:legend.site')
            : t('map:legend.departureSite')}
        </LegendText>
      </Fragment>
    ),
    shouldBeDisplayed: () => true,
  },
  {
    renderComponent: ({ t }) => (
      <Fragment key="arrivalSitePending">
        <LegendIconBigger src={arrivalSitePending} alt="Arrival site pending" />
        <LegendText>{t('map:legend.arrivalSitePending')}</LegendText>
      </Fragment>
    ),
    shouldBeDisplayed: (page) => PAGES.shippingOrders === page,
  },
  {
    renderComponent: ({ t }) => (
      <Fragment key="arrivalSiteDelivered">
        <LegendIconBigger
          src={arrivalSiteDelivered}
          alt="Arrival site delivered"
        />
        <LegendText>{t('map:legend.arrivalSiteDelivered')}</LegendText>
      </Fragment>
    ),
    shouldBeDisplayed: (page) => PAGES.shippingOrders === page,
  },
  {
    renderComponent: ({ t }) => (
      <Fragment key="shippingOrder">
        <LegendIcon src={shippingOrderIcon} alt="Shipping order" />
        <LegendText>{t('map:legend.shippingOrder')}</LegendText>
      </Fragment>
    ),
    shouldBeDisplayed: (_, user) => user.company.enable_shipping_orders,
  },
  {
    renderComponent: ({ t }) => (
      <Fragment key="geofence">
        <LegendIcon src={geofenceIcon} alt="Geofence" />
        <LegendText>{t('map:legend.geofence')}</LegendText>
      </Fragment>
    ),
    shouldBeDisplayed: () => true,
  },
  {
    renderComponent: ({ t }) => (
      <Fragment key="waitingCheckpoint">
        <LegendIcon src={checkpointWaitingIcon} alt="Waiting checkpoint" />
        <LegendText>{t('map:legend.waitingCheckpoint')}</LegendText>
      </Fragment>
    ),
    shouldBeDisplayed: (page) => PAGES.shippingOrders === page,
  },
  {
    renderComponent: ({ t }) => (
      <Fragment key="deliveredCheckpoint">
        <LegendIcon src={checkpointDeliveredIcon} alt="Delivered checkpoint" />
        <LegendText>{t('map:legend.deliveredCheckpoint')}</LegendText>
      </Fragment>
    ),
    shouldBeDisplayed: (page) => PAGES.shippingOrders === page,
  },
  {
    renderComponent: ({ t }) => (
      <Fragment key="magData">
        <LegendIcon src={imgMagData} alt="Mag data" />
        <LegendText>{t('map:legend.magData')}</LegendText>
      </Fragment>
    ),
    shouldBeDisplayed: () => true,
  },
  {
    renderComponent: ({ t }) => (
      <Fragment key="magDataNotPrecise">
        <LegendIcon src={magDataNotPreciseIcon} alt="Not precise mag data" />
        <LegendText>{t('map:legend.magDataNotPrecise')}</LegendText>
      </Fragment>
    ),
    shouldBeDisplayed: (_, user) =>
      !!user.company.approximate_geolocation_interval,
  },
];

const Legend = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { me: user } = useContext(MeContext);

  const page = useMemo(() => {
    if (null !== matchPath(routes.shippingOrders.path, location.pathname)) {
      return PAGES.shippingOrders;
    }

    return PAGES.map;
  }, [location]);

  return (
    <LegendContainer>
      <LegendPanel>
        <PanelSection title={t('map:legend.title')}>
          <LegendGrid>
            {LEGENDS.filter((l) => l.shouldBeDisplayed(page, user)).map((c) =>
              c.renderComponent({ page, t }),
            )}
          </LegendGrid>
        </PanelSection>
      </LegendPanel>
    </LegendContainer>
  );
};

export { Legend };
