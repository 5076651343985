import * as Actions from './actions';

import uniqueId from 'lodash/uniqueId';
import { STEPS, isNextAvailable } from './utils';

function deliveryReducer(state, action) {
  switch (action.type) {
    case Actions.INCREMENT_ACTIVE_STEP: {
      const isLastStep =
        (state.deliveryModel && STEPS.checkpoint === state.activeStep + 1) ||
        STEPS.summary === state.activeStep + 1;

      return {
        ...state,
        activeStep: state.isDone
          ? state.deliveryModel
            ? STEPS.checkpoint
            : STEPS.summary
          : state.activeStep + 1,
        orders:
          0 !== state.activeStep
            ? state.orders.filter((order) => order.reference) // Delete unfilled order
            : state.orders, // Keep unfilled order
        checkpoints: state.checkpoints.filter(
          (checkpoint) => checkpoint.checkpointDepartureDate,
        ), // Delete unfilled checkpoint
        isNextAvailable: isNextAvailable(state.activeStep + 1, state),
        isDone: state.isDone ? state.isDone : isLastStep,
      };
    }
    case Actions.DECREMENT_ACTIVE_STEP:
      return {
        ...state,
        activeStep: 0 <= action.payload ? action.payload : state.activeStep - 1,
        isNextAvailable: true,
      };
    case Actions.SET_IS_NEXT_AVAILABLE:
      return {
        ...state,
        isNextAvailable: action.payload,
      };
    case Actions.SET_DELIVERY_REFERENCE_ACTION:
      return {
        ...state,
        deliveryReference: action.payload,
        deliveryReferenceTouched: '' !== action.payload.trim(),
        isNextAvailable: '' !== action.payload.trim(),
      };
    case Actions.SET_DELIVERY_REFERENCE_TOUCHED_ACTION:
      return {
        ...state,
        deliveryReferenceTouched: action.payload,
      };
    case Actions.SET_DELIVERY_MODEL_ACTION:
      return {
        ...state,
        deliveryModel: action.payload,
        departureSite: {},
        arrivalSite: {},
        arrivalDate: null,
        checkpoints: [],
        isDone:
          state.isDone && state.deliveryModel && !action.payload
            ? false
            : state.isDone,
      };
    case Actions.SET_ORDERS_ACTION:
      return {
        ...state,
        orders: action.payload,
        isNextAvailable: action.payload.some((order) => order.reference),
      };
    case Actions.SET_DEPARTURE_SITE_ACTION:
      return {
        ...state,
        departureSite: action.payload,
        isNextAvailable: action.payload && state.departureDate,
      };
    case Actions.SET_DEPARTURE_DATE_ACTION: {
      // If the departure date is later than one of the checkpoints or the arrival date, set isDone to false and pass to related steps
      const shouldChangeArrivalDate = action.payload?.isAfter(
        state.arrivalDate,
      );
      const shouldChangeCheckpoints = state.checkpoints.some((c) =>
        c.checkpointDepartureDate?.isBefore(action.payload),
      );
      const shouldGoToFinalStep =
        !shouldChangeArrivalDate && !shouldChangeCheckpoints
          ? state.isDone
          : false;
      const isDone =
        state.deliveryModel && action.payload && state.departureSite
          ? true
          : shouldGoToFinalStep;

      return {
        ...state,
        departureDate: action.payload,
        arrivalDate: shouldChangeArrivalDate ? null : state.arrivalDate,
        checkpoints: shouldChangeCheckpoints ? [] : state.checkpoints,
        isNextAvailable:
          (state.deliveryModel && action.payload && state.carrierReference) ||
          (state.departureSite && action.payload),
        isDone: isDone,
      };
    }
    case Actions.SET_CHECKPOINTS_ACTION: {
      // If one of the checkpoint dates is later then arrival date, set isDone to false and pass to related step
      const shouldChangeDate = action.payload.some(
        (point) =>
          point.checkpointDepartureDate > state.arrivalDate ||
          point.checkpointArrivalDate > state.arrivalDate,
      );

      return {
        ...state,
        checkpoints: action.payload,
        isNextAvailable:
          !action.payload.length ||
          action.payload.every(
            (point) =>
              point.site &&
              point.checkpointDepartureDate &&
              point.checkpointArrivalDate,
          ),
        arrivalDate: shouldChangeDate ? null : state.arrivalDate,
        isDone: shouldChangeDate ? false : state.isDone,
      };
    }
    case Actions.SET_ARRIVAL_SITE_ACTION:
      return {
        ...state,
        arrivalSite: action.payload,
        isNextAvailable:
          action.payload && state.arrivalDate && state.carrierReference,
      };
    case Actions.SET_ARRIVAL_DATE_ACTION:
      return {
        ...state,
        arrivalDate: action.payload,
        isNextAvailable:
          action.payload && state.arrivalSite && state.carrierReference,
      };
    case Actions.SET_WEIGHT_ACTION:
      return {
        ...state,
        weight: action.payload,
      };
    case Actions.SET_COMMENT_ACTION:
      return {
        ...state,
        comment: action.payload,
      };
    case Actions.SET_CARRIER_REFERENCE_ACTION:
      return {
        ...state,
        carrierReference: action.payload,
        isNextAvailable:
          (state.deliveryModel && state.departureDate && action.payload) ||
          (state.departureDate && state.arrivalSite && action.payload),
      };
    case Actions.SET_CARRIER_NAME_ACTION:
      return {
        ...state,
        carrierName: action.payload,
      };
    default:
      return { ...state };
  }
}

const INITIAL_STATE = {
  activeStep: 0,
  isNextAvailable: false,
  deliveryReference: '',
  deliveryReferenceTouched: false,
  deliveryModel: null,
  departureSite: null,
  departureDate: null,
  arrivalSite: null,
  arrivalDate: null,
  weight: '',
  comment: '',
  carrierReference: '',
  carrierName: '',
  isDone: false,
  checkpoints: [],
  orders: [
    {
      reference: '',
      asset: null,
      uniqueId: uniqueId(),
    },
  ],
};

export { deliveryReducer, INITIAL_STATE };
