import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Loading } from 'front-library';

import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { routes } from '../../../routes';
import { CallbackForm } from '../../organisms/Callbacks/CallbackForm';
import { CREATE_CALLBACK, GET_CALLBACK, UPDATE_CALLBACK } from './graphql';
import { mapCallbackToFormValues, mapFormValuesToGraphql } from './utils';

import { callbackTypesEnum } from '../../../helpers/form/callbackType';
import { Modal } from '../../molecules/Modal/Modal';

const Callback = ({ refetch }) => {
  const { t } = useTranslation();
  const { id = null } = useParams();
  const navigate = useNavigate();

  const callbackTypes = useMemo(() => {
    return Object.keys(callbackTypesEnum).map((key) => ({
      value: callbackTypesEnum[key],
      title: t(`callbacks:types.${key}`),
    }));
  }, [t]);

  const { data: callbackData, loading: getLoading } = useQueryGraphQl(
    GET_CALLBACK,
    { id },
    {},
    null === id,
  );
  const [createCallbackMutation, { loading: createLoading }] =
    useMutation(CREATE_CALLBACK);
  const [updateCallbackMutation, { loading: updateLoading }] =
    useMutation(UPDATE_CALLBACK);

  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(mapCallbackToFormValues());

  if (callbackData?.callback && !formValues.id) {
    setFormValues(mapCallbackToFormValues(callbackData.callback));
  }

  const title = useMemo(
    () => (id ? t('callbacks:update') : t('callbacks:create')),
    [id, t],
  );

  const handelSave = async () => {
    try {
      await createCallbackMutation({
        variables: mapFormValuesToGraphql(formValues),
      });

      setErrors({});
      handleClose();
      toast.success(t('callbacks:created.success', { name: formValues.name }));
      refetch();
    } catch (e) {
      setErrors(e?.graphQLErrors[0]?.extensions?.validation || {});
      toast.error(t('callbacks:created.error', { name: formValues.name }));
    }
  };

  const handleUpdate = async () => {
    try {
      await updateCallbackMutation({
        variables: mapFormValuesToGraphql(formValues),
      });

      setErrors({});
      handleClose();
      toast.success(t('callbacks:updated.success', { name: formValues.name }));
      refetch();
    } catch (e) {
      setErrors(e?.graphQLErrors[0]?.extensions.validation || {});
      toast.error(t('callbacks:updated.error', { name: formValues.name }));
    }
  };

  const handleClose = () =>
    navigate(generatePath(routes.administrationCallback.path));

  return (
    <>
      {(getLoading || createLoading || updateLoading) && <Loading />}
      <Modal onClose={handleClose} title={title}>
        {!getLoading && (
          <CallbackForm
            callbackTypes={callbackTypes}
            formValues={formValues}
            setFormValues={setFormValues}
            errors={errors}
            onCancel={handleClose}
            onSubmit={id ? handleUpdate : handelSave}
          />
        )}
      </Modal>
    </>
  );
};

Callback.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export { Callback };
