import { GraySeparator, TextField } from 'front-library';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Actions from '../../../../../stores/shippingOrder/actions';
import { TextArea, Title } from './styled-components';

const Extra = ({ state, dispatch }) => {
  const { t } = useTranslation();

  const { comment, carrierReference, carrierName } = state;

  return (
    <>
      <GraySeparator $borderStyle="dashed" />
      <Title>{t('shippingOrders:modal.steps.additionalInfo')}</Title>

      {/* Comment*/}
      <TextArea
        label={t('shippingOrders:modal.steps.comment')}
        value={comment}
        type="textarea"
        onChange={(data) =>
          dispatch({
            type: Actions.SET_COMMENT_ACTION,
            payload: data,
          })
        }
      />

      {/* Carrier reference*/}
      <TextField
        label={t('shippingOrders:modal.steps.carrierReference')}
        onChange={(data) =>
          dispatch({
            type: Actions.SET_CARRIER_REFERENCE_ACTION,
            payload: data,
          })
        }
        placeholder={t(
          'shippingOrders:modal.steps.carrierReferencePlaceholder',
        )}
        required={true}
        value={carrierReference}
      />

      {/* Carrier name*/}
      <TextField
        label={t('shippingOrders:modal.steps.carrierName')}
        placeholder={t('shippingOrders:modal.steps.carrierNamePlaceholder')}
        onChange={(data) =>
          dispatch({
            type: Actions.SET_CARRIER_NAME_ACTION,
            payload: data,
          })
        }
        value={carrierName}
      />
    </>
  );
};

Extra.propTypes = {
  state: PropTypes.object,
  dispatch: PropTypes.func,
};

export { Extra };
