import { useEffect, useState } from 'react';

function useBatchHttpQueryCollection(fetcher, params) {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      setItems([]);

      if (ignore) {
        return;
      }

      const { content, total } = await fetcher(
        {
          queryParams: params,
          page: 1,
          numberOfItemsPerPage: 1,
        },
        controller,
      );

      if (!content) {
        return;
      }

      let numberOfLoadedItems = 0;
      const iterationThreshold = 10;
      const numberOfItemsPerPage = 100;
      const numberOfQueries = Math.ceil(total / numberOfItemsPerPage);
      let promises = [];

      for (let i = 1; i <= numberOfQueries; i++) {
        promises.push(
          fetcher(
            {
              queryParams: params,
              page: i,
              numberOfItemsPerPage,
            },
            controller,
          ),
        );

        if (i % iterationThreshold === 0 || i === numberOfQueries) {
          const itemCollection = await Promise.all(promises);

          setItems(() => [
            ...itemCollection.flatMap((collection) => collection.content),
          ]);
          promises = [];

          numberOfLoadedItems += iterationThreshold * numberOfItemsPerPage;

          if (numberOfLoadedItems >= total) {
            setIsLoading(false);
          }
        }
      }
    }

    const controller = new AbortController();
    let ignore = false;
    fetchData();

    return () => {
      ignore = true;
      controller.abort('Aborted due to page/view change.');
    };
  }, [fetcher, params]);

  return { isLoading, items };
}

export { useBatchHttpQueryCollection };
