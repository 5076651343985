import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, FileInput, Label, TextField } from 'front-library';

import { getErrorText } from '../../../../helpers';
import { HelpText, Logo } from '../styled-components';

const General = ({ formValues, setFormValues, errors = {} }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Name */}
      <TextField
        errorText={getErrorText(errors, 'name', t('companies:form.name'))}
        label={t('companies:form.name')}
        onChange={(name) => setFormValues({ ...formValues, name })}
        required={true}
        value={formValues.name}
      />

      {/* Addess */}
      <TextField
        errorText={getErrorText(errors, 'address', t('companies:form.address'))}
        label={t('companies:form.address')}
        onChange={(address) => setFormValues({ ...formValues, address })}
        required={true}
        value={formValues.address}
      />

      {/* Phone */}
      <TextField
        errorText={getErrorText(
          errors,
          'phone_number',
          t('companies:form.phone'),
        )}
        label={t('companies:form.phone')}
        onChange={(phoneNumber) =>
          setFormValues({ ...formValues, phoneNumber })
        }
        placeholder="+33 9 07 26 93 74"
        required={true}
        value={formValues.phoneNumber}
      />

      {/* Default geofence */}
      <TextField
        helpText={t('companies:form.defaultGeofenceHelpText')}
        label={t('companies:form.defaultGeofence')}
        onChange={(defaultGeofence) =>
          setFormValues({ ...formValues, defaultGeofence })
        }
        required={true}
        type="number"
        value={formValues.defaultGeofence}
      />

      {/* SIRET */}
      <TextField
        label={t('companies:form.siret')}
        onChange={(legalReference) =>
          setFormValues({ ...formValues, legalReference })
        }
        value={formValues.legalReference}
      />

      {/* Logo */}
      {formValues.logoUrl && (
        <>
          <Label>{t('companies:form.currentLogo')}</Label>
          <Logo src={formValues.logoUrl} alt="logo" />
        </>
      )}
      <div>
        <Label>{t('companies:form.logo')}</Label>
        <FileInput
          file={formValues.file}
          fileName={formValues.file?.name || ''}
          label={t('companies:form.importLabel')}
          onChange={(file) => setFormValues({ ...formValues, file })}
        />
        <HelpText $marginTop="5px">{t('companies:form.logoHelpText')}</HelpText>
      </div>
      <Checkbox
        label={t('companies:form.enableLogo')}
        onChange={(e) =>
          setFormValues({ ...formValues, enableCustomLogo: e.target.checked })
        }
        checked={formValues.enableCustomLogo}
      />
    </>
  );
};

General.propTypes = {
  errors: PropTypes.object,
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
};

export { General };
