import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { routes } from '../../../../routes';
import { ListPage } from '../../../templates/ListPage';
import { CustomParameterModal } from '../CustomParameterModal';
import { DataGrid } from './DataGrid';

import { BtnInATab } from 'front-library';
import IconAddGreen from 'front-library/build/img/icon-add-green.svg';

import { useMutation } from '@apollo/client';
import { useQueryGraphQl } from '../../../../hooks/useQueryGraphQl';
import { Loading } from '../../../molecules/Loading/Loading';
import { DELETE_CUSTOM_PARAMETER, GET_CUSTOM_PARAMETERS } from '../graphql';

const CustomParametersList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    loading,
    data: customParametersData,
    refetch,
  } = useQueryGraphQl(GET_CUSTOM_PARAMETERS);
  const [deleteCustomParameterMutation, { loading: deleteLoading }] =
    useMutation(DELETE_CUSTOM_PARAMETER);

  const handleAdd = () =>
    navigate(routes.administrationCustomParameterCreation.path);

  const handleDelete = (customParameter) => {
    confirmAlert({
      title: t('common:deletion'),
      message: t('customParameters:askDelete'),
      buttons: [
        {
          label: t('common:yes'),
          onClick: () => handleDeleteConfirm(customParameter),
        },
        {
          label: t('common:no'),
        },
      ],
    });
  };

  const handleDeleteConfirm = async (customParameter) => {
    try {
      await deleteCustomParameterMutation({
        variables: { id: customParameter.id },
      });
      await refetch();
      toast.success(t('customParameters:deleted.success'));
    } catch (e) {
      console.error(e);
      toast.error(t('customParameters:deleted.error'));
    }
  };

  const modelNames = [
    {
      value: 'App\\Models\\AssetType',
      title: t('customParameters:byAssetType'),
    },
    { value: 'App\\Models\\Asset', title: t('customParameters:byAsset') },
  ];

  const valueTypes = {
    number: t('customParameters:number'),
    string: t('customParameters:string'),
    date: t('customParameters:date'),
    boolean: t('customParameters:boolean'),
    file: t('customParameters:file'),
  };

  return (
    <>
      <Routes>
        <Route
          path={routes.administrationCustomParameterCreation.partialPath}
          element={
            <CustomParameterModal
              modelNames={modelNames}
              refetch={refetch}
              valueTypes={valueTypes}
            />
          }
        />
        <Route
          path={routes.administrationCustomParameterEdition.partialPath}
          element={
            <CustomParameterModal
              modelNames={modelNames}
              refetch={refetch}
              valueTypes={valueTypes}
            />
          }
        />
      </Routes>
      <ListPage title={t('menu:customParameters')} onAdd={handleAdd}>
        <DataGrid
          modelNames={modelNames}
          valueTypes={valueTypes}
          customParameters={customParametersData?.custom_parameters?.data}
          onDelete={handleDelete}
        />
        {(loading || deleteLoading) && <Loading />}
        <div className="btnInATabContainer">
          <BtnInATab
            text={t('customParameters:addCustomParameter')}
            onClick={handleAdd}
            imgSrc={IconAddGreen}
          />
        </div>
      </ListPage>
    </>
  );
};

export { CustomParametersList };
