import styled from 'styled-components';

export const ImportPanel = styled.div`
    position: fixed;
    top: 110px;
    z-index: 2000;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 5px 20px 0 rgba(43,43,0,.25);
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(5.5px);
    width: 500px;
    padding: 15px;
    margin: 10px;
    border-radius: 5px;
    border: 1px solid #b3b3b3;
    color: #002b2b;
    display: grid;
    gap: 15px;
`;

export const Title = styled.div`
    font-family: 'Muli semi';
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Icon = styled.img`
    width: 16px;
    margin-right: 3px;

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

export const Link = styled.a`
    display: flex;
    gap: 3px;
    color: #002B2B;
`;

export const HelperText = styled.div`
    color: #707070;
    font-size: 12px;
    margin-left: 26px;
`;

export const ConfigurationTitle = styled.div`
    font-size: 16px;
    color: #002B2B;
`;
