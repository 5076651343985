import styled from 'styled-components';

export const ModalContainer = styled.div`
    position: fixed;
    z-index: 5001;
    top: 0;
    left: 0;
`;

export const Center = styled.div`
    text-align: center;
`;

export const FileIcon = styled.img`
    width: 20px;
    vertical-align: text-top;
    margin-left: 10px;
`;

export const DownloadLink = styled.button`
    color: #0d3e40;
    cursor: pointer;
`;

export const OuterContainer = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 43, 43, 0.6);
    z-index: 500;
    display: flex;
    align-items: center;
`;

export const InnerContainer = styled.div`
    width: fit-content;
    border-radius: 5px;
    background-color: white;
    margin: auto;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    padding: 20px;
`;

export const TitleContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 15px;
    align-items: center;
    margin-bottom: 20px;

    button {
        justify-self: end;
        align-self: start;
        margin-top: 5px;
        width: 15px;
        cursor: pointer;
        outline: none;

        :hover{
            opacity: 0.7;
        }
    }
`;

export const CsvTable = styled.table`
    max-width: 80vw;
    max-height: 75vh;
    display: block;
    overflow: scroll;

    td {
        border: 1px solid black;
        padding: 5px;
    }
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    max-width: 80vw;
    max-height: 75vh;
`;

export const Iframe = styled.iframe`
    max-width: 80vw;
    max-height: 75vh;
`;
