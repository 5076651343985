import L from 'leaflet';

import activeAsset from 'front-library/build/img/active-asset.svg';
import activeSite from 'front-library/build/img/active-site.svg';
import asset from 'front-library/build/img/asset.svg';
import imgSiteWaitingEdit from 'front-library/build/img/icon-arrive_modify.svg';
import imgCheckpointWaiting from 'front-library/build/img/icon-check-point-waiting.svg';
import imgCheckpoint from 'front-library/build/img/icon-check-point.svg';
import imgCheckpointWaitingEdit from 'front-library/build/img/icon-checkpoint-modify.svg';
import imgCheckpointEdit from 'front-library/build/img/icon-checkpoint-valid-modify.svg';
import imgOrder from 'front-library/build/img/icon-shipping-order.svg';
import imgSiteDeliveryConfirmedEdit from 'front-library/build/img/icon-site-arrived-valid-modify.svg';
import imgSiteDeliveryConfirmed from 'front-library/build/img/icon-site-delivery_confirmed.svg';
import imgSiteEdit from 'front-library/build/img/icon-site-modify.svg';
import imgSiteWaiting from 'front-library/build/img/icon-site-waiting.svg';
import imgMagDataNotPrecise from 'front-library/build/img/magdatas-not-precise.svg';
import imgMagData from 'front-library/build/img/magdatas.svg';
import imgSite from 'front-library/build/img/site.svg';

const icons = {
  site: new L.Icon({
    iconUrl: imgSite,
    iconRetinaUrl: imgSite,
    iconSize: new L.Point(30, 30),
    className: 'leaflet-div-icon marker-site',
  }),
  siteEdit: new L.Icon({
    iconUrl: imgSiteEdit,
    iconRetinaUrl: imgSiteEdit,
    iconSize: new L.Point(30, 30),
    className: 'leaflet-div-icon marker-site',
  }),
  siteWaiting: new L.Icon({
    iconUrl: imgSiteWaiting,
    iconRetinaUrl: imgSiteWaiting,
    iconSize: new L.Point(60, 60),
    iconAnchor: [30, 60],
    className: 'leaflet-div-icon marker-siteWaiting',
  }),
  siteWaitingEdit: new L.Icon({
    iconUrl: imgSiteWaitingEdit,
    iconRetinaUrl: imgSiteWaitingEdit,
    iconSize: new L.Point(60, 60),
    iconAnchor: [30, 60],
    className: 'leaflet-div-icon marker-siteWaiting',
  }),
  siteDeliveryConfirmedEdit: new L.Icon({
    iconUrl: imgSiteDeliveryConfirmedEdit,
    iconRetinaUrl: imgSiteDeliveryConfirmedEdit,
    iconSize: new L.Point(60, 60),
    iconAnchor: [30, 60],
    className: 'leaflet-div-icon marker-siteDeliveryConfirmed',
  }),
  siteDeliveryConfirmed: new L.Icon({
    iconUrl: imgSiteDeliveryConfirmed,
    iconRetinaUrl: imgSiteDeliveryConfirmed,
    iconSize: new L.Point(60, 60),
    iconAnchor: [30, 60],
    className: 'leaflet-div-icon marker-siteDeliveryConfirmed',
  }),
  order: new L.Icon({
    iconUrl: imgOrder,
    iconRetinaUrl: imgOrder,
    iconSize: new L.Point(30, 30),
    className: 'leaflet-div-icon marker-order',
  }),
  magData: new L.Icon({
    iconUrl: imgMagData,
    iconRetinaUrl: imgMagData,
    iconSize: new L.Point(15, 15),
    className: 'leaflet-div-icon marker-magData',
  }),
  impreciseMagData: new L.Icon({
    iconUrl: imgMagDataNotPrecise,
    iconRetinaUrl: imgMagDataNotPrecise,
    iconSize: new L.Point(15, 15),
    className: 'leaflet-div-icon marker-magData',
  }),
  asset: new L.Icon({
    iconUrl: asset,
    iconRetinaUrl: asset,
    iconSize: new L.Point(30, 30),
    iconAnchor: [16, 35],
    className: 'leaflet-div-icon marker-asset',
  }),
  assetMagData: new L.Icon({
    iconUrl: imgMagData,
    iconRetinaUrl: imgMagData,
    iconSize: new L.Point(30, 30),
    className: 'leaflet-div-icon marker-magData',
  }),
  assetMagDataNotPrecise: new L.Icon({
    iconUrl: imgMagDataNotPrecise,
    iconRetinaUrl: imgMagDataNotPrecise,
    iconSize: new L.Point(30, 30),
    className: 'leaflet-div-icon marker-magData',
  }),
  activeAsset: new L.Icon({
    iconUrl: activeAsset,
    iconRetinaUrl: activeAsset,
    iconSize: new L.Point(30, 30),
    iconAnchor: [16, 35],
    className: 'leaflet-div-icon marker-asset',
  }),
  clusterActiveAsset: new L.Icon({
    iconUrl: activeAsset,
    iconRetinaUrl: activeAsset,
    iconSize: new L.Point(30, 30),
    iconAnchor: [16, 65],
    className: 'leaflet-div-icon marker-asset',
  }),
  activeSite: new L.Icon({
    iconUrl: activeSite,
    iconRetinaUrl: activeSite,
    iconSize: new L.Point(30, 30),
    className: 'leaflet-div-icon marker-site',
  }),
  checkpoint: new L.Icon({
    iconUrl: imgCheckpoint,
    iconRetinaUrl: imgCheckpoint,
    iconSize: new L.Point(30, 30),
    className: 'leaflet-div-icon marker-checkpoint',
  }),
  checkpointEdit: new L.Icon({
    iconUrl: imgCheckpointEdit,
    iconRetinaUrl: imgCheckpointEdit,
    iconSize: new L.Point(30, 30),
    className: 'leaflet-div-icon marker-checkpoint',
  }),
  checkpointWaiting: new L.Icon({
    iconUrl: imgCheckpointWaiting,
    iconRetinaUrl: imgCheckpointWaiting,
    iconSize: new L.Point(30, 30),
    className: 'leaflet-div-icon marker-checkpointWaiting',
  }),
  checkpointWaitingEdit: new L.Icon({
    iconUrl: imgCheckpointWaitingEdit,
    iconRetinaUrl: imgCheckpointWaitingEdit,
    iconSize: new L.Point(30, 30),
    className: 'leaflet-div-icon marker-checkpointWaiting',
  }),
};

export { icons };
