import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from 'front-library';

import { getErrorText } from '../../../helpers';
import { Form } from '../../atoms/Form/Form';

const BranchForm = ({
  errors = {},
  formValues,
  setFormValues,
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Form onCancel={onCancel} onSubmit={onSubmit}>
      <TextField
        errorText={getErrorText(errors, 'name', t('branches:form.name'))}
        label={t('branches:form.name')}
        onChange={(name) => setFormValues({ ...formValues, name })}
        required={true}
        value={formValues.name}
      />

      <TextField
        errorText={getErrorText(
          errors,
          'reference',
          t('branches:form.reference'),
        )}
        label={t('branches:form.reference')}
        onChange={(reference) => setFormValues({ ...formValues, reference })}
        value={formValues.reference}
      />
    </Form>
  );
};

BranchForm.propTypes = {
  errors: PropTypes.object,
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { BranchForm };
