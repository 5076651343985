const INCREMENT_ACTIVE_STEP = 'INCREMENT_ACTIVE_STEP';
const DECREMENT_ACTIVE_STEP = 'DECREMENT_ACTIVE_STEP';
const SET_IS_NEXT_AVAILABLE = 'SET_IS_NEXT_AVAILABLE';
const SET_DELIVERY_REFERENCE_ACTION = 'SET_DELIVERY_REFERENCE_ACTION';
const SET_DELIVERY_REFERENCE_TOUCHED_ACTION =
  'SET_DELIVERY_REFERENCE_TOUCHED_ACTION';
const SET_DELIVERY_MODEL_ACTION = 'SET_DELIVERY_MODEL_ACTION';
const SET_ORDERS_ACTION = 'SET_ORDERS_ACTION';
const SET_DEPARTURE_SITE_ACTION = 'SET_DEPARTURE_SITE_ACTION';
const SET_DEPARTURE_DATE_ACTION = 'SET_DEPARTURE_DATE_ACTION';
const SET_CHECKPOINTS_ACTION = 'SET_CHECKPOINTS_ACTION';
const SET_ARRIVAL_SITE_ACTION = 'SET_ARRIVAL_SITE_ACTION';
const SET_ARRIVAL_DATE_ACTION = 'SET_ARRIVAL_DATE_ACTION';
const SET_WEIGHT_ACTION = 'SET_WEIGHT_ACTION';
const SET_COMMENT_ACTION = 'SET_COMMENT_ACTION';
const SET_CARRIER_REFERENCE_ACTION = 'SET_CARRIER_REFERENCE_ACTION';
const SET_CARRIER_NAME_ACTION = 'SET_CARRIER_NAME_ACTION';

export {
  INCREMENT_ACTIVE_STEP,
  DECREMENT_ACTIVE_STEP,
  SET_IS_NEXT_AVAILABLE,
  SET_DELIVERY_REFERENCE_ACTION,
  SET_DELIVERY_REFERENCE_TOUCHED_ACTION,
  SET_DELIVERY_MODEL_ACTION,
  SET_ORDERS_ACTION,
  SET_DEPARTURE_SITE_ACTION,
  SET_DEPARTURE_DATE_ACTION,
  SET_CHECKPOINTS_ACTION,
  SET_ARRIVAL_SITE_ACTION,
  SET_ARRIVAL_DATE_ACTION,
  SET_WEIGHT_ACTION,
  SET_COMMENT_ACTION,
  SET_CARRIER_REFERENCE_ACTION,
  SET_CARRIER_NAME_ACTION,
};
