import { useMutation } from '@apollo/client';
import IconReturn from 'front-library/build/img/icon-return.svg';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { routes } from '../../../routes';
import { Loading } from '../../molecules/Loading/Loading';
import { ListPage } from '../../templates/ListPage';
import { CustomAlertsPanel } from './CustomAlertsPanel';
import { PanelAlertAsset } from './PanelAlertAsset';
import { PanelAlertDelivery } from './PanelAlertDelivery';
import { PanelAlertReport } from './PanelAlertReport';

import { BackButton, Username } from './styled-components';

import { useParams } from 'react-router-dom';
import {
  GET_AUTOMATIONS,
  GET_USER_ALERT,
  GET_USER_ALERT_BY_ID,
  UPDATE_USER_ALERT,
  UPDATE_USER_AUTOMATED_ACTION,
} from './graphql';

let timeoutSave = null;

const Alert = () => {
  const { t } = useTranslation();
  const { id = null } = useParams();

  const { data: userData, loading: userLoading } = useQueryGraphQl(
    id ? GET_USER_ALERT_BY_ID : GET_USER_ALERT,
    { id },
  );
  const { data: automationData, loading: automationLoading } =
    useQueryGraphQl(GET_AUTOMATIONS);
  const [updateUserAlert] = useMutation(UPDATE_USER_ALERT);
  const [updateUserAutomatedAction] = useMutation(UPDATE_USER_AUTOMATED_ACTION);
  const [user, setUser] = useState(null);
  const [userAlerts, setUserAlerts] = useState([]);
  const [userAutomations, setUserAutomations] = useState([]);

  useEffect(() => {
    if (!userData) {
      return;
    }

    if (userData?.me) {
      setUser({
        ...userData.me,
        rolesName: userData.me.roles.map((r) => r.name),
      });
      setUserAlerts(userData.me.alerts.data);
      setUserAutomations(userData.me.automated_actions?.data);

      return;
    }

    setUser({
      ...userData.user,
      rolesName: userData.user.roles.map((r) => r.name),
    });
    setUserAlerts(userData.user.alerts.data);
    setUserAutomations(userData.user.automated_actions.data);
  }, [userData]);

  const isSmsEnabled = useMemo(() => user?.company?.is_sms_enabled, [user]);

  const isAllowed = useCallback(
    (level, alertId, type) => {
      if (!user?.roles?.find(({ name }) => name === 'sms')) {
        return false;
      }

      return 'alert' === level
        ? !!userAlerts?.find(
            (a) => a.id === alertId && a.pivot.is_allowed & type,
          )
        : !!userAutomations?.find(
            (a) => a.id === alertId && a.pivot.is_allowed & type,
          );
    },
    [user, userAlerts, userAutomations],
  );

  const isActivated = useCallback(
    (level, alertId, type) => {
      return 'alert' === level
        ? !!userAlerts?.find(
            (a) => a.id === alertId && a.pivot.is_active & type,
          )
        : !!userAutomations?.find(
            (a) => a.id === alertId && a.pivot.is_active & type,
          );
    },
    [userAlerts, userAutomations],
  );

  const onAlertChange = (level, alertId, type, checked, parameter = null) => {
    switch (level) {
      case 'rapport':
      case 'alert': {
        let newUserAlerts = [...userAlerts];
        const alert = userAlerts.find((a) => a.id === alertId);

        if (alert) {
          if (level === 'rapport') {
            if (checked) {
              newUserAlerts = newUserAlerts.map((a) =>
                a.id !== alertId ? a : { id: alertId, pivot: { parameter } },
              );
            } else {
              newUserAlerts = newUserAlerts.filter((a) => a.id !== alertId);
            }
          } else {
            newUserAlerts = newUserAlerts.map((a) =>
              a.id !== alertId
                ? a
                : {
                    id: alertId,
                    pivot: checked
                      ? {
                          type:
                            a.pivot.type & type
                              ? a.pivot.type
                              : a.pivot.type + type,
                          is_allowed:
                            a.pivot.is_allowed & type
                              ? a.pivot.is_allowed
                              : a.pivot.is_allowed + type,
                          is_active: a.pivot.is_active + type,
                        }
                      : {
                          type:
                            a.pivot.type & type
                              ? a.pivot.type
                              : a.pivot.type - type,
                          is_allowed:
                            a.pivot.is_allowed & type
                              ? a.pivot.is_allowed
                              : a.pivot.is_allowed - type,
                          is_active: a.pivot.is_active - type,
                        },
                  },
            );
          }
        } else if (level === 'rapport') {
          newUserAlerts.push({ id: alertId, pivot: { parameter } });
        } else {
          newUserAlerts.push({
            id: alertId,
            pivot: { type: type, is_allowed: type, is_active: type },
          });
        }

        setUserAlerts(newUserAlerts);

        onAlertSave(newUserAlerts);
        break;
      }
      case 'automation': {
        let newUserAutomations = [...userAutomations];
        const authorization = userAutomations.find((a) => a.id === alertId);

        if (authorization) {
          newUserAutomations = newUserAutomations.map((a) =>
            a.id !== alertId
              ? a
              : {
                  id: alertId,
                  pivot: checked
                    ? {
                        type:
                          a.pivot.type & type
                            ? a.pivot.type
                            : a.pivot.type + type,
                        is_allowed:
                          a.pivot.is_allowed & type
                            ? a.pivot.is_allowed
                            : a.pivot.is_allowed + type,
                        is_active: a.pivot.is_active + type,
                      }
                    : {
                        type:
                          a.pivot.type & type
                            ? a.pivot.type
                            : a.pivot.type - type,
                        is_allowed:
                          a.pivot.is_allowed & type
                            ? a.pivot.is_allowed
                            : a.pivot.is_allowed - type,
                        is_active: a.pivot.is_active - type,
                      },
                },
          );
        } else {
          newUserAutomations.push({
            id: alertId,
            pivot: { type: type, is_allowed: type, is_active: type },
          });
        }

        setUserAutomations(newUserAutomations);

        onAutomationSave(newUserAutomations);
        break;
      }
      default:
    }
  };

  const onAlertSave = (alerts) => {
    if (timeoutSave) {
      clearTimeout(timeoutSave);
    }

    timeoutSave = setTimeout(async () => {
      try {
        await updateUserAlert({
          variables: {
            alerts: alerts.map((a) => ({
              id: a.id,
              parameter: a.pivot.parameter,
              type: a.pivot.type,
              is_allowed: a.pivot.is_allowed,
              is_active: a.pivot.is_active,
            })),
            id: user.id,
          },
        });

        toast.success(t('alert:update.success'));
      } catch (_e) {
        toast.error(t('alert:update.error'));
      }
    }, 600);
  };

  const onAutomationSave = (automations) => {
    if (timeoutSave) {
      clearTimeout(timeoutSave);
    }

    timeoutSave = setTimeout(async () => {
      try {
        await updateUserAutomatedAction({
          variables: {
            automated_actions: automations.map((a) => ({
              id: a.id,
              type: a.pivot.type,
              is_allowed: a.pivot.is_allowed,
              is_active: a.pivot.is_active,
            })),
            id: user.id,
          },
        });

        toast.success(t('alert:update.success'));
      } catch (_e) {
        toast.error(t('alert:update.error'));
      }
    }, 600);
  };

  const pageTitle = () => {
    if (userData?.user && user) {
      return (
        <>
          {t('menu:notificationFor', { user: userData.user })}{' '}
          <Username>{user.username}</Username>
        </>
      );
    }

    return t('menu:notification');
  };

  return (
    <>
      {(userLoading || automationLoading) && <Loading />}

      {!(userLoading || automationLoading) && user && (
        <ListPage
          title={pageTitle()}
          leftElement={
            userData?.user && (
              <BackButton
                img={IconReturn}
                imgOnlyOnMobile={true}
                onClick={() => window.history.back()}
                text={t('menu:return')}
                variant="borderlessGreen"
              />
            )
          }
        >
          <div className="panel-container">
            {routes.panelAlertReport.allowed(user) && (
              <PanelAlertReport
                onChange={onAlertChange}
                userAlerts={userAlerts}
              />
            )}
            {routes.panelAlertDelivery.allowed(user) && (
              <PanelAlertDelivery
                onChange={onAlertChange}
                user={user}
                isSmsEnabled={isSmsEnabled}
                isAllowed={isAllowed}
                isActivated={isActivated}
              />
            )}
            {routes.panelAlertAsset.allowed(user) &&
              (user.company.enable_temperature ||
                user.company.enable_shock) && (
                <PanelAlertAsset
                  onChange={onAlertChange}
                  user={user}
                  isSmsEnabled={isSmsEnabled}
                  isAllowed={isAllowed}
                  isActivated={isActivated}
                />
              )}
            {routes.panelCustomAlerts.allowed(user) &&
              automationData?.automated_actions?.data?.length > 0 && (
                <CustomAlertsPanel
                  automations={automationData?.automated_actions?.data}
                  onChange={onAlertChange}
                  user={user}
                  isSmsEnabled={isSmsEnabled}
                  isAllowed={isAllowed}
                  isActivated={isActivated}
                  canCreateAlert={
                    !!userData?.me?.roles?.find(
                      ({ name }) => name === 'Administrator',
                    )
                  }
                />
              )}
          </div>
        </ListPage>
      )}
    </>
  );
};

export { Alert };
