import { TitleIcon } from 'front-library';
import BellIcon from 'front-library/build/img/icon-bell.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../routes';
import {
  AlertMaxTemperature,
  AlertMinTemperature,
  AlertShippingOrderDelayed,
  AlertShippingOrderDeviated,
  AlertShippingOrderLikelyDeviated,
  AlertShippingOrderNotArrived,
  AlertShippingOrderOnDeliverySite,
  AlertShippingOrderStarted,
  AlertShockDetected,
  AlertTourDelayed,
  AlertTourOnDeliverySite,
  AlertUntrackedShippingOrder,
  SMS,
} from '../../pages/Alert/constants';
import {
  CheckboxContainer,
  Label,
  TitleCheckboxContainer,
  TitleIconContainer,
} from './styled-components';

const SmsAlertField = ({ currentUser, automations, formData, setFormData }) => {
  const { t } = useTranslation();

  const onItemChange = (itemId, collectionName, checked) => {
    let newUserItems = [...formData[collectionName]];
    const searchedItem = formData[collectionName].find((a) => a.id === itemId);

    if (searchedItem) {
      newUserItems = newUserItems.map((a) => {
        if (a.id !== itemId) {
          return a;
        }

        const type = checked ? a.pivot.type + SMS : a.pivot.type - SMS;
        const is_allowed = checked
          ? a.pivot.is_allowed + SMS
          : a.pivot.is_allowed - SMS;
        const is_active =
          a.pivot.is_active & SMS
            ? checked
              ? a.pivot.is_active + SMS
              : a.pivot.is_active - SMS
            : a.pivot.is_active;

        return { id: itemId, pivot: { type, is_allowed, is_active } };
      });
    } else {
      newUserItems.push({ id: itemId, pivot: { type: SMS, is_allowed: SMS } });
    }

    setFormData({ ...formData, [collectionName]: newUserItems });
  };

  const renderCheckbox = (label, alertConstant) => {
    return (
      <CheckboxContainer
        label={label}
        onChange={(e) =>
          onItemChange(alertConstant, 'alerts', e.target.checked)
        }
        checked={
          !!formData.alerts.find(
            (a) => a.id === alertConstant && a.pivot.is_allowed & SMS,
          )
        }
      />
    );
  };

  return (
    <>
      <TitleIconContainer>
        <TitleIcon text={t('user:form.alertSection')} img={BellIcon} />
      </TitleIconContainer>
      {/* Asset */}
      {routes.panelAlertAsset.allowed(formData) &&
        (currentUser.company.enable_temperature ||
          currentUser.company.enable_shock) && (
          <TitleCheckboxContainer>
            <Label>{t('user:form.asset')}</Label>
            {currentUser.company.enable_temperature && (
              <>
                {/* Temperature is below the minimum */}
                {renderCheckbox(
                  t('alert:asset.alertMinTemperature'),
                  AlertMinTemperature,
                )}

                {/* Temperature is above the maximum */}
                {renderCheckbox(
                  t('alert:asset.alertMaxTemperature'),
                  AlertMaxTemperature,
                )}
              </>
            )}
            {currentUser.company.enable_shock && (
              <>
                {/* A shock is detected */}
                {renderCheckbox(
                  t('alert:asset.alertShockDetected'),
                  AlertShockDetected,
                )}
              </>
            )}
          </TitleCheckboxContainer>
        )}
      {/* Delivery */}
      {routes.panelAlertDelivery.allowed(currentUser) && (
        <TitleCheckboxContainer>
          <Label>{t('user:form.delivery')}</Label>
          {/* Delivery in progress */}
          {renderCheckbox(
            t('alert:delivery.alertShippingOrderStarted'),
            AlertShippingOrderStarted,
          )}

          {/* Delivery delayed on delivery site */}
          {renderCheckbox(
            t('alert:delivery.alertShippingOrderDelayed'),
            AlertShippingOrderDelayed,
          )}

          {/* Overdue at one of the transit points */}
          {renderCheckbox(
            t('alert:delivery.alertTourDelayed'),
            AlertTourDelayed,
          )}

          {/* Arrival at one of the waypoints */}
          {renderCheckbox(
            t('alert:delivery.alertTourOnDeliverySite'),
            AlertTourOnDeliverySite,
          )}

          {/* Arrived at delivery site */}
          {renderCheckbox(
            t('alert:delivery.alertShippingOrderOnDeliverySite'),
            AlertShippingOrderOnDeliverySite,
          )}

          {/* Delivery slot overdue */}
          {renderCheckbox(
            t('alert:delivery.alertShippingOrderNotArrived'),
            AlertShippingOrderNotArrived,
          )}

          {/* Delivery not tracked */}
          {renderCheckbox(
            t('alert:delivery.alertUntrackedShippingOrder'),
            AlertUntrackedShippingOrder,
          )}

          {/* Deviated some */}
          {renderCheckbox(
            t('alert:delivery.alertDeviated'),
            AlertShippingOrderDeviated,
          )}

          {/* Likely deviated */}
          {currentUser?.company?.likely_deviated_max > 0 &&
            renderCheckbox(
              t('alert:delivery.alertLikelyDeviated'),
              AlertShippingOrderLikelyDeviated,
            )}
        </TitleCheckboxContainer>
      )}
      {/* Automation */}
      {routes.panelCustomAlerts.allowed(currentUser) &&
        automations.length > 0 && (
          <TitleCheckboxContainer>
            <Label>{t('user:form.automation')}</Label>
            {automations.map((automation) => (
              <CheckboxContainer
                key={automation.id}
                label={automation.name}
                onChange={(e) =>
                  onItemChange(automation.id, 'automations', e.target.checked)
                }
                checked={
                  !!formData.automations.find(
                    (a) => a.id === automation.id && a.pivot.is_allowed & SMS,
                  )
                }
              />
            ))}
          </TitleCheckboxContainer>
        )}
    </>
  );
};

export { SmsAlertField };
