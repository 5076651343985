import L from 'leaflet';
import React, { useRef, Fragment, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Polyline } from 'react-leaflet';

import {
  DATE_OUT_ACTION,
  DATE_OVER_ACTION,
  MARKER_OUT_ACTION,
  MARKER_OVER_ACTION,
  PATH_OUT_ACTION,
  PATH_OVER_ACTION,
  POPUP_OUT_ACTION,
  RESET_ACTION,
} from '../../../../stores/assetPath/actions';
import {
  ASSET_PATH_INITIAL_STATE,
  assetPathReducer,
} from '../../../../stores/assetPath/reducer';

import { AssetMarker } from '../Markers/AssetMarker';
import { PolylineDecorator } from './PolylineDecorator';
import './styles.css';

const PATH_COLOR = 'rgba(49, 155, 155, 1)';
const PATH_TRANSPARENT_COLOR = 'rgba(153, 191, 187, 0.4)';

/**
 * Show interactive map with all asset movements and points.
 * @param {Array<{latitude: string, longitude: string, device_timestamp: string}>} movements Contains all positions without
 * following doublons. If there is 2 positions with same latitude and logitude, we keep only the last one.
 * @param {Array<{latitude: string, longitude: string, timestamps: Array<String>}>} singlePoints Contains all points with device timestamps.
 * @param {Array<{latitude: string, longitude: string, timestamp string}>} allPoints Contains all point, not filtered.
 * @returns Map with arrow and points
 */
const AssetPositions = ({ movements, singlePoints, allPoints }) => {
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(
    assetPathReducer,
    ASSET_PATH_INITIAL_STATE,
  );

  const currentPopupStayOpenned = useRef(false);

  window.resetMap = () => {
    // For dev, reset map state
    dispatch({ type: RESET_ACTION });
  };

  window.showMapState = () => {
    // For dev, it shows map state
    console.info(state);
  };

  /**
   * Copy of pathOver state, to use in timeouts
   */
  const pathOverRef = useRef(null);

  const canRenderAssetPositions = movements && singlePoints && allPoints;

  let leavePopupTimeout = null;
  let leaveDatesTimeout = null;

  const { pathOver, opennedPopups, markerOver, dateOver } = state;

  /**
   * Check if we must color path or not.
   * @param {number} index Index of point in movements
   */
  const mustColorPath = (index) => {
    const pathCondition =
      null !== pathOver &&
      (pathOver === index || pathOver + 1 === index || pathOver - 1 === index);
    const pointCondition =
      null === dateOver.indexOver && markerOver.movementIndexes.includes(index);
    const dateCondition = dateOver.movementIndexes.includes(index);

    return pathCondition || pointCondition || dateCondition;
  };

  const mustColorSomething = () =>
    null !== pathOver ||
    opennedPopups.indexes.length ||
    null !== markerOver.index;

  /**
   * Compute which popups should be openned.
   * @param {Array<string>} coords Latitude and longitude
   * @param {number} index Index in movements
   */
  const computeOpennedPopups = (coords, index) => {
    if (null !== markerOver.index) {
      return;
    }

    const firstIndex = singlePoints.findIndex(
      ({ latitude, longitude }) =>
        latitude === coords[0] && longitude === coords[1],
    );
    const indexes = [firstIndex];
    const timestamps = { [firstIndex]: movements[index].device_timestamp };

    if (index < movements.length - 1) {
      const nextMovement = movements[index + 1];
      const secondIndex = singlePoints.findIndex(
        ({ latitude, longitude }) =>
          latitude === nextMovement.latitude &&
          longitude === nextMovement.longitude,
      );

      indexes.push(secondIndex);
      timestamps[secondIndex] = nextMovement.device_timestamp;
    }

    pathOverRef.current = index;

    dispatch({
      type: PATH_OVER_ACTION,
      payload: {
        opennedPopups: { indexes, timestamps },
        pathOver: index,
      },
    });
  };

  /**
   * Compute needed informations to show when a marker is over.
   * @param {{ latitude: string, longitude: string, timestamps: Array<String>}} point Point where marker is over
   * @param {number} index Index in singlePoints
   */
  const handleMarkerOver = (point, index) => {
    const timestamp = point.timestamps[point.timestamps.length - 1];
    const movementIndexes = [
      movements.findIndex(
        ({ device_timestamp }) => device_timestamp === timestamp,
      ),
    ];

    if (movementIndexes[0] > 0) {
      movementIndexes.push(movementIndexes[0] - 1);
    }

    dispatch({
      type: MARKER_OVER_ACTION,
      payload: {
        index,
        movementIndexes,
        timestamps: point.timestamps,
      },
    });
  };

  /**
   * Trigerred when a date is over in a popup.
   * @param {number} indexOver Index of date over in popup dates.
   */
  const handleDateOver = (indexOver) => {
    const dateData = {
      indexOver,
      bold: [],
      movementIndexes: [],
    };

    const popupsData = {
      indexes: [],
      timestamps: {},
    };

    const currentMarker = singlePoints[markerOver.index];
    const timestamp = markerOver.timestamps[indexOver];
    const indexInAllPoints = allPoints.findIndex(
      (p) => p.device_timestamp === timestamp,
    );

    computeDateOverBeforePoint(
      indexOver,
      indexInAllPoints,
      currentMarker,
      dateData,
      popupsData,
    );
    computeDateOverAfterPoint(
      indexOver,
      indexInAllPoints,
      singlePoints,
      currentMarker,
      dateData,
      popupsData,
    );

    dispatch({
      type: DATE_OVER_ACTION,
      payload: {
        dateOver: dateData,
        opennedPopups: popupsData,
      },
    });
  };

  /**
   * This function is used to compute what informations we should display about the point before the date over in the popup.
   * @param {number} indexOver Dates index over in the popup.
   * @param {number} indexInAllPoints Point index in allPoints array.
   * @param {{ latitude: number, longitude: number, timestamps: Array<string>}} currentMarker Marker over
   * @param {{ indexOver: number, bold: Array<number>, movementIndexes: Array<number>}} dateData Data to fill to show dates infos
   * @param {{ indexes: Array<number>, timestamps: {[number]: string }}} popupsData Data to fill to show in popups
   */
  const computeDateOverBeforePoint = (
    indexOver,
    indexInAllPoints,
    currentMarker,
    dateData,
    popupsData,
  ) => {
    if (indexInAllPoints < 1) {
      return;
    }

    const previousPoint = allPoints[indexInAllPoints - 1];

    if (
      previousPoint.latitude === currentMarker.latitude &&
      previousPoint.longitude === currentMarker.longitude
    ) {
      // Previous point is same marker, we put dates in bold
      dateData.bold.push(indexOver - 1);

      return;
    }

    // Otherwise, we have to find the paths to color and the popups to open
    const prevMovIndex = movements.findIndex(
      (m) =>
        m.latitude === previousPoint.latitude &&
        m.longitude === previousPoint.longitude &&
        m.device_timestamp === previousPoint.device_timestamp,
    );
    const prevMov = movements[prevMovIndex];
    const prevPointIndex = singlePoints.findIndex(
      (p) =>
        p.latitude === prevMov.latitude &&
        p.longitude === prevMov.longitude &&
        p.timestamps.includes(prevMov.device_timestamp),
    );

    dateData.movementIndexes.push(prevMovIndex);
    popupsData.indexes.push(prevPointIndex);
    popupsData.timestamps[prevPointIndex] = prevMov.device_timestamp;
  };

  /**
   * This function is used to compute what informations we should display about the point before the date over in the popup.
   * @param {number} indexOver Dates index over in the popup.
   * @param {number} indexInAllPoints Point index in allPoints array.
   * @param {Array<{latitude: number, longitude: number, timestamps: Array<string>}} singlePoints Single points.
   * @param {{ latitude: number, longitude: number, timestamps: Array<string>}} currentMarker Marker over.
   * @param {{ indexOver: number, bold: Array<number>, movementIndexes: Array<number>}} dateData Data to fill to show dates infos.
   * @param {{ indexes: Array<number>, timestamps: {[number]: string }}} popupsData Data to fill to show in popups.
   */
  const computeDateOverAfterPoint = (
    indexOver,
    indexInAllPoints,
    singlePoints,
    currentMarker,
    dateData,
    popupsData,
  ) => {
    if (indexInAllPoints >= allPoints.length - 1) {
      return;
    }

    const nextPoint = allPoints[indexInAllPoints + 1];

    if (
      nextPoint.latitude === currentMarker.latitude &&
      nextPoint.longitude === currentMarker.longitude
    ) {
      dateData.bold.push(indexOver + 1);

      return;
    }

    const nextMovIndex = findNextMovement(
      nextPoint.latitude,
      nextPoint.longitude,
      allPoints[indexInAllPoints].device_timestamp,
    );

    if (null !== nextMovIndex) {
      dateData.movementIndexes.push(nextMovIndex - 1);
    }

    const nextMarkerIndex = singlePoints.findIndex(
      (p) =>
        p.latitude === nextPoint.latitude &&
        p.longitude === nextPoint.longitude &&
        p.timestamps.includes(nextPoint.device_timestamp),
    );

    if (nextMarkerIndex) {
      popupsData.indexes.push(nextMarkerIndex);

      if (popupsData.timestamps[nextMarkerIndex]) {
        // If something is already here it means the previous timestamp was same point
        popupsData.timestamps[nextMarkerIndex] = [
          popupsData.timestamps[nextMarkerIndex],
          nextPoint.device_timestamp,
        ];
      } else {
        popupsData.timestamps[nextMarkerIndex] = nextPoint.device_timestamp;
      }
    }
  };

  const findNextMovement = (latitude, longitude, timestamp) => {
    for (let i = 0, l = movements.length; i < l; i++) {
      const mov = movements[i];

      if (
        mov.latitude === latitude &&
        mov.longitude === longitude &&
        mov.device_timestamp > timestamp
      ) {
        return i;
      }
    }

    return null;
  };

  /**
   * Get timestamps to show in a popup.
   * @param {number} index Index of point in singlePoints
   */
  const getPopupTimestamps = (index) => {
    if (markerOver.index === index) {
      return singlePoints[index].timestamps;
    }

    if (opennedPopups.indexes.includes(index)) {
      return Array.isArray(opennedPopups.timestamps[index])
        ? opennedPopups.timestamps[index]
        : [opennedPopups.timestamps[index]];
    }

    return [];
  };

  return (
    <>
      {/* Display all asset movements */}
      {canRenderAssetPositions &&
        movements.map((position, index) => {
          const nextPosition = movements[index + 1];
          const coords = [position.latitude, position.longitude];
          const nextCoords = [nextPosition?.latitude, nextPosition?.longitude];
          const color = mustColorSomething()
            ? mustColorPath(index, position, nextPosition)
              ? PATH_COLOR
              : PATH_TRANSPARENT_COLOR
            : PATH_COLOR;

          return index < movements.length - 1 ? (
            <Fragment key={position.id}>
              <PolylineDecorator
                patterns={[
                  {
                    offset: '50%',
                    endOffset: '50%',
                    repeat: 0,
                    symbol: L.Symbol.arrowHead({
                      pixelSize: 8,
                      polygon: false,
                      pathOptions: {
                        stroke: true,
                        color: color,
                      },
                    }),
                  },
                ]}
                positions={[coords, nextCoords]}
                color={color}
              />
              {null === markerOver.index && (
                <Polyline
                  opacity={0}
                  weight={20}
                  positions={[coords, nextCoords]}
                  onclick={() => computeOpennedPopups(coords, index)}
                  onmouseover={() => computeOpennedPopups(coords, index)}
                  onmouseout={() => {
                    dispatch({ type: PATH_OUT_ACTION });
                    pathOverRef.current = null;
                  }}
                  pane={null !== markerOver.index ? 'mapPane' : 'tooltipPane'}
                />
              )}
            </Fragment>
          ) : null;
        })}

      {/* Display all asset points */}
      {canRenderAssetPositions &&
        singlePoints.map((point, index) => {
          const coords = [point.latitude, point.longitude];
          const isOpen =
            opennedPopups.indexes.includes(index) || markerOver.index === index;
          const timestamps = getPopupTimestamps(index);

          return (
            <AssetMarker
              accuracy={point.accuracy}
              key={point.id}
              coords={coords}
              notPrecise={point.notPrecise}
              popUpTitle={t('assets:passageDates', {
                count: timestamps.length,
              })}
              popUpTimestamps={timestamps}
              isOpen={isOpen}
              boldDates={dateOver.bold}
              handleMouseOver={() => handleMarkerOver(point, index)}
              handleMouseOut={() => {
                leavePopupTimeout = setTimeout(() => {
                  if (!currentPopupStayOpenned.current) {
                    dispatch({ type: MARKER_OUT_ACTION });
                  }
                }, 500);
              }}
              handleClose={() => null}
              handleMouseOverPopup={() => {
                clearTimeout(leavePopupTimeout);
                currentPopupStayOpenned.current = true;
              }}
              handleMouseOutPopup={() => {
                leavePopupTimeout = setTimeout(() => {
                  if (null !== pathOverRef.current) {
                    return;
                  }

                  currentPopupStayOpenned.current = false;
                  dispatch({ type: POPUP_OUT_ACTION });
                }, 500);
              }}
              handleDateOver={(indexOver) => {
                clearTimeout(leaveDatesTimeout);
                handleDateOver(indexOver);
              }}
              handleDatesOut={() => {
                leaveDatesTimeout = setTimeout(() => {
                  if (null !== pathOverRef.current) {
                    return;
                  }

                  dispatch({ type: DATE_OUT_ACTION });
                }, 500);
              }}
              canBeOverMarker={null === markerOver.index}
            />
          );
        })}
    </>
  );
};

export { AssetPositions };
