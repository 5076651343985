import { gql } from '@apollo/client';

const GET_BEACONS = gql`
    query GetBeacons($search: Mixed) {
        data: mags(
            first: 20,
            where: {
                AND: [
                    {
                        column: "is_gateway"
                        operator: EQ
                        value: 0
                    },
                    {
                        HAS: {
                            relation: "beacon"
                        }
                    },
                    {
                        column: "mag_id"
                        operator: LIKE
                        value: $search
                    }
                ]
            },
            orderBy: {
                column: MAG_ID
                order: ASC
            }
        ) {
            data {
                value: id
                title: mag_id
            }
        }
    }
`;

const GET_GATEWAYS = gql`
    query GetGateways($search: Mixed) {
        data: mags(
            first: 20,
            where: {
                AND: [
                    {
                        column: "is_gateway"
                        operator: EQ
                        value: 1
                    },
                    {
                        column: "mag_id"
                        operator: LIKE
                        value: $search
                    }
                ]
            },
            orderBy: {
                column: MAG_ID
                order: ASC
            }
        ) {
            data {
                value: id
                title: mag_id
            }
        }
    }
`;

export { GET_BEACONS, GET_GATEWAYS };
