import {
  convertStringToValue,
  mapCustomParametersValues,
} from '../../../helpers/customParameters';

export function mapAssetTypeToFormValues(assetType) {
  return {
    id: assetType.id,
    name: assetType.name || '',
    temperatureMin: assetType.temperature_min || null,
    temperatureMax: assetType.temperature_max || null,
  };
}

export function mapAssetTypeCustomParametersToFormValues(
  assetType,
  customParameters,
) {
  const mappedCustomParameters = {};

  for (const customParam of assetType.custom_parameters.data) {
    mappedCustomParameters[customParam.id] = convertStringToValue(
      customParam.pivot.value,
      customParameters.find(({ id }) => id === customParam.id)?.value_type,
    );
  }

  return {
    customParameters: mappedCustomParameters,
  };
}

export function mapFormValuesToGraphql({
  id,
  name,
  temperatureMin,
  temperatureMax,
  customParameters,
}) {
  return {
    ...(id && { id }),
    name,
    temperature_min: temperatureMin ? +temperatureMin : null,
    temperature_max: temperatureMax ? +temperatureMax : null,
    ...(customParameters && {
      custom_parameters: mapCustomParametersValues(customParameters),
    }),
  };
}
