import styled from 'styled-components';

export const TitleButtons = styled.div`
    display: flex;
    gap: 20px;
    margin-left: 20px;
`;

export const Container = styled.div`
    padding: 10px 14px;
`;
