import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from 'front-library';

import { getErrorText } from '../../../helpers';
import { Form } from '../../atoms/Form/Form';
import { Modal } from '../../molecules/Modal/Modal';

const FluxModal = ({ selectedFlux, onClose, onSave }) => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState({});
  const [fluxName, setFluxName] = useState(selectedFlux?.name || '');

  return (
    <Modal onClose={onClose} title={t('flux:form.newFlux')}>
      <Form
        disableSubmit={!fluxName}
        onCancel={onClose}
        onSubmit={() => onSave(fluxName, setErrors)}
      >
        <TextField
          errorText={getErrorText(errors, 'name', t('flux:form.name'))}
          label={t('flux:form.name')}
          placeholder={t('flux:form.namePlaceholder')}
          onChange={setFluxName}
          required={true}
          value={fluxName}
        />
      </Form>
    </Modal>
  );
};

export { FluxModal };
