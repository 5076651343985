import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Loading } from 'front-library';

import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { routes } from '../../../routes';
import { Modal } from '../../molecules/Modal/Modal';
import { BranchForm } from '../../organisms/Branches/BranchForm';
import { CREATE_BRANCH, GET_BRANCH, UPDATE_BRANCH } from './graphql';

const Branch = ({ refetch }) => {
  const { t } = useTranslation();
  const { id = null } = useParams();
  const navigate = useNavigate();

  const { data: branchData, loading: getLoading } = useQueryGraphQl(
    GET_BRANCH,
    { id },
    {},
    null === id,
  );
  const [createBranchMutation, { loading: createLoading }] =
    useMutation(CREATE_BRANCH);
  const [updateBranchMutation, { loading: updateLoading }] =
    useMutation(UPDATE_BRANCH);

  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState({});

  if (branchData?.branch && !formValues.id) {
    setFormValues({
      id: branchData?.branch.id,
      name: branchData?.branch.name,
      reference: branchData?.branch.reference,
    });
  }

  const title = useMemo(
    () => (id ? t('branches:update') : t('branches:create')),
    [id, t],
  );

  const handleSave = async () => {
    try {
      await createBranchMutation({ variables: formValues });

      setErrors({});
      handleClose();
      toast.success(t('branches:created.success', { name: formValues.name }));
      refetch();
    } catch (e) {
      setErrors(e?.graphQLErrors[0]?.extensions?.validation || {});
      toast.error(t('branches:created.error'));
    }
  };

  const handleUpdate = async () => {
    try {
      await updateBranchMutation({ variables: formValues });

      setErrors({});
      handleClose();
      toast.success(t('branches:updated.success', { name: formValues.name }));
      refetch();
    } catch (e) {
      setErrors(e?.graphQLErrors[0]?.extensions?.validation || {});
      toast.error(t('branches:updated.error', { name: formValues.name }));
    }
  };

  const handleClose = () =>
    navigate(generatePath(routes.administrationBranch.path));

  return (
    <Modal onClose={handleClose} title={title}>
      {(getLoading || createLoading || updateLoading) && <Loading />}
      {!getLoading && (
        <BranchForm
          errors={errors}
          formValues={formValues}
          setFormValues={setFormValues}
          onCancel={handleClose}
          onSubmit={id ? handleUpdate : handleSave}
        />
      )}
    </Modal>
  );
};

Branch.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export { Branch };
