import { ColumnCheckbox, Grid } from 'front-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import styled from 'styled-components';

import EditIcon from 'front-library/build/img/icon-modify-green-2.svg';
import DeleteIcon from 'front-library/build/img/trash.svg';

import { routes } from '../../../../routes';

const GridHeaderContainer = styled.div`
  margin-bottom: 10px;
`;

const DataGrid = ({ data, onDelete, onOrderBy, onOrderByRelation }) => {
  const { t } = useTranslation();

  return (
    <>
      <GridHeaderContainer>
        <Grid.Grid>
          <Grid.Header>
            <Grid.Cell size="xl">
              <ColumnCheckbox
                label={t('assetStates:form.name')}
                onClick={(order) => onOrderBy('NAME', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="md">
              <ColumnCheckbox
                label={t('assetStates:assetQuantity')}
                onClick={(order) =>
                  onOrderByRelation('assets', 'COUNT', null, order)
                }
              />
            </Grid.Cell>
            <Grid.Cell size="xs" />
            <Grid.Cell size="xs" />
          </Grid.Header>
        </Grid.Grid>
      </GridHeaderContainer>

      <Grid.Grid>
        {data?.map((assetState) => (
          <GridRow
            assetState={assetState}
            key={assetState.id}
            onDelete={onDelete}
          />
        ))}
      </Grid.Grid>
    </>
  );
};

const GridRow = ({ assetState, onDelete }) => {
  return (
    <Grid.Row>
      <Grid.Cell size="xl">{assetState.name}</Grid.Cell>
      <Grid.Cell size="md">{assetState.assets.paginatorInfo.total}</Grid.Cell>
      <Grid.Cell size="xs" alignment="right">
        <Link
          to={generatePath(routes.administrationAssetStateEdition.path, {
            id: assetState.id,
          })}
        >
          <Grid.IconButton imgSrc={EditIcon} />
        </Link>
      </Grid.Cell>
      <Grid.Cell size="xs" alignment="right">
        {(assetState.assets.paginatorInfo.total > 0 && (
          <Grid.IconButton imgSrc={DeleteIcon} disabled={true} />
        )) || (
          <Grid.IconButton
            imgSrc={DeleteIcon}
            onClick={() => onDelete(assetState)}
          />
        )}
      </Grid.Cell>
    </Grid.Row>
  );
};

export { DataGrid };
