import { gql } from '@apollo/client';

const GET_FILTERS_DATA = gql`
    query GetFiltersData {
        asset_types(
            where: {
                column: "name"
                operator: NEQ
                value: "Undefined"
            }
        ) {
            paginatorInfo {
                total
            }
        }
        site_types(
            where: {
                column: "name"
                operator: NOT_IN
                value: ["OFF_SITE", "Undefined"]
            }
        ) {
            paginatorInfo {
                total
            }
        }
        asset_states(first: 100) {
            data {
                value: id
                title: name
            }
        }
        branches {
            paginatorInfo {
                total
            }
        }
    }
`;

export { GET_FILTERS_DATA };
