import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  debug: false,
  lng: 'en',
  fallbackLng: 'en',
  saveMissing: true,
  interpolation: {
    escapeValue: false,
    format: (value, format, _) => {
      if ('uppercase' === format) {
        return value.toUpperCase();
      }

      if ('lowercase' === format) {
        return value.toLowerCase();
      }

      if ('capitalize' === format) {
        return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
      }

      return value;
    },
  },
  resources: {
    en: {
      auth: require('../locales/en/auth.json'),
      common: require('../locales/en/common.json'),
      error: require('../locales/en/error.json'),
      menu: require('../locales/en/menu.json'),
      filter: require('../locales/en/filter.json'),
      shippingOrderStatus: require('../locales/en/shippingOrderStatus.json'),
      site: require('../locales/en/site.json'),
      siteTypes: require('../locales/en/siteTypes.json'),
      alert: require('../locales/en/alert.json'),
      inputSuffix: require('../locales/en/inputSuffix.json'),
      customParameters: require('../locales/en/customParameters.json'),
      branches: require('../locales/en/branches.json'),
      assetStates: require('../locales/en/assetStates.json'),
      assets: require('../locales/en/assets.json'),
      assetTypes: require('../locales/en/assetTypes.json'),
      assetGroups: require('../locales/en/assetGroups.json'),
      callbacks: require('../locales/en/callbacks.json'),
      tourModels: require('../locales/en/tourModels.json'),
      user: require('../locales/en/user.json'),
      fileviewer: require('../locales/en/fileviewer.json'),
      automatedActions: require('../locales/en/automatedActions.json'),
      mapFilters: require('../locales/en/mapFilters.json'),
      flux: require('../locales/en/flux.json'),
      graphqlFilters: require('../locales/en/graphqlFilters.json'),
      import: require('../locales/en/import.json'),
      smsLinkPage: require('../locales/en/smsLinkPage.json'),
      shippingOrders: require('../locales/en/shippingOrders.json'),
      magTypes: require('../locales/en/magTypes.json'),
      companies: require('../locales/en/companies.json'),
      mags: require('../locales/en/mags.json'),
      map: require('../locales/en/map.json'),
    },
    fr: {
      auth: require('../locales/fr/auth.json'),
      common: require('../locales/fr/common.json'),
      error: require('../locales/fr/error.json'),
      menu: require('../locales/fr/menu.json'),
      filter: require('../locales/fr/filter.json'),
      shippingOrderStatus: require('../locales/fr/shippingOrderStatus.json'),
      site: require('../locales/fr/site.json'),
      siteTypes: require('../locales/fr/siteTypes.json'),
      alert: require('../locales/fr/alert.json'),
      inputSuffix: require('../locales/fr/inputSuffix.json'),
      customParameters: require('../locales/fr/customParameters.json'),
      branches: require('../locales/fr/branches.json'),
      assetStates: require('../locales/fr/assetStates.json'),
      assets: require('../locales/fr/assets.json'),
      assetTypes: require('../locales/fr/assetTypes.json'),
      assetGroups: require('../locales/fr/assetGroups.json'),
      callbacks: require('../locales/fr/callbacks.json'),
      tourModels: require('../locales/fr/tourModels.json'),
      user: require('../locales/fr/user.json'),
      fileviewer: require('../locales/fr/fileviewer.json'),
      automatedActions: require('../locales/fr/automatedActions.json'),
      mapFilters: require('../locales/fr/mapFilters.json'),
      flux: require('../locales/fr/flux.json'),
      graphqlFilters: require('../locales/fr/graphqlFilters.json'),
      import: require('../locales/fr/import.json'),
      smsLinkPage: require('../locales/fr/smsLinkPage.json'),
      shippingOrders: require('../locales/fr/shippingOrders.json'),
      magTypes: require('../locales/fr/magTypes.json'),
      companies: require('../locales/fr/companies.json'),
      mags: require('../locales/fr/mags.json'),
      map: require('../locales/fr/map.json'),
    },
  },
});

export { i18n };
