import { base64ToObject } from '../../helpers';
import * as Actions from './actions';

function filtersReducer(state, action) {
  switch (action.type) {
    case Actions.SET_STATUS_FILTER_ACTION:
      return {
        ...state,
        statusFilter: action.payload,
        pageVariable: 1,
        wait: true,
      };
    case Actions.SET_REF_FILTER_ACTION:
      return {
        ...state,
        refFilter: action.payload,
        pageVariable: 1,
        wait: true,
      };
    case Actions.SET_ASSET_FILTER_ACTION:
      return {
        ...state,
        assetFilter: action.payload,
        pageVariable: 1,
        wait: true,
      };
    case Actions.SET_ORDER_FILTER_ACTION:
      return {
        ...state,
        orderFilter: action.payload,
        pageVariable: 1,
        wait: true,
      };
    case Actions.SET_CARRIER_FILTER_ACTION:
      return {
        ...state,
        carrierFilter: action.payload,
        pageVariable: 1,
        wait: true,
      };
    case Actions.SET_DEPARTURE_FILTER_ACTION:
      return {
        ...state,
        departureFilter: action.payload,
        pageVariable: 1,
        wait: true,
      };
    case Actions.SET_ARRIVAL_FILTER_ACTION:
      return {
        ...state,
        arrivalFilter: action.payload,
        pageVariable: 1,
        wait: true,
      };
    case Actions.SET_CHECKPOINT_FILTER_ACTION:
      return {
        ...state,
        checkpointFilter: action.payload,
        pageVariable: 1,
        wait: true,
      };
    case Actions.SET_DEPARTURE_TIMESTAMP_FILTER_ACTION:
      return {
        ...state,
        departureTimestampFilter: action.payload,
        pageVariable: 1,
        wait: true,
      };
    case Actions.SET_ARRIVAL_TIMESTAMP_FILTER_ACTION:
      return {
        ...state,
        arrivalTimestampFilter: action.payload,
        pageVariable: 1,
        wait: true,
      };
    case Actions.SET_IS_LATE_VARIABLE_ACTION:
      return {
        ...state,
        isLateVariable: action.payload,
        pageVariable: 1,
        wait: false,
      };
    case Actions.SET_PAGE_VARIABLE_ACTION:
      return {
        ...state,
        pageVariable: action.payload,
        wait: false,
      };
    case Actions.SET_SEARCH_VARIABLE_ACTION:
      return {
        ...state,
        searchVariable: action.payload,
        pageVariable: 1,
        wait: false,
      };
    case Actions.SET_ORDER_BY_VARIABLE_ACTION:
      return {
        ...state,
        orderByVariable: action.payload,
        wait: false,
      };
    case Actions.SET_SAVED_FILTERS_VARIABLES_ACTION: {
      return {
        ...base64ToObject(action.payload.filters),
        savedFiltersId: action.payload.id,
        wait: false,
      };
    }
    case Actions.RESET_FILTERS_ACTION:
      return {
        ...INITIAL_STATE,
        statusFilter: [],
      };
    default:
      return { ...state };
  }
}

const INITIAL_STATE = {
  statusFilter: [1, 3, 4, 5, 7, 8, 9, 10],
  refFilter: [],
  carrierFilter: [],
  departureFilter: [],
  arrivalFilter: [],
  departureTimestampFilter: {
    dates: null,
  },
  arrivalTimestampFilter: {
    dates: null,
  },
  assetFilter: [],
  orderFilter: [],
  searchVariable: '',
  isLateVariable: false,
  firstVariable: 20,
  pageVariable: 1,
  orderByVariable: [
    {
      column: 'ID',
      order: 'DESC',
    },
  ],
  wait: false,
  savedFiltersId: null,
};

export { filtersReducer, INITIAL_STATE };
