import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderRow = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 40px;
    gap: 10px;
    align-items: center;
    margin-bottom: 6px;
`;

export const BodyRow = styled(HeaderRow)`
    margin-top: 6px;
`;

export const VerticalFlex = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const BlueText = styled.p`
    color: #6CA6AB;
    line-break: anywhere;
`;

export const BlueLink = styled(Link)`
    color: #6CA6AB;
    line-break: anywhere;
`;

export const ActionButtonsContainer = styled.div`
    display: flex;
    justify-content: end;
    gap: 8px;
`;
