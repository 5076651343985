import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Button,
  ColumnCheckbox,
  Grid,
  PageTitle,
  Select,
  Switch,
} from 'front-library';
import ResetIcon from 'front-library/build/img/reset-button.svg';

import { getShippingOrderTranslate } from '../../../../helpers';
import { exportTypesEnum } from '../../../../helpers/form/exportTypesEnum';
import * as Actions from '../../../../stores/shippingOrdersFilters/actions';
import { ExportModal } from '../../../molecules/ExportModal/ExportModal';
import { Loading } from '../../../molecules/Loading/Loading';
import { AdvancedFilters } from '../AdvancedFilters/AdvancedFilters';
import { EXPORT_SHIPPING_ORDERS } from './graphql';

import { routes } from '../../../../routes';
import { normalizeExportFilters } from '../../../pages/ShippingOrders/utils';
import {
  GridHeader,
  HeaderContainer,
  HeaderPagination,
  HeaderSearchBar,
  LateOnlyContainer,
  RightSidebarContainer,
  StatusesContainer,
  TitleContainer,
} from './styled-components';

// It will be refactored later
const statuses = [
  { value: 1, title: 'new' },
  { value: 7, title: 'paired' },
  { value: 3, title: 'delivery_in_progress' },
  { value: 9, title: 'on_site' },
  { value: 4, title: 'arrived_on_delivery_site' },
  { value: 5, title: 'delivered' },
  { value: 8, title: 'delivery_confirmed' },
  { value: 10, title: 'closed' },
  { value: 6, title: 'canceled' },
  { value: 11, title: 'canceled_by_automation' },
];

const Header = ({ title, filtersState, dispatch, paginatorInfo }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [exportShippingOrders, { loading }] = useMutation(
    EXPORT_SHIPPING_ORDERS,
  );

  const [showExport, setShowExport] = useState(false);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const { statusFilter, isLateVariable, searchVariable } = filtersState;

  const handleCreate = () => {
    navigate(routes.shippingOrdersCreation.path);
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderContainer>
        <TitleContainer>
          <PageTitle>{title}</PageTitle>
          <HeaderSearchBar
            onChange={(value) =>
              dispatch({
                type: Actions.SET_SEARCH_VARIABLE_ACTION,
                payload: value,
              })
            }
            placeholder={t('common:searchbar')}
            value={searchVariable}
          />
          <Button
            onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
            text={t('shippingOrders:advancedSearch')}
            variant="orangePlain"
          />
          <StatusesContainer>
            <Select
              multiple={true}
              onChange={(values) =>
                dispatch({
                  type: Actions.SET_STATUS_FILTER_ACTION,
                  payload: values || [],
                })
              }
              options={statuses.map(({ value, title }) => ({
                value,
                title: getShippingOrderTranslate(t, title),
              }))}
              placeholder={t('shippingOrders:filters.status', {
                count: statusFilter.length.toString(),
              })}
              selectAll={t('common:selectAll')}
              value={statuses
                .filter((s) => statusFilter.includes(s.value))
                .map((s) => s.value)}
            />
          </StatusesContainer>
          <LateOnlyContainer>
            <Switch
              checked={isLateVariable}
              labelSwichtTitle={t('shippingOrders:filters.onlyLate')}
              onChange={() =>
                dispatch({
                  type: Actions.SET_IS_LATE_VARIABLE_ACTION,
                  payload: !isLateVariable,
                })
              }
            />
          </LateOnlyContainer>

          <Button
            img={ResetIcon}
            imgOnlyOnMobile={true}
            onClick={() => dispatch({ type: Actions.RESET_FILTERS_ACTION })}
            text={t('shippingOrders:reset')}
            variant="green"
          />

          <RightSidebarContainer>
            <Button
              onClick={() => handleCreate()}
              text={t('common:add')}
              variant="green"
            />
            <Button
              onClick={() => setShowExport(true)}
              text={t('common:export')}
              variant="green"
            />
            {paginatorInfo && (
              <HeaderPagination
                count={paginatorInfo.total}
                currentPage={paginatorInfo.currentPage - 1}
                onPageChange={(page) =>
                  dispatch({
                    type: Actions.SET_PAGE_VARIABLE_ACTION,
                    payload: page + 1,
                  })
                }
                rowsPerPage={paginatorInfo.perPage}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}–${to} ${t('common:of')} ${
                    count !== -1 ? count : `${t('common:moreThan')} ${to}`
                  }`
                }
              />
            )}
          </RightSidebarContainer>
        </TitleContainer>
        <Grid.Grid>
          <GridHeader>
            <Grid.Cell size="md">
              <ColumnCheckbox
                label={t('shippingOrders:list.header.reference')}
                onClick={(order) =>
                  dispatch({
                    type: Actions.SET_ORDER_BY_VARIABLE_ACTION,
                    payload: [
                      {
                        column: 'REFERENCE',
                        order,
                      },
                    ],
                  })
                }
              />
            </Grid.Cell>
            <Grid.Cell size="md">
              <ColumnCheckbox
                label={t('shippingOrders:list.header.nextStopEstimated')}
                onClick={(order) =>
                  dispatch({
                    type: Actions.SET_ORDER_BY_VARIABLE_ACTION,
                    payload: [
                      {
                        column: 'HUMAN_ETA',
                        order,
                      },
                    ],
                  })
                }
              />
            </Grid.Cell>
            <Grid.Cell size="xl">
              <ColumnCheckbox
                label={t('shippingOrders:list.header.status')}
                onClick={(order) =>
                  dispatch({
                    type: Actions.SET_ORDER_BY_VARIABLE_ACTION,
                    payload: [
                      {
                        shipping_order_status: {
                          aggregate: 'MAX',
                          column: 'NAME',
                        },
                        order,
                      },
                    ],
                  })
                }
              />
            </Grid.Cell>
            <Grid.Cell size="xs" />
          </GridHeader>
        </Grid.Grid>
      </HeaderContainer>
      {showExport && (
        <ExportModal
          onClose={() => setShowExport(false)}
          downloadFile={async (userId, extensions) => {
            try {
              await exportShippingOrders({
                variables: {
                  user_id: userId,
                  type: exportTypesEnum.shippingOrder,
                  formats: extensions,
                  filters: normalizeExportFilters(filtersState),
                },
              });

              toast.success(t('common:exportSuccess'));
            } catch (error) {
              Sentry.captureException(error);

              toast.error(t('common:exportError'));
            }
          }}
        />
      )}
      <AdvancedFilters
        show={showAdvancedFilters}
        onClose={() => setShowAdvancedFilters(false)}
        filtersState={filtersState}
        dispatch={dispatch}
      />
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  filtersState: PropTypes.object,
  dispatch: PropTypes.func,
  paginatorInfo: PropTypes.object,
};

export { Header };
