import L from 'leaflet';
import PropTypes from 'prop-types';
import React from 'react';
import { Marker, Popup } from 'react-leaflet';

const PopupOffset = new L.Point(0, 0);

const MarkerMagma = ({
  icon,
  onMouseOver = null,
  onMouseOut = null,
  pane = 'markerPane',
  position,
  title = null,
}) => {
  return (
    <Marker
      position={position}
      icon={icon}
      onMouseOver={(e) => {
        e.target.openPopup();
        onMouseOver?.();
      }}
      onMouseOut={(e) => {
        e.target.closePopup();
        onMouseOut?.();
      }}
      pane={pane}
    >
      {title && <Popup offset={PopupOffset}>{title}</Popup>}
    </Marker>
  );
};

MarkerMagma.propTypes = {
  icon: PropTypes.object.isRequired,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  pane: PropTypes.string,
  position: PropTypes.array.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export { MarkerMagma };
