import { GraySeparator, Label, Select, Switch, TextField } from 'front-library';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getErrorText } from '../../../helpers';
import { Form } from '../../atoms/Form/Form';
import { Autocomplete } from '../../molecules/Autocomplete/Autocomplete';
import { GET_ASSET_TYPES } from '../../molecules/Autocomplete/graphql';

const CustomParameterForm = ({
  errors,
  formValues,
  modelNames,
  onCancel,
  onSubmit,
  setFormValues,
  valueTypes,
}) => {
  const { t } = useTranslation();

  const selectableValueTypes = useMemo(
    () =>
      Object.entries(valueTypes).map(([key, value]) => ({
        value: key,
        title: value,
      })),
    [valueTypes],
  );

  return (
    <Form onCancel={onCancel} onSubmit={onSubmit}>
      <Select
        errorText={getErrorText(
          errors,
          'model',
          t('customParameters:form.mode'),
        )}
        helpText={t('customParameters:form.assetTypeHelper')}
        label={t('customParameters:form.mode')}
        onChange={(model) => setFormValues({ ...formValues, model })}
        options={modelNames}
        required={true}
        value={formValues.model}
      />
      <Autocomplete
        label={t('customParameters:assetType')}
        multiple={true}
        onChange={(assetTypes) => setFormValues({ ...formValues, assetTypes })}
        query={GET_ASSET_TYPES}
        required={true}
        selectAll={true}
        value={formValues.assetTypes}
      />
      <GraySeparator />
      <TextField
        errorText={getErrorText(
          errors,
          'name',
          t('customParameters:form.name'),
        )}
        label={t('customParameters:form.name')}
        onChange={(name) => setFormValues({ ...formValues, name })}
        required={true}
        value={formValues.name}
      />
      <Select
        errorText={getErrorText(
          errors,
          'value_type',
          t('customParameters:form.valueType'),
        )}
        label={t('customParameters:form.valueType')}
        onChange={(valueType) => setFormValues({ ...formValues, valueType })}
        options={selectableValueTypes}
        required={true}
        value={formValues.valueType}
      />
      <TextField
        label={t('customParameters:description')}
        onChange={(description) =>
          setFormValues({ ...formValues, description })
        }
        type="textarea"
        value={formValues.description}
      />
      <div>
        <Label>{t('customParameters:form.permission')}</Label>
        <Switch
          labelSwichtTitle={t('customParameters:form.operatorEditable')}
          checked={formValues.operatorEditable}
          onChange={() =>
            setFormValues({
              ...formValues,
              operatorEditable: !formValues.operatorEditable,
            })
          }
        />
      </div>
    </Form>
  );
};

CustomParameterForm.propTypes = {
  errors: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  modelNames: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setFormValues: PropTypes.func.isRequired,
  valueTypes: PropTypes.object.isRequired,
};

export { CustomParameterForm };
