import styled from 'styled-components';

export const ModalContainer = styled.div`
    position: fixed;
    z-index: ${({ $zIndex }) => $zIndex};
    top: 0;
    left: 0;
`;

export const MarginModal = styled.div`
    margin-right: -12px;
`;

export const MarginScrollableContainer = styled.div`
    margin-right: 12px;
`;
