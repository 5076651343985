import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { trackPageExit, trackPageView } from '../../../helpers/mixpanel';

/**
 * This component is used to track page changes with Mixpanel.
 */
const PageTracker = ({ children, pageName }) => {
  useEffect(() => {
    trackPageView(pageName);

    return () => trackPageExit(pageName);
  }, [pageName]);

  return children;
};

PageTracker.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string.isRequired,
};

export { PageTracker };
