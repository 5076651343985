import moment from 'moment';

const Operators = {
  IN: 'IN',
  BETWEEN: 'BETWEEN',
  NEQ: 'NEQ',
  LTE: 'LTE',
  GTE: 'GTE',
};

function mapArrayToGraphQl(column, value) {
  if (0 === value.length) {
    return null;
  }

  return {
    column,
    operator: Operators.IN,
    value,
  };
}

function mapDatesToGraphQl(column, value) {
  if (shouldSkipDates(value.dates)) {
    return null;
  }

  let dates = value.dates;
  let operator = Operators.BETWEEN;

  // We do this so we can select only one date.
  if (null === dates[0]) {
    operator = Operators.LTE;
    dates = dates[1];
  } else if (null === dates[1]) {
    operator = Operators.GTE;
    dates = dates[0];
  }

  return {
    column,
    operator,
    value: dates,
  };
}

function shouldSkipDates(dates) {
  // When no dates selected
  if (null === dates) {
    return true;
  }

  // When dates are cleared
  if (2 === dates.length && !dates[0] && !dates[1]) {
    return true;
  }

  return false;
}

// Here we build all the mapping logic
const FiltersConfiguration = {
  refFilter: (value) => ({
    column: 'reference',
    operator: Operators.IN,
    graphql: mapArrayToGraphQl(
      'reference',
      value.map((option) => option.value),
    ),
  }),
  statusFilter: (value) => ({
    column: 'shipping_order_status_id',
    operator: Operators.IN,
    graphql: mapArrayToGraphQl('shipping_order_status_id', value),
  }),
  carrierFilter: (value) => ({
    column: 'carrier_id',
    operator: Operators.IN,
    graphql: mapArrayToGraphQl(
      'carrier_id',
      value.map((option) => option.value),
    ),
  }),
  departureFilter: (value) => ({
    column: 'departure_site_id',
    operator: Operators.IN,
    graphql: mapArrayToGraphQl(
      'departure_site_id',
      value.map((option) => option.value),
    ),
  }),
  departureTimestampFilter: (value) => ({
    column: 'departure_timestamp',
    operator: Operators.BETWEEN,
    graphql: mapDatesToGraphQl('departure_timestamp', value),
  }),
  arrivalFilter: (value) => ({
    column: 'arrival_site_id',
    operator: Operators.IN,
    graphql: mapArrayToGraphQl(
      'arrival_site_id',
      value.map((option) => option.value),
    ),
  }),
  arrivalTimestampFilter: (value) => ({
    column: 'arrival_timestamp',
    operator: Operators.BETWEEN,
    graphql: mapDatesToGraphQl('arrival_timestamp', value),
  }),
  checkpointFilter: (value) => ({
    column: 'checkpoints',
    operator: Operators.IN,
    graphql:
      (0 !== value.length && {
        HAS: {
          relation: 'tour.shipping_orders',
          condition: {
            AND: [
              {
                column: 'tour_order',
                operator: Operators.NEQ,
                value: null,
              },
              {
                column: 'arrival_site_id',
                operator: Operators.IN,
                value: value.map((option) => option.value),
              },
            ],
          },
        },
      }) ||
      null,
  }),
  assetFilter: (value) => ({
    column: 'assets',
    operator: Operators.IN,
    graphql:
      (0 !== value.length && {
        HAS: {
          relation: 'orders.asset',
          condition: {
            column: 'id',
            operator: Operators.IN,
            value: value.map((option) => option.value),
          },
        },
      }) ||
      null,
  }),
  orderFilter: (value) => ({
    column: 'orders',
    operator: Operators.IN,
    graphql:
      (0 !== value.length && {
        HAS: {
          relation: 'orders',
          condition: {
            column: 'id',
            operator: Operators.IN,
            value: value.map((option) => option.value),
          },
        },
      }) ||
      null,
  }),
};

export function mapFiltersToGraphQl(state) {
  const mappedFilters = {};

  for (const [key, mappingFunction] of Object.entries(FiltersConfiguration)) {
    const value = state[key];

    if (undefined === value || null === value) {
      continue;
    }

    const { column, graphql } = mappingFunction(value);

    if (null !== graphql) {
      mappedFilters[column] = graphql;
    }
  }

  return mappedFilters;
}

/**
 * Map the reducer's variables to data for graphql.
 */
export function mapVariablesToGraphQl(state) {
  return {
    isLate: state.isLateVariable,
    page: state.pageVariable,
    first: state.firstVariable,
    orderBy: state.orderByVariable,
    search: state.searchVariable || null,
  };
}

/**
 * Map the reducer's filter and variables to data for graphql.
 */
export function mapFiltersAndVariablesToGraphQl(state) {
  return {
    variables: mapVariablesToGraphQl(state),
    filters: mapFiltersToGraphQl(state),
  };
}

export function normalizeExportFilters(state) {
  const filters = {};

  for (const [key, mappingFunction] of Object.entries(FiltersConfiguration)) {
    const stateValue = state[key];

    if (undefined === stateValue || null === stateValue) {
      continue;
    }

    const config = mappingFunction(stateValue);

    if (Operators.IN === config.operator && 0 !== stateValue.length) {
      filters[config.column] = stateValue.map((v) =>
        'object' === typeof v ? v.value : v,
      );
    } else if (
      Operators.BETWEEN === config.operator &&
      null !== stateValue.dates
    ) {
      filters[config.column] = stateValue.dates.map(
        (date) =>
          moment(date.toISOString())?.format('YYYY-MM-DD HH:mm:ss') || null,
      );
    }
  }

  if (state.searchVariable) {
    filters.search = encodeURI(state.searchVariable);
  }

  return filters;
}
