import HumanizeDuration from 'humanize-duration';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PanelMessage, PanelSection } from 'front-library';

import { MeContext } from '../../../../contexts/MeContext';
import { MagPanelSectionContent } from './styled-components';

const MagPanelSection = ({ lastPosition, latestMagData }) => {
  const { t } = useTranslation();
  const { me: user } = useContext(MeContext);

  const formatValue = useCallback(
    (translationKey, value) => {
      if (null === value || undefined === value) {
        return '';
      }

      return t(translationKey, {
        humanDuration: HumanizeDuration(moment(value).local().diff(moment()), {
          units: ['y', 'mo', 'd', 'h', 'm'],
          language: user?.lang || 'en',
          largest: 2,
          maxDecimalPoints: 0,
        }),
      });
    },
    [user, t],
  );

  return (
    <PanelSection title={t('shippingOrders:panelSections.mag.title')}>
      <MagPanelSectionContent>
        <PanelMessage
          label={t('shippingOrders:panelSections.mag.lastMovement.label')}
          value={formatValue(
            'shippingOrders:panelSections.mag.lastMovement.value',
            lastPosition?.device_timestamp,
          )}
        />
        <PanelMessage
          label={t('shippingOrders:panelSections.mag.lastMessage.label')}
          value={formatValue(
            'shippingOrders:panelSections.mag.lastMessage.value',
            latestMagData?.created_at,
          )}
        />
      </MagPanelSectionContent>
    </PanelSection>
  );
};

MagPanelSection.propTypes = {
  lastPosition: PropTypes.object,
  latestMagData: PropTypes.object,
};

export { MagPanelSection };
