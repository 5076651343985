import { convertSecondsToTime, convertTimeToSeconds } from '../../../helpers';

export function mapFormValuesToGraphQl({
  id,
  name,
  reference,
  departureSite,
  tourModelPoints,
}) {
  return {
    id: id || null,
    name: name,
    reference: reference,
    departure_site: {
      connect: +departureSite.value,
    },
    tour_model_points: {
      create: tourModelPoints.map((tmp, index) => ({
        tour_order: index + 1,
        duration: convertTimeToSeconds(tmp.duration),
        site_id: +tmp.site.value,
      })),
      delete: tourModelPoints.filter((tmp) => tmp.id).map((tmp) => tmp.id),
    },
  };
}

export function mapGraphQlToFormValues(tourModel = null) {
  return {
    id: tourModel?.id || null,
    name: tourModel?.name || '',
    reference: tourModel?.reference || '',
    departureSite: tourModel?.departure_site
      ? {
          value: tourModel.departure_site.id,
          title: tourModel.departure_site.name,
        }
      : null,
    tourModelPoints: tourModel?.tour_model_points.length
      ? tourModel.tour_model_points.map((tmp) => ({
          id: tmp.id,
          tourOrder: tmp.tour_order,
          duration: convertSecondsToTime(tmp.duration),
          site: {
            value: tmp.site.id,
            title: tmp.site.name,
          },
        }))
      : [{ id: null, tourOrder: 1, duration: '00:00:00', site: null }],
  };
}
