import L from 'leaflet';
import { MapLayer, withLeaflet } from 'react-leaflet';
require('leaflet.markercluster');

class MarkerClusterGroupLayer extends MapLayer {
  createLeafletElement({ children, leaflet: { map }, ...props }) {
    const clusterProps = {};
    const clusterEvents = {};

    // Splitting props and events to different objects
    for (const [propName, prop] of Object.entries(props)) {
      if (propName.startsWith('on')) {
        clusterEvents[propName] = prop;
      } else {
        clusterProps[propName] = prop;
      }
    }

    // Creating markerClusterGroup Leaflet element
    const markerClusterGroup = new L.markerClusterGroup(clusterProps);
    this.contextValue = { layerContainer: markerClusterGroup, map };

    // Initializing event listeners
    for (const [eventAsProp, callback] of Object.entries(clusterEvents)) {
      const clusterEvent = `cluster${eventAsProp.substring(2).toLowerCase()}`;
      markerClusterGroup.on(clusterEvent, callback);
    }

    return markerClusterGroup;
  }
}

const MarkerClusterGroup = withLeaflet(MarkerClusterGroupLayer);

export { MarkerClusterGroup };
