const { gql } = require('@apollo/client');

const GET_AUTOMATED_ACTION_FORM_DATA = gql`
    query GetAutomatedActionFormData {
        AssetState: asset_states(
            first: 100
        ) {
            data {
                value: id
                title: name
            }
        }
        ShippingOrderStatus: shipping_order_statuses(
            first: 20
        ) {
            data {
                value: id
                title: name
            }
        }
    }
`;

const GET_AUTOMATED_ACTIONS = gql`
    query GetAutomatedActions($first: Int, $page: Int) {
        automated_actions(first: $first, page: $page, orderBy: { column: NAME, order: ASC }) {
            paginatorInfo{
                total
                currentPage
                lastPage
                perPage
            }
            data {
                id
                name
                model
                event
                notify
                relational_conditions {
                    data {
                        id
                        conditionable_type
                        conditionable {
                            ... on AssetType {
                                __typename
                                id
                                name
                            }
                            ... on AssetState {
                                __typename
                                id
                                name
                            }
                            ... on SiteType {
                                __typename
                                id
                                name
                            }
                            ... on Site {
                                __typename
                                id
                                name
                            }
                            ... on ShippingOrderStatus {
                                __typename
                                id
                                name
                            }
                        }
                    }
                }
                value_conditions {
                    data {
                        id
                        condition_type
                        value
                    }
                }
                actions {
                    data {
                        id
                        actionable_type
                        actionable {
                            ... on AssetState {
                                __typename
                                id
                                name
                            }
                            ... on ShippingOrderStatus {
                                __typename
                                id
                                name
                            }
                        }
                    }
                }
                created_by {
                    id
                    name
                    surname
                }
                created_at
                branch_notification
            }
        }
    }
`;

const CREATE_AUTOMATED_ACTION = gql`
    mutation CreateAutomatedAction(
        $name: String,
        $event: String,
        $model: String,
        $notify: Boolean,
        $relational_conditions: UpdateAutomatedActionRelationalConditionRelation,
        $value_conditions: UpdateAutomatedActionValueConditionRelation,
        $actions: UpdateAutomatedActionActionRelation,
        $branch_notification: Int,
    ) {
        createAutomatedAction(input: {
            name: $name
            event: $event
            model: $model
            notify: $notify
            relational_conditions: $relational_conditions,
            value_conditions: $value_conditions,
            actions: $actions,
            branch_notification: $branch_notification,
        }) {
            id
        }
    }
`;

const UPDATE_AUTOMATED_ACTION = gql`
    mutation UpdateAutomatedAction(
        $id: ID!,
        $name: String,
        $event: String,
        $model: String,
        $notify: Boolean,
        $relational_conditions: UpdateAutomatedActionRelationalConditionRelation,
        $value_conditions: UpdateAutomatedActionValueConditionRelation,
        $actions: UpdateAutomatedActionActionRelation,
        $branch_notification: Int,
    ) {
        updateAutomatedAction(input: {
            id: $id
            name: $name
            event: $event
            model: $model
            notify: $notify
            relational_conditions: $relational_conditions,
            value_conditions: $value_conditions,
            actions: $actions,
            branch_notification: $branch_notification,
        }) {
            id
        }
    }
`;

const DELETE_AUTOMATED_ACTION = gql`
    mutation DeleteAutomatedAction($id: ID!) {
        deleteAutomatedAction(id: $id) {
            id
        }
    }
`;

export {
  GET_AUTOMATED_ACTION_FORM_DATA,
  CREATE_AUTOMATED_ACTION,
  UPDATE_AUTOMATED_ACTION,
  GET_AUTOMATED_ACTIONS,
  DELETE_AUTOMATED_ACTION,
};
