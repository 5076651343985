import { ColumnCheckbox, Grid } from 'front-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import styled from 'styled-components';

import EditIcon from 'front-library/build/img/icon-modify-green-2.svg';
import DeleteIcon from 'front-library/build/img/trash.svg';

import { routes } from '../../../../routes';

const GridHeaderContainer = styled.div`
  margin-bottom: 10px;
`;

const DataGrid = ({ data, onDelete, onOrderBy, onOrderByRelation }) => {
  const { t } = useTranslation();

  return (
    <>
      <GridHeaderContainer>
        <Grid.Grid>
          <Grid.Header>
            <Grid.Cell size="xl">
              <ColumnCheckbox
                label={t('siteTypes:form.name')}
                onClick={(order) => onOrderBy('NAME', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="md">
              <ColumnCheckbox
                label={t('siteTypes:siteQuantity')}
                onClick={(order) =>
                  onOrderByRelation('sites', 'COUNT', null, order)
                }
              />
            </Grid.Cell>
            <Grid.Cell size="xs" />
            <Grid.Cell size="xs" />
          </Grid.Header>
        </Grid.Grid>
      </GridHeaderContainer>

      <Grid.Grid>
        {data?.map((siteType) => (
          <GridRow key={siteType.id} siteType={siteType} onDelete={onDelete} />
        ))}
      </Grid.Grid>
    </>
  );
};

const GridRow = ({ siteType, onDelete }) => {
  return (
    <Grid.Row>
      <Grid.Cell size="xl">{siteType.name}</Grid.Cell>
      <Grid.Cell size="md">{siteType.sites.paginatorInfo.total}</Grid.Cell>
      <Grid.Cell size="xs" alignment="right">
        <Link
          to={generatePath(routes.administrationSiteTypeEdition.path, {
            id: siteType.id,
          })}
        >
          <Grid.IconButton imgSrc={EditIcon} />
        </Link>
      </Grid.Cell>
      <Grid.Cell size="xs" alignment="right">
        <Grid.IconButton
          imgSrc={DeleteIcon}
          onClick={() => onDelete(siteType)}
        />
      </Grid.Cell>
    </Grid.Row>
  );
};

export { DataGrid };
