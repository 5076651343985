import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Loading } from 'front-library';

import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { routes } from '../../../routes';
import { Modal } from '../../molecules/Modal/Modal';
import { CompanyForm } from '../../organisms/Companies/CompanyForm';
import { CREATE_COMPANY, GET_COMPANY, UPDATE_COMPANY } from './graphql';
import { mapCompanyToFormValues, mapFormValuesToGraphql } from './utils';

const Company = ({ refetch }) => {
  const { t } = useTranslation();
  const { id = null } = useParams();
  const navigate = useNavigate();

  const { data: companyData, loading: getLoading } = useQueryGraphQl(
    GET_COMPANY,
    { id },
    {},
    null === id,
  );
  const [createCompanyMutation, { loading: createLoading }] =
    useMutation(CREATE_COMPANY);
  const [updateCompanyMutation, { loading: updateLoading }] =
    useMutation(UPDATE_COMPANY);

  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(mapCompanyToFormValues());

  if (companyData?.company && !formValues?.id) {
    setFormValues({
      ...formValues,
      ...mapCompanyToFormValues(companyData.company),
    });
  }

  const title = useMemo(
    () => (id ? t('companies:update') : t('companies:create')),
    [id, t],
  );

  const handleSave = async () => {
    try {
      await createCompanyMutation({
        variables: mapFormValuesToGraphql(formValues),
      });

      setErrors({});
      refetch();
      handleClose();
      toast.success(t('companies:created.success', { name: formValues.name }));
    } catch (e) {
      setErrors(e.graphQLErrors?.[0]?.extensions?.validation || {});
      toast.error(t('companies:created.error', { name: formValues.name }));
    }
  };

  const handleUpdate = async () => {
    try {
      await updateCompanyMutation({
        variables: mapFormValuesToGraphql(formValues),
      });

      setErrors({});
      refetch();
      handleClose();
      toast.success(t('companies:updated.success', { name: formValues.name }));
    } catch (e) {
      setErrors(e.graphQLErrors?.[0]?.extensions?.validation || {});
      toast.error(t('companies:updated.error', { name: formValues.name }));
    }
  };

  const handleClose = () =>
    navigate(generatePath(routes.administrationCompany.path));

  return (
    <Modal onClose={handleClose} title={title}>
      {(getLoading || createLoading || updateLoading) && <Loading />}
      {!getLoading && (
        <CompanyForm
          formValues={formValues}
          setFormValues={setFormValues}
          errors={errors}
          onCancel={handleClose}
          onSubmit={id ? handleUpdate : handleSave}
        />
      )}
    </Modal>
  );
};

Company.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export { Company };
