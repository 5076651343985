import moment from 'moment';
import React, { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ColumnCheckbox,
  Grid,
  Label,
  ScrollableContainer,
} from 'front-library';
import AddIcon from 'front-library/build/img/icon-add-green.svg';
import EditIcon from 'front-library/build/img/icon-modify-green-2.svg';
import DeleteIcon from 'front-library/build/img/trash.svg';

import { formatSqlTime } from '../../pages/FluxManagement/utils';
import {
  ConfigContainer,
  ConfigFirstLine,
  ConfigRoute,
  ConfigRowContainer,
  NoConfig,
} from './styled-components';
import './styles.css';

const FluxList = ({
  data,
  offSiteId,
  onFluxEdit,
  onFluxDelete,
  onConfigAdd,
  onConfigEdit,
  onConfigDelete,
  onOrderBy,
}) => {
  const { t } = useTranslation();

  return (
    <Grid.Grid>
      <Grid.Header>
        <Grid.Cell size="xl">
          <ColumnCheckbox
            label={t('flux:form.name')}
            onClick={(order) => onOrderBy('NAME', order)}
          />
        </Grid.Cell>
      </Grid.Header>
      {data?.map((flux) => (
        <Grid.Row
          expandPanel={
            <FluxExpandPanel
              flux={flux}
              offSiteId={offSiteId}
              onConfigAdd={onConfigAdd}
              onConfigEdit={onConfigEdit}
              onConfigDelete={onConfigDelete}
            />
          }
          key={flux.id}
        >
          <Grid.Cell size="xl">{flux.name}</Grid.Cell>
          <Grid.Cell size="xs">
            <Grid.IconButton
              imgSrc={EditIcon}
              onClick={() => onFluxEdit(flux)}
            />
          </Grid.Cell>
          <Grid.Cell size="xs">
            <Grid.IconButton
              imgSrc={DeleteIcon}
              onClick={() => onFluxDelete(flux)}
            />
          </Grid.Cell>
        </Grid.Row>
      ))}
    </Grid.Grid>
  );
};

const FluxExpandPanel = ({
  flux,
  offSiteId,
  onConfigAdd,
  onConfigEdit,
  onConfigDelete,
}) => {
  const [t, i18n] = useTranslation();

  return (
    <ConfigContainer>
      <ConfigFirstLine>
        <p>{t('flux:configuration')}</p>
        <Button
          text={t('common:add')}
          img={AddIcon}
          imgHoverAnimation="rotation180"
          onClick={() => onConfigAdd(flux)}
        />
      </ConfigFirstLine>
      {flux?.flux_configurations?.data?.length > 0 && (
        <Grid.Grid>
          <Grid.Header className="no-shadow-header">
            <Grid.Cell size="md">{t('flux:form.name')}</Grid.Cell>
            <Grid.Cell size="md">{t('flux:form.period')}</Grid.Cell>
            <Grid.Cell size="md">{t('flux:form.reviewDate')}</Grid.Cell>
            <Grid.Cell size="xs" />
            <Grid.Cell size="xs" />
          </Grid.Header>
          {flux?.flux_configurations?.data?.map((config) => (
            <Grid.Row
              key={config.id}
              expandPanel={
                <ConfigExpandPanel config={config} offSiteId={offSiteId} />
              }
              className="no-shadow-row"
            >
              <Grid.Cell size="md">{config.name}</Grid.Cell>
              <Grid.Cell size="md">
                {i18n.format(t('common:from'), 'capitalize')}{' '}
                {moment(config.period_from).format('YYYY-MM-DD')}{' '}
                {t('common:to')} {moment(config.period_to).format('YYYY-MM-DD')}
              </Grid.Cell>
              <Grid.Cell size="md">
                {config.review_date &&
                  moment(config.review_date).format('YYYY-MM-DD')}
              </Grid.Cell>
              <Grid.Cell size="xs">
                <Grid.IconButton
                  imgSrc={EditIcon}
                  onClick={() => onConfigEdit(config)}
                />
              </Grid.Cell>
              <Grid.Cell size="xs">
                <Grid.IconButton
                  imgSrc={DeleteIcon}
                  onClick={() => onConfigDelete(config)}
                />
              </Grid.Cell>
            </Grid.Row>
          ))}
        </Grid.Grid>
      )}
      {!flux?.flux_configurations?.data?.length && (
        <NoConfig>
          {t('flux:noConfig')}{' '}
          <span role="img" aria-label="sad">
            😞
          </span>
        </NoConfig>
      )}
    </ConfigContainer>
  );
};

const ConfigExpandPanel = ({ config, offSiteId }) => {
  const { t } = useTranslation();

  const getSiteLabel = useCallback(
    (index) => {
      if (0 === index) {
        return t('flux:form.departureSite');
      }

      if (config.flux_configuration_sites.data.length - 1 === index) {
        return t('flux:form.arrivalSite');
      }

      return t('flux:form.intermediateSite', { n: index / 2 });
    },
    [t, config],
  );

  const formatTimeOnSite = useCallback(
    (timeOnSite) => {
      const [d, h, m] = formatSqlTime(timeOnSite).split(':');

      return `${d}${t('common:timeInput.d')}: ${h}${t(
        'common:timeInput.h',
      )}: ${m}${t('common:timeInput.m')}`;
    },
    [t],
  );

  return (
    <ScrollableContainer className="horizontal-scroll" maxHeight="400px">
      <ConfigRowContainer>
        <ConfigRoute columns={config.flux_configuration_sites.data.length}>
          {config.flux_configuration_sites.data.map((fcs, i) => (
            <Fragment key={fcs.id}>
              {fcs.site.id === offSiteId && (
                <>
                  <div>
                    <Label>{t('flux:form.route')}</Label>
                    <br />
                    {formatTimeOnSite(fcs.time_on_site)}
                  </div>
                  <div />
                </>
              )}
              {fcs.site.id !== offSiteId && (
                <>
                  <div>
                    <Label>{getSiteLabel(i)}</Label>
                    <br />
                    {fcs.site.name}
                  </div>
                  <div>
                    <Label>{t('flux:form.timeOnSite')}</Label>
                    <br />
                    {formatTimeOnSite(fcs.time_on_site)}
                  </div>
                </>
              )}
            </Fragment>
          ))}
        </ConfigRoute>
        {config.comment && (
          <div>
            <Label>{t('flux:form.comment')}</Label>
            <br />
            {config.comment}
          </div>
        )}
      </ConfigRowContainer>
    </ScrollableContainer>
  );
};

export { FluxList };
