import { useMutation } from '@apollo/client';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Loading } from 'front-library';

import { MeContext } from '../../../contexts/MeContext';
import { Actions, PanelContext } from '../../../contexts/PanelContext';
import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { Modal } from '../../molecules/Modal/Modal';
import { UserForm } from '../../organisms/Users/UserForm';
import {
  CREATE_USER,
  GENERATE_API_TOKEN,
  GET_API_TOKEN,
  GET_AUTOMATED_ACTIONS,
  GET_ROLES,
  UPDATE_USER,
} from './graphql';
import { mapFormDataToGraphQl, mapUserToFormData } from './utils';

const User = ({
  user,
  action,
  currentUser,
  refetch,
  onClose,
  setting = false,
}) => {
  const { t } = useTranslation();
  const { refetchMe } = useContext(MeContext);

  const { data: automationData, loading: automationLoading } = useQueryGraphQl(
    GET_AUTOMATED_ACTIONS,
  );
  const { data: rolesData, loading: rolesLoading } = useQueryGraphQl(GET_ROLES);
  const [createUserMutation, { loading: createLoading }] =
    useMutation(CREATE_USER);
  const [updateUserMutation, { loading: updateLoading }] =
    useMutation(UPDATE_USER);
  const [generateApiTokenMutation, { loading: tokenLoading }] =
    useMutation(GENERATE_API_TOKEN);
  const { data: tokenData } = useQueryGraphQl(GET_API_TOKEN, { id: user?.id });

  const [statePanel, dispatch] = useContext(PanelContext);

  const [token, setToken] = useState(null);
  const [formData, setFormData] = useState(mapUserToFormData(user));
  const title = user ? t('user:update') : t('user:create');

  useEffect(() => {
    if (tokenData?.user?.api_token) {
      setToken(tokenData.user.api_token);
    }
  }, [tokenData]);

  if (setting && !statePanel.panelUserForm.show) {
    return <div />;
  }

  const handleSave = async () => {
    try {
      await createUserMutation({
        variables: mapFormDataToGraphQl(
          formData,
          user,
          rolesData?.roles?.data ?? [],
        ),
      });

      dispatch({ type: Actions.resetMessages });
      onClose();
      toast.success(
        t('user:created.success', {
          name: `${formData.surname} ${formData.name}`,
        }),
      );
      refetch();
    } catch (error) {
      console.error(error);

      dispatch({
        type: Actions.errors,
        payload: {
          errors: error?.graphQLErrors[0]?.extensions?.validation || [],
        },
      });
      toast.error(t('user:created.error'));
    }
  };

  const handleUpdate = async () => {
    try {
      await updateUserMutation({
        variables: mapFormDataToGraphQl(
          formData,
          user,
          rolesData?.roles?.data ?? [],
        ),
      });

      dispatch({ type: Actions.resetMessages });

      if (setting) {
        dispatch({ type: Actions.panel, panel: 'panelUserForm' });
      } else {
        onClose();
      }

      if (setting) {
        toast.success(t('user:updated.setting.success'));

        await refetchMe();
      } else {
        toast.success(
          t('user:updated.success', {
            name: `${formData.surname} ${formData.name}`,
          }),
        );

        refetch();

        if (user.id === currentUser.id) {
          await refetchMe();
        }
      }
    } catch (error) {
      console.error(error);

      dispatch({
        type: Actions.errors,
        payload: {
          errors: error?.graphQLErrors[0]?.extensions?.validation || [],
        },
      });
      toast.error(
        t('user:updated.error', {
          name: `${formData.surname} ${formData.name}`,
        }),
      );
    }
  };

  const handleGenerateToken = async () => {
    try {
      const response = await generateApiTokenMutation({
        variables: { id: user.id },
      });

      dispatch({ type: Actions.resetMessages });
      toast.success(
        t('user:token.success', {
          name: `${formData.surname} ${formData.name}`,
        }),
      );
      setToken(response.data.generateApiToken.api_token);
    } catch (error) {
      console.error(error);

      dispatch({
        type: Actions.errors,
        payload: {
          errors: error?.graphQLErrors[0]?.extensions?.validation || [],
        },
      });
      toast.error(t('user:token.error'));
    }
  };

  const isLoading =
    automationLoading ||
    rolesLoading ||
    createLoading ||
    updateLoading ||
    tokenLoading;

  return (
    <Modal
      onClose={
        setting
          ? () => {
              dispatch({ type: Actions.resetMessages });
              dispatch({ type: Actions.panel, panel: 'panelUserForm' });
            }
          : onClose
      }
      title={title}
      zIndex={3000}
    >
      {isLoading && <Loading />}
      {!isLoading && (
        <UserForm
          user={user}
          token={token}
          action={action}
          automations={automationData?.automated_actions?.data}
          currentUser={currentUser}
          formData={formData}
          setFormData={setFormData}
          generateToken={handleGenerateToken}
          errors={statePanel.errors}
          onCancel={
            setting
              ? () => {
                  dispatch({ type: Actions.resetMessages });
                  dispatch({ type: Actions.panel, panel: 'panelUserForm' });
                }
              : onClose
          }
          onSubmit={user ? handleUpdate : handleSave}
        />
      )}
    </Modal>
  );
};

export { User };
