import {
  Button,
  ScrollableContainer,
  Switch,
  TitleIcon,
  Tooltip,
} from 'front-library';
import styled from 'styled-components';

const ScrollableContainerMarginBottom = styled(ScrollableContainer)`
    margin-bottom: 15px;
`;

const PanelContainer = styled.div`
    padding: 15px;
`;

const BackButton = styled(Button)`
    font-size: 14px;
    font-weight: bold;
`;

const Username = styled.span`
    color: #b3b3b3;
    font-size: 16px;
    font-weight: bold;
`;

const SecondTitle = styled.div`
    font-size: 12px;
    font-family: "Muli semi";
    text-align: center;
    margin-left: auto;
`;

const SecondTitleAndDescription = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-right: 10px;
    padding-bottom: 12px;
`;

const LeftSpan = styled.span`
    margin-right: 14px;
`;

const LeftSwitch = styled(Switch)`
    margin-left: auto;
`;

const SwitchTooltip = styled(Tooltip)`
    margin-left: auto;
`;

const AlertTitle = styled(TitleIcon)`
    p {
        font-size: 14px !important;
        color: #6ca6ab !important;
        line-height: 1.5;
        letter-spacing: 0.00938em;
    }
    
    img {
        width: 30px;
        height: 30px;
    }
`;

const CustomAlertTitle = styled.div`
    font-size: 14px !important;
    color: #6ca6ab !important;
    line-height: 1.5;
    letter-spacing: 0.00938em;
`;

const HelpText = styled.div`
    padding-left: 30px;
    color: #b3b3b3;
    font-size: 12px;
    margin-top: -8px;
`;

export {
  ScrollableContainerMarginBottom,
  PanelContainer,
  BackButton,
  Username,
  SecondTitle,
  LeftSpan,
  LeftSwitch,
  AlertTitle,
  CustomAlertTitle,
  HelpText,
  SecondTitleAndDescription,
  SwitchTooltip,
};
