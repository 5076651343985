import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from 'front-library';

import { Title } from '../styled-components';

const AutoRefreshInterval = ({ formValues, setFormValues }) => {
  const { t } = useTranslation();

  return (
    <>
      <Title>{t('companies:form.autoRefreshInterval')}</Title>
      <TextField
        label={t('companies:form.autoRefreshIntervalLabel')}
        onChange={(autoRefreshInterval) =>
          setFormValues({ ...formValues, autoRefreshInterval })
        }
        type="number"
        value={formValues.autoRefreshInterval}
      />
    </>
  );
};

AutoRefreshInterval.propTypes = {
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
};

export { AutoRefreshInterval };
