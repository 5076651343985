import styled from 'styled-components';

export const InfosContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 20px;
    align-items: center;
    justify-items: center;
`;

export const FormContainer = styled.div`
    display: grid;
    grid-gap: 10px;
`;

export const SitesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-left: 10px;
    padding-right: 10px;
`;

export const ActionsContainer = styled.div`
    text-align: center;
`;

export const SitesList = styled.ul`
    list-style-type: none;
    border: 1px solid black;
    padding: 5px;
    max-height: 600px;
    overflow-y: scroll;
`;

export const Site = styled.li`
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
`;

export const SelectedSite = styled(Site)`
    border: 1px solid red;
`;
