import { TextField } from 'front-library';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getErrorText } from '../../../helpers';
import { Form } from '../../atoms/Form/Form';

const SiteTypeForm = ({
  errors,
  formValues,
  setFormValues,
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Form
      disableSubmit={!formValues.name}
      onCancel={onCancel}
      onSubmit={onSubmit}
    >
      <TextField
        errorText={getErrorText(errors, 'name', t('siteTypes:form.name'))}
        label={t('siteTypes:form.name')}
        onChange={(name) => setFormValues({ ...formValues, name })}
        required={true}
        value={formValues.name}
      />
    </Form>
  );
};

SiteTypeForm.propTypes = {
  errors: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { SiteTypeForm };
