import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import './index.css';

import { i18n } from './helpers/i18n'; // initialisation de i18next

// style for confirm alerts
import 'react-confirm-alert/src/react-confirm-alert.css';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

import { ThemeProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { Theme } from 'front-library';
import { ServerError } from './components/pages/Errors/ServerError';

momentDurationFormatSetup(moment);

// Session connection
const client = new ApolloClient({
  link: createUploadLink({
    uri: process.env.REACT_APP_URL_API,
  }),
  cache: new InMemoryCache(),
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ServerError />}>
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={Theme}>
          <ThemeProvider theme={Theme}>
            <App />
          </ThemeProvider>
        </MuiThemeProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
