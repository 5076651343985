import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom';

import { Button, TextField } from 'front-library';
import previous from 'front-library/build/img/icon-return.svg';

import resetPasswordSideImage from '../../../assets/images/auth/reset-password.png';
import { MeContext } from '../../../contexts/MeContext';
import { HttpError } from '../../../http/HttpError';
import { statusCodes } from '../../../http/codes';
import { routes } from '../../../routes';
import { Loading } from '../../molecules/Loading/Loading';
import { AuthPage } from '../../templates/AuthPage/AuthPage';
import {
  Form,
  GraySeparator,
  Links,
  PasswordCriterias,
  Titles,
} from './styled-components';

const ResetPassword = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { token = null } = useParams();
  const { refetchMe } = useContext(MeContext);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formState, setFormState] = useState({});

  useEffect(() => {
    i18n.changeLanguage('en');
  }, [i18n.changeLanguage]);

  useEffect(() => {
    fetch('/auth/verify-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': document
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute('content'),
      },
      body: JSON.stringify({ token: token }),
    })
      .then((response) => {
        if (!response.ok) {
          if (
            [
              statusCodes.UNAUTHORIZED,
              statusCodes.INSUFFICIENT_SPACE_ON_RESOURCE,
            ].includes(response.status)
          ) {
            response.json().then((data) => setError({ token: data.message }));

            return;
          }

          throw new HttpError(response.statusText, response.status);
        }

        response.json().then((data) => setFormState(data));
      })
      .catch(() => setError({ token: t('error:apiServerError') }))
      .finally(() => setLoading(false));
  }, [t, token]);

  const resetPassword = () => {
    setLoading(true);

    fetch(routes.resetPassword.partialPath, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': document
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute('content'),
      },
      body: JSON.stringify({
        email: formState.email,
        password: formState.newPassword,
        password_confirmation: formState.newPasswordConfirmation,
        token,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          if (statusCodes.UNAUTHORIZED === response.status) {
            response.json().then((data) => setError(data));

            return;
          }

          throw new HttpError(response.statusText, response.status);
        }

        const user = await refetchMe();
        const path =
          'map' === user.data.me.home_page
            ? routes.map.path
            : routes.shippingOrders.path;

        navigate(generatePath(path));
      })
      .catch(() => setError({ token: t('error:apiServerError') }))
      .finally(() => setLoading(false));
  };

  return (
    <AuthPage sideImage={resetPasswordSideImage}>
      <Titles>
        <h1>{t('auth:choosePassword')}</h1>
      </Titles>
      <PasswordCriterias>
        <li>{t('auth:passwordCriterias.0')}</li>
        <li>{t('auth:passwordCriterias.1')}</li>
        <li>{t('auth:passwordCriterias.2')}</li>
        <li>{t('auth:passwordCriterias.3')}</li>
      </PasswordCriterias>
      <Form onSubmit={resetPassword}>
        <TextField
          errorText={error?.token || error?.user}
          label={t('auth:email')}
          onChange={(email) => setFormState({ ...formState, email })}
          required={true}
          type="email"
          value={formState.email}
        />
        <TextField
          errorText={error?.password}
          label={t('auth:newPassword')}
          onChange={(newPassword) =>
            setFormState({ ...formState, newPassword })
          }
          required={true}
          type="password"
          value={formState.newPassword}
        />
        <TextField
          label={t('auth:confirmNewPassword')}
          onChange={(newPasswordConfirmation) =>
            setFormState({ ...formState, newPasswordConfirmation })
          }
          required={true}
          type="password"
          value={formState.newPasswordConfirmation}
        />
        <Button
          text={t('auth:resetPassword')}
          type="submit"
          disabled={
            !formState.email ||
            !formState.newPassword ||
            !formState.newPasswordConfirmation
          }
        />
      </Form>
      <GraySeparator />
      <Links>
        <Link to={generatePath(routes.login.path)}>
          <Button
            bold={true}
            img={previous}
            text={t('auth:back')}
            variant="borderlessGreen"
          />
        </Link>
      </Links>

      {loading && <Loading />}
    </AuthPage>
  );
};

export { ResetPassword };
