import { useMutation } from '@apollo/client';
import { BtnInATab, Loading } from 'front-library';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import IconAddGreen from 'front-library/build/img/icon-add-green.svg';
import { confirmAlert } from 'react-confirm-alert';
import { useParams } from 'react-router-dom';
import { useQueryGraphQl } from '../../../../hooks/useQueryGraphQl';
import { routes } from '../../../../routes';
import { ListPage } from '../../../templates/ListPage';
import { DELETE_ASSET_GROUPS, GET_ASSET_GROUPS } from '../graphql';
import { DataGrid } from './DataGrid';

const AssetGroupList = () => {
  // @TODO This is ugly, we should know why we do this and how to remove it
  window.history.pushState(null, '', '/asset_group');

  const { t } = useTranslation();
  const { message } = useParams();

  const [result, setResult] = useState(message);
  const {
    data,
    setPagination,
    setFirst,
    setVariable,
    setOrderBy,
    setOrderByRelation,
    loading,
    refetch,
  } = useQueryGraphQl(GET_ASSET_GROUPS, { first: 25, search: '%' });
  const [deleteAssetGroup, { loading: deleteLoading }] =
    useMutation(DELETE_ASSET_GROUPS);

  const handleDelete = (row) => {
    confirmAlert({
      title: t('common:deletion'),
      message: t('assetGroups:askDelete', { reference: row.reference }),
      buttons: [
        {
          label: t('common:yes'),
          onClick: () => handleDeleteConfirm(row),
        },
        {
          label: t('common:no'),
        },
      ],
    });
  };

  const handleDeleteConfirm = async ({ id, reference }) => {
    try {
      await deleteAssetGroup({ variables: { id } });
      await refetch();
      toast.success(t('assetGroups:deleted.success', { reference }));
    } catch (e) {
      console.error(e);
      toast.error(t('assetGroups:deleted.error', { reference }));
    }
  };

  const onEditHandler = (assetGroup) => {
    localStorage.setItem('reference', assetGroup.reference);
    window.location = `/asset_group/${assetGroup.id}/edit`;
  };

  if (result && !loading) {
    const reference = localStorage.getItem('reference');
    const toastMessage =
      result === 'created'
        ? t('assetGroups:created.success')
        : reference
          ? t('assetGroups:updated.success', { reference })
          : t('assetGroups:updated.successNull');

    localStorage.removeItem('reference');
    setResult(null);

    toast.success(toastMessage, { toastId: 'message' });
  }

  return (
    <ListPage
      title={t('menu:assetGroup')}
      paginatorInfo={data?.asset_groups?.paginatorInfo}
      onPaginate={setPagination}
      onRowsPerPageChange={setFirst}
      onSearch={(search) => setVariable('search', `%${search.trim()}%`)}
      onAdd={() => {
        window.location = routes.administrationAssetGroupCreation.path;
      }}
    >
      <DataGrid
        data={data?.asset_groups?.data}
        onDelete={handleDelete}
        onOrderBy={setOrderBy}
        onOrderByRelation={setOrderByRelation}
        loading={loading}
        onClick={onEditHandler}
      />
      {(loading || deleteLoading) && <Loading />}
      <div className="btnInATabContainer">
        <BtnInATab
          text={t('assetGroups:add')}
          onClick={() => {
            window.location = routes.administrationAssetGroupCreation.path;
          }}
          imgSrc={IconAddGreen}
        />
      </div>
    </ListPage>
  );
};

export { AssetGroupList };
