import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import { ColumnCheckbox, Grid } from 'front-library';
import EyeIcon from 'front-library/build/img/icon-apercu.svg';
import EditIcon from 'front-library/build/img/icon-modify-green-2.svg';
import DeleteIcon from 'front-library/build/img/trash.svg';

import { routes } from '../../../../routes';
import { SiteList } from '../../Site/List/SiteList';

import { Modal } from '../../../molecules/Modal/Modal';
import {
  CellWithEye,
  Container,
  GridHeaderContainer,
  PreCell,
} from './styled-components';

const DataGrid = ({ data, onDelete, onOrderBy, onOrderByRelation }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [branchId, setBranchId] = useState(null);

  const onSiteClickHandler = (id) => {
    setBranchId(id);
    setShowModal(true);
  };

  return (
    <>
      <GridHeaderContainer>
        <Grid.Grid>
          <Grid.Header>
            <Grid.Cell size="md">
              <ColumnCheckbox
                label={t('branches:form.name')}
                onClick={(order) => onOrderBy('NAME', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="md">
              <ColumnCheckbox
                label={t('branches:form.reference')}
                onClick={(order) => onOrderBy('REFERENCE', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="s">
              <ColumnCheckbox
                label={t('branches:form.sites')}
                onClick={(order) =>
                  onOrderByRelation('sites', 'COUNT', null, order)
                }
              />
            </Grid.Cell>
            <Grid.Cell size="s">
              <ColumnCheckbox
                label={t('branches:form.users')}
                onClick={(order) =>
                  onOrderByRelation('users', 'COUNT', null, order)
                }
              />
            </Grid.Cell>
            <Grid.Cell size="xs" />
            <Grid.Cell size="xs" />
          </Grid.Header>
        </Grid.Grid>
      </GridHeaderContainer>

      <Grid.Grid>
        {data?.map((branch) => (
          <GridRow
            key={branch.id}
            branch={branch}
            onDelete={onDelete}
            onSiteClick={onSiteClickHandler}
          />
        ))}
      </Grid.Grid>

      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          size="big"
          title={t('branches:form.sites')}
        >
          <Container>
            <SiteList branchId={branchId} />
          </Container>
        </Modal>
      )}
    </>
  );
};

const GridRow = ({ branch, onDelete, onSiteClick }) => {
  return (
    <Grid.Row>
      <PreCell size="md">{branch.name}</PreCell>
      <PreCell size="md">{branch.reference}</PreCell>
      <CellWithEye size="s">
        {branch.sites.paginatorInfo.total}
        {branch.sites.paginatorInfo.total > 0 && (
          <Grid.IconButton
            imgSrc={EyeIcon}
            onClick={() => onSiteClick(branch.id)}
          />
        )}
      </CellWithEye>
      <Grid.Cell size="s">{branch.users.paginatorInfo.total}</Grid.Cell>
      <Grid.Cell size="xs">
        <Link
          to={generatePath(routes.administrationBranchEdition.path, {
            id: branch.id,
          })}
        >
          <Grid.IconButton imgSrc={EditIcon} />
        </Link>
      </Grid.Cell>
      <Grid.Cell size="xs">
        <Grid.IconButton imgSrc={DeleteIcon} onClick={() => onDelete(branch)} />
      </Grid.Cell>
    </Grid.Row>
  );
};

export { DataGrid };
