import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, TextField } from 'front-library';

import { HelpText, Title } from '../styled-components';

const Mag = ({ formValues, setFormValues }) => {
  const { t } = useTranslation();

  return (
    <>
      <Title>{t('companies:form.mags')}</Title>

      {/* Battery limit */}
      <TextField
        label={t('companies:form.batteryLimit')}
        onChange={(batteryLifeLimit) =>
          setFormValues({ ...formValues, batteryLifeLimit })
        }
        required={true}
        type="number"
        value={formValues.batteryLifeLimit}
      />
      <HelpText>{t('companies:form.batteryLimitHelpText')}</HelpText>

      {/* Accuracy */}
      <TextField
        label={t('companies:form.accuracy')}
        onChange={(accuracy) => setFormValues({ ...formValues, accuracy })}
        required={true}
        type="number"
        value={formValues.accuracy}
      />
      <HelpText>{t('companies:form.accuracyHelpText')}</HelpText>

      {/* Period to calculate lifetime */}
      <TextField
        label={t('companies:form.periodLifeBattery')}
        onChange={(periodLifeBattery) =>
          setFormValues({ ...formValues, periodLifeBattery })
        }
        type="number"
        value={formValues.periodLifeBattery}
      />

      {/* Approximate geolocation interval */}
      <Checkbox
        label={t('companies:form.nonPrecisePosition')}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            enableApproximateGeolocationInterval: e.target.checked,
            // always empty, because if you uncheck, we empty this, and if you check it was empty before.
            approximateGeolocationInterval: '',
          })
        }
        checked={formValues.enableApproximateGeolocationInterval}
      />
      {formValues.enableApproximateGeolocationInterval && (
        <>
          <TextField
            label={t('companies:form.approximateGeolocationInterval')}
            min="1"
            onChange={(approximateGeolocationInterval) =>
              setFormValues({
                ...formValues,
                approximateGeolocationInterval,
              })
            }
            type="number"
            value={formValues.approximateGeolocationInterval}
          />
          <HelpText>
            {t('companies:form.approximateGeolocationIntervalHelpText')}
          </HelpText>
        </>
      )}
    </>
  );
};

Mag.propTypes = {
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
};

export { Mag };
