import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { ColumnCheckbox, Grid } from 'front-library';
import EyeIcon from 'front-library/build/img/icon-apercu.svg';
import EditIcon from 'front-library/build/img/icon-modify-green-2.svg';
import DeleteIcon from 'front-library/build/img/trash.svg';

import { routes } from '../../../../routes';
import { Modal } from '../../../molecules/Modal/Modal';
import { CallbackErrorList } from './CallbackErrorList';

const GridHeaderContainer = styled.div`
  margin-bottom: 10px;
`;

const CellWithEye = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  gap: 5px;
  font-size: 12px;
  color: #002B2B;
  
  :hover {
    img {
      transition: all 0.15s ease-out;
      height: 18px; 
    }
  }
`;

const CellWithoutLinkPadding = styled(Grid.Cell)`
  a {
    padding: 0 !important;
  }
`;

const DataGrid = ({ data, onDelete, onOrderBy }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState(null);

  const onErrorClickHandler = (errors) => {
    setErrors(errors);
    setShowModal(true);
  };

  return (
    <>
      <GridHeaderContainer>
        <Grid.Grid>
          <Grid.Header>
            <Grid.Cell size="xl">
              <ColumnCheckbox
                label={t('callbacks:form.name')}
                onClick={(order) => onOrderBy('NAME', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="xl">
              <ColumnCheckbox
                label={t('callbacks:form.type')}
                onClick={(order) => onOrderBy('TYPE', order)}
              />
            </Grid.Cell>
            <CellWithoutLinkPadding size="md">
              <ColumnCheckbox label={t('callbacks:form.errorHistory')} />
            </CellWithoutLinkPadding>
            <Grid.Cell size="xs" />
            <Grid.Cell size="xs" />
          </Grid.Header>
        </Grid.Grid>
      </GridHeaderContainer>

      <Grid.Grid>
        {data?.map((callback) => (
          <GridRow
            key={callback.id}
            callback={callback}
            onDelete={onDelete}
            onErrorClickHandler={onErrorClickHandler}
          />
        ))}
      </Grid.Grid>

      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          size="big"
          title={t('callbacks:form.errorModal.title')}
        >
          <CallbackErrorList errors={errors} />
        </Modal>
      )}
    </>
  );
};

const GridRow = ({ callback, onDelete, onErrorClickHandler }) => {
  return (
    <Grid.Row>
      <Grid.Cell size="xl">{callback.name}</Grid.Cell>
      <Grid.Cell size="xl">{callback.type}</Grid.Cell>
      <CellWithEye size="md">
        {callback?.errors?.length}
        {callback?.errors?.length > 0 && (
          <Grid.IconButton
            imgSrc={EyeIcon}
            onClick={() => onErrorClickHandler(callback?.errors)}
          />
        )}
      </CellWithEye>
      <Grid.Cell size="xs" alignment="right">
        <Link
          to={generatePath(routes.administrationCallbackEdition.path, {
            id: callback.id,
          })}
        >
          <Grid.IconButton imgSrc={EditIcon} />
        </Link>
      </Grid.Cell>
      <Grid.Cell size="xs" alignment="right">
        <Grid.IconButton
          imgSrc={DeleteIcon}
          onClick={() => onDelete(callback)}
        />
      </Grid.Cell>
    </Grid.Row>
  );
};

export { DataGrid };
