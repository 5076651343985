import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, TextField } from 'front-library';

import { GraySeparator } from 'front-library';
import { getErrorText } from '../../../helpers';
import { Form } from '../../atoms/Form/Form';
import { Autocomplete } from '../../molecules/Autocomplete/Autocomplete';
import { GET_PROVIDERS } from '../../molecules/Autocomplete/graphql';
import { Characteristic } from './Characteristic';

const MagTypeForm = ({
  formData,
  formValues,
  setFormValues,
  errors = {},
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const qualifications = useMemo(
    () => [
      { value: 'TEST', title: t('magTypes:qualifications.test') },
      { value: 'TRIAL_READY', title: t('magTypes:qualifications.trial') },
      { value: 'PRODUCTION', title: t('magTypes:qualifications.production') },
      { value: 'REJECTED', title: t('magTypes:qualifications.rejected') },
    ],
    [t],
  );

  const canSubmit =
    formValues.name &&
    formValues.key &&
    formValues.provider &&
    formValues.qualification;

  return (
    <Form disableSubmit={!canSubmit} onCancel={onCancel} onSubmit={onSubmit}>
      {/* Name */}
      <TextField
        errorText={getErrorText(errors, 'name', t('magTypes:form.name'))}
        label={t('magTypes:form.name')}
        onChange={(name) => setFormValues({ ...formValues, name })}
        required={true}
        value={formValues.name || ''}
      />

      {/* Key */}
      <TextField
        errorText={getErrorText(errors, 'key', t('magTypes:form.key'))}
        label={t('magTypes:form.key')}
        onChange={(key) => setFormValues({ ...formValues, key })}
        required={true}
        value={formValues.key || ''}
      />

      {/* Provider */}
      <Autocomplete
        errorText={getErrorText(
          errors,
          'provider.connect',
          t('magTypes:form.provider'),
        )}
        label={t('magTypes:form.provider')}
        onChange={(provider) => setFormValues({ ...formValues, provider })}
        query={GET_PROVIDERS}
        required={true}
        value={formValues.provider || null}
      />

      {/* Qualification */}
      <Select
        errorText={getErrorText(
          errors,
          'qualification',
          t('magTypes:form.qualification'),
        )}
        label={t('magTypes:form.qualification')}
        onChange={(qualification) =>
          setFormValues({ ...formValues, qualification })
        }
        options={qualifications}
        required={true}
        value={formValues.qualification || ''}
      />

      {/* Networks */}
      <Select
        label={t('magTypes:form.networks')}
        onChange={(networks) => setFormValues({ ...formValues, networks })}
        options={formData.networks.data}
        multiple={true}
        value={formValues.networks || []}
      />

      {/* Geolocation sources */}
      <Select
        label={t('magTypes:form.geolocationSources')}
        onChange={(geolocationSources) =>
          setFormValues({ ...formValues, geolocationSources })
        }
        options={formData.geolocation_sources.data}
        multiple={true}
        value={formValues.geolocationSources || []}
      />

      <GraySeparator $borderStyle="dashed" />

      {/* Characteristics */}
      <h3>{t('magTypes:form.characteristics')}</h3>
      {formValues.characteristics?.map((characteristic, index) => (
        <Characteristic
          characteristic={characteristic}
          formValues={formValues}
          index={index}
          key={characteristic.value}
          setFormValues={setFormValues}
        />
      ))}
    </Form>
  );
};

MagTypeForm.propTypes = {
  errors: PropTypes.object,
  formData: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { MagTypeForm };
