import React, { useContext, useEffect, useRef } from 'react';

import { MeContext } from '../../../contexts/MeContext';
import { TableauContainer } from './styled-components';

const Tableau = () => {
  const { me: user } = useContext(MeContext);
  const ref = useRef(null);
  const tableauVizRef = useRef(null);

  useEffect(() => {
    const { tableau } = window;

    if (user?.company.tableau_workbook_url && !tableauVizRef.current) {
      tableauVizRef.current = new tableau.Viz(
        ref.current,
        user?.company.tableau_workbook_url,
      );
    }
  }, [user]);

  return (
    <div>
      <TableauContainer ref={ref} />
    </div>
  );
};

export { Tableau };
