import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Grid, Tag, Text } from 'front-library';
import EditIcon from 'front-library/build/img/icon-modify-green-2.svg';
import DeleteIcon from 'front-library/build/img/trash.svg';
import PropTypes from 'prop-types';
import { Link, generatePath } from 'react-router-dom';
import { routes } from '../../../../routes';

const UnbrakedCell = styled(Grid.Cell)`
  word-break: initial;
`;

const FlexCell = styled(Grid.Cell)`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const SIZES = ['md', 's', 'l', 's', 'md', 'xs', 'xs'];

const DataGrid = ({ customParameters, modelNames, onDelete, valueTypes }) => {
  const { t } = useTranslation();

  const columns = [
    t('customParameters:name'),
    t('customParameters:type'),
    t('customParameters:description'),
    t('customParameters:assignment'),
    t('customParameters:assetType'),
  ];

  const modelNamesDisplay = useMemo(() => {
    const display = {};

    for (const modelName of modelNames) {
      display[~modelName.value] = modelName.title;
    }

    return display;
  }, [modelNames]);

  return (
    <Grid.Grid>
      <Grid.Header>
        {columns.map((c, i) => (
          <Grid.Cell size={SIZES[i]} key={c}>
            {c}
          </Grid.Cell>
        ))}
        <Grid.Cell size={SIZES[5]} />
        <Grid.Cell size={SIZES[6]} />
      </Grid.Header>
      {customParameters?.map((customParameter) => (
        <Grid.Row key={customParameter.id}>
          <Grid.Cell size={SIZES[0]}>
            <Text.Bold>{customParameter.name}</Text.Bold>
          </Grid.Cell>
          <UnbrakedCell size={SIZES[1]}>
            {valueTypes[customParameter.value_type]}
          </UnbrakedCell>
          <Grid.Cell size={SIZES[2]}>{customParameter.description}</Grid.Cell>
          <Grid.Cell size={SIZES[3]}>
            {modelNamesDisplay[customParameter.model]}
          </Grid.Cell>
          <FlexCell size={SIZES[4]}>
            {customParameter.asset_types_filters.data?.length
              ? customParameter.asset_types_filters.data.map(({ id, name }) => (
                  <Tag key={id}>{name}</Tag>
                ))
              : t('customParameters:allAssetTypes')}
          </FlexCell>
          <Grid.Cell size={SIZES[5]} alignment="right">
            <Link
              to={generatePath(
                routes.administrationCustomParameterEdition.path,
                {
                  id: customParameter.id,
                },
              )}
            >
              <Grid.IconButton imgSrc={EditIcon} />
            </Link>
          </Grid.Cell>
          <Grid.Cell size={SIZES[6]} alignment="right">
            <Grid.IconButton
              imgSrc={DeleteIcon}
              onClick={() => onDelete(customParameter)}
            />
          </Grid.Cell>
        </Grid.Row>
      ))}
    </Grid.Grid>
  );
};

DataGrid.propTypes = {
  customParameters: PropTypes.arrayOf(PropTypes.object),
  modelNames: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
  valueTypes: PropTypes.object.isRequired,
};

export { DataGrid };
