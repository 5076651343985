import { useMutation } from '@apollo/client';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Select } from 'front-library';
import Trash from 'front-library/build/img/trash.svg';

import { MeContext } from '../../../contexts/MeContext';
import { Loading } from '../../molecules/Loading/Loading';
import { DELETE_GRAPHQL_FILTER } from './graphql';

import { Form } from '../../atoms/Form/Form';
import { Modal } from '../../molecules/Modal/Modal';

const GraphqlFiltersList = ({
  onClose,
  onConfirm,
  selectedFiltersId,
  page,
}) => {
  const { t } = useTranslation();
  const { me, refetchMe } = useContext(MeContext);

  const [forceOpenSelect, setForceOpenSelect] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(
    me?.graphql_filters?.data?.find((f) => f.id === selectedFiltersId) || null,
  );

  const [deleteFilterMutation, { loading }] = useMutation(
    DELETE_GRAPHQL_FILTER,
  );

  const graphqlFilters = useMemo(() => {
    if (me.graphql_filters?.data) {
      return me.graphql_filters.data
        .filter((f) => page === f.page)
        .map((f) => ({
          ...f,
          value: f.id,
          title: f.name,
          secondTitle: f.default_view ? t('graphqlFilters:byDefault') : null,
        }));
    }

    return [];
  }, [me, page, t]);

  const handleDeleteConfirm = useCallback(
    async (id) => {
      try {
        await deleteFilterMutation({ variables: { id } });

        if (id === selectedFilter?.id) {
          setSelectedFilter(null);
        }

        toast.success(t('graphqlFilters:deleted.success'));
      } catch (error) {
        console.error(error);
        toast.error(t('graphqlFilters:deleted.error'));
      } finally {
        try {
          await refetchMe();
        } catch (error) {
          console.error(error);
          toast.error(t('common:fetchMeError'));
        }
        setForceOpenSelect(false);
      }
    },
    [deleteFilterMutation, refetchMe, t, selectedFilter],
  );

  const handleDelete = useCallback(
    (id) => {
      setForceOpenSelect(true);
      confirmAlert({
        title: t('common:deletion'),
        message: t('graphqlFilters:askDelete'),
        buttons: [
          {
            label: t('common:yes'),
            onClick: () => handleDeleteConfirm(id),
          },
          {
            label: t('common:no'),
            onClick: () => setForceOpenSelect(false),
          },
        ],
      });
    },
    [handleDeleteConfirm, t],
  );

  return (
    <Modal onClose={onClose} size="small" title={t('graphqlFilters:listTitle')}>
      <Form onCancel={onClose} onSubmit={() => onConfirm(selectedFilter)}>
        <Select
          onChange={(value) =>
            setSelectedFilter(
              graphqlFilters.find((f) => f.id === value) || null,
            )
          }
          options={graphqlFilters}
          value={selectedFilter?.id || ''}
          endLineButtonIconSrc={Trash}
          endLineButtonOnClick={(option) => handleDelete(option)}
          forceOpen={forceOpenSelect}
        />
      </Form>
      {loading && <Loading />}
    </Modal>
  );
};

export { GraphqlFiltersList };
