import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useReducer, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, generatePath, useNavigate } from 'react-router-dom';

import { MeContext } from '../../../contexts/MeContext';
import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { routes } from '../../../routes';
import * as Actions from '../../../stores/shippingOrdersFilters/actions';
import {
  INITIAL_STATE as INITIAL_FILTERS_STATE,
  filtersReducer,
} from '../../../stores/shippingOrdersFilters/reducer';
import { Loading } from '../../molecules/Loading/Loading';
import { CreationModal } from '../../organisms/ShippingOrders/CreationModal/CreationModal';
import { Header } from '../../organisms/ShippingOrders/Header/Header';
import { List } from '../../organisms/ShippingOrders/List/List';

import { base64ToObject, objectToBase64 } from '../../../helpers';
import { useQueryState } from '../../../hooks/useQueryState';
import { GET_DEFAULT_GRAPHQL_FILTER, GET_SHIPPING_ORDERS } from './graphql';
import {
  mapFiltersAndVariablesToGraphQl,
  mapFiltersToGraphQl,
  mapVariablesToGraphQl,
} from './utils';

const ShippingOrders = () => {
  const { t } = useTranslation();
  const { me } = useContext(MeContext);
  const navigate = useNavigate();
  const [urlFilters, setUrlFilters] = useQueryState('filters');
  const [reference] = useQueryState('reference');

  /** Initializing the filters state */
  const [filtersState, dispatch] = useReducer(
    filtersReducer,
    null === urlFilters
      ? {
          ...INITIAL_FILTERS_STATE,
          refFilter: reference ? [{ title: reference, value: reference }] : [],
        }
      : base64ToObject(urlFilters),
  );

  /** Here we get the default filters, if user saved one */
  const { loading: graphqlFiltersLoading, data: graphqlFilterData } =
    useQueryGraphQl(
      GET_DEFAULT_GRAPHQL_FILTER,
      {
        userId: me.id,
      },
      {},
      null !== urlFilters,
    );

  /** Here we get all shipping order, with filters */
  const {
    loading: shippingOrdersLoading,
    data: shippingOrdersData,
    refetch,
    setVariablesAndFilters,
  } = useQueryGraphQl(
    GET_SHIPPING_ORDERS,
    mapVariablesToGraphQl(filtersState),
    mapFiltersToGraphQl(filtersState),
  );

  /**
   * To know if user changed a filter, useful to know if we change url or not.
   */
  const filtersTouched = useMemo(
    () =>
      !_.isEqual(
        _.omit(filtersState, ['pageVariable', 'orderByVariable']),
        _.omit(INITIAL_FILTERS_STATE, ['pageVariable', 'orderByVariable']),
      ),
    [filtersState],
  );

  /**
   * Called when filters and variables changes, to update query.
   */
  useEffect(() => {
    if (filtersTouched) {
      setUrlFilters(objectToBase64(filtersState));
    }

    const varsAndFilters = mapFiltersAndVariablesToGraphQl(filtersState);
    let timeOutId = null;

    if (filtersState.wait) {
      timeOutId = setTimeout(
        () => setVariablesAndFilters(varsAndFilters),
        1500,
      );
    } else {
      setVariablesAndFilters(varsAndFilters);
    }

    return () => clearTimeout(timeOutId);
  }, [filtersState, setVariablesAndFilters, filtersTouched, setUrlFilters]);

  /** If we got default filters, we set it */
  useEffect(() => {
    if (1 === graphqlFilterData?.graphql_filters?.data?.length) {
      const filters = graphqlFilterData?.graphql_filters?.data[0];

      dispatch({
        type: Actions.SET_SAVED_FILTERS_VARIABLES_ACTION,
        payload: {
          id: filters.id,
          filters: filters.filters,
        },
      });
    }
  }, [graphqlFilterData]);

  return (
    <>
      <Routes>
        <Route
          path={routes.shippingOrdersCreation.partialPath}
          element={
            <CreationModal
              onClose={() => navigate(generatePath(routes.shippingOrders.path))}
              refetchShippingOrders={refetch}
            />
          }
        />
      </Routes>

      {(shippingOrdersLoading || graphqlFiltersLoading) && <Loading />}
      <Header
        title={t('menu:shippingorders')}
        paginatorInfo={shippingOrdersData?.shipping_orders?.paginatorInfo}
        filtersState={filtersState}
        dispatch={dispatch}
      />
      <List
        shippingOrders={shippingOrdersData?.shipping_orders?.data || []}
        refetch={refetch}
        user={me}
      />
    </>
  );
};

ShippingOrders.propTypes = {
  getTitle: PropTypes.func,
  reference: PropTypes.string,
  create: PropTypes.bool,
  filters: PropTypes.string,
};

export { ShippingOrders };
