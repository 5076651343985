import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PanelSection, Select } from 'front-library';

import { FiltersSectionNumber } from '../../styled-components';

const AssetStateFilter = ({ assetStatesFilter, assetStates, onChange }) => {
  const { t } = useTranslation();

  return (
    <PanelSection
      title={t('mapFilters:assetState')}
      customContent={
        assetStatesFilter?.length ? (
          <FiltersSectionNumber>
            {`(${assetStatesFilter.length}/${assetStates.length})`}
          </FiltersSectionNumber>
        ) : null
      }
      className="standard-panel-section"
    >
      <Select
        multiple={true}
        onChange={(types) => onChange(types)}
        options={assetStates}
        placeholder={t('common:nSelected', { count: assetStatesFilter.length })}
        selectAll={t('common:selectAll')}
        value={assetStatesFilter}
      />
    </PanelSection>
  );
};

AssetStateFilter.propTypes = {
  assetStatesFilter: PropTypes.array,
  assetStates: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { AssetStateFilter };
