import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { Loading } from 'front-library';

import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { routes } from '../../../routes';
import { Modal } from '../../molecules/Modal/Modal';
import { MagImportForm } from '../../organisms/Mags/MagImportForm';
import { GET_FORM_DATA, IMPORT_MAG } from './graphql';
import {
  mapMagImportFormValuesToGraphql,
  mapMagImportToFormValues,
} from './utils';

const Import = ({ refetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: formData, loading: getFormDataLoading } =
    useQueryGraphQl(GET_FORM_DATA);
  const [importMagsMutation, { loading: importLoading }] =
    useMutation(IMPORT_MAG);

  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(mapMagImportToFormValues());

  const handleImport = async () => {
    try {
      const response = (
        await importMagsMutation({
          variables: mapMagImportFormValuesToGraphql(formValues),
        })
      ).data.importMag;

      setErrors(response);
      refetch();
    } catch (e) {
      setErrors(e.graphQLErrors?.[0]?.extensions?.validation || {});
    }
  };

  const handleClose = () =>
    navigate(generatePath(routes.administrationMag.path));

  return (
    <Modal onClose={handleClose} title={t('common:import')}>
      {(getFormDataLoading || importLoading) && <Loading />}
      {!getFormDataLoading && !importLoading && (
        <MagImportForm
          formData={formData}
          formValues={formValues}
          onCancel={handleClose}
          onSubmit={handleImport}
          setFormValues={setFormValues}
          errors={errors}
        />
      )}
    </Modal>
  );
};

Import.propTypes = {
  refetch: PropTypes.func,
};

export { Import };
