import React from 'react';

import { ColumnCheckbox, Grid } from 'front-library';

import uniqueId from 'lodash/uniqueId';
import { EditCell, HeaderCell, HeaderRow } from './styled-components';

const DataGrid = ({ headers, rows }) => {
  return (
    <>
      <Grid.Grid>
        <HeaderRow>
          {headers.map((header) => (
            <HeaderCell size="s" key={uniqueId()}>
              <ColumnCheckbox label={header} />
            </HeaderCell>
          ))}
        </HeaderRow>
      </Grid.Grid>
      <Grid.Grid>
        {rows.map((row) => (
          <GridRow key={uniqueId()} row={row} />
        ))}
      </Grid.Grid>
    </>
  );
};

const GridRow = ({ row }) => {
  return (
    <Grid.Row>
      {row.map((cell) => (
        <Grid.Cell size="s" key={uniqueId()}>
          {Array.isArray(cell) ? (
            cell.join(', ')
          ) : 'object' === typeof cell ? (
            <EditCell>{cell.value}</EditCell>
          ) : (
            cell
          )}
        </Grid.Cell>
      ))}
    </Grid.Row>
  );
};

export { DataGrid };
