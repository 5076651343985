const AlertReport = '4';
const AlertAssetStationary = '5';
const AlertAssetOffSite = '6';
const AlertMinTemperature = '8';
const AlertMaxTemperature = '9';
const AlertShockDetected = '10';
const AlertUntrackedShippingOrder = '11';
const AlertShippingOrderStarted = '12';
const AlertShippingOrderDelayed = '13';
const AlertShippingOrderOnDeliverySite = '14';
const AlertShippingOrderNotArrived = '15';
const AlertMovementDetected = '16';
const AlertTourDelayed = '17';
const AlertTourOnDeliverySite = '18';
const AlertShippingOrderDeviated = '19';
const AlertShippingOrderLikelyDeviated = '20';
const AlertAssetNotCummunicate = '21';
const AlertMagLowBattery = '22';
const AlertLeaveSite = '23';

const Email = 1;
const SMS = 2;

export {
  AlertReport,
  AlertAssetStationary,
  AlertAssetOffSite,
  AlertMinTemperature,
  AlertMaxTemperature,
  AlertShockDetected,
  AlertUntrackedShippingOrder,
  AlertShippingOrderStarted,
  AlertShippingOrderDelayed,
  AlertShippingOrderOnDeliverySite,
  AlertShippingOrderNotArrived,
  AlertMovementDetected,
  AlertTourDelayed,
  AlertTourOnDeliverySite,
  AlertShippingOrderDeviated,
  AlertShippingOrderLikelyDeviated,
  AlertAssetNotCummunicate,
  AlertMagLowBattery,
  AlertLeaveSite,
  Email,
  SMS,
};
