import humanizeDuration from 'humanize-duration';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import passiveArrow from 'front-library/build/img/icon-arrow-light-green.svg';
import activeArrow from 'front-library/build/img/icon-arrow-orange.svg';
import checkpointIcon from 'front-library/build/img/icon-checkpoint-orange.svg';
import departureIcon from 'front-library/build/img/icon-site-light-green.svg';
import arrivalIcon from 'front-library/build/img/icon-site-orange-2.svg';

import {
  CheckpointBody,
  CheckpointTitle,
  CheckpointWrapper,
  Divider,
  Dot,
  DotWrapper,
  GridSection,
  Label,
  LabelWrapper,
  Value,
} from './styled-components';

const Checkpoint = ({ checkpoint, orders, isOpen }) => {
  const { t } = useTranslation();
  const [showContent, setShowContent] = useState(isOpen);

  const siteIcon = useMemo(
    () =>
      checkpoint.type === 'checkpoint'
        ? checkpointIcon
        : checkpoint.type === 'departureSite'
          ? departureIcon
          : arrivalIcon,
    [checkpoint],
  );

  const arrivalDifference = useMemo(
    () =>
      checkpoint.arrival_timestamp && checkpoint.real_arrival_timestamp
        ? moment(checkpoint.real_arrival_timestamp).diff(
            checkpoint.arrival_timestamp,
          )
        : null,
    [checkpoint],
  );

  const departureDifference = useMemo(
    () =>
      checkpoint.departure_timestamp && checkpoint.real_departure_timestamp
        ? moment(checkpoint.real_departure_timestamp).diff(
            checkpoint.departure_timestamp,
          )
        : null,
    [checkpoint],
  );

  const getHumanizeDifference = (difference) => {
    const sign = difference > 0 ? '+' : difference < 0 ? '-' : '';

    return (
      sign +
      humanizeDuration(difference, {
        units: ['y', 'mo', 'd', 'h', 'm'],
        language: 'fr',
        largest: 2,
        maxDecimalPoints: 0,
      })
    );
  };

  const getTimeOnSite = useCallback((departure, arrival) => {
    const duration = moment.duration(moment(departure).diff(arrival));

    return `${duration.hours()}h${duration
      .minutes()
      .toString()
      .padStart(2, '0')}`;
  }, []);

  return (
    <CheckpointWrapper level={isOpen ? 'warning' : 'help'}>
      {/* Name */}
      <CheckpointTitle
        className={showContent ? 'title-panel-open' : ''}
        onClick={() => setShowContent(!showContent)}
      >
        <img
          className="img-title-panel-detail-box-2"
          src={isOpen ? activeArrow : passiveArrow}
          alt="arrow"
        />
        <div>
          {checkpoint.site.name}
          <Label level="help">{t(`smsLinkPage:${checkpoint.type}`)}</Label>
        </div>
        <img src={siteIcon} alt="site" />
      </CheckpointTitle>

      {showContent && (
        <CheckpointBody>
          {/* Arrival */}
          {checkpoint?.arrival_timestamp && (
            <div>
              <LabelWrapper>
                <Label level="info">{t('smsLinkPage:arrival')}</Label>
              </LabelWrapper>
              <Value>
                {checkpoint.arrival_timestamp}
                <Label level="help">{t('smsLinkPage:scheduled')}</Label>
              </Value>

              {checkpoint?.real_arrival_timestamp && (
                <div>
                  <Value level={arrivalDifference > 0 ? 'danger' : 'success'}>
                    {checkpoint.real_arrival_timestamp}
                  </Value>
                  <Value level="help">
                    {getHumanizeDifference(arrivalDifference)}
                  </Value>
                </div>
              )}
            </div>
          )}
          {/* Departure */}
          {checkpoint.type === 'departureSite' &&
            checkpoint?.departure_timestamp && (
              <div>
                <LabelWrapper>
                  <Label level="info">{t('smsLinkPage:departure')}</Label>
                </LabelWrapper>
                <Value>
                  {checkpoint.departure_timestamp}
                  <Label level="help">{t('smsLinkPage:scheduled')}</Label>
                </Value>

                {checkpoint?.real_departure_timestamp && (
                  <div>
                    <Value
                      level={departureDifference > 0 ? 'danger' : 'success'}
                    >
                      {checkpoint.real_departure_timestamp}
                    </Value>
                    <Value level="help">
                      {getHumanizeDifference(departureDifference)}
                    </Value>
                  </div>
                )}
              </div>
            )}
          {/* Time on site */}
          {checkpoint?.real_arrival_timestamp &&
            checkpoint?.real_departure_timestamp && (
              <Value level="info">
                {t('smsLinkPage:timeOnSite')}
                <Label>
                  {getTimeOnSite(
                    checkpoint.real_departure_timestamp,
                    checkpoint.real_arrival_timestamp,
                  )}
                </Label>
              </Value>
            )}
          {/* Order */}
          {orders.length && checkpoint?.type === 'arrivalSite' && (
            <div>
              <Divider />
              <div>
                <Value level="info">{t('smsLinkPage:siteOrders')}</Value>
                <GridSection>
                  <DotWrapper>
                    <Dot level="default" />
                    <Label level="help">{t('smsLinkPage:orders')}</Label>
                  </DotWrapper>
                  <DotWrapper>
                    <Dot level="warning" />
                    <Label level="help">{t('smsLinkPage:magId')}</Label>
                  </DotWrapper>
                  <DotWrapper>
                    <Dot level="info" />
                    <Label level="help">
                      {t('smsLinkPage:assetReference')}
                    </Label>
                  </DotWrapper>
                </GridSection>
              </div>
              {orders.map((order) => (
                <div key={order.id}>
                  <Divider level="help" />
                  <div>
                    <div>
                      <Label>{order.reference}</Label>
                    </div>
                    <div>
                      <Label level="warning">{order.asset?.mag.mag_id}</Label>
                    </div>
                    <div>
                      <Label level="info">{order.asset?.reference}</Label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </CheckpointBody>
      )}
    </CheckpointWrapper>
  );
};

Checkpoint.propTypes = {
  checkpoint: PropTypes.object,
  orders: PropTypes.array,
  isOpen: PropTypes.bool,
};

export { Checkpoint };
