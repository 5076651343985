import CSV from 'comma-separated-values';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from 'front-library';
import uniqueId from 'lodash/uniqueId';
import { CsvTable } from './styled-components';

const parse = (data) => {
  const rows = [];
  const columns = [];

  for (const line of new CSV(data)) {
    if (columns.length < 1) {
      for (const index in line) {
        columns.push({
          key: `key-${index}`,
          name: line[index],
        });
      }
    } else {
      const row = {};

      for (const index in line) {
        row[`key-${index}`] = line[index];
      }

      rows.push(row);
    }
  }

  return { rows, columns };
};

const CsvViewer = ({ url }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);

  const getCsv = useCallback(async (url) => {
    try {
      const res = await fetch(url, {
        method: 'get',
        headers: { 'content-type': 'text/csv;charset=UTF-8' },
      });

      if (res.status === 200) {
        const data = await res.text();
        setData(parse(data));
      } else {
        console.error(`Error code ${res.status}`);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    url && getCsv(url);

    return () => {};
  }, [url, getCsv]);

  return data ? (
    <CsvTable>
      <thead>
        <tr>
          {data.columns.map(({ name, key }) => (
            <td key={key}>
              <Text.Bold>{name}</Text.Bold>
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.rows.map((row) => (
          <tr key={uniqueId()}>
            {Object.keys(row).map((k) => (
              <td key={k}>{row[k]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </CsvTable>
  ) : (
    <p>{t('fileviewer:loading')}</p>
  );
};

export { CsvViewer };
