import styled from 'styled-components';

import { TextField } from 'front-library';

import { Autocomplete } from '../Autocomplete/Autocomplete';

export const CPTitle = styled.div`
    font-size: 12px;
    color: #6ca6ab;
`;

export const CPValue = styled.div`
    font-size: 16px;
    color: #0d3e40;
    font-weight: bold;
`;

export const CPDescription = styled.div`
    font-size: 12px;
    color: #b3b3b3;
`;

export const ImgPen = styled.img`
    width: 14px;
    margin-left: 4px;
`;

export const TextFieldContainer = styled(TextField)`
    input, textarea {
        font-family: 'Muli bold';
        font-size: 16px;
        color: #0d3e40;
        &:read-only {
            border-color: transparent;
            box-shadow: none;
            padding-left: 0;
        }
    
        /* width */
        ::-webkit-scrollbar {
            width: 8px;
        }

        /* path */
        ::-webkit-scrollbar-track {
            background: #e4e4e4;
        }

        /* bar */
        ::-webkit-scrollbar-thumb {
            background: #555;
            border-radius: 4px;
        }

        /* hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #ef9d11;
        }
    }
`;

export const StyledAutocomplete = styled(Autocomplete)`
    margin-right: 20px;

    .Mui-disabled, div.Mui-disabled:hover {
        border: none !important;
        box-shadow: none !important;
    }

    .Mui-disabled .MuiAutocomplete-endAdornment {
        display: none;
    }

    input {
        font-family: 'Muli bold' !important;
        font-size: 16px !important;
        color: #0d3e40 !important;
        -webkit-text-fill-color: #0d3e40 !important;
        -moz-text-fill-color: #0d3e40 !important;
        &.Mui-disabled {
            padding-left: 0 !important;
        }
    }
`;

export const ButtonTitle = styled.button`
    :disabled {
        cursor: text;
    }
`;
