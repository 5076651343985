import dayjs from 'dayjs';
import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Button, GraySeparator, Panel } from 'front-library';
import close from 'front-library/build/img/delete-cross.svg';

import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { routes } from '../../../routes';
import {
  CLOSE_ASSET_PANEL_ACTION,
  OPEN_ASSET_PANEL_ACTION,
  SET_ASSET_POSITIONS_ACTION,
  SET_CENTER_ACTION,
} from '../../../stores/map/actions';
import { CustomParametersSection } from '../../molecules/AssetPanel/CustomParametersSection';
import { ResponsibilitiesSection } from '../../molecules/AssetPanel/ResponsibilitiesSection';
import { TemperaturesSection } from '../../molecules/AssetPanel/TemperaturesSection';

import { useAsset } from '../../../hooks/assets/useAsset';
import { AssetPanelSkeleton } from './AssetPanelSkeleton';
import { GET_MAG_DATAS, GET_SITE_TRANSITS } from './graphql';
import {
  AssetJourneyLabel,
  Content,
  GridContainer,
  Head,
  JourneyRangePicker,
  LastActionBlock,
  LastActionContent,
  LastActionLabel,
  MagId,
  MagIdLabel,
  PanelContainer,
  State,
  Title,
} from './styled-components';
import {
  buildMovements,
  buildSinglePointsWithTimestamps,
  disabledAssetJourneyDates,
  getElapsedTime,
  shouldDisabledTimes,
} from './utils';

const AssetPanel = ({ dispatch, user, positionForced, mapTouched }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id: assetId } = useParams();

  const [dates, setDates] = useState({
    start: dayjs().startOf('day'),
    end: dayjs().endOf('day'),
  });

  const [executeMagDatasQuery, setExecuteMagDatasQuery] = useState(false);

  const { content: asset, isLoading: assetLoading } = useAsset(assetId);
  const { data: siteTransitsData, setVariables: setSiteTransitsVariables } =
    useQueryGraphQl(GET_SITE_TRANSITS, { assetId });
  const {
    data: magDatas,
    loading: magDatasLoading,
    setFilters,
  } = useQueryGraphQl(GET_MAG_DATAS, {}, {}, !executeMagDatasQuery);

  useEffect(() => {
    setSiteTransitsVariables({ assetId });
  }, [assetId, setSiteTransitsVariables]);

  useEffect(() => {
    if (dates.start && dates.end) {
      setFilters({
        asset_id: {
          column: 'asset_id',
          operator: 'EQ',
          value: assetId,
        },
        device_timestamp: {
          column: 'device_timestamp',
          operator: 'BETWEEN',
          value: [
            dates.start.format('YYYY-MM-DD HH:mm:ss'), // format('YYYY-MM-DD HH:mm:ss') prevents date conversion to utc
            dates.end.format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        latitude: {
          column: 'latitude',
          operator: 'IS_NOT_NULL',
        },
        longitude: {
          column: 'longitude',
          operator: 'IS_NOT_NULL',
        },
        is_position_accepted: {
          column: 'is_position_accepted',
          operator: 'EQ',
          value: true,
        },
      });
      setExecuteMagDatasQuery(true);
    } else {
      setExecuteMagDatasQuery(false);
    }
  }, [assetId, dates, setFilters]);

  useEffect(() => {
    if (asset) {
      dispatch({
        type: OPEN_ASSET_PANEL_ACTION,
        payload: {
          asset,
        },
      });
    }
  }, [asset, dispatch]);

  useEffect(() => {
    if (asset?.mag && !positionForced && !mapTouched) {
      dispatch({
        type: SET_CENTER_ACTION,
        payload: {
          center: [
            asset.mag.last_accepted_coordinates.latitude,
            asset.mag.last_accepted_coordinates.longitude,
          ],
        },
      });
    }
  }, [positionForced, mapTouched, dispatch, asset]);

  useEffect(() => {
    if (magDatas?.mag_datas?.data && !magDatasLoading) {
      const md = magDatas.mag_datas.data;
      const movements = md.length ? buildMovements(md) : null;
      const singlePoints = md.length
        ? buildSinglePointsWithTimestamps(md, user.company)
        : null;

      dispatch({
        type: SET_ASSET_POSITIONS_ACTION,
        payload: {
          assetPositions: {
            movements,
            singlePoints,
            allPoints: md,
          },
        },
      });
    } else {
      dispatch({
        type: SET_ASSET_POSITIONS_ACTION,
        payload: {
          assetPositions: null,
        },
      });
    }
  }, [magDatas, user, dispatch, magDatasLoading]);

  useEffect(() => {
    return () => dispatch({ type: CLOSE_ASSET_PANEL_ACTION });
  }, [dispatch]);

  const siteTransits = siteTransitsData?.site_transits?.data;

  const temperatures = useMemo(() => {
    if (!user?.company?.enable_temperature) {
      return [];
    }

    if (magDatas?.mag_datas?.data) {
      const magDatasTemperatures = magDatas.mag_datas.data
        .filter((md) => null !== md.temperature)
        .map((md) => ({
          temperature: md.temperature,
          timestamp: md.device_timestamp,
        }));

      if (magDatasTemperatures.length) {
        return magDatasTemperatures;
      }
    }

    return asset?.last_temperature
      ? [{ temperature: asset?.last_temperature }]
      : [];
  }, [asset, magDatas, user]);

  return assetLoading || !asset ? (
    <AssetPanelSkeleton />
  ) : (
    <PanelContainer>
      <Panel>
        <Content>
          <Head>
            <Title>{asset?.reference}</Title>
            <State>
              {t('assets:state')}: {asset?.state?.name}
            </State>
            <Button
              img={close}
              variant="borderless"
              imgHoverAnimation="shrink"
              onClick={() => navigate(generatePath(routes.filtersMap.path))}
            />
          </Head>
          <GridContainer>
            <div>
              <MagIdLabel>{t('assets:magid')}</MagIdLabel>
              <MagId>{asset?.mag?.mag_id}</MagId>
            </div>
            <div>
              <MagIdLabel>{t('assets:form.type')}</MagIdLabel>
              <MagId>{asset?.type?.name}</MagId>
            </div>
          </GridContainer>

          <GridContainer>
            <LastActionBlock>
              <LastActionLabel>{t('assets:lastMovement')}</LastActionLabel>
              <LastActionContent>
                {asset?.mag?.last_accepted_device_timestamp &&
                  getElapsedTime(
                    asset?.mag?.last_accepted_device_timestamp,
                    `D [${t('common:days')}], H [${t('common:h')}], m[${t(
                      'common:min',
                    )}]`,
                  )}
              </LastActionContent>
            </LastActionBlock>
            <LastActionBlock>
              <LastActionLabel>{t('assets:lastCommunication')}</LastActionLabel>
              <LastActionContent>
                {asset?.mag?.last_message_date &&
                  getElapsedTime(
                    asset?.mag?.last_message_date,
                    `D [${t('common:days')}], H [${t('common:h')}], m[${t(
                      'common:min',
                    )}]`,
                  )}
              </LastActionContent>
            </LastActionBlock>
          </GridContainer>
          <AssetJourneyLabel>{t('assets:journey')}</AssetJourneyLabel>

          <JourneyRangePicker
            locale={i18n.language}
            className="DateChoiceBasic"
            disabledDate={disabledAssetJourneyDates}
            showTime={true}
            required={true}
            onOk={([start, end]) => {
              setDates({ start, end: end?.second(59) });
            }}
            disabledTime={(current, type, { from }) =>
              shouldDisabledTimes(current, type, from, dates)
            }
          />

          {showTemperatures(
            temperatures,
            asset?.type?.temperature_min,
            asset?.type?.temperature_max,
          )}
          {showResponsibilities(siteTransits, temperatures)}
          {asset?.custom_parameters.length !== 0 &&
            showCustomParametersSection(asset, siteTransits, temperatures)}
        </Content>
      </Panel>
    </PanelContainer>
  );
};

const showTemperatures = (temperatures, min, max) => {
  if (temperatures?.length) {
    return (
      <TemperaturesSection
        temperatures={temperatures}
        minimunTemperature={min}
        maximumTemperature={max}
      />
    );
  }
};

const showResponsibilities = (siteTransits, temperatures) => {
  if (siteTransits?.length) {
    return (
      <>
        {temperatures?.length ? <GraySeparator /> : null}
        <ResponsibilitiesSection siteTransits={siteTransits} />
      </>
    );
  }
};

const showCustomParametersSection = (asset, siteTransits, temperatures) => {
  return (
    <>
      {(temperatures?.length || siteTransits?.data?.length) && (
        <GraySeparator />
      )}
      <CustomParametersSection
        customParametersValues={asset.custom_parameters}
      />
    </>
  );
};

export { AssetPanel };
