import { gql } from '@apollo/client';

const GET_MAG_TYPE = gql`
    query GetMagType($id: ID!) {
        mag_type(id: $id) {
            id
            key
            name
            qualification
            provider {
                value: id
                title: name
            }
            geolocation_sources {
                value: id
                title: name
            }
            networks {
                value: id
                title: name
            }
            characteristics {
                value: id
                key
                title: name
                type
                data_type
                unit
                pivot {
                    parameter
                }
            }
        }
    }
`;

const GET_FORM_DATA = gql`
    query GetFormData {
        networks(first: 100) {
            data {
                value: id
                title: name
            }
        }
        geolocation_sources(first: 100) {
            data {
                value: id
                title: name
            }
        }
        characteristics(
            first: 10000,
            where: {
                column: "key"
                operator: IN
                value: [
                    "firmware",
                    "message_capacity",
                    "battery_natural_loss",
                    "RECHARGEABLE",
                    "REPLACEABLE_BATTERY",
                    "COMMENT",
                ]
            },
            orderBy: {
                column: DATA_TYPE
                order: ASC
            }
        ) {
            data {
                value: id
                key
                title: name
                type
                data_type
                unit
            }
        }
    }
`;

const GET_MAG_TYPES = gql`
    query MagTypes($first: Int, $page: Int, $search: Mixed, $orderBy: [QueryMagTypesOrderByRelationOrderByClause!]) {
        mag_types(first: $first, page: $page, orderBy: $orderBy, where: {
            OR: [
                {
                    column: "id"
                    operator: EQ
                    value: $search
                }
                {
                    column: "key"
                    operator: LIKE
                    value: $search
                }
                {
                    column: "name"
                    operator: LIKE
                    value: $search
                }
                {
                    column: "qualification"
                    operator: LIKE
                    value: $search
                }
            ]
        }) {
            paginatorInfo {
                total
                currentPage
                lastPage
                perPage
            }
            data {
                id
                name
                qualification
                provider {
                    name
                }
                mags {
                    paginatorInfo {
                        total
                    }
                }
            }
        }
    }
`;

const CREATE_MAG_TYPE = gql`
    mutation CreateMagType(
        $key: String
        $name: String
        $qualification: String
        $provider: UpdateRelation
        $geolocation_sources: UpdateManyRelation
        $networks: UpdateManyRelation
        $characteristics: UpdateMagTypeCharacteristics
    ) {
        createMagType(input: {
            key: $key
            name: $name
            qualification: $qualification
            provider: $provider
            geolocation_sources: $geolocation_sources
            networks: $networks
            characteristics: $characteristics
        }) {
            id
        }
    }
`;

const UPDATE_MAG_TYPE = gql`
    mutation UpdateMagType(
        $id: ID!
        $key: String
        $name: String
        $qualification: String
        $provider: UpdateRelation
        $geolocation_sources: UpdateManyRelation
        $networks: UpdateManyRelation
        $characteristics: UpdateMagTypeCharacteristics
    ) {
        updateMagType(input: {
            id: $id
            key: $key
            name: $name
            qualification: $qualification
            provider: $provider
            geolocation_sources: $geolocation_sources
            networks: $networks
            characteristics: $characteristics
        }) {
            id
        }
    }
`;

const DELETE_MAG_TYPE = gql`
    mutation DeleteMagType($id: ID!) {
        deleteMagType(id: $id) {
            id
        }
    }
`;

export {
  GET_MAG_TYPE,
  GET_FORM_DATA,
  GET_MAG_TYPES,
  CREATE_MAG_TYPE,
  UPDATE_MAG_TYPE,
  DELETE_MAG_TYPE,
};
