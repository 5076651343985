import { gql } from '@apollo/client';

const GET_AUTOMATED_ACTIONS = gql`
    query GetAutomatedActions {
        automated_actions(first: 100000, where: {
            column: "notify"
            value: true
        }) {
            data {
                id
                name
            }
        }
    }
`;

const GET_ROLES = gql`
    query GetRoles {
        roles(first: 100000) {
            data {
                id
                name
            }
        }
    }
`;

const GET_USERS = gql`
    query Users($first: Int, $page: Int, $search: Mixed, $orderBy: [QueryUsersOrderByRelationOrderByClause!]) {
        users(first: $first, page: $page, orderBy: $orderBy, where: {
            OR: [
                {
                    column: "id"
                    operator: EQ
                    value: $search
                }
                {
                    column: "surname"
                    operator: LIKE
                    value: $search
                },
                {
                    column: "name"
                    operator: LIKE
                    value: $search
                },                {
                    column: "email"
                    operator: LIKE
                    value: $search
                },
                {
                    HAS: {
                        relation: "roles"
                        condition: {
                            column: "name"
                            operator: LIKE
                            value: $search
                        }
                    }
                },
                {
                    HAS: {
                        relation: "branches"
                        condition: {
                            column: "name"
                            operator: LIKE
                            value: $search
                        }
                    }
                }
            ]
        }) {
            paginatorInfo {
                total
                currentPage
                lastPage
                perPage
            }
            data {
                id
                surname
                name
                email
                phone
                username
                home_page
                lang
                api_token
                roles {
                    id
                    name
                }
                branches {
                    id
                    name
                }
                alerts(first: 100000) {
                    data {
                        id
                        pivot {
                            type
                            is_allowed
                            is_active
                        }
                    }
                }
                automated_actions(first: 100000) {
                    data {
                        id
                        pivot {
                            type
                            is_allowed
                            is_active
                        }
                    }
                }
            }
        }
    }
`;

const GET_API_TOKEN = gql`
    query GetApiToken($id: ID) {
        user(id: $id) {
            api_token
        }
    }
`;

const CREATE_USER = gql`
    mutation CreateUser(
        $name: String
        $surname: String
        $email: String
        $phone: String
        $username: String
        $password: String
        $password_confirmation: String
        $password_force_update: Boolean
        $lang: String
        $home_page: String
        $branches: UpdateManyRelation,
        $roles: UpdateManyRelation,
        $alerts: UpdateUserAlerts,
        $automated_actions: UpdateUserAutomatedActions
    ) {
        createUser(
            input: {
                name: $name
                surname: $surname
                email: $email
                phone: $phone
                username: $username
                password: $password
                password_confirmation: $password_confirmation
                password_force_update: $password_force_update
                lang: $lang
                home_page: $home_page
                branches: $branches
                roles: $roles
                alerts: $alerts
                automated_actions: $automated_actions
            }
        ) {
            id
        }
    }
`;

const UPDATE_USER = gql`
    mutation UpdateUser(
        $id: ID!
        $surname: String
        $name: String
        $email: String
        $phone: String
        $password: String
        $password_confirmation: String
        $password_force_update: Boolean
        $username: String
        $lang: String
        $home_page: String
        $branches: UpdateManyRelation,
        $roles: UpdateManyRelation,
        $alerts: UpdateUserAlerts,
        $automated_actions: UpdateUserAutomatedActions
    ) {
        updateUser(
            input: {
                id: $id
                surname: $surname
                name: $name
                email: $email
                phone: $phone
                password: $password
                password_confirmation: $password_confirmation
                password_force_update: $password_force_update
                username: $username
                lang: $lang
                home_page: $home_page
                branches: $branches
                roles: $roles
                alerts: $alerts
                automated_actions: $automated_actions
            }
        ) {
            id
        }
    }
`;

const DELETE_USER = gql`
    mutation DeleteUser($id: ID!) {
        deleteUser(id: $id) {
            id
        }
    }
`;

const GENERATE_API_TOKEN = gql`
    mutation GenerateApiToken($id: ID!) {
        generateApiToken(id: $id) {
            api_token
        }
    }
`;

const UPDATE_PASSWORD = gql`
    mutation UpdatePassword(
        $id: ID!
        $admin_password: String
        $password: String
        $password_confirmation: String
        $password_force_update: Boolean
    ) {
        updatePassword(
            input: {
                id: $id
                admin_password: $admin_password
                password: $password
                password_confirmation: $password_confirmation
                password_force_update: $password_force_update
            }
        ) {
            id
        }
    }
`;

export {
  GET_AUTOMATED_ACTIONS,
  GET_ROLES,
  GET_USERS,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  GET_API_TOKEN,
  GENERATE_API_TOKEN,
  UPDATE_PASSWORD,
};
