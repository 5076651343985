import { ColumnCheckbox, Grid } from 'front-library';
import EditIcon from 'front-library/build/img/icon-modify-green-2.svg';
import DeleteIcon from 'front-library/build/img/trash.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '../../../../routes';

const GridHeaderContainer = styled.div`
  margin-bottom: 10px;
`;

const SIZES = ['xs', 's', 'md', 'l', 'xl'];

const DataGrid = ({ data, onDelete, onOrderBy, enableTemperature }) => {
  const { t } = useTranslation();

  return (
    <>
      <GridHeaderContainer>
        <Grid.Grid>
          <Grid.Header>
            <Grid.Cell size={SIZES[3]}>
              <ColumnCheckbox
                label={t('assetTypes:form:name')}
                onClick={(order) => onOrderBy('NAME', order)}
              />
            </Grid.Cell>
            <Grid.Cell size={SIZES[2]}>
              <ColumnCheckbox
                label={t('assetTypes:form:quantity')}
                onClick={(order) => onOrderBy('ASSETS', order)}
              />
            </Grid.Cell>
            {enableTemperature && (
              <Grid.Cell size={SIZES[2]}>
                <ColumnCheckbox
                  label={t('assetTypes:form:temperature_min')}
                  onClick={(order) => onOrderBy('TEMPERATURE_MIN', order)}
                />
              </Grid.Cell>
            )}
            {enableTemperature && (
              <Grid.Cell size={SIZES[2]}>
                <ColumnCheckbox
                  label={t('assetTypes:form:temperature_max')}
                  onClick={(order) => onOrderBy('TEMPERATURE_MAX', order)}
                />
              </Grid.Cell>
            )}
            <Grid.Cell size={SIZES[0]} />
            <Grid.Cell size={SIZES[0]} />
          </Grid.Header>
        </Grid.Grid>
      </GridHeaderContainer>
      <Grid.Grid>
        {data?.map((assetType) => (
          <GridRow
            assetType={assetType}
            key={assetType.id}
            onDelete={onDelete}
            enableTemperature={enableTemperature}
          />
        ))}
      </Grid.Grid>
    </>
  );
};

const GridRow = ({ assetType, onDelete, enableTemperature }) => {
  return (
    <Grid.Row>
      <Grid.Cell size={SIZES[3]}>{assetType.name}</Grid.Cell>
      <Grid.Cell size={SIZES[2]}>
        {assetType.assets.paginatorInfo.total}
      </Grid.Cell>
      {enableTemperature && (
        <Grid.Cell size={SIZES[2]}>{assetType.temperature_min}</Grid.Cell>
      )}
      {enableTemperature && (
        <Grid.Cell size={SIZES[2]}>{assetType.temperature_max}</Grid.Cell>
      )}
      <Grid.Cell size={SIZES[0]} alignment="right">
        <Link
          to={generatePath(routes.administrationAssetTypeEdition.path, {
            id: assetType.id,
          })}
        >
          <Grid.IconButton imgSrc={EditIcon} />
        </Link>
      </Grid.Cell>
      <Grid.Cell size={SIZES[0]} alignment="right">
        {(assetType.assets.paginatorInfo.total > 0 && (
          <Grid.IconButton disabled={true} imgSrc={DeleteIcon} />
        )) || (
          <Grid.IconButton
            onClick={() => onDelete(assetType)}
            imgSrc={DeleteIcon}
          />
        )}
      </Grid.Cell>
    </Grid.Row>
  );
};

export { DataGrid };
