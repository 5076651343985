import styled from 'styled-components';

export const ConditionFieldsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
`;

export const ConditionTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const NameContainer = styled.div`
    padding: 15px 15px 20px 15px;
    border-radius: 5px;
    border: 1px solid #002B2B;
`;

export const PaddingContainer = styled.div`
    padding-top: 15px;
`;

export const NotificationContainer = styled.div`
    padding: 15px 15px 20px 15px;
    border-radius: 5px;
    border: 1px solid #6CA6AB;
`;

export const ActionContainer = styled.div`
    padding: 15px 15px 20px 15px;
    border-radius: 5px;
    border: 1px solid #EF9D11;
`;

export const TitleContainer = styled.div`
    display: grid;
    grid-template-columns: 28px 1fr;
`;

export const ConditionContainer = styled.div`
    padding: 15px 15px 20px 15px;
    border-radius: 5px;
    border: 1px solid #B3B3B3;
`;

export const EventContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
`;
