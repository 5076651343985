import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import reactDom from 'react-dom';

import { Modal as ModalLib, ScrollableContainer } from 'front-library';

import {
  MarginModal,
  MarginScrollableContainer,
  ModalContainer,
} from './styled-components';

const SIZES = {
  small: 400,
  medium: 520,
  big: 1060,
};

const Modal = ({
  children,
  className,
  onClose,
  size = 'medium',
  showCross,
  title,
  zIndex = 1002,
}) => {
  useEffect(() => {
    document.querySelector('body').style.overflow = 'hidden';

    return () => {
      document.querySelector('body').style.overflow = 'auto';
    };
  }, []);

  return reactDom.createPortal(
    <ModalContainer $zIndex={zIndex}>
      <ModalLib
        className={className}
        onClose={onClose}
        showCross={showCross}
        title={title}
        width={SIZES[size]}
      >
        <MarginModal>
          <ScrollableContainer maxHeight="82vh">
            <MarginScrollableContainer>{children}</MarginScrollableContainer>
          </ScrollableContainer>
        </MarginModal>
      </ModalLib>
    </ModalContainer>,
    document.body,
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  onClose: PropTypes.func,
  showCross: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(SIZES)),
  title: PropTypes.string,
  zIndex: PropTypes.number,
};

export { Modal };
