import { gql } from '@apollo/client';

const GET_SITES = gql`
    query GetSites {
        sites(first: 100000) {
            data {
                id
                name
                latitude
                longitude
            }
        }
    }
`;

const GET_API_USER = gql`
    query GetApiUser {
        apiUser {
            api_token
        }
    }
`;

export { GET_SITES, GET_API_USER };
