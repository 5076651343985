import { useMutation } from '@apollo/client';
import isEqual from 'lodash/isEqual';
import parser from 'papaparse';
import React, { useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Button, ErrorMessage, FileInput, Loading } from 'front-library';

import { MeContext } from '../../../contexts/MeContext';
import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { DataGrid } from './DataGrid';
import {
  CREATE_ASSETS,
  CREATE_SITES,
  CREATE_USERS,
  DELETE_ASSETS,
  DELETE_SITES,
  GET_ASSETS,
  GET_SITES,
  UPDATE_ASSETS,
  UPDATE_SITES,
} from './graphql';
import {
  Container,
  ImportButtonTitle,
  ImportLink,
  ImportSelect,
  ImportTitle,
  InputContainer,
} from './styled-components';
import {
  IMPORT_TYPES,
  mapAssetValues,
  mapSiteValues,
  parseAssets,
  parseSites,
  parseUsers,
} from './utils';

const importMaxLines = Number.parseInt(
  document
    .querySelector('meta[name="import-max-lines"]')
    ?.getAttribute('content') ?? 2000,
);
const importMaxLinesForUpdate = importMaxLines / 4;

const Import = () => {
  const { t } = useTranslation();
  const { me: user } = useContext(MeContext);
  const errorsRef = useRef();

  const [skipAssetQuery, setSkipAssetQuery] = useState(true);
  const [skipSiteQuery, setSkipSiteQuery] = useState(true);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState([]);
  const [showData, setShowData] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [users, setUsers] = useState([]);
  const [assets, setAssets] = useState([]);
  const [sites, setSites] = useState([]);
  const [wasRendered, setWasRendered] = useState(false);

  const {
    data: assetList,
    loading: assetsLoading,
    setVariable: setAssetVariable,
  } = useQueryGraphQl(
    GET_ASSETS,
    { first: importMaxLines },
    {},
    skipAssetQuery,
  );
  const {
    data: siteList,
    loading: sitesLoading,
    setVariable: setSiteVariable,
  } = useQueryGraphQl(GET_SITES, { first: importMaxLines }, {}, skipSiteQuery);

  const [createUsersMutation, { loading: createUsersLoading }] =
    useMutation(CREATE_USERS);
  const [createAssetsMutation, { loading: createAssetsLoading }] =
    useMutation(CREATE_ASSETS);
  const [createSitesMutation, { loading: createSitesLoading }] =
    useMutation(CREATE_SITES);
  const [updateAssetsMutation, { loading: updateAssetsLoading }] =
    useMutation(UPDATE_ASSETS);
  const [deleteAssetsMutation, { loading: deleteAssetsLoading }] =
    useMutation(DELETE_ASSETS);
  const [updateSitesMutation, { loading: updateSitesLoading }] =
    useMutation(UPDATE_SITES);
  const [deleteSitesMutation, { loading: deleteSitesLoading }] =
    useMutation(DELETE_SITES);

  const HEADERS = {
    [IMPORT_TYPES.user_import]: [
      t('import:headers.name'),
      t('import:headers.surname'),
      t('import:headers.userName'),
      t('import:headers.email'),
      t('import:headers.password'),
      t('import:headers.updatePassword'),
      t('import:headers.branches'),
      t('import:headers.page'),
      t('import:headers.language'),
      t('import:headers.alerts'),
      t('import:headers.roles'),
    ],
    [IMPORT_TYPES.site_import]: [
      t('import:headers.name'),
      t('import:headers.address'),
      t('import:headers.type'),
      t('import:headers.reference'),
      t('import:headers.latitude'),
      t('import:headers.longitude'),
      t('import:headers.radius'),
      t('import:headers.branches'),
    ],
    [IMPORT_TYPES.asset_import]: [
      t('import:headers.reference'),
      t('import:headers.magId'),
      t('import:headers.type'),
      t('import:headers.state'),
      t('import:headers.purchaseValue'),
      t('import:headers.branches'),
      t('import:headers.responsible'),
    ],
    [IMPORT_TYPES.site_modification]: [
      t('import:headers.name'),
      t('import:headers.address'),
      t('import:headers.type'),
      t('import:headers.reference'),
      t('import:headers.branches'),
    ],
  };

  const setRequiredError = (importType, line, index, errors) => {
    errors.push(
      t('import:errors.required', {
        line: line + 1,
        field: HEADERS[importType][index].toLowerCase(),
      }),
    );
  };

  const operations = [
    {
      value: IMPORT_TYPES.asset_import,
      title: `${t('import:operations.assetImport')} ${t('import:maximumLimit', {
        limit: importMaxLines,
      })}`,
      url: `${process.env.REACT_APP_IMPORT_EXAMPLE_PATH}/asset_import_template.csv`,
      columnsNumber: 7,
      entries: t('import:assets'),
      action: 'created',
      setInput: (rowsArray) => {
        setAssets(
          parseAssets(
            rowsArray,
            'create',
            errorsRef,
            t,
            setErrors,
            (line, index, errors) =>
              setRequiredError(IMPORT_TYPES.asset_import, line, index, errors),
          ),
        );
        setHeaders(HEADERS[IMPORT_TYPES.asset_import]);
      },
      mutation: async () =>
        (await createAssetsMutation({ variables: { input: assets } })).data
          .createAssets,
    },
    {
      value: IMPORT_TYPES.asset_modification,
      title: `${t('import:operations.assetModification')} ${t(
        'import:maximumLimit',
        { limit: importMaxLinesForUpdate },
      )}`,
      url: `${process.env.REACT_APP_IMPORT_EXAMPLE_PATH}/asset_modification_import_template.csv`,
      columnsNumber: 7,
      entries: t('import:assets'),
      action: 'updated',
      setInput: (rowsArray) => {
        setAssets(
          parseAssets(
            rowsArray,
            'update',
            errorsRef,
            t,
            setErrors,
            (line, index, errors) =>
              setRequiredError(IMPORT_TYPES.asset_import, line, index, errors),
          ),
        );
        setHeaders(HEADERS[IMPORT_TYPES.asset_import]);
        setAssetVariable(
          'references',
          rowsArray.map((row) => row[0]),
        );
        setSkipAssetQuery(false);
      },
      mutation: async () =>
        (await updateAssetsMutation({ variables: { input: assets } })).data
          .updateAssets,
    },
    {
      value: IMPORT_TYPES.asset_deletion,
      title: `${t('import:operations.assetDeletion')} ${t(
        'import:maximumLimit',
        { limit: importMaxLines },
      )}`,
      url: `${process.env.REACT_APP_IMPORT_EXAMPLE_PATH}/asset_deletion_template.csv`,
      columnsNumber: 1,
      entries: t('import:assets'),
      action: 'deleted',
      setInput: (rowsArray) => {
        setHeaders(HEADERS[IMPORT_TYPES.asset_import]);
        setAssetVariable(
          'references',
          rowsArray.map((row) => row[0]),
        );
        setSkipAssetQuery(false);
      },
      mutation: async () =>
        (await deleteAssetsMutation({ variables: { ids: assets } })).data
          .deleteAssets,
    },
    {
      value: IMPORT_TYPES.site_import,
      title: `${t('import:operations.siteImport')} ${t('import:maximumLimit', {
        limit: importMaxLines,
      })}`,
      url: `${process.env.REACT_APP_IMPORT_EXAMPLE_PATH}/template_import_sites.csv`,
      columnsNumber: 8,
      entries: t('import:sites'),
      action: 'created',
      setInput: (rowsArray) => {
        setSites(
          parseSites(
            rowsArray,
            'create',
            errorsRef,
            t,
            setErrors,
            (line, index, errors) =>
              setRequiredError(IMPORT_TYPES.site_import, line, index, errors),
          ),
        );
        setHeaders(HEADERS[IMPORT_TYPES.site_import]);
      },
      mutation: async () =>
        (await createSitesMutation({ variables: { input: sites } })).data
          .createSites,
    },
    {
      value: IMPORT_TYPES.site_modification,
      title: `${t('import:operations.siteModification')} ${t(
        'import:maximumLimit',
        { limit: importMaxLinesForUpdate },
      )}`,
      url: `${process.env.REACT_APP_IMPORT_EXAMPLE_PATH}/site_modification_template.csv`,
      columnsNumber: 5,
      entries: t('import:sites'),
      action: 'updated',
      setInput: (rowsArray) => {
        setSites(
          parseSites(
            rowsArray,
            'update',
            errorsRef,
            t,
            setErrors,
            (line, index, errors) =>
              setRequiredError(
                IMPORT_TYPES.site_modification,
                line,
                index,
                errors,
              ),
          ),
        );
        setHeaders(HEADERS[IMPORT_TYPES.site_modification]);
        setSiteVariable(
          'names',
          rowsArray.map((row) => row[0]),
        );
        setSkipSiteQuery(false);
      },
      mutation: async () =>
        (await updateSitesMutation({ variables: { input: sites } })).data
          .updateSites,
    },
    {
      value: IMPORT_TYPES.site_deletion,
      title: `${t('import:operations.siteDeletion')} ${t(
        'import:maximumLimit',
        { limit: importMaxLines },
      )}`,
      url: `${process.env.REACT_APP_IMPORT_EXAMPLE_PATH}/site_deletion_template.csv`,
      columnsNumber: 1,
      entries: t('import:sites'),
      action: 'deleted',
      setInput: (rowsArray) => {
        setHeaders(HEADERS[IMPORT_TYPES.site_import]);
        setSiteVariable(
          'names',
          rowsArray.map((row) => row[0]),
        );
        setSkipSiteQuery(false);
      },
      mutation: async () =>
        (await deleteSitesMutation({ variables: { ids: sites } })).data
          .deleteSites,
    },
    {
      value: IMPORT_TYPES.user_import,
      title: `${t('import:operations.userImport')} ${t('import:maximumLimit', {
        limit: importMaxLines,
      })}`,
      url: `${process.env.REACT_APP_IMPORT_EXAMPLE_PATH}/user_import_template.csv`,
      columnsNumber: 16,
      entries: t('import:users'),
      action: 'created',
      setInput: (rowsArray) => {
        setUsers(
          parseUsers(
            rowsArray,
            errorsRef,
            t,
            setErrors,
            (line, index, errors) =>
              setRequiredError(IMPORT_TYPES.user_import, line, index, errors),
          ),
        );
        setHeaders(HEADERS[IMPORT_TYPES.user_import]);
      },
      mutation: async () =>
        (await createUsersMutation({ variables: { input: users } })).data
          .createUsers,
    },
  ];

  // Edit asset preview when modifying / deleting
  if (
    (IMPORT_TYPES.asset_modification === selectedOperation?.value ||
      IMPORT_TYPES.asset_deletion === selectedOperation?.value) &&
    assetList?.assets?.data &&
    !wasRendered
  ) {
    const newAssets = [];
    const newErrors = [];

    const editedRows = [...rows].map((editedRow, index) => {
      const asset = assetList.assets.data.find(
        (asset) => asset.reference === editedRow[0],
      );

      if (!asset) {
        newErrors.push(
          t('import:errors.assetNotFound', {
            line: index + 1,
            reference: editedRow[0],
          }),
        );

        return editedRow;
      }

      const mappedAsset = mapAssetValues(asset);

      if (IMPORT_TYPES.asset_modification === selectedOperation?.value) {
        // Add asset id for update
        const newAsset = {
          ...assets.find((a) => a.reference === asset.reference),
          id: asset.id,
        };
        newAssets.push(newAsset);

        const map = [
          { index: 1, key: 'mag_id', value: mappedAsset.mag_id },
          { index: 2, key: 'type', value: mappedAsset.type },
          { index: 3, key: 'state', value: mappedAsset.state },
          { index: 4, key: 'buy_value', value: mappedAsset.buy_value },
          {
            index: 5,
            key: 'branches',
            transform: (cell) => {
              const branches = cell
                ? cell.split(';').map((branch) => branch.trim())
                : null;
              return branches && !isEqual(branches, mappedAsset.branches)
                ? { value: cell }
                : branches?.join(';');
            },
          },
          { index: 6, key: 'responsible', value: mappedAsset.responsible },
        ];

        return map.reduce(
          (row, { index, value, transform }) => {
            if (!isEqual(editedRow[index], value)) {
              row[index] = transform
                ? transform(editedRow[index])
                : { value: editedRow[index] };
            }
            return row;
          },
          [...editedRow],
        );
      }

      editedRow[1] = mappedAsset.mag_id;
      editedRow[2] = mappedAsset.type;
      editedRow[3] = mappedAsset.state;
      editedRow[4] = mappedAsset.buy_value;
      editedRow[5] = mappedAsset.branches;
      editedRow[6] = mappedAsset.responsible;

      newAssets.push(asset.id);

      return editedRow;
    });

    setAssets(newAssets);
    setErrors([...errors, ...newErrors]);
    setRows(editedRows);
    setWasRendered(true);
  }

  // Edit site preview when modifying / deleting
  if (
    [IMPORT_TYPES.site_modification, IMPORT_TYPES.site_deletion].includes(
      selectedOperation?.value,
    ) &&
    siteList?.sites?.data &&
    !wasRendered
  ) {
    const newSites = [];
    const newErrors = [];

    const editedRows = [...rows].map((editedRow, index) => {
      const site = siteList.sites.data.find(
        (site) => site.name === editedRow[0],
      );

      if (!site) {
        newErrors.push(
          t('import:errors.siteNotFound', {
            line: index + 1,
            name: editedRow[0],
          }),
        );

        return editedRow;
      }

      const mappedSite = mapSiteValues(site);

      if (IMPORT_TYPES.site_modification === selectedOperation?.value) {
        // Add site id for update
        const newSite = {
          ...sites.find((s) => s.name === site.name),
          id: site.id,
        };

        newSites.push(newSite);

        const map = [
          { index: 1, key: 'address', value: mappedSite.address },
          { index: 2, key: 'type', value: mappedSite.type },
          { index: 3, key: 'reference', value: mappedSite.reference },
          {
            index: 4,
            key: 'branches',
            value: mappedSite.branches.join(';'),
            transform: (cell) => {
              const branches = cell
                ? cell.split(';').map((branch) => branch.trim())
                : null;
              return branches && !isEqual(branches, mappedSite.branches)
                ? { value: cell }
                : branches?.join(';');
            },
          },
        ];

        // Edit row to highlight changes
        const mappedRow = map.reduce(
          (row, { index, value, transform }) => {
            if ('' === editedRow[index]) {
              row[index] = value;
            } else if (!isEqual(editedRow[index], value)) {
              row[index] = transform
                ? transform(editedRow[index])
                : { value: editedRow[index] };
            }
            return row;
          },
          [...editedRow],
        );

        return mappedRow;
      }

      editedRow[1] = mappedSite.address;
      editedRow[2] = mappedSite.type;
      editedRow[3] = mappedSite.reference;
      editedRow[4] = mappedSite.latitude;
      editedRow[5] = mappedSite.longitude;
      editedRow[6] = mappedSite.geofence_radius;
      editedRow[7] = mappedSite.branches;

      newSites.push(site.id);

      return editedRow;
    });

    setSites(newSites);
    setErrors([...errors, ...newErrors]);
    setRows(editedRows);
    setWasRendered(true);
  }

  // To fix stale closure problem
  errorsRef.current = errors;

  const preprocessData = () => {
    setErrors([]);
    setRows([]);
    setShowData(false);

    if (!skipAssetQuery) {
      setSkipAssetQuery(true);
    }

    if (!skipSiteQuery) {
      setSkipSiteQuery(true);
    }
  };

  const onSelectOperation = (operation) => {
    if (operation) {
      preprocessData();
      setSelectedOperation(operation);
    } else {
      setErrors([`${selectedOperation.value} ${t('import:errors.unknown')}`]);
    }
  };

  const onSelectFile = (file) => {
    preprocessData();
    setFile(file);
  };

  const importFile = () => {
    setErrors([]);

    parser.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        if (!results.data.length) {
          setErrors([t('import:errors.emptyFile')]);

          return;
        }

        const lineCount =
          'updated' === selectedOperation.action
            ? importMaxLinesForUpdate
            : importMaxLines;

        // Check file line count
        if (results.data.length > lineCount) {
          setErrors([t('import:errors.fileTooBig', { lineCount })]);

          return;
        }

        // Check number of columns
        if (
          selectedOperation.columnsNumber !==
          Object.keys(results.data[0]).length
        ) {
          setErrors([t('import:errors.length')]);

          return;
        }

        const rowsArray = [];

        for (const index in results.data) {
          rowsArray.push(Object.values(results.data[index]));
        }

        selectedOperation.setInput(rowsArray);
        setRows(rowsArray);
        setShowData(!errorsRef.current.length);
        setWasRendered(false);
      },
    });
  };

  const setMutationErrors = (errors) => {
    const newErrors = [];

    for (const error of errors) {
      newErrors.push(error.message);
    }

    setErrors(newErrors);
  };

  const confirmImport = async () => {
    const operation = operations.find(
      (o) => o.value === selectedOperation.value,
    );

    try {
      const response = await operation.mutation();

      if (response?.errors?.length) {
        setMutationErrors(response.errors);
        toast.error(
          t(
            `import:${operation.action}.${
              response.errors.some(
                (error) => 'validation' === error.extensions.reason,
              )
                ? 'failure'
                : 'partialFailure'
            }`,
            { entries: operation.entries },
          ),
        );

        return;
      }

      setErrors([]);
      toast.success(
        t(`import:${operation.action}.success`, { entries: operation.entries }),
      );
    } catch (error) {
      const errorMessage =
        error?.graphQLErrors[0]?.debugMessage ||
        error?.graphQLErrors[0]?.message ||
        '';

      setErrors([errorMessage]);
      setShowData(false);
      toast.error(
        t(`import:${operation.action}.failure`, { entries: operation.entries }),
      );
    }
  };

  return (
    <Container>
      {(assetsLoading ||
        sitesLoading ||
        createUsersLoading ||
        createAssetsLoading ||
        updateAssetsLoading ||
        deleteAssetsLoading ||
        createSitesLoading ||
        updateSitesLoading ||
        deleteSitesLoading) && <Loading />}
      <>
        <ImportTitle>{user.company.name}</ImportTitle>
        <InputContainer>
          {/* Operation */}
          <ImportSelect
            label={t('import:operation')}
            onChange={(data) =>
              onSelectOperation(operations.find(({ value }) => value === data))
            }
            options={operations}
            required={true}
            value={selectedOperation ? selectedOperation.value : ''}
          />
          {/* Example */}
          <div>
            <ImportButtonTitle>{t('import:example')}</ImportButtonTitle>
            <ImportLink
              href={selectedOperation?.url}
              disabled={null === selectedOperation}
            >
              {t('import:download')}
            </ImportLink>
          </div>

          {/* File */}
          <div>
            <ImportButtonTitle>{t('import:file')}</ImportButtonTitle>
            <FileInput
              label={t('import:chooseFile')}
              onChange={(file) => onSelectFile(file)}
              fileName={file?.name}
            />
          </div>

          {/* Import */}
          <div>
            <ImportButtonTitle>{t('import:action')}</ImportButtonTitle>
            <Button
              disabled={!(selectedOperation && file && !rows.length)}
              onClick={importFile}
              text={t('import:import')}
              variant="green"
            />
          </div>
        </InputContainer>

        {/* Errors */}
        {errors?.map((error) => (
          <ErrorMessage key={error} text={error} />
        ))}
      </>

      {showData && headers && (
        <>
          <DataGrid headers={headers} rows={rows} />
          <Button
            onClick={confirmImport}
            text={t('import:validate')}
            variant="green"
          />
        </>
      )}
    </Container>
  );
};

export { Import };
