import { gql } from '@apollo/client';

const CREATE_FLUX = gql`
    mutation CreateFlux($name: String!) {
        createFlux(
            input: {
                name: $name
            }
        ) {
            id
            name
        }
    }
`;

const GET_FLUX = gql`
    query GetFlux($first: Int, $page: Int, $search: Mixed, $orderBy: [QueryFluxOrderByOrderByClause!]) {
        flux(
            first: $first
            page: $page
            orderBy: $orderBy
            where: {
                column: "name"
                operator: LIKE
                value: $search
            }
        ) {
            paginatorInfo{
                total
                currentPage
                lastPage
                perPage
            }
            data {
                id
                name
                flux_configurations(
                    first: 100000
                    orderBy: { column: PERIOD_TO, order: ASC }
                ) {
                    data {
                        id
                        name
                        period_from
                        period_to
                        review_date
                        comment
                        flux {
                            id
                        }
                        flux_configuration_sites(
                            first: 100000
                            orderBy: { column: ORDER, order: ASC}
                        ) {
                            data {
                                id
                                site(trashed: WITH) {
                                    id
                                    name
                                    deleted_at
                                }
                                time_on_site
                                order
                            }
                        }
                    }
                }
            }
        }
    }
`;

const UPDATE_FLUX = gql`
    mutation UpdateFlux($id: ID!, $name: String!) {
        updateFlux(
            input: {
                id: $id
                name: $name
            }
        ) {
            id
        }
    }
`;

const DELETE_FLUX = gql`
    mutation DeleteFlux(
        $id: ID!
    ) {
        deleteFlux(
            id: $id
        ) {
            id
        }
    }
`;

const CREATE_CONFIGURATION = gql`
    mutation CreateConfiguration(
        $flux: UpdateRelation!,
        $name: String!,
        $period_from: DateTime,
        $period_to: DateTime,
        $review_date: DateTime,
        $comment: String,
        $flux_configuration_sites: UpdateFluxConfigurationSiteRelation
    ) {
        createFluxConfiguration(
            input: {
                flux: $flux
                name: $name
                period_from: $period_from
                period_to: $period_to
                review_date: $review_date
                comment: $comment
                flux_configuration_sites: $flux_configuration_sites
            }
        ) {
            id
            name
        }
    }
`;

const UPDATE_CONFIGURATION = gql`
    mutation UpdateConfiguration(
        $id: ID!,
        $name: String!,
        $period_from: DateTime,
        $period_to: DateTime,
        $review_date: DateTime,
        $comment: String,
        $flux_configuration_sites: UpdateFluxConfigurationSiteRelation
    ) {
        updateFluxConfiguration(
            input: {
                id: $id
                name: $name
                period_from: $period_from
                period_to: $period_to
                review_date: $review_date
                comment: $comment
                flux_configuration_sites: $flux_configuration_sites
            }
        ) {
            id
            name
        }
    }
`;

const DELETE_CONFIGURATION = gql`
    mutation DeleteFluxConfiguration(
        $id: ID!
    ) {
        deleteFluxConfiguration(
            id: $id
        ) {
            id
        }
    }
`;

const GET_SITES = gql`
    query GetSites {
        sites(first: 100000) {
            data {
                value: id
                title: name
                site_type {
                    name
                }
            }
        }
    }
`;

export {
  CREATE_FLUX,
  GET_FLUX,
  UPDATE_FLUX,
  DELETE_FLUX,
  CREATE_CONFIGURATION,
  UPDATE_CONFIGURATION,
  DELETE_CONFIGURATION,
  GET_SITES,
};
