import { Autocomplete } from 'front-library';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

let oldAddress = '';
let timeout = null;

const AddressAutocomplete = ({
  errorText = null,
  onChange,
  onInputChange,
  value,
}) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState([]);
  const [input, setInput] = useState(value?.title || '');

  useEffect(() => {
    if (!input || input.length < 3 || input === oldAddress) {
      return;
    }

    oldAddress = input;

    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      (async () => {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?q=${input.replace(
            / /g,
            '+',
          )}&format=json&polygon=1&addressdetails=1`,
        );
        const json = await response.json();
        const mapped = json.map((addr) => ({
          value: addr.place_id,
          title: addr.display_name,
          latitude: addr.lat,
          longitude: addr.lon,
        }));

        setOptions(uniqBy(mapped, (obj) => obj.title));
      })();
    }, 500);
  }, [input]);

  return (
    <Autocomplete
      errorText={errorText}
      hideCheckboxes={true}
      hideDropwdownArrow={true}
      label={t('site:form.address')}
      onChange={onChange}
      onInputChange={(value) => {
        setInput(value);
        onInputChange?.(value);
      }}
      options={options}
      placeholder={value?.title || ''}
      required={true}
      value={value}
    />
  );
};

AddressAutocomplete.propTypes = {
  errorText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  value: PropTypes.shape({
    value: PropTypes.any,
    title: PropTypes.string,
  }),
};

export { AddressAutocomplete };
