import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PanelSection, ScrollableContainer } from 'front-library';

import { MeContext } from '../../../../contexts/MeContext';
import { getShippingOrderTranslate } from '../../../../helpers';
import { useQueryGraphQl } from '../../../../hooks/useQueryGraphQl';
import { generatePathWithQueryParams, routes } from '../../../../routes';
import { LocationMap } from '../../../molecules/LocationMap';
import { mapGeofence } from '../../../molecules/LocationMap/utils';
import { CommentCarrierPanelSection } from '../CommentCarrierPanelSection/CommentCarrierPanelSection';
import { MagPanelSection } from '../MagPanelSection/MagPanelSection';
import { RoutePanelSection } from '../RoutePanelSection/RoutePanelSection';

import { GET_ASSET_GROUP } from './graphql';
import {
  Container,
  Eta,
  PanelContainer,
  PanelWithBorderColor,
  WithMarginGraySeparator,
} from './styled-components';

const PANEL_COLORS = {
  green: '#7BC769',
  orange: '#EF9D11',
  gray: '#b3b3b3',
};

const Details = ({ nextEstimatedStop, masterShippingOrder, refetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { me: user } = useContext(MeContext);

  const { data: assetGroup, loading: assetGroupLoading } = useQueryGraphQl(
    GET_ASSET_GROUP,
    {
      assetGroupId: masterShippingOrder.asset_group?.id,
      earlyDeparture: moment(masterShippingOrder.departure_timestamp).subtract(
        user.company.early_departure,
        'minutes',
      ),
    },
  );

  const magDatas = useMemo(
    () => assetGroup?.asset_group?.mag_datas?.data,
    [assetGroup],
  );

  const lastPosition = useMemo(
    () => (magDatas?.length ? magDatas[magDatas.length - 1] : null),
    [magDatas],
  );

  // Has we already have the degraded position in the requested mag_datas we
  // can find it here instead of requesting the mag_datas table a second time.
  const degradedPosition = useMemo(() => {
    if (
      !magDatas?.length ||
      !masterShippingOrder.mag_data_id_degraded_position
    ) {
      return null;
    }

    return magDatas.find(
      (md) => md.id === masterShippingOrder.mag_data_id_degraded_position,
    );
  }, [masterShippingOrder, magDatas]);

  // Used to check if we can update the geofence of a site included in the shipping order
  const canUpdateGeofence = useMemo(
    () =>
      ![
        'delivered',
        'delivery_confirmed',
        'arrived_on_delivery_site',
        'canceled',
        'closed',
      ].includes(masterShippingOrder.shipping_order_status.name) &&
      routes.siteEdit.allowed(user) &&
      !masterShippingOrder.is_recomputing,
    [masterShippingOrder, user],
  );

  const panelColor = useMemo(() => {
    if (
      ['delivery_in_progress', 'closed'].includes(
        masterShippingOrder.shipping_order_status.name,
      )
    ) {
      return 'orange';
    }

    if (
      [
        'paired',
        'delivered',
        'delivery_confirmed',
        'arrived_on_delivery_site',
      ].includes(masterShippingOrder.shipping_order_status.name)
    ) {
      return 'green';
    }

    return 'gray';
  }, [masterShippingOrder]);

  const handleGeofenceEdit = useCallback(
    (siteId) =>
      navigate(
        generatePathWithQueryParams(
          routes.siteEdit.path,
          { id: siteId },
          { editGeofence: 1, soReference: masterShippingOrder.reference },
        ),
      ),
    [navigate, masterShippingOrder],
  );

  return (
    <Container>
      {!assetGroupLoading && (
        <LocationMap
          departureSite={{
            ...masterShippingOrder.departure_site,
            geofence: mapGeofence(
              masterShippingOrder?.departure_site?.geofence,
            ),
          }}
          arrivalSite={{
            ...masterShippingOrder.arrival_site,
            isDelivered: masterShippingOrder.real_arrival_timestamp !== null,
            geofence: mapGeofence(masterShippingOrder?.arrival_site?.geofence),
          }}
          checkpoints={
            masterShippingOrder.tour
              ? masterShippingOrder.tour.shipping_orders.data
                  .filter((so) => so.tour_order !== null)
                  .map((so) => ({
                    ...so.arrival_site,
                    geofence: mapGeofence(so?.arrival_site?.geofence),
                    isDelivered: so.real_arrival_timestamp !== null,
                  }))
              : []
          }
          routing={magDatas || []}
          order={lastPosition}
          degradedPosition={degradedPosition}
          onGeofenceEdit={canUpdateGeofence ? handleGeofenceEdit : null}
        />
      )}
      <PanelContainer>
        <PanelWithBorderColor color={PANEL_COLORS[panelColor]}>
          <ScrollableContainer maxHeight="70vh">
            <PanelSection
              title={getShippingOrderTranslate(
                t,
                masterShippingOrder.shipping_order_status.name,
              )}
              customContent={<Eta>{nextEstimatedStop}</Eta>}
              arrowColor={panelColor}
              isOpenDefault={true}
            >
              <WithMarginGraySeparator />
              <RoutePanelSection shippingOrder={masterShippingOrder} />
              <WithMarginGraySeparator />
              <CommentCarrierPanelSection
                shippingOrder={masterShippingOrder}
                refetch={refetch}
              />
              <WithMarginGraySeparator />
              <MagPanelSection
                lastPosition={lastPosition}
                latestMagData={assetGroup?.asset_group?.latestMagData}
              />
            </PanelSection>
          </ScrollableContainer>
        </PanelWithBorderColor>
      </PanelContainer>
    </Container>
  );
};

Details.propTypes = {
  nextEstimatedStop: PropTypes.string,
  masterShippingOrder: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export { Details };
