import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Img500 from '../../../assets/images/errors/500.svg';
import { ErrorPage } from '../../templates/ErrorPage/ErrorPage';

const ServerError = () => {
  const { t } = useTranslation();

  return (
    <ErrorPage
      image={Img500}
      text={
        <Trans components={{ br: <br /> }} i18nKey="error:serverError.text" />
      }
      title={t('error:serverError.title')}
    />
  );
};

export { ServerError };
