import moment from 'moment';

const COLORS = {
  red: '#C81912',
  green: '#ACD77A',
  orange: '#EF9D11',
  gray: '#B3B3B3',
};

export function buildPoints(shippingOrder) {
  const points = [
    {
      siteName: shippingOrder.departure_site.name,
      siteBranches: shippingOrder.departure_site.branches,
      timestamp: shippingOrder.departure_timestamp,
      realDepartureTimestamp: shippingOrder.real_departure_timestamp,
      realArrivalTimestamp: shippingOrder.real_arrival_timestamp,
      humanEta: shippingOrder.human_eta,
      first: true,
    },
  ];

  if (shippingOrder.tour) {
    const checkpoints = shippingOrder.tour.shipping_orders.data.filter(
      (so) => null !== so.tour_order,
    );

    for (const checkpoint of checkpoints) {
      points.push({
        siteName: checkpoint.arrival_site.name,
        siteBranches: checkpoint.arrival_site.branches,
        timestamp: checkpoint.arrival_timestamp,
        realDepartureTimestamp: checkpoint.real_departure_timestamp,
        realArrivalTimestamp: checkpoint.real_arrival_timestamp,
        humanEta: checkpoint.human_eta,
      });
    }
  }

  points.push({
    siteName: shippingOrder.arrival_site.name,
    siteBranches: shippingOrder.arrival_site.branches,
    timestamp: shippingOrder.arrival_timestamp,
    realDepartureTimestamp: shippingOrder.real_departure_timestamp,
    realArrivalTimestamp: shippingOrder.real_arrival_timestamp,
    humanEta: shippingOrder.human_eta,
  });

  return points;
}

export function getDotColor(point) {
  if (
    (point.first && point.realDepartureTimestamp) ||
    (!point.first && point.realArrivalTimestamp)
  ) {
    return COLORS.green;
  }

  return COLORS.gray;
}

export function getBarColor(point) {
  if (point.realArrivalTimestamp) {
    if (point.realArrivalTimestamp > point.timestamp) {
      return COLORS.red;
    }

    return COLORS.green;
  }

  if (point.human_eta && point.human_eta > point.timestamp) {
    return COLORS.red;
  }

  return COLORS.orange;
}

export function getProgression(point) {
  if (point.realArrivalTimestamp) {
    return 100;
  }

  if (point.humanEta) {
    // if eta exist, time spent on total time
    const percentage =
      (moment(point.realDepartureTimestamp).diff(moment()) /
        moment(point.realDepartureTimestamp).diff(point.humanEta)) *
      100;

    // if percent > 100 but shipping order not delivered
    return percentage > 100 ? 99 : percentage;
  }

  return 0;
}

export function getNextPoint(points, index) {
  if (index === points.length - 1) {
    return null;
  }

  return points[index + 1];
}
