import { gql } from '@apollo/client';

const GET_ASSET_GROUPS = gql`
    query AssetGroups($first: Int, $page: Int, $search: Mixed, $orderBy: [QueryAssetGroupsOrderByRelationOrderByClause!]) {
        asset_groups(first: $first, page: $page, orderBy: $orderBy, where: {
            OR: [
                {
                    column: "reference"
                    operator: LIKE
                    value: $search
                }
                {
                    HAS: {
                        relation: "asset_group_type"
                        condition: {
                            column: "name"
                            operator: LIKE
                            value: $search
                        }
                    }
                }
                {
                    HAS: {
                        relation: "users"
                        condition: {
                            OR: [
                                {
                                    column: "name"
                                    operator: LIKE
                                    value: $search
                                }
                                {
                                    column: "surname"
                                    operator: LIKE
                                    value: $search
                                }
                            ]
                        }
                    }
                }
            ]
        }) {
            paginatorInfo {
                total
                currentPage
                lastPage
                perPage
            }
            data {
                id
                reference
                assets {
                    paginatorInfo {
                        total
                    }
                    data {
                        buy_value
                    }
                }
                asset_group_type {
                    id
                    name
                }
                responsibles {
                    data {
                        id
                        name
                        surname
                    }
                }
            }
        }
    }
`;

const DELETE_ASSET_GROUPS = gql`
    mutation DeleteAssetGroup($id: ID!) {
        deleteAssetGroup(id: $id) {
            id
        }
    }
`;

export { GET_ASSET_GROUPS, DELETE_ASSET_GROUPS };
