import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Loading } from 'front-library';

import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { routes } from '../../../routes';
import { Modal } from '../../molecules/Modal/Modal';
import { MagForm } from '../../organisms/Mags/MagForm';
import { CREATE_MAG, GET_FORM_DATA, GET_MAG, UPDATE_MAG } from './graphql';
import { mapMagFormValuesToGraphql, mapMagToFormValues } from './utils';

const Mag = ({ refetch }) => {
  const { t } = useTranslation();
  const { id = null } = useParams();
  const navigate = useNavigate();

  const { data: formData, loading: getFormDataLoading } =
    useQueryGraphQl(GET_FORM_DATA);
  const { data: magData, loading: getLoading } = useQueryGraphQl(
    GET_MAG,
    { id },
    {},
    null === id,
  );
  const [createMagMutation, { loading: createLoading }] =
    useMutation(CREATE_MAG);
  const [updateMagMutation, { loading: updateLoading }] =
    useMutation(UPDATE_MAG);

  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(mapMagToFormValues());

  if (magData?.mag && !formValues.id) {
    setFormValues({
      ...formValues,
      ...mapMagToFormValues(magData.mag),
    });
  }

  const title = useMemo(
    () => (id ? t('mags:update') : t('mags:create')),
    [id, t],
  );

  const handleSave = async () => {
    try {
      await createMagMutation({
        variables: mapMagFormValuesToGraphql(formValues),
      });

      setErrors({});
      refetch();
      handleClose();
      toast.success(t('mags:created.success', { name: formValues.magId }));
    } catch (e) {
      setErrors(e.graphQLErrors?.[0]?.extensions?.validation || {});
      toast.error(t('mags:created.error'));
    }
  };

  const handleUpdate = async () => {
    try {
      await updateMagMutation({
        variables: mapMagFormValuesToGraphql(formValues),
      });

      setErrors({});
      refetch();
      handleClose();
      toast.success(t('mags:updated.success', { name: formValues.magId }));
    } catch (e) {
      setErrors(e.graphQLErrors?.[0]?.extensions?.validation || {});
      toast.error(t('mags:updated.error', { name: formValues.magId }));
    }
  };

  const handleClose = () =>
    navigate(generatePath(routes.administrationMag.path));

  return (
    <Modal onClose={handleClose} title={title}>
      {(getLoading || createLoading || updateLoading || getFormDataLoading) && (
        <Loading />
      )}
      {!getLoading && !getFormDataLoading && (
        <MagForm
          formData={formData}
          formValues={formValues}
          setFormValues={setFormValues}
          errors={errors}
          onCancel={handleClose}
          onSubmit={id ? handleUpdate : handleSave}
        />
      )}
    </Modal>
  );
};

Mag.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export { Mag };
