import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Checkbox } from 'front-library';

import { MeContext } from '../../../contexts/MeContext';
import { Form } from '../../atoms/Form/Form';
import { Modal } from '../Modal/Modal';
import { Subtitle } from './styled-components';

const ExportModal = ({ downloadFile, onClose }) => {
  const { t } = useTranslation();
  const context = useContext(MeContext);

  const [extensions, setExtensions] = useState({ xlsx: false, csv: false });

  return (
    <Modal size="small" title={t('common:exportData')} onClose={onClose}>
      <Form
        disableSubmit={!extensions.csv && !extensions.xlsx}
        submitTranslateKey={t('common:startExport')}
        onSubmit={() => downloadFile(+context.me.id, extensions)}
        onCancel={onClose}
      >
        <Subtitle>
          <Trans
            i18nKey="common:exportModalHelpText"
            values={{
              email: context.me.email,
            }}
            components={{ strong: <strong /> }}
          />
        </Subtitle>

        <Checkbox
          label={t('common:exportXls')}
          onChange={(e) =>
            setExtensions({ ...extensions, xlsx: e.target.checked })
          }
          checked={extensions.xlsx}
        />
        <Checkbox
          label={t('common:exportCsv')}
          onChange={(e) =>
            setExtensions({ ...extensions, csv: e.target.checked })
          }
          checked={extensions.csv}
        />
      </Form>
    </Modal>
  );
};

ExportModal.propTypes = {
  downloadFile: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { ExportModal };
