import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Button, Panel } from 'front-library';
import close from 'front-library/build/img/delete-cross.svg';

import {
  AssetJourneyLabel,
  Content,
  Head,
  LastActionBlock,
  LastActionContent,
  LastActionLabel,
  LastActions,
  MagId,
  MagIdLabel,
  PanelContainer,
  State,
  Title,
} from './styled-components';

import { routes } from '../../../routes';

const AssetPanelSkeleton = () => {
  const navigate = useNavigate();

  return (
    <PanelContainer>
      <Panel>
        <Content>
          <Head>
            <Title>
              <Skeleton variant="text" width={100} />
            </Title>
            <State>
              <Skeleton variant="text" width={100} />
            </State>
            <Button
              img={close}
              variant="borderless"
              imgHoverAnimation="shrink"
              onClick={() => navigate(generatePath(routes.filtersMap.path))}
            />
          </Head>
          <MagIdLabel>
            <Skeleton variant="text" width={70} />
          </MagIdLabel>
          <MagId>
            <Skeleton variant="text" width={100} />
          </MagId>
          <LastActions>
            <LastActionBlock>
              <LastActionLabel>
                <Skeleton variant="text" width={150} />
              </LastActionLabel>
              <LastActionContent>
                <Skeleton variant="text" width={150} />
              </LastActionContent>
            </LastActionBlock>
            <LastActionBlock>
              <LastActionLabel>
                <Skeleton variant="text" width={150} />
              </LastActionLabel>
              <LastActionContent>
                <Skeleton variant="text" width={150} />
              </LastActionContent>
            </LastActionBlock>
          </LastActions>
          <AssetJourneyLabel>
            <Skeleton variant="text" width={130} />
          </AssetJourneyLabel>
          <Skeleton variant="text" height={50} />
        </Content>
      </Panel>
    </PanelContainer>
  );
};

export { AssetPanelSkeleton };
