import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ColumnCheckbox, Grid } from 'front-library';
import EditIcon from 'front-library/build/img/icon-modify-green-2.svg';
import DeleteIcon from 'front-library/build/img/trash.svg';

import { Link, generatePath } from 'react-router-dom';
import { routes } from '../../../../routes';

const GridHeaderContainer = styled.div`
    margin-bottom: 10px;
`;

const DataGrid = ({ data, onDelete, onOrderBy }) => {
  const { t } = useTranslation();

  return (
    <>
      <GridHeaderContainer>
        <Grid.Grid>
          <Grid.Header>
            <Grid.Cell size="xl">
              <ColumnCheckbox
                label={t('tourModels:form.name')}
                onClick={(order) => onOrderBy('NAME', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="xl">
              <ColumnCheckbox
                label={t('tourModels:form.reference')}
                onClick={(order) => onOrderBy('REFERENCE', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="xs" />
            <Grid.Cell size="xs" />
          </Grid.Header>
        </Grid.Grid>
      </GridHeaderContainer>

      <Grid.Grid>
        {data?.map((tourModel) => (
          <GridRow
            key={tourModel.id}
            tourModel={tourModel}
            onDelete={onDelete}
          />
        ))}
      </Grid.Grid>
    </>
  );
};

const GridRow = ({ tourModel, onDelete }) => {
  return (
    <Grid.Row>
      <Grid.Cell size="xl">{tourModel.name}</Grid.Cell>
      <Grid.Cell size="xl">{tourModel.reference}</Grid.Cell>
      <Grid.Cell size="xs" alignment="right">
        <Link
          to={generatePath(routes.administrationTourModelEdition.path, {
            id: tourModel.id,
          })}
        >
          <Grid.IconButton imgSrc={EditIcon} />
        </Link>
      </Grid.Cell>
      <Grid.Cell size="xs" alignment="right">
        <Grid.IconButton
          imgSrc={DeleteIcon}
          onClick={() => onDelete(tourModel)}
        />
      </Grid.Cell>
    </Grid.Row>
  );
};

export { DataGrid };
