export function mapMagToFormValues(mag = null) {
  return {
    id: mag?.id || null,
    magId: mag?.mag_id || '',
    providerDeviceId: mag?.provider_device_id || '',
    networkDeviceId: mag?.network_device_id || '',
    state: mag?.state || '',
    magType: mag?.mag_type?.id || null,
    company: mag?.company || null,
    owner: mag?.owner || null,
    ble: mag?.is_gateway ? 'gateway' : mag?.beacon ? 'beacon' : 'none',
    isGateway: mag?.is_gateway || false,
    beacon: mag?.beacon
      ? {
          minor: mag.beacon.minor,
          major: mag.beacon.major,
          macAddress: mag.beacon.mac_address,
        }
      : null,
    networkOperators: mag?.network_operators?.map((o) => o.value) || [],
  };
}

export function mapMagFormValuesToGraphql(mag) {
  return {
    ...(mag.id && { id: mag.id }),
    mag_id: mag.magId.trim(),
    provider_device_id: mag.providerDeviceId.trim(),
    network_device_id: mag.networkDeviceId.trim(),
    state: mag.state,
    mag_type: { connect: mag.magType },
    company: { connect: mag.company?.value },
    owner: { connect: mag.owner?.value },
    is_gateway: mag.isGateway,
    beacon: mag.beacon
      ? {
          minor: +mag.beacon.minor,
          major: +mag.beacon.major,
          mac_address: mag.beacon.macAddress,
        }
      : null,
    network_operators: {
      [mag.id ? 'sync' : 'connect']: mag.networkOperators,
    },
  };
}

export function mapMagImportToFormValues(data = null) {
  return {
    company: data?.company || null,
    owner: data?.owner || null,
    magType: data?.mag_type?.id || null,
    fakeAssets: data?.fakeAssets || false,
    file: data?.file || null,
    networkOperators: data?.network_operators?.map((o) => o.value) || [],
  };
}

export function mapMagImportFormValuesToGraphql({
  company,
  owner,
  magType,
  fakeAssets,
  file,
  networkOperators,
}) {
  return {
    company: +company?.value || null,
    owner: +owner?.value || null,
    mag_type: magType ? +magType : null,
    fakeAssets,
    file,
    network_operators: {
      connect: networkOperators.length ? networkOperators : [],
    },
  };
}
