import { Sidebar } from 'front-library';
import logo from 'front-library/build/img/logo-magma.png';
import React from 'react';

const SidebarLoading = () => {
  return (
    <div id="side-menu-loading">
      <Sidebar>
        <div className="logo">
          <a href="index.html">
            <img
              className="logo-magma"
              src={logo}
              alt="Logo Magma Technology"
            />
          </a>
        </div>
        <div className="welcome-loading" />
        <form className="search">
          <div className="item-search-loading">
            <div className="search-loading-1" />
            <div className="search-loading-2" />
          </div>
        </form>
        <div className="separateur" />
        <div className="element-loading" />
        <div className="element-loading" />
        <div className="element-loading" />
        <div className="element-loading" />
        <div className="contenu-menu-end">
          <div className="separateur separateur-2" />
          <div className="importer-section-loading">
            <div className="importer-loading-1" />
            <div className="importer-loading-2" />
          </div>
          <div className="importer-section-loading">
            <div className="importer-loading-1" />
            <div className="importer-loading-2" />
          </div>
          <div className="importer-section-loading">
            <div className="importer-loading-1" />
            <div className="importer-loading-2" />
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export { SidebarLoading };
