import { gql } from '@apollo/client';

const GET_ASSETS = gql`
    query GetAssets($search: Mixed) {
        assets(
            first: 20,
            where: {
                column: "reference"
                operator: LIKE
                value: $search
            }
            orderBy: {
                column: REFERENCE
                order: ASC
            }
        ) {
            data {
                value: id
                title: reference
                shipping_orders {
                    id
                }
            }
        }
    }
`;

export { GET_ASSETS };
