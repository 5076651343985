import { Grid } from 'front-library';
import styled from 'styled-components';

export const GridHeaderContainer = styled.div`
  margin-bottom: 10px;
`;

export const CellWithEye = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  gap: 5px;
  font-size: 12px;
  color: #002B2B;

  &:hover {
    img {
      transition: all 0.15s ease-out;
      height: 18px; 
    }
  }
`;

export const Container = styled.div`
  padding-bottom: 0px;
  padding-top: 35px;
  
  & > * {
    margin: 0;
  }
`;

export const PreCell = styled(Grid.Cell)`
  white-space: pre;
`;
