import dayjs from 'dayjs';
import moment from 'moment';
import { removeEmptyValues } from '../../../helpers';
import { range } from '../../organisms/ShippingOrders/CreationModal/utils';

/**
 *
 * @param {*} positions
 * @returns
 */
export const buildMovements = (positions) =>
  positions.filter((current, index, array) => {
    if (index < array.length - 1) {
      const next = array[index + 1];

      return (
        next.latitude !== current.latitude ||
        next.longitude !== current.longitude
      );
    }

    return true;
  });

/**
 *
 * @param {*} positions
 * @returns
 */
export const buildSinglePointsWithTimestamps = (positions, company) => {
  const results = [];

  for (const position of positions) {
    const samePosition = results.find(
      (r) =>
        r.latitude === position.latitude && r.longitude === position.longitude,
    );

    if (samePosition) {
      samePosition.accuracy = position.accuracy;
      samePosition.notPrecise = position.accuracy > company.accuracy;
      samePosition.timestamps.push(position.device_timestamp);
    } else {
      results.push({
        id: position.id,
        accuracy: position.accuracy,
        latitude: position.latitude,
        longitude: position.longitude,
        notPrecise: position.accuracy > company.accuracy,
        timestamps: [position.device_timestamp],
      });
    }
  }

  return results;
};

/**
 *
 * @param {*} date
 * @param {*} format
 * @returns
 */
export const getElapsedTime = (date, format) =>
  moment.duration(moment().diff(date)).format(format);

export function mapAssetToFormValues(asset) {
  return {
    mag: asset?.mag ? { title: asset.mag.mag_id, value: asset.mag.id } : null,
    branches:
      asset?.branches?.data?.map(({ id, name }) => ({
        title: name,
        value: id,
      })) || [],
    assetType: asset?.asset_type
      ? { title: asset.asset_type.name, value: asset.asset_type.id }
      : null,
    state: asset?.state?.id || '',
    assetGroup: asset?.asset_group
      ? { title: asset.asset_group.reference, value: asset.asset_group.id }
      : null,
    reference: asset?.reference || '',
    buyValue: asset?.buy_value || '',
    responsible: asset?.responsibles?.data?.length
      ? {
          title: asset.responsibles.data[0].name,
          value: asset.responsibles.data[0].id,
        }
      : null,
  };
}

export function mapAssetFormValuesToGraphql(formValues) {
  return removeEmptyValues({
    mag: formValues.mag ? { connect: formValues.mag.value } : null,
    branches: { sync: formValues.branches.map((branch) => branch.value) },
    asset_type: formValues.assetType
      ? { connect: formValues.assetType.value }
      : null,
    state: formValues.state ? { connect: formValues.state } : null,
    reference: formValues.reference,
    buy_value: +formValues.buyValue,
    asset_group: formValues.assetGroup
      ? { connect: formValues.assetGroup.value }
      : null,
    responsibles: {
      sync: formValues.responsible ? [formValues.responsible.value] : [],
    },
  });
}

export const disabledAssetJourneyDates = (current, { from }) => {
  const now = dayjs();

  if (current && from) {
    if (current < from.startOf('day')) {
      return true;
    }

    return current >= now;
  }

  return current && current >= now;
};

export const shouldDisabledTimes = (current, type, from, selectedDates) => {
  if (type !== 'end') {
    return null;
  }

  let currentDate = current;
  const now = dayjs();
  if (
    selectedDates.start &&
    now.startOf('minute').isSame(current.startOf('minute'))
  ) {
    currentDate = selectedDates.start;
  }

  const isToday = now.startOf('day').isSame(currentDate.startOf('day'));

  if (isToday) {
    return {
      disabledHours: () => range(now.hour() + 1, 24),
      disabledMinutes: () => range(now.minute(), 60),
    };
  }

  if (!from) {
    return null;
  }

  const isFromDay = currentDate.startOf('day') <= from.startOf('day');
  const isFromHour = isFromDay && currentDate.hour() <= from.hour();

  const hours = isFromDay ? from.hour() : [];
  const minutes = isFromHour ? from.minute() : [];

  return {
    disabledHours: () => range(0, hours),
    disabledMinutes: () => range(0, minutes),
  };
};
