import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, TitleIcon } from 'front-library';
import IconList from 'front-library/build/img/icon-list.svg';

import { TitleRemove } from '../../atoms/TitleRemove/TitleRemove';

import {
  HeaderContainer,
  InputsWrapper,
  KeyInputWrapper,
  TitleContainer,
  ValueInputWrapper,
} from './styled-components';

const Header = ({ header, index, onDelete, onUpdate }) => {
  const { t } = useTranslation();

  return (
    <HeaderContainer>
      <TitleContainer>
        <TitleIcon img={IconList} />
        {(0 === index && (
          <div>{`${t('callbacks:form.header')} ${index + 1}`}</div>
        )) || (
          <TitleRemove onClick={() => onDelete(header.id)}>
            {`${t('callbacks:form.header')} ${index + 1}`}
          </TitleRemove>
        )}
      </TitleContainer>

      <InputsWrapper>
        <KeyInputWrapper>
          <TextField
            label={t('callbacks:form.key')}
            onChange={(data) => onUpdate({ ...header, key: data })}
            value={header.key}
          />
        </KeyInputWrapper>
        <ValueInputWrapper>
          <TextField
            label={t('callbacks:form.value')}
            onChange={(data) => onUpdate({ ...header, value: data })}
            value={header.value}
          />
        </ValueInputWrapper>
      </InputsWrapper>
    </HeaderContainer>
  );
};

Header.propTypes = {
  header: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export { Header };
