import L from 'leaflet';
import { icons } from './icons';

import clusterAsset from 'front-library/build/img/cluster-asset.svg';
import clusterShippingOrder from 'front-library/build/img/cluster-shipping-order.svg';
import clusterSite from 'front-library/build/img/cluster-site.svg';
import { generatePath } from 'react-router-dom';
import { routes } from '../../../routes';

export function buildEmptyGeoJson() {
  return {
    type: 'FeatureCollection',
    features: [],
  };
}

export function mapSingleSiteToGeoJson(site) {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [site.coordinates.longitude, site.coordinates.latitude],
    },
    properties: {
      siteId: site.id,
      siteName: site.name,
    },
  };
}

export function mapSitesToGeoJson(sites) {
  return {
    type: 'FeatureCollection',
    features: sites ? sites.map(mapSingleSiteToGeoJson) : [],
  };
}

export function mapSingleAssetToGeoJson(asset) {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [
        asset.mag.last_accepted_coordinates.longitude,
        asset.mag.last_accepted_coordinates.latitude,
      ],
    },
    properties: {
      asset,
    },
  };
}

export function mapAssetsToGeoJson(assets) {
  return {
    type: 'FeatureCollection',
    features: assets ? assets.map(mapSingleAssetToGeoJson) : [],
  };
}

export function mapSingleShippingOrderToGeoJson(shippingOrder) {
  function shippingOrderToPoint(so) {
    if (so.degraded_mag_datas) {
      return [so.degraded_mag_datas.longitude, so.degraded_mag_datas.latitude];
    }

    const assetWithLastPosition = so.asset_group.assets.data.reduce(
      (previous, current) =>
        previous.mag.last_accepted_device_timestamp >
        current.mag.last_accepted_device_timestamp
          ? previous
          : current,
    );

    return assetWithLastPosition?.mag.last_accepted_latitude &&
      assetWithLastPosition?.mag.last_accepted_longitude
      ? [
          assetWithLastPosition.mag.last_accepted_longitude,
          assetWithLastPosition.mag.last_accepted_latitude,
        ]
      : null;
  }

  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: shippingOrderToPoint(shippingOrder),
    },
    properties: {
      shippingOrderId: shippingOrder.id,
      shippingOrderReference: shippingOrder.reference,
    },
  };
}

export function mapShippingOrdersToGeoJson(shippingOrders) {
  return {
    type: 'FeatureCollection',
    features: shippingOrders
      ? shippingOrders
          .map(mapSingleShippingOrderToGeoJson)
          .filter((p) => null !== p.geometry.coordinates)
      : [],
  };
}

export function geoJsonToSiteMarker(feature, latlng) {
  return L.marker(latlng, {
    icon: icons.site,
    pane: 'shadowPane',
  }).bindPopup(feature.properties.siteName, {
    offset: new L.Point(0, -10),
  });
}

export function geoJsonToAssetMarker(feature, latlng) {
  return L.marker(latlng, { icon: icons.asset, pane: 'shadowPane' }).bindPopup(
    `${feature.properties.asset.reference}<br />${feature.properties.asset.type.name}`,
    {
      offset: new L.Point(0, -25),
    },
  );
}

export function geoJsonToShippingOrderMarker(feature, latlng) {
  return L.marker(latlng, {
    icon: icons.order,
    pane: 'shadowPane',
  }).bindPopup(feature.properties.shippingOrderReference, {
    offset: new L.Point(0, -5),
  });
}

export function geoJsonToSiteEvent(feature, layer, navigate) {
  layer.on({
    mouseover: (e) => e.target.openPopup(),
    mouseout: (e) => e.target.closePopup(),
    click: () =>
      navigate(
        generatePath(routes.siteMap.path, { id: feature.properties.siteId }),
      ),
  });
}

export function geoJsonToAssetEvent(feature, layer, navigate) {
  layer.on({
    mouseover: (e) => e.target.openPopup(),
    mouseout: (e) => e.target.closePopup(),
    click: () =>
      navigate(
        generatePath(routes.assetMap.path, { id: feature.properties.asset.id }),
      ),
  });
}

export function geoJsonToShippingOrderEvent(feature, layer, navigate) {
  layer.on({
    mouseover: (e) => e.target.openPopup(),
    mouseout: (e) => e.target.closePopup(),
    click: () =>
      navigate(
        `${generatePath(routes.shippingOrders.path)}?reference=${
          feature.properties.shippingOrderReference
        }`,
      ),
  });
}

export function createSiteClusterIconFunc(cluster) {
  return L.divIcon({
    html: `
            <div class="cluster">
                <img src="${clusterSite}" alt="cluster site" />
                <div class="cluster-text">${cluster.getChildCount()}</div>
            </div>
        `,
    className: 'siteCluster',
    iconSize: L.point(32, 32),
  });
}

export function createAssetClusterIconFunc(cluster) {
  return L.divIcon({
    html: `
            <div class="cluster">
                <img src="${clusterAsset}" alt="cluster asset" />
                <div class="cluster-text">${cluster.getChildCount()}</div>
            </div>
        `,
    className: 'assetCluster',
    iconSize: L.point(32, 32),
  });
}

export function createShippingOrderClusterIconFunc(cluster) {
  return L.divIcon({
    html: `
            <div class="cluster">
                <img src="${clusterShippingOrder}" alt="cluster shipping order" />
                <div class="cluster-text">${cluster.getChildCount()}</div>
            </div>
        `,
    className: 'shippingOrderCluster',
    iconSize: L.point(32, 32),
  });
}

function shippingOrdersBounds(shippingOrders) {
  const result = [];

  for (const so of shippingOrders) {
    if (so.degraded_mag_datas) {
      result.push([
        so.degraded_mag_datas.latitude,
        so.degraded_mag_datas.longitude,
      ]);
      continue;
    }

    const magWithLastPosition = so.asset_group.assets.data.reduce(
      (previous, current) =>
        previous.mag.last_accepted_device_timestamp >
        current.mag.last_accepted_device_timestamp
          ? previous
          : current,
    );

    result.push(
      magWithLastPosition?.last_accepted_latitude &&
        magWithLastPosition?.last_accepted_longitude
        ? [
            magWithLastPosition.last_accepted_longitude,
            magWithLastPosition.last_accepted_latitude,
          ]
        : null,
    );
  }

  return result.filter((r) => null !== r);
}

export function getBounds(sites, assets, shippingOrders) {
  let result = [];

  if (sites?.length) {
    result = [
      ...result,
      ...sites
        .filter((s) => s.coordinates.latitude && s.coordinates.longitude)
        .map((s) => [s.coordinates.latitude, s.coordinates.longitude]),
    ];
  }

  if (assets?.length) {
    result = [
      ...result,
      ...assets
        .filter((a) => a.mag)
        .map((a) => [
          a.mag.last_accepted_coordinates.latitude,
          a.mag.last_accepted_coordinates.longitude,
        ]),
    ];
  }

  if (shippingOrders?.length) {
    result = [...result, ...shippingOrdersBounds(shippingOrders)];
  }

  return result;
}

export function mapGeofence(geofence, name = null) {
  const mapToCoordinates = (geoItem) =>
    geoItem?.coordinates ?? {
      latitude: geoItem.latitude,
      longitude: geoItem.longitude,
    };

  if (
    'GeofenceCircle' === geofence?.__typename ||
    'circle' === geofence?.type
  ) {
    const coordinates = mapToCoordinates(geofence);

    return {
      type: 'circle',
      id: geofence.id,
      center: [coordinates.latitude, coordinates.longitude],
      radius: geofence.radius,
      name,
    };
  }

  const getCoordinates = (point) => {
    const coordinates = mapToCoordinates(point);

    return [coordinates.latitude, coordinates.longitude];
  };

  return {
    type: geofence.is_rectangle ? 'rectangle' : 'polygon',
    bounds: geofence.points.map(getCoordinates),
    id: geofence.id,
    name,
  };
}
