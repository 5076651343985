import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from 'front-library';

import { TitleRemove } from '../../../../atoms/TitleRemove/TitleRemove';

import { Autocomplete } from '../../../../molecules/Autocomplete/Autocomplete';
import { GET_ASSETS_WITHOUT_ASSET_GROUP } from '../graphql';
import { ItemContainer } from './styled-components';

const Order = ({
  index,
  reference,
  asset,
  onChange,
  onRemove,
  usedAssetIds,
}) => {
  const { t } = useTranslation();

  return (
    <ItemContainer>
      {(0 !== index || (0 === index && '' !== reference)) && (
        <TitleRemove onClick={() => onRemove()}>
          {`${t('shippingOrders:modal.steps.orderTitle')} ${index + 1}`}
        </TitleRemove>
      )}

      {/* Reference */}
      <TextField
        label={t('shippingOrders:modal.steps.order')}
        onChange={(data) => onChange({ reference: data, asset })}
        required={true}
        placeholder={t('shippingOrders:modal.steps.orderPlaceholder')}
        value={reference}
      />

      {/* Asset */}
      <Autocomplete
        disabled={!reference}
        label={t('shippingOrders:modal.steps.associateAsset')}
        onChange={(data) =>
          onChange({
            reference,
            asset: data,
          })
        }
        query={GET_ASSETS_WITHOUT_ASSET_GROUP}
        queryVars={{ alreadyUsed: usedAssetIds }}
        value={asset}
      />
    </ItemContainer>
  );
};

Order.propTypes = {
  index: PropTypes.number.isRequired,
  reference: PropTypes.string,
  asset: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  usedAssetIds: PropTypes.array.isRequired,
};

export { Order };
