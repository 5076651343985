import styled from 'styled-components';

import { Form } from '../../../atoms/Form/Form';

export const OutsideContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 260px;
    height: 100%;
    background-color: #ffffff;
    z-index: 2;
    box-shadow: -5px 0px 30px 0px rgba(0,0,0,0.15);
    padding: 15px;
    display: ${({ show }) => (show ? 'block' : 'none')};

    @keyframes slideInFromRight {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(0);
        }
    }

    animation: 0.4s ease-out 0s 1 slideInFromRight;
`;

export const StyledForm = styled(Form)`
    font-size: 13px;
    padding-bottom: 20px;
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    p {
        flex-grow: 1;
        font-size: 20px;
        font-family: 'Muli semi';
    }
`;

export const Close = styled.button`
    border: none;
    z-index: 10;

    img {
        height: 15px;
        width: 15px;
        transition: all ease 0.2s;
    }
    
    :hover img {
        background-color: #b9e6eb;
        border-radius: 50%;
        padding: 2px;
    }
`;
