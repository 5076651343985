import { gql } from '@apollo/client';

const GET_TOUR_MODEL = gql`
    query GetTourModel($id: ID) {
        tour_model(id: $id) {
            id
            name
            reference
            departure_site {
                id
                name
            }
            tour_model_points {
                id
                tour_order
                duration
                site {
                    id
                    name
                }
            }
        }
    }
`;

const GET_TOUR_MODELS = gql`
    query TourModels($first: Int, $page: Int, $search: Mixed, $orderBy: [QueryTourModelsOrderByOrderByClause!]) {
        tour_models(first: $first, page: $page, orderBy: $orderBy, where: {
            OR: [
                {
                    column: "id"
                    operator: EQ
                    value: $search
                }
                {
                    column: "name"
                    operator: LIKE
                    value: $search
                }
                {
                    column: "reference"
                    operator: LIKE
                    value: $search
                }
            ]
        }) {
            paginatorInfo {
                total
                currentPage
                lastPage
                perPage
            }
            data {
                id
                name
                reference
            }
        }
    }
`;

const CREATE_TOUR_MODEL = gql`
    mutation CreateTourModel(
        $name: String
        $reference: String
        $departure_site: UpdateRelation
        $tour_model_points: UpdateTourModelPoint
    ) {
        createTourModel(
            input: {
                name: $name
                reference: $reference
                departure_site: $departure_site
                tour_model_points: $tour_model_points
            }
        ) {
            id
        }
    }
`;

const UPDATE_TOUR_MODEL = gql`
    mutation UpdateTourModel(
        $id: ID!
        $name: String
        $reference: String
        $departure_site: UpdateRelation
        $tour_model_points: UpdateTourModelPoint
    ) {
        updateTourModel(
            input: {
                id: $id
                name: $name
                reference: $reference
                departure_site: $departure_site
                tour_model_points: $tour_model_points
            }
        ) {
            id
        }
    }
`;

const DELETE_TOUR_MODEL = gql`
    mutation DeleteTourModel($id: ID!) {
        deleteTourModel(id: $id) {
            id
        }
    }
`;

export {
  GET_TOUR_MODEL,
  GET_TOUR_MODELS,
  CREATE_TOUR_MODEL,
  UPDATE_TOUR_MODEL,
  DELETE_TOUR_MODEL,
};
