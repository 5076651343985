import styled from 'styled-components';

export const Title = styled.h3`
    color: #002b2b;
`;

export const HelpText = styled.p`
    margin-top: ${({ $marginTop }) => ($marginTop ? $marginTop : '0')};
    font-size: 10px;
`;

export const Sms = styled.span`
    margin-left: 5px;
    font-weight: bold;
    color: ${({ $color }) => $color};
`;

export const Logo = styled.img`
    width: 100%;
`;
