import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import GreenClockIcon from 'front-library/build/img/clock-green.svg';
import RedClockIcon from 'front-library/build/img/clock.png';
import { Hour, NextEstimatedStopContainer } from './styled-components';

const COLORS = {
  red: '#C81912',
  green: '#76BD22',
};

const NextEstimatedStop = ({ nextEstimatedStop, inProgressShippingOrder }) => {
  const isLate = useMemo(() => {
    if (!inProgressShippingOrder) {
      return false;
    }

    return (
      inProgressShippingOrder.human_eta &&
      moment(inProgressShippingOrder.human_eta) &&
      moment(inProgressShippingOrder.human_eta).diff(
        inProgressShippingOrder.arrival_timestamp,
      ) > 0
    );
  }, [inProgressShippingOrder]);

  return nextEstimatedStop ? (
    <NextEstimatedStopContainer>
      {isLate && <img src={RedClockIcon} alt="Late clock icon" />}
      {!isLate && <img src={GreenClockIcon} alt="On time clock icon" />}
      <p>
        {nextEstimatedStop.split(' ')[0]}
        <Hour color={isLate ? COLORS.red : COLORS.green}>
          {nextEstimatedStop.split(' ')[1]}
        </Hour>
      </p>
    </NextEstimatedStopContainer>
  ) : null;
};

NextEstimatedStop.propTypes = {
  nextEstimatedStop: PropTypes.string,
  inProgressShippingOrder: PropTypes.object,
};

export { NextEstimatedStop };
