import { useMutation } from '@apollo/client';
import FileSaver from 'file-saver';
import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { Route, Routes, generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { BtnInATab, Loading } from 'front-library';
import IconAddGreen from 'front-library/build/img/icon-add-green.svg';

import { useQueryGraphQl } from '../../../../hooks/useQueryGraphQl';
import { routes } from '../../../../routes';
import { ListPage } from '../../../templates/ListPage';
import { MagDataList } from '../../MagData/List/MagDataList';
import { Import } from '../Import';
import { Mag } from '../Mag';
import { DELETE_MAG, GET_MAGS } from '../graphql';
import { BtnInATabContainer } from '../styled-components';
import { DataGrid } from './DataGrid';

const MagList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const {
    data: magData,
    setPagination,
    setFirst,
    setVariable,
    setOrderBy,
    setOrderByRelation,
    search,
    loading: magLoading,
    refetch,
  } = useQueryGraphQl(GET_MAGS, { first: 25, search: '%' });
  const [deleteMag, { loading: deleteLoading }] = useMutation(DELETE_MAG);

  const handleDeleteConfirm = async (id, name) => {
    try {
      await deleteMag({ variables: { id } });

      toast.success(t('mags:deleted.success', { name }));

      await refetch();
    } catch (_e) {
      toast.error(t('mags:deleted.error', { name }));
    }
  };

  const onDeleteHandler = (mag) =>
    confirmAlert({
      title: t('common:deletion'),
      message: t('mags:askDelete', { name: mag.mag_id }),
      buttons: [
        {
          label: t('common:yes'),
          onClick: () => handleDeleteConfirm(mag.id, mag.mag_id),
        },
        {
          label: t('common:no'),
        },
      ],
    });

  const downloadFile = (url) => {
    setLoading(true);

    fetch(url)
      .then(async (res) => {
        const fileName = res.headers
          .get('Content-Disposition')
          .match(/filename="([^"]+)"/);
        const blob = await res.blob();
        const fileUrl = URL.createObjectURL(blob);
        FileSaver(fileUrl, fileName[1]);
      })
      .catch(() => toast.error(t('common:exportError')))
      .finally(() => setLoading(false));
  };

  const handleAdd = () => navigate(routes.administrationMagCreation.path);

  return (
    <>
      <Routes>
        <Route
          path={routes.administrationMagCreation.partialPath}
          element={<Mag refetch={refetch} />}
        />
        <Route
          path={routes.administrationMagEdition.partialPath}
          element={<Mag refetch={refetch} />}
        />
        <Route
          path={routes.administrationMagImport.partialPath}
          element={<Import refetch={refetch} />}
        />
        <Route
          path={routes.administrationMagData.partialPath}
          element={<MagDataList downloadFile={downloadFile} />}
        />
      </Routes>

      {(loading || magLoading || deleteLoading) && <Loading />}

      <ListPage
        title={t('menu:magManagement')}
        paginatorInfo={magData?.all_mags.paginatorInfo}
        onPaginate={setPagination}
        onRowsPerPageChange={setFirst}
        onSearch={(search) => setVariable('search', `%${search.trim()}%`)}
        onAdd={handleAdd}
        pathExportXls={`${generatePath(
          routes.magExportXLSX.path,
        )}?search=${encodeURIComponent(search)}`}
        pathExportCsv={`${generatePath(
          routes.magExportCSV.path,
        )}?search=${encodeURIComponent(search)}`}
        downloadFile={downloadFile}
        onImport={() => navigate(routes.administrationMagImport.path)}
      >
        <DataGrid
          mags={magData?.all_mags.data}
          onDelete={onDeleteHandler}
          onOrderBy={setOrderBy}
          onOrderByRelation={setOrderByRelation}
        />

        <BtnInATabContainer>
          <BtnInATab
            text={t('mags:add')}
            onClick={handleAdd}
            imgSrc={IconAddGreen}
          />
        </BtnInATabContainer>
      </ListPage>
    </>
  );
};

export { MagList };
