import { useMutation } from '@apollo/client';
import _ from 'lodash';
import React, { useContext, useMemo, useReducer } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import IconAddGreen from 'front-library/build/img/icon-add-green.svg';
import noFlux from 'front-library/build/img/illustration-empty-board-flux.svg';

import { BtnInATab } from 'front-library';

import {
  CREATE_CONFIGURATION,
  CREATE_FLUX,
  DELETE_CONFIGURATION,
  DELETE_FLUX,
  GET_FLUX,
  UPDATE_CONFIGURATION,
  UPDATE_FLUX,
} from './graphql';
import {
  mapFluxConfigFormDataToGraphql,
  mapGraphqlToFluxConfigFormData,
} from './utils';

import { MeContext } from '../../../contexts/MeContext';
import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import {
  ADD_CONFIG_TO_FLUX_ACTION,
  CLOSE_CONFIG_MODAL_ACTION,
  EDIT_CONFIG_ACTION,
  SET_CONFIG_FORM_DATA_ACTION,
  SET_IS_LOADING_MUTATION_ACTION,
  SET_IS_NAME_MODAL_OPEN_ACTION,
} from '../../../stores/flux/actions';
import { FLUX_INITIAL_STATE, fluxReducer } from '../../../stores/flux/reducer';
import { Loading } from '../../molecules/Loading/Loading';
import { FluxConfigModal } from '../../organisms/Flux/FluxConfigModal';
import { FluxList } from '../../organisms/Flux/FluxList';
import { FluxModal } from '../../organisms/Flux/FluxModal';
import { ListPage } from '../../templates/ListPage';

const NoFluxDiv = styled.div`
  color: #7B7B7B;
  text-align: center;
  opacity: 0.9;
  padding-top: 35vh;
  
  > img {
    width: 400px;
  }
`;

const FluxManagement = () => {
  const { t } = useTranslation();
  const { me: user } = useContext(MeContext);

  const [state, dispatch] = useReducer(fluxReducer, FLUX_INITIAL_STATE);
  const {
    isLoadingMutation,
    isNameModalOpen,
    isConfigModalOpen,
    fluxConfigFormState,
    selectedFlux,
  } = state;

  const {
    loading: fluxLoading,
    data: fluxData,
    search,
    refetch,
    setFirst,
    setPagination,
    setOrderBy,
    setVariable,
  } = useQueryGraphQl(GET_FLUX, { search: '%%', first: 25 });

  const [createFluxMutation] = useMutation(CREATE_FLUX);
  const [updateFluxMutation] = useMutation(UPDATE_FLUX);
  const [deleteFluxMutation] = useMutation(DELETE_FLUX);
  const [createConfigMutation] = useMutation(CREATE_CONFIGURATION);
  const [updateConfigMutation] = useMutation(UPDATE_CONFIGURATION);
  const [deleteConfigMutation] = useMutation(DELETE_CONFIGURATION);

  const offSiteId = useMemo(() => user.company.off_site.id, [user]);

  const handleFluxSave = async (name, onError) => {
    dispatch({
      type: SET_IS_LOADING_MUTATION_ACTION,
      payload: { isLoading: true },
    });

    try {
      if (selectedFlux) {
        await updateFluxMutation({ variables: { id: selectedFlux.id, name } });
      } else {
        await createFluxMutation({ variables: { name } });
      }

      await refetch();

      toast.success(
        selectedFlux ? t('flux:updated.success') : t('flux:created.success'),
      );

      dispatch({
        type: SET_IS_NAME_MODAL_OPEN_ACTION,
        payload: { isOpen: false },
      });
    } catch (e) {
      onError(e?.graphQLErrors[0]?.extensions?.validation || {});
      toast.error(
        selectedFlux ? t('flux:updated.error') : t('flux:created.error'),
      );
    }

    dispatch({
      type: SET_IS_LOADING_MUTATION_ACTION,
      payload: { isLoading: false },
    });
  };

  const handleConfigSave = async () => {
    dispatch({
      type: SET_IS_LOADING_MUTATION_ACTION,
      payload: { isLoading: true },
    });

    const isUpdate = null !== fluxConfigFormState.id;

    try {
      if (isUpdate) {
        await updateConfigMutation({
          variables: mapFluxConfigFormDataToGraphql(
            fluxConfigFormState,
            offSiteId,
          ),
        });
      } else {
        await createConfigMutation({
          variables: mapFluxConfigFormDataToGraphql(
            fluxConfigFormState,
            offSiteId,
          ),
        });
      }

      await refetch();

      toast.success(
        t(
          isUpdate
            ? 'flux:updated.configSuccess'
            : 'flux:created.configSuccess',
        ),
      );

      dispatch({ type: CLOSE_CONFIG_MODAL_ACTION });
    } catch (e) {
      toast.error(
        t(isUpdate ? 'flux:updated.configError' : 'flux:created.configError'),
      );

      dispatch({
        type: SET_CONFIG_FORM_DATA_ACTION,
        payload: {
          formData: {
            ..._.cloneDeep(fluxConfigFormState),
            errors: e?.graphQLErrors[0]?.extensions?.validation || {},
          },
        },
      });
    }

    dispatch({
      type: SET_IS_LOADING_MUTATION_ACTION,
      payload: { isLoading: false },
    });
  };

  const handleFluxEdit = (flux) =>
    dispatch({
      type: SET_IS_NAME_MODAL_OPEN_ACTION,
      payload: {
        isOpen: true,
        selectedFlux: flux,
      },
    });

  const handleFluxDelete = (flux) =>
    confirmAlert({
      title: t('common:deletion'),
      message: t('flux:askDelete'),
      buttons: [
        {
          label: t('common:yes'),
          onClick: () => handleFluxDeleteConfirm(flux),
        },
        {
          label: t('common:no'),
        },
      ],
    });

  const handleFluxDeleteConfirm = async (flux) => {
    dispatch({
      type: SET_IS_LOADING_MUTATION_ACTION,
      payload: { isLoading: true },
    });

    try {
      await deleteFluxMutation({ variables: { id: flux.id } });
      await refetch();

      toast.success(t('flux:deleted.success'));
    } catch (_e) {
      toast.error(t('flux:deleted.error'));
    }

    dispatch({
      type: SET_IS_LOADING_MUTATION_ACTION,
      payload: { isLoading: false },
    });
  };

  const handleConfigAdd = (flux) =>
    dispatch({
      type: ADD_CONFIG_TO_FLUX_ACTION,
      payload: {
        fluxId: flux.id,
      },
    });

  const handleConfigEdit = (config) =>
    dispatch({
      type: EDIT_CONFIG_ACTION,
      payload: {
        fluxConfig: mapGraphqlToFluxConfigFormData(config),
      },
    });

  const handleConfigDelete = (config) =>
    confirmAlert({
      title: t('common:deletion'),
      message: t('flux:askDeleteConfig'),
      buttons: [
        {
          label: t('common:yes'),
          onClick: () => handleConfigDeleteConfirm(config),
        },
        {
          label: t('common:no'),
        },
      ],
    });

  const handleConfigDeleteConfirm = async (config) => {
    dispatch({
      type: SET_IS_LOADING_MUTATION_ACTION,
      payload: { isLoading: true },
    });

    try {
      await deleteConfigMutation({ variables: { id: config.id } });
      await refetch();

      toast.success(t('flux:deleted.configSuccess'));
    } catch (_e) {
      toast.error(t('flux:deleted.configError'));
    }

    dispatch({
      type: SET_IS_LOADING_MUTATION_ACTION,
      payload: { isLoading: false },
    });
  };

  return (
    <ListPage
      title={t('menu:fluxManagement')}
      onAdd={() =>
        dispatch({
          type: SET_IS_NAME_MODAL_OPEN_ACTION,
          payload: { isOpen: true },
        })
      }
      onSearch={(search) => setVariable('search', `%${search.trim()}%`)}
      onPaginate={setPagination}
      onRowsPerPageChange={setFirst}
      paginatorInfo={fluxData?.flux?.paginatorInfo}
    >
      {fluxData?.flux?.data?.length > 0 && (
        <FluxList
          data={fluxData?.flux?.data}
          offSiteId={offSiteId}
          onFluxEdit={handleFluxEdit}
          onFluxDelete={handleFluxDelete}
          onConfigAdd={handleConfigAdd}
          onConfigEdit={handleConfigEdit}
          onConfigDelete={handleConfigDelete}
          onOrderBy={setOrderBy}
        />
      )}
      {!fluxData?.flux?.data?.length && (
        <NoFlux fluxData={fluxData} search={search} />
      )}
      {isNameModalOpen && (
        <FluxModal
          selectedFlux={selectedFlux}
          onClose={() =>
            dispatch({
              type: SET_IS_NAME_MODAL_OPEN_ACTION,
              payload: { isOpen: false },
            })
          }
          onSave={handleFluxSave}
        />
      )}
      {isConfigModalOpen && (
        <FluxConfigModal
          onClose={() => dispatch({ type: CLOSE_CONFIG_MODAL_ACTION })}
          onSave={handleConfigSave}
          dispatch={dispatch}
          formData={fluxConfigFormState}
        />
      )}
      {(fluxLoading || isLoadingMutation) && <Loading />}
      {fluxData?.flux?.data?.length > 0 && (
        <div className="btnInATabContainer">
          <BtnInATab
            text={t('flux:addFlux')}
            onClick={() =>
              dispatch({
                type: SET_IS_NAME_MODAL_OPEN_ACTION,
                payload: { isOpen: true },
              })
            }
            imgSrc={IconAddGreen}
          />
        </div>
      )}
    </ListPage>
  );
};

const NoFlux = ({ fluxData, search }) => {
  const { t } = useTranslation();

  return (
    <NoFluxDiv>
      {!fluxData?.flux?.data && t('flux:fluxLoading')}
      {fluxData?.flux?.data?.length === 0 &&
        '%%' !== search &&
        t('flux:noFluxFound')}
      {fluxData?.flux?.data?.length === 0 &&
        '%%' === search &&
        t('flux:noFluxCreated')}
      <br />
      <img src={noFlux} alt="no flux" />
    </NoFluxDiv>
  );
};

export { FluxManagement };
