import * as Sentry from '@sentry/react';
import { stringify } from 'qs';

/**
 * @typedef {object} ResponseContent
 * @property {object} content - The Http call response content
 * @property {object} response - The Http call response
 */

/**
 * Create http response content for collection endpoints
 *
 * @param {object} content
 * @param {object} response
 * @returns {ResponseContent}
 */
function createCollectionResponseContent(content, response) {
  return { content: content.data, response, total: content.meta.total };
}

export async function getById(url, id, { signal }) {
  let response;

  try {
    response = await fetch(`/api/${url}/${id}`, {
      signal,
      headers: {
        Accept: 'application/json',
      },
      credentials: 'same-origin',
    });
  } catch (error) {
    if (error?.name !== 'AbortError') {
      Sentry.captureException(error);
    } else {
      console.error(error);
    }
  }

  if (!response?.ok) {
    return { content: null, response };
  }

  const content = await response.json();
  return { content, response };
}

export async function get(url, params, { signal }) {
  let response;

  try {
    const queryString = stringify(params);
    response = await fetch(`/api/${url}?${queryString}`, {
      signal,
      headers: {
        Accept: 'application/json',
      },
      credentials: 'same-origin',
    });
  } catch (error) {
    if (error?.name !== 'AbortError') {
      Sentry.captureException(error);
    } else {
      console.error(error);
    }
  }

  if (!response?.ok) {
    return createCollectionResponseContent({ data: [], meta: { total: 0 } }, [
      { message: 'Fail to perform request. Server error.' },
    ]);
  }

  const content = await response.json();
  return createCollectionResponseContent(content, response);
}

export async function postAjax(url, body, { signal }) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const token = document
    .querySelector('meta[name="csrf-token"]')
    ?.getAttribute('content');

  if (token) {
    headers['X-CSRF-TOKEN'] = token;
  }

  let response;
  try {
    response = await fetch(`/${url}`, {
      method: 'POST',
      signal,
      headers,
      credentials: 'same-origin',
      body: JSON.stringify(body),
    });
  } catch (error) {
    if (error?.name !== 'AbortError') {
      Sentry.captureException(error);
    } else {
      console.error(error);
    }
  }

  if (!response?.ok) {
    return { content: null, response };
  }

  const content = await response.json();
  return { content, response };
}
