import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import previous from 'front-library/build/img/icon-return.svg';

import { AuthPage } from '../../templates/AuthPage/AuthPage';

import { Button, TextField } from 'front-library';
import { fetchSsoTenantRedirectUri } from '../../../http/authRepository';
import { routes } from '../../../routes';
import { Form, GraySeparator, Links, Titles } from './styled-components';

const SsoLogin = () => {
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    i18n.changeLanguage('en');
  }, [i18n.changeLanguage]);

  const redirectTenant = async () => {
    const controller = new AbortController();
    const { content, response } = await fetchSsoTenantRedirectUri(
      { email },
      controller,
    );

    const redirectUrl = content?.redirect_url;
    if (!response.ok || !redirectUrl) {
      setErrorMessage(t('error:unknownSsoTenant'));
      return;
    }

    window.location.href = redirectUrl;
  };

  return (
    <AuthPage>
      <Titles>
        <h1>{t('auth:welcome')}</h1>
        <h2>{t('auth:ssoLogin')}</h2>
      </Titles>
      <Form onSubmit={redirectTenant}>
        <TextField
          errorText={errorMessage}
          label={t('auth:email')}
          onChange={(value) => setEmail(value)}
          required={true}
          type="email"
          value={email}
        />
        <Button text={t('auth:ssoContinue')} type="submit" />
      </Form>
      <GraySeparator />
      <Links>
        <Link to={generatePath(routes.login.path)}>
          <Button
            bold={true}
            img={previous}
            text="Return to password login"
            variant="borderlessGreen"
          />
        </Link>
      </Links>
    </AuthPage>
  );
};

export { SsoLogin };
