import PropTypes from 'prop-types';
import React from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Loading } from 'front-library';

import { prepareGraphqlSearch } from '../../../../helpers';
import { useQueryGraphQl } from '../../../../hooks/useQueryGraphQl';
import { routes } from '../../../../routes';
import { Modal } from '../../../molecules/Modal/Modal';
import { ListPage } from '../../../templates/ListPage';
import { GET_MAG_DATAS } from '../graphql';
import { DataGrid } from './DataGrid';

const MagDataList = ({ downloadFile }) => {
  const { id = null } = useParams();
  const navigate = useNavigate();

  const {
    data,
    setPagination,
    setFirst,
    setVariable,
    setOrderBy,
    setOrderByRelation,
    search,
    loading,
  } = useQueryGraphQl(
    GET_MAG_DATAS,
    {
      mag_id: id,
      first: 25,
      search: '%',
      orderBy: [{ column: 'DEVICE_TIMESTAMP', order: 'DESC' }],
    },
    {
      where: { column: 'mag_id', operator: 'EQ', value: id },
    },
  );

  return (
    <Modal
      onClose={() => navigate(generatePath(routes.administrationMag.path))}
      size="big"
    >
      {loading && <Loading />}

      <ListPage
        paginatorInfo={data?.all_mag_datas.paginatorInfo}
        onPaginate={setPagination}
        onRowsPerPageChange={setFirst}
        onSearch={(search) =>
          setVariable('search', prepareGraphqlSearch(search))
        }
        pathExportXls={`${generatePath(routes.magDataExportXLSX.path, {
          mag_id: id,
        })}?search=${encodeURIComponent(search)}`}
        pathExportCsv={`${generatePath(routes.magDataExportCSV.path, {
          mag_id: id,
        })}?search=${encodeURIComponent(search)}`}
        downloadFile={downloadFile}
      >
        <DataGrid
          magDatas={data?.all_mag_datas.data}
          onOrderBy={setOrderBy}
          onOrderByRelation={setOrderByRelation}
        />
      </ListPage>
    </Modal>
  );
};

MagDataList.propTypes = {
  downloadFile: PropTypes.func,
};

export { MagDataList };
