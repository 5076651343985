import { useQuery } from '@apollo/client';
import { useCallback, useMemo, useReducer } from 'react';

import * as Actions from '../stores/queryGraphQl/actions';

import { queryGraphQlReducer } from '../stores/queryGraphQl/reducer';

const useQueryGraphQl = (
  query,
  defaultVariables = {},
  defaultFilters = {},
  skip = false,
  queryOptions = {},
) => {
  const [state, dispatch] = useReducer(queryGraphQlReducer, {
    filters: { ...defaultFilters },
    variables: { ...defaultVariables },
  });

  const variablesAndFilters = useMemo(
    () => ({
      variables: state.variables,
      filters: state.filters,
    }),
    [state],
  );

  const setVariables = useCallback(
    (variables) =>
      dispatch({
        type: Actions.SET_VARIABLES_ACTION,
        payload: variables,
      }),
    [],
  );

  const setVariable = useCallback(
    (label, value) =>
      dispatch({
        type: Actions.SET_VARIABLE_ACTION,
        payload: {
          label,
          value,
        },
      }),
    [],
  );

  const setFilters = useCallback(
    (filters) =>
      dispatch({
        type: Actions.SET_FILTERS_ACTION,
        payload: filters,
      }),
    [],
  );

  const setFilter = useCallback(
    (column, operator, value) =>
      dispatch({
        type: Actions.SET_FILTER_ACTION,
        payload: {
          column,
          operator,
          value,
        },
      }),
    [],
  );

  const setVariablesAndFilters = useCallback(
    ({ variables, filters }) =>
      dispatch({
        type: Actions.SET_VARIABLES_AND_FILTERS_ACTION,
        payload: {
          variables,
          filters,
        },
      }),
    [],
  );

  const getWhere = useCallback(() => {
    const { filters } = state;
    const where = [];

    for (const i in filters) {
      const filter = { ...filters[i] };

      if (
        filter.operator !== 'BETWEEN' ||
        filter.value.filter((v) => v).length === 2
      ) {
        where.push(filter);
      }
    }

    return where;
  }, [state]);

  const setPagination = useCallback(
    (page) => setVariable('page', page),
    [setVariable],
  );

  const setFirst = useCallback(
    (first) =>
      dispatch({
        type: Actions.SET_FIRST_ACTION,
        payload: first,
      }),
    [],
  );

  const setOrderBy = useCallback(
    (column, order = 'ASC') => setVariable('orderBy', [{ column, order }]),
    [setVariable],
  );

  const setOrderByRelation = useCallback(
    (relation, aggregate, column, order = 'ASC') => {
      const value = column
        ? { [relation]: { aggregate, column }, order }
        : { [relation]: { aggregate }, order };
      setVariable('orderBy', [value]);
    },
    [setVariable],
  );

  const setSearch = useCallback(
    (value) => setVariable('search', value),
    [setVariable],
  );

  // call query graphql
  const { loading, error, data, refetch } = useQuery(query, {
    variables: {
      ...state.variables,
      where: {
        AND: getWhere(),
      },
    },
    notifyOnNetworkStatusChange: true,
    skip,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    ...queryOptions,
  });

  return {
    // Getter
    data,
    loading,
    error,
    filters: state.filters,
    search: state.variables.search,
    orderBY: state.variables.orderBY,
    variables: state.variables,
    variablesAndFilters,

    //setter
    setVariable,
    setVariables,
    setFilter,
    setFilters,
    setVariablesAndFilters,
    setPagination,
    setFirst,
    setOrderBy,
    setOrderByRelation,
    setSearch,

    // refresh data
    refetch,
  };
};

export { useQueryGraphQl };
