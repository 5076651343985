import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, TitleIcon } from 'front-library';
import IconCheckPoint from 'front-library/build/img/icon-checkpoint-orange.svg';

import { TitleRemove } from '../../atoms/TitleRemove/TitleRemove';
import { Autocomplete } from '../../molecules/Autocomplete/Autocomplete';
import { GET_SITES_WITHOUT_OFFSITE } from '../../molecules/Autocomplete/graphql';

import { Container, TitleContainer } from './styled-components';

const TourModelPointForm = ({ formValues, index, setFormValues }) => {
  const { t } = useTranslation();

  const current = formValues.tourModelPoints[index];

  const updatePoint = (newData) => {
    const points = formValues.tourModelPoints;
    points[index] = {
      ...points[index],
      ...newData,
    };

    setFormValues({ ...formValues, tourModelPoints: points });
  };

  const deletePoint = () => {
    const points = [...formValues.tourModelPoints];
    points.splice(index, 1);

    setFormValues({ ...formValues, tourModelPoints: points });
  };

  return (
    <Container>
      <TitleContainer>
        <TitleIcon img={IconCheckPoint} />
        {(0 === index && (
          <div>{`${t('tourModels:form.checkPoint')} ${index + 1}`}</div>
        )) || (
          <TitleRemove onClick={deletePoint}>
            {`${t('tourModels:form.checkPoint')} ${index + 1}`}
          </TitleRemove>
        )}
      </TitleContainer>

      {/* Site */}
      <Autocomplete
        label={t('tourModels:form.site')}
        onChange={(site) => updatePoint({ site })}
        query={GET_SITES_WITHOUT_OFFSITE}
        required={true}
        value={current.site}
      />

      {/* Duration */}
      <TextField
        type="time"
        label={t('tourModels:form.duration')}
        onChange={(duration) => updatePoint({ duration })}
        value={current.duration}
        timeInputProps={{
          daysLabel: t('common:timeInput.d'),
          hoursLabel: t('common:timeInput.h'),
          minutesLabel: t('common:timeInput.m'),
        }}
        required={true}
      />
    </Container>
  );
};

TourModelPointForm.propTypes = {
  formValues: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setFormValues: PropTypes.func.isRequired,
};

export { TourModelPointForm };
