import humanizeDuration from 'humanize-duration';
import moment from 'moment';

export function getDiff(start, end, lang, withSign = false) {
  if (!start || !end) {
    return '-';
  }

  const diff = moment(start).diff(moment(end));
  const humanized = humanizeDuration(Math.abs(diff), {
    units: ['y', 'mo', 'd', 'h', 'm'],
    language: lang,
    largest: 2,
    maxDecimalPoints: 0,
  });

  if (!withSign) {
    return humanized;
  }

  if (diff > 0) {
    return `+ ${humanized}`;
  }

  if (diff < 0) {
    return `- ${humanized}`;
  }

  return humanized;
}
