const { gql } = require('@apollo/client');

const GET_USER_ALERT_BY_ID = gql`
    query GetUser($id: ID)
    {
        user(id: $id) {
            id
            surname
            name
            username
            phone
            alerts(first: 100000) {
                data {
                    id
                    pivot {
                        parameter
                        type
                        is_allowed
                        is_active
                    }
                }
            }
            automated_actions(first: 100000) {
                data {
                    id
                    pivot {
                        type
                        is_allowed
                        is_active
                    }
                }
            }
            company {
                enable_temperature
                enable_shipping_orders
                enable_shock
                enable_automated_actions
                is_sms_enabled
                likely_deviated_max
            }
            roles {
                name
            }
        }
    }
`;

const GET_USER_ALERT = gql`
    {
        me {
            id
            phone
            alerts(first: 100000) {
                data {
                    id
                    pivot {
                        parameter
                        type
                        is_allowed
                        is_active
                    }
                }
            }
            automated_actions(first: 100000) {
                data {
                    id
                    pivot {
                        type
                        is_allowed
                        is_active
                    }
                }
            }
            company {
                enable_temperature
                enable_shipping_orders
                enable_shock
                enable_automated_actions
                is_sms_enabled
                likely_deviated_max
            }
            roles {
                name
            }
        }
    }
`;

const UPDATE_USER_ALERT = gql`
    mutation UpdateUserAlert($id: ID!, $alerts: [ConnectUserAlert!]) {
        updateUserAlert(input: {
            id: $id
            alerts: {sync: $alerts}
        })  {
            id
        }
    }
`;

const UPDATE_USER_AUTOMATED_ACTION = gql`
    mutation UpdateUserAutomatedAction($id: ID!, $automated_actions: [ConnectUserAutomatedAction!]) {
        updateUserAutomatedAction(input: {
            id: $id
            automated_actions: {sync: $automated_actions}
        })  {
            id
        }
    }
`;

const GET_AUTOMATIONS = gql`
    query GetAutomations {
        automated_actions(first: 100000, where: {
            column: "notify"
            value: true
        }) {
            data {
                id
                name
            }
        }
    }
`;

export {
  GET_USER_ALERT,
  GET_USER_ALERT_BY_ID,
  UPDATE_USER_ALERT,
  GET_AUTOMATIONS,
  UPDATE_USER_AUTOMATED_ACTION,
};
