import { gql } from '@apollo/client';

const CANCEL_SHIPPING_ORDER = gql`
    mutation CancelShippingOrder($id: ID!) {
        cancelShippingOrder(id: $id) {
            id
        }
    }
`;

export { CANCEL_SHIPPING_ORDER };
