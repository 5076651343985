import { useMutation } from '@apollo/client';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { BtnInATab, Loading } from 'front-library';
import IconAddGreen from 'front-library/build/img/icon-add-green.svg';

import { useQueryGraphQl } from '../../../../hooks/useQueryGraphQl';
import { routes } from '../../../../routes';
import { ListPage } from '../../../templates/ListPage';
import { TourModel } from '../TourModel';
import { DELETE_TOUR_MODEL, GET_TOUR_MODELS } from '../graphql';
import { DataGrid } from './DataGrid';

const TourModelList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data,
    setPagination,
    setFirst,
    setVariable,
    setOrderBy,
    loading,
    refetch,
  } = useQueryGraphQl(GET_TOUR_MODELS, { search: '%' });

  const [deleteTourModelMutation, { loading: deleteLoading }] =
    useMutation(DELETE_TOUR_MODEL);

  const handleDeleteConfirm = async (id, name) => {
    try {
      await deleteTourModelMutation({ variables: { id } });
      await refetch();

      toast.success(t('tourModels:deleted.success', { name }));
    } catch (e) {
      console.error(e);
      toast.error(t('tourModels:deleted.error', { name }));
    }
  };

  const onDeleteHandler = async (row) =>
    confirmAlert({
      title: t('common:deletion'),
      message: t('tourModels:askDelete', { name: row.name }),
      buttons: [
        {
          label: t('common:yes'),
          onClick: () => handleDeleteConfirm(row.id, row.name),
        },
        {
          label: t('common:no'),
        },
      ],
    });

  const handleAdd = () => navigate(routes.administrationTourModelCreation.path);

  return (
    <>
      <Routes>
        <Route
          path={routes.administrationTourModelCreation.partialPath}
          element={<TourModel refetch={refetch} />}
        />
        <Route
          path={routes.administrationTourModelEdition.partialPath}
          element={<TourModel refetch={refetch} />}
        />
      </Routes>

      <ListPage
        title={t('menu:deliveryModel')}
        paginatorInfo={data?.tour_models?.paginatorInfo}
        onPaginate={setPagination}
        onRowsPerPageChange={setFirst}
        onSearch={(search) => setVariable('search', `%${search.trim()}%`)}
        onAdd={handleAdd}
      >
        <DataGrid
          data={data?.tour_models?.data}
          onDelete={onDeleteHandler}
          onOrderBy={setOrderBy}
        />

        {(loading || deleteLoading) && <Loading />}

        <div className="btnInATabContainer">
          <BtnInATab
            text={t('tourModels:add')}
            onClick={handleAdd}
            imgSrc={IconAddGreen}
          />
        </div>
      </ListPage>
    </>
  );
};

export { TourModelList };
