import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PanelSection } from 'front-library';

import { Autocomplete } from '../../../../molecules/Autocomplete/Autocomplete';
import { GET_ASSET_TYPES } from '../../../../molecules/Autocomplete/graphql';
import { FiltersSectionNumber } from '../../styled-components';

const AssetTypeFilter = ({ assetTypesFilter, assetTypesTotal, onChange }) => {
  const { t } = useTranslation();

  return (
    <PanelSection
      title={t('mapFilters:assetType')}
      customContent={
        assetTypesFilter?.length ? (
          <FiltersSectionNumber>
            {`(${assetTypesFilter.length}/${assetTypesTotal})`}
          </FiltersSectionNumber>
        ) : null
      }
      className="standard-panel-section"
    >
      <Autocomplete
        multiple={true}
        onChange={(values) => onChange(values)}
        placeholderTranslateKey="common:nSelected"
        query={GET_ASSET_TYPES}
        selectAll={true}
        value={assetTypesFilter}
      />
    </PanelSection>
  );
};

AssetTypeFilter.propTypes = {
  assetTypesFilter: PropTypes.array,
  assetTypesTotal: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { AssetTypeFilter };
