import 'leaflet-routing-machine';
import 'leaflet/dist/leaflet.css';
import React, { useContext, useEffect, useState } from 'react';
import {
  Circle,
  LayersControl,
  ScaleControl,
  TileLayer,
  ZoomControl,
} from 'react-leaflet';
import 'react-leaflet-markercluster/dist/styles.min.css';

import { TopScreenLoading } from 'front-library';

import { MeContext } from '../../../contexts/MeContext';
import { Legend } from './Legend/Legend';
import { ArrivalSiteMarker } from './Markers/ArrivalSiteMarker';
import { CheckpointMarker } from './Markers/CheckpointMarker';
import { MarkerMagma } from './Markers/MarkerMagma';
import { SiteMarker } from './Markers/SiteMarker';
import { MeasureControl } from './MeasureControl';
import { Routing } from './Routing/Routing';
import { RoutingPoints } from './Routing/RoutingPoints';
import { icons } from './icons';
import './index.css';
import {
  LoadingContainer,
  LocationMapContainer,
  StyledLeafletMap,
} from './styled-components';

const nbByChunckRouting = 24;

const LocationMap = ({
  showControls = true,
  routing = [],
  departureSite,
  arrivalSite,
  checkpoints = [],
  order,
  bounds = [],
  degradedPosition,
  center,
  children,
  zoom,
  onLocationChange,
  assetLocation,
  siteLocation,
  loading = false,
  removePanelOffset = false,
  onAction,
  onGeofenceEdit,
  geoJsonAssets,
}) => {
  const [refMap, setRefMap] = useState(null);
  const { me: user } = useContext(MeContext);

  let lastPoint = [];

  const routingChunk = routing
    .map((d) => [d.latitude, d.longitude])
    .reduce(
      (all, one, i) => {
        const ch = Math.floor(i / nbByChunckRouting);

        // if next route get lastpoint
        if (ch > 0 && i % nbByChunckRouting === 0) {
          all[ch] = [lastPoint];
        }

        all[ch] = [...(all[ch] || []), one];

        // save point
        lastPoint = one;

        return all;
      },
      [[]],
    );

  let boundsMap = [
    ...[degradedPosition, arrivalSite, departureSite, ...checkpoints, order]
      .filter((s) => s?.latitude && s?.longitude)
      .map((s) => [s.latitude, s.longitude]),
    ...bounds,
  ].filter((b) => b[0] && b[1]);

  if (boundsMap?.length > 0 && !removePanelOffset) {
    const boundsTemp = boundsMap.reduce((p, c) => {
      return [
        (p[0] && p[0] > c[0] && p[0]) || c[0], // lat max
        (p[1] && p[1] < c[0] && p[1]) || c[0], // lat min
        (p[2] && p[2] > c[1] && p[2]) || c[1], // long max
        (p[3] && p[3] < c[1] && p[3]) || c[1], // long min
      ];
    }, []);

    boundsMap = [
      ...boundsMap,
      // add point for decale map with panel
      [boundsTemp[1], boundsTemp[3] - (boundsTemp[2] - boundsTemp[3])],
    ];
  }

  useEffect(() => {
    const zoomIn = document.querySelector('.leaflet-control-zoom-in');
    const zoomOut = document.querySelector('.leaflet-control-zoom-out');

    const removeListeners = () => {
      if (!onAction || !refMap) {
        return;
      }

      refMap?.container?.removeEventListener('click', onAction);
      document.removeEventListener('mousewheel', onAction);

      zoomIn?.removeEventListener('click', onAction);
      zoomOut?.removeEventListener('click', onAction);
    };

    if (refMap) {
      zoomIn?.removeAttribute('href');
      zoomOut?.removeAttribute('href');
    }

    if (refMap && onAction) {
      removeListeners();

      refMap.container.addEventListener('click', onAction, { once: true });
      document.addEventListener('mousewheel', onAction, {
        once: true,
        capture: true,
      });
      zoomIn.addEventListener('click', onAction, { once: true });
      zoomOut.addEventListener('click', onAction, { once: true });

      return () => removeListeners();
    }
  }, [refMap, onAction]);

  const checkForOverlappingAsset = () => {
    let count = 0;

    for (const feature of geoJsonAssets.features) {
      if (
        feature.geometry.coordinates[0] === assetLocation.longitude &&
        feature.geometry.coordinates[1] === assetLocation.latitude
      ) {
        count++;
      }
    }

    return 1 === count;
  };

  const renderMapBoxTiles = () => {
    if (!showControls) {
      return (
        <TileLayer
          url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
          attribution='Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>'
          maxZoom={22}
          tileSize={512}
          zoomOffset={-1}
          id="mapbox/streets-v11"
          accessToken={process.env.REACT_APP_MAPBOX_KEY}
        />
      );
    }

    return (
      <LayersControl>
        <LayersControl.BaseLayer checked={true} name="Streets">
          <TileLayer
            url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
            attribution='Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>'
            maxZoom={22}
            tileSize={512}
            zoomOffset={-1}
            id="mapbox/streets-v11"
            accessToken={process.env.REACT_APP_MAPBOX_KEY}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Satellite">
          <TileLayer
            url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
            attribution='Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>'
            maxZoom={22}
            tileSize={512}
            zoomOffset={-1}
            id="mapbox/satellite-streets-v11"
            accessToken={process.env.REACT_APP_MAPBOX_KEY}
          />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay name="Traffic">
          <TileLayer
            url="https://2.traffic.maps.ls.hereapi.com/traffic/6.0/tiles/{z}/{x}/{y}/256/png32?apiKey={accessToken}"
            accessToken={process.env.REACT_APP_HEREAPI_KEY}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Truck limitations">
          <TileLayer
            url="https://2.base.maps.ls.hereapi.com/maptile/2.1/truckonlytile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey={accessToken}"
            accessToken={process.env.REACT_APP_HEREAPI_KEY}
          />
        </LayersControl.Overlay>
      </LayersControl>
    );
  };

  const renderGoogleMapsTiles = () => {
    if (!showControls) {
      return (
        <TileLayer
          url="https://www.google.fr/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
          attribution="Google Maps"
          maxZoom={22}
          tileSize={512}
          zoomOffset={-1}
        />
      );
    }

    return (
      <LayersControl>
        <LayersControl.BaseLayer checked={true} name="Google Map">
          <TileLayer
            attribution="Google Maps"
            url="https://www.google.fr/maps/vt?lyrs=m@189&gl=fr&x={x}&y={y}&z={z}"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Google Map Satellite">
          <TileLayer
            attribution="Google Maps Satellite"
            url="https://www.google.fr/maps/vt?lyrs=s@189&gl=fr&x={x}&y={y}&z={z}"
          />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay name="Traffic">
          <TileLayer
            url="https://2.traffic.maps.ls.hereapi.com/traffic/6.0/tiles/{z}/{x}/{y}/256/png32?apiKey={accessToken}"
            accessToken={process.env.REACT_APP_HEREAPI_KEY}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Truck limitations">
          <TileLayer
            url="https://2.base.maps.ls.hereapi.com/maptile/2.1/truckonlytile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey={accessToken}"
            accessToken={process.env.REACT_APP_HEREAPI_KEY}
          />
        </LayersControl.Overlay>
      </LayersControl>
    );
  };

  return (
    <LocationMapContainer>
      <StyledLeafletMap
        bounds={(boundsMap.length > 0 && boundsMap) || null}
        maxZoom={22}
        ref={(ref) => setRefMap(ref)}
        zoomControl={false}
        scrollWheelZoom={true}
        center={center}
        zoom={zoom}
        onmove={(e) => e.originalEvent && onAction && onAction()}
        onzoomend={(e) =>
          onLocationChange?.(e.target.getCenter(), e.target.getZoom())
        }
        onmoveend={(e) =>
          onLocationChange?.(e.target.getCenter(), e.target.getZoom())
        }
        minZoom={2}
        worldCopyJump={true}
      >
        {loading && (
          <LoadingContainer>
            <TopScreenLoading />
          </LoadingContainer>
        )}
        {showControls && <ZoomControl position="topright" />}

        {'Viapost' === user.company.name && renderGoogleMapsTiles()}
        {'Viapost' !== user.company.name && renderMapBoxTiles()}

        {showControls && (
          <MeasureControl position="topright" lineColor="#EF9D11" />
        )}
        <ScaleControl />

        {refMap &&
          routingChunk.map((r, i) => (
            <Routing
              key={`${Date.now()}-${i}`}
              map={refMap}
              waypoints={r}
              index={`${Date.now()}-${i}`}
            />
          ))}

        {departureSite && (
          <SiteMarker site={departureSite} onGeofenceEdit={onGeofenceEdit} />
        )}

        {arrivalSite && (
          <ArrivalSiteMarker
            site={arrivalSite}
            onGeofenceEdit={onGeofenceEdit}
          />
        )}

        {checkpoints.length > 0 &&
          checkpoints.map((c, i) => (
            <CheckpointMarker
              key={`${Date.now()}-${i}`}
              checkpoint={c}
              onGeofenceEdit={onGeofenceEdit}
            />
          ))}

        {routing?.length && <RoutingPoints routing={routing} />}

        {order && (
          <MarkerMagma
            position={
              (degradedPosition && [
                degradedPosition.latitude,
                degradedPosition.longitude,
              ]) || [order.latitude, order.longitude]
            }
            icon={icons.order}
          />
        )}

        {degradedPosition && (
          <>
            <Circle
              center={[degradedPosition.latitude, degradedPosition.longitude]}
              color="#EF9D11"
              weight="2"
              radius={degradedPosition.accuracy}
            />
            {refMap && order && (
              <Routing
                degraded={true}
                map={refMap}
                waypoints={[
                  [degradedPosition.latitude, degradedPosition.longitude],
                  [order.latitude, order.longitude],
                ]}
              />
            )}
          </>
        )}

        {assetLocation && (
          <MarkerMagma
            position={[assetLocation.latitude, assetLocation.longitude]}
            icon={
              checkForOverlappingAsset()
                ? icons.clusterActiveAsset
                : icons.activeAsset
            }
            pane="shadowPane"
          />
        )}

        {siteLocation && (
          <MarkerMagma
            position={[siteLocation.latitude, siteLocation.longitude]}
            icon={icons.activeSite}
            pane="shadowPane"
          />
        )}

        {children}
      </StyledLeafletMap>
      <Legend />
    </LocationMapContainer>
  );
};

export { LocationMap };
