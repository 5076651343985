import { useMutation } from '@apollo/client';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import {
  Route,
  Routes,
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { BtnInATab, Button, Loading } from 'front-library';
import IconAddGreen from 'front-library/build/img/icon-add-green.svg';
import UserIcon from 'front-library/build/img/icon-user.svg';

import { MeContext } from '../../../../contexts/MeContext';
import { useQueryGraphQl } from '../../../../hooks/useQueryGraphQl';
import { routes } from '../../../../routes';
import { ListPage } from '../../../templates/ListPage';
import { User } from '../User';
import { DELETE_USER, GET_USERS } from '../graphql';
import { DataGrid } from './DataGrid';

const ButtonContainer = styled.div`
    display: flex;
    gap: 10px;
    padding: 7px 5px;
    background-color: white;
`;

const UserList = () => {
  const { me: currentUser } = useContext(MeContext);
  const { id = null, action = null } = useParams();

  const isAdmin =
    currentUser.rolesName.includes('superAdmin') ||
    currentUser.rolesName.includes('Administrator');
  const search = isAdmin ? id ?? '%' : currentUser.id;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null);
  const {
    data,
    setPagination,
    setFirst,
    setVariable,
    setOrderBy,
    setOrderByRelation,
    refetch,
    loading,
  } = useQueryGraphQl(GET_USERS, { first: 10, search });
  const [deleteUser, { loading: deleteLoading }] = useMutation(DELETE_USER);

  const handleDeleteConfirm = async (id, fullName) => {
    try {
      await deleteUser({ variables: { id } });
      await refetch();

      toast.success(t('user:deleted:success', { name: fullName }));
    } catch (e) {
      console.error(e);
      toast.error(t('user:deleted:error', { name: fullName }));
    }
  };

  const handleDelete = (row) =>
    confirmAlert({
      title: t('common:deletion'),
      message: t('user:askDelete', { name: `${row.surname} ${row.name}` }),
      buttons: [
        {
          label: t('common:yes'),
          onClick: () =>
            handleDeleteConfirm(+row.id, `${row.surname} ${row.name}`),
        },
        {
          label: t('common:no'),
        },
      ],
    });

  /** @TODO remove this, it will be managed by the router */
  const toggleModal = useCallback(
    (value, id = null) => {
      setUser(data.users.data.find((user) => user.id === id));
      setShowModal(value);
    },
    [data],
  );

  const handleAdd = () => {
    // Change current url to user/create
    navigate(routes.administrationUserCreation.path);
    toggleModal(true);
  };

  const handleClose = () => {
    toggleModal(false);
    navigate(generatePath(routes.administrationUser.path));
  };

  /** @TODO remove this uggly useEffect !! */
  useEffect(() => {
    if (id && data) {
      toggleModal(true, id);
    }
  }, [data, id, toggleModal]);

  return (
    <>
      {/* @TODO make the routing the good way */}
      <Routes>
        <Route path={routes.administrationUserCreation.path} />
        <Route path={routes.administrationUserEdition.path} />
      </Routes>
      <ListPage
        title={t('menu:userManagement')}
        paginatorInfo={isAdmin ? data?.users?.paginatorInfo : null}
        onPaginate={setPagination}
        onRowsPerPageChange={setFirst}
        onSearch={
          isAdmin
            ? (search) => setVariable('search', `%${search.trim()}%`)
            : null
        }
        onAdd={isAdmin ? handleAdd : null}
      >
        {currentUser.rolesName.includes('superAdmin') && (
          <ButtonContainer className="header">
            <Button
              text={t('user:followup')}
              onClick={() => {
                window.location.href = '/user/track';
              }}
              img={UserIcon}
            />
          </ButtonContainer>
        )}

        <DataGrid
          data={data?.users?.data}
          currentUser={currentUser}
          onDelete={handleDelete}
          onOrderBy={setOrderBy}
          onOrderByRelation={setOrderByRelation}
        />

        {(loading || deleteLoading) && <Loading />}

        {isAdmin && (
          <div className="btnInATabContainer">
            <BtnInATab
              text={t('user:add')}
              onClick={handleAdd}
              imgSrc={IconAddGreen}
            />
          </div>
        )}

        {showModal && (
          <User
            user={user}
            action={action}
            currentUser={currentUser}
            refetch={refetch}
            onClose={handleClose}
          />
        )}
      </ListPage>
    </>
  );
};

export { UserList };
