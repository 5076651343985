import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  AutocompleteEvents,
  Autocomplete as FrontLibAutocomplete,
} from 'front-library';

import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { Loading } from '../Loading/Loading';

const Autocomplete = ({
  className,
  customOptions = [],
  disabled = false,
  errorText = null,
  label = null,
  multiple = false,
  onChange,
  placeholderTranslateKey,
  query,
  queryVars = {},
  required = false,
  selectAll = false,
  value,
}) => {
  const { t } = useTranslation();

  // The goal is to run requests only when Autocomplete is open
  const [isOpen, setIsOpen] = useState(false);

  const {
    loading,
    data: queryData,
    variables,
    setVariable,
  } = useQueryGraphQl(query, { ...queryVars, search: '%' }, {}, !isOpen);

  const options = useMemo(() => {
    if (!queryData?.data?.data) {
      return [];
    }

    return [...customOptions, ...queryData.data.data];
  }, [customOptions, queryData]);

  const placeholder = useMemo(() => {
    if (Array.isArray(value)) {
      if (1 === value.length) {
        return value[0].title;
      }

      if (0 !== value.length) {
        if (placeholderTranslateKey) {
          return t(placeholderTranslateKey, { count: value.length });
        }

        return t('common:nSelected', { count: value.length });
      }

      return t('common:select');
    }

    return value ? value.title : t('common:select');
  }, [placeholderTranslateKey, t, value]);

  /**
   * This function is here to keep the previously selected items when
   * you make a new search and click on select all and only unselect
   * the displayed items without touching the previously selected ones.
   */
  const handleChange = useCallback(
    (newValue, event) => {
      if (AutocompleteEvents.selectAllSelected === event) {
        onChange(uniqBy([...value, ...newValue], (obj) => obj.value));

        return;
      }

      if (
        AutocompleteEvents.selectAllUnselected === event &&
        '%' !== variables.search
      ) {
        onChange(
          value.filter((v) => !options.find((o) => o.value === v.value)),
        );

        return;
      }

      onChange(newValue);
    },
    [onChange, options, value, variables],
  );

  return (
    <>
      {loading && <Loading />}
      <FrontLibAutocomplete
        className={className}
        disabled={disabled}
        errorText={errorText}
        helperMessage={
          queryData?.data?.data?.length &&
          20 === queryData?.data?.data?.length ? (
            <Trans
              i18nKey="common:autocompleteHelper"
              values={{
                count: queryData.data.data.length,
              }}
              components={{ strong: <strong /> }}
            />
          ) : null
        }
        label={label}
        multiple={multiple}
        onChange={handleChange}
        onInputChange={(input) =>
          setVariable('search', '' === input ? '%' : `%${input}%`)
        }
        onOpen={() => setIsOpen(true)}
        options={options}
        placeholder={placeholder}
        required={required}
        selectAll={selectAll ? t('common:selectAll') : null}
        value={value}
      />
    </>
  );
};

Autocomplete.propTypes = {
  className: PropTypes.string,
  customOptions: PropTypes.array,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholderTranslateKey: PropTypes.string,
  query: PropTypes.object.isRequired,
  queryVars: PropTypes.object,
  required: PropTypes.bool,
  selectAll: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.any,
      title: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
        title: PropTypes.string,
      }),
    ),
  ]),
};

export { Autocomplete };
