import * as Actions from './actions';

import { buildFilters, buildVariables } from './utils';

function queryGraphQlReducer(state, action) {
  switch (action.type) {
    case Actions.SET_VARIABLES_ACTION:
      return { ...state, variables: action.payload };
    case Actions.SET_FILTERS_ACTION:
      return { ...state, filters: action.payload };
    case Actions.SET_FILTER_ACTION:
      return {
        ...state,
        filters: {
          ...buildFilters(
            state.filters,
            action.payload.column,
            action.payload.operator,
            action.payload.value,
          ),
        },
        variables: {
          ...state.variables,
          page: 1,
        },
      };
    case Actions.SET_VARIABLE_ACTION:
      return {
        ...state,
        variables: {
          ...buildVariables(
            state.variables,
            action.payload.label,
            action.payload.value,
          ),
        },
      };
    case Actions.SET_VARIABLES_AND_FILTERS_ACTION:
      return {
        ...state,
        variables: {
          ...action.payload.variables,
        },
        filters: {
          ...action.payload.filters,
        },
      };
    case Actions.SET_FIRST_ACTION:
      return {
        ...state,
        variables: {
          ...state.variables,
          first: action.payload,
          page: 0,
        },
      };
    default:
      return { ...state };
  }
}

export { queryGraphQlReducer };
