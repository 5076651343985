import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { BtnInATab, Loading } from 'front-library';
import IconAddGreen from 'front-library/build/img/icon-add-green.svg';

import { exportTypesEnum } from '../../../../helpers/form/exportTypesEnum';
import { useQueryGraphQl } from '../../../../hooks/useQueryGraphQl';
import { routes } from '../../../../routes';
import {
  DELETE_ASSET,
  EXPORT_ASSETS,
  GET_ASSETS,
  GET_CUSTOM_PARAMETERS,
} from '../graphql';

import { ListPage } from '../../../templates/ListPage';
import { Asset } from '../Asset';
import { BtnInATabContainer } from '../styled-components';
import { DataGrid } from './DataGrid';

const TITLES = {
  view: 'menu:assetsManagement',
  edit: 'menu:assetEdition',
};

const AssetList = ({ page }) => {
  const { id = null } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [exportAssets, { loading: exportLoading }] = useMutation(EXPORT_ASSETS);

  const search = id ?? '%';

  const [showModal, setShowModal] = useState(false);
  const [asset, setAsset] = useState(null);

  const { data: dataCustomParameters } = useQueryGraphQl(GET_CUSTOM_PARAMETERS);
  const {
    data,
    setPagination,
    setFirst,
    setVariable,
    setOrderBy,
    loading: assetLoading,
    refetch,
  } = useQueryGraphQl(GET_ASSETS, { first: 25, search });
  const [deleteAsset, { loading: deleteLoading }] = useMutation(DELETE_ASSET);

  const handleDelete = (row) => {
    confirmAlert({
      title: t('common:deletion'),
      message: t('assets:askDelete', { reference: row.reference }),
      buttons: [
        {
          label: t('common:yes'),
          onClick: () => handleDeleteConfirm(row),
        },
        {
          label: t('common:no'),
        },
      ],
    });
  };

  const handleDeleteConfirm = async ({ id, reference }) => {
    try {
      await deleteAsset({ variables: { id } });
      await refetch();
      toast.success(t('assets:deleted.success', { reference }));
    } catch (e) {
      console.error(e);
      toast.error(t('assets:deleted.error', { reference }));
    }
  };

  const toggleModal = useCallback(
    (value, id = null) => {
      setAsset(data.assets.data.find((item) => item.id === id));
      setShowModal(value);
    },
    [data],
  );

  const onClose = () => {
    toggleModal(false);

    if (id) {
      navigate(routes.administrationAsset.path);
    }
  };

  const downloadFile = async (userId, extensions) => {
    try {
      await exportAssets({
        variables: {
          user_id: userId,
          type: exportTypesEnum.asset,
          formats: extensions,
          filters: [],
        },
      });

      toast.success(t('common:exportSuccess'));
    } catch (error) {
      Sentry.captureException(error);

      toast.error(t('common:exportError'));
    }
  };

  /** @TODO remove this and use router to handle modal state */
  useEffect(() => {
    if (id && data) {
      toggleModal(true, id);
    }
  }, [data, id, toggleModal]);

  return (
    <>
      <ListPage
        title={t(TITLES[page])}
        paginatorInfo={data?.assets?.paginatorInfo}
        onPaginate={setPagination}
        onRowsPerPageChange={setFirst}
        search={true}
        onSearch={(search) => setVariable('search', `%${search.trim()}%`)}
        onAdd={() => toggleModal(true)}
        asyncExport={true}
        downloadFile={downloadFile}
      >
        <DataGrid
          data={data?.assets?.data}
          onDelete={handleDelete}
          onOrderBy={setOrderBy}
          loading={assetLoading}
          onClick={toggleModal}
        />
        {(assetLoading || deleteLoading || exportLoading) && <Loading />}
        <BtnInATabContainer>
          <BtnInATab
            text={t('assets:addAsset')}
            onClick={() => toggleModal(true)}
            imgSrc={IconAddGreen}
          />
        </BtnInATabContainer>
        {showModal && (
          <Asset
            onClose={onClose}
            onSave={refetch}
            asset={asset}
            dataCustomParameters={dataCustomParameters}
          />
        )}
      </ListPage>
    </>
  );
};

AssetList.propTypes = {
  page: PropTypes.string.isRequired,
};

export { AssetList };
