import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Checkbox, FileInput, Text } from 'front-library';
import CloseIcon from 'front-library/build/img/delete-cross.svg';
import DownloadIcon from 'front-library/build/img/download-icon.svg';

import { MeContext } from '../../../contexts/MeContext';
import { Form } from '../../atoms/Form/Form';
import {
  ConfigurationTitle,
  HelperText,
  Icon,
  Link,
  Title,
} from './styled-components';

const EXAMPLE_LINK = `${process.env.REACT_APP_AWS_PUBLIC_PATH}/Import+exemple.csv`;

const ImportForm = ({
  existingRef = false,
  existingRefOrderEmptyAsset = false,
  formValues,
  onClose,
  onImport,
  setFormValues,
}) => {
  const { me: user } = useContext(MeContext);
  const { t } = useTranslation();

  const exampleDate = useMemo(
    () =>
      moment()
        .utc()
        .format(
          user.company.date_format
            .replace('H', 'HH')
            .replace('d', 'DD')
            .replace('m', 'MM')
            .replace('i', 'mm')
            .replace('s', 'ss'),
        ),
    [user],
  );

  return (
    <Form
      cancelTranslateKey="shippingOrders:importPanel.actions.cancel"
      hideSubmit={!formValues.selectedFile}
      hideCancel={!formValues.selectedFile || !existingRef}
      onCancel={onClose}
      onSubmit={onImport}
      submitTranslateKey={
        existingRef
          ? 'shippingOrders:importPanel.actions.finish'
          : 'shippingOrders:importPanel.actions.import'
      }
    >
      <Title>
        {t('shippingOrders:importPanel.title')}
        <Icon src={CloseIcon} onClick={onClose} />
      </Title>
      <Trans i18nKey="shippingOrders:importPanel.explanation" />
      <p>
        <Text.Bold>{user.company.date_format}</Text.Bold> ({exampleDate})
      </p>

      <Link href={EXAMPLE_LINK}>
        <Icon src={DownloadIcon} />
        {t('shippingOrders:importPanel.downloadExample')}
      </Link>

      <FileInput
        fileName={formValues.selectedFile?.name || ''}
        label={t('shippingOrders:importPanel.label')}
        onChange={(selectedFile) =>
          setFormValues({ ...formValues, selectedFile })
        }
      />

      {existingRef && (
        <ConfigurationTitle>
          {t('shippingOrders:importPanel.configurationTitle')}
        </ConfigurationTitle>
      )}

      {(existingRef || existingRefOrderEmptyAsset) && (
        <div>
          <Checkbox
            label={t('shippingOrders:importPanel.replaceTitle')}
            checked={formValues.replaceShippingOrders}
            onChange={(e) => {
              if (!e.target.checked && formValues.preserveAsset) {
                return;
              }

              setFormValues({
                ...formValues,
                replaceShippingOrders: e.target.checked,
              });
            }}
          />
          <HelperText>
            {t('shippingOrders:importPanel.replaceHelper')}
          </HelperText>
        </div>
      )}

      {existingRefOrderEmptyAsset && (
        <div>
          <Checkbox
            label={t('shippingOrders:importPanel.preserveTitle')}
            checked={formValues.preserveAsset}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                preserveAsset: e.target.checked,
                replaceShippingOrders: e.target.checked
                  ? true
                  : formValues.replaceShippingOrders,
              })
            }
          />
          <HelperText>
            {t('shippingOrders:importPanel.preserveHelper')}
          </HelperText>
        </div>
      )}
    </Form>
  );
};

ImportForm.propTypes = {
  existingRef: PropTypes.bool.isRequired,
  existingRefOrderEmptyAsset: PropTypes.bool.isRequired,
  formValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onImport: PropTypes.func.isRequired,
  setFormValues: PropTypes.func.isRequired,
};

export { ImportForm };
