import { gql } from '@apollo/client';

const IMPORT_SHIPPING_ORDER = gql`
    mutation ImportShippingOrder(
        $file: Upload!,
        $replaceShippingOrder: Boolean,
        $preserveAsset: Boolean,
        $skipDuplicatesChecks: Boolean
    ) {
        importShippingOrder(
            input: {
                file: $file,
                replaceShippingOrder: $replaceShippingOrder
                preserveAsset: $preserveAsset
                skipDuplicatesChecks: $skipDuplicatesChecks
            }
        ) {
            columns_matching
            errors {
                field
                line
                model
                modelRef
                message
                type
            }
            existing_ref
            existing_ref_order_empty_asset
            new_shipping_orders_count
            replacement_shipping_orders_count
            status
            total_line
        }
    }
`;

export { IMPORT_SHIPPING_ORDER };
