import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel, PanelTitle, Switch } from 'front-library';
import imgArrivedCheckPoint from 'front-library/build/img/arrived-check-point.svg';
import imgArrivedSite from 'front-library/build/img/arrived-site.svg';
import imgDeliveryNotFollow from 'front-library/build/img/delivery-not-follow.svg';
import imgDelivery from 'front-library/build/img/delivery.svg';
import imgDeviated from 'front-library/build/img/devoye.svg';
import imgLateCheckPoint from 'front-library/build/img/late-check-point.svg';
import imgNoDetectArrivedSite from 'front-library/build/img/no-detect-arrived-site.svg';

import {
  AlertShippingOrderDelayed,
  AlertShippingOrderDeviated,
  AlertShippingOrderLikelyDeviated,
  AlertShippingOrderNotArrived,
  AlertShippingOrderOnDeliverySite,
  AlertShippingOrderStarted,
  AlertTourDelayed,
  AlertTourOnDeliverySite,
  AlertUntrackedShippingOrder,
  Email,
  SMS,
} from './constants';
import {
  AlertTitle,
  HelpText,
  LeftSpan,
  LeftSwitch,
  PanelContainer,
  SecondTitle,
  SecondTitleAndDescription,
  SwitchTooltip,
} from './styled-components';

const PanelAlertDelivery = ({
  onChange,
  user,
  isSmsEnabled,
  isAllowed,
  isActivated,
}) => {
  const { t } = useTranslation();

  const isDeliverySmsAlertAllowed = (alertId) =>
    isAllowed('alert', alertId, SMS);

  const getTooltipText = (alertId) =>
    isDeliverySmsAlertAllowed(alertId)
      ? t('alert:phoneTooltip')
      : t('alert:permissionTooltip');

  return (
    <Panel>
      <PanelContainer>
        <div className="title-alert-box">
          <PanelTitle label={t('alert:delivery.title')} />
        </div>
        <SecondTitleAndDescription>
          <div>{t('alert:delivery.intro')}</div>
          <SecondTitle>
            {isSmsEnabled && <LeftSpan>{t('alert:sms')}</LeftSpan>}
            <span>{t('alert:email')}</span>
          </SecondTitle>
        </SecondTitleAndDescription>
        {/* Delivery in progress */}
        <div className="testgrid">
          <AlertTitle
            text={t('alert:delivery.alertShippingOrderStarted')}
            img={imgDelivery}
          />
          {isSmsEnabled &&
            ((isDeliverySmsAlertAllowed(AlertShippingOrderStarted) &&
              user.phone && (
                <LeftSwitch
                  onChange={(e) =>
                    onChange(
                      'alert',
                      AlertShippingOrderStarted,
                      SMS,
                      e.target.checked,
                    )
                  }
                  checked={isActivated('alert', AlertShippingOrderStarted, SMS)}
                  className="switchIconInput styleLabelInput"
                  disabled={
                    !isDeliverySmsAlertAllowed(AlertShippingOrderStarted)
                  }
                />
              )) || (
              <SwitchTooltip
                text={getTooltipText(AlertShippingOrderStarted)}
                placement="left"
              >
                <LeftSwitch
                  onChange={(e) =>
                    onChange(
                      'alert',
                      AlertShippingOrderStarted,
                      SMS,
                      e.target.checked,
                    )
                  }
                  checked={isActivated('alert', AlertShippingOrderStarted, SMS)}
                  className="switchIconInput styleLabelInput"
                  disabled={true}
                />
              </SwitchTooltip>
            ))}
          <Switch
            onChange={(e) =>
              onChange(
                'alert',
                AlertShippingOrderStarted,
                Email,
                e.target.checked,
              )
            }
            checked={isActivated('alert', AlertShippingOrderStarted, Email)}
            className="switchIconInput styleLabelInput"
          />
        </div>
        {/* Delivery delayed on delivery site */}
        <div className="testgrid">
          <AlertTitle
            text={t('alert:delivery.alertShippingOrderDelayed')}
            img={imgLateCheckPoint}
          />
          {isSmsEnabled &&
            ((isDeliverySmsAlertAllowed(AlertShippingOrderDelayed) &&
              user.phone && (
                <LeftSwitch
                  onChange={(e) =>
                    onChange(
                      'alert',
                      AlertShippingOrderDelayed,
                      SMS,
                      e.target.checked,
                    )
                  }
                  checked={isActivated('alert', AlertShippingOrderDelayed, SMS)}
                  className="switchIconInput styleLabelInput"
                  disabled={
                    !isDeliverySmsAlertAllowed(AlertShippingOrderDelayed)
                  }
                />
              )) || (
              <SwitchTooltip
                text={getTooltipText(AlertShippingOrderDelayed)}
                placement="left"
              >
                <LeftSwitch
                  onChange={(e) =>
                    onChange(
                      'alert',
                      AlertShippingOrderDelayed,
                      SMS,
                      e.target.checked,
                    )
                  }
                  checked={isActivated('alert', AlertShippingOrderDelayed, SMS)}
                  className="switchIconInput styleLabelInput"
                  disabled={true}
                />
              </SwitchTooltip>
            ))}
          <Switch
            onChange={(e) =>
              onChange(
                'alert',
                AlertShippingOrderDelayed,
                Email,
                e.target.checked,
              )
            }
            checked={isActivated('alert', AlertShippingOrderDelayed, Email)}
            className="switchIconInput styleLabelInput"
          />
        </div>
        {/* Overdue at one of the transit points */}
        <div className="testgrid">
          <AlertTitle
            text={t('alert:delivery.alertTourDelayed')}
            img={imgLateCheckPoint}
          />
          {isSmsEnabled &&
            ((isDeliverySmsAlertAllowed(AlertTourDelayed) && user.phone && (
              <LeftSwitch
                onChange={(e) =>
                  onChange('alert', AlertTourDelayed, SMS, e.target.checked)
                }
                checked={isActivated('alert', AlertTourDelayed, SMS)}
                className="switchIconInput styleLabelInput"
                disabled={!isDeliverySmsAlertAllowed(AlertTourDelayed)}
              />
            )) || (
              <SwitchTooltip
                text={getTooltipText(AlertTourDelayed)}
                placement="left"
              >
                <LeftSwitch
                  onChange={(e) =>
                    onChange('alert', AlertTourDelayed, SMS, e.target.checked)
                  }
                  checked={isActivated('alert', AlertTourDelayed, SMS)}
                  className="switchIconInput styleLabelInput"
                  disabled={true}
                />
              </SwitchTooltip>
            ))}
          <Switch
            onChange={(e) =>
              onChange('alert', AlertTourDelayed, Email, e.target.checked)
            }
            checked={isActivated('alert', AlertTourDelayed, Email)}
            className="switchIconInput styleLabelInput"
          />
        </div>
        {/* Arrival at one of the waypoints */}
        <div className="testgrid">
          <AlertTitle
            text={t('alert:delivery.alertTourOnDeliverySite')}
            img={imgArrivedCheckPoint}
          />
          {isSmsEnabled &&
            ((isDeliverySmsAlertAllowed(AlertTourOnDeliverySite) &&
              user.phone && (
                <LeftSwitch
                  onChange={(e) =>
                    onChange(
                      'alert',
                      AlertTourOnDeliverySite,
                      SMS,
                      e.target.checked,
                    )
                  }
                  checked={isActivated('alert', AlertTourOnDeliverySite, SMS)}
                  className="switchIconInput styleLabelInput"
                  disabled={!isDeliverySmsAlertAllowed(AlertTourOnDeliverySite)}
                />
              )) || (
              <SwitchTooltip
                text={getTooltipText(AlertTourOnDeliverySite)}
                placement="left"
              >
                <LeftSwitch
                  onChange={(e) =>
                    onChange(
                      'alert',
                      AlertTourOnDeliverySite,
                      SMS,
                      e.target.checked,
                    )
                  }
                  checked={isActivated('alert', AlertTourOnDeliverySite, SMS)}
                  className="switchIconInput styleLabelInput"
                  disabled={true}
                />
              </SwitchTooltip>
            ))}
          <Switch
            onChange={(e) =>
              onChange(
                'alert',
                AlertTourOnDeliverySite,
                Email,
                e.target.checked,
              )
            }
            checked={isActivated('alert', AlertTourOnDeliverySite, Email)}
            className="switchIconInput styleLabelInput"
          />
        </div>
        {/* Arrived at delivery site */}
        <div className="testgrid">
          <AlertTitle
            text={t('alert:delivery.alertShippingOrderOnDeliverySite')}
            img={imgArrivedSite}
          />
          {isSmsEnabled &&
            ((isDeliverySmsAlertAllowed(AlertShippingOrderOnDeliverySite) &&
              user.phone && (
                <LeftSwitch
                  onChange={(e) =>
                    onChange(
                      'alert',
                      AlertShippingOrderOnDeliverySite,
                      SMS,
                      e.target.checked,
                    )
                  }
                  checked={isActivated(
                    'alert',
                    AlertShippingOrderOnDeliverySite,
                    SMS,
                  )}
                  className="switchIconInput styleLabelInput"
                  disabled={
                    !isDeliverySmsAlertAllowed(AlertShippingOrderOnDeliverySite)
                  }
                />
              )) || (
              <SwitchTooltip
                text={getTooltipText(AlertShippingOrderOnDeliverySite)}
                placement="left"
              >
                <LeftSwitch
                  onChange={(e) =>
                    onChange(
                      'alert',
                      AlertShippingOrderOnDeliverySite,
                      SMS,
                      e.target.checked,
                    )
                  }
                  checked={isActivated(
                    'alert',
                    AlertShippingOrderOnDeliverySite,
                    SMS,
                  )}
                  className="switchIconInput styleLabelInput"
                  disabled={true}
                />
              </SwitchTooltip>
            ))}
          <Switch
            onChange={(e) =>
              onChange(
                'alert',
                AlertShippingOrderOnDeliverySite,
                Email,
                e.target.checked,
              )
            }
            checked={isActivated(
              'alert',
              AlertShippingOrderOnDeliverySite,
              Email,
            )}
            className="switchIconInput styleLabelInput"
          />
        </div>
        {/* Delivery slot overdue */}
        <div className="testgrid">
          <AlertTitle
            text={t('alert:delivery.alertShippingOrderNotArrived')}
            img={imgNoDetectArrivedSite}
          />
          {isSmsEnabled &&
            ((isDeliverySmsAlertAllowed(AlertShippingOrderNotArrived) &&
              user.phone && (
                <LeftSwitch
                  onChange={(e) =>
                    onChange(
                      'alert',
                      AlertShippingOrderNotArrived,
                      SMS,
                      e.target.checked,
                    )
                  }
                  checked={isActivated(
                    'alert',
                    AlertShippingOrderNotArrived,
                    SMS,
                  )}
                  className="switchIconInput styleLabelInput"
                  disabled={
                    !isDeliverySmsAlertAllowed(AlertShippingOrderNotArrived)
                  }
                />
              )) || (
              <SwitchTooltip
                text={getTooltipText(AlertShippingOrderNotArrived)}
                placement="left"
              >
                <LeftSwitch
                  onChange={(e) =>
                    onChange(
                      'alert',
                      AlertShippingOrderNotArrived,
                      SMS,
                      e.target.checked,
                    )
                  }
                  checked={isActivated(
                    'alert',
                    AlertShippingOrderNotArrived,
                    SMS,
                  )}
                  className="switchIconInput styleLabelInput"
                  disabled={true}
                />
              </SwitchTooltip>
            ))}
          <Switch
            onChange={(e) =>
              onChange(
                'alert',
                AlertShippingOrderNotArrived,
                Email,
                e.target.checked,
              )
            }
            checked={isActivated('alert', AlertShippingOrderNotArrived, Email)}
            className="switchIconInput styleLabelInput"
          />
        </div>
        {/* Delivery not tracked */}
        <div className="testgrid">
          <AlertTitle
            text={t('alert:delivery.alertUntrackedShippingOrder')}
            img={imgDeliveryNotFollow}
          />
          {isSmsEnabled &&
            ((isDeliverySmsAlertAllowed(AlertUntrackedShippingOrder) &&
              user.phone && (
                <LeftSwitch
                  onChange={(e) =>
                    onChange(
                      'alert',
                      AlertUntrackedShippingOrder,
                      SMS,
                      e.target.checked,
                    )
                  }
                  checked={isActivated(
                    'alert',
                    AlertUntrackedShippingOrder,
                    SMS,
                  )}
                  className="switchIconInput styleLabelInput"
                  disabled={
                    !isDeliverySmsAlertAllowed(AlertUntrackedShippingOrder)
                  }
                />
              )) || (
              <SwitchTooltip
                text={getTooltipText(AlertUntrackedShippingOrder)}
                placement="left"
              >
                <LeftSwitch
                  onChange={(e) =>
                    onChange(
                      'alert',
                      AlertUntrackedShippingOrder,
                      SMS,
                      e.target.checked,
                    )
                  }
                  checked={isActivated(
                    'alert',
                    AlertUntrackedShippingOrder,
                    SMS,
                  )}
                  className="switchIconInput styleLabelInput"
                  disabled={true}
                />
              </SwitchTooltip>
            ))}
          <Switch
            onChange={(e) =>
              onChange(
                'alert',
                AlertUntrackedShippingOrder,
                Email,
                e.target.checked,
              )
            }
            checked={isActivated('alert', AlertUntrackedShippingOrder, Email)}
            className="switchIconInput styleLabelInput"
          />
        </div>
        {/* Deviated some */}
        <div className="testgrid">
          <AlertTitle
            text={t('alert:delivery.alertDeviated')}
            img={imgDeviated}
          />
          {isSmsEnabled &&
            ((isDeliverySmsAlertAllowed(AlertShippingOrderDeviated) &&
              user.phone && (
                <LeftSwitch
                  onChange={(e) =>
                    onChange(
                      'alert',
                      AlertShippingOrderDeviated,
                      SMS,
                      e.target.checked,
                    )
                  }
                  checked={isActivated(
                    'alert',
                    AlertShippingOrderDeviated,
                    SMS,
                  )}
                  className="switchIconInput styleLabelInput"
                  disabled={
                    !isDeliverySmsAlertAllowed(AlertShippingOrderDeviated)
                  }
                />
              )) || (
              <SwitchTooltip
                text={getTooltipText(AlertShippingOrderDeviated)}
                placement="left"
              >
                <LeftSwitch
                  onChange={(e) =>
                    onChange(
                      'alert',
                      AlertShippingOrderDeviated,
                      SMS,
                      e.target.checked,
                    )
                  }
                  checked={isActivated(
                    'alert',
                    AlertShippingOrderDeviated,
                    SMS,
                  )}
                  className="switchIconInput styleLabelInput"
                  disabled={true}
                />
              </SwitchTooltip>
            ))}
          <Switch
            onChange={(e) =>
              onChange(
                'alert',
                AlertShippingOrderDeviated,
                Email,
                e.target.checked,
              )
            }
            checked={isActivated('alert', AlertShippingOrderDeviated, Email)}
            className="switchIconInput styleLabelInput"
          />
        </div>
        <HelpText>{t('alert:delivery.alertDeviatedInfo')}</HelpText>
        {/* Likely deviated */}
        {user?.company?.likely_deviated_max > 0 && (
          <>
            <div className="testgrid">
              <AlertTitle
                text={t('alert:delivery.alertLikelyDeviated')}
                img={imgDeviated}
              />
              {isSmsEnabled &&
                ((isDeliverySmsAlertAllowed(AlertShippingOrderLikelyDeviated) &&
                  user.phone && (
                    <LeftSwitch
                      onChange={(e) =>
                        onChange(
                          'alert',
                          AlertShippingOrderLikelyDeviated,
                          SMS,
                          e.target.checked,
                        )
                      }
                      checked={isActivated(
                        'alert',
                        AlertShippingOrderLikelyDeviated,
                        SMS,
                      )}
                      className="switchIconInput styleLabelInput"
                      disabled={
                        !isDeliverySmsAlertAllowed(
                          AlertShippingOrderLikelyDeviated,
                        )
                      }
                    />
                  )) || (
                  <SwitchTooltip
                    text={getTooltipText(AlertShippingOrderLikelyDeviated)}
                    placement="left"
                  >
                    <LeftSwitch
                      onChange={(e) =>
                        onChange(
                          'alert',
                          AlertShippingOrderLikelyDeviated,
                          SMS,
                          e.target.checked,
                        )
                      }
                      checked={isActivated(
                        'alert',
                        AlertShippingOrderLikelyDeviated,
                        SMS,
                      )}
                      className="switchIconInput styleLabelInput"
                      disabled={true}
                    />
                  </SwitchTooltip>
                ))}
              <Switch
                onChange={(e) =>
                  onChange(
                    'alert',
                    AlertShippingOrderLikelyDeviated,
                    Email,
                    e.target.checked,
                  )
                }
                checked={isActivated(
                  'alert',
                  AlertShippingOrderLikelyDeviated,
                  Email,
                )}
                className="switchIconInput styleLabelInput"
              />
            </div>
            <HelpText>{t('alert:delivery.alertLikelyDeviatedInfo')}</HelpText>
          </>
        )}
      </PanelContainer>
    </Panel>
  );
};

export { PanelAlertDelivery };
