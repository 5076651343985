import { gql } from '@apollo/client';

const GET_SITE_TYPE = gql`
    query GetSiteType($id: ID!) {
        site_type(id: $id) {
            id
            name
        }
    }
`;

const GET_SITE_TYPES = gql`
    query GetSiteTypes($first: Int, $page: Int, $search: Mixed, $orderBy: [QuerySiteTypesOrderByRelationOrderByClause!]) {
        site_types(first: $first, page: $page, orderBy: $orderBy, where: {
            OR: [
                {
                    column: "id"
                    operator: EQ
                    value: $search
                }
                {
                    column: "name"
                    operator: LIKE
                    value: $search
                }
            ]
        }) {
            paginatorInfo {
                total
                currentPage
                lastPage
                perPage
            }
            data {
                id
                name
                sites {
                    paginatorInfo {
                        total
                    }
                }
            }
        }
    }
`;

const CREATE_SITE_TYPE = gql`
    mutation CreateSiteType($name: String) {
        createSiteType(input: {name: $name}) {
            id
        }
    }
`;

const UPDATE_SITE_TYPE = gql`
    mutation UpdateSiteType($id: ID!, $name: String) {
        updateSiteType(input: {id: $id, name: $name}) {
            id
        }
    }
`;

const DELETE_SITE_TYPE = gql`
    mutation DeleteSiteType($id: ID!) {
        deleteSiteType(id: $id) {
            id
        }
    }
`;

export {
  GET_SITE_TYPE,
  GET_SITE_TYPES,
  CREATE_SITE_TYPE,
  UPDATE_SITE_TYPE,
  DELETE_SITE_TYPE,
};
