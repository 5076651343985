import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, generatePath } from 'react-router-dom';

import { Button, Checkbox, Select, TextField, TitleIcon } from 'front-library';
import NutIcon from 'front-library/build/img/icon-nut-green.svg';
import PreferenceGreenIcon from 'front-library/build/img/icon-preference-green.svg';
import UserIcon from 'front-library/build/img/icon-user-green.svg';
import { ReactComponent as WarningIcon } from 'front-library/build/img/warning-icon.svg';

import { routes } from '../../../routes';
import { Password } from '../../pages/Users/Password';

import { getErrorText } from '../../../helpers';
import {
  canSeeField,
  canSeeSuperAdminOption,
  defaultFormValuesByRole,
  getRoleFieldOptions,
  hasRole,
  roleEnum,
} from '../../../helpers/form/roles';
import { Form } from '../../atoms/Form/Form';
import { Autocomplete } from '../../molecules/Autocomplete/Autocomplete';
import { GET_BRANCHES } from '../../molecules/Autocomplete/graphql';
import { FeatureFlagField } from './FeatureFlagField';
import { SmsAlertField } from './SmsAlertField';
import {
  Badge,
  DashedBorder,
  EditPasswordContainer,
  FeatureFlagContainer,
  GridContainer,
  HelpToken,
  Label,
  PasswordButton,
  TitleCheckboxContainer,
  TitleIconContainer,
  WarningContainer,
} from './styled-components';

const UserForm = ({
  user,
  formData,
  setFormData,
  token,
  action,
  automations,
  currentUser,
  generateToken,
  errors,
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const roleFieldOptions = getRoleFieldOptions(t);

  const pages = useMemo(
    () => [
      { title: t('user:form.map'), value: 'map' },
      { title: t('user:form.shippingOrders'), value: 'ShippingOrders' },
    ],
    [t],
  );

  const languages = useMemo(
    () => [
      { title: t('user:form.en'), value: 'en' },
      { title: t('user:form.fr'), value: 'fr' },
      { title: t('user:form.es'), value: 'es' },
    ],
    [t],
  );

  // @TODO remove this useEffect, it should be handled by the router
  useEffect(() => {
    if (action && action === 'password') {
      setShowModal(true);
    }
  }, [action]);

  const onRoleChange = (selectedRole) => {
    // Some roles hide specific field, so we apply default values for them
    const defaultValuesForRole = defaultFormValuesByRole?.[selectedRole] ?? {};

    // Selecting one role toggle the other ones, we remove then add
    const roleOptionNames = roleFieldOptions.map((option) => option.value);
    const roles = formData.roles.filter(
      (roleName) => !roleOptionNames.includes(roleName),
    );

    setFormData({
      ...formData,
      ...defaultValuesForRole,
      roles: [...roles, selectedRole],
      selectedRole,
    });
  };

  const isUpgradingFromObserver = () => {
    const userInitialRoles = user.roles.map((role) => role.name);
    return (
      hasRole(roleEnum.observer, userInitialRoles) &&
      !hasRole(roleEnum.observer, formData.roles)
    );
  };

  const renderTextField = (field, type = 'text', required = true) => {
    const label = t(`user:form.${camelCase(field)}`);

    return (
      <TextField
        key={field}
        errorText={getErrorText(errors, field, label)}
        label={label}
        name={field}
        onChange={(value) => setFormData({ ...formData, [field]: value })}
        required={required}
        value={formData[field]}
        type={type}
      />
    );
  };

  return (
    <>
      <Routes>
        {/* @TODO provide the element to Route */}
        <Route
          path={generatePath(routes.administrationPasswordEdition.path, {
            id: formData?.id,
          })}
        />
      </Routes>

      <Form onCancel={onCancel} onSubmit={onSubmit}>
        <div>
          <TitleIconContainer>
            <TitleIcon text={t('user:form.information')} img={UserIcon} />
          </TitleIconContainer>
          {/* Surname */}
          {renderTextField('surname')}
        </div>
        {['name', 'username', 'email'].map((field) =>
          renderTextField(field, 'text'),
        )}

        {renderTextField('phone', 'text', false)}

        {/* Branches */}
        {currentUser.company.enable_branch && (
          <Autocomplete
            errorText={getErrorText(
              errors,
              'branches.sync',
              t('user:form.branches'),
            )}
            label={t('user:form.branches')}
            multiple={true}
            onChange={(branches) => setFormData({ ...formData, branches })}
            query={GET_BRANCHES}
            value={formData.branches}
            disabled={hasRole(roleEnum.operator, currentUser.rolesName)}
          />
        )}

        {/* Roles */}
        {
          <Select
            errorText={getErrorText(errors, 'roles', t('user:form.role'))}
            label={t('user:form.role')}
            onChange={onRoleChange}
            options={roleFieldOptions}
            required={true}
            value={formData.selectedRole}
            disabled={hasRole(roleEnum.operator, currentUser.rolesName)}
          />
        }

        {/* Edit user password */}
        {formData.id &&
          formData.selectedRole &&
          canSeeField(formData.selectedRole, 'password') &&
          !isUpgradingFromObserver() && (
            <EditPasswordContainer>
              <PasswordButton
                onClick={() => setShowModal(true)}
                text={t('user:form.changePass')}
                variant="green"
              />

              {showModal && (
                <Password
                  userId={formData?.id}
                  sameUser={formData?.id === currentUser.id}
                  onSave={() => null}
                  onClose={() => setShowModal(false)}
                />
              )}
            </EditPasswordContainer>
          )}

        {/* Create user password */}
        {((!formData.id &&
          formData.selectedRole &&
          canSeeField(formData.selectedRole, 'password')) ||
          (formData.id && isUpgradingFromObserver())) && (
          <GridContainer>
            {renderTextField('password', 'password')}
            {renderTextField('passwordConfirmation', 'password')}

            <Checkbox
              label={t('user:form.force')}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  isForcedPasswordUpdate: !!e.target.checked,
                })
              }
              checked={formData.isForcedPasswordUpdate}
            />
          </GridContainer>
        )}

        {formData.selectedRole &&
          !hasRole(roleEnum.operator, currentUser.rolesName) && (
            <>
              <DashedBorder />
              <TitleIconContainer>
                <TitleIcon text={t('user:form.optionSection')} img={NutIcon} />
              </TitleIconContainer>

              <FeatureFlagContainer>
                {/* Options */}
                <TitleCheckboxContainer>
                  <Label>{t('user:form.functionalities')}</Label>

                  <FeatureFlagField
                    currentUser={currentUser}
                    formData={formData}
                    setFormData={setFormData}
                  />
                </TitleCheckboxContainer>
              </FeatureFlagContainer>
            </>
          )}

        {/* SMS alerts authorizations */}
        {hasRole(roleEnum.sms, formData.roles) &&
          canSeeSuperAdminOption(currentUser, 'is_sms_enabled') && (
            <>
              <DashedBorder />

              <SmsAlertField
                currentUser={currentUser}
                automations={automations}
                formData={formData}
                setFormData={setFormData}
              />
            </>
          )}

        {formData.selectedRole && (
          <>
            <DashedBorder />

            <TitleIconContainer>
              <TitleIcon
                text={t('user:form.preference')}
                img={PreferenceGreenIcon}
              />
            </TitleIconContainer>

            {/* Page / language / branches */}
            {formData.selectedRole &&
              canSeeField(formData.selectedRole, 'homepage') && (
                <Select
                  errorText={getErrorText(errors, 'page', t('user:form.page'))}
                  label={t('user:form.page')}
                  onChange={(page) =>
                    setFormData({ ...formData, page: page ? page : 'map' })
                  }
                  options={pages}
                  required={true}
                  value={formData.page}
                />
              )}

            <Select
              errorText={getErrorText(errors, 'lang', t('user:form.lang'))}
              label={t('user:form.language')}
              onChange={(language) =>
                setFormData({
                  ...formData,
                  language: language ? language : 'fr',
                })
              }
              options={languages}
              required={true}
              value={formData.language}
            />

            {/* Token */}
            {formData.id &&
              hasRole(roleEnum.apiAdmin, formData.roles) &&
              formData.selectedRole &&
              canSeeField(formData.selectedRole, 'api_token') && (
                <>
                  <div>
                    <Label>{t('user:form.token')}</Label>
                    <Badge>{token ? token : t('user:form.noToken')}</Badge>
                    <WarningContainer>
                      <WarningIcon />
                      {token && (
                        <HelpToken>{t('user:form.tokenWarning')}</HelpToken>
                      )}
                    </WarningContainer>
                  </div>
                  <Button
                    onClick={generateToken}
                    text={t('user:form.generateToken')}
                    variant="green"
                  />
                </>
              )}
          </>
        )}
      </Form>
    </>
  );
};

UserForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { UserForm };
