import { gql } from '@apollo/client';

const GET_MAG_DATAS = gql`
    query GetMagDatas($first: Int, $page: Int, $search: Mixed, $mag_id: Mixed, $orderBy: [QueryAllMagDatasOrderByRelationOrderByClause!]) {
        all_mag_datas(first: $first, page: $page, orderBy: $orderBy, where: {
            AND: [
                {
                    column: "mag_id"
                    operator: EQ
                    value: $mag_id
                }
                {
                    OR: [
                        {
                            column: "bat_lvl"
                            operator: EQ
                            value: $search
                        }
                        {
                            column: "rssi"
                            operator: EQ
                            value: $search
                        }
                        {
                            column: "accuracy"
                            operator: EQ
                            value: $search
                        }
                        {
                            column: "temperature"
                            operator: EQ
                            value: $search
                        }
                        {
                            column: "device_timestamp"
                            operator: LIKE
                            value: $search
                        }
                        {
                            HAS: {
                                relation: "geolocation_source"
                                condition: {
                                    column: "name"
                                    operator: LIKE
                                    value: $search
                                }
                            }
                        }
                    ]
                }
            ]
        }) {
            paginatorInfo {
                total
                currentPage
                lastPage
                perPage
            }
            data {
                id
                mag_id
                created_at
                timestamp: device_timestamp
                sites {
                    data {
                        name
                    }
                }
                geolocation_source { name }
                bat_lvl
                rssi
                accuracy
                temperature
                json
            }
        }
    }
`;

const GET_MAG_DATA = gql`
    query GetMagData($id: ID!) {
        mag_data(id: $id) {
            mag_id
            json    
        }
    }
`;

export { GET_MAG_DATAS, GET_MAG_DATA };
