import PropTypes from 'prop-types';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { MeContext } from '../../../contexts/MeContext';

let refreshInterval = null;

/**
 * This component is used to auto refresh its child
 * component, using the company setting as interval.
 */
const AutoRefresh = ({ children }) => {
  const { me: user } = useContext(MeContext);
  const [renderKey, setRenderKey] = useState(0);

  const resetInterval = useCallback(() => {
    clearInterval(refreshInterval);

    refreshInterval = setInterval(
      () => setRenderKey((rk) => rk + 1),
      (user?.company?.auto_refresh_interval || 60) * 60 * 1000,
    );
  }, [user]);

  useEffect(() => {
    resetInterval();

    document.addEventListener('click', resetInterval);
    document.addEventListener('keypress', resetInterval);

    return () => {
      clearInterval(refreshInterval);
      document.removeEventListener('click', resetInterval);
      document.removeEventListener('keypress', resetInterval);
    };
  }, [resetInterval]);

  return <Fragment key={renderKey}>{children}</Fragment>;
};

AutoRefresh.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AutoRefresh };
