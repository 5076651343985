import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BtnInATab,
  ErrorMessage,
  Select,
  Switch,
  TextField,
  TitleIcon,
} from 'front-library';
import IconAddDisabled from 'front-library/build/img/icon-add-disabled.svg';
import IconAddGreen from 'front-library/build/img/icon-add-green.svg';
import iconBell from 'front-library/build/img/icon-bell.svg';
import iconNutPrimary from 'front-library/build/img/icon-nut-primary.svg';

import uniqueId from 'lodash/uniqueId';
import { getErrorText } from '../../../helpers';
import { Form } from '../../atoms/Form/Form';
import { Modal } from '../../molecules/Modal/Modal';
import { Action } from './Action';
import { Condition } from './Condition';
import {
  NameContainer,
  NotificationContainer,
  PaddingContainer,
} from './styled-components';
import {
  ASSET_CONDITIONS,
  CONDITIONS,
  INTERVAL_CONDITIONS,
  MODEL_ASSET,
  MODEL_SHIPPING_ORDER,
  MODEL_SITE,
  POSSIBLE_MODEL_CONDITIONS_CHOICES,
  RELATIONAL_CONDITIONS,
  SITE_CONDITIONS,
  mapActionsToForm,
  mapAutomatedActionToGraphql,
  mapConditionsToForm,
} from './utils';

const AutomatedActionsModal = ({
  selectedAutomatedAction,
  actionsConditionsOptions,
  onSave,
  onClose,
}) => {
  const { t } = useTranslation();

  const relationalConditionChoices = useMemo(
    () => [
      {
        value: CONDITIONS.assetType,
        title: t(
          'automatedActions:form.relationalConditionsTypeOptions.byAssetType',
        ),
      },
      {
        value: CONDITIONS.assetState,
        title: t(
          'automatedActions:form.relationalConditionsTypeOptions.byAssetState',
        ),
      },
      {
        value: CONDITIONS.siteType,
        title: t(
          'automatedActions:form.relationalConditionsTypeOptions.bySiteType',
        ),
      },
      {
        value: CONDITIONS.site,
        title: t(
          'automatedActions:form.relationalConditionsTypeOptions.bySite',
        ),
      },
    ],
    [t],
  );

  const siteConditionsTranslations = `
    "${t('automatedActions:form.valueConditionTypeOptions.minAssetOnSite')}",
    "${t('automatedActions:form.valueConditionTypeOptions.maxAssetOnSite')}" or
    "${t('automatedActions:form.valueConditionTypeOptions.maxValueOnSite')}"
  `;

  const assetConditionsTranslations = `
    "${t('automatedActions:form.conditionsTypeOptions.assetSiteEnter')}",
    "${t('automatedActions:form.conditionsTypeOptions.assetSiteLeave')}",
    "${t('automatedActions:form.valueConditionTypeOptions.assetStateExpired')}" or
    "${t('automatedActions:form.valueConditionTypeOptions.assetOnSiteSince')}"
  `;

  const actionShippingOrderStatuses = useMemo(
    () =>
      actionsConditionsOptions.ShippingOrderStatus.data.filter((s) =>
        ['delivered', 'canceled', 'delivery_confirmed', 'closed'].includes(
          s.title,
        ),
      ),
    [actionsConditionsOptions],
  );

  const models = useMemo(
    () => [
      {
        title: t('automatedActions:form.models.asset'),
        value: MODEL_ASSET,
      },
      {
        title: t('automatedActions:form.models.shippingOrder'),
        value: MODEL_SHIPPING_ORDER,
      },
      {
        title: t('automatedActions:form.models.site'),
        value: MODEL_SITE,
      },
    ],
    [t],
  );

  const branchNotifications = useMemo(
    () => [
      {
        title: t('automatedActions:form.branchNotificationOptions.all'),
        value: 1,
      },
      {
        title: t('automatedActions:form.branchNotificationOptions.linked'),
        value: 2,
      },
    ],
    [t],
  );

  const [errorMessage, setErrorMessage] = useState('');
  const [name, setName] = useState(selectedAutomatedAction?.name || '');
  const [model, setModel] = useState(selectedAutomatedAction?.model || '');
  const [branchNotification, setBranchNotification] = useState(
    selectedAutomatedAction?.branch_notification || '',
  );
  const [notify, setNotify] = useState(
    selectedAutomatedAction?.notify || false,
  );
  const [conditions, setConditions] = useState(
    mapConditionsToForm(
      selectedAutomatedAction?.relational_conditions?.data,
      selectedAutomatedAction?.value_conditions?.data,
    ),
  );
  const [actions, setActions] = useState(
    mapActionsToForm(selectedAutomatedAction?.actions?.data),
  );
  const [errors, setErrors] = useState({});

  const checkAutomation = useCallback(() => {
    if ('' === name) {
      setErrorMessage(t('automatedActions:form.errors.name'));

      return false;
    }

    if ('' === model) {
      setErrorMessage(t('automatedActions:form.errors.model'));

      return false;
    }

    setErrorMessage('');

    return true;
  }, [model, name, t]);

  const checkRelationCondition = useCallback(
    (
      condition,
      conditions,
      modelConditions,
      typeChoices,
      conditionsTranslations,
    ) => {
      if (!conditions.find((c) => modelConditions.includes(c.type))) {
        const errorMessage = `"${
          typeChoices.find((choice) => choice.value === condition.type).title
        }" 
        ${t('automatedActions:form.errors.paired')} ${conditionsTranslations}`;

        setErrorMessage(errorMessage);

        return false;
      }

      return true;
    },
    [t],
  );

  const checkConditions = useCallback(() => {
    if (!conditions.length || !conditions[0].type) {
      setErrorMessage(t('automatedActions:form.errors.condition'));

      return false;
    }

    const invalidCondition = conditions.find(
      (c) =>
        (c.isRelational && !c.values.length) ||
        (c.isValue && (null === c.value || '' === c.value)) ||
        (INTERVAL_CONDITIONS.includes(c.type) &&
          (null === c.value || '00:00:00' === c.value)),
    );

    if (invalidCondition) {
      setErrorMessage(t('automatedActions:form.errors.value'));

      return false;
    }

    // Check if a relational condition has been chosen and if it is the only one what is not allowed
    const condition = conditions.find((c) =>
      RELATIONAL_CONDITIONS.includes(c.type),
    );
    if (condition) {
      if (
        MODEL_SITE === model &&
        !checkRelationCondition(
          condition,
          conditions,
          SITE_CONDITIONS,
          relationalConditionChoices,
          siteConditionsTranslations,
        )
      ) {
        return false;
      }

      if (
        MODEL_ASSET === model &&
        !checkRelationCondition(
          condition,
          conditions,
          ASSET_CONDITIONS,
          relationalConditionChoices,
          assetConditionsTranslations,
        )
      ) {
        return false;
      }
    }

    return true;
  }, [
    conditions,
    t,
    model,
    checkRelationCondition,
    relationalConditionChoices,
    siteConditionsTranslations,
    assetConditionsTranslations,
  ]);

  const isSaveEnabled = useMemo(() => {
    return checkAutomation() && checkConditions();
  }, [checkAutomation, checkConditions]);

  const updateModel = useCallback(
    (newModel) => {
      if (MODEL_SHIPPING_ORDER !== newModel) {
        setActions([]);
      }

      setModel(newModel);
      setConditions(
        conditions.filter((c) =>
          POSSIBLE_MODEL_CONDITIONS_CHOICES[newModel].includes(c.type),
        ),
      );
    },
    [conditions],
  );

  const addCondition = () => setConditions([...conditions, {}]);

  const removeCondition = (index) => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1);
    setConditions(newConditions);
  };

  const updateCondition = (index, condition) => {
    const newConditions = [...conditions];
    newConditions[index] = condition;
    setConditions(newConditions);
  };

  const addAction = () =>
    setActions([
      ...actions,
      {
        uniqueId: uniqueId(),
      },
    ]);

  const removeAction = (index) => {
    const newActions = [...actions];
    newActions.splice(index, 1);
    setActions(newActions);
  };

  const updateAction = (index, action) => {
    const newActions = [...actions];
    newActions[index] = action;
    setActions(newActions);
  };

  return (
    <Modal
      onClose={onClose}
      title={t('automatedActions:automatedActionCreation')}
    >
      <Form
        disableSubmit={!isSaveEnabled}
        onCancel={onClose}
        onSubmit={() =>
          onSave(
            mapAutomatedActionToGraphql({
              name,
              model,
              notify,
              branchNotification,
              conditions,
              actions,
            }),
            onClose,
            setErrors,
          )
        }
      >
        <NameContainer>
          <TitleIcon
            img={iconNutPrimary}
            text={t('automatedActions:form.name')}
          />
          {/* Name */}
          <PaddingContainer>
            <TextField
              errorText={getErrorText(
                errors,
                'name',
                t('automatedActions:form.name'),
              )}
              label={t('automatedActions:form.name')}
              onChange={(value) => setName(value)}
              required={true}
              value={name}
            />
          </PaddingContainer>
          {/* Model */}
          <PaddingContainer>
            <Select
              errorText={getErrorText(
                errors,
                'model',
                t('automatedActions:form.model'),
              )}
              label={t('automatedActions:form.model')}
              onChange={(value) => updateModel(value)}
              options={models}
              required={true}
              value={model}
            />
          </PaddingContainer>
        </NameContainer>

        {/* Conditions */}
        {conditions.map((condition, index) => (
          <Condition
            key={`${index}_${condition.type}`}
            condition={condition}
            index={index}
            onRemove={() => removeCondition(index)}
            onUpdate={(c) => updateCondition(index, c)}
            conditionsOptions={actionsConditionsOptions}
            model={model}
            conditions={conditions}
          />
        ))}
        <BtnInATab
          className={
            '' === model
              ? 'btn-disabled-special'
              : 'btn-add-special btn-condition'
          }
          imgSrc={'' === model ? IconAddDisabled : IconAddGreen}
          text={t('automatedActions:form.addCondition')}
          disabled={true}
          onClick={addCondition}
        />

        {/* Actions */}
        {actions.map((action, index) => (
          <Action
            key={action.uniqueId}
            index={index}
            action={action}
            onRemove={() => removeAction(index)}
            onUpdate={(a) => updateAction(index, a)}
            actionsOptions={actionsConditionsOptions}
            statuses={actionShippingOrderStatuses.map((s) => s.value)}
          />
        ))}
        {MODEL_SHIPPING_ORDER === model && (
          <BtnInATab
            className={
              '' === model
                ? 'btn-disabled-special'
                : 'btn-add-special btn-action'
            }
            imgSrc={'' === model ? IconAddDisabled : IconAddGreen}
            text={t('automatedActions:form.addAction')}
            disabled={'' === model}
            onClick={addAction}
          />
        )}

        {/* Notification */}
        <NotificationContainer>
          <TitleIcon img={iconBell} text={t('automatedActions:notification')} />
          <PaddingContainer>
            <Switch
              className="switchStandard"
              labelSwichtTitle={t('automatedActions:form.addAsNotification')}
              checked={notify}
              onChange={(_, data) => setNotify(data)}
            />
          </PaddingContainer>
          {/* Branches */}
          {notify && MODEL_SHIPPING_ORDER === model && (
            <PaddingContainer>
              <Select
                label={t('automatedActions:form.branchNotification')}
                onChange={(value) => setBranchNotification(value)}
                options={branchNotifications}
                required={true}
                value={branchNotification}
              />
            </PaddingContainer>
          )}
        </NotificationContainer>

        {errorMessage && <ErrorMessage text={errorMessage} />}
      </Form>
    </Modal>
  );
};

export { AutomatedActionsModal };
