import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loading } from 'front-library';
import routeIcon from 'front-library/build/img/icon-route-orange.svg';
import arrow from 'front-library/build/img/single-left-arrow.svg';

import { getShippingOrderTranslate } from '../../../helpers';
import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { LocationMap } from '../../molecules/LocationMap';
import { MarkerMagma } from '../../molecules/LocationMap/Markers/MarkerMagma';
import { icons } from '../../molecules/LocationMap/icons';
import { mapGeofence } from '../../molecules/LocationMap/utils';
import { ExpiredPage } from './ExpiredPage';
import { Itinerary } from './Itinerary';
import { GET_SHIPPING_ORDER } from './graphql';

import { useParams } from 'react-router-dom';
import {
  BackButton,
  Container,
  Divider,
  FlexColumnSection,
  FlexSection,
  GridSection,
  Label,
  LabelWrapper,
  LocationMapContainer,
  MapButton,
  Section,
  Title,
  Value,
} from './styled-components';

const SmsLinkPage = () => {
  const { t } = useTranslation();
  const { id = null } = useParams();

  const { data, loading } = useQueryGraphQl(
    GET_SHIPPING_ORDER,
    {},
    { where: { column: 'shipping_orders.id', operator: 'EQ', value: id } },
  );
  const ongoingStatuses = ['new', 'delivery_in_progress', 'paired', 'on_site'];
  const [showMap, setShowMap] = useState(false);

  const currentCheckPoint = useMemo(() => {
    if (!data?.shipping_orders?.data?.[0]) {
      return;
    }

    const shippingOrder = data.shipping_orders.data[0];
    if (!shippingOrder.tour) {
      return shippingOrder.real_departure_timestamp === null
        ? {
            ...shippingOrder.departure_site,
            arrival_timestamp: shippingOrder.departure_timestamp,
          }
        : {
            ...shippingOrder.arrival_site,
            arrival_timestamp: shippingOrder.arrival_timestamp,
            human_eta: shippingOrder.human_eta,
          };
    }

    const shippingOrders = shippingOrder.tour.shipping_orders.data;
    const master = shippingOrders.find((so) => so.tour_order === null);

    if (master.real_departure_timestamp === null) {
      return {
        ...master.departure_site,
        arrival_timestamp: master.departure_timestamp,
      };
    }

    if (master.real_arrival_timestamp !== null) {
      return {
        ...master.arrival_site,
        arrival_timestamp: master.arrival_timestamp,
        human_eta: master.human_eta,
      };
    }

    const lastArrived = shippingOrders
      .filter((so) => so.real_arrival_timestamp !== null)
      .pop();

    if (!lastArrived) {
      const index = shippingOrders.findIndex((so) => so.id === master.id);
      const next = shippingOrders[index + 1];

      return {
        ...next.arrival_site,
        arrival_timestamp: next.arrival_timestamp,
        human_eta: next.human_eta,
      };
    }

    const index = shippingOrders.findIndex((so) => so.id === lastArrived.id);
    const next = shippingOrders[index + 1];

    return next
      ? {
          ...next.arrival_site,
          arrival_timestamp: next.arrival_timestamp,
          human_eta: next.human_eta,
        }
      : {
          ...master.arrival_site,
          arrival_timestamp: master.arrival_timestamp,
          human_eta: master.human_eta,
        };
  }, [data]);

  const shippingOrder = data?.shipping_orders?.data?.[0];
  const magDatas = shippingOrder?.asset_group?.mag_datas?.data;
  const latestMagData = shippingOrder?.asset_group?.latestMagData;

  const lastPosition = useMemo(() => {
    if (!magDatas) {
      return null;
    }

    return magDatas.length > 0 ? magDatas[magDatas.length - 1] : null;
  }, [magDatas]);

  const degradedPosition = useMemo(() => {
    if (!magDatas?.length || !shippingOrder?.mag_data_id_degraded_position) {
      return null;
    }

    return magDatas.find(
      (md) => md.id === shippingOrder.mag_data_id_degraded_position,
    );
  }, [magDatas, shippingOrder]);

  const getTime = (date) => {
    const duration = moment.duration(moment(date).diff(moment()));
    const months = Math.abs(duration.months());
    const days = Math.abs(duration.days());
    const hours = Math.abs(duration.hours());
    const minutes = Math.abs(duration.minutes());

    return `${
      ((months && `${months + t('smsLinkPage:month')}, `) || '') +
      ((days && `${days + t('smsLinkPage:day')}, `) || '') +
      hours +
      t('smsLinkPage:hour')
    }, ${minutes}${t('smsLinkPage:minute')}`;
  };

  /** Access to this page should not exceed 24h after the delivery is completed or cancelled */
  const shouldShowExpiredView = () => {
    const completedStatuses = ['delivered', 'delivery_confirmed'];
    const expiredStatuses = [...completedStatuses, 'canceled', 'closed'];

    if (
      !shippingOrder ||
      !expiredStatuses.includes(shippingOrder.shipping_order_status.name)
    ) {
      return false;
    }

    // If closed or cancelled we show expire view right away
    if (!completedStatuses.includes(shippingOrder.shipping_order_status.name)) {
      return moment(shippingOrder.updated_at).add(1, 'day').isBefore(); // Default to current time
    }

    // If completed then, we should show expire date after 24H
    return moment(shippingOrder.real_arrival_timestamp)
      .add(1, 'day')
      .isBefore(); // Default to current time
  };

  const getMagInfo = (map) => {
    if (!lastPosition || !latestMagData) {
      return null;
    }

    if (map) {
      return (
        <FlexColumnSection>
          <div>
            <LabelWrapper>
              <Label level="info">{t('smsLinkPage:lastMovement')}</Label>
            </LabelWrapper>
            {lastPosition && (
              <Value>{getTime(lastPosition.device_timestamp)}</Value>
            )}
          </div>
          <div>
            <LabelWrapper>
              <Label level="info">{t('smsLinkPage:lastCommunication')}</Label>
            </LabelWrapper>
            {latestMagData && (
              <Value>{getTime(latestMagData.created_at)}</Value>
            )}
          </div>
        </FlexColumnSection>
      );
    }

    return (
      <GridSection>
        <div>
          <LabelWrapper>
            <Label level="info">{t('smsLinkPage:lastMovement')}</Label>
          </LabelWrapper>
          {lastPosition && (
            <Value>{getTime(lastPosition.device_timestamp)}</Value>
          )}
        </div>
        <div>
          <LabelWrapper>
            <Label level="info">{t('smsLinkPage:lastCommunication')}</Label>
          </LabelWrapper>
          {latestMagData && <Value>{getTime(latestMagData.created_at)}</Value>}
        </div>
      </GridSection>
    );
  };

  const isLate = useMemo(() => {
    if (currentCheckPoint) {
      return (
        currentCheckPoint.arrival_timestamp &&
        currentCheckPoint.human_eta &&
        moment(currentCheckPoint.human_eta).diff(
          currentCheckPoint.arrival_timestamp,
        ) > 0
      );
    }
  }, [currentCheckPoint]);

  if ((!loading && !shippingOrder) || shouldShowExpiredView()) {
    return (
      <Container>
        <ExpiredPage />
      </Container>
    );
  }

  return (
    <div>
      {loading && <Loading />}

      {!loading && (
        <Container>
          {showMap && (
            <LocationMapContainer>
              <BackButton
                text={t('smsLinkPage:back')}
                imgSrc={arrow}
                onClick={() => setShowMap(!showMap)}
              />
              <LocationMap
                showControls={false}
                departureSite={{
                  ...shippingOrder.departure_site,
                  geofence: mapGeofence(shippingOrder.departure_site.geofence),
                }}
                arrivalSite={{
                  ...shippingOrder.arrival_site,
                  isDelivered: shippingOrder.real_arrival_timestamp !== null,
                  geofence: mapGeofence(shippingOrder.arrival_site.geofence),
                }}
                checkpoints={
                  shippingOrder.tour
                    ? shippingOrder.tour.shipping_orders.data
                        .filter((so) => so.tour_order !== null)
                        .map((so) => ({
                          ...so.arrival_site,
                          geofence: mapGeofence(so?.arrival_site?.geofence),
                          isDelivered: so.real_arrival_timestamp !== null,
                        }))
                    : []
                }
                routing={shippingOrder.asset_group?.mag_datas?.data || []}
                order={lastPosition}
                degradedPosition={degradedPosition}
              >
                {lastPosition && (
                  <MarkerMagma
                    position={
                      (degradedPosition && [
                        degradedPosition.latitude,
                        degradedPosition.longitude,
                      ]) || [lastPosition.latitude, lastPosition.longitude]
                    }
                    icon={icons.order}
                    title={getMagInfo(true)}
                  />
                )}
              </LocationMap>
            </LocationMapContainer>
          )}

          {!showMap && (
            <>
              <FlexSection>
                <div>
                  <LabelWrapper>
                    <Label level="info">{t('smsLinkPage:reference')}</Label>
                  </LabelWrapper>
                  <Value>{shippingOrder.reference}</Value>
                </div>
                <MapButton
                  text={t('smsLinkPage:map')}
                  imgSrc={routeIcon}
                  onClick={() => setShowMap(!showMap)}
                />
              </FlexSection>

              {/* Status */}
              <FlexSection>
                <div>
                  {ongoingStatuses.includes(
                    shippingOrder.shipping_order_status.name,
                  ) &&
                    currentCheckPoint.human_eta && (
                      <div>
                        <LabelWrapper>
                          <Label level="info">
                            {t('smsLinkPage:estimatedArrival')}
                          </Label>
                          <Label>({t('smsLinkPage:nextSite')})</Label>
                        </LabelWrapper>
                        <Value level={isLate ? 'danger' : 'success'}>
                          {currentCheckPoint.human_eta}
                        </Value>
                      </div>
                    )}
                  <LabelWrapper>
                    <Label>{t('smsLinkPage:status')}</Label>
                    <Label level="warning">
                      {getShippingOrderTranslate(
                        t,
                        shippingOrder.shipping_order_status.name,
                      )}
                    </Label>
                  </LabelWrapper>
                </div>
              </FlexSection>

              {/* Itinerary */}
              <Title>{t('smsLinkPage:route')}</Title>
              <Itinerary
                shippingOrder={shippingOrder}
                currentCheckPoint={currentCheckPoint}
              />

              {/* Mag */}
              <Divider />
              <Title>{t('smsLinkPage:mag')}</Title>
              {getMagInfo()}

              {/* Details */}
              <Divider />
              <Title>{t('smsLinkPage:details')}</Title>
              <Section>
                <div>
                  <LabelWrapper>
                    <Label level="info">{t('smsLinkPage:carrier')}</Label>
                  </LabelWrapper>
                  <Value>{shippingOrder.carrier.name}</Value>
                </div>
                <div>
                  <LabelWrapper>
                    <Label level="info">{t('smsLinkPage:comment')}</Label>
                  </LabelWrapper>
                  <Value>{shippingOrder.comment}</Value>
                </div>
              </Section>
            </>
          )}
        </Container>
      )}
    </div>
  );
};

SmsLinkPage.propTypes = {
  id: PropTypes.number,
};

export { SmsLinkPage };
