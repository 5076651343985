import { gql } from '@apollo/client';

const GET_ASSET_TYPE = gql`
    query GetAssetType($id: ID!) {
        asset_type(id: $id) {
            id
            name
            temperature_min
            temperature_max
            custom_parameters(first: 10000) {
                data {
                    id
                    pivot {
                        value
                    }
                }
            }
        }
    }
`;

const GET_ASSET_TYPES = gql`
    query AssetTypes($first: Int, $page: Int, $search: Mixed, $orderBy: [QueryAssetTypesOrderByOrderByClause!]) {
        asset_types(first: $first, page: $page, orderBy: $orderBy, where: {
            OR: [
                {
                    column: "id"
                    operator: EQ
                    value: $search
                }
                {
                    column: "name"
                    operator: LIKE
                    value: $search
                }
            ]
        }) {
            paginatorInfo {
                total
                currentPage
                lastPage
                perPage
            }
            data {
                id
                name
                temperature_min
                temperature_max
                assets {
                    paginatorInfo { total }
                }
            }
        }
    }
`;

const CREATE_ASSET_TYPE = gql`
    mutation CreateAssetType(
        $name: String
        $temperature_min: Int
        $temperature_max: Int
        $custom_parameters: UpdateParameterizableRelations
    ) {
        createAssetType(
            input: {
                name: $name
                temperature_min: $temperature_min
                temperature_max: $temperature_max
                custom_parameters: $custom_parameters
            }
        ) {
            id
        }
    }
`;

const UPDATE_ASSET_TYPE = gql`
    mutation UpdateAssetType(
        $id: ID!
        $name: String
        $temperature_min: Int
        $temperature_max: Int
        $custom_parameters: UpdateParameterizableRelations
    ) {
        updateAssetType(
            input: {
                id: $id
                name: $name
                temperature_min: $temperature_min
                temperature_max: $temperature_max
                custom_parameters: $custom_parameters
            }
        ) {
            id
        }
    }
`;

const DELETE_ASSET_TYPE = gql`
    mutation DeleteAsset($id: ID!) {
        deleteAssetType(id: $id) {
            id
        }
    }
`;

const GET_CUSTOM_PARAMETERS = gql`
    {
        custom_parameters(first: 10000, where: {
            column: "model"
            operator: EQ
            value: "App\\\\Models\\\\AssetType"
        }) {
            data {
                id
                name
                description
                model
                value_type
                asset_types_filters {
                    data { id }
                }
            }
        }
    }
`;

export {
  GET_ASSET_TYPE,
  GET_ASSET_TYPES,
  CREATE_ASSET_TYPE,
  UPDATE_ASSET_TYPE,
  DELETE_ASSET_TYPE,
  GET_CUSTOM_PARAMETERS,
};
