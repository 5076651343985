import styled from 'styled-components';

export const AuthContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 55% 45%;
`;

export const SideImage = styled.div`
    background-image: url(${({ background }) => background});
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
`;

export const ContentContainer = styled.div`
    height: 100vh;
    display: grid;
`;

export const Logo = styled.img`
    width: 155px;
    height: 43px;
`;

export const StatusPageContainer = styled.div`
    align-self: end;
    text-align: center;
    padding-bottom: 10px;
`;

export const ActionsContainer = styled.div`
    align-self: center;
    justify-self: center;
    display: grid;
    gap: 37px;
    justify-items: center;
`;
