import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MeContext } from '../../../contexts/MeContext';

import { Select, TextField } from 'front-library';

import { getErrorText } from '../../../helpers';
import { Form } from '../../atoms/Form/Form';
import { Autocomplete } from '../../molecules/Autocomplete/Autocomplete';
import {
  GET_ASSET_GROUPS,
  GET_ASSET_TYPES,
  GET_BRANCHES,
  GET_USERS,
} from '../../molecules/Autocomplete/graphql';
import { CustomParametersForm } from '../../organisms/CustomParameters/CustomParametersForm';
import { GET_MAGS } from '../../pages/Assets/graphql';

import { DashedBorder } from './styled-components';

const AssetForm = ({
  asset = null,
  assetStates,
  errors = {},
  formValues,
  setFormValues,
  customParameters,
  handleUpdateCustomParametersFormValue,
  customParametersFormValues,
  onSubmit,
  onCancel,
}) => {
  const { me: user } = useContext(MeContext);
  const { t } = useTranslation();

  return (
    <Form onSubmit={onSubmit} onCancel={onCancel}>
      {/* Mag ID */}
      <Autocomplete
        errorText={getErrorText(errors, 'mag.connect', 'Mag ID')}
        label={t('assets:magid')}
        onChange={(mag) => setFormValues({ ...formValues, mag })}
        query={GET_MAGS}
        queryVars={{ id: asset?.mag ? asset.mag.id : null }}
        value={formValues.mag}
      />

      {/* Branches */}
      {user.company.enable_branch && (
        <Autocomplete
          errorText={getErrorText(
            errors,
            'branches.sync',
            t('assets:form.branch'),
          )}
          label={t('assets:form.branches', { count: 1 })}
          multiple={true}
          onChange={(branches) => setFormValues({ ...formValues, branches })}
          placeholderTranslateKey="assets:form.branches"
          query={GET_BRANCHES}
          value={formValues.branches}
        />
      )}

      {/* Asset type */}
      <Autocomplete
        errorText={getErrorText(
          errors,
          'asset_type.connect',
          t('assets:form.type'),
        )}
        label={t('assets:form.type')}
        onChange={(assetType) => setFormValues({ ...formValues, assetType })}
        query={GET_ASSET_TYPES}
        required={true}
        value={formValues.assetType}
      />

      <DashedBorder />

      {/* State */}
      <Select
        errorText={getErrorText(
          errors,
          'state.connect',
          t('assets:form.asset_state'),
        )}
        label={t('assets:form.asset_state')}
        onChange={(state) => setFormValues({ ...formValues, state })}
        options={assetStates}
        required={true}
        value={formValues.state}
      />

      {/* Reference */}
      <TextField
        errorText={getErrorText(
          errors,
          'reference',
          t('assets:form.reference'),
        )}
        label={t('assets:form.reference')}
        onChange={(reference) => setFormValues({ ...formValues, reference })}
        required={true}
        value={formValues.reference}
      />

      {/* Buy value */}
      <TextField
        errorText={getErrorText(
          errors,
          'buy_value',
          t('assets:form.buy_value'),
        )}
        label={t('assets:form.buy_value')}
        onChange={(buyValue) => setFormValues({ ...formValues, buyValue })}
        step=".1"
        type="number"
        value={formValues.buyValue}
      />

      <DashedBorder />

      {/* Group */}
      <Autocomplete
        errorText={getErrorText(
          errors,
          'asset_group.connect',
          t('assets:form.asset_group'),
        )}
        label={t('assets:form.asset_group')}
        onChange={(assetGroup) => setFormValues({ ...formValues, assetGroup })}
        query={GET_ASSET_GROUPS}
        value={formValues.assetGroup}
      />

      {/* Responsable */}
      <Autocomplete
        errorText={getErrorText(
          errors,
          'responsibles',
          t('assets:form.responsible'),
        )}
        label={t('assets:form.responsible')}
        onChange={(responsible) =>
          setFormValues({ ...formValues, responsible })
        }
        query={GET_USERS}
        value={formValues.responsible}
      />
      {customParameters?.length > 0 && (
        <CustomParametersForm
          customParameters={customParameters}
          selectedAssetType={formValues?.asset_type?.connect}
          onUpdateValue={handleUpdateCustomParametersFormValue}
          values={customParametersFormValues}
        />
      )}
    </Form>
  );
};

AssetForm.propTypes = {
  asset: PropTypes.object,
  assetStates: PropTypes.arrayOf(PropTypes.object).isRequired,
  errors: PropTypes.object,
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  customParameters: PropTypes.array,
  handleUpdateCustomParametersFormValue: PropTypes.func,
  customParametersFormValues: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { AssetForm };
