import PropTypes from 'prop-types';
import React from 'react';
import { Circle } from 'react-leaflet';

const ErrorRadius = ({ coords, accuracy }) => (
  <Circle
    center={coords}
    radius={accuracy}
    fillColor="#C81D18"
    color="#C81D18"
    dashArray="10 10"
  />
);

ErrorRadius.propTypes = {
  coords: PropTypes.array.isRequired,
  accuracy: PropTypes.number.isRequired,
};

export { ErrorRadius };
