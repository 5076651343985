import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Button } from 'front-library';

import styled from 'styled-components';
import { Loading } from '../../../molecules/Loading/Loading';
import { Modal } from '../../../molecules/Modal/Modal';

const ModalBody = styled.div`
  display: flex;
  gap: 20px;
`;

const ExportModal = ({ xlsPath, csvPath, onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const downloadFile = useCallback(
    (url) => {
      setLoading(true);

      fetch(url)
        .then(async (res) => {
          const fileName = res.headers
            .get('Content-Disposition')
            .match(/filename="([^"]+)"/);
          const blob = await res.blob();
          const fileUrl = URL.createObjectURL(blob);
          FileSaver(fileUrl, fileName[1]);
        })
        .catch(() => toast.error(t('common:exportError')))
        .finally(() => setLoading(false));
    },
    [t],
  );

  return (
    <>
      {loading && <Loading />}
      <Modal onClose={onClose} size="small" title={t('common:export')}>
        <ModalBody>
          <Button
            onClick={() => downloadFile(xlsPath)}
            text={t('common:exportXls')}
            variant="green"
          />
          <Button
            onClick={() => downloadFile(csvPath)}
            variant="green"
            text={t('common:exportCsv')}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

ExportModal.propTypes = {
  xlsPath: PropTypes.string,
  csvPath: PropTypes.string,
  onClose: PropTypes.func,
};

export { ExportModal };
