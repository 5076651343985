import { gql } from '@apollo/client';

const GET_MAP_SITES = `query GetMapSites($first: Int, $page: Int, $company_id: Mixed) {
        sites(first: $first, page: $page, company_id: $company_id, where: {
            AND: [
                {
                    HAS: {
                        relation: "company"
                        condition: {
                            column: "id"
                            operator: EQ
                            value: $company_id
                        }
                    }
                },
                {
                    HAS: {
                        relation: "site_type"
                        condition: {
                            column: "name"
                            operator: NEQ
                            value: "OFF_SITE"
                        }
                    }
                }
            ]
        }) {
            paginatorInfo {
                count
                currentPage
                lastPage
                total
            }
            data {
                id
                name
                latitude
                longitude
                company {
                    id
                }
                site_type {
                    id
                }
                branches {
                    id
                }
            }
        }
    }
`;

const GET_MAP_ASSETS = `query GetMapAssets($first: Int, $page: Int, $company_id: Mixed) {
        assets(first: $first, page: $page, company_id: $company_id, where: {
            AND: [
                {
                    HAS: {
                        relation: "company"
                        condition: {
                            column: "id"
                            operator: EQ
                            value: $company_id
                        }
                    }
                }
                {
                    HAS: {
                        relation: "mag"
                        condition: {
                            AND: [
                                {
                                    column: "last_accepted_latitude"
                                    operator: NEQ
                                    value: null
                                }
                                {
                                    column: "last_accepted_longitude"
                                    operator: NEQ
                                    value: null
                                }
                            ]
                        }
                    }
                }
            ]

        }) {
            paginatorInfo {
                count
                currentPage
                lastPage
                total
            }
            data {
                id
                reference
                company {
                    id
                }
                asset_type {
                    id
                    name
                }
                state {
                    id
                }
                branches(first: $first) {
                    data {
                        id
                    }
                }
                mag {
                    mag_id
                    last_accepted_latitude
                    last_accepted_longitude
                    last_accepted_device_timestamp
                    last_accepted_accuracy
                }
                asset_group {
                    id
                }
                date_enter_site
                sites(first: $first) {
                    data {
                        id
                        site_type {
                            name
                        }
                    }
                }
            }
        }
    }
`;

const GET_MAP_SHIPPING_ORDERS = gql`
    query GetMapShippingOrders {
        shipping_orders(
            first: 100000
            where: {
                HAS: {
                    relation: "shipping_order_status"
                    condition: {
                        column: "name"
                        operator: IN
                        value: [
                            "paired"
                            "on_site"
                            "delivery_in_progress"
                            "loaded"
                            "arrived_on_delivery_site"
                        ]
                    }
                }
                AND: [
                    {
                        HAS: {
                            relation: "asset_group.assets.mag"
                            condition: { column: "id", operator: IS_NOT_NULL }
                        }
                    }
                    {
                        HAS: {
                            relation: "mag_datas"
                        }
                    }
                ]
            }
        ) {
            data {
                id
                reference
                asset_group {
                    id
                    assets(first: 100000) {
                        data {
                            id
                            mag {
                                last_accepted_latitude
                                last_accepted_longitude
                                last_accepted_device_timestamp
                                last_accepted_accuracy
                            }
                        }
                    }
                    branches(first: 100000) {
                        data {
                            id
                        }
                    }
                }
                degraded_mag_datas {
                    latitude
                    longitude
                }
            }
        }
    }
`;

export { GET_MAP_SITES, GET_MAP_ASSETS, GET_MAP_SHIPPING_ORDERS };
