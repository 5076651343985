import { Autocomplete } from 'front-library';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mapSearchData } from './utils';

const Search = ({
  assets = [],
  defaultOpen = false,
  onSelect,
  sites = [],
  shippingOrders = [],
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');

  const options = useMemo(
    () => mapSearchData(sites, assets, shippingOrders),
    [assets, sites, shippingOrders],
  );

  const filteredOptions = useMemo(
    () =>
      [...options]
        .filter((o) => o.title.toUpperCase().includes(inputValue.toUpperCase()))
        .slice(0, 20),
    [options, inputValue],
  );

  return (
    <Autocomplete
      defaultOpen={defaultOpen}
      hideCheckboxes={true}
      onChange={onSelect}
      onInputChange={setInputValue}
      options={filteredOptions}
      placeholder={t('mapFilters:search')}
    />
  );
};

Search.propTypes = {
  assets: PropTypes.array.isRequired,
  defaultOpen: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  sites: PropTypes.array.isRequired,
  shippingOrders: PropTypes.array.isRequired,
};

export { Search };
