import { Grid, Select } from 'front-library';
import styled from 'styled-components';

export const ImportTitle = styled.h1`
    box-shadow: 0 0 8px red;
    border-radius: 5px;
    padding: 5px 10px;
    display: inline-block;
`;

export const Container = styled.div`
    display: grid;
    gap: 15px;
    padding: 20px;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
`;

export const ImportSelect = styled(Select)`
    flex: 1;
`;

export const ImportButtonTitle = styled.div`
    padding-bottom: 2px;
    color: #6ca6ab;
`;

export const ImportLink = styled.a`
    outline: none !important;
    display: block;
    height: 28px;
    padding: 4px 15px 0;
    font-size: 0.85em;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgb(0 43 43 / 15%);
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
    color: ${(props) => (props.disabled ? '#b3b3b3' : 'initial')};
`;

export const HeaderRow = styled(Grid.Header)`
    box-shadow: 0px 0px 8px #00000029;
`;

export const HeaderCell = styled(Grid.Cell)`
    font-weight: 600;
`;

export const EditCell = styled.span`
    color: #09dcb5;
`;
