const SET_IS_LOADING_MUTATION_ACTION = 'SET_IS_LOADING_MUTATION_ACTION';
const SET_IS_NAME_MODAL_OPEN_ACTION = 'SET_IS_NAME_MODAL_OPEN_ACTION';
const ADD_CONFIG_TO_FLUX_ACTION = 'ADD_CONFIG_TO_FLUX_ACTION';
const SET_CONFIG_FORM_DATA_ACTION = 'SET_CONFIG_FORM_DATA_ACTION';
const EDIT_CONFIG_ACTION = 'EDIT_CONFIG_ACTION';
const CLOSE_CONFIG_MODAL_ACTION = 'CLOSE_CONFIG_MODAL_ACTION';
const REMOVE_SITE_CONFIG_FROM_CONFIG_ACTION =
  'REMOVE_SITE_CONFIG_FROM_CONFIG_ACTION';

export {
  SET_IS_LOADING_MUTATION_ACTION,
  SET_IS_NAME_MODAL_OPEN_ACTION,
  ADD_CONFIG_TO_FLUX_ACTION,
  SET_CONFIG_FORM_DATA_ACTION,
  EDIT_CONFIG_ACTION,
  CLOSE_CONFIG_MODAL_ACTION,
  REMOVE_SITE_CONFIG_FROM_CONFIG_ACTION,
};
