import { gql } from '@apollo/client';

const GET_SITE_FOR_MAP_PANEL = gql`
    query GetSite($id: ID!, $mid: Mixed) {
        site(id: $id) {
            id
            name
            reference
            address
            contact
            phone_number
            email
            latitude
            longitude
            site_type {
                id
                name
            }
            assets(first: 100000) {
                paginatorInfo {
                    total
                }
                data {
                    id
                    reference
                    asset_type {
                        name
                    }
                    mag {
                        mag_id
                    }
                    current_shipping_order {
                        id
                        shipping_order_status {
                            id
                            name
                        }
                    }
                    previous_sites(first: 10000) {
                        data  {
                            id
                            name
                        }
                    }
                }
            }
            geofence {
                __typename
                ... on GeofencePolygon {
                    id
                    is_rectangle
                    points {
                        latitude
                        longitude
                    }
                }
                ... on GeofenceCircle {
                    id
                    latitude
                    longitude
                    radius
                }
            }
            site_zones {
                id
                name
                geofence {
                    __typename
                    ... on GeofencePolygon {
                        id
                        is_rectangle
                        points {
                            latitude
                            longitude
                        }
                    }
                    ... on GeofenceCircle {
                        id
                        latitude
                        longitude
                        radius
                    }
                }
            }
        }
        asset_types(first: 100000, where: {
            HAS: {
                relation: "assets.sites"
                condition: {
                    column: "id"
                    operator: EQ
                    value: $mid
                }
            }
        }) {
            data {
                id
                name
                assets(where: {
                    HAS: {
                        relation: "sites"
                        condition: {
                            column: "id"
                            operator: EQ
                            value: $mid
                        }
                    }
                }) {
                    paginatorInfo {
                        total
                    }
                }
            }
        }
    }
`;

const GET_SITE_FOR_FORM = gql`
    query Site($id: ID){
        site(id: $id) {
            id
            reference
            name
            address
            latitude
            longitude
            contact
            phone_number
            email
            branches {
                id
                reference
            }
            site_type {
                id
                name
            }
            zones {
                id 
                name
                geofence {
                    __typename
                    ... on GeofencePolygon {id is_rectangle points{latitude, longitude, index}}
                    ... on GeofenceCircle {id longitude, latitude, radius}
                } 
            }
            geofence {
                __typename
                ... on GeofencePolygon {id is_rectangle points{latitude, longitude, index}}
                ... on GeofenceCircle {id longitude, latitude, radius}
            }
            bssids{
                id
                BSSID
            }
            mags {
                id
                mag_id
                is_gateway
            }
        }
    }
`;

const GET_SITES = gql`
query Sites($first: Int, $page: Int, $search: Mixed, $orderBy: [QuerySitesOrderByOrderByClause!]) {
    sites(first: $first, page: $page, orderBy: $orderBy, where: {
        HAS: {
            relation: "site_type"
            condition: {
                column: "name"
                operator: NEQ
                value: "OFF_SITE"
            }
        }
            OR: [
                {
                    HAS: {
                        relation: "site_type"
                        condition: {
                            column: "name"
                            operator: LIKE
                            value: $search
                        }
                    }
                },
                {
                    column: "name"
                    operator: LIKE
                    value: $search
                },
                {
                    column: "reference"
                    operator: LIKE
                    value: $search
                },
                {
                    column: "address"
                    operator: LIKE
                    value: $search
                },
                {
                    HAS: {
                        relation: "branches"
                        condition: {
                            column: "id"
                            operator: EQ
                            value: $search
                        }
                    }
                }
            ]
    }) {
        paginatorInfo{
            total
            currentPage
            lastPage
            perPage
        }
        data{
            id
            name
            reference
            address
            longitude
            latitude
            site_type {
                id
                name
            }
            departure_shipping_orders {
                paginatorInfo {
                    total
                }
            }
            assets {
                paginatorInfo {
                    total
                }
            }
        }
    }
}
`;

const CREATE_SITE = gql`
    mutation CreateSite(
        $name: String!
        $address: String!
        $latitude: Float!
        $longitude: Float!
        $contact: String
        $phone_number: String
        $email: String
        $reference: String!
        $siteTypeId: ID!
        $branches: UpdateManyRelation
        $geofence: GeofenceInput
        $zones: [SiteZoneInput]
        $bssids: [String]
        $mags: [UpdateMagRelation]
    ) {
        createSite(input:{
            name: $name
            address: $address
            site_type: {
                connect: $siteTypeId
            }
            latitude: $latitude
            longitude: $longitude
            contact: $contact
            phone_number: $phone_number
            email: $email
            reference: $reference
            branches: $branches
            geofence: $geofence
            zones: $zones
            bssids: $bssids
            mags: $mags
        }) {
            id
            name
            reference
        }
    }
`;

const UPDATE_SITE = gql`
    mutation UpdateSite(
        $id: ID!
        $name: String!
        $address: String!
        $latitude: Float!
        $longitude: Float!
        $contact: String
        $phone_number: String
        $email: String
        $reference: String!
        $siteTypeId: ID!
        $branches: UpdateManyRelation
        $geofence: GeofenceInput
        $zones: [SiteZoneUpdateInput]
        $bssids: [String]
        $mags: [UpdateMagRelation]
    ) {
        updateSite(input:{
            id: $id
            name: $name
            address: $address
            site_type: {
                connect: $siteTypeId
            }
            latitude: $latitude
            longitude: $longitude
            contact: $contact
            phone_number: $phone_number
            email: $email
            reference: $reference
            branches: $branches
            geofence: $geofence
            zones: $zones
            bssids: $bssids
            mags: $mags
        }) {
            id
            name
            reference
        }
    }
`;

const DELETE_SITE = gql`
    mutation DeleteSite($id: ID!) {
        deleteSite(id: $id) {
            id
        }
    }
`;

export {
  GET_SITE_FOR_MAP_PANEL,
  GET_SITE_FOR_FORM,
  GET_SITES,
  CREATE_SITE,
  UPDATE_SITE,
  DELETE_SITE,
};
