import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Loading, TextField } from 'front-library';

import { getErrorText } from '../../../helpers';
import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { routes } from '../../../routes';
import { Form } from '../../atoms/Form/Form';
import { Modal } from '../../molecules/Modal/Modal';
import {
  CREATE_ASSET_STATE,
  GET_ASSET_STATE,
  UPDATE_ASSET_STATE,
} from './graphql';

const AssetState = ({ refetch }) => {
  const { t } = useTranslation();
  const { id = null } = useParams();
  const navigate = useNavigate();

  const { data: assetStateData, loading: getLoading } = useQueryGraphQl(
    GET_ASSET_STATE,
    { id },
    {},
    null === id,
  );
  const [createAssetStateMutation, { loading: createLoading }] =
    useMutation(CREATE_ASSET_STATE);
  const [updateAssetStateMutation, { loading: updateLoading }] =
    useMutation(UPDATE_ASSET_STATE);

  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState({});

  if (assetStateData?.asset_state && !formValues.id) {
    setFormValues({
      id: assetStateData?.asset_state.id,
      name: assetStateData?.asset_state.name,
    });
  }

  const title = useMemo(
    () => (id ? t('assetStates:update') : t('assetStates:create')),
    [id, t],
  );

  const handleSave = async () => {
    try {
      await createAssetStateMutation({ variables: formValues });

      setErrors({});
      handleClose();
      toast.success(
        t('assetStates:created.success', { name: formValues.name }),
      );
      refetch();
    } catch (e) {
      setErrors(e?.graphQLErrors[0]?.extensions?.validation || {});
      toast.error(t('assetStates:created.error'));
    }
  };

  const handleUpdate = async () => {
    try {
      await updateAssetStateMutation({ variables: formValues });

      setErrors({});
      handleClose();
      toast.success(
        t('assetStates:updated.success', { name: formValues.name }),
      );
      refetch();
    } catch (e) {
      setErrors(e?.graphQLErrors[0]?.extensions?.validation || {});
      toast.error(t('assetStates:updated.error', { name: formValues.name }));
    }
  };

  const handleClose = () =>
    navigate(generatePath(routes.administrationAssetState.path));

  return (
    <Modal onClose={handleClose} title={title}>
      {(getLoading || createLoading || updateLoading) && <Loading />}
      <Form
        disableSubmit={!formValues.name}
        onCancel={handleClose}
        onSubmit={id ? handleUpdate : handleSave}
      >
        {!getLoading && (
          <TextField
            errorText={getErrorText(errors, 'name', t('assetStates:form.name'))}
            label={t('assetStates:form.name')}
            onChange={(name) => setFormValues({ ...formValues, name })}
            required={true}
            value={formValues.name}
          />
        )}
      </Form>
    </Modal>
  );
};

AssetState.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export { AssetState };
