import {
  Menu,
  MenuLink,
  SidebarFooter,
  SidebarHeader,
  Sidebar as SidebarLib,
  SidebarLink,
  SidebarSeparator,
} from 'front-library';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { generatePathWithQueryParams, menuLink, routes } from '../../../routes';
import { SidebarLoading } from './SidebarLoading';

import './sidebar-loading.css';

import LogoutIcon from 'front-library/build/img/icon-log-out-sidebar.svg';
import SearchIcon from 'front-library/build/img/icon-search-sidebar.svg';
import SetupIcon from 'front-library/build/img/icon-setup-icon-sidebar.svg';
import SupportIcon from 'front-library/build/img/icon-support-sidebar.svg';
import DeliveryIcon from 'front-library/build/img/import-icon-white.svg';
import LogoMagma from 'front-library/build/img/logo-magma.svg';
import { useContext } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { PanelContext } from '../../../contexts/PanelContext';
import { CompanySwitch } from '../CompanySwitch.js';

const Sidebar = ({ me, displayMenu = true, isLoading }) => {
  const { t } = useTranslation();
  const [statePanel, dispatch] = useContext(PanelContext);

  const logoUrl = useMemo(
    () =>
      me.company.enable_custom_logo && me.company.logo_url
        ? me.company.logo_url
        : LogoMagma,
    [me],
  );

  if (isLoading) {
    return <SidebarLoading />;
  }

  const { pathname, search } = window.location;
  const path = pathname + search;
  const conditions = [
    '/company',
    '/asset_type',
    '/asset_group',
    '/user',
    '/branch',
    '/asset_state',
    '/site_type',
    '/tour_model',
    '/callback',
    '/map/filters',
    '/mag_type',
    '/shippingorders',
    '/mag',
  ];

  const generateMenuLink = (l, i) => {
    if (!l.allowed(me)) {
      return;
    }

    if (l.path && conditions.some((condition) => l.path.includes(condition))) {
      l.path = l.externalUrl ? l.path : generatePath(l.path);
    }

    const openSublink =
      l.subLink &&
      l.subLink.filter(
        (sl) =>
          sl.path === pathname ||
          sl.otherLinkActive?.find((ol) => path.match(ol)) ||
          (sl.subLink &&
            sl.subLink.filter(
              (
                ssl, // test sublink
              ) =>
                ssl.path === pathname ||
                ssl.otherLinkActive?.find((ol) => path.match(ol)),
            ).length > 0), // test link add or edit
      ).length > 0;

    return (
      <MenuLink
        key={`menulink-${i}`}
        href={l.path}
        {...l}
        title={
          (l.v2 && <Link to={l.path}>{l.getTitle(t)}</Link>) || l.getTitle(t)
        }
        className={
          (l.path === pathname ||
            // biome-ignore lint/security/noGlobalEval:
            l.otherLinkActive?.find((ol) => path.match(eval(ol)))) &&
          'active'
        }
        openSubLink={openSublink}
      >
        {l.subLink?.map((s, j) => generateMenuLink(s, j))}
      </MenuLink>
    );
  };

  return (
    <SidebarLib>
      <SidebarHeader logo={logoUrl} name={me.surname} />
      {displayMenu && (
        <div>
          <div className="menu-1">
            <SidebarLink v2={true} img={SearchIcon}>
              <Link
                to={generatePathWithQueryParams(
                  routes.filtersMap.path,
                  {},
                  {
                    showFilter: 1,
                    search: 1,
                  },
                )}
              >
                {t('menu:search')}
              </Link>
            </SidebarLink>
            {routes.shippingOrderImportPanel.allowed(me) && (
              <SidebarLink
                href="#"
                handleClick={() => {
                  if (statePanel.shippingOrderImportPanel.show) {
                    dispatch({ type: 'resetMessages' });
                  }

                  dispatch({
                    type: 'panel',
                    panel: 'shippingOrderImportPanel',
                  });
                }}
                img={DeliveryIcon}
              >
                {t('menu:importDelivery')}
              </SidebarLink>
            )}
            <SidebarSeparator />
          </div>
          <div className="menu-2">
            <Menu>
              {menuLink.map((route, i) => generateMenuLink(route, i))}
            </Menu>
          </div>
          {me.roles.map((r) => r.name).includes('superAdmin') && (
            <CompanySwitch user={me} />
          )}
          <SidebarFooter>
            <SidebarLink
              handleClick={(e) => {
                const lang = me.lang === 'en' ? 'en-gb' : 'fr';
                const helpdeskUrl = encodeURI(
                  `https://magmatechnologyhelp.zendesk.com/hc/${lang}/signin?return_to=https://magmatechnologyhelp.zendesk.com/hc/${lang}`,
                );
                window.open(helpdeskUrl, '_blank');
                e.preventDefault();
                return false;
              }}
              href="#"
              img={SupportIcon}
            >
              {t('menu:support')}
            </SidebarLink>
            <SidebarLink
              href="#"
              img={SetupIcon}
              handleClick={() =>
                dispatch({ type: 'panel', panel: 'panelUserForm' })
              }
            >
              {t('menu:setting')}
            </SidebarLink>
            <SidebarLink
              href="/logout"
              handleClick={(event) => {
                event.preventDefault();
                document.getElementById('logout-form').submit();
              }}
              img={LogoutIcon}
            >
              {t('menu:logout')}
            </SidebarLink>
            <form
              id="logout-form"
              action="/logout"
              method="POST"
              style={{ display: 'none' }}
            />
          </SidebarFooter>
        </div>
      )}
    </SidebarLib>
  );
};

export { Sidebar };
