export const COLORS = {
  DARK_GRAY: '#707070',
  RED: '#C81912',
  GREEN: '#7BC769',
};

export const POINT_TYPES = {
  departure: 'departure',
  arrival: 'arrival',
  checkpoint: 'checkpoint',
};
