import { ColumnCheckbox, Grid, Text } from 'front-library';
import EditIcon from 'front-library/build/img/icon-modify-green-2.svg';
import DeleteIcon from 'front-library/build/img/trash.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '../../../../routes';

const LinkName = styled(Link)`
  color: #002B2B;
  
  :hover {
    color: #20676C;
  }
  
  :visited {
    color: #002B2B;
  }
  
  :active {
    color: #B9E6EB;
  }
`;

const GridHeaderContainer = styled.div`
  margin-bottom: 10px;
`;

const SIZES = ['xs', 's', 'md', 'l', 'xl'];

const DataGrid = ({ data, onDelete, onOrderBy, onClick }) => {
  const { t } = useTranslation();

  return (
    <>
      <GridHeaderContainer>
        <Grid.Grid>
          <Grid.Header>
            <Grid.Cell size={SIZES[2]}>
              <ColumnCheckbox
                label={t('assets:list.reference')}
                onClick={(order) => onOrderBy('REFERENCE', order)}
              />
            </Grid.Cell>
            <Grid.Cell size={SIZES[2]}>
              <ColumnCheckbox
                label={t('assets:list.magid')}
                onClick={(order) => onOrderBy('ASSET_MAG_ID', order)}
              />
            </Grid.Cell>
            <Grid.Cell size={SIZES[2]}>
              <ColumnCheckbox
                label={t('assets:list.type')}
                onClick={(order) => onOrderBy('ASSET_TYPE_NAME', order)}
              />
            </Grid.Cell>
            <Grid.Cell size={SIZES[1]}>
              <ColumnCheckbox
                label={t('assets:list.buy_value')}
                onClick={(order) => onOrderBy('BUY_VALUE', order)}
              />
            </Grid.Cell>
            <Grid.Cell size={SIZES[2]}>
              <ColumnCheckbox
                label={t('assets:list.group')}
                onClick={(order) => onOrderBy('ASSET_GROUP_REFERENCE', order)}
              />
            </Grid.Cell>
            <Grid.Cell size={SIZES[1]}>
              <ColumnCheckbox
                label={t('assets:list.state')}
                onClick={(order) => onOrderBy('ASSET_STATE_NAME', order)}
              />
            </Grid.Cell>
            <Grid.Cell size={SIZES[0]} />
            <Grid.Cell size={SIZES[0]} />
          </Grid.Header>
        </Grid.Grid>
      </GridHeaderContainer>
      <Grid.Grid>
        {data?.map((asset) => (
          <GridRow
            asset={asset}
            key={asset.id}
            onDelete={onDelete}
            onClick={onClick}
          />
        ))}
      </Grid.Grid>
    </>
  );
};

const GridRow = ({ asset, onDelete, onClick }) => {
  const { t } = useTranslation();

  const onEditHandler = (event, id) => {
    event.nativeEvent.preventDefault();
    onClick(true, id);
  };

  return (
    <Grid.Row>
      <Grid.Cell size={SIZES[2]}>
        {asset.last_mag_data_id ? (
          <LinkName to={generatePath(routes.assetMap.path, { id: asset.id })}>
            <Text.Bold>{asset.reference}</Text.Bold>
          </LinkName>
        ) : (
          asset.reference
        )}
      </Grid.Cell>
      <Grid.Cell size={SIZES[2]}>
        {asset.mag?.mag_id ?? t('assets:magNotAffected')}
      </Grid.Cell>
      <Grid.Cell size={SIZES[2]}>{asset.asset_type?.name}</Grid.Cell>
      <Grid.Cell size={SIZES[1]}>{asset.buy_value ?? 0}</Grid.Cell>
      <Grid.Cell size={SIZES[2]}>{asset.asset_group?.reference}</Grid.Cell>
      <Grid.Cell size={SIZES[1]}>{asset.state.name}</Grid.Cell>
      <Grid.Cell size={SIZES[0]} alignment="right">
        <a
          href={generatePath(routes.administrationAssetEdition.path, {
            id: asset.id,
          })}
        >
          <Grid.IconButton
            onClick={(event) => onEditHandler(event, asset.id)}
            imgSrc={EditIcon}
          />
        </a>
      </Grid.Cell>
      <Grid.Cell size={SIZES[0]} alignment="right">
        <Grid.IconButton onClick={() => onDelete(asset)} imgSrc={DeleteIcon} />
      </Grid.Cell>
    </Grid.Row>
  );
};

export { DataGrid };
