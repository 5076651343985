import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Loading } from 'front-library';

import { MeContext } from '../../../contexts/MeContext';
import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { routes } from '../../../routes';
import { Modal } from '../../molecules/Modal/Modal';
import { TourModelForm } from '../../organisms/TourModels/TourModelForm';
import {
  CREATE_TOUR_MODEL,
  GET_TOUR_MODEL,
  UPDATE_TOUR_MODEL,
} from './graphql';
import { mapFormValuesToGraphQl, mapGraphQlToFormValues } from './utils';

const TourModel = ({ refetch }) => {
  const { t } = useTranslation();
  const { id = null } = useParams();
  const { me: user } = useContext(MeContext);
  const navigate = useNavigate();

  const { data: tourModelData, loading: getLoading } = useQueryGraphQl(
    GET_TOUR_MODEL,
    { id },
    {},
    null === id,
  );
  const [createTourModelMutation, { loading: createLoasing }] =
    useMutation(CREATE_TOUR_MODEL);
  const [updateTourModelMutation, { loading: updateLoading }] =
    useMutation(UPDATE_TOUR_MODEL);

  const title = useMemo(
    () => (null !== id ? t('tourModels:update') : t('tourModels:create')),
    [id, t],
  );
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(mapGraphQlToFormValues());

  if (tourModelData?.tour_model && !formValues.id) {
    setFormValues(mapGraphQlToFormValues(tourModelData.tour_model));
  }

  const handleSave = async () => {
    try {
      await createTourModelMutation({
        variables: mapFormValuesToGraphQl(formValues),
      });

      setErrors({});
      handleClose();
      toast.success(t('tourModels:created.success', { name: formValues.name }));
      refetch();
    } catch (e) {
      setErrors(e?.graphQLErrors[0]?.extensions?.validation || {});
      toast.error(t('tourModels:created.error', { name: formValues.name }));
    }
  };

  const handleUpdate = async () => {
    try {
      await updateTourModelMutation({
        variables: mapFormValuesToGraphQl(formValues),
      });

      setErrors({});
      handleClose();
      toast.success(t('tourModels:updated.success', { name: formValues.name }));
      refetch();
    } catch (e) {
      setErrors(e?.graphQLErrors[0]?.extensions.validation || {});
      toast.error(t('tourModels:updated.error', { name: formValues.name }));
    }
  };

  const handleClose = () =>
    navigate(generatePath(routes.administrationTourModel.path));

  return (
    <>
      {(getLoading || createLoasing || updateLoading) && <Loading />}
      <Modal onClose={handleClose} title={title}>
        {!getLoading && (
          <TourModelForm
            user={user}
            formValues={formValues}
            setFormValues={setFormValues}
            errors={errors}
            onCancel={handleClose}
            onSubmit={id ? handleUpdate : handleSave}
          />
        )}
      </Modal>
    </>
  );
};

TourModel.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export { TourModel };
