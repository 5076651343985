import _ from 'lodash';

import * as Actions from './actions';

function assetPathReducer(state, action) {
  switch (action.type) {
    case Actions.PATH_OVER_ACTION:
      return {
        ...state,
        opennedPopups: action.payload.opennedPopups,
        pathOver: action.payload.pathOver,
      };
    case Actions.PATH_OUT_ACTION:
      return {
        ...state,
        pathOver: null,
        opennedPopups: _.cloneDeep(ASSET_PATH_INITIAL_STATE.opennedPopups),
      };
    case Actions.MARKER_OVER_ACTION:
      return {
        ...state,
        markerOver: action.payload,
      };
    case Actions.MARKER_OUT_ACTION:
      return {
        ...state,
        markerOver: _.cloneDeep(ASSET_PATH_INITIAL_STATE.markerOver),
        dateOver: _.cloneDeep(ASSET_PATH_INITIAL_STATE.dateOver),
      };
    case Actions.POPUP_OUT_ACTION:
      return {
        ...state,
        markerOver: _.cloneDeep(ASSET_PATH_INITIAL_STATE.markerOver),
        dateOver: _.cloneDeep(ASSET_PATH_INITIAL_STATE.dateOver),
        opennedPopups: _.cloneDeep(ASSET_PATH_INITIAL_STATE.opennedPopups),
      };
    case Actions.DATE_OVER_ACTION:
      return {
        ...state,
        opennedPopups: action.payload.opennedPopups,
        dateOver: action.payload.dateOver,
      };
    case Actions.DATE_OUT_ACTION:
      return {
        ...state,
        dateOver: _.cloneDeep(ASSET_PATH_INITIAL_STATE.dateOver),
        opennedPopups: _.cloneDeep(ASSET_PATH_INITIAL_STATE.opennedPopups),
      };
    case Actions.RESET_ACTION:
      return _.cloneDeep(ASSET_PATH_INITIAL_STATE);
    default:
      return state;
  }
}

const ASSET_PATH_INITIAL_STATE = {
  pathOver: null, // Used to know which path to color. Value is path index in movements.
  opennedPopups: {
    // Used to know which popups to open.
    indexes: [], // point index in singlePoints
    timestamps: {}, // {[point index]: value}. Used to know which timestamp to display in which popup.
  },
  markerOver: {
    // Used to know which marker is over
    index: null, // point index in singlePoints
    movementIndexes: [], // point indexes in singlePoints, to know which path to color
    timestamps: [], // timestamps to show in marker
  },
  dateOver: {
    // Used to know what information to display when a date is over in a popup containing multiple dates.
    indexOver: null, // index of date over in popup dates array
    bold: [], // index of dates that must be diplayed in bold
    movementIndexes: [], // index of points to know which path to color
  },
};

export { assetPathReducer, ASSET_PATH_INITIAL_STATE };
