import styled from 'styled-components';

export const PanelContainer = styled.div`
    z-index: 1;
    position: fixed;
`;

export const PanelContent = styled.div`
    padding: 5px 20px 10px 20px;
    max-height: 65vh;
    display: grid;
    grid-row-gap: 10px;
`;

export const StationarySinceContainer = styled.div`
    display: flex;
    align-items: center;

    > div {
        width: 70px;
    }
`;

export const StationarySinceLabel = styled.p`
    flex-grow: 1;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
`;

export const FilterChoiceLabel = styled(StationarySinceLabel)`
    display: flex;
    gap: 5px;
    cursor: pointer;
`;

export const FiltersTopContent = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
`;

export const FiltersSectionNumber = styled.span`
    font-family: 'Muli light';
    font-size: 16px;
    margin-left: 5px;
    color: #707070;
`;

export const FiltersTopNumber = styled.div`
    background-color: #20676c;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: white;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    font-family: 'Muli bold';
`;

export const FiltersTopText = styled.div`
    font-size: 16px;
    color: #707070;
    font-family: "Muli light";
    align-self: center;
`;

export const SkeletonPanelContent = styled.div`
    padding-left: 5px;
    padding-right: 5px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 10px;
    padding-top: 20px;

    > button {
        flex: 1;
    }
`;

export const Icon = styled.img`
    width: 13px;    
`;
