import { Label, TitleIcon } from 'front-library';
import React from 'react';

import IntermediateSiteIcon from 'front-library/build/img/icon-checkpoint-orange.svg';
import ArrivalSiteIcon from 'front-library/build/img/icon-finish-orange.svg';
import SiteIcon from 'front-library/build/img/icon-site-orange.svg';

import { DashedBorder, SummarySiteInfos } from './styled-components';

const SiteDisplay = ({ t, index, isLast, siteConfig }) => {
  const [siteDays, siteHours, siteMinutes] = siteConfig.timeOnSite.split(':');
  const [transportDays, transportHours, transportMinutes] =
    siteConfig?.transportTime?.split(':') || [0, 0, 0];

  return (
    <div>
      <SummarySiteInfos>
        {0 === index && (
          <TitleIcon img={SiteIcon} text={t('flux:form.departureSite')} />
        )}
        {isLast && (
          <TitleIcon img={ArrivalSiteIcon} text={t('flux:form.arrivalSite')} />
        )}
        {0 !== index && !isLast && (
          <TitleIcon
            img={IntermediateSiteIcon}
            text={t('flux:form.intermediateSite', { n: index })}
          />
        )}
        <div>
          <Label>{t('flux:form.timeOnSite')}</Label>
        </div>
        <div>{siteConfig.site?.title}</div>
        <div>
          {siteDays}
          {t('common:timeInput.d')}: {siteHours}
          {t('common:timeInput.h')}: {siteMinutes}
          {t('common:timeInput.m')}
        </div>
      </SummarySiteInfos>
      {!isLast && (
        <>
          <Label>{t('flux:form.routeDuration')}</Label>
          <div>
            {transportDays}
            {t('common:timeInput.d')}: {transportHours}
            {t('common:timeInput.h')}: {transportMinutes}
            {t('common:timeInput.m')}
          </div>
          <DashedBorder />
        </>
      )}
    </div>
  );
};

export { SiteDisplay };
