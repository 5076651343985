import styled from 'styled-components';

import { Stepper as LibStepper } from 'front-library';

export const NextPreviousContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 5px;

    > button {
        flex: 1;
    }
`;

export const Stepper = styled(LibStepper)`
    margin-bottom: 12px;
`;
