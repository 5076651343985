import isEqual from 'lodash/isEqual';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  GraySeparator,
  Panel,
  PanelSection,
  Switch,
} from 'front-library';
import FilterIcon from 'front-library/build/img/icon-filtre-black.svg';
import ResetIcon from 'front-library/build/img/reset-button.svg';

import { AssetStateFilter } from './Filters/AssetStateFilter/AssetStateFilter';
import { AssetTypeFilter } from './Filters/AssetTypeFilter/AssetTypeFilter';
import { BranchFilter } from './Filters/BranchFilter/BranchFilter';
import { SiteTypeFilter } from './Filters/SiteTypeFilter/SiteTypeFilter';
import { StationarySinceFilter } from './Filters/StationarySinceFilter/StationarySinceFilter';
import { MapFiltersSkeleton } from './MapFiltersSkeleton';
import { Search } from './Search';
import { GET_FILTERS_DATA } from './graphql';
import {
  FilterChoiceLabel,
  FiltersTopContent,
  FiltersTopNumber,
  FiltersTopText,
  Icon,
  PanelContainer,
  PanelContent,
} from './styled-components';
import {
  filterAssets,
  filterShippingOrders,
  filterSites,
  redirect,
} from './utils';

import { base64ToObject } from '../../../helpers';
import { trackForm } from '../../../helpers/mixpanel';
import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { useQueryState } from '../../../hooks/useQueryState';
import * as Actions from '../../../stores/map/actions';
import { GraphqlFiltersList } from '../../organisms/GraphqlFilters/GraphqlFiltersList';
import { GraphqlFiltersSave } from '../../organisms/GraphqlFilters/GraphqlFiltersSave';

/**
 * @TODO There's a lot of effects here, we have to find a way to get rid of it.
 */
const MapFilters = ({
  dispatch,
  user,
  allSites,
  allAssets,
  assetsWithoutAssetGroup,
  allShippingOrders,
  mapTouched,
  positionForced,
  filtersTouched,
  assetTypesFilter,
  siteTypesFilter,
  assetStatesFilter,
  branchesFilter,
  stationarySinceFilter,
  offsiteFilter,
  shippingOrdersOnlyFilter,
  selectedGraphqlFilter,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [defaultSearch] = useQueryState('search', false, 'boolean');
  const [showFilters, setShowFilters] = useQueryState(
    'showFilter',
    false,
    'boolean',
  );
  const [urlFilters, setUrlFilters] = useQueryState(
    'filters',
    null,
    'base64Object',
  );

  const [showGraphQlFiltersSelectionModal, setGraphQlFiltersSelectionModal] =
    useState(false);
  const [showGraphQlFiltersCreationModal, setShowGraphQlFiltersCreationModal] =
    useState(false);

  const { data: filtersData, loading: filtersLoading } =
    useQueryGraphQl(GET_FILTERS_DATA);

  const savedFiltersChoices = useMemo(
    () =>
      user.graphql_filters.data
        .filter((filter) => 'map' === filter.page)
        .map((filter) => ({
          value: filter.id,
          title: filter.name,
          secondTitle: filter.default_view
            ? t('graphqlFilters:byDefault')
            : null,
          filter,
        })),
    [user.graphql_filters.data, t],
  );

  const numberOfFilters = useMemo(
    () =>
      (0 !== siteTypesFilter.length) +
      (0 !== assetTypesFilter.length) +
      (0 !== assetStatesFilter.length) +
      (0 !== branchesFilter.length) +
      (0 !== stationarySinceFilter && '0' !== stationarySinceFilter) +
      (false !== offsiteFilter) +
      (false !== shippingOrdersOnlyFilter),
    [
      siteTypesFilter,
      assetTypesFilter,
      assetStatesFilter,
      branchesFilter,
      stationarySinceFilter,
      offsiteFilter,
      shippingOrdersOnlyFilter,
    ],
  );

  const wereFiltersChanged = () =>
    siteTypesFilter.length !== filtersData.site_types.paginatorInfo.total ||
    assetTypesFilter.length !== filtersData.asset_types.paginatorInfo.total ||
    assetStatesFilter.length !== filtersData.asset_states.data.length ||
    branchesFilter.length !== filtersData.branches.paginatorInfo.total + 1 || // + 1 because of "no branch" option
    stationarySinceFilter ||
    offsiteFilter ||
    shippingOrdersOnlyFilter;

  // Used to put in URL or save in graphql_filters
  const filters = useMemo(
    () => ({
      siteTypesFilter,
      assetTypesFilter,
      assetStatesFilter,
      branchesFilter,
      stationarySinceFilter,
      offsiteFilter,
      shippingOrdersOnlyFilter,
    }),
    [
      siteTypesFilter,
      assetTypesFilter,
      assetStatesFilter,
      branchesFilter,
      stationarySinceFilter,
      offsiteFilter,
      shippingOrdersOnlyFilter,
    ],
  );

  const resetFilters = useCallback(
    async () =>
      dispatch({
        type: Actions.RESET_FILTERS,
        payload: {
          siteTypesFilter: [],
          assetTypesFilter: [],
          assetStatesFilter: [],
          branchesFilter: [],
        },
      }),
    [dispatch],
  );

  const setSelectedGraphqlFilter = useCallback(
    (filter) => {
      if (filter) {
        dispatch({
          type: Actions.SET_SELECTED_FILTER,
          payload: {
            ...base64ToObject(filter.filters),
            selectedGraphqlFilter: filter,
          },
        });
      } else {
        resetFilters();
      }
    },
    [dispatch, resetFilters],
  );

  const selectGraphqlFilter = (filter) => {
    trackForm('select filter');
    setSelectedGraphqlFilter(filter);
    setGraphQlFiltersSelectionModal(false);
  };

  /**
   * Page initialisation.
   *
   * Firstly, we set the current panel in the state, then we check if there is filters in the url.
   * If filters in url we apply it, otherwise we check if user has default saved filters and we apply it.
   *
   * @TODO refactor this to remove useEffect
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    dispatch({
      type: Actions.SET_CURRENT_PANEL_ACTION,
      payload: 'FILTERS',
    });

    // if no url filter, we apply the default save filter
    if (!urlFilters) {
      const defaultGraphqlFilter = user.graphql_filters.data.find(
        (filter) => 'map' === filter.page && filter.default_view,
      );

      if (defaultGraphqlFilter) {
        dispatch({
          type: Actions.SET_SELECTED_FILTER,
          payload: {
            ...base64ToObject(defaultGraphqlFilter.filters),
            selectedGraphqlFilter: defaultGraphqlFilter,
          },
        });
      }

      return;
    }

    if (
      urlFilters.siteTypesFilter &&
      !isEqual(siteTypesFilter, urlFilters.siteTypesFilter)
    ) {
      dispatch({
        type: Actions.SET_SITE_TYPES_FILTER,
        payload: urlFilters.siteTypesFilter,
      });
    }

    if (
      urlFilters.assetTypesFilter &&
      !isEqual(assetTypesFilter, urlFilters.assetTypesFilter)
    ) {
      dispatch({
        type: Actions.SET_ASSET_TYPES_FILTER,
        payload: urlFilters.assetTypesFilter,
      });
    }

    if (
      urlFilters.assetStatesFilter &&
      !isEqual(assetStatesFilter, urlFilters.assetStatesFilter)
    ) {
      dispatch({
        type: Actions.SET_ASSET_STATES_FILTER,
        payload: urlFilters.assetStatesFilter,
      });
    }

    if (
      urlFilters.branchesFilter &&
      !isEqual(branchesFilter, urlFilters.branchesFilter)
    ) {
      dispatch({
        type: Actions.SET_BRANCHES_FILTER,
        payload: urlFilters.branchesFilter,
      });
    }

    if (undefined !== urlFilters.stationarySinceFilter) {
      dispatch({
        type: Actions.SET_STATIONARY_SINCE_FILTER,
        payload: +urlFilters.stationarySinceFilter,
      });
    }

    if (undefined !== urlFilters.offsiteFilter) {
      dispatch({
        type: Actions.SET_OFFSITE_FILTER,
        payload: !!urlFilters.offsiteFilter,
      });
    }

    if (undefined !== urlFilters.shippingOrdersOnlyFilter) {
      dispatch({
        type: Actions.SET_SHIPPING_ORDERS_ONLY_FILTER,
        payload: !!urlFilters.shippingOrdersOnlyFilter,
      });
    }
  }, []);

  useEffect(() => {
    if (filtersTouched) {
      setUrlFilters(filters);

      return;
    }
  }, [filtersTouched, setUrlFilters, filters]);

  useEffect(() => {
    if (allSites.length && (filtersTouched || selectedGraphqlFilter)) {
      dispatch({
        type: Actions.SET_GEOJSON_SITES_ACTION,
        payload: {
          sites: filterSites(
            allSites,
            siteTypesFilter,
            branchesFilter,
            user.company.enable_branch,
          ),
        },
      });
    }
  }, [
    allSites,
    siteTypesFilter,
    branchesFilter,
    dispatch,
    filtersTouched,
    selectedGraphqlFilter,
    user,
  ]);

  useEffect(() => {
    if (
      assetsWithoutAssetGroup.length &&
      (filtersTouched || selectedGraphqlFilter)
    ) {
      dispatch({
        type: Actions.SET_GEOJSON_ASSETS_ACTION,
        payload: {
          assets: filterAssets(
            assetsWithoutAssetGroup,
            assetTypesFilter,
            assetStatesFilter,
            branchesFilter,
            stationarySinceFilter,
            offsiteFilter,
            user.company.enable_branch,
          ),
        },
      });
    }
  }, [
    assetsWithoutAssetGroup,
    assetTypesFilter,
    assetStatesFilter,
    branchesFilter,
    stationarySinceFilter,
    offsiteFilter,
    dispatch,
    filtersTouched,
    selectedGraphqlFilter,
    user,
  ]);

  useEffect(() => {
    if (allShippingOrders.length && filtersTouched) {
      dispatch({
        type: Actions.SET_GEOJSON_SHIPPING_ORDERS_ACTION,
        payload: {
          shippingOrders: filterShippingOrders(
            allShippingOrders,
            branchesFilter,
          ),
        },
      });
    }
  }, [allShippingOrders, branchesFilter, dispatch, filtersTouched]);

  useEffect(() => {
    if (
      !mapTouched &&
      !positionForced &&
      (allSites?.length ||
        assetsWithoutAssetGroup?.length ||
        allShippingOrders?.length)
    ) {
      dispatch({
        type: Actions.SET_BOUNDS_ACTION,
        payload: {
          sites: allSites,
          assets: assetsWithoutAssetGroup,
          shippingOrders: allShippingOrders,
        },
      });
    }
  }, [
    allSites,
    assetsWithoutAssetGroup,
    allShippingOrders,
    dispatch,
    mapTouched,
    positionForced,
  ]);

  return filtersLoading ? (
    <MapFiltersSkeleton isOpen={showFilters} />
  ) : (
    <PanelContainer>
      <Panel className={showFilters ? 'panel-filters' : 'panel-filters-closed'}>
        <PanelSection
          title={t('mapFilters:filters')}
          forceOpen={showFilters}
          onOpen={() => setShowFilters(true)}
          onClose={() => setShowFilters(false)}
          customContent={
            <FiltersTopContent>
              {!showFilters && 0 !== numberOfFilters && (
                <FiltersTopNumber>{numberOfFilters}</FiltersTopNumber>
              )}
              {showFilters && (
                <FiltersTopText>
                  {t('mapFilters:nFiltersApplied', { count: numberOfFilters })}
                </FiltersTopText>
              )}
              {showFilters && (
                <Button
                  img={ResetIcon}
                  imgHoverAnimation="rotationMinus360"
                  onClick={resetFilters}
                  text={t('mapFilters:reset')}
                  variant="borderlessGreen"
                />
              )}
            </FiltersTopContent>
          }
        >
          <PanelContent>
            <Search
              assets={allAssets}
              defaultOpen={defaultSearch}
              onSelect={(selected) => redirect(selected, navigate)}
              shippingOrders={allShippingOrders}
              sites={allSites}
            />
            <GraySeparator />
            {/* Asset type */}
            {filtersData?.asset_types?.paginatorInfo && (
              <AssetTypeFilter
                assetTypesFilter={assetTypesFilter}
                assetTypesTotal={filtersData.asset_types.paginatorInfo.total}
                onChange={(value) =>
                  dispatch({
                    type: Actions.SET_ASSET_TYPES_FILTER,
                    payload: value,
                  })
                }
              />
            )}
            <GraySeparator />
            {/* Site type */}
            {filtersData?.site_types?.paginatorInfo && (
              <SiteTypeFilter
                siteTypesFilter={siteTypesFilter}
                siteTypesTotal={filtersData.site_types.paginatorInfo.total}
                onChange={(value) =>
                  dispatch({
                    type: Actions.SET_SITE_TYPES_FILTER,
                    payload: value,
                  })
                }
              />
            )}
            <GraySeparator />
            {/* Asset state */}
            {filtersData?.asset_states?.data && (
              <AssetStateFilter
                assetStatesFilter={assetStatesFilter}
                assetStates={filtersData.asset_states.data}
                onChange={(value) =>
                  dispatch({
                    type: Actions.SET_ASSET_STATES_FILTER,
                    payload: value,
                  })
                }
              />
            )}
            <GraySeparator />
            {/* Branches */}
            {filtersData?.branches?.paginatorInfo &&
              user?.company?.enable_branch && (
                <>
                  <BranchFilter
                    branchesFilter={branchesFilter}
                    branchesTotal={filtersData.branches.paginatorInfo.total}
                    onChange={(value) =>
                      dispatch({
                        type: Actions.SET_BRANCHES_FILTER,
                        payload: value,
                      })
                    }
                  />
                  <GraySeparator />
                </>
              )}
            {/* Stationary since */}
            <StationarySinceFilter
              stationarySinceFilter={+stationarySinceFilter}
              onChange={(value) =>
                dispatch({
                  type: Actions.SET_STATIONARY_SINCE_FILTER,
                  payload: value,
                })
              }
            />
            {/* Off site */}
            <Switch
              className="switchStandard"
              labelSwichtTitle={t('mapFilters:offSiteOnly')}
              checked={offsiteFilter}
              onChange={(_, value) =>
                dispatch({ type: Actions.SET_OFFSITE_FILTER, payload: value })
              }
            />
            {/* Shipping orders only*/}
            {user?.company?.enable_shipping_orders && (
              <Switch
                className="switchStandard"
                labelSwichtTitle={t('mapFilters:shippingOrdersOnly')}
                checked={shippingOrdersOnlyFilter}
                onChange={(_, value) =>
                  dispatch({
                    type: Actions.SET_SHIPPING_ORDERS_ONLY_FILTER,
                    payload: value,
                  })
                }
              />
            )}
            {/* Filters */}
            {savedFiltersChoices?.length > 0 && (
              <FilterChoiceLabel
                onClick={() =>
                  setGraphQlFiltersSelectionModal(
                    !showGraphQlFiltersSelectionModal,
                  )
                }
              >
                <Icon src={FilterIcon} />
                {t('mapFilters:selectFilter')}
              </FilterChoiceLabel>
            )}
            <Button
              variant="green"
              text={t('mapFilters:saveFilters', { pronoun: '' })}
              onClick={() =>
                setShowGraphQlFiltersCreationModal(
                  !showGraphQlFiltersCreationModal,
                )
              }
              disabled={!wereFiltersChanged()}
            />
            {/* Filter modal */}
            {showGraphQlFiltersSelectionModal && (
              <GraphqlFiltersList
                onClose={() => setGraphQlFiltersSelectionModal(false)}
                onConfirm={(filter) => selectGraphqlFilter(filter)}
                page="map"
                selectedFiltersId={selectedGraphqlFilter?.id}
              />
            )}

            {/* Creation modal */}
            {showGraphQlFiltersCreationModal && (
              <GraphqlFiltersSave
                filters={filters}
                page="map"
                onClose={() => setShowGraphQlFiltersCreationModal(false)}
              />
            )}
          </PanelContent>
        </PanelSection>
      </Panel>
    </PanelContainer>
  );
};

export { MapFilters };
