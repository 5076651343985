import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Checkbox,
  ErrorMessage,
  FileInput,
  Select,
  SuccessMessage,
} from 'front-library';

import uniqueId from 'lodash/uniqueId';
import { formatErrorMessage, getErrorText } from '../../../helpers';
import { Form } from '../../atoms/Form/Form';
import { Autocomplete } from '../../molecules/Autocomplete/Autocomplete';
import { GET_COMPANIES } from '../../molecules/Autocomplete/graphql';

const MagImportForm = ({
  errors,
  formData,
  formValues,
  onCancel,
  onSubmit,
  setFormValues,
}) => {
  const { t } = useTranslation();

  const magTypes = useMemo(
    () =>
      formData.mag_types.data.map((type) => ({
        value: type.id,
        title: `${type.provider.name} - ${type.name}${
          type.characteristics[0].pivot.parameter
            ? ` - ${type.characteristics[0].pivot.parameter}`
            : ''
        }`,
      })),
    [formData.mag_types.data],
  );

  return (
    <Form
      disableSubmit={
        !formValues.company || !formValues.magType || !formValues.file
      }
      onCancel={onCancel}
      onSubmit={onSubmit}
    >
      <h5>{t('mags:import.fileFormatDescription')}</h5>

      {/* Company */}
      <Autocomplete
        errorText={getErrorText(errors, 'company', t('mags:form.company'))}
        label={t('mags:form.company')}
        query={GET_COMPANIES}
        value={formValues.company || null}
        onChange={(company) => setFormValues({ ...formValues, company })}
        required={true}
      />

      {/* Owner */}
      <Autocomplete
        label={t('mags:form.owner')}
        query={GET_COMPANIES}
        value={formValues.owner || null}
        onChange={(owner) => setFormValues({ ...formValues, owner })}
      />

      {/* Mag type */}
      <Select
        errorText={getErrorText(errors, 'mag_type', t('mags:form.magType'))}
        label={t('mags:form.magType')}
        options={magTypes}
        value={formValues.magType || ''}
        onChange={(magType) => setFormValues({ ...formValues, magType })}
        required={true}
      />

      {/* Network operators */}
      <Select
        label={t('mags:form.operator')}
        options={formData.network_operators.data}
        value={formValues.networkOperators || []}
        onChange={(networkOperators) =>
          setFormValues({ ...formValues, networkOperators })
        }
        multiple={true}
      />

      {/* Fake assets */}
      <Checkbox
        label={t('mags:import.createFakeAsset')}
        checked={formValues.fakeAssets}
        onChange={(e) =>
          setFormValues({ ...formValues, fakeAssets: e.target.checked })
        }
      />

      {/* File */}
      <FileInput
        file={formValues?.file}
        fileName={formValues?.file?.name}
        label={t('mags:import.label')}
        onChange={(file) => setFormValues({ ...formValues, file })}
      />
      {errors['input.file'] && (
        <ErrorMessage
          text={formatErrorMessage(
            errors['input.file'][0],
            /input\.[^ ]+/,
            t('mags:import.file'),
          )}
        />
      )}

      {/* Messages */}
      {'FILE_HANDLING' === errors?.errors?.[0]?.extensions?.code &&
        errors.errors[0].extensions.handling.map((message) => (
          <ErrorMessage key={uniqueId()} text={message} />
        ))}
      {errors.result && <SuccessMessage text={errors.result} />}
    </Form>
  );
};

MagImportForm.propTypes = {
  errors: PropTypes.object,
  formData: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setFormValues: PropTypes.func.isRequired,
};

export { MagImportForm };
