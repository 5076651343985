import { Loading as LoadingLib } from 'front-library';
import React from 'react';
import reactDom from 'react-dom';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  position: fixed;
  z-index: 11000;
  top: 0;
  left: 0;
`;

const Loading = () => {
  return reactDom.createPortal(
    <LoadingContainer>
      <LoadingLib />
    </LoadingContainer>,
    document.body,
  );
};

export { Loading };
