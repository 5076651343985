import styled from 'styled-components';

export const Content = styled.div`
    padding: 0px 20px 20px 20px;
    max-height: 84vh;
`;

export const Container = styled.div`
    position: fixed;
    z-index: 1300;
`;

export const TitleIconContainer = styled.div`
    padding: 5px 0 15px 0;
`;

export const TitleIconMandatoryContainer = styled.div`
    display: flex;
    padding: 20px 0;
`;

export const AddWifiButtonContainer = styled.div`
    padding: 0px 10px;
`;

export const InnerFormContainer = styled.div`
    display: grid;
    gap: 10px;
    padding: 0 0 10px 0;
`;
