import L from 'leaflet';
import { MapLayer, withLeaflet } from 'react-leaflet';

const elementRouting = [];

/**
 * @TODO I just extracted this code to its own file, but as class components are now
 * not the way React work, we should transform this to a React functionnal component.
 *
 * (and cleaning it by the way)
 */
class RoutingLayer extends MapLayer {
  state = {};

  createLeafletElement() {
    const { map, waypoints, degraded, index } = this.props;
    this.leafletElement = L.Routing.control({
      fitSelectedRoutes: false,
      waypoints: waypoints.map((p) => L.latLng(p[0], p[1])),
      router: L.Routing.mapbox(process.env.REACT_APP_MAPBOX_KEY),
      routeWhileDragging: false,
      lineOptions: {
        addWaypoints: false,
        styles: degraded
          ? [{ color: '#B3B3B3', weight: 3, dashArray: [7, 9] }]
          : [
              { color: '#002B2B', opacity: 1, weight: 6 },
              { color: 'rgb(95, 142, 140)', opacity: 1, weight: 4 },
              { color: '#5F8E8C', opacity: 1, weight: 3 },
            ],
      },
      createMarker: () => null,
    })
      .on('routingerror', (error) => {
        if (error.error.status === 'NoRoute') {
          const wp = this.leafletElement.getWaypoints();
          if (wp.length > 2) {
            let lastPoint = [];
            const routingChunk = waypoints.reduce(
              (all, one, i) => {
                const ch = Math.floor(i / 2);
                // if next route get lastpoint
                if (ch > 0 && i % 2 === 0) {
                  all[ch] = [lastPoint];
                }
                all[ch] = [...(all[ch] || []), one];
                // save point
                lastPoint = one;
                return all;
              },
              [[]],
            );

            for (const i in wp) {
              this.leafletElement = L.Routing.control({
                fitSelectedRoutes: false,
                waypoints: routingChunk[i].map((p) => L.latLng(p[0], p[1])),
                router: L.Routing.mapbox(process.env.REACT_APP_MAPBOX_KEY),
                routeWhileDragging: false,
                lineOptions: {
                  addWaypoints: false,
                  styles: [
                    { color: '#002B2B', opacity: 1, weight: 6 },
                    { color: 'rgb(95, 142, 140)', opacity: 1, weight: 4 },
                    { color: '#5F8E8C', opacity: 1, weight: 3 },
                  ],
                },
                createMarker: () => null,
              })
                .on('routingerror', () => {
                  const wp = this.leafletElement
                    .getWaypoints()
                    .map((p) => [p.latLng.lat, p.latLng.lng]);
                  L.polyline(wp, {
                    color: '#6CA6AB',
                    weight: 9,
                    opacity: 0.4,
                  }).addTo(map.leafletElement);
                  L.polyline(wp, {
                    color: 'white',
                    weight: 6,
                    opacity: 0.6,
                  }).addTo(map.leafletElement);
                  L.polyline(wp, { color: '#5F8E8C', weight: 3 }).addTo(
                    map.leafletElement,
                  );
                })
                .addTo(map.leafletElement);
              this.leafletElement.hide();
            }
          }
        }
      })
      .addTo(map.leafletElement);

    this.leafletElement.hide();

    if (elementRouting !== undefined) {
      if (elementRouting[index] !== undefined) {
        elementRouting[index].setWaypoints([]);
        delete elementRouting[index];
      }

      elementRouting[index] = this.leafletElement;
    }

    this.state = { test: this.leafletElement };

    return this.leafletElement.getPlan();
  }

  componentWillUnmount() {
    delete elementRouting[this.props.index];
  }

  updateLeafletElement(props) {
    if (
      this.leafletElement &&
      JSON.stringify(props.waypoints) !== JSON.stringify(this.props.waypoints)
    ) {
      this.leafletElement.setWaypoints(this.props.waypoints);
    }
  }
}

const Routing = withLeaflet(RoutingLayer);

export { Routing };
