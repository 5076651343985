import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import reactDom from 'react-dom';
import { useTranslation } from 'react-i18next';

import {
  Button,
  DateRangePicker,
  GraySeparator,
  ScrollableContainer,
} from 'front-library';
import CloseImage from 'front-library/build/img/delete-cross.svg';
import WheelIcon from 'front-library/build/img/wheel-green.svg';

import * as Actions from '../../../../stores/shippingOrdersFilters/actions';
import { Autocomplete } from '../../../molecules/Autocomplete/Autocomplete';
import { GraphqlFiltersList } from '../../GraphqlFilters/GraphqlFiltersList';
import { GraphqlFiltersSave } from '../../GraphqlFilters/GraphqlFiltersSave';
import { getDateRangePresets } from './constant';

import { GET_SHIPPING_ORDERS } from '../../../molecules/Autocomplete/graphql';
import {
  GET_ARRIVAL_SITES,
  GET_ASSETS,
  GET_CARRIERS,
  GET_CHECKPOINTS,
  GET_DEPARTURE_SITES,
  GET_ORDERS,
} from './graphql';
import {
  Close,
  OutsideContainer,
  StyledForm,
  Title,
} from './styled-components';

const AdvancedFilters = ({ show, onClose, filtersState, dispatch }) => {
  const { t, i18n } = useTranslation();

  const [showGraphQlFiltersList, setShowGraphQlFiltersList] = useState(false);
  const [showSaveFiltersModal, setShowSaveFiltersModal] = useState(false);

  const normalizeDate = (date) =>
    (date && !dayjs.isDayjs(date)) || date ? dayjs(date) : date;

  const {
    refFilter,
    assetFilter,
    orderFilter,
    carrierFilter,
    departureFilter,
    arrivalFilter,
    checkpointFilter,
    departureTimestampFilter: { dates: departureDates },
    arrivalTimestampFilter: { dates: arrivalDates },
    savedFiltersId,
  } = filtersState;

  return reactDom.createPortal(
    <>
      {showGraphQlFiltersList && (
        <GraphqlFiltersList
          onClose={() => setShowGraphQlFiltersList(false)}
          page="shippingOrders"
          selectedFiltersId={savedFiltersId}
          onConfirm={(filters) => {
            dispatch({
              type: Actions.SET_SAVED_FILTERS_VARIABLES_ACTION,
              payload: {
                id: filters.id,
                filters: filters.filters,
              },
            });
            setShowGraphQlFiltersList(false);
          }}
        />
      )}
      {showSaveFiltersModal && (
        <GraphqlFiltersSave
          onClose={() => setShowSaveFiltersModal(false)}
          page="shippingOrders"
          filters={filtersState}
        />
      )}
      <OutsideContainer show={show}>
        <Title>
          <p>{t('shippingOrders:advancedFilters.title')}</p>
          <Close onClick={onClose}>
            <img src={CloseImage} alt="Close" />
          </Close>
        </Title>
        <ScrollableContainer maxHeight="90vh">
          <StyledForm hideSubmit={true} hideCancel={true}>
            <Autocomplete
              label={t('shippingOrders:advancedFilters.reference', {
                count: 1,
              })}
              multiple={true}
              onChange={(values) =>
                dispatch({
                  type: Actions.SET_REF_FILTER_ACTION,
                  payload: values,
                })
              }
              placeholderTranslateKey="shippingOrders:advancedFilters.reference"
              query={GET_SHIPPING_ORDERS}
              selectAll={true}
              value={refFilter || []}
            />
            <Autocomplete
              label={t('shippingOrders:advancedFilters.asset', { count: 1 })}
              multiple={true}
              onChange={(values) =>
                dispatch({
                  type: Actions.SET_ASSET_FILTER_ACTION,
                  payload: values,
                })
              }
              placeholderTranslateKey="shippingOrders:advancedFilters.asset"
              query={GET_ASSETS}
              selectAll={true}
              value={assetFilter || []}
            />
            <Autocomplete
              label={t('shippingOrders:advancedFilters.order', { count: 1 })}
              multiple={true}
              onChange={(values) =>
                dispatch({
                  type: Actions.SET_ORDER_FILTER_ACTION,
                  payload: values,
                })
              }
              placeholderTranslateKey="shippingOrders:advancedFilters.order"
              query={GET_ORDERS}
              selectAll={true}
              value={orderFilter || []}
            />
            <Autocomplete
              label={t('shippingOrders:advancedFilters.carrier', { count: 1 })}
              multiple={true}
              onChange={(values) =>
                dispatch({
                  type: Actions.SET_CARRIER_FILTER_ACTION,
                  payload: values,
                })
              }
              placeholderTranslateKey="shippingOrders:advancedFilters.carrier"
              query={GET_CARRIERS}
              selectAll={true}
              value={carrierFilter || []}
            />
            <GraySeparator />
            <Autocomplete
              label={t('shippingOrders:advancedFilters.departureSite', {
                count: 1,
              })}
              multiple={true}
              onChange={(values) =>
                dispatch({
                  type: Actions.SET_DEPARTURE_FILTER_ACTION,
                  payload: values,
                })
              }
              placeholderTranslateKey="shippingOrders:advancedFilters.departureSite"
              query={GET_DEPARTURE_SITES}
              selectAll={true}
              value={departureFilter || []}
            />

            <DateRangePicker
              locale={i18n.language}
              className="DateChoiceBasic"
              label={t('shippingOrders:advancedFilters.period')}
              placeholder={[
                t('shippingOrders:advancedFilters.startDate'),
                t('shippingOrders:advancedFilters.endDate'),
              ]}
              defaultValue={[
                normalizeDate(departureDates?.[0]),
                normalizeDate(departureDates?.[1]),
              ]}
              presets={getDateRangePresets(t)}
              onChange={(dates) => {
                const dispatchNewValue = (dates) =>
                  dispatch({
                    type: Actions.SET_DEPARTURE_TIMESTAMP_FILTER_ACTION,
                    payload: { dates },
                  });

                if (!dates) {
                  dispatchNewValue(null);

                  return;
                }

                const [from, to] = dates;
                const start = normalizeDate(from)?.startOf('day');
                const end = normalizeDate(to)?.endOf('day');

                dispatchNewValue([start, end]);
              }}
            />

            <GraySeparator />
            <Autocomplete
              label={t('shippingOrders:advancedFilters.checkpoint', {
                count: 1,
              })}
              multiple={true}
              onChange={(values) =>
                dispatch({
                  type: Actions.SET_CHECKPOINT_FILTER_ACTION,
                  payload: values,
                })
              }
              placeholderTranslateKey="shippingOrders:advancedFilters.checkpoint"
              query={GET_CHECKPOINTS}
              selectAll={true}
              value={checkpointFilter || []}
            />
            <GraySeparator />
            <Autocomplete
              label={t('shippingOrders:advancedFilters.arrivalSite', {
                count: 1,
              })}
              multiple={true}
              onChange={(values) =>
                dispatch({
                  type: Actions.SET_ARRIVAL_FILTER_ACTION,
                  payload: values,
                })
              }
              placeholderTranslateKey="shippingOrders:advancedFilters.arrivalSite"
              query={GET_ARRIVAL_SITES}
              selectAll={true}
              value={arrivalFilter || []}
            />

            <DateRangePicker
              locale={i18n.language}
              className="DateChoiceBasic"
              label={t('shippingOrders:advancedFilters.period')}
              placeholder={[
                t('shippingOrders:advancedFilters.startDate'),
                t('shippingOrders:advancedFilters.endDate'),
              ]}
              defaultValue={[
                normalizeDate(arrivalDates?.[0]),
                normalizeDate(arrivalDates?.[1]),
              ]}
              presets={getDateRangePresets(t)}
              onChange={(dates) => {
                const dispatchNewValue = (dates) =>
                  dispatch({
                    type: Actions.SET_ARRIVAL_TIMESTAMP_FILTER_ACTION,
                    payload: { dates },
                  });

                if (!dates) {
                  dispatchNewValue(null);

                  return;
                }

                const [from, to] = dates;
                const start = normalizeDate(from)?.startOf('day');
                const end = normalizeDate(to)?.endOf('day');

                dispatchNewValue([start, end]);
              }}
            />
            <Button
              onClick={() => setShowSaveFiltersModal(true)}
              variant="green"
              text={t('shippingOrders:advancedFilters.save')}
            />
            <Button
              img={WheelIcon}
              onClick={() => setShowGraphQlFiltersList(true)}
              text={t('shippingOrders:advancedFilters.savedFilters')}
              variant="borderlessGreen"
            />
          </StyledForm>
        </ScrollableContainer>
      </OutsideContainer>
    </>,
    document.body,
  );
};

AdvancedFilters.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  filtersState: PropTypes.object,
  dispatch: PropTypes.func,
};

export { AdvancedFilters };
