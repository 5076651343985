import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from 'front-library';

import {
  StationarySinceContainer,
  StationarySinceLabel,
} from '../../styled-components';

const StationarySinceFilter = ({ stationarySinceFilter, onChange }) => {
  const { t } = useTranslation();

  return (
    <StationarySinceContainer>
      <StationarySinceLabel>
        {t('mapFilters:stationarySince')}
      </StationarySinceLabel>
      <TextField
        min="0"
        onChange={(value) => onChange(value)}
        type="number"
        value={stationarySinceFilter}
        noMargin={true}
      />
    </StationarySinceContainer>
  );
};

StationarySinceFilter.propTypes = {
  stationarySinceFilter: PropTypes.number,
  onChange: PropTypes.func,
};

export { StationarySinceFilter };
