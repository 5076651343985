import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    gap: 10px;
    margin-top: 20px;
    padding: 15px 15px 20px 15px;
    border-radius: 5px;
    border: 1px solid #B3B3B3;
`;

export const TitleContainer = styled.div`
    display: grid;
    grid-template-columns: 28px 1fr;
`;
