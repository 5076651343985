import React from 'react';
import styled from 'styled-components';

import {
  FollowingResponsibility,
  PanelSection,
  ProgressLine,
  ScrollableContainer,
} from 'front-library';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Content = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

  .follow-responsibilty-all {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
`;

const ResponsibilitiesContainer = styled.div`
  flex-grow: 1;
  padding-left: 5px;
`;

const ResponsibilitiesSection = ({ siteTransits }) => {
  const { t } = useTranslation();

  return (
    <PanelSection
      title={t('assets:responsibilitiesTitle')}
      className="standard-panel-section"
    >
      <Content>
        <ScrollableContainer maxHeight="360px">
          <FlexContainer>
            <ProgressLine nPoint={siteTransits.length} />
            <ResponsibilitiesContainer>
              {siteTransits.map((st, i) => (
                <FollowingResponsibility
                  key={st.id}
                  labelEndDate={t('assets:outDate')}
                  labelStartDate={t('assets:enterDate')}
                  titleInfoFollow={st.sites.data.map((s) => (
                    <div key={s.id}>
                      {s.name}
                      <br />
                    </div>
                  ))}
                  valueStartDate={moment(st.date_in).format(
                    'DD MM Y | HH[h]mm',
                  )}
                  valueEndDate={
                    st.date_out
                      ? moment(st.date_out).format('DD MM Y | HH[h]mm')
                      : siteTransits[i - 1]?.date_in
                        ? moment(siteTransits[i - 1].date_in).format(
                            'DD MM Y | HH[h]mm',
                          )
                        : ''
                  }
                />
              ))}
            </ResponsibilitiesContainer>
          </FlexContainer>
        </ScrollableContainer>
      </Content>
    </PanelSection>
  );
};

export { ResponsibilitiesSection };
