import PropTypes from 'prop-types';
import React from 'react';

import { Checkbox, TextField } from 'front-library';

const Characteristic = ({
  characteristic,
  formValues,
  index,
  setFormValues,
}) => {
  return (
    <>
      {'boolean' === characteristic.data_type ? (
        <Checkbox
          key={characteristic.key}
          label={characteristic.title}
          checked={'1' === characteristic?.pivot?.parameter}
          onChange={(e) => {
            const characteristics = [...formValues.characteristics];
            characteristics[index].pivot = {
              parameter: e.target.checked ? '1' : '0',
            };

            setFormValues({ ...formValues, characteristics });
          }}
        />
      ) : (
        <TextField
          key={characteristic.key}
          label={characteristic.title}
          onChange={(parameter) => {
            const characteristics = [...formValues.characteristics];
            characteristics[index].pivot = { parameter };

            setFormValues({ ...formValues, characteristics });
          }}
          secondTitle={characteristic.unit ? `(${characteristic.unit})` : ''}
          type={'numeric' === characteristic.data_type ? 'number' : 'text'}
          value={characteristic.pivot?.parameter ?? ''}
        />
      )}
    </>
  );
};

Characteristic.propTypes = {
  characteristic: PropTypes.object,
  formValues: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setFormValues: PropTypes.func.isRequired,
};

export { Characteristic };
