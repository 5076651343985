import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ColumnCheckbox, Grid, Tag } from 'front-library';
import BellIcon from 'front-library/build/img/icon-bell-darker.svg';
import EditIcon from 'front-library/build/img/icon-modify-green-2.svg';
import DeleteIcon from 'front-library/build/img/trash.svg';

import { Link, generatePath } from 'react-router-dom';
import {
  findMainRole,
  getTranslationForRole,
  roleEnum,
} from '../../../../helpers/form/roles';
import { routes } from '../../../../routes';

const GridHeaderContainer = styled.div`
  margin-bottom: 10px;
`;

const FlexCell = styled(Grid.Cell)`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const NoMArginTag = styled(Tag)`
  margin: 0;
`;

const DataGrid = ({
  data,
  currentUser,
  onDelete,
  onOrderBy,
  onOrderByRelation,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <GridHeaderContainer>
        <Grid.Grid>
          <Grid.Header>
            <Grid.Cell size="md">
              <ColumnCheckbox
                label={t('user:form:surname')}
                onClick={(order) => onOrderBy('SURNAME', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="md">
              <ColumnCheckbox
                label={t('user:form:name')}
                onClick={(order) => onOrderBy('NAME', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="xl">
              <ColumnCheckbox
                label={t('user:form:email')}
                onClick={(order) => onOrderBy('EMAIL', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="md">
              <ColumnCheckbox label={t('user:form:role')} />
            </Grid.Cell>

            {currentUser.company.enable_branch && (
              <Grid.Cell size="md">
                <ColumnCheckbox
                  label={t('user:form:branches')}
                  onClick={(order) =>
                    onOrderByRelation('branches', 'MAX', 'NAME', order)
                  }
                />
              </Grid.Cell>
            )}

            {currentUser.rolesName.includes('superAdmin') && (
              <Grid.Cell size="xs" />
            )}
            <Grid.Cell size="xs" />
            <Grid.Cell size="xs" />
          </Grid.Header>
        </Grid.Grid>
      </GridHeaderContainer>
      <Grid.Grid>
        {data?.map((user) => (
          <GridRow
            user={user}
            currentUser={currentUser}
            key={user.id}
            onDelete={onDelete}
          />
        ))}
      </Grid.Grid>
    </>
  );
};

const GridRow = ({ user, currentUser, onDelete }) => {
  const { t } = useTranslation();
  const mainRole = useMemo(
    () => getTranslationForRole(t, findMainRole(user.roles).name),
    [t, user],
  );

  return (
    <Grid.Row>
      <Grid.Cell size="md">{user.surname}</Grid.Cell>
      <Grid.Cell size="md">{user.name}</Grid.Cell>
      <Grid.Cell size="xl">{user.email}</Grid.Cell>
      <Grid.Cell size="md">{mainRole}</Grid.Cell>

      {user?.branches && (
        <FlexCell size="md">
          {user.branches.map(({ id, name }) => (
            <NoMArginTag textBox={name} key={id} />
          ))}
        </FlexCell>
      )}

      {(currentUser.rolesName.includes(roleEnum.superAdmin) ||
        currentUser.rolesName.includes(roleEnum.administrator)) && (
        <Grid.Cell size="xs" alignment="right">
          <Grid.IconButton
            imgSrc={BellIcon}
            onClick={() => {
              window.location.href = `/user/${user.id}/alerts/edit`;
            }}
          />
        </Grid.Cell>
      )}

      <Grid.Cell size="xs" alignment="right">
        <Link
          to={generatePath(routes.administrationUserEdition.path, {
            id: user.id,
          })}
        >
          <Grid.IconButton imgSrc={EditIcon} />
        </Link>
      </Grid.Cell>
      <Grid.Cell size="xs" alignment="right">
        {(currentUser.id === user.id && (
          <Grid.IconButton disabled={true} imgSrc={DeleteIcon} />
        )) || (
          <Grid.IconButton imgSrc={DeleteIcon} onClick={() => onDelete(user)} />
        )}
      </Grid.Cell>
    </Grid.Row>
  );
};

export { DataGrid };
