import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BtnInATab, Select, TextField } from 'front-library';
import IconAddDisabled from 'front-library/build/img/icon-add-disabled.svg';
import IconAddGreen from 'front-library/build/img/icon-add-green.svg';

import { generateUniqKey, getErrorText } from '../../../helpers';
import { Form } from '../../atoms/Form/Form';
import { Header } from './Header';

const FORMATS = [
  { title: 'Magma', value: 'magma' },
  { title: 'ITSS', value: 'ITSS' },
];

const CallbackForm = ({
  callbackTypes,
  errors,
  formValues,
  setFormValues,
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const isAddButtonEnabled = useMemo(
    () => !!formValues.headers.at(-1).key,
    [formValues.headers],
  );

  const updateHeaders = (index, header) => {
    const headers = [...formValues.headers];
    headers[index] = header;

    setFormValues({ ...formValues, headers });
  };

  const removeHeader = (index, id) => {
    const deletedHeaders = [...formValues.deletedHeaders];

    if (id) {
      deletedHeaders.push(id);
    }

    const headers = [...formValues.headers];
    headers.splice(index, 1);

    setFormValues({ ...formValues, headers, deletedHeaders });
  };

  return (
    <Form onCancel={onCancel} onSubmit={onSubmit}>
      {/* Type */}
      <Select
        errorText={getErrorText(errors, 'type', t('callbacks:form.type'))}
        label={t('callbacks:form.type')}
        onChange={(type) =>
          setFormValues({
            ...formValues,
            type,
            itssAppId: 'asset' === type ? formValues.itssAppId : '',
          })
        }
        options={callbackTypes.map((callbackType) => ({
          title: callbackType.title,
          value: callbackType.value,
        }))}
        required={true}
        value={formValues.type}
      />

      {/* Format */}
      <Select
        errorText={getErrorText(errors, 'format', t('callbacks:form.format'))}
        label={t('callbacks:form.format')}
        onChange={(format) =>
          setFormValues({
            ...formValues,
            format,
            itssAppId: 'ITSS' === format ? formValues.itssAppId : '',
          })
        }
        options={FORMATS}
        required={true}
        value={formValues.format}
      />

      {/* Name */}
      <TextField
        errorText={getErrorText(errors, 'name', t('callbacks:form.name'))}
        label={t('callbacks:form.name')}
        onChange={(name) => setFormValues({ ...formValues, name })}
        required={true}
        value={formValues.name}
      />

      {/* ITSS app id */}
      {'ITSS' === formValues.format && 'asset' === formValues.type && (
        <TextField
          errorText={getErrorText(
            errors,
            'itss_app_id',
            t('callbacks:form.itssAppId'),
          )}
          label={t('callbacks:form.itssAppId')}
          onChange={(itssAppId) => setFormValues({ ...formValues, itssAppId })}
          required={true}
          value={formValues.itssAppId}
        />
      )}

      {/* Url */}
      <TextField
        errorText={getErrorText(errors, 'url', t('callbacks:form.url'))}
        label={t('callbacks:form.url')}
        onChange={(url) => setFormValues({ ...formValues, url })}
        required={true}
        value={formValues.url}
      />

      {/* Email */}
      <TextField
        label={t('callbacks:form.email')}
        onChange={(mail) => setFormValues({ ...formValues, mail })}
        value={formValues.mail}
      />

      {/* Content type */}
      <TextField
        label={t('callbacks:form.contentType')}
        onChange={(contentType) =>
          setFormValues({ ...formValues, contentType })
        }
        placeholder={t('callbacks:form.contentTypePlaceholder')}
        value={formValues.contentType}
      />

      {/* Headers */}
      {formValues.headers.map((header, index) => (
        <Header
          key={header.uniqKey}
          header={header}
          index={index}
          onUpdate={(header) => updateHeaders(index, header)}
          onDelete={(id) => removeHeader(index, id)}
        />
      ))}

      <BtnInATab
        className={
          isAddButtonEnabled
            ? 'btn-add-special btn-condition'
            : 'btn-disabled-special'
        }
        imgSrc={isAddButtonEnabled ? IconAddGreen : IconAddDisabled}
        text={t('callbacks:form.addHeader')}
        disabled={isAddButtonEnabled}
        onClick={() =>
          setFormValues({
            ...formValues,
            headers: [
              ...formValues.headers,
              { uniqKey: generateUniqKey(), key: null, value: null },
            ],
          })
        }
      />
    </Form>
  );
};

CallbackForm.propTypes = {
  callbackTypes: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { CallbackForm };
