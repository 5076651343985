import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { MeContext } from '../../contexts/MeContext';
import { Sidebar } from '../molecules/Sidebar';
import { ShippingOrderImportPanel } from '../organisms/ShippingOrderImportPanel/ShippingOrderImportPanel';
import { User } from '../pages/Users/User';
import { Content, DivTemplate, SidebarContainer } from './styled-components';

const PageWithSidebar = ({ children, displayMenu = true }) => {
  const context = useContext(MeContext);

  return (
    <DivTemplate>
      <SidebarContainer>
        <Sidebar
          displayMenu={displayMenu}
          me={context?.me}
          isLoading={undefined === context}
        />
      </SidebarContainer>
      <Content id="content">
        {children}
        <ShippingOrderImportPanel me={context?.me} />
        <User user={context.me} currentUser={context.me} setting={true} />
      </Content>
    </DivTemplate>
  );
};

PageWithSidebar.propTypes = {
  children: PropTypes.object,
  displayMenu: PropTypes.bool,
};

export { PageWithSidebar };
