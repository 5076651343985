import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DatePicker,
  FileInput,
  Label,
  PanelTitle,
  Switch,
  TextField,
} from 'front-library';

import {
  CustomParametersContainer,
  FileField,
  TitleContainer,
} from './styled-components';

// @TODO remove this
import './customParameters.css';

import { urlDecode } from '../../../helpers';
import { FileViewer } from '../../molecules/FileViewer';

const CustomParametersForm = ({
  customParameters,
  selectedAssetType = null,
  values,
  onUpdateValue,
}) => {
  const { t, i18n } = useTranslation();

  const showableCustomParameters = selectedAssetType
    ? customParameters.filter((param) =>
        0 === param.asset_types_filters.data.length
          ? true
          : param.asset_types_filters.data.some(
              (filter) => filter.id === selectedAssetType,
            ),
      )
    : customParameters;

  const [fileToDisplay, setFileToDisplay] = useState(null);

  return (
    <div>
      <TitleContainer>
        <PanelTitle label={t('menu:customParameters')} />
      </TitleContainer>
      <CustomParametersContainer>
        {showableCustomParameters.map((param) => (
          <div key={param.id}>
            {'string' === param.value_type &&
              renderStringField(param, values[param.id], onUpdateValue)}
            {'number' === param.value_type &&
              renderNumberField(param, values[param.id], onUpdateValue)}
            {'date' === param.value_type &&
              renderDateField(param, values[param.id], onUpdateValue, i18n)}
            {'boolean' === param.value_type &&
              renderBooleanField(param, values[param.id], onUpdateValue)}
            {'file' === param.value_type &&
              renderFileField(
                param,
                values[param.id],
                onUpdateValue,
                setFileToDisplay,
                t,
              )}
          </div>
        ))}
      </CustomParametersContainer>
      {fileToDisplay && (
        <FileViewer
          filePath={fileToDisplay.url}
          fileType={fileToDisplay.type}
          filename={fileToDisplay.filename}
          onClose={() => setFileToDisplay(null)}
        />
      )}
    </div>
  );
};

const renderStringField = (param, value, onChange) => {
  return (
    <TextField
      label={param.name}
      secondTitle={param.description}
      type="text"
      onChange={(val) => onChange(param.id, val)}
      value={value}
    />
  );
};

const renderNumberField = (param, value, onChange) => {
  return (
    <TextField
      label={param.name}
      secondTitle={param.description}
      type="number"
      onChange={(val) => onChange(param.id, val)}
      value={value}
    />
  );
};

const renderDateField = (param, value, onChange, i18n) => {
  return (
    <div>
      <Label>{param.name}</Label>
      {param.description && (
        <span className="second-title-box">{param.description}</span>
      )}
      <DatePicker
        locale={i18n.language}
        onChange={(val) => onChange(param.id, val.format())}
        defaultValue={dayjs.isDayjs(value) ? value : dayjs(value)}
      />
    </div>
  );
};

const renderBooleanField = (param, checked, onChange) => {
  return (
    <div className="switch-box-all">
      <div>
        <Switch
          className="styleLabelInput"
          labelSwichtTitle={param.name}
          onChange={(e) => onChange(param.id, e.target.checked)}
          checked={!!checked}
        />
      </div>
      {param.description && (
        <p className="textLabelInput switchDescription">{param.description}</p>
      )}
    </div>
  );
};

const renderFileField = (param, value, onChange, handleDisplayFile, t) => {
  return (
    <FileField>
      <FileInput
        label={param.name}
        fileName={value?.filename && urlDecode(value.filename)}
        onChange={(selectedFile) =>
          onChange(param.id, {
            filename: selectedFile.name,
            file: selectedFile,
          })
        }
      />
      {value?.file && (
        <button
          onClick={() =>
            handleDisplayFile({
              url:
                'string' === typeof value.file
                  ? value.file
                  : URL.createObjectURL(value.file),
              type: value.file?.type ? value.file?.type : 'url',
              filename: urlDecode(value.filename),
            })
          }
          type="button"
        >
          {t('customParameters:displayFile')}
        </button>
      )}
    </FileField>
  );
};

export { CustomParametersForm };
