import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Panel, PanelTitle, Switch } from 'front-library';
import IconAddGreen from 'front-library/build/img/icon-add-green.svg';

import { generatePathWithQueryParams, routes } from '../../../routes';
import { Email, SMS } from './constants';
import {
  CustomAlertTitle,
  LeftSpan,
  LeftSwitch,
  PanelContainer,
  ScrollableContainerMarginBottom,
  SecondTitle,
  SecondTitleAndDescription,
  SwitchTooltip,
} from './styled-components';

const CustomAlertsPanel = ({
  user,
  automations,
  onChange,
  isSmsEnabled,
  isAllowed,
  isActivated,
  canCreateAlert = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isSmsAutomationAllowed = (alertId) =>
    isAllowed('automation', alertId, SMS);

  return (
    <Panel>
      <PanelContainer>
        <div className="title-alert-box">
          <PanelTitle label={t('menu:automatedActions')} />
        </div>
        <SecondTitleAndDescription>
          <div>{t('alert:automatedActions.intro')}</div>
          <SecondTitle>
            {isSmsEnabled && <LeftSpan>{t('alert:sms')}</LeftSpan>}
            <span>{t('alert:email')}</span>
          </SecondTitle>
        </SecondTitleAndDescription>
        <ScrollableContainerMarginBottom>
          {automations?.map((automation) => (
            <div className="testgrid" key={automation.id}>
              <CustomAlertTitle>{automation.name}</CustomAlertTitle>
              {isSmsEnabled &&
                ((isSmsAutomationAllowed(automation.id) && user.phone && (
                  <LeftSwitch
                    onChange={(e) =>
                      onChange(
                        'automation',
                        automation.id,
                        SMS,
                        e.target.checked,
                      )
                    }
                    checked={isActivated('automation', automation.id, SMS)}
                    className="switchIconInput styleLabelInput"
                    disabled={!isSmsAutomationAllowed(automation.id)}
                  />
                )) || (
                  <SwitchTooltip
                    text={
                      isSmsAutomationAllowed(automation.id)
                        ? t('alert:phoneTooltip')
                        : t('alert:permissionTooltip')
                    }
                    placement="left"
                  >
                    <LeftSwitch
                      onChange={(e) =>
                        onChange(
                          'automation',
                          automation.id,
                          SMS,
                          e.target.checked,
                        )
                      }
                      checked={isActivated('automation', automation.id, SMS)}
                      className="switchIconInput styleLabelInput"
                      disabled={true}
                    />
                  </SwitchTooltip>
                ))}
              <Switch
                onChange={(e) =>
                  onChange('automation', automation.id, Email, e.target.checked)
                }
                checked={isActivated('automation', automation.id, Email)}
                className="switchIconInput styleLabelInput"
              />
            </div>
          ))}
        </ScrollableContainerMarginBottom>
        {canCreateAlert && (
          <Button
            text={t('automatedActions:addAutomatedAction')}
            img={IconAddGreen}
            imgHoverAnimation="rotation180"
            onClick={() =>
              navigate(
                generatePathWithQueryParams(
                  routes.administrationAutomatedActions.path,
                  {},
                  {
                    addAction: 1,
                  },
                ),
              )
            }
          />
        )}
      </PanelContainer>
    </Panel>
  );
};

export { CustomAlertsPanel };
