import { Grid, Pagination, SearchBar } from 'front-library';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
    position: fixed;
    width: 100%;
    z-index: 2;
    background-color: white;
    display: flex;
    flex-direction: column;
`;

export const TitleContainer = styled.div`
    display: flex;
    gap: 10px;
    padding: 10px;
`;

export const RightSidebarContainer = styled.div`
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: unset;
    gap: 10px;
    height: 51px;
    padding-top: 10px;
`;

export const LateOnlyContainer = styled.div`
    height: 28px;
    display: flex;
    align-items: center;

    & .MuiFormControlLabel-labelPlacementEnd {
        margin-right: 0;
        display: flex;
        align-items: baseline;
    }
`;

export const StatusesContainer = styled.div`
    width: 150px;
`;

export const GridHeader = styled(Grid.Header)`
    width: calc(100% - 270px);
`;

export const HeaderPagination = styled(Pagination)`
    .MuiToolbar-root {
        padding-left: 0;
        margin-left: 0;
        padding-right: 3px;
        min-height: 28px;
        gap: 10px;
    }
  
    .MuiTablePagination-actions {
        margin-left: 0 !important;
    }
    
    .MuiTablePagination-spacer, & .MuiInputBase-root  {
        display: none;
    }
`;

export const HeaderSearchBar = styled(SearchBar)`
    .boite-search {
        width: 140px;

        @media (min-width: 1400px) {
            width: 180px;
        }
    }
`;
