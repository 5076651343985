import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import iconAddress from 'front-library/build/img/adress-icon.svg';

import { Panel, Responsible, Switch, Text, TitleIcon } from 'front-library';
import { routes } from '../../../routes';
import {
  CLOSE_SITE_PANEL_ACTION,
  OPEN_SITE_PANEL_ACTION,
  SET_CENTER_ACTION,
  SET_SHOW_GEOFENCE_ACTION,
  SET_SHOW_ZONES_ACTION,
} from '../../../stores/map/actions';
import { AssetTypesSection } from '../../molecules/SitePanel/AssetTypesSection';

import { useSite } from '../../../hooks/sites/useSite';
import { useSiteAssetTypes } from '../../../hooks/sites/useSiteAssetTypes';
import { Header } from '../../molecules/SitePanel/Header/Header';
import { SitePanelSkeleton } from './SitePanelSkeleton';
import { SitePanelTable } from './SitePanelTable';
import {
  AssetsTotal,
  PanelContainer,
  PanelContent,
  ProfileSection,
} from './styled-components';

const SitePanel = ({
  dispatch,
  user,
  showGeofence,
  showZones,
  positionForced,
  mapTouched,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: siteId } = useParams();
  const { content: site, isLoading: siteLoading = true } = useSite(siteId);
  const { content: siteAssetTypes } = useSiteAssetTypes(siteId, user);

  const canEdit = useMemo(
    () =>
      site &&
      'OFF_SITE' !== site.type?.name &&
      !user?.rolesName?.includes('Operator'),
    [user, site],
  );

  useEffect(() => {
    if (site) {
      dispatch({
        type: OPEN_SITE_PANEL_ACTION,
        payload: { site },
      });
    }
  }, [site, dispatch]);

  useEffect(() => {
    if (
      site &&
      'OFF_SITE' !== site.type?.name &&
      !positionForced &&
      !mapTouched
    ) {
      dispatch({
        type: SET_CENTER_ACTION,
        payload: {
          center: [site.coordinates.latitude, site.coordinates.longitude],
        },
      });
    }
  }, [site, positionForced, mapTouched, dispatch]);

  useEffect(
    () => () => {
      dispatch({ type: CLOSE_SITE_PANEL_ACTION });
    },
    [dispatch],
  );

  return siteLoading || !site ? (
    <SitePanelSkeleton />
  ) : (
    <PanelContainer>
      <Panel>
        <PanelContent>
          <Header
            onEdit={
              canEdit
                ? () =>
                    navigate(
                      generatePath(routes.siteEdit.path, { id: siteId }),
                      { goBack: true },
                    )
                : null
            }
            siteName={site?.name}
            siteTypeName={site?.type?.name}
          />
          <ProfileSection>
            {site?.address && (
              <TitleIcon
                iconHeight={'32px'}
                img={iconAddress}
                text={site.address}
              />
            )}
            {site?.contact && (
              <Responsible
                responsibleName={site.contact}
                responsibleMail={site.email}
                responsibleTel={site.phone_number}
              />
            )}
            {site && 'OFF_SITE' !== site?.type.name && (
              <Switch
                className="geofence switchHigherFont"
                labelSwichtTitle={t('site:panel.displayGeofence')}
                checked={showGeofence}
                onChange={(_, checked) =>
                  dispatch({
                    type: SET_SHOW_GEOFENCE_ACTION,
                    payload: { showGeofence: checked },
                  })
                }
              />
            )}
            {site && site.zones.length > 0 && (
              <Switch
                className="geofence switchHigherFont"
                labelSwichtTitle={t('site:panel.displayZones')}
                checked={showZones}
                onChange={(_, checked) =>
                  dispatch({
                    type: SET_SHOW_ZONES_ACTION,
                    payload: { showZones: checked },
                  })
                }
              />
            )}
            {site && site.assets_total >= 0 && (
              <AssetsTotal>
                <div>{t('site:panel.assetsTotal').toUpperCase()}</div>
                <p>
                  <Text.Bold>{site.assets_total}</Text.Bold>
                </p>
              </AssetsTotal>
            )}
          </ProfileSection>
          {siteAssetTypes && siteAssetTypes.length > 0 && (
            <AssetTypesSection assetTypesCounts={siteAssetTypes} />
          )}
        </PanelContent>
        {site && site.assets_total > 0 && (
          <SitePanelTable site={site} user={user} />
        )}
      </Panel>
    </PanelContainer>
  );
};

export { SitePanel };
