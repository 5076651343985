import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const ModalContainer = styled.div`
    position: fixed;
    z-index: 1002;
    top: 0;
    left: 0;
`;

export const ButtonContainer = styled.div`
    text-align: end;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
`;
