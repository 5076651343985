const RESET_ACTION = 'RESET_ACTION';
const PATH_OVER_ACTION = 'PATH_OVER_ACTION';
const PATH_OUT_ACTION = 'PATH_OUT_ACTION';
const MARKER_OVER_ACTION = 'MARKER_OVER_ACTION';
const MARKER_OUT_ACTION = 'MARKER_OUT_ACTION';
const POPUP_OUT_ACTION = 'POPUP_OUT_ACTION';
const DATE_OVER_ACTION = 'DATE_OVER_ACTION';
const DATE_OUT_ACTION = 'DATE_OUT_ACTION';

export {
  RESET_ACTION,
  PATH_OVER_ACTION,
  PATH_OUT_ACTION,
  MARKER_OVER_ACTION,
  MARKER_OUT_ACTION,
  POPUP_OUT_ACTION,
  DATE_OVER_ACTION,
  DATE_OUT_ACTION,
};
