import { gql, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';

import 'front-library/build/css/bundle.css';
import './App.css';
import { Loading } from './components/molecules/Loading/Loading';
import { NotFound } from './components/pages/Errors/NotFound';
import { ServerError } from './components/pages/Errors/ServerError';
import { MeContext } from './contexts/MeContext';
import { PanelProvider } from './contexts/PanelContext';
import { getEnvironment, isLocal, isProduction } from './helpers/env';
import { initMixpanel } from './helpers/mixpanel';
import { routes } from './routes';
import { getAllowedRoutes } from './routes/utils';

Sentry.init({
  dsn: isProduction()
    ? 'https://6d176f957ae24080a97a13a064b8fdd8@o4504282114097152.ingest.sentry.io/4505289382100992'
    : null,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/magmatechnology\.io\/v2\/api/,
      ],
    }),
    new Sentry.Replay(),
  ],

  environment: getEnvironment(),

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  beforeSend: (event, hint) => {
    if (isLocal()) {
      console.error(hint.originalException || hint.syntheticException);
      return null; // this drops the event and nothing will be sent to sentry
    }

    return event;
  },
});

const ME = gql`
{
  me {
    id
    name
    email
    phone
    surname
    username
    password_force_update
    lang
    home_page
    branches {
      id
      name
    }
    roles {
      id
      name
    }
    alerts(first: 100000) {
      data {
        id
        pivot {
          type
          is_allowed
          is_active
        }
      }
    }
    automated_actions(first: 100000) {
      data {
        id
        pivot {
          type
          is_allowed
          is_active
        }
      }
    }
    company {
      id
      name
      address
      phone_number
      default_geofence
      eta_period
      time_to_deliver
      shipping_order_first_delay_alert
      date_format
      enable_temperature
      shipping_order_delay_frequence_alert
      order_delivery_delay
      optimise_real_departure_date
      battery_life_limit
      accuracy
      enable_shock
      enable_humidity
      enable_branch
      enable_api
      enable_shipping_orders
      enable_cycle_analytic
      legal_reference
      logo_url
      enable_custom_logo
      enable_custom_parameters
      enable_automated_actions
      enable_tableau
      tableau_workbook_url
      early_departure
      is_sms_enabled
      auto_refresh_interval
      off_site {
        id
      }
      approximate_geolocation_interval
    }
    graphql_filters(first: 10000) {
      data {
        id
        name
        page
        filters
        default_view
      }
    }
  }
}`;

// Paths authorized to be unauthenticated
const authorizedPaths = [
  routes.smsLinkPage.expiredPath,
  routes.login.path,
  routes.ssoLogin.path,
  routes.forgotPassword.path,
  routes.resetPassword.partialPath,
];

const logged = false;

const App = () => {
  const { i18n } = useTranslation();
  const { loading, error, data, refetch } = useQuery(ME, {
    pollInterval: 60 * 1000,
    errorPolicy: 'all',
  });

  if (loading) {
    return <Loading />;
  }

  if (
    error &&
    !authorizedPaths.some((path) =>
      window.location.pathname.startsWith(path),
    ) &&
    !error.message.includes('This action is unauthorized.')
  ) {
    if (isLocal()) {
      console.error(error);
    }

    if (error.message === 'Unauthenticated.') {
      window.location.href = logged ? routes.loggedout.path : routes.login.path;
      return;
    }

    return <ServerError />;
  }

  const me = data.me
    ? { ...data.me, rolesName: data.me.roles.map((r) => r.name) }
    : null;

  if (me) {
    if (i18n.language !== data.me.lang) {
      i18n.changeLanguage(data.me.lang);

      dayjs.extend(weekday);
      dayjs.extend(localeData);
      dayjs.locale(i18n.language);
    }

    document.documentElement.lang = data.me.lang;

    (() => {
      window.satismeter =
        window.satismeter ||
        ((...parameters) => {
          window.satismeter.q = window.satismeter.q || [];
          window.satismeter.q.push(parameters);
        });
      window.satismeter.l = 1 * new Date();
      const script = document.createElement('script');
      const parent = document.getElementsByTagName('script')[0].parentNode;
      script.async = 1;
      script.src = 'https://app.satismeter.com/js';
      parent.appendChild(script);
    })();

    window.satismeter({
      writeKey: '1KKB2wxZzS4P3BrT',
      userId: me.id,
      traits: {
        name: me.name,
        email: me.email,
        createdAt: me.createdAt,
      },
    });

    initMixpanel(me);
  }

  const allowedRoutes = getAllowedRoutes(me).filter((route) => route.component);

  return (
    <Router className="App">
      <MeContext.Provider value={{ me, allowedRoutes, refetchMe: refetch }}>
        <PanelProvider>
          <Routes>
            {allowedRoutes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                element={route.component}
                exact={!!route.exact}
              />
            ))}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </PanelProvider>
      </MeContext.Provider>
      <ToastContainer />
    </Router>
  );
};

export { App };
