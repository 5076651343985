import styled from 'styled-components';

export const HeaderContainer = styled.div`
    display: grid;
    gap: 10px;
    padding: 15px 15px 20px 15px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ef9d11;
`;

export const TitleContainer = styled.div`
    display: grid;
    grid-template-columns: 28px 1fr;
`;

export const InputsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
`;

export const KeyInputWrapper = styled.div`
    width: 100%;
`;

export const ValueInputWrapper = styled.div`
    width: 100%;
`;
