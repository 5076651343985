import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { PanelSection } from 'front-library';

import { MeContext } from '../../../../contexts/MeContext';
import { GET_CARRIERS } from '../../../molecules/Autocomplete/graphql';
import { EditableField } from '../../../molecules/EditableField/EditableField';
import { Loading } from '../../../molecules/Loading/Loading';

import {
  UPDATE_SHIPPING_ORDER_CARRIER,
  UPDATE_SHIPPING_ORDER_COMMENT,
} from './graphql';
import { CarrierAndWeight, Comment } from './styled-components';

const CommentCarrierPanelSection = ({ shippingOrder, refetch }) => {
  const { t } = useTranslation();
  const { me: user } = useContext(MeContext);

  const [updateCarrier, { loading: updateCarrierLoading }] = useMutation(
    UPDATE_SHIPPING_ORDER_CARRIER,
  );
  const [updateComment, { loading: updateCommentLoading }] = useMutation(
    UPDATE_SHIPPING_ORDER_COMMENT,
  );

  const onCarrierUpdate = useCallback(
    (carrier) => {
      if (!user.rolesName.includes('Administrator')) {
        return;
      }

      try {
        updateCarrier({
          variables: {
            id: shippingOrder.id,
            carrier: {
              connect: carrier?.value || null,
              disconnect: !carrier?.value,
            },
          },
        });
        refetch();
        toast.success(
          t('shippingOrders:shippingOrder.update.success', {
            reference: shippingOrder.reference,
          }),
        );
      } catch (e) {
        console.error(e);
        toast.error(t('shippingOrders:shippingOrder.updated.error'));
      }
    },
    [shippingOrder, updateCarrier, user, t, refetch],
  );

  const onCommentUpdate = useCallback(
    (comment) => {
      if (!user.rolesName.includes('Administrator')) {
        return;
      }

      try {
        updateComment({
          variables: {
            id: shippingOrder.id,
            comment,
          },
        });
        refetch();
        toast.success(
          t('shippingOrders:shippingOrder.update.success', {
            reference: shippingOrder.reference,
          }),
        );
      } catch (e) {
        console.error(e);
        toast.error(t('shippingOrders:shippingOrder.updated.error'));
      }
    },
    [user, shippingOrder, updateComment, refetch, t],
  );

  return (
    <>
      {(updateCarrierLoading || updateCommentLoading) && <Loading />}
      <PanelSection title={t('shippingOrders:panelSections.details.title')}>
        <CarrierAndWeight>
          <EditableField
            label={t('shippingOrders:panelSections.details.carrier')}
            onSave={onCarrierUpdate}
            query={GET_CARRIERS}
            type="autocomplete"
            value={
              shippingOrder.carrier
                ? {
                    title: shippingOrder.carrier.name,
                    value: shippingOrder.carrier.id,
                  }
                : null
            }
          />
          {!!shippingOrder.weight && (
            <EditableField
              label={t('shippingOrders:panelSections.details.weight')}
              value={shippingOrder.weight}
              type="number"
            />
          )}
        </CarrierAndWeight>
        <Comment>
          <EditableField
            onSave={onCommentUpdate}
            label={t('shippingOrders:panelSections.details.comment')}
            value={shippingOrder.comment}
            type="text"
          />
        </Comment>
      </PanelSection>
    </>
  );
};

CommentCarrierPanelSection.propTypes = {
  shippingOrder: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export { CommentCarrierPanelSection };
