import styled from 'styled-components';

import { Grid } from 'front-library';

export const GridRow = styled(Grid.Row)`
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    border-top: 1px solid #B3B3B3;
    border-bottom: 1px solid #6CA6AB;
    border-right: none;
    border-left: none;
    background-color: #F1F1F1;
    box-shadow: rgba(0, 0, 0, 0.11) 0px 6px 5px;

    div:nth-child(3) {
        margin-left: 0;
    }
`;

export const TitleCell = styled(Grid.Cell)`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const LoadingImage = styled.img`
    width: 18px;
`;

export const WarningImage = styled.img`
    width: 18px;
    transition: all 0.2s ease-out;
    :hover {
        opacity: 0.6;
    }
    cursor: pointer;
`;
