import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { BtnInATab, Loading } from 'front-library';
import IconAddGreen from 'front-library/build/img/icon-add-green.svg';

import { useQueryGraphQl } from '../../../../hooks/useQueryGraphQl';
import { routes } from '../../../../routes';
import { ListPage } from '../../../templates/ListPage';
import { DELETE_SITE, GET_SITES } from '../graphql';
import { DataGrid } from './DataGrid';

const SiteList = ({ branchId = null }) => {
  const search = branchId ?? '%';
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loadingMutation, setLoadingMutation] = useState(false);
  const {
    data,
    setPagination,
    setFirst,
    setVariable,
    setOrderBy,
    loading,
    refetch,
  } = useQueryGraphQl(GET_SITES, { search, first: branchId ? 10 : 25 });
  const [deleteSite] = useMutation(DELETE_SITE);

  const handleDelete = (row) =>
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <h1>{t('common:deletion')}</h1>
            <div>
              <div>{t('site:askDelete')}</div>
              <div>{t('site:delete.assetAttached')}</div>
            </div>
            <div className="react-confirm-alert-button-group">
              <button
                onClick={() => {
                  handleDeleteConfirm(row);
                  onClose();
                }}
                type="button"
              >
                {t('common:yes')}
              </button>
              <button type="button" onClick={() => onClose()}>
                {t('common:no')}
              </button>
            </div>
          </div>
        );
      },
    });

  const handleDeleteConfirm = async ({ id, name }) => {
    try {
      setLoadingMutation(true);

      await deleteSite({ variables: { id } });
      await refetch();

      toast.success(t('site:deleted.success', { name }));

      setLoadingMutation(false);
    } catch (e) {
      console.error(e);

      toast.error(t('site:deleted.error', { name }));

      setLoadingMutation(false);
    }
  };

  return (
    <ListPage
      title={t('menu:sitesManagment')}
      paginatorInfo={data?.sites?.paginatorInfo}
      onAdd={branchId ? null : () => navigate(routes.siteAdd.path)}
      onPaginate={setPagination}
      onRowsPerPageChange={setFirst}
      onSearch={
        branchId
          ? null
          : (search) => setVariable('search', `%${search.trim()}%`)
      }
      fullHeightContainer={null === branchId}
    >
      <DataGrid
        data={data?.sites?.data}
        branchId={branchId}
        onDelete={handleDelete}
        onOrderBy={setOrderBy}
      />

      {(loading || loadingMutation) && <Loading />}

      {!branchId && (
        <div className="btnInATabContainer">
          <BtnInATab
            text={t('site:addSite')}
            onClick={() => navigate(routes.siteAdd.path)}
            imgSrc={IconAddGreen}
          />
        </div>
      )}
    </ListPage>
  );
};

export { SiteList };
