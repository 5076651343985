import styled from 'styled-components';

export const HorizontalFlex = styled.div`
    display: flex;
    align-items: baseline;
    gap: 10px;
`;

export const VerticalFlex = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    gap: 3px;
`;

export const ArrivalDeparture = styled.p`
    font-family: 'Muli semi';
    font-size: 14px;
    color: #002B2B;
`;

export const PlannedReal = styled.p`
    font-family: 'Muli regular';
    font-size: 12px;
    color: #707070;
`;

export const PlannedTimestamp = styled.p`
    font-family: 'Muli regular';
    font-size: 14px;
    color: #6CA6AB;
`;

export const RealTimestamp = styled.p`
    font-family: 'Muli bold';
    font-size: 14px;
    color: ${({ color }) => color};
`;

export const Pipe = styled.p`
    align-self: end;
    color: #002B2B;
`;

export const Diff = styled.div`
    align-self: end;
    display: flex;
    color: #707070;
    font-size: 14px;
    gap: 8px;
`;

export const DiffLabel = styled.p`
    font-family: 'Muli regular';
`;
