import { gql } from '@apollo/client';

/**
 * Here we put GENERIC queries that can be used in multiple places.
 *
 * Example of what you can put here: fetch of the sites without any criteria.
 * Example of whant you CAN'T put here: fetch of sites filtered by a logic specific to a page.
 */

const GET_ASSET_GROUPS = gql`
    query GetAssetGroup($search: Mixed) {
        data: asset_groups(
            first: 20,
            where: {
                column: "reference"
                operator: LIKE
                value: $search
            },
            orderBy: {
                column: REFERENCE
                order: ASC
            }
        ) {
            data {
                value: id
                title: reference
            }
        }
    }
`;

const GET_ASSET_TYPES = gql`
    query GetAssetTypes($search: Mixed) {
        data: asset_types(
            first: 20,
            where: {
                AND: [
                    {
                        column: "name"
                        operator: NEQ
                        value: "Undefined"
                    },
                    {
                        column: "name"
                        operator: LIKE
                        value: $search
                    }
                ]
            },
            orderBy: {
                column: NAME
                order: ASC
            }
        ) {
            data {
                value: id
                title: name
            }
        }
    }
`;

const GET_BRANCHES = gql`
    query GetBranches($search: Mixed) {
        data: branches(
            first: 20,
            where: {
                OR: [
                    {
                        column: "name"
                        operator: LIKE
                        value: $search
                    },
                    {
                        column: "reference"
                        operator: LIKE
                        value: $search
                    }
                ]
            },
            orderBy: {
                column: REFERENCE
                order: ASC
            }
        ) {
            data {
                value: id
                title: reference
            }
        }
    }
`;

const GET_CARRIERS = gql`
    query GetCarriers($search: Mixed) {
        data: carriers(
            first: 20,
            where: {
                column: "name"
                operator: LIKE
                value: $search
            },
            orderBy: {
                column: NAME
                order: ASC
            }
        ) {
            data {
                value: id
                title: name
            }
        }
    }
`;

const GET_MAGS = gql`
    query GetMags($search: Mixed) {
        data: mags(
            first: 20,
            where: {
                column: "mag_id"
                operator: LIKE
                value: $search
            },
            orderBy: {
                column: MAG_ID
                order: ASC
            }
        ) {
            data {
                value: id
                title: mag_id
            }
        }
    }
`;

const GET_PROVIDERS = gql`
    query GetProviders($search: Mixed) {
        data: providers(
            first: 20,
            where: {
                column: "name"
                operator: LIKE
                value: $search
            },
            orderBy: {
                column: NAME
                order: ASC
            }
        ) {
            data {
                value: id
                title: name
            }
        }
    }
`;

const GET_SHIPPING_ORDERS = gql`
    query GetShippingOrders($search: Mixed) {
        data: shipping_orders(
            first: 20,
            where: {
                column: "reference"
                operator: LIKE
                value: $search
            },
            orderBy: {
                column: REFERENCE
                order: ASC
            }
        ) {
            data {
                value: reference
                title: reference
            }
        }
    }
`;

const GET_SITES_WITHOUT_OFFSITE = gql`
    query GetSitesWithoutOffsite($search: Mixed) {
        data: sites(
            first: 20,
            where: {
                AND: [
                    {
                        column: "name"
                        operator: LIKE
                        value: $search
                    },
                    {
                        HAS: {
                            relation: "site_type"
                            condition: {
                                column: "name"
                                operator: NEQ
                                value: "OFF_SITE"
                            }
                        }
                    }
                ]
            },
            orderBy: {
                column: NAME
                order: ASC
            }
        ) {
            data {
                value: id
                title: name
            }
        }
    }
`;

const GET_SITE_TYPES = gql`
    query GetSiteTypes($search: Mixed) {
        data: site_types(
            first: 20,
            where: {
                AND: [
                    {
                        column: "name"
                        operator: LIKE
                        value: $search
                    },
                    {
                        column: "name"
                        operator: NOT_IN
                        value: ["OFF_SITE", "Undefined"]
                    }
                ]
            },
            orderBy: {
                column: NAME
                order: ASC
            }
        ) {
            data {
                value: id
                title: name
            }
        }
    }
`;

const GET_TOUR_MODELS = gql`
    query GetTourModels($search: Mixed) {
        data: tour_models(
            first: 20,
            where: {
                column: "name"
                operator: LIKE
                value: $search
            },
            orderBy: {
                column: NAME
                order: ASC
            }
        ) {
            data {
                value: id
                title: name
            }
        }
    }
`;

const GET_USERS = gql`
    query GetUsers($search: Mixed) {
        data: users(
            first: 20,
            where: {
                column: "name"
                operator: LIKE
                value: $search
            },
            orderBy: {
                column: NAME
                order: ASC
            }
        ) {
            data {
                value: id
                title: name
            }
        }
    }
`;

const GET_COMPANIES = gql`
    query GetCompanies($search: Mixed) {
        data: companies(
            first: 20,
            where: {
                column: "name"
                operator: LIKE
                value: $search
            },
            orderBy: {
                column: NAME
                order: ASC
            }
        ) {
            data {
                value: id
                title: name
            }
        }
    }
`;

export {
  GET_ASSET_GROUPS,
  GET_ASSET_TYPES,
  GET_BRANCHES,
  GET_CARRIERS,
  GET_MAGS,
  GET_PROVIDERS,
  GET_SHIPPING_ORDERS,
  GET_SITES_WITHOUT_OFFSITE,
  GET_SITE_TYPES,
  GET_TOUR_MODELS,
  GET_USERS,
  GET_COMPANIES,
};
