import styled from 'styled-components';

export const PaginationContainer = styled.div`
    position: absolute;
    right: -12px;
    top: 1px;
    margin-right: 20px;
`;

export const TitleBarContainer = styled.div`
    display: flex;
    gap: 10px;
    padding: 10px;
`;
