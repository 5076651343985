import IconAddGreen from 'front-library/build/img/two-arrows-down.svg';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { PanelTable } from 'front-library';

import { getShippingOrderTranslate } from '../../../helpers';
import { routes } from '../../../routes';

import { useSiteAssets } from '../../../hooks/assets/useSiteAssets';
import { LoadMoreButton, TableLink } from './styled-components';

const SitePanelTable = ({ site, user }) => {
  const { t } = useTranslation();
  const assets = site.assets;
  const [offset, setOffset] = useState(0);
  const numberOfSitesToLoad = 10;
  const assetIds = useMemo(
    () => assets.map((x) => x.id).slice(offset, offset + numberOfSitesToLoad),
    [offset, assets],
  );
  const { isLoading: isAssetsLoading, items: loadedAssets } =
    useSiteAssets(assetIds);

  const loadMore = () => {
    if (offset < site.assets.length) {
      setOffset(offset + numberOfSitesToLoad);
    }
  };

  return isAssetsLoading ? null : (
    <PanelTable
      data={loadedAssets}
      getData={(asset) => [
        <TableLink
          to={generatePath(routes.assetMap.path, { id: asset.id })}
          key={asset.id}
        >
          {asset.reference}
        </TableLink>,
        asset.previous_sites?.length
          ? asset.previous_sites.map((s) => s.name).join(' ; ')
          : '',
        asset.type.name,
        user?.company.enable_shipping_orders
          ? asset.current_shipping_order
            ? getShippingOrderTranslate(t, asset.current_shipping_order.status)
            : t('site:panel:statusNotAffected')
          : null,
      ]}
      columns={
        user?.company.enable_shipping_orders
          ? [
              t('site:panel.reference'),
              t('site:panel.previousSites'),
              t('site:panel.assetType'),
              t('site:panel.deliveryStatus'),
            ]
          : [
              t('site:panel.reference'),
              t('site:panel.previousSites'),
              t('site:panel.assetType'),
            ]
      }
    >
      {loadedAssets.length !== site.assets.length && (
        <LoadMoreButton
          img={IconAddGreen}
          onClick={loadMore}
          text={t('site:panel.loadMore')}
          variant="borderlessGreen"
        />
      )}
    </PanelTable>
  );
};

export { SitePanelTable };
