import { gql } from '@apollo/client';

const UPDATE_ORDER = gql`
    mutation UpdateOrder($id: ID!, $reference: String!, $asset: UpdateRelation) {
        updateOrder(
            input: {
                id: $id
                reference: $reference
                asset: $asset
            }
        ) {
            id
        }
    }
`;

const DELETE_ORDER = gql`
    mutation DeleteOrder($id: ID!) {
        deleteOrder(id: $id) {
            id
        } 
    }
`;

export { UPDATE_ORDER, DELETE_ORDER };
