import _ from 'lodash';

function setRelations(previous, current) {
  current.relational_conditions = removeDuplicate(
    current.relational_conditions,
  );
  current.relational_conditions = {
    create: current.relational_conditions.filter(
      (condition) =>
        !previous.relational_conditions.data.find(
          (c) =>
            c.conditionable.id === condition.conditionable_id &&
            c.conditionable_type === condition.conditionable_type,
        ),
    ),
    delete: previous.relational_conditions.data
      .filter(
        (condition) =>
          !current.relational_conditions.find(
            (c) =>
              c.conditionable_type === condition.conditionable_type &&
              c.conditionable_id === condition.conditionable.id,
          ),
      )
      .map((c) => c.id),
  };

  current.value_conditions = removeDuplicate(current.value_conditions);
  current.value_conditions = {
    create: current.value_conditions
      .filter(
        (vc) =>
          !previous.value_conditions.data.find(
            (pvc) =>
              pvc.condition_type === vc.condition_type &&
              pvc.value === vc.value,
          ),
      )
      .map((vc) => ({
        condition_type: vc.condition_type,
        value: vc.value,
      })),
    delete: previous.value_conditions.data
      .filter(
        (pvc) =>
          !current.value_conditions.find(
            (vc) =>
              vc.condition_type === pvc.condition_type &&
              vc.value === pvc.value,
          ),
      )
      .map((pvc) => pvc.id),
  };

  current.actions = removeDuplicate(current.actions);
  current.actions = {
    create: current.actions.filter(
      (action) =>
        !previous.actions.data.find(
          (a) =>
            a.actionable.id === action.actionable_id &&
            a.actionable_type === action.actionable_type,
        ),
    ),
    delete: previous.actions.data
      .filter(
        (action) =>
          !current.actions.find(
            (a) =>
              a.actionable_type === action.actionable_type &&
              a.actionable_id === action.actionable.id,
          ),
      )
      .map((a) => a.id),
  };

  return current;
}

function removeDuplicate(data) {
  return _.uniqWith(data, _.isEqual);
}

export { setRelations, removeDuplicate };
