import { gql } from '@apollo/client';

const GET_SHIPPING_ORDER = gql`
    query GetShippingOrders(
        $where: WhereConditions,
    ) {
        shipping_orders(
            where: $where,
        ) { 
            data {
                id
                reference
                comment
                departure_timestamp
                real_departure_timestamp
                arrival_timestamp
                real_arrival_timestamp
                tour_order
                human_eta
                departure_site {
                    id
                    name
                    latitude
                    longitude
                    geofence {
                        __typename
                        ... on GeofencePolygon {
                            id
                            is_rectangle
                            points {
                                latitude
                                longitude
                            }
                        }
                        ... on GeofenceCircle {
                            id
                            latitude
                            longitude
                            radius
                        }
                    }
                }
                arrival_site {
                    id
                    name
                    latitude
                    longitude
                    geofence {
                        __typename
                        ... on GeofencePolygon {
                            id
                            is_rectangle
                            points {
                                latitude
                                longitude
                            }
                        }
                        ... on GeofenceCircle {
                            id
                            latitude
                            longitude
                            radius
                        }
                    }
                }
                shipping_order_status {
                    name
                }
                carrier {
                    name
                }
                tour {
                    shipping_orders(first: 100000) {
                        data {
                            id
                            reference
                            departure_timestamp
                            real_departure_timestamp
                            arrival_timestamp
                            real_arrival_timestamp
                            checkpoint_real_departure_timestamp
                            tour_order
                            human_eta
                            departure_site (trashed: WITH) {
                                id
                                name
                                latitude
                                longitude
                                geofence {
                                    __typename
                                    ... on GeofencePolygon {
                                        id
                                        is_rectangle
                                        points {
                                            latitude
                                            longitude
                                        }
                                    }
                                    ... on GeofenceCircle {
                                        id
                                        latitude
                                        longitude
                                        radius
                                    }
                                }
                            }
                            arrival_site (trashed: WITH) {
                                id
                                name
                                latitude
                                longitude
                                geofence {
                                    __typename
                                    ... on GeofencePolygon {
                                        id
                                        is_rectangle
                                        points {
                                            latitude
                                            longitude
                                        }
                                    }
                                    ... on GeofenceCircle {
                                        id
                                        latitude
                                        longitude
                                        radius
                                    }
                                }
                            }
                        }
                    }
                }
                asset_group {
                    latestMagData {
                        created_at
                    }
                    mag_datas (
                        first: 10000
                        orderBy: {
                            column: DEVICE_TIMESTAMP
                            order: ASC
                        }
                    ) {
                        data {
                            id
                            accuracy
                            device_timestamp
                            latitude
                            longitude
                        }
                    }
                }
                orders {
                    id
                    reference
                    asset {
                        reference
                        mag {
                            mag_id
                        }
                    }
                }
                updated_at
            }
        }
    }
`;

export { GET_SHIPPING_ORDER };
