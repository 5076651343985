import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Button, Panel } from 'front-library';
import close from 'front-library/build/img/delete-cross.svg';

import {
  Head,
  Name,
  PanelContainer,
  PanelContent,
  ProfileSection,
  Type,
} from './styled-components';

import { routes } from '../../../routes';

const SitePanelSkeleton = () => {
  const navigate = useNavigate();

  return (
    <PanelContainer>
      <Panel>
        <PanelContent>
          <Head>
            <Name>
              <Skeleton variant="text" width={100} />
            </Name>
            <Type>
              <Skeleton variant="text" width={100} />
            </Type>
            <Button
              img={close}
              variant="borderless"
              imgHoverAnimation="shrink"
              onClick={() => navigate(generatePath(routes.filtersMap.path))}
            />
          </Head>
          <ProfileSection>
            <Skeleton variant="text" width={300} height={30} />
            <Skeleton variant="text" width={300} height={30} />
            <Skeleton variant="text" width={300} height={60} />
          </ProfileSection>
        </PanelContent>
      </Panel>
    </PanelContainer>
  );
};

export { SitePanelSkeleton };
