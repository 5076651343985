import styled from 'styled-components';

export const Container = styled.div`
    background-color: #E4E8E8;
`;

export const DivTemplate = styled.div`
    display: flex;
`;

export const SidebarContainer = styled.div`
    position: fixed;
`;

export const Content = styled.div`
    width: calc(100vw - 270px);
    margin-left: 270px;
`;

export const FullHeightContainer = styled.div`
    background-color: #E4E8E8;
    min-height: calc(100vh - 50px);
`;
