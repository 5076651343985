import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Checkbox, TextField } from 'front-library';

import { MeContext } from '../../../contexts/MeContext';
import { getErrorText, objectToBase64 } from '../../../helpers';
import { trackForm } from '../../../helpers/mixpanel';
import { Form } from '../../atoms/Form/Form';
import { Loading } from '../../molecules/Loading/Loading';
import { Modal } from '../../molecules/Modal/Modal';
import { CREATE_GRAPHQL_FILTER } from './graphql';

const GraphqlFiltersSave = ({ filters, page, onClose }) => {
  const { t } = useTranslation();
  const { refetchMe } = useContext(MeContext);

  const [name, setName] = useState('');
  const [defaultView, setDefaultView] = useState(false);
  const [mutationLoading, setMutationLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [createGraphqlFilter] = useMutation(CREATE_GRAPHQL_FILTER);

  const handleCreateFilter = async () => {
    setMutationLoading(true);
    trackForm('save filter');

    try {
      await createGraphqlFilter({
        variables: {
          name,
          page,
          filters: objectToBase64(filters),
          default_view: defaultView,
        },
      });
      setErrors({});
      toast.success(t('graphqlFilters:created.success'));
      onClose();
    } catch (error) {
      console.error(error);
      setErrors(error?.graphQLErrors[0]?.extensions?.validation || {});
      toast.error(t('graphqlFilters:created.error'));
    } finally {
      try {
        refetchMe(); // Refresh user's filters
      } catch (error) {
        console.error(error);
        toast.error(t('common:fetchMeError'));
      }

      setMutationLoading(false);
    }
  };

  return (
    <Modal
      onClose={onClose}
      size="small"
      title={t('graphqlFilters:saveFilters')}
    >
      <Form
        disableSubmit={!name}
        onCancel={onClose}
        onSubmit={handleCreateFilter}
      >
        <TextField
          errorText={getErrorText(errors, 'name', t('graphqlFilters:name'))}
          label={t('graphqlFilters:name')}
          onChange={(value) => setName(value)}
          required={true}
          value={name}
        />
        <Checkbox
          label={t('graphqlFilters:defineAsDefault')}
          onChange={(e) => {
            setDefaultView(e.target.checked);
          }}
        />
      </Form>
      {mutationLoading && <Loading />}
    </Modal>
  );
};

export { GraphqlFiltersSave };
