import styled from 'styled-components';

export const Container = styled.div`
    margin: 0px 20px 10px 16px;
`;

export const CheckpointName = styled.p`
    color: #002B2B;
    font-size: 13px;
    word-break: break-all;
    padding: 5px 10px 0px 5px;
`;

export const CheckpointNameSameBranches = styled(CheckpointName)`
    font-weight: bold;
    text-decoration: underline 2px #EF9D11 !important;
`;

export const CheckpointTimestamp = styled.p`
    color: #6CA6AB;
    font-size: 11px;
    padding-left: 5px;
`;
