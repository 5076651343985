import { gql } from '@apollo/client';

const GET_ASSET_STATE = gql`
    query GetAssetState($id: ID!) {
        asset_state(id: $id) {
            id
            name
        }
    }
`;

const GET_ASSET_STATES = gql`
    query AssetStates($first: Int, $page: Int, $search: Mixed, $orderBy: [QueryAssetStatesOrderByRelationOrderByClause!]) {
        asset_states(first: $first, page: $page, orderBy: $orderBy, where: {
            OR: [
                {
                    column: "id"
                    operator: EQ
                    value: $search
                }
                {
                    column: "name"
                    operator: LIKE
                    value: $search
                }
            ]
        }) {
            paginatorInfo {
                total
                currentPage
                lastPage
                perPage
            }
            data {
                id
                name
                assets {
                    paginatorInfo {
                        total
                    }
                }
            }
        }
    }
`;

const CREATE_ASSET_STATE = gql`
    mutation CreateAssetState($name: String) {
        createAssetState(input: {name: $name}) {
            id
        }
    }
`;

const UPDATE_ASSET_STATE = gql`
    mutation UpdateAssetState($id: ID!, $name: String) {
        updateAssetState(input: {id: $id, name: $name}) {
            id
        }
    }
`;

const DELETE_ASSET_STATE = gql`
    mutation DeleteAssetState($id: ID!) {
        deleteAssetState(id: $id) {
            id
        }
    }
`;

export {
  GET_ASSET_STATE,
  GET_ASSET_STATES,
  DELETE_ASSET_STATE,
  CREATE_ASSET_STATE,
  UPDATE_ASSET_STATE,
};
