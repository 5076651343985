import React from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import './charts.css';

import { PanelSection } from 'front-library';

const TemperatureTag = styled.div`
  background: #78AEFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #0000003D;
  border-radius: 3px;
  color: white;
  text-align: center;
  padding: 5px;
  width: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const chartOptions = {
  stroke: {
    curve: 'smooth',
    width: 2,
    dashArray: [0, 5, 5],
  },
  xaxis: { type: 'datetime' },
  colors: ['#20676c', '#33AAFF', '#FF3F33'],
  chart: { fontFamily: 'Muli regular' },
};

const TemperaturesSection = ({
  temperatures,
  minimunTemperature = null,
  maximumTemperature = null,
}) => {
  const { t } = useTranslation();

  const minMax =
    temperatures.length > 1 &&
    null !== minimunTemperature &&
    null !== maximumTemperature
      ? [
          {
            name: t('assets:minimunTemperature'),
            data: [
              { x: new Date(temperatures[0].timestamp).getTime(), y: 10 },
              {
                x: new Date(
                  temperatures[temperatures.length - 1].timestamp,
                ).getTime(),
                y: 10,
              },
            ],
          },
          {
            name: t('assets:maximumTemperature'),
            data: [
              { x: new Date(temperatures[0].timestamp).getTime(), y: 20 },
              {
                x: new Date(
                  temperatures[temperatures.length - 1].timestamp,
                ).getTime(),
                y: 20,
              },
            ],
          },
        ]
      : [];

  return (
    <PanelSection
      title={t('assets:temperature')}
      className="standard-panel-section"
    >
      {1 === temperatures.length && (
        <TemperatureTag>{temperatures[0].temperature}°</TemperatureTag>
      )}
      {1 !== temperatures.length && (
        <Chart
          type="line"
          height={200}
          options={chartOptions}
          series={[
            {
              name: t('assets:temperature'),
              data: temperatures.map((t) => ({
                x: new Date(t.timestamp).getTime(),
                y: t.temperature,
              })),
            },
            ...minMax,
          ]}
        />
      )}
    </PanelSection>
  );
};

export { TemperaturesSection };
