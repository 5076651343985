import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Label } from 'front-library';
import Arrow from 'front-library/build/img/arrow-circle.svg';
import EditIcon from 'front-library/build/img/icon-pen-green-dark.svg';

import { ClickableIcon, Grid, Icon, Title } from './styled-components';

import { GraySeparator } from 'front-library';

const Summary = ({ state, editStep }) => {
  const { t } = useTranslation();

  const {
    deliveryReference,
    orders,
    departureSite,
    departureDate,
    checkpoints,
    arrivalSite,
    arrivalDate,
    deliveryModel,
    //weight,
    comment,
    carrierReference,
    carrierName,
  } = state;

  const [activeTabs, setActiveTabs] = useState({
    delivery: true,
    order: true,
    departure: true,
    checkpoints: true,
    arrival: true,
    extra: true,
  });

  const toggleTab = (name) =>
    setActiveTabs({ ...activeTabs, [name]: !activeTabs[name] });

  return (
    <>
      {/* Delivery */}
      <Title fontSize="14px" alignItems="center">
        <ClickableIcon
          src={Arrow}
          marginRight="5"
          rotate={activeTabs.delivery ? 90 : 0}
          onClick={() => toggleTab('delivery')}
        />
        {t('shippingOrders:modal.steps.delivery')}
        <Icon
          src={EditIcon}
          cursor="pointer"
          marginLeft="5"
          width="14"
          onClick={() => editStep(0)}
        />
      </Title>

      {activeTabs.delivery && (
        <div>
          <Label>{t('shippingOrders:order.modal.reference')}</Label>
          <div>{deliveryReference}</div>
        </div>
      )}

      <GraySeparator />

      {/* Order */}
      <Title fontSize="14px" alignItems="center">
        <ClickableIcon
          src={Arrow}
          marginRight="5"
          rotate={activeTabs.order ? 90 : 0}
          onClick={() => toggleTab('order')}
        />
        {t('shippingOrders:modal.steps.ordersTitle')}
        <Icon
          src={EditIcon}
          cursor="pointer"
          marginLeft="5"
          width="14"
          onClick={() => editStep(1)}
        />
      </Title>

      {activeTabs.order &&
        orders.map((order) => (
          <Grid key={order.uniqueId}>
            <div>
              <Label>{t('shippingOrders:modal.steps.order')}</Label>
              <div>{order.reference}</div>
            </div>
            <div>
              <Label>
                {t('shippingOrders:advancedFilters.asset', { count: 1 })}
              </Label>
              <div>{order.asset?.title}</div>
            </div>
          </Grid>
        ))}

      <GraySeparator />

      {/* Departure */}
      <Title fontSize="14px" alignItems="center">
        <ClickableIcon
          src={Arrow}
          marginRight="5"
          rotate={activeTabs.departure ? 90 : 0}
          onClick={() => toggleTab('departure')}
        />
        {t('shippingOrders:modal.steps.departureTitle')}
        <Icon
          src={EditIcon}
          cursor="pointer"
          marginLeft="5"
          width="14"
          onClick={() => editStep(2)}
        />
      </Title>
      {activeTabs.departure && (
        <>
          {!deliveryModel && (
            <div>
              <Label>{t('shippingOrders:modal.steps.site')}</Label>
              <p>{departureSite.title}</p>
            </div>
          )}
          <div>
            <Label>{t('shippingOrders:modal.steps.departureDate')}</Label>
            <p>{departureDate.format('YYYY-MM-DD HH:mm')}</p>
          </div>
          <GraySeparator />
        </>
      )}

      {/* Checkpoints */}
      {!deliveryModel && (
        <>
          <Title fontSize="14px" alignItems="center">
            <ClickableIcon
              src={Arrow}
              marginRight="5"
              rotate={activeTabs.checkpoints ? 90 : 0}
              onClick={() => toggleTab('checkpoints')}
            />
            {t('shippingOrders:modal.steps.checkpointsTitle')}
            <Icon
              src={EditIcon}
              cursor="pointer"
              marginLeft="5"
              width="14"
              onClick={() => editStep(3)}
            />
          </Title>
          {activeTabs.checkpoints &&
            checkpoints.map((checkpoint) => (
              <div key={checkpoint.uniqueId}>
                <div>
                  <Label>{t('shippingOrders:modal.steps.site')}</Label>
                  <div>{checkpoint.site.title}</div>
                </div>
                <Grid marginTop="15px">
                  <div>
                    <Label>{t('shippingOrders:modal.steps.arrivalDate')}</Label>
                    <p>
                      {checkpoint.checkpointArrivalDate.format(
                        'YYYY-MM-DD HH:mm',
                      )}
                    </p>
                  </div>
                  <div>
                    <Label>
                      {t('shippingOrders:modal.steps.departureDate')}
                    </Label>
                    <p>
                      {checkpoint.checkpointDepartureDate.format(
                        'YYYY-MM-DD HH:mm',
                      )}
                    </p>
                  </div>
                </Grid>
              </div>
            ))}
          <GraySeparator />
        </>
      )}

      {/* Arrival */}
      {!deliveryModel && (
        <>
          <Title fontSize="14px" alignItems="center">
            <ClickableIcon
              src={Arrow}
              marginRight="5"
              rotate={activeTabs.arrival ? 90 : 0}
              onClick={() => toggleTab('arrival')}
            />
            {t('shippingOrders:modal.steps.arrivalTitle')}
            <Icon
              src={EditIcon}
              cursor="pointer"
              marginLeft="5"
              width="14"
              onClick={() => editStep(4)}
            />
          </Title>
          {activeTabs.arrival && (
            <>
              <div>
                <Label>{t('shippingOrders:modal.steps.site')}</Label>
                <div>{arrivalSite.title}</div>
              </div>
              <div>
                <Label>{t('shippingOrders:modal.steps.arrivalDate')}</Label>
                <div>{arrivalDate.format('YYYY-MM-DD HH:mm')}</div>
              </div>
              <GraySeparator />
            </>
          )}
        </>
      )}

      {/* Additional info */}
      <Title fontSize="14px" alignItems="center">
        <ClickableIcon
          src={Arrow}
          marginRight="5"
          rotate={activeTabs.extra ? 90 : 0}
          onClick={() => toggleTab('extra')}
        />
        {t('shippingOrders:modal.steps.additionalInfo')}
        <Icon
          src={EditIcon}
          cursor="pointer"
          marginLeft="5"
          width="14"
          onClick={() => editStep(deliveryModel ? 2 : 4)}
        />
      </Title>
      {activeTabs.extra && (
        <>
          {/* <div>
                        <Label>{t('shippingOrders:modal.steps.weight')}</Label>
                        <p>{weight}</p>
                    </div> */}
          <div>
            <Label>{t('shippingOrders:modal.steps.comment')}</Label>
            <p>{comment}</p>
          </div>
          <div>
            <Label>{t('shippingOrders:modal.steps.carrierReference')}</Label>
            <p>{carrierReference}</p>
          </div>
          <div>
            <Label>{t('shippingOrders:modal.steps.carrierName')}</Label>
            <p>{carrierName}</p>
          </div>
          <GraySeparator />
        </>
      )}
    </>
  );
};

Summary.propTypes = {
  state: PropTypes.object,
  editStep: PropTypes.func,
};

export { Summary };
