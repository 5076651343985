import { Button, Theme } from 'front-library';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    * {
        display: inline-block;
    }
`;

export const Title = styled.h1`
    font-size: 20px;
    color: ${Theme.palette.primary.darkest};
`;

export const EditButton = styled(Button)`
    :hover > img {
        opacity: 0.6;
        cursor: pointer;
    }

    :active > img {
        opacity: 0.8;
    }
`;

export const SecondTitle = styled.h2`
    font-size: 14px;
    color: ${Theme.palette.grey.dark};
`;
