import { convertStringToKey } from '../../../helpers';
import { roleEnum } from '../../../helpers/form/roles';

export const IMPORT_TYPES = {
  site_import: 'site_import',
  asset_import: 'asset_import',
  user_import: 'user_import',
  asset_modification: 'asset_modification',
  asset_deletion: 'asset_deletion',
  site_modification: 'site_modification',
  site_deletion: 'site_deletion',
};

export function mapAssetValues(asset) {
  return {
    reference: asset.reference,
    buy_value: asset.buy_value?.toString(),
    mag_id: asset.mag?.mag_id,
    type: asset.asset_type.name,
    state: asset.state.name,
    branches: asset.branches.data.map((branch) => branch.reference),
    responsible: asset.responsible.data[0]?.email,
  };
}

export function mapSiteValues(site) {
  return {
    name: site.name,
    address: site.address,
    reference: site.reference,
    latitude: site.latitude,
    longitude: site.longitude,
    geofence_radius: site.geofence_radius ?? '',
    type: site.site_type.name,
    branches: site.branches.map((branch) => branch.reference),
  };
}

export function parseUsers(
  rowsArray,
  errorsRef,
  t,
  setErrors,
  setRequiredError,
) {
  const users = [];

  for (const [index, row] of rowsArray.entries()) {
    const roles = [];
    const roleTranslations = [];
    const newErrors = errorsRef.current;

    // Required
    for (let i = 0; i < 4; i++) {
      if (!row[i]) {
        setRequiredError(index, i, newErrors);
      }
    }

    if (!row[4] && row[12] !== 'observer') {
      setRequiredError(index, 4, newErrors);
    }

    setErrors(newErrors);

    // Role
    if (roleEnum.administrator.toLowerCase() === row[12].toLowerCase()) {
      roles.push(roleEnum.administrator);
      roleTranslations.push(roleEnum.administrator);
    } else if (roleEnum.observer.toLowerCase() === row[12].toLowerCase()) {
      roles.push(roleEnum.observer);
      roleTranslations.push(roleEnum.observer);
    } else {
      roles.push(roleEnum.operator);
      roleTranslations.push(roleEnum.operator);
    }

    // Automations
    const automations = row[15]
      ? row[15].split(';').filter((action) => action.trim())
      : [];

    // Options
    const map = [
      {
        index: 11,
        translationKey: 'user:form.options.api',
        roleName: roleEnum.apiAdmin,
      },
      {
        index: 10,
        translationKey: 'user:form.options.cycleAnalytic',
        roleName: roleEnum.cycleAnalyticOption,
      },
      {
        index: 9,
        translationKey: 'user:form.options.shippingOrderAnalytic',
        roleName: roleEnum.shippingOrderAnalyticOption,
      },
      {
        index: 8,
        translationKey: 'user:form.options.shippingOrder',
        roleName: roleEnum.shippingOrderOption,
      },
      {
        index: 7,
        translationKey: 'user:form.options.assetManagement',
        roleName: roleEnum.assetManagement,
      },
    ];

    for (const { index, translationKey, roleName } of map) {
      const rowValue = row[index];

      if ('yes' === rowValue.toLowerCase()) {
        roleTranslations.push(t(translationKey));
        roles.push(t(roleName));
      }
    }

    row.splice(7, 6);
    row.push(roleTranslations.join(', '));

    users.push({
      surname: row[0],
      name: row[1],
      username: row[2],
      email: row[3],
      password: row[4],
      password_force_update:
        '' === row[5] || 'yes' === row[5].toLowerCase() ? 1 : 0,
      lang: { en: 'en', es: 'es', fr: 'fr' }[row[8]?.toLowerCase()] || 'fr',
      home_page: 'deliveries' === row[7] ? 'ShippingOrders' : 'map',
      branches: row[6] ? row[6].split(';').map((branch) => branch.trim()) : [],
      roles,
      automated_actions: automations,
    });
  }

  return users;
}

export function parseAssets(
  rowsArray,
  action,
  errorsRef,
  t,
  setErrors,
  setRequiredError,
) {
  const assets = [];
  const seen = new Map(); // All seen references
  const newErrors = errorsRef.current;

  for (const [index, row] of rowsArray.entries()) {
    const assetReference = row[0];

    if (seen.has(assetReference)) {
      seen.get(assetReference).push(index + 1); // Add the current line number to the array of duplicted reference
    } else {
      seen.set(assetReference, [index + 1]);
    }

    // Required
    const requiredColumns = 'create' === action ? [0, 2, 3] : [0];
    for (const i of requiredColumns) {
      if (!row[i]) {
        setRequiredError(index, i, newErrors);
      }
    }

    assets.push({
      reference: row[0],
      mag_id: row[1] || null,
      type: row[2] || null,
      state: row[3] || null,
      buy_value: row[4] || 0,
      branches: row[5] ? row[5].split(';').map((branch) => branch.trim()) : [],
      responsible: row[6],
    });
  }

  newErrors.push(...getDuplicateErrors(seen, t('import:reference'), t));

  setErrors(newErrors);

  return assets;
}

export function parseSites(
  rowsArray,
  action,
  errorsRef,
  t,
  setErrors,
  setRequiredError,
) {
  const sites = [];
  const seen = new Map(); // All seen references
  const newErrors = errorsRef.current;

  for (const [index, row] of rowsArray.entries()) {
    const siteName = row[0];

    if (seen.has(siteName)) {
      seen.get(siteName).push(index + 1); // Add the current line number to the array of duplicted reference
    } else {
      seen.set(siteName, [index + 1]);
    }

    // Required
    const requiredColumnsNumber = 'create' === action ? 3 : 1;
    for (let i = 0; i < requiredColumnsNumber; i++) {
      if (!row[i]) {
        setRequiredError(index, i, newErrors);
      }
    }

    const branchCell = 'create' === action ? row[7] : row[4];
    const branches = branchCell
      ? branchCell.split(';').map((branch) => branch.trim())
      : [];

    sites.push({
      name: row[0],
      address: row[1] || null,
      type: row[2] || null,
      reference: row[3]
        ? row[3]
        : 'create' === action
          ? convertStringToKey(row[0])
          : null,
      latitude: 'create' === action ? row[4].replace(',', '.') : null,
      longitude: 'create' === action ? row[5].replace(',', '.') : null,
      radius: row[6] ?? null,
      branches: branches,
    });
  }

  newErrors.push(...getDuplicateErrors(seen, t('import:name'), t));

  setErrors(newErrors);

  return sites;
}

function getDuplicateErrors(seen, field, t) {
  const errors = [];

  // Check for duplicate references
  seen.forEach((lineNumbers, value) => {
    if (1 === lineNumbers.length) {
      return;
    }

    for (const lineNumber of lineNumbers) {
      const duplicateLines = lineNumbers
        .filter((line) => line !== lineNumber)
        .join(', ');

      errors.push(
        t('import:errors.duplicated', {
          line: lineNumber,
          field,
          value,
          lines: duplicateLines,
        }),
      );
    }
  });

  return errors;
}
