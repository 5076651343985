import uniqueId from 'lodash/uniqueId';

const MODEL_SITE = 'App\\Models\\Site';
const MODEL_ASSET = 'App\\Models\\Asset';
const MODEL_SHIPPING_ORDER = 'App\\Models\\ShippingOrder';
const CONDITIONS = {
  site: 'Site',
  siteType: 'SiteType',
  assetType: 'AssetType',
  assetState: 'AssetState',
  minAssets: 'MIN_ASSETS_ON_SITE',
  maxAssets: 'MAX_ASSETS_ON_SITE',
  maxValue: 'MAX_VALUE_ON_SITE',
  assetSiteEnter: 'ASSET_SITE_ENTER',
  assetSiteLeave: 'ASSET_SITE_LEAVE',
  assetStateExpired: 'ASSET_STATE_EXPIRED',
  assetOnSiteSince: 'ASSET_ON_SITE_SINCE',
  shippingOrderStatus: 'ShippingOrderStatus',
  thereticalArrivalDateExceeded: 'THEORETICAL_ARRIVAL_DATE_EXCEEDED',
  thereticalDepartureDateExceeded: 'THEORETICAL_DEPARTURE_DATE_EXCEEDED',
  arrivalTimeLessThan: 'ARRIVAL_TIME_LESS_THAN',
};
const SITE_CONDITIONS = [
  CONDITIONS.minAssets,
  CONDITIONS.maxAssets,
  CONDITIONS.maxValue,
];
const ASSET_CONDITIONS = [
  CONDITIONS.assetSiteEnter,
  CONDITIONS.assetSiteLeave,
  CONDITIONS.assetStateExpired,
  CONDITIONS.assetOnSiteSince,
];
const SHIPPING_ORDER_CONDITIONS = [
  CONDITIONS.shippingOrderStatus,
  CONDITIONS.thereticalArrivalDateExceeded,
  CONDITIONS.thereticalDepartureDateExceeded,
  CONDITIONS.arrivalTimeLessThan,
];
const RELATIONAL_CONDITIONS = [
  CONDITIONS.siteType,
  CONDITIONS.site,
  CONDITIONS.assetType,
  CONDITIONS.assetState,
];
const INTERVAL_CONDITIONS = [
  CONDITIONS.assetStateExpired,
  CONDITIONS.assetOnSiteSince,
];
const POSSIBLE_MODEL_CONDITIONS_CHOICES = {
  [MODEL_ASSET]: [...ASSET_CONDITIONS, ...RELATIONAL_CONDITIONS],
  [MODEL_SITE]: [...SITE_CONDITIONS, ...RELATIONAL_CONDITIONS],
  [MODEL_SHIPPING_ORDER]: [...SHIPPING_ORDER_CONDITIONS],
};

function mapConditionsToForm(relationalConditions, valueConditions) {
  let formConditions = [];

  if (relationalConditions?.length) {
    for (const condition of relationalConditions) {
      const conditionInFormConditions = formConditions.find(
        (c) => c.type === condition.conditionable.__typename,
      );

      let value = null;

      if (
        -1 ===
        [CONDITIONS.assetState, CONDITIONS.shippingOrderStatus].indexOf(
          condition.conditionable.__typename,
        )
      ) {
        value = {
          title: condition.conditionable.name,
          value: condition.conditionable.id,
        };
      } else {
        value = condition.conditionable.id;
      }

      if (conditionInFormConditions) {
        conditionInFormConditions.values.push(value);
      } else {
        formConditions.push({
          type: condition.conditionable.__typename,
          values: [value],
          isRelational: true,
        });
      }
    }
  }

  if (valueConditions?.length) {
    formConditions = [
      ...formConditions,
      ...valueConditions.map((c) => ({
        value: c.value,
        type: c.condition_type,
        isValue: true,
      })),
    ];
  }

  return formConditions;
}

function mapActionsToForm(actions) {
  return (
    actions?.map((a) => ({
      type: a.actionable.__typename,
      uniqueId: uniqueId(),
      value: a.actionable.id,
    })) || []
  );
}

function mapAutomatedActionToGraphql({
  name,
  model,
  notify,
  branchNotification,
  conditions,
  actions,
}) {
  return {
    name: name || null,
    model: model || null,
    notify: notify || false,
    branch_notification: branchNotification || null,
    value_conditions:
      conditions
        ?.filter((c) => c.isValue || c.isCondition)
        .map((c) => ({
          condition_type: c.type,
          value: c.value,
        })) || [],
    actions:
      actions?.map((a) => ({
        actionable_type: `App\\Models\\${a.type}`,
        actionable_id: a.value,
      })) || [],
    relational_conditions:
      conditions
        ?.filter((c) => c.isRelational)
        .flatMap((c) =>
          c.values.map((v) => ({
            conditionable_type: `App\\Models\\${c.type}`,
            conditionable_id: 'object' === typeof v ? v.value : v,
          })),
        ) || [],
  };
}

export {
  MODEL_SITE,
  MODEL_ASSET,
  MODEL_SHIPPING_ORDER,
  CONDITIONS,
  SITE_CONDITIONS,
  ASSET_CONDITIONS,
  SHIPPING_ORDER_CONDITIONS,
  INTERVAL_CONDITIONS,
  RELATIONAL_CONDITIONS,
  POSSIBLE_MODEL_CONDITIONS_CHOICES,
  mapConditionsToForm,
  mapActionsToForm,
  mapAutomatedActionToGraphql,
};
