import React from 'react';

import { StepTitle } from './styled-components';

import { SiteSelector } from './SiteSelector';

const RoutesStep = ({
  t,
  formData,
  editSite,
  addIntermediateSite,
  removeIntermediateSite,
}) => {
  return (
    <div>
      <StepTitle>{t('flux:form.route')}</StepTitle>
      {formData.fluxConfigurationSites.map((fcs, index) => (
        <SiteSelector
          key={fcs.uniqId}
          t={t}
          index={index}
          siteConfig={fcs}
          isLast={formData.fluxConfigurationSites.length - 1 === index}
          addIntermediateSite={addIntermediateSite}
          removeIntermediateSite={removeIntermediateSite}
          editSite={editSite}
        />
      ))}
    </div>
  );
};

export { RoutesStep };
