import { gql } from '@apollo/client';

const GET_COMPANY = gql`
    query GetCompany($id: ID!) {
        company(id: $id) {
            id
            name
            address
            phone_number
            default_geofence
            eta_period
            time_to_deliver
            shipping_order_first_delay_alert
            date_format
            enable_temperature
            shipping_order_delay_frequence_alert
            order_delivery_delay
            optimise_real_departure_date
            asset_branch_assignment_by_position
            battery_life_limit
            accuracy
            enable_shock
            enable_humidity
            enable_branch
            enable_api
            enable_shipping_orders
            enable_cycle_analytic
            enable_pair_site
            early_departure
            legal_reference
            logo_path
            enable_custom_logo
            likely_deviated_max
            enable_custom_parameters
            enable_automated_actions
            enable_tableau
            tableau_workbook_url
            is_sms_enabled
            sms_quota
            sms_counter
            is_departure_start_enabled
            auto_refresh_interval
            degraded_position_msg_without_position
            degraded_position_distance_min
            period_life_battery
            readjustment_limit
            logo_url
            approximate_geolocation_interval
            import_error_recipients
            enable_shipping_order_import_replace
            enable_shipping_order_import_preserve_asset
        }
    }
`;

const GET_COMPANIES = gql`
    query Companies($first: Int, $page: Int, $search: Mixed, $orderBy: [QueryCompaniesOrderByRelationOrderByClause!]) {
        companies(first: $first, page: $page, orderBy: $orderBy, where: {
            OR: [
                {
                    column: "name"
                    operator: LIKE
                    value: $search
                }
            ]
        }) {
            paginatorInfo {
                total
                currentPage
                lastPage
                perPage
            }
            data {
                id
                name
                address
                phone_number
                accuracy
                battery_life_limit
                users {
                    paginatorInfo {
                        total
                    }
                }
                assets: assetsWithoutGlobalScopes {
                    paginatorInfo {
                        total
                    }
                }
                mags: magsWithoutGlobalScopes {
                    paginatorInfo {
                        total
                    }
                }
            }
        }
    }
`;

const CREATE_COMPANY = gql`
    mutation CreateCompany(
        $name: String
        $address: String
        $phone_number: String
        $default_geofence: Int
        $eta_period: Int
        $time_to_deliver: Int
        $shipping_order_first_delay_alert: Int
        $date_format: String
        $enable_temperature: Boolean
        $shipping_order_delay_frequence_alert: Int
        $order_delivery_delay: Int
        $optimise_real_departure_date: Boolean
        $asset_branch_assignment_by_position: Boolean
        $battery_life_limit: Int
        $accuracy: Int
        $enable_shock: Boolean
        $enable_humidity: Boolean
        $enable_branch: Boolean
        $enable_api: Boolean
        $enable_shipping_orders: Boolean
        $enable_cycle_analytic: Boolean
        $enable_pair_site: Boolean
        $early_departure: Int
        $legal_reference: String
        $logo_path: String
        $enable_custom_logo: Boolean
        $likely_deviated_max: Int
        $enable_custom_parameters: Boolean
        $enable_automated_actions: Boolean
        $enable_tableau: Boolean
        $tableau_workbook_url: String
        $is_sms_enabled: Boolean
        $sms_quota: Int
        $is_departure_start_enabled: Boolean
        $auto_refresh_interval: Int
        $degraded_position_msg_without_position: Int
        $degraded_position_distance_min: Int
        $period_life_battery: Int
        $readjustment_limit: Int
        $file: Upload
        $approximate_geolocation_interval: Int
        $import_error_recipients: String
        $enable_shipping_order_import_replace: Boolean
        $enable_shipping_order_import_preserve_asset: Boolean
    ) {
        createCompany(input: {
            name: $name
            address: $address
            phone_number: $phone_number
            default_geofence: $default_geofence
            eta_period: $eta_period
            time_to_deliver: $time_to_deliver
            shipping_order_first_delay_alert: $shipping_order_first_delay_alert
            date_format: $date_format
            enable_temperature: $enable_temperature
            shipping_order_delay_frequence_alert: $shipping_order_delay_frequence_alert
            order_delivery_delay: $order_delivery_delay
            optimise_real_departure_date: $optimise_real_departure_date
            asset_branch_assignment_by_position: $asset_branch_assignment_by_position
            battery_life_limit: $battery_life_limit
            accuracy: $accuracy
            enable_shock: $enable_shock
            enable_humidity: $enable_humidity
            enable_branch: $enable_branch
            enable_api: $enable_api
            enable_shipping_orders: $enable_shipping_orders
            enable_cycle_analytic: $enable_cycle_analytic
            enable_pair_site: $enable_pair_site
            early_departure: $early_departure
            legal_reference: $legal_reference
            logo_path: $logo_path
            enable_custom_logo: $enable_custom_logo
            likely_deviated_max: $likely_deviated_max
            enable_custom_parameters: $enable_custom_parameters
            enable_automated_actions: $enable_automated_actions
            enable_tableau: $enable_tableau
            tableau_workbook_url: $tableau_workbook_url
            is_sms_enabled: $is_sms_enabled
            sms_quota: $sms_quota
            is_departure_start_enabled: $is_departure_start_enabled
            auto_refresh_interval: $auto_refresh_interval
            degraded_position_msg_without_position: $degraded_position_msg_without_position
            degraded_position_distance_min: $degraded_position_distance_min
            period_life_battery: $period_life_battery
            readjustment_limit: $readjustment_limit
            file: $file
            approximate_geolocation_interval: $approximate_geolocation_interval
            import_error_recipients: $import_error_recipients
            enable_shipping_order_import_replace: $enable_shipping_order_import_replace
            enable_shipping_order_import_preserve_asset: $enable_shipping_order_import_preserve_asset
        }) {
            id
        }
    }
`;

const UPDATE_COMPANY = gql`
    mutation UpdateCompany(
        $id: ID!
        $name: String
        $address: String
        $phone_number: String
        $default_geofence: Int
        $eta_period: Int
        $time_to_deliver: Int
        $shipping_order_first_delay_alert: Int
        $date_format: String
        $enable_temperature: Boolean
        $shipping_order_delay_frequence_alert: Int
        $order_delivery_delay: Int
        $optimise_real_departure_date: Boolean
        $asset_branch_assignment_by_position: Boolean
        $battery_life_limit: Int
        $accuracy: Int
        $enable_shock: Boolean
        $enable_humidity: Boolean
        $enable_branch: Boolean
        $enable_api: Boolean
        $enable_shipping_orders: Boolean
        $enable_cycle_analytic: Boolean
        $enable_pair_site: Boolean
        $early_departure: Int
        $legal_reference: String
        $logo_path: String
        $enable_custom_logo: Boolean
        $likely_deviated_max: Int
        $enable_custom_parameters: Boolean
        $enable_automated_actions: Boolean
        $enable_tableau: Boolean
        $tableau_workbook_url: String
        $is_sms_enabled: Boolean
        $sms_quota: Int
        $is_departure_start_enabled: Boolean
        $auto_refresh_interval: Int
        $degraded_position_msg_without_position: Int
        $degraded_position_distance_min: Int
        $period_life_battery: Int
        $readjustment_limit: Int
        $file: Upload
        $approximate_geolocation_interval: Int
        $import_error_recipients: String
        $enable_shipping_order_import_replace: Boolean
        $enable_shipping_order_import_preserve_asset: Boolean
    ) {
        updateCompany(input: {
            id: $id
            name: $name
            address: $address
            phone_number: $phone_number
            default_geofence: $default_geofence
            eta_period: $eta_period
            time_to_deliver: $time_to_deliver
            shipping_order_first_delay_alert: $shipping_order_first_delay_alert
            date_format: $date_format
            enable_temperature: $enable_temperature
            shipping_order_delay_frequence_alert: $shipping_order_delay_frequence_alert
            order_delivery_delay: $order_delivery_delay
            optimise_real_departure_date: $optimise_real_departure_date
            asset_branch_assignment_by_position: $asset_branch_assignment_by_position
            battery_life_limit: $battery_life_limit
            accuracy: $accuracy
            enable_shock: $enable_shock
            enable_humidity: $enable_humidity
            enable_branch: $enable_branch
            enable_api: $enable_api
            enable_shipping_orders: $enable_shipping_orders
            enable_cycle_analytic: $enable_cycle_analytic
            enable_pair_site: $enable_pair_site
            early_departure: $early_departure
            legal_reference: $legal_reference
            logo_path: $logo_path
            enable_custom_logo: $enable_custom_logo
            likely_deviated_max: $likely_deviated_max
            enable_custom_parameters: $enable_custom_parameters
            enable_automated_actions: $enable_automated_actions
            enable_tableau: $enable_tableau
            tableau_workbook_url: $tableau_workbook_url
            is_sms_enabled: $is_sms_enabled
            sms_quota: $sms_quota
            is_departure_start_enabled: $is_departure_start_enabled
            auto_refresh_interval: $auto_refresh_interval
            degraded_position_msg_without_position: $degraded_position_msg_without_position
            degraded_position_distance_min: $degraded_position_distance_min
            period_life_battery: $period_life_battery
            readjustment_limit: $readjustment_limit
            file: $file
            approximate_geolocation_interval: $approximate_geolocation_interval
            import_error_recipients: $import_error_recipients
            enable_shipping_order_import_replace: $enable_shipping_order_import_replace
            enable_shipping_order_import_preserve_asset: $enable_shipping_order_import_preserve_asset
        }) {
            id
        }
    }
`;

export { GET_COMPANY, GET_COMPANIES, CREATE_COMPANY, UPDATE_COMPANY };
