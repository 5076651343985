import { DateRangePicker } from 'front-library';
import styled from 'styled-components';

export const PanelContainer = styled.div`
    z-index: 1300;
    position: fixed;
`;

export const Content = styled.div`
    padding: 20px;
    max-height: 65vh;
`;

export const Head = styled.div`
    display: flex;
    align-items: center;
`;

export const Title = styled.h1`
    font-size: 20px;
    color: #002B2B;
    flex-grow: 1;
`;

export const State = styled.div`
    font-size: 14px;
    color: #002B2B;
`;

export const MagIdLabel = styled.div`
    font-size: 14px;
    color: #707070;
    margin-top: 13px;
`;

export const MagId = styled.div`
    font-size: 14px;
    color: #002B2B;
`;

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 21px;
`;

export const LastActions = styled.div`
    display: flex;
    margin-top: 21px;
`;

export const LastActionBlock = styled.div`
    flex-grow: 1;
`;

export const LastActionLabel = styled.div`
    font-size: 14px;
    color: #707070;
`;

export const LastActionContent = styled.div`
    font-size: 14px;
    color: #002B2B;
`;

export const AssetJourneyLabel = styled.div`
    margin-top: 34px;
    margin-bottom: 10px;
    color: #002B2B;
    font-family: 'Muli semi';
    font-size: 16px;
`;

export const Button = styled.div`
    background-color: red;
`;

export const BtnInATabContainer = styled.div`
    margin: 9px;
`;

export const JourneyRangePicker = styled(DateRangePicker)`
margin-bottom: 15px;
`;
