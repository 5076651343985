import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ErrorMessage, SuccessMessage } from 'front-library';

import { useContext } from 'react';
import { Actions, PanelContext } from '../../../contexts/PanelContext';
import { Loading } from '../../molecules/Loading/Loading';
import { ImportForm } from './ImportForm';
import { IMPORT_SHIPPING_ORDER } from './graphql';
import { ImportPanel } from './styled-components';
import { getErrorTranslationKey } from './utils';

const INITIAL_FORM_VALUES = {
  selectedFile: null,
  preserveAsset: false,
  replaceShippingOrders: false,
};

const ShippingOrderImportPanel = () => {
  const { t } = useTranslation();

  const [statePanel, dispatch] = useContext(PanelContext);
  const [importShippingOrder, { loading }] = useMutation(IMPORT_SHIPPING_ORDER);

  const [skipDuplicatesChecks, setSkipDuplicatesChecks] = useState(false);
  const [formValues, setFormValues] = useState({ ...INITIAL_FORM_VALUES });

  if (!statePanel.shippingOrderImportPanel.show) {
    return <div />;
  }

  const {
    existingRef,
    existingRefOrderEmptyAsset,
    errors,
    importedLinesCount,
    success,
    replacedShippingOrdersCount,
    newShippingOrdersCount,
  } = statePanel;

  const handleImport = async () => {
    dispatch({ type: Actions.resetMessages });

    try {
      const dataQuery = await importShippingOrder({
        variables: {
          file: formValues.selectedFile,
          replaceShippingOrder: formValues.replaceShippingOrders,
          preserveAsset: formValues.preserveAsset,
          skipDuplicatesChecks: skipDuplicatesChecks,
        },
      });
      const data = dataQuery.data.importShippingOrder;

      setSkipDuplicatesChecks(true);

      if ('success' === data.status) {
        toast.success(t('shippingOrders:importPanel.toasts.success'));
        dispatch({
          type: Actions.success,
          payload: {
            importedLinesCount: data.total_line,
            newShippingOrdersCount: data.new_shipping_orders_count,
            replacedShippingOrdersCount: data.replacement_shipping_orders_count,
          },
        });
        dispatch({ type: Actions.refresh, payload: true });
        setSkipDuplicatesChecks(false);

        return;
      }

      if (!data.existing_ref && !data.existing_ref_order_empty_asset) {
        toast.error(t('shippingOrders:importPanel.toasts.error'));
        setSkipDuplicatesChecks(false);
      }

      dispatch({
        type: Actions.errors,
        payload: {
          columnsMatching: data.columns_matching,
          errors: data.errors.map(
            (e) =>
              t('shippingOrders:importPanel.errorMessageLine', {
                line: e.line,
              }) +
              t(getErrorTranslationKey(e), {
                limit: 2,
                reference: e.modelRef,
              }),
          ),
          existingRef: data.existing_ref,
          existingRefOrderEmptyAsset: data.existing_ref_order_empty_asset,
        },
      });

      if (data.existing_ref_order_empty_asset) {
        setFormValues({ ...formValues, replaceShippingOrders: true });
      }
    } catch (e) {
      console.error(e);
      toast.error(t('shippingOrders:importPanel.toasts.error'));
    }
  };

  return (
    <ImportPanel>
      <ImportForm
        existingRef={statePanel.existingRef}
        existingRefOrderEmptyAsset={statePanel.existingRefOrderEmptyAsset}
        formValues={formValues}
        onImport={handleImport}
        onClose={() => {
          dispatch({ type: Actions.resetMessages });
          dispatch({ type: Actions.panel, panel: 'shippingOrderImportPanel' });
          setSkipDuplicatesChecks(false);
          setFormValues({ ...INITIAL_FORM_VALUES });
        }}
        setFormValues={setFormValues}
      />
      {loading && <Loading />}

      {!existingRef && !existingRefOrderEmptyAsset && errors?.length > 0 && (
        <>
          <ErrorMessage
            hideCross={true}
            light={true}
            text={
              <Trans
                components={{ strong: <strong /> }}
                i18nKey="shippingOrders:importPanel.messages.error"
                values={{
                  filename: formValues.selectedFile.name,
                }}
              />
            }
          />
          <div>
            {0 !== errors.length && (
              <ErrorMessage key={errors[0]} light={true} text={errors[0]} />
            )}
            {/* For now we only show one error, but later we are going to show it all */}
            {/* {errors.map((e) => (
              <ErrorMessage key={e} light text={e} />
            ))} */}
          </div>
        </>
      )}

      {success && 0 !== replacedShippingOrdersCount && (
        <SuccessMessage
          light={true}
          text={
            <Trans
              components={{ strong: <strong /> }}
              i18nKey="shippingOrders:importPanel.messages.successWithReplaced"
              values={{
                filename: formValues.selectedFile.name,
                importedLinesCount,
                newShippingOrdersCount,
                replacedShippingOrdersCount,
              }}
            />
          }
        />
      )}

      {success && 0 === replacedShippingOrdersCount && (
        <SuccessMessage
          light={true}
          text={
            <Trans
              components={{ strong: <strong /> }}
              i18nKey="shippingOrders:importPanel.messages.success"
              values={{
                filename: formValues.selectedFile.name,
                importedLinesCount,
                newShippingOrdersCount,
              }}
            />
          }
        />
      )}
    </ImportPanel>
  );
};

export { ShippingOrderImportPanel };
