import qs from 'qs';
import React, { useReducer } from 'react';

export const Actions = {
  panel: 'panel',
  refresh: 'refresh',
  success: 'success',
  errors: 'errors',
  resetMessages: 'resetMessages',
};

function reducer(state, action) {
  switch (action.type) {
    case Actions.panel:
      return {
        ...state,
        [action.panel]: {
          show: !state[action.panel].show,
        },
      };
    case Actions.refresh:
      return {
        ...state,
        refreshPage: action.payload,
      };
    case Actions.success:
      return {
        ...state,
        existingRef: false,
        existingRefOrderEmptyAsset: false,
        importedLinesCount: action.payload.importedLinesCount,
        newShippingOrdersCount: action.payload.newShippingOrdersCount,
        replacedShippingOrdersCount: action.payload.replacedShippingOrdersCount,
        success: true,
      };
    case Actions.errors:
      return {
        ...state,
        columnsMatching: action.payload.columnsMatching,
        errors: action.payload.errors,
        existingRef: action.payload.existingRef,
        existingRefOrderEmptyAsset: action.payload.existingRefOrderEmptyAsset,
        success: false,
      };
    case Actions.resetMessages:
      return {
        ...state,
        success: false,
        errors: [],
        existingRef: false,
        existingRefOrderEmptyAsset: false,
      };
    default:
      return { ...state };
  }
}

const initialState = () => {
  const parameters = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  return {
    shippingOrderImportPanel: {
      show: typeof parameters.shippingOrderImportPanel !== 'undefined',
    },
    panelSiteForm: {
      show: typeof parameters.panelSiteForm !== 'undefined',
    },
    panelUserForm: {
      show: typeof parameters.panelUserForm !== 'undefined',
    },
    refreshPage: false,
    success: false,
    errors: [],
    existingRef: false,
    existingRefOrderEmptyAsset: false,
    newShippingOrdersCount: null,
    replacedShippingOrdersCount: null,
    importedLinesCount: null,
  };
};

const PanelContext = React.createContext({});

const PanelProvider = ({ children }) => {
  const contextValue = useReducer(reducer, initialState());

  return (
    <PanelContext.Provider value={contextValue}>
      {children}
    </PanelContext.Provider>
  );
};

export { PanelContext, PanelProvider };
