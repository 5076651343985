import styled from 'styled-components';

export const Actions = styled.div`
    display: flex;
    gap: 10px;
    padding-top: 10px;

    > button {
        width: 100%;
    }
`;
