import moment from 'moment';

const GRAPHQL_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export function mapFluxConfigFormDataToGraphql(formData, offSiteId) {
  const graphqlData = {
    flux: {
      connect: formData.fluxId,
    },
    name: formData.name,
    period_from: formData.periodFrom?.format(GRAPHQL_DATE_FORMAT),
    period_to: formData.periodTo?.format(GRAPHQL_DATE_FORMAT),
    review_date: formData.reviewDate?.format(GRAPHQL_DATE_FORMAT),
    comment: formData.comment,
    flux_configuration_sites: {
      upsert: mapFluxConfigurationSitesFormDataToGraphql(
        formData.fluxConfigurationSites,
        offSiteId,
      ),
      delete: formData.fluxConfigurationSitesDelete,
    },
  };

  if (formData.id) {
    graphqlData.id = formData.id;
  }

  return graphqlData;
}

export function mapGraphqlToFluxConfigFormData(fluxConfig) {
  const formData = {
    id: fluxConfig.id,
    fluxId: fluxConfig.flux.id,
    name: fluxConfig.name,
    periodFrom: moment(fluxConfig.period_from),
    periodTo: moment(fluxConfig.period_to),
    reviewDate: fluxConfig?.review_date ? moment(fluxConfig.review_date) : null,
    comment: fluxConfig.comment,
    fluxConfigurationSites: mapGraphqlToFluxConfigurationSitesFormData(
      fluxConfig.flux_configuration_sites.data,
    ),
    fluxConfigurationSitesDelete: [],
    activeStep: 0,
    errors: {},
  };

  return formData;
}

function mapGraphqlToFluxConfigurationSitesFormData(fluxConfigurationSites) {
  const mapped = [];

  for (let i = 0, l = fluxConfigurationSites.length; i < l; i += 2) {
    const current = fluxConfigurationSites[i];
    const data = {
      id: current.id,
      site: {
        value: current.site.id,
        title: current.site.name,
      },
      timeOnSite: formatSqlTime(current.time_on_site),
      uniqId: Date.now() + i,
      deleted_at: current.site.deleted_at,
    };

    if (i + 1 < fluxConfigurationSites.length - 1) {
      const next = fluxConfigurationSites[i + 1];
      data.transportId = next.id;
      data.transportTime = formatSqlTime(next.time_on_site);
    }

    mapped.push(data);
  }

  return mapped;
}

function mapFluxConfigurationSitesFormDataToGraphql(
  fluxConfigurationSites,
  offSiteId,
) {
  return fluxConfigurationSites
    .flatMap((fcs, index) => {
      const current = [
        {
          site: {
            connect: fcs.site.value,
          },
          time_on_site: formatTimeToSql(fcs.timeOnSite),
        },
      ];

      if (fcs.id) {
        current[0].id = fcs.id;
      }

      if (index < fluxConfigurationSites.length - 1) {
        current.push({
          site: {
            connect: offSiteId,
          },
          time_on_site: formatTimeToSql(fcs.transportTime),
        });

        if (fcs.transportId) {
          current[1].id = fcs.transportId;
        }
      }

      return current;
    })
    .map((fcs, index) => ({
      ...fcs,
      order: index,
    }));
}

function formatTimeToSql(time) {
  const [d, h, m] = time.split(':');

  return `${String(+d * 24 + +h).padStart(2, '0')}:${m}:00`;
}

export function formatSqlTime(time) {
  const [h, m] = time.split(':');

  return `${String(Math.floor(+h / 24)).padStart(2, '0')}:${String(
    +h % 24,
  ).padStart(2, '0')}:${m}`;
}
