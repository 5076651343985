import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Grid, Text } from 'front-library';
import DeleteIcon from 'front-library/build/img/trash.svg';

import { MeContext } from '../../../../contexts/MeContext';
import { Loading } from '../../../molecules/Loading/Loading';
import { Details } from '../Details/Details';
import { NextEstimatedStop } from '../NextEstimatedStop/NextEstimatedStop';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { Status } from '../Status/Status';

import { CANCEL_SHIPPING_ORDER } from './graphql';
import loadingIcon from './loading-icon.svg';
import {
  GridRow,
  LoadingImage,
  TitleCell,
  WarningImage,
} from './styled-components';
import warningIcon from './warning-icon.svg';

const Row = ({ shippingOrder: masterShippingOrder, defaultOpen, refetch }) => {
  const { t } = useTranslation();
  const { me: user } = useContext(MeContext);
  const [cancelShippingOrder, { loading: cancelLoading }] = useMutation(
    CANCEL_SHIPPING_ORDER,
  );
  const [isRecomputing, setIsRecomputing] = useState(false);

  const nextEstimatedStop = useMemo(() => {
    if (
      'delivery_in_progress' !== masterShippingOrder.shipping_order_status.name
    ) {
      return null;
    }

    return masterShippingOrder.tour
      ? masterShippingOrder.tour.shipping_orders.data.find((so) => so.human_eta)
          ?.human_eta
      : masterShippingOrder.human_eta;
  }, [masterShippingOrder]);

  const inProgresssShippingOrder = useMemo(() => {
    let inProgresss = masterShippingOrder;

    if (masterShippingOrder.tour) {
      inProgresss = masterShippingOrder.tour.shipping_orders.data.find(
        (so) =>
          !so.real_arrival_timestamp &&
          so.real_departure_timestamp &&
          so.human_eta &&
          so.shipping_order_status.name !== 'on_site',
      );
    }

    return inProgresss;
  }, [masterShippingOrder]);

  // Show modal to let the user recompute a shipping order after a geofence updated.
  const showRecomputeModal = useCallback(
    () =>
      confirmAlert({
        message: t('shippingOrders:recomputeShippingOrder'),
        buttons: [
          {
            label: t('common:yes'),
            onClick: () => {
              setIsRecomputing(true);
              fetch(
                `/shipping-orders/${masterShippingOrder.id}/update-positions`,
                {
                  method: 'GET',
                  headers: new Headers({
                    'Content-Type': 'application/json',
                  }),
                },
              )
                .then((response) => {
                  if (200 === response.status) {
                    toast.success(t('shippingOrders:recomputeSuccess'));
                  } else {
                    toast.error(t('shippingOrders:recomputeError'));
                  }
                })
                .catch(() => toast.error(t('shippingOrders:recomputeError')))
                .finally(() => {
                  refetch();
                  setIsRecomputing(false);
                });
            },
          },
          {
            label: t('common:no'),
          },
        ],
      }),
    [t, masterShippingOrder, refetch],
  );

  // Used to know if we display the icon to recompute the shipping order.
  const canRecomputeShippingOrder = useMemo(() => {
    if (
      !user.rolesName.includes('Administrator') ||
      !masterShippingOrder?.assets?.paginatorInfo?.count
    ) {
      return false;
    }

    if (masterShippingOrder?.is_recomputing) {
      return false;
    }

    if (
      masterShippingOrder?.geofence_updated ||
      masterShippingOrder?.are_assets_updated
    ) {
      return true;
    }

    return !!masterShippingOrder?.tour?.shipping_orders?.data?.find(
      (so) => so.geofence_updated || so.are_assets_updated,
    );
  }, [masterShippingOrder, user]);

  const renderRecomputeIcon = useCallback(() => {
    if (masterShippingOrder.is_recomputing) {
      return <LoadingImage src={loadingIcon} />;
    }

    if (canRecomputeShippingOrder) {
      return <WarningImage onClick={showRecomputeModal} src={warningIcon} />;
    }

    return null;
  }, [masterShippingOrder, showRecomputeModal, canRecomputeShippingOrder]);

  const handleCancelShippingOrder = useCallback(
    () =>
      confirmAlert({
        title: t('shippingOrders:confirm.cancel.title'),
        message: t('shippingOrders:confirm.cancel.message', {
          reference: masterShippingOrder.reference,
        }),
        buttons: [
          {
            label: t('common:yes'),
            onClick: async () => {
              try {
                await cancelShippingOrder({
                  variables: { id: masterShippingOrder.id },
                });
                await refetch();
                toast.success(
                  t('shippingOrders:shippingOrder.cancel.success', {
                    reference: masterShippingOrder.reference,
                  }),
                );
              } catch (e) {
                console.error(e);
                toast.error(t('shippingOrders:shippingOrder.cancel.error'));
              }
            },
          },
          {
            label: t('common:no'),
          },
        ],
      }),
    [masterShippingOrder, t, cancelShippingOrder, refetch],
  );

  return (
    <>
      {(cancelLoading || isRecomputing) && <Loading />}
      <GridRow
        expandPanel={
          <Details
            nextEstimatedStop={nextEstimatedStop}
            masterShippingOrder={masterShippingOrder}
            refetch={refetch}
          />
        }
        expanded={defaultOpen}
        customLine={<ProgressBar shippingOrder={masterShippingOrder} />}
      >
        <TitleCell size="md">
          <p>
            <Text.Bold>{masterShippingOrder.reference}</Text.Bold>
          </p>
          {renderRecomputeIcon()}
        </TitleCell>
        <Grid.Cell size="md">
          <NextEstimatedStop
            nextEstimatedStop={nextEstimatedStop}
            masterShippingOrder={masterShippingOrder}
            inProgressShippingOrder={inProgresssShippingOrder}
          />
        </Grid.Cell>
        <Grid.Cell size="xl">
          <Status shippingOrder={masterShippingOrder} />
        </Grid.Cell>
        <Grid.Cell size="xs">
          <Grid.IconButton
            imgSrc={DeleteIcon}
            onClick={handleCancelShippingOrder}
          />
        </Grid.Cell>
      </GridRow>
    </>
  );
};

Row.propTypes = {
  shippingOrder: PropTypes.object.isRequired,
  defaultOpen: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired,
};

export { Row };
