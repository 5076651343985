import moment from 'moment';

export const extractFilenameFromUrl = (url) =>
  new URL(url).searchParams
    .get('response-content-disposition')
    .replace(/.*=/, '');

const convertValue = (value) => {
  const type = typeof value;

  switch (type) {
    case 'string':
      return value ? { value, type: 'string' } : null;
    case 'number':
      return value ? { value: String(value), type: 'number' } : null;
    case 'boolean':
      return { value: String(value), type: 'boolean' };
    case 'object': {
      if (moment.isMoment(value)) {
        return { value: value.format('YYYY-MM-DD'), type: 'date' };
      }

      //file
      return value.file && 'object' === typeof value.file
        ? { value: value.file, type: 'file' } // new file uploaded
        : value.filename
          ? {} // on edit, file not changed, no null return to don't erase file
          : null; // no file
    }
    default:
      throw new Error('INVALID_TYPE');
  }
};

export const mapCustomParametersValues = (values) => ({
  sync: Object.keys(values)
    .map((key) => {
      const value = convertValue(values[key]);

      return value
        ? {
            id: key,
            ['file' === value.type ? 'file' : 'value']: value.value,
          }
        : null;
    })
    .filter((v) => v),
});

export const convertStringToValue = (value, type) => {
  switch (type) {
    case 'string':
      return value;
    case 'number':
      return +value;
    case 'boolean':
      return 'true' === value;
    case 'date':
      return moment(value, 'YYYY-MM-DD');
    case 'file':
      return {
        filename: extractFilenameFromUrl(value),
        file: value,
      };
    default:
      throw new Error('INVALID_TYPE');
  }
};
