import React from 'react';

import { Grid, Text } from 'front-library';
import EditIcon from 'front-library/build/img/icon-modify-green-2.svg';
import DeleteIcon from 'front-library/build/img/trash.svg';

const SIZES = ['l', 'md', 'l', 'xs', 'xs'];

const DataGrid = ({ columns, data, onEdit, onDelete }) => {
  return (
    <Grid.Grid>
      <Grid.Header>
        {columns.map((c, i) => (
          <Grid.Cell size={SIZES[i]} key={c}>
            {c}
          </Grid.Cell>
        ))}
        <Grid.Cell size={SIZES[3]} />
        <Grid.Cell size={SIZES[4]} />
      </Grid.Header>
      {data.map((automatedAction) => (
        <Grid.Row key={automatedAction.id}>
          <Grid.Cell size={SIZES[0]}>
            <Text.Bold>{automatedAction.name}</Text.Bold>
          </Grid.Cell>
          <Grid.Cell size={SIZES[1]}>
            {automatedAction.created_at?.split(' ')[0]}
          </Grid.Cell>
          <Grid.Cell size={SIZES[2]}>
            {automatedAction.created_by?.surname}{' '}
            {automatedAction.created_by?.name}
          </Grid.Cell>
          <Grid.Cell size={SIZES[3]} alignment="right">
            <Grid.IconButton
              imgSrc={EditIcon}
              onClick={() => onEdit(automatedAction)}
            />
          </Grid.Cell>
          <Grid.Cell size={SIZES[4]} alignment="right">
            <Grid.IconButton
              imgSrc={DeleteIcon}
              onClick={() => onDelete(automatedAction)}
            />
          </Grid.Cell>
        </Grid.Row>
      ))}
    </Grid.Grid>
  );
};

export { DataGrid };
