import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useReducer, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, ErrorMessage, Loading } from 'front-library';

import { useQueryGraphQl } from '../../../../hooks/useQueryGraphQl';
import { routes } from '../../../../routes';
import * as Actions from '../../../../stores/shippingOrder/actions';
import {
  INITIAL_STATE,
  deliveryReducer,
} from '../../../../stores/shippingOrder/reducer';
import { STEPS } from '../../../../stores/shippingOrder/utils';

import { Form } from '../../../atoms/Form/Form';
import { Modal } from '../../../molecules/Modal/Modal';
import { Arrival } from './Steps/Arrival';
import { Checkpoints } from './Steps/Checkpoints';
import { Departure } from './Steps/Departure';
import { Orders } from './Steps/Orders';
import { ShippingOrder } from './Steps/ShippingOrder';
import { Summary } from './Steps/Summary';
import {
  COUNT_SHIPPING_ORDERS_BY_REFERENCE,
  CREATE_SHIPPING_ORDER,
} from './graphql';
import { NextPreviousContainer, Stepper } from './styled-components';
import { mapFormValuesToGraphQl } from './utils';

const CreationModal = ({ onClose, refetchShippingOrders }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(deliveryReducer, INITIAL_STATE);
  const [executeDuplicateQuery, setExecuteDuplicateQuery] = useState(false);
  const [error, setError] = useState('');

  const { activeStep, deliveryModel, deliveryReference } = state;

  const {
    data: isShippingOrderDuplicated,
    setVariable: setCheckDuplicateVariable,
    loading: checkDuplicateLoading,
  } = useQueryGraphQl(
    COUNT_SHIPPING_ORDERS_BY_REFERENCE,
    {},
    {},
    !executeDuplicateQuery,
  );

  const [createShippingOrderMutation, { loading: createShippingOrderLoading }] =
    useMutation(CREATE_SHIPPING_ORDER);

  const isSummaryStep = useMemo(
    () =>
      STEPS.summary === activeStep ||
      (deliveryModel && STEPS.checkpoint === activeStep),
    [activeStep, deliveryModel],
  );

  // If the shipping order reference is unique go to the next step after check
  useEffect(() => {
    if (isShippingOrderDuplicated?.shipping_orders?.paginatorInfo) {
      if (
        0 === isShippingOrderDuplicated?.shipping_orders?.paginatorInfo?.count
      ) {
        dispatch({ type: Actions.INCREMENT_ACTIVE_STEP });
      }

      dispatch({
        type: Actions.SET_DELIVERY_REFERENCE_TOUCHED_ACTION,
        payload: false,
      });
    }
  }, [isShippingOrderDuplicated]);

  const goToNextStep = () => {
    if (STEPS.shippingOrder === activeStep && state.deliveryReferenceTouched) {
      // Check shipping order reference
      dispatch({
        type: Actions.SET_IS_NEXT_AVAILABLE,
        payload: false,
      });
      setCheckDuplicateVariable('reference', deliveryReference.trim());
      setExecuteDuplicateQuery(true);
    } else {
      dispatch({ type: Actions.INCREMENT_ACTIVE_STEP });
    }
  };

  const createShippingOrder = async () => {
    try {
      await createShippingOrderMutation({
        variables: mapFormValuesToGraphQl(state),
      });
      setError(null);
      toast.success(
        t('shippingOrders:modal.created.success', {
          reference: deliveryReference,
        }),
      );
      await refetchShippingOrders();
      navigate(routes.shippingOrders.path);
    } catch (error) {
      console.error(error);
      setError(error.message);

      toast.error(
        t('shippingOrders:modal.created.error', {
          reference: deliveryReference,
        }),
      );
    }
  };

  const renderStep = () => {
    if (STEPS.shippingOrder === activeStep) {
      return (
        <ShippingOrder
          deliveryReference={deliveryReference}
          model={deliveryModel}
          shippingOrderExistsByReference={
            !!isShippingOrderDuplicated?.shipping_orders?.paginatorInfo?.count
          }
          dispatch={dispatch}
        />
      );
    }

    if (STEPS.order === activeStep) {
      return <Orders orders={state.orders} dispatch={dispatch} />;
    }

    if (STEPS.departure === activeStep) {
      return <Departure state={state} dispatch={dispatch} />;
    }

    if (!deliveryModel && STEPS.checkpoint === activeStep) {
      return (
        <Checkpoints
          checkpoints={state.checkpoints}
          departureDate={state.departureDate}
          dispatch={dispatch}
        />
      );
    }

    if (!deliveryModel && STEPS.arrival === activeStep) {
      return <Arrival state={state} dispatch={dispatch} />;
    }

    if (
      (deliveryModel && STEPS.checkpoint === activeStep) ||
      STEPS.summary === activeStep
    ) {
      return (
        <Summary
          state={state}
          editStep={(step) =>
            dispatch({
              type: Actions.DECREMENT_ACTIVE_STEP,
              payload: step,
            })
          }
        />
      );
    }

    return null;
  };

  return (
    <Modal onClose={onClose} title={t('shippingOrders:modal.title')}>
      {(checkDuplicateLoading || createShippingOrderLoading) && <Loading />}

      <Stepper
        stepsNumber={(deliveryModel ? STEPS.checkpoint : STEPS.summary) + 1}
        activeStep={activeStep}
      />

      <Form
        hideCancel={true}
        hideSubmit={true}
        onSubmit={() =>
          isSummaryStep ? createShippingOrder() : goToNextStep()
        }
      >
        {renderStep()}

        {/* Errors */}
        {error && <ErrorMessage text={error} />}

        <NextPreviousContainer>
          {/* Previous */}
          {0 < activeStep && !isSummaryStep && (
            <Button
              onClick={() =>
                dispatch({
                  type: Actions.DECREMENT_ACTIVE_STEP,
                })
              }
              text={t('shippingOrders:modal.previous')}
              variant="blue"
            />
          )}
          {/* Next */}
          <Button
            disabled={!state.isNextAvailable}
            text={t(
              isSummaryStep
                ? 'shippingOrders:importPanel.createButtontext'
                : 'shippingOrders:modal.next',
            )}
            type="submit"
          />
        </NextPreviousContainer>
      </Form>
    </Modal>
  );
};

CreationModal.propTypes = {
  onClose: PropTypes.func,
  refetchShippingOrders: PropTypes.func,
};

export { CreationModal };
