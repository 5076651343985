import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { Grid } from 'front-library';

import { Row } from '../Row/Row';

import { Container } from './styled-components';

const List = ({ shippingOrders, refetch }) => {
  /**
   * Here we create a key by concatenaiting all shipping orders ids.
   * The goal is to re-render the rows only when the shipping orders changes.
   * I did this because if we put only the shipping order as key, when you change
   * search criterias, if the shipping order was still in the new results the panel
   * stayed open which is not a desired behaviour. I also tried to concatenate
   * the shipping order id with Date.now but it trigerred unnecessary renders.
   */
  const renderKey = useMemo(
    () =>
      shippingOrders?.length
        ? shippingOrders.reduce((acc, so) => acc + so.id, '')
        : '0',
    [shippingOrders],
  );

  return (
    <Container>
      <Grid.Grid>
        {shippingOrders?.map((so) => (
          <Row
            shippingOrder={so}
            key={so.id + renderKey}
            refetch={refetch}
            defaultOpen={1 === shippingOrders.length}
          />
        ))}
      </Grid.Grid>
    </Container>
  );
};

List.propTypes = {
  shippingOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
  refetch: PropTypes.func.isRequired,
};

export { List };
