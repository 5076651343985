export function mapCallbackToFormValues(callback = null) {
  return {
    id: callback?.id || null,
    name: callback?.name || '',
    contentType: callback?.content_type || '',
    format: callback?.format || '',
    type: callback?.type || '',
    url: callback?.url || '',
    mail: callback?.mail || '',
    itssAppId: callback?.itss_app_id || '',
    headers: callback?.headers?.length
      ? callback.headers.map((header) => ({
          uniqKey: Date.now() + Math.random(),
          id: +header.id,
          key: header.key,
          value: header.value,
        }))
      : [
          {
            uniqKey: Date.now() + Math.random(),
            key: '',
            value: '',
          },
        ],
    deletedHeaders: [],
  };
}

export function mapFormValuesToGraphql({
  id,
  name,
  contentType,
  format,
  type,
  url,
  mail,
  headers,
  deletedHeaders,
  itssAppId,
}) {
  return {
    ...(id && { id }),
    name: name || null,
    content_type: contentType,
    mail: mail || null,
    format: format || null,
    type: type || null,
    url,
    itss_app_id: itssAppId || null,
    headers: id
      ? {
          delete: deletedHeaders,
          upsert: headers
            .filter((header) => header.key && header.value)
            .map((header) => ({
              ...(header.id && { id: header.id }),
              key: header.key,
              value: header.value,
            })),
        }
      : {
          create: headers
            .filter((header) => header.key && header.value)
            .map(({ key, value }) => ({ key, value })),
        },
  };
}
