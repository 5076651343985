import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CanceledByAutomationIcon from 'front-library/build/img/canceled-auto.svg';
import ArrivedOnDeliverySiteIcon from 'front-library/build/img/icon-arrived-on-site.svg';
import CanceledIcon from 'front-library/build/img/icon-canceled.svg';
import ClosedIcon from 'front-library/build/img/icon-closed.svg';
import DeliveredIcon from 'front-library/build/img/icon-delivered.svg';
import DeliveryConfirmedIcon from 'front-library/build/img/icon-delivery-confirmed.svg';
import DeliveryInProgressIcon from 'front-library/build/img/icon-in-progress.svg';
import NewIcon from 'front-library/build/img/icon-new.svg';
import PairedIcon from 'front-library/build/img/icon-paired.svg';
import OnSiteIcon from 'front-library/build/img/on-site-tab.svg';

import { getShippingOrderTranslate } from '../../../../helpers';
import { StatusContainer } from './styled-components';

const STATUS_IMAGES = {
  new: NewIcon,
  delivery_in_progress: DeliveryInProgressIcon,
  canceled: CanceledIcon,
  canceled_by_automation: CanceledByAutomationIcon,
  paired: PairedIcon,
  delivered: DeliveredIcon,
  delivery_confirmed: DeliveryConfirmedIcon,
  arrived_on_delivery_site: ArrivedOnDeliverySiteIcon,
  on_site: OnSiteIcon,
  closed: ClosedIcon,
};

const STATUS_COLORS = {
  new: '#707070',
  delivery_in_progress: '#ef9d11',
  canceled: '#C81912',
  canceled_by_automation: '#C81912',
  paired: '#76BD22',
  delivered: '#76BD22',
  delivery_confirmed: '#76BD22',
  arrived_on_delivery_site: '#76BD22',
  on_site: '#707070',
  closed: '#ef9d11',
};

const Status = ({ shippingOrder }) => {
  const { t } = useTranslation();

  return (
    <StatusContainer
      textColor={STATUS_COLORS[shippingOrder.shipping_order_status.name]}
    >
      <img
        src={STATUS_IMAGES[shippingOrder.shipping_order_status.name]}
        alt="Shipping order status"
      />
      <p>
        {getShippingOrderTranslate(t, shippingOrder.shipping_order_status.name)}
      </p>
    </StatusContainer>
  );
};

Status.propTypes = {
  shippingOrder: PropTypes.object,
};

export { Status };
