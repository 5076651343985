import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useSearchParams } from 'react-router-dom';

import { Button, TextField } from 'front-library';
import next from 'front-library/build/img/icon-next.svg';

import { HttpError } from '../../../http/HttpError';
import { statusCodes } from '../../../http/codes';
import { routes } from '../../../routes';
import { Loading } from '../../molecules/Loading/Loading';
import { AuthPage } from '../../templates/AuthPage/AuthPage';
import {
  ForgottenLink,
  Form,
  GraySeparator,
  Links,
  Titles,
} from './styled-components';

const Login = () => {
  const { t, i18n } = useTranslation();

  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formState, setFormState] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    i18n.changeLanguage('en');
  }, [i18n.changeLanguage]);

  const login = async () => {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content');

    if (!token) {
      setError(t('error:apiServerError'));

      throw new Error('CSRF token is missing');
    }

    setLoading(true);

    await fetch(routes.login.path, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': token,
      },
      body: JSON.stringify({
        email: formState.email,
        password: formState.password,
        SAMLRequest: searchParams.get('SAMLRequest'),
        RelayState: searchParams.get('RelayState'),
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          if (
            [statusCodes.UNAUTHORIZED, statusCodes.TOO_MANY_REQUESTS].includes(
              response.status,
            )
          ) {
            response.json().then((data) => setError(data.message));

            return;
          }

          throw new HttpError(response.statusText, response.status);
        }

        /**
         * Currently our REST API auth is based on cookie. Our cookie being http only for security purpose,
         * we cannot edit it on the front end side. We prefer reloading the page to let the backend handle redirections
         * and cookie refresh.
         */
        location.reload();
      })
      .catch(() => {
        setError(t('error:apiServerError'));
      })
      .finally(() => setLoading(false));
  };

  return (
    <AuthPage showStatusPage={true}>
      <Titles>
        <h1>{t('auth:welcome')}</h1>
        <h2>{t('auth:passwordLogin')}</h2>
      </Titles>
      <Form onSubmit={login}>
        <TextField
          label={t('auth:email')}
          onChange={(email) => setFormState({ ...formState, email })}
          required={true}
          type="email"
          value={formState.email}
        />
        <TextField
          errorText={error}
          label="Password"
          onChange={(password) => setFormState({ ...formState, password })}
          required={true}
          type="password"
          value={formState.password}
        />
        <Button text="Login" type="submit" />
      </Form>
      <GraySeparator />
      <Links>
        <Link to={generatePath(routes.ssoLogin.path)}>
          <Button
            bold={true}
            img={next}
            imgPosition="right"
            text="Continue with SSO"
            variant="borderlessGreen"
          />
        </Link>
        <ForgottenLink to={generatePath(routes.forgotPassword.path)}>
          {t('auth:forgottenPassword')}
        </ForgottenLink>
      </Links>

      {loading && <Loading />}
    </AuthPage>
  );
};

export { Login };
