import _ from 'lodash';

import {
  ADD_CONFIG_TO_FLUX_ACTION,
  CLOSE_CONFIG_MODAL_ACTION,
  EDIT_CONFIG_ACTION,
  REMOVE_SITE_CONFIG_FROM_CONFIG_ACTION,
  SET_CONFIG_FORM_DATA_ACTION,
  SET_IS_LOADING_MUTATION_ACTION,
  SET_IS_NAME_MODAL_OPEN_ACTION,
} from './actions';

import { getStepWithErrors } from './utils';

function fluxReducer(state, action) {
  switch (action.type) {
    case SET_IS_LOADING_MUTATION_ACTION:
      return { ...state, isLoadingMutation: action.payload.isLoading };
    case SET_IS_NAME_MODAL_OPEN_ACTION:
      return {
        ...state,
        isNameModalOpen: action.payload.isOpen,
        selectedFlux: action.payload.selectedFlux || null,
      };
    case ADD_CONFIG_TO_FLUX_ACTION:
      return {
        ...state,
        isConfigModalOpen: true,
        fluxConfigFormState: {
          ..._.cloneDeep(FLUX_INITIAL_CONFIG_FORM_STATE),
          fluxId: action.payload.fluxId,
        },
      };
    case EDIT_CONFIG_ACTION:
      return {
        ...state,
        isConfigModalOpen: true,
        fluxConfigFormState: action.payload.fluxConfig,
      };
    case REMOVE_SITE_CONFIG_FROM_CONFIG_ACTION: {
      const index = action.payload.index;
      const fcs = _.cloneDeep(state.fluxConfigFormState.fluxConfigurationSites);
      const toDelete = fcs[index];
      const ids = [];

      if (toDelete.id) {
        ids.push(toDelete.id);
      }

      if (toDelete.transportId) {
        ids.push(toDelete.transportId);
      }

      fcs.splice(index, 1);

      return {
        ...state,
        fluxConfigFormState: {
          ..._.cloneDeep(state.fluxConfigFormState),
          fluxConfigurationSites: fcs,
          fluxConfigurationSitesDelete: [
            ...state.fluxConfigFormState.fluxConfigurationSitesDelete,
            ...ids,
          ],
        },
      };
    }
    case CLOSE_CONFIG_MODAL_ACTION:
      return {
        ...state,
        isConfigModalOpen: false,
        fluxConfigFormState: _.cloneDeep(FLUX_INITIAL_CONFIG_FORM_STATE),
      };
    case SET_CONFIG_FORM_DATA_ACTION:
      return {
        ...state,
        fluxConfigFormState: {
          ..._.cloneDeep(action.payload.formData),
          activeStep: getStepWithErrors(
            undefined === action.payload.formData.activeStep
              ? state.fluxConfigFormState.activeStep
              : action.payload.formData.activeStep,
            action.payload.formData.errors,
          ),
        },
      };
    default:
      return state;
  }
}

const FLUX_INITIAL_CONFIG_SITE_STATE = {
  id: null,
  transportId: null,
  site: null,
  timeOnSite: '00:00:00',
  transportTime: '00:00:00',
  uniqId: null,
  deleted_at: null,
};

const FLUX_INITIAL_CONFIG_FORM_STATE = {
  id: null,
  fluxId: null,
  name: '',
  periodFrom: null,
  periodTo: null,
  fluxConfigurationSites: [
    { ..._.cloneDeep(FLUX_INITIAL_CONFIG_SITE_STATE), uniqId: Date.now() - 1 },
    { ..._.cloneDeep(FLUX_INITIAL_CONFIG_SITE_STATE), uniqId: Date.now() },
  ],
  reviewDate: null,
  comment: '',
  fluxConfigurationSitesDelete: [],
  activeStep: 0,
  errors: {},
};

const FLUX_INITIAL_STATE = {
  isLoadingMutation: false,

  isNameModalOpen: false,
  isConfigModalOpen: false,

  selectedFlux: null,

  fluxConfigFormState: _.cloneDeep(FLUX_INITIAL_CONFIG_FORM_STATE),
};

export {
  fluxReducer,
  FLUX_INITIAL_STATE,
  FLUX_INITIAL_CONFIG_SITE_STATE,
  FLUX_INITIAL_CONFIG_FORM_STATE,
};
