import { routes } from './index';

const getAllowedRoutes = (user) => {
  const routesKey = Object.keys(routes);

  return routesKey
    .filter((key) => {
      if ('boolean' === typeof routes[key].allowed) {
        return routes[key].allowed;
      }

      if (user && 'function' === typeof routes[key].allowed) {
        return routes[key].allowed(user);
      }

      return false;
    })
    .map((key) => ({
      key,
      ...routes[key],
    }));
};

export { getAllowedRoutes };
