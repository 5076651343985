import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from 'front-library';

import * as Actions from '../../../../../stores/shippingOrder/actions';

import { Autocomplete } from '../../../../molecules/Autocomplete/Autocomplete';
import { GET_TOUR_MODELS } from '../../../../molecules/Autocomplete/graphql';
import { HelpText, Title } from './styled-components';

const ShippingOrder = ({
  deliveryReference,
  shippingOrderExistsByReference,
  dispatch,
  model,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Title>
        {t('shippingOrders:modal.steps.step', { step: 1 })}
        {t('shippingOrders:modal.steps.deliveryTitle')}
        <HelpText color="#C81912">
          {t('shippingOrders:modal.steps.asterisk')}
        </HelpText>
      </Title>

      {/* Reference */}
      <TextField
        errorText={
          shippingOrderExistsByReference
            ? t('shippingOrders:modal.steps.errors.deliveryReference')
            : null
        }
        label={t('shippingOrders:modal.steps.deliveryReference')}
        onChange={(data) =>
          dispatch({
            type: Actions.SET_DELIVERY_REFERENCE_ACTION,
            payload: data,
          })
        }
        placeholder={t('shippingOrders:modal.steps.deliveryPlaceholder')}
        required={true}
        value={deliveryReference}
      />

      {/* Model */}
      <Autocomplete
        label={t('shippingOrders:modal.steps.deliveryModel')}
        onChange={(data) =>
          dispatch({
            type: Actions.SET_DELIVERY_MODEL_ACTION,
            payload: data,
          })
        }
        query={GET_TOUR_MODELS}
        value={model}
      />
    </>
  );
};

ShippingOrder.propTypes = {
  deliveryReference: PropTypes.string,
  model: PropTypes.object,
  models: PropTypes.array,
  shippingOrderExistsByReference: PropTypes.bool,
  dispatch: PropTypes.func,
};

export { ShippingOrder };
