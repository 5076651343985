import styled from 'styled-components';

import { Button, Checkbox, Label as LibLabel } from 'front-library';

export const TitleIconContainer = styled.div`
    padding: 0 0 15px 0;
`;

export const EditPasswordContainer = styled.div`
    padding-top: 20px;
`;

export const DashedBorder = styled.div`
    width: 100%;
    border: 1px #B3B3B3;
    border-style: dashed none none none;
    opacity: 0.8;
    margin-top: 20px;
    margin-bottom: 15px;
`;

export const FeatureFlagContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
`;

export const TitleRadioContainer = styled.div`
    grid-area: 1/2/2/3;
`;

export const RadioContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    & > label {
        font-size: 12px;
    }
`;

export const TitleCheckboxContainer = styled.div`
    font-size: 16px;
`;

export const CheckboxContainer = styled(Checkbox)`
    margin-bottom: 10px;
`;

export const Label = styled(LibLabel)`
    display: inline-block;
    margin-bottom: 15px;
    color: #002B2B;
    font-size: 16px;
`;

export const Help = styled.div`
    font-size: 12px;
    color: #707070;
    margin-top: 5px;
    margin-bottom: 8px;
`;

export const HelpToken = styled.div`
    font-size: 12px;
    color: #c81912;
`;

export const WarningContainer = styled.div`
    padding: 10px 0;
    display: grid;
    grid-template-columns: 15px 1fr;
    grid-template-rows: 15px;
    grid-gap: 5px;
    align-items: end;
`;

export const GridContainer = styled.div`
    display: grid;
    gap: 10px;
`;

export const Badge = styled.span`
    display: inline-block;
    width: 100%;
    font-size: 12px;
`;

export const PasswordButton = styled(Button)`
    width: 100%;
`;
