import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import GrayArrow from 'front-library/build/img/arrow-circle-gray.svg';

import { Loading } from '../../../../../molecules/Loading/Loading';
import { Grid } from './Grid/Grid';
import { OrderModal } from './OrderModal/OrderModal';

import { Text } from 'front-library';
import { DELETE_ORDER, UPDATE_ORDER } from './graphql';
import {
  ArrowButton,
  ArrowImg,
  Container,
  GrayText,
  OrdersCountContainer,
} from './styled-components';

const Orders = ({ shippingOrder, orders, refetchOrders }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [errors, setErrors] = useState(null);

  const [updateOrder, { loading: updateOrderLoading }] =
    useMutation(UPDATE_ORDER);
  const [deleteOrder, { loading: deleteOrderLoading }] =
    useMutation(DELETE_ORDER);

  const handleOrderUpdate = useCallback(
    async (reference, assetId) => {
      const variables = {
        id: Number.parseInt(selectedOrder.id),
        reference,
      };

      if (assetId !== selectedOrder?.asset?.id) {
        variables.asset = {
          [assetId ? 'connect' : 'disconnect']: assetId || true,
        };
      }

      try {
        await updateOrder({ variables });
        toast.success(t('shippingOrders:order.updated.success', { reference }));
        setSelectedOrder(null);
        await refetchOrders();
      } catch (e) {
        console.error(e);
        toast.error(t('shippingOrders:order.updated.error'));
        setErrors(e);
      }
    },
    [refetchOrders, selectedOrder, t, updateOrder],
  );

  const handleOrderDelete = useCallback(
    (order) =>
      confirmAlert({
        title: t('shippingOrders:order.delete.title'),
        message: t('shippingOrders:order.delete.message', {
          reference: order.reference,
        }),
        buttons: [
          {
            label: t('common:yes'),
            onClick: async () => {
              try {
                await deleteOrder({
                  variables: {
                    id: order.id,
                  },
                });
                toast.success(
                  t('shippingOrders:order.deleted.success', {
                    reference: order.reference,
                  }),
                );
                await refetchOrders();
              } catch (e) {
                console.error(e);
                toast.error(t('shippingOrders:order.deleted.error'));
              }
            },
          },
          {
            label: t('common:no'),
          },
        ],
      }),
    [deleteOrder, refetchOrders, t],
  );

  return (
    <>
      {(deleteOrderLoading || updateOrderLoading) && <Loading />}
      {selectedOrder && (
        <OrderModal
          shippingOrder={shippingOrder}
          order={selectedOrder}
          onClose={() => setSelectedOrder(null)}
          onSave={handleOrderUpdate}
          errors={errors}
        />
      )}
      <Container>
        <ArrowButton onClick={() => setIsOpen(!isOpen)}>
          <ArrowImg src={GrayArrow} alt="Open/Close" isOpen={isOpen} />
        </ArrowButton>
        {isOpen && (
          <Grid
            orders={orders}
            onOrderEdit={setSelectedOrder}
            onOrderDelete={handleOrderDelete}
          />
        )}
        {!isOpen && (
          <OrdersCountContainer>
            <GrayText>{t('shippingOrders:panelSections.route.order')}</GrayText>
            <p>
              <Text.Bold>{orders?.length}</Text.Bold>
            </p>
          </OrdersCountContainer>
        )}
      </Container>
    </>
  );
};

Orders.propTypes = {
  shippingOrder: PropTypes.object,
  orders: PropTypes.array,
  refetchOrders: PropTypes.func,
};

export { Orders };
