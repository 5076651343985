import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Button, PanelSection } from 'front-library';

import { useQueryGraphQl } from '../../../../hooks/useQueryGraphQl';
import { Loading } from '../../../molecules/Loading/Loading';
import { ExportModal } from '../ExportModal/ExportModal';
import { OrderModal } from './Point/Orders/OrderModal/OrderModal';
import { Point } from './Point/Point';

import { CREATE_ORDER, GET_ORDERS } from './graphql';
import { Container, TitleButtons } from './styled-components';

const RoutePanelSection = ({ shippingOrder }) => {
  const { t } = useTranslation();

  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [errors, setErrors] = useState(null);

  const {
    data: ordersData,
    refetch: refetchOrders,
    loading: ordersLoading,
  } = useQueryGraphQl(GET_ORDERS, {
    shippingOrderId: shippingOrder.id,
  });

  const [createOrder, { loading: createOrderLoading }] =
    useMutation(CREATE_ORDER);

  const checkpoints = useMemo(
    () =>
      shippingOrder?.tour?.shipping_orders?.data.filter(
        (so) => null !== so.tour_order,
      ),
    [shippingOrder],
  );

  const handleOrderCreate = useCallback(
    async (reference, assetId) => {
      const variables = {
        reference,
        shippingOrderId: shippingOrder.id,
      };

      if (assetId) {
        variables.assetId = assetId;
      }

      try {
        await createOrder({ variables });
        toast.success(t('shippingOrders:order.created.success', { reference }));
        setShowOrderModal(false);
        await refetchOrders();
      } catch (e) {
        console.error(e);
        setErrors(e);
        toast.error(t('shippingOrders:order.created.error'));
      }
    },
    [createOrder, refetchOrders, t, shippingOrder],
  );

  const findCheckpointOrders = useCallback(
    (checkpointId) => {
      const checkpoint =
        ordersData?.shipping_order?.tour?.shipping_orders?.data?.find(
          ({ id }) => id === checkpointId,
        );

      return checkpoint?.orders || [];
    },
    [ordersData],
  );

  return (
    <>
      {(ordersLoading || createOrderLoading) && <Loading />}
      {showOrderModal && (
        <OrderModal
          shippingOrder={shippingOrder}
          errors={errors}
          onClose={() => setShowOrderModal(false)}
          onSave={handleOrderCreate}
        />
      )}
      {showExportModal && (
        <ExportModal
          xlsPath={`/orders/export?extension=xlsx&shipping_order_id=${shippingOrder.id}`}
          csvPath={`/orders/export?extension=csv&shipping_order_id=${shippingOrder.id}`}
          onClose={() => setShowExportModal(false)}
        />
      )}
      <PanelSection
        title={t('shippingOrders:panelSections.route.title')}
        customContent={
          <TitleButtons>
            <Button
              onClick={() => setShowOrderModal(true)}
              text={t('shippingOrders:panelSections.route.addOrder')}
              variant="green"
            />
            <Button
              onClick={() => setShowExportModal(true)}
              text={t('shippingOrders:panelSections.route.export')}
              variant="green"
            />
          </TitleButtons>
        }
        isOpenDefault={true}
      >
        <Container>
          <Point type="departure" shippingOrder={shippingOrder} />
          {checkpoints?.map((cp) => (
            <Point
              key={cp.id}
              type="checkpoint"
              shippingOrder={cp}
              orders={findCheckpointOrders(cp.id)}
              refetchOrders={refetchOrders}
            />
          ))}
          <Point
            type="arrival"
            shippingOrder={shippingOrder}
            orders={ordersData?.shipping_order?.orders}
            refetchOrders={refetchOrders}
          />
        </Container>
      </PanelSection>
    </>
  );
};

RoutePanelSection.propTypes = {
  shippingOrder: PropTypes.object,
};

export { RoutePanelSection };
