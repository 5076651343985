import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  canSeeOption,
  canSeeSuperAdminOption,
  companyOptionToRole,
  companyOptionTranslation,
  roleEnum,
  toggleFormRole,
} from '../../../helpers/form/roles';
import { CheckboxContainer } from './styled-components';

const FeatureFlagField = ({ currentUser, formData, setFormData }) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    const filterDisplayableRoles = ({
      name,
      isEnabled: isEnabledOnCompany,
    }) => {
      if (!isEnabledOnCompany) {
        return false;
      }

      if (canSeeOption(formData.selectedRole, name)) {
        return true;
      }

      return canSeeSuperAdminOption(currentUser, name);
    };

    // Filter and format options shown to the user.
    // If a company has an option disabled, then the user cannot see it.
    return (({
      enable_api,
      enable_shipping_orders,
      enable_cycle_analytic,
      is_sms_enabled,
    }) => {
      return [
        { name: 'enable_shipping_orders', isEnabled: enable_shipping_orders },
        { name: 'enable_api', isEnabled: enable_api },
        { name: 'enable_cycle_analytic', isEnabled: enable_cycle_analytic },
        { name: 'is_sms_enabled', isEnabled: is_sms_enabled },
      ]
        .filter(filterDisplayableRoles)
        .map(({ name, enable }) => ({
          name: name,
          enable,
          trans: t(companyOptionTranslation[name]),
        }));
    })(currentUser.company);
  }, [currentUser, formData.selectedRole, t]);

  return (
    <>
      <CheckboxContainer
        label={t('user:form.options.assetManagement')}
        onChange={() =>
          setFormData(toggleFormRole(formData, 'asset_management'))
        }
        checked={formData.roles.includes(roleEnum.assetManagement)}
      />

      {options.map((option) => {
        if (option.name !== 'enable_tableau') {
          const roleForOption = companyOptionToRole[option.name];

          return (
            <CheckboxContainer
              label={option.trans}
              onChange={() =>
                setFormData(toggleFormRole(formData, roleForOption))
              }
              checked={
                currentUser.company[option.name] &&
                formData.roles.includes(roleForOption)
              }
              key={option.name}
            />
          );
        }

        if (
          currentUser.company.enable_tableau &&
          currentUser.rolesName.includes('superAdmin')
        ) {
          return (
            <CheckboxContainer
              label={t('user:form.options.tableauExplorer')}
              onChange={() =>
                setFormData(toggleFormRole(formData, roleEnum.tableauExplorer))
              }
              checked={formData.roles.includes(roleEnum.tableauExplorer)}
              key={option.name}
            />
          );
        }

        return null;
      })}
    </>
  );
};

FeatureFlagField.propTypes = {
  currentUser: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};

export { FeatureFlagField };
