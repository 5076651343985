import PropTypes from 'prop-types';
import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Button } from 'front-library';
import close from 'front-library/build/img/delete-cross.svg';
import pen from 'front-library/build/img/icon-pen-green-dark.svg';

import { routes } from '../../../../routes';
import {
  EditButton,
  HeaderContainer,
  SecondTitle,
  Title,
} from './styled-components';

const Header = ({ onEdit = null, siteName, siteTypeName }) => {
  const navigate = useNavigate();

  return (
    <HeaderContainer>
      <div>
        <Title>{siteName}</Title>
        {onEdit && (
          <EditButton img={pen} variant="borderless" onClick={onEdit} />
        )}
        <SecondTitle>{siteTypeName}</SecondTitle>
      </div>
      <Button
        img={close}
        variant="borderless"
        imgHoverAnimation="shrink"
        onClick={() => navigate(generatePath(routes.filtersMap.path))}
      />
    </HeaderContainer>
  );
};

Header.propTypes = {
  onEdit: PropTypes.func,
  siteName: PropTypes.string,
  siteTypeName: PropTypes.string,
};

export { Header };
