import { gql } from '@apollo/client';

const UPDATE_SHIPPING_ORDER_CARRIER = gql`
    mutation UpdateShippingOrderCarrier(
        $id: ID!
        $carrier: UpdateRelation
    ) {
        updateShippingOrder(input: {
            id: $id
            carrier: $carrier
        }) {
            id
        }
    }
`;

const UPDATE_SHIPPING_ORDER_COMMENT = gql`
    mutation UpdateShippingOrderComment(
        $id: ID!
        $comment: String
    ) {
        updateShippingOrder(input: {
            id: $id
            comment: $comment
        }) {
            id
        }
    }
`;

export { UPDATE_SHIPPING_ORDER_CARRIER, UPDATE_SHIPPING_ORDER_COMMENT };
