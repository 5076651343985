import React from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker, TextField } from 'front-library';

import { AnnotationStepContainer, StepTitle } from './styled-components';

const AnnotationStep = ({ formData, updateField }) => {
  const { t, i18n } = useTranslation();

  return (
    <AnnotationStepContainer>
      <StepTitle>{t('flux:form.annotations')}</StepTitle>
      <DatePicker
        locale={i18n.language}
        onChange={(reviewDate) => updateField({ reviewDate })}
        defaultValue={formData.reviewDate}
        label={t('flux:form.reviewDate')}
      />

      <TextField
        label={t('flux:form.comment')}
        className="big-textarea"
        onChange={(comment) => updateField({ comment })}
        type="textarea"
        value={formData.comment}
      />
    </AnnotationStepContainer>
  );
};

export { AnnotationStep };
