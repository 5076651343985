import * as defaultMixpanel from 'mixpanel-browser';
import slugify from 'slugify';
import { isProduction } from './env';
import { roleEnum } from './form/roles';

let isInit = false;

const events = {
  pageExit: 'Page Exit',
  formSubmitted: 'Form Submitted',
};

function formatRole(roles) {
  if (roles.includes(roleEnum.superAdmin)) {
    return 'super admin';
  }

  if (roles.includes(roleEnum.administrator)) {
    return 'admin';
  }

  if (roles.includes(roleEnum.operator)) {
    return 'operator';
  }

  return 'observer';
}

export const mixpanel = defaultMixpanel;

export function initMixpanel(user) {
  if (isInit) {
    return;
  }

  defaultMixpanel.init(
    isProduction()
      ? 'a6a86a314d0a084273b96230e52f549e'
      : '20b076cce21ebd1529ee9dd49dc1aaad',
  );

  defaultMixpanel.identify(user.id);
  const userMainRole = formatRole(user.rolesName);
  const mixPanelName = `${user.surname}.${userMainRole.slice(0, 2)}.${
    user.company.name
  }`;

  defaultMixpanel.people.set({
    $email: user.email,
    $name: slugify(mixPanelName, { lower: true }),
    $first_name: user.surname,
    $last_name: user.name,
    Company: user.company.name,
    Role: userMainRole,
  });

  // Add properties to every fired events
  defaultMixpanel.register({ company: user.company.name });

  isInit = true;
}

export function trackPageView(page) {
  mixpanel.track_pageview({ page_name: page });

  // Start an internal timer that end once the event given as arg is fired
  // Also add the ellapsed time in the given event as property
  mixpanel.time_event(events.pageExit);
}

export function trackPageExit(page) {
  mixpanel.track(events.pageExit, { page_name: page });
}

export function trackForm(formName, properties = {}) {
  mixpanel.track(events.formSubmitted, {
    form_name: formName,
    ...properties,
  });
}
