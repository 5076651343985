import { ColumnCheckbox, Grid, Text } from 'front-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '../../../../routes';

import EditIcon from 'front-library/build/img/icon-modify-green-2.svg';
import DeleteIcon from 'front-library/build/img/trash.svg';

const LinkName = styled(Link)`
  color: #002B2B;
  
  :hover {
    color: #20676C;
  }
  
  :visited {
    color: #002B2B;
  }
  
  :active {
    color: #B9E6EB;
  }
`;

const GridHeaderContainer = styled.div`
    margin-bottom: 10px;
`;

const PreCell = styled(Grid.Cell)`
    white-space: pre;
`;

const SIZES = ['l', 'md', 'xl', 's', 'xs', 'xs'];

const DataGrid = ({ data, branchId, onDelete, onOrderBy }) => {
  const { t } = useTranslation();

  return (
    <>
      <GridHeaderContainer>
        <Grid.Grid>
          <Grid.Header>
            <Grid.Cell size={SIZES[0]}>
              <ColumnCheckbox
                label={t('site:list.name')}
                onClick={(order) => onOrderBy('NAME', order)}
              />
            </Grid.Cell>
            <Grid.Cell size={SIZES[1]}>
              <ColumnCheckbox
                label={t('site:list.reference')}
                onClick={(order) => onOrderBy('REFERENCE', order)}
              />
            </Grid.Cell>
            <Grid.Cell size={SIZES[2]}>{t('site:list.address')}</Grid.Cell>
            <Grid.Cell size={branchId ? SIZES[1] : SIZES[3]}>
              <ColumnCheckbox
                label={branchId ? t('common:type') : t('site:list.siteType')}
                onClick={(order) => onOrderBy('SITE_TYPE_NAME', order)}
              />
            </Grid.Cell>
            {!branchId && <Grid.Cell size={SIZES[4]} />}
            {!branchId && <Grid.Cell size={SIZES[5]} />}
          </Grid.Header>
        </Grid.Grid>
      </GridHeaderContainer>
      <Grid.Grid>
        {data?.map((site) => (
          <GridRow
            site={site}
            branchId={branchId}
            key={site.id}
            onDelete={onDelete}
          />
        ))}
      </Grid.Grid>
    </>
  );
};

const GridRow = ({ site, onDelete, branchId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid.Row>
      <PreCell size={SIZES[0]}>
        <LinkName to={generatePath(routes.siteMap.path, { id: site.id })}>
          <Text.Bold>{site.name}</Text.Bold>
        </LinkName>
      </PreCell>
      <Grid.Cell size={SIZES[1]}>{site.reference}</Grid.Cell>
      <Grid.Cell size={SIZES[2]}>
        {site.address}
        {/* (<a href={`https://www.google.com/maps/@${site.latitude},${site.longitude},13z`} target="__blank">{t('site:list.showOnMap')}</a>)
                (<a href={`https://www.google.com/maps/${encodeURIComponent(site.address)},13z`} target="__blank">{t('site:list.showOnMap')}</a>) */}
      </Grid.Cell>
      <Grid.Cell size={branchId ? SIZES[1] : SIZES[3]}>
        {site.site_type.name}
      </Grid.Cell>
      {!branchId && (
        <Grid.Cell size={SIZES[4]} alignment="right">
          <Grid.IconButton
            onClick={() =>
              navigate(generatePath(routes.siteEdit.path, { id: site.id }), {
                goBack: true,
              })
            }
            imgSrc={EditIcon}
          />
        </Grid.Cell>
      )}
      {!branchId && (
        <Grid.Cell size={SIZES[5]} alignment="right">
          {(site?.departure_shipping_orders?.paginatorInfo?.total > 0 && (
            <Grid.IconButton
              disabled={true}
              imgSrc={DeleteIcon}
              title={t('site:delete.shippingOrderAttached')}
            />
          )) || (
            <Grid.IconButton
              onClick={() => onDelete(site)}
              imgSrc={DeleteIcon}
            />
          )}
        </Grid.Cell>
      )}
    </Grid.Row>
  );
};

export { DataGrid };
