import Skeleton from '@mui/material/Skeleton';
import React from 'react';

import { GraySeparator, Panel } from 'front-library';

import {
  PanelContainer,
  PanelContent,
  SkeletonPanelContent,
} from './styled-components';

const MapFiltersSkeleton = ({ isOpen }) => {
  return (
    <PanelContainer>
      <Panel className={isOpen ? 'panel-filters' : 'panel-filters-closed'}>
        {!isOpen && (
          <SkeletonPanelContent>
            <Skeleton variant="text" height={40} width={150} />
          </SkeletonPanelContent>
        )}
        {isOpen && (
          <PanelContent>
            <Skeleton variant="text" height={40} />
            <GraySeparator />
            <Skeleton variant="text" height={40} />
            <GraySeparator />
            <Skeleton variant="text" height={40} />
            <GraySeparator />
            <Skeleton variant="text" height={40} />
            <GraySeparator />
            <Skeleton variant="text" width={150} />
            <div>
              <Skeleton variant="text" height={40} />
            </div>
            <Skeleton variant="text" width={150} />
          </PanelContent>
        )}
      </Panel>
    </PanelContainer>
  );
};

export { MapFiltersSkeleton };
