import { gql } from '@apollo/client';

const GET_ORDERS = gql`
    query GetOrders(
        $shippingOrderId: ID
    ) {
        shipping_order(id: $shippingOrderId) {
            orders {
                id
                reference
                asset {
                    id
                    reference
                    mag {
                        mag_id
                    }
                }
            }
            tour {
                shipping_orders(first: 10000) {
                    data {
                        orders {
                            id
                            reference
                            asset {
                                id
                                reference
                                mag {
                                    mag_id
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const CREATE_ORDER = gql`
    mutation CreateOrder($reference: String!, $shippingOrderId: ID!, $assetId: ID) {
        createOrder(
            input: {
                reference: $reference
                shipping_order: { connect: $shippingOrderId }
                asset: {
                    connect: $assetId
                }
            }
        ) {
            id
        }
    }
`;

export { GET_ORDERS, CREATE_ORDER };
