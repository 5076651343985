import { PanelSection, RepartitionChart } from 'front-library';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const COLORS = [
  '#026464',
  '#01BEBE',
  '#9CDCE3',
  '#F7C97C',
  '#EF9D11',
  '#764E0A',
];

const PanelContainer = styled.div`
  padding: 7px 5px 10px 5px;
`;

const AssetTypesSection = ({ assetTypesCounts }) => {
  const { t } = useTranslation();
  const chartData = useMemo(() => {
    const ordered = [...assetTypesCounts]
      .sort((a, b) => a.assets_total - b.assets_total)
      .reverse();
    const displayed = [...ordered].splice(
      0,
      6 === assetTypesCounts.length ? 6 : 5,
    );

    const data = {
      counts: displayed.map((e) => e.assets_total),
      labels: displayed.map((e) => e.name),
      colors: COLORS,
    };

    const others = 6 === assetTypesCounts.length ? [] : [...ordered].splice(5);

    if (others.length) {
      data.counts.push(
        others
          .map((e) => e.assets_total)
          .reduce((previous, current) => previous + current, 0),
      );
      data.labels.push(t('site:panel.repartitionChart.others'));
    }

    return data;
  }, [assetTypesCounts, t]);

  return (
    <PanelSection
      title={t('site:panel.repartitionChart.assetTypesOnSite')}
      className="standard-panel-section"
      isOpenDefault={true}
    >
      <PanelContainer>
        {chartData && (
          <RepartitionChart
            colors={chartData.colors}
            counts={chartData.counts}
            labels={chartData.labels}
          />
        )}
      </PanelContainer>
    </PanelSection>
  );
};

export { AssetTypesSection };
