import { useMutation } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes';
import { SiteFormContainer } from './SiteFormContainer';
import { CREATE_SITE } from './graphql';

const SiteNew = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createSite, { loading }] = useMutation(CREATE_SITE);

  const handleSave = async (site, onError) => {
    try {
      await createSite({
        variables: {
          ...site,
          zones: site.zones.map((z) => {
            z.id = undefined;

            return z;
          }),
        },
      });

      onError({});
      toast.success(t('site:created.success', { name: site.name }));
      navigate(routes.administrationSite.path);
    } catch (e) {
      console.error(e);
      toast.error(t('site:created.error'));
      onError(e?.graphQLErrors[0]?.extensions?.validation || {});
    }
  };

  return (
    <SiteFormContainer
      onSave={handleSave}
      onCancel={() => navigate(routes.administrationSite.path)}
      defaultEditGeofence={false}
      mutationLoading={loading}
    />
  );
};

export { SiteNew };
