import { gql } from '@apollo/client';

const GET_ASSET_GROUP = gql`
    query GetAssetGroup(
        $assetGroupId: ID
        $earlyDeparture: Mixed
    ) {
        asset_group(id: $assetGroupId) {
            assets(first: 10000) {
                paginatorInfo {
                    count
                }
            }
            mag_datas(
                first: 10000
                where: {
                    AND: [
                        {
                            column: "device_timestamp"
                            operator: GT
                            value: $earlyDeparture
                        },
                        {
                            column: "is_position_accepted"
                            operator: EQ
                            value: true
                        }
                    ]
                }
                orderBy: {
                    column: DEVICE_TIMESTAMP
                    order: ASC
                }
            ) {
                data {
                    id
                    latitude
                    longitude
                    accuracy
                    device_timestamp
                }
            }
            latestMagData {
                id
                created_at
            }
            site_transits(
                where: {
                    column: "date_in"
                    operator: GT
                    value: $earlyDeparture
                }
                first: 10000
            ) {
                data {
                    id
                    date_in
                    date_out
                    sites(trashed: WITH, first: 10000) {
                        data {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export { GET_ASSET_GROUP };
