import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Loading } from 'front-library';

import uniqueId from 'lodash/uniqueId';
import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { routes } from '../../../routes';
import { Modal } from '../../molecules/Modal/Modal';
import { GET_MAG_DATA } from './graphql';

const OriginalMessage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { magDataId } = useParams();

  const { data: magData, loading: getLoading } = useQueryGraphQl(GET_MAG_DATA, {
    id: magDataId,
  });

  const json = magData?.mag_data ? JSON.parse(magData.mag_data.json) : null;

  return (
    <Modal
      title={t('mags:form.data.message')}
      zIndex={1010}
      onClose={() =>
        navigate(
          generatePath(routes.administrationMagData.path, {
            id: magData.mag_data.mag_id,
          }),
        )
      }
    >
      {getLoading && <Loading />}

      {!getLoading && (
        <pre>
          {'string' === typeof json
            ? json.split(',').map((j) => <div key={uniqueId()}>{j || '-'}</div>)
            : JSON.stringify(json, null, 4)}
        </pre>
      )}
    </Modal>
  );
};

export { OriginalMessage };
