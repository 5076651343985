import { gql } from '@apollo/client';

const GET_CUSTOM_PARAMETERS = gql`
  query CustomParametersAndAssetTypes {
    custom_parameters(first: 100) { 
      data {
        id
        name
        value_type
        description
        model
        operator_editable
        asset_types_filters(first: 100000) {
          data {
            id
            name
          }
        }
      }
    }
    asset_types(
      first: 10000
      where: {
        column: "name"
        operator: NEQ
        value: "Undefined"
      }
    ) {
      data {
        id
        name
      }
    }
  }
`;

const GET_CUSTOM_PARAMETER = gql`
  query GetCustomParameter($id: ID!) {
    custom_parameter(id: $id) {
      id
      name
      value_type
      description
      model
      operator_editable
      asset_types_filters(first: 100000) {
        data {
          id
          name
        }
      }
    }
  }
`;

const CREATE_CUSTOM_PARAMETER = gql`
  mutation CreateCustomParameter($name: String, $description: String, $value_type: String, $model: String, $operator_editable: Boolean, $asset_types_filters: UpdateAssetTypeRelations) {
    createCustomParameter(
      input: {
        name: $name
        description: $description
        value_type: $value_type
        model: $model
        operator_editable: $operator_editable
        asset_types_filters: $asset_types_filters
      }
    ) {
      id
      name
      value_type
      description
      model
      operator_editable
      asset_types_filters(first: 100000) {
        data {
          id
          name
        }
      }
    }
  }
`;

const UPDATE_CUSTOM_PARAMETER = gql`
  mutation UpdateCustomParameter($id: ID!, $name: String, $description: String, $value_type: String, $model: String, $operator_editable: Boolean, $asset_types_filters: UpdateAssetTypeRelations) {
    updateCustomParameter(
      input: {
        id: $id
        name: $name
        description: $description
        value_type: $value_type
        model: $model
        operator_editable: $operator_editable
        asset_types_filters: $asset_types_filters
      }
    ) {
      id
      name
      value_type
      description
      model
      operator_editable
      asset_types_filters {
        data {
          id
          name
        }
      }
    }
  }
`;

const DELETE_CUSTOM_PARAMETER = gql`
  mutation DeleteCustomParameter($id: ID!) {
    deleteCustomParameter(id: $id) {
      id
    }
  }
`;

export {
  GET_CUSTOM_PARAMETERS,
  GET_CUSTOM_PARAMETER,
  CREATE_CUSTOM_PARAMETER,
  UPDATE_CUSTOM_PARAMETER,
  DELETE_CUSTOM_PARAMETER,
};
