import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker, Label } from 'front-library';

import * as Actions from '../../../../../stores/shippingOrder/actions';
import { Extra } from './Extra';

import { Autocomplete } from '../../../../molecules/Autocomplete/Autocomplete';
import { GET_SITES_WITHOUT_OFFSITE } from '../../../../molecules/Autocomplete/graphql';
import { HelpText, Title } from './styled-components';

const Departure = ({ state, dispatch }) => {
  const { t, i18n } = useTranslation();

  const { departureSite, departureDate, deliveryModel } = state;

  return (
    <>
      <Title>
        {t('shippingOrders:modal.steps.step', { step: 3 })}
        {t('shippingOrders:modal.steps.departureTitle')}
        <HelpText color="#C81912">
          {t('shippingOrders:modal.steps.asterisk')}
        </HelpText>
      </Title>

      {!deliveryModel && (
        <Autocomplete
          label={t('shippingOrders:modal.steps.site')}
          onChange={(data) =>
            dispatch({
              type: Actions.SET_DEPARTURE_SITE_ACTION,
              payload: data,
            })
          }
          required={true}
          query={GET_SITES_WITHOUT_OFFSITE}
          value={departureSite}
        />
      )}

      {deliveryModel && (
        <div>
          <Label>{t('shippingOrders:modal.steps.site')}</Label>
          <div>{t('shippingOrders:modal.steps.deliverySite')}</div>
        </div>
      )}

      <DatePicker
        className="DateChoiceBasic"
        locale={i18n.language}
        placement={deliveryModel ? 'bottomLeft' : 'topLeft'}
        label={t('shippingOrders:modal.steps.departureDate')}
        defaultValue={departureDate}
        placeholder={t('shippingOrders:modal.steps.departureDate')}
        showTime={true}
        required={true}
        onChange={(date) => {
          dispatch({
            type: Actions.SET_DEPARTURE_DATE_ACTION,
            payload: date,
          });
        }}
      />

      {deliveryModel && <Extra state={state} dispatch={dispatch} />}
    </>
  );
};

Departure.propTypes = {
  sites: PropTypes.array,
  state: PropTypes.object,
  dispatch: PropTypes.func,
};

export { Departure };
