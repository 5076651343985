import React from 'react';

import { TextField } from 'front-library';
import IconAddGreen from 'front-library/build/img/icon-add-green.svg';

import { TitleRemove } from '../../atoms/TitleRemove/TitleRemove';
import { Autocomplete } from '../../molecules/Autocomplete/Autocomplete';
import { GET_SITES_WITHOUT_OFFSITE } from '../../molecules/Autocomplete/graphql';
import {
  DashedBorder,
  GridContainer,
  MarginBtnInATab,
  SitesTitle,
} from './styled-components';

const SiteSelector = ({
  t,
  index,
  siteConfig,
  isLast,
  addIntermediateSite,
  removeIntermediateSite,
  editSite,
}) => {
  const isFirst = 0 === index;

  const title =
    (isFirst && t('flux:form.departureSite')) ||
    (isLast && t('flux:form.arrivalSite')) ||
    t('flux:form.intermediateSite', { n: index });

  return (
    <>
      {!isFirst && !isLast && (
        <TitleRemove onClick={() => removeIntermediateSite(index)}>
          {t('flux:form.intermediateSite', { n: index })}
        </TitleRemove>
      )}
      {(isFirst || isLast) && <SitesTitle>{title}</SitesTitle>}
      <GridContainer>
        <Autocomplete
          label={t('common:select')}
          onChange={(value) => editSite(index, 'site', value)}
          query={GET_SITES_WITHOUT_OFFSITE}
          required={true}
          value={siteConfig.site}
        />
        <TextField
          label={t('flux:form.timeOnSite')}
          onChange={(value) => editSite(index, 'timeOnSite', value)}
          required={true}
          timeInputProps={{
            daysLabel: t('common:timeInput.d'),
            hoursLabel: t('common:timeInput.h'),
            minutesLabel: t('common:timeInput.m'),
          }}
          type="time"
          value={siteConfig.timeOnSite}
        />
      </GridContainer>
      {!isLast && (
        <>
          <DashedBorder />
          <SitesTitle>{t('flux:form.transport')}</SitesTitle>
          <TextField
            label={t('flux:form.routeDuration')}
            onChange={(value) => editSite(index, 'transportTime', value)}
            required={true}
            timeInputProps={{
              daysLabel: t('common:timeInput.d'),
              hoursLabel: t('common:timeInput.h'),
              minutesLabel: t('common:timeInput.m'),
            }}
            type="time"
            value={siteConfig.transportTime}
          />
          <MarginBtnInATab
            className="btn-add-special"
            imgSrc={IconAddGreen}
            text={t('flux:form.addIntermediateSite')}
            onClick={() => addIntermediateSite(index + 1)}
          />
          <DashedBorder />
        </>
      )}
    </>
  );
};

export { SiteSelector };
