import styled from 'styled-components';

export const TitleContainer = styled.div`
    margin-left: -10px;
`;

export const FileField = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;

    > div {
        flex-grow: 1;
    }
`;
export const CustomParametersContainer = styled.div`
    display: grid;
    gap: 10px;
`;
