import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';

import background01 from '../../../assets/images/auth/background-01.jpg';
import background02 from '../../../assets/images/auth/background-02.jpg';
import background03 from '../../../assets/images/auth/background-03.jpg';
import background04 from '../../../assets/images/auth/background-04.jpg';
import background05 from '../../../assets/images/auth/background-05.jpg';
import background06 from '../../../assets/images/auth/background-06.jpg';
import background07 from '../../../assets/images/auth/background-07.jpg';

import logo from '../../../assets/images/logo.svg';
import {
  ActionsContainer,
  AuthContainer,
  ContentContainer,
  Logo,
  SideImage,
  StatusPageContainer,
} from './styled-components';

const STATUS_PAGE_STYLE = `
    .basic .status-container {
        font-family: 'Muli regular';
        font-size: 11px;
    }

    .basic .status-indicator {
        width: 0.7rem;
        height: 0.7rem;
    }
`;

const SIDE_IMAGES = [
  background01,
  background02,
  background03,
  background04,
  background05,
  background06,
  background07,
];

const SIDE_IMAGE = SIDE_IMAGES[Math.floor(Math.random() * SIDE_IMAGES.length)];

const AuthPage = ({ sideImage = null, children, showStatusPage = false }) => {
  const statusRef = useRef();

  // Ugly way to style status page component but the
  // other way was to recode it, which is worst.
  useEffect(() => {
    if (!statusRef?.current?.shadowRoot) {
      return;
    }

    const css = new CSSStyleSheet();
    css.replaceSync(STATUS_PAGE_STYLE);

    statusRef.current.shadowRoot.adoptedStyleSheets.push(css);
  }, []);

  return (
    <AuthContainer>
      <SideImage background={sideImage || SIDE_IMAGE} />
      <ContentContainer>
        <ActionsContainer>
          <Logo src={logo} alt="Magma logo" />
          {children}
        </ActionsContainer>
        {showStatusPage && (
          <StatusPageContainer>
            <statuspage-widget
              ref={statusRef}
              src="https://magmatechnology1.statuspage.io/"
            />
          </StatusPageContainer>
        )}
      </ContentContainer>
    </AuthContainer>
  );
};

AuthPage.propTypes = {
  children: PropTypes.node,
  showStatusPage: PropTypes.bool.isRequired,
};

export { AuthPage };
