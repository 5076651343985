import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Loading } from 'front-library';

import { MeContext } from '../../../contexts/MeContext';
import { Modal } from '../../molecules/Modal/Modal';
import { PasswordForm } from '../../organisms/Users/PasswordForm';
import { UPDATE_PASSWORD } from './graphql';

const Password = ({ userId, sameUser, onSave, onClose }) => {
  const { t } = useTranslation();
  const { me: user } = useContext(MeContext);

  if (!userId) {
    userId = user?.id;
  }

  const [updatePasswordMutation, { loading }] = useMutation(UPDATE_PASSWORD);
  const [formValues, setFormValues] = useState({
    adminPassword: '',
    password: '',
    passwordConfirmation: '',
    isForcedPasswordUpdate: false,
  });
  const [errors, setErrors] = useState({});

  const handleUpdate = async () => {
    try {
      await updatePasswordMutation({
        variables: {
          id: userId,
          admin_password: formValues.adminPassword,
          password: formValues.password,
          password_confirmation: formValues.passwordConfirmation,
          password_force_update: formValues.isForcedPasswordUpdate,
        },
      });

      setErrors({});

      if (onClose) {
        onClose();
      }

      toast.success(t('user:password.success'));
      onSave();
    } catch (e) {
      if (e?.graphQLErrors[0]?.extensions?.reason === 'password') {
        setErrors({ admin_password: t('user:password.exception') } || {});
      } else {
        setErrors(e?.graphQLErrors[0]?.extensions?.validation || {});
      }

      toast.error(t('user:password.error'));
    }
  };

  return (
    <Modal
      onClose={onClose}
      title={t('user:form.passwordModalTitle')}
      showCross={false}
      zIndex={3002}
    >
      {loading && <Loading />}
      {!loading && (
        <PasswordForm
          errors={errors}
          formValues={formValues}
          sameUser={sameUser}
          setFormValues={setFormValues}
          onCancel={onClose}
          onSubmit={handleUpdate}
        />
      )}
    </Modal>
  );
};

export { Password };
