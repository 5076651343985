import L from 'leaflet';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';

import { Geofence } from '../Geofence';
import { icons } from '../icons';

const PopupOffset = new L.Point(0, 0);

const CheckpointMarker = ({ checkpoint, onGeofenceEdit }) => {
  const [over, setOver] = useState(false);

  const icon = useMemo(() => {
    if (onGeofenceEdit && over && checkpoint.isDelivered) {
      return icons.checkpointEdit;
    }

    if (onGeofenceEdit && over) {
      return icons.checkpointWaitingEdit;
    }

    if (checkpoint.isDelivered) {
      return icons.checkpoint;
    }

    return icons.checkpointWaiting;
  }, [checkpoint, onGeofenceEdit, over]);

  return (
    <>
      <Marker
        position={[checkpoint.latitude, checkpoint.longitude]}
        onMouseOver={(e) => e.target.openPopup() && setOver(true)}
        onMouseOut={(e) => e.target.closePopup() && setOver(false)}
        onclick={() => onGeofenceEdit?.(checkpoint.id)}
        icon={icon}
      >
        {checkpoint.name && (
          <Popup offset={PopupOffset}>{checkpoint.name}</Popup>
        )}
      </Marker>
      {checkpoint.geofence && (
        <Geofence geofence={checkpoint.geofence} styleType="site" />
      )}
    </>
  );
};

CheckpointMarker.propTypes = {
  checkpoint: PropTypes.object.isRequired,
  onGeofenceEdit: PropTypes.func,
};

export { CheckpointMarker };
