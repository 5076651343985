import React, { useEffect, useState } from 'react';
import { version } from '../../../../package.json';

const Version = () => {
  const [backVersion, setBackVersion] = useState(null);

  useEffect(() => {
    fetch('/version_back.txt')
      .then(async (res) => {
        if (res.status > 400) {
          throw new Error('Request error');
        }

        setBackVersion(await res.text());
      })
      .catch(() => setBackVersion('no version back'));
  }, []);

  return (
    <div>
      version front: {version} back: {backVersion}
    </div>
  );
};

export { Version };
