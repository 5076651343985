import React from 'react';
import { useTranslation } from 'react-i18next';
import Img404 from '../../../assets/images/errors/404.png';
import { ErrorPage } from '../../templates/ErrorPage/ErrorPage';

const NotFound = () => {
  const { t } = useTranslation();

  return <ErrorPage title={t('error:notFound.title')} image={Img404} />;
};

export { NotFound };
