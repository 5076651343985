import { gql } from '@apollo/client';

const CREATE_GRAPHQL_FILTER = gql`
    mutation CreateGraphqlFilter(
        $name: String!
        $page: String!
        $filters: String!
        $default_view: Boolean
    ) {
        createGraphqlFilter(
            input: {
                name: $name
                page: $page
                filters: $filters
                default_view: $default_view
            }
        ) {
            id
        }
    }
`;

const UPDATE_GRAPHQL_FILTER = gql`
    mutation UpdateGraphqlFilter(
        $id: ID!
        $name: String!
        $page: String!
        $filters: String!
        $default_view: Boolean
    ) {
        updateGraphqlFilter(
            input: {
                id: $id
                name: $name
                page: $page
                filters: $filters
                default_view: $default_view
            }
        ) {
            id
        }
    }
`;

const DELETE_GRAPHQL_FILTER = gql`
    mutation DeleteGraphqlFilter($id: ID!) {
        deleteGraphqlFilter(id: $id) {
            id
        } 
    }
`;

export { CREATE_GRAPHQL_FILTER, DELETE_GRAPHQL_FILTER, UPDATE_GRAPHQL_FILTER };
