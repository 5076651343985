import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from 'front-library';
import { generatePath } from 'react-router-dom';
import { routes } from '../../../routes';
import {
  AdditionalText,
  Background,
  Content,
  Illustration,
  Link,
  LinksContainer,
  TextContainer,
  Title,
} from './styled-components';

const ErrorPage = ({ image, text, title }) => {
  const { t } = useTranslation();
  const helpdeskUrl = encodeURI(
    'https://magmatechnologyhelp.zendesk.com/hc/fr/signin?return_to=https://magmatechnologyhelp.zendesk.com/hc/fr',
  );

  return (
    <Background>
      <Content>
        <TextContainer>
          <Title>
            <Text.ItalicLight>{title}</Text.ItalicLight>
          </Title>
          {text && (
            <AdditionalText>
              <Text.ItalicLight>{text}</Text.ItalicLight>
            </AdditionalText>
          )}
        </TextContainer>
        <LinksContainer>
          <div>
            <Link href={generatePath(routes.filtersMap.path)}>
              {t('error:return')}
            </Link>
          </div>
          <div>
            <Link target="_blank" href={helpdeskUrl}>
              {t('error:help')}
            </Link>
          </div>
        </LinksContainer>
        <Illustration>
          <img src={image} alt="Error" />
        </Illustration>
      </Content>
    </Background>
  );
};

ErrorPage.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export { ErrorPage };
