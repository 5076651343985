import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, TextField } from 'front-library';

import { HelpText, Title } from '../styled-components';

const ShippingOrder = ({ formValues, setFormValues }) => {
  const { t } = useTranslation();

  return (
    <>
      <Title>{t('companies:form.deliveries')}</Title>

      {/* Delivery time */}
      <TextField
        label={t('companies:form.timeToDeliver')}
        onChange={(timeToDeliver) =>
          setFormValues({ ...formValues, timeToDeliver })
        }
        required={true}
        type="number"
        value={formValues.timeToDeliver}
      />
      <HelpText>{t('companies:form.timeToDeliverHelpText')}</HelpText>

      {/* ETA period */}
      <TextField
        label={t('companies:form.etaPeriod')}
        onChange={(etaPeriod) => setFormValues({ ...formValues, etaPeriod })}
        required={true}
        type="number"
        value={formValues.etaPeriod}
      />
      <HelpText>{t('companies:form.etaPeriodHelpText')}</HelpText>

      {/* Delivery delay alert frequency */}
      <TextField
        label={t('companies:form.alertDelayFrequency')}
        onChange={(shippingOrderDelayFrequenceAlert) =>
          setFormValues({ ...formValues, shippingOrderDelayFrequenceAlert })
        }
        required={true}
        type="number"
        value={formValues.shippingOrderDelayFrequenceAlert}
      />
      <HelpText>{t('companies:form.alertDelayFrequencyHelpText')}</HelpText>

      {/* First alert for delivery delay */}
      <TextField
        label={t('companies:form.firstDelayAlertTime')}
        onChange={(shippingOrderFirstDelayAlert) =>
          setFormValues({ ...formValues, shippingOrderFirstDelayAlert })
        }
        required={true}
        type="number"
        value={formValues.shippingOrderFirstDelayAlert}
      />
      <HelpText>{t('companies:form.firstDelayAlertTimeHelpText')}</HelpText>

      {/* Date format */}
      <TextField
        label={t('companies:form.dateFormat')}
        onChange={(dateFormat) => setFormValues({ ...formValues, dateFormat })}
        required={true}
        value={formValues.dateFormat}
      />
      <HelpText>{t('companies:form.dateFormatHelpText')}</HelpText>

      {/* Order delivery validation time */}
      <TextField
        label={t('companies:form.orderDeliveryDelay')}
        onChange={(orderDeliveryDelay) =>
          setFormValues({ ...formValues, orderDeliveryDelay })
        }
        type="number"
        value={formValues.orderDeliveryDelay}
      />
      <HelpText>{t('companies:form.orderDeliveryDelayHelpText')}</HelpText>

      {/* Enable start date adjustment */}
      <Checkbox
        label={t('companies:form.optimiseRealDepartureDate')}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            optimiseRealDepartureDate: e.target.checked,
          })
        }
        checked={formValues.optimiseRealDepartureDate}
      />
      <HelpText>
        {t('companies:form.optimiseRealDepartureDateHelpText')}
      </HelpText>

      {/* Likely maximum deviation */}
      <TextField
        label={t('companies:form.likelyDeviatedMax')}
        onChange={(likelyDeviatedMax) =>
          setFormValues({ ...formValues, likelyDeviatedMax })
        }
        type="number"
        value={formValues.likelyDeviatedMax}
      />
      <HelpText>{t('companies:form.likelyDeviatedMaxHelpText')}</HelpText>

      {/* Early start limit */}
      <TextField
        label={t('companies:form.earlyDeparture')}
        onChange={(earlyDeparture) =>
          setFormValues({ ...formValues, earlyDeparture })
        }
        type="number"
        value={formValues.earlyDeparture}
      />
      <HelpText>{t('companies:form.earlyDepartureHelpText')}</HelpText>

      {/* ETA validity time */}
      <TextField
        label={t('companies:form.readjustmentLimit')}
        onChange={(readjustmentLimit) =>
          setFormValues({ ...formValues, readjustmentLimit })
        }
        type="number"
        value={formValues.readjustmentLimit}
      />
      <HelpText>{t('companies:form.readjustmentLimitHelpText')}</HelpText>

      {/* Enable pair site */}
      <Checkbox
        label={t('companies:form.enablePairSite')}
        onChange={(e) =>
          setFormValues({ ...formValues, enablePairSite: e.target.checked })
        }
        checked={formValues.enablePairSite}
      />
      <HelpText>{t('companies:form.enablePairSiteHelpText')}</HelpText>

      {/* Enable start date adjustment */}
      <Checkbox
        label={t('companies:form.isDepartureStartEnabled')}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            isDepartureStartEnabled: e.target.checked,
          })
        }
        checked={formValues.isDepartureStartEnabled}
      />
      <HelpText>{t('companies:form.isDepartureStartEnabledHelpText')}</HelpText>

      <Title>{t('companies:form.options.shippingOrderImport.title')}</Title>

      {/* Replace existing shipping orders during the import */}
      <Checkbox
        checked={formValues.enableShippingOrderImportReplace}
        disabled={formValues.enableShippingOrderImportPreserveAsset}
        label={t(
          'companies:form.options.shippingOrderImport.enableReplaceShippingOrder.checkbox',
        )}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            enableShippingOrderImportReplace: e.target.checked,
          })
        }
      />
      <HelpText>
        {t(
          'companies:form.options.shippingOrderImport.enableReplaceShippingOrder.helptext',
        )}
      </HelpText>

      {/* Preserve paired assets if replacing shipping order */}
      <Checkbox
        label={t(
          'companies:form.options.shippingOrderImport.enablePreserveAsset.checkbox',
        )}
        onChange={(e) => {
          if (e.target.checked) {
            setFormValues({
              ...formValues,
              enableShippingOrderImportReplace: true,
              enableShippingOrderImportPreserveAsset: true,
            });

            return;
          }

          setFormValues({
            ...formValues,
            enableShippingOrderImportPreserveAsset: false,
          });
        }}
        checked={formValues.enableShippingOrderImportPreserveAsset}
      />
      <HelpText>
        {t(
          'companies:form.options.shippingOrderImport.enablePreserveAsset.helptext',
        )}
      </HelpText>
    </>
  );
};

ShippingOrder.propTypes = {
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
};

export { ShippingOrder };
