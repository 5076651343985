import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PanelSection } from 'front-library';

import { Autocomplete } from '../../../../molecules/Autocomplete/Autocomplete';
import { GET_BRANCHES } from '../../../../molecules/Autocomplete/graphql';
import { FiltersSectionNumber } from '../../styled-components';
import { NO_BRANCH } from '../../utils';

const BranchFilter = ({ branchesFilter, branchesTotal, onChange }) => {
  const { t } = useTranslation();

  return (
    <PanelSection
      title={t('mapFilters:byBranch')}
      customContent={
        branchesFilter?.length ? (
          <FiltersSectionNumber>
            {`(${branchesFilter.length}/${branchesTotal + 1})`}
          </FiltersSectionNumber>
        ) : null
      }
      className="standard-panel-section"
    >
      <Autocomplete
        customOptions={[{ value: NO_BRANCH, title: t('mapFilters:noBranch') }]}
        multiple={true}
        onChange={(values) => onChange(values)}
        placeholderTranslateKey="common:nSelected"
        query={GET_BRANCHES}
        selectAll={true}
        value={branchesFilter}
      />
    </PanelSection>
  );
};

BranchFilter.propTypes = {
  branchesFilter: PropTypes.array,
  branchesTotal: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { BranchFilter };
