import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { routes } from '../../../routes';
import { Loading } from '../../molecules/Loading/Loading';
import { Modal } from '../../molecules/Modal/Modal';
import { CustomParameterForm } from '../../organisms/CustomParameters/CustomParameterForm';
import {
  CREATE_CUSTOM_PARAMETER,
  GET_CUSTOM_PARAMETER,
  UPDATE_CUSTOM_PARAMETER,
} from './graphql';
import {
  mapCustomParameterFormValuesToGraphql,
  mapCustomParameterToFormValues,
} from './utils';

const CustomParameterModal = ({ modelNames, refetch, valueTypes }) => {
  const { t } = useTranslation();
  const { id = null } = useParams();
  const navigate = useNavigate();

  const { data: customParameterData, loading: getCustomParameterLoading } =
    useQueryGraphQl(GET_CUSTOM_PARAMETER, { id }, {}, null === id);
  const [createCustomParameterMutation, { loading: createLoading }] =
    useMutation(CREATE_CUSTOM_PARAMETER);
  const [updateCustomParameterMutation, { loading: updateLoading }] =
    useMutation(UPDATE_CUSTOM_PARAMETER);

  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(
    mapCustomParameterToFormValues(),
  );

  if (customParameterData?.custom_parameter && !formValues.id) {
    setFormValues({
      ...formValues,
      ...mapCustomParameterToFormValues(customParameterData.custom_parameter),
    });
  }

  const title = useMemo(
    () =>
      id
        ? t('customParameters:form.update')
        : t('customParameters:form.create'),
    [id, t],
  );

  const handleCreate = async () => {
    try {
      await createCustomParameterMutation({
        variables: mapCustomParameterFormValuesToGraphql(formValues),
      });

      setErrors({});
      handleClose();
      toast.success(t('customParameters:created.success'));
      refetch();
    } catch (e) {
      setErrors(e?.graphQLErrors[0]?.extensions?.validation || {});
      toast.error(t('customParameters:created.error'));
    }
  };

  const handleUpdate = async () => {
    try {
      await updateCustomParameterMutation({
        variables: mapCustomParameterFormValuesToGraphql(formValues),
      });

      setErrors({});
      handleClose();
      toast.success(t('customParameters:updated.success'));
      refetch();
    } catch (e) {
      setErrors(e?.graphQLErrors?.[0]?.extensions?.validation || {});
      toast.error(t('customParameters:updated.error'));
    }
  };

  const handleClose = () =>
    navigate(generatePath(routes.administrationCustomParameters.path));

  return (
    <Modal onClose={handleClose} title={title}>
      {(getCustomParameterLoading || createLoading || updateLoading) && (
        <Loading />
      )}
      {!getCustomParameterLoading && (
        <CustomParameterForm
          errors={errors}
          formValues={formValues}
          onCancel={handleClose}
          onSubmit={id ? handleUpdate : handleCreate}
          modelNames={modelNames}
          setFormValues={setFormValues}
          valueTypes={valueTypes}
        />
      )}
    </Modal>
  );
};

CustomParameterModal.propTypes = {
  modelNames: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  refetch: PropTypes.func.isRequired,
  valueTypes: PropTypes.object.isRequired,
};

export { CustomParameterModal };
