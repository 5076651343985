import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'front-library';

import { getShippingOrderTranslate } from '../../../helpers';
import { Autocomplete } from '../../molecules/Autocomplete/Autocomplete';
import {
  GET_ASSET_TYPES,
  GET_SITES_WITHOUT_OFFSITE,
  GET_SITE_TYPES,
} from '../../molecules/Autocomplete/graphql';

const ConditionRelationSelector = ({ condition, onChange, selectOptions }) => {
  const { t } = useTranslation();

  const query = useMemo(() => {
    switch (condition.type) {
      case 'Site':
        return GET_SITES_WITHOUT_OFFSITE;
      case 'AssetType':
        return GET_ASSET_TYPES;
      case 'SiteType':
        return GET_SITE_TYPES;
      default:
        return null;
    }
  }, [condition.type]);

  if ('AssetState' === condition.type) {
    return (
      <Select
        label={t('automatedActions:form.value')}
        multiple={true}
        onChange={(values) => onChange({ ...condition, values })}
        options={selectOptions.AssetState.data}
        required={true}
        value={condition.values || []}
      />
    );
  }

  if ('ShippingOrderStatus' === condition.type) {
    return (
      <Select
        label={t('automatedActions:form.value')}
        multiple={true}
        onChange={(values) => onChange({ ...condition, values })}
        options={selectOptions.ShippingOrderStatus.data.map((option) => ({
          ...option,
          title: getShippingOrderTranslate(t, option.title),
        }))}
        required={true}
        value={condition.values || []}
      />
    );
  }

  return (
    <Autocomplete
      label={t('automatedActions:form.value')}
      multiple={true}
      onChange={(values) => onChange({ ...condition, values })}
      query={query}
      required={true}
      value={condition.values || []}
    />
  );
};

ConditionRelationSelector.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selectOptions: PropTypes.object.isRequired,
};

export { ConditionRelationSelector };
