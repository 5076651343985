import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ColumnCheckbox, Grid } from 'front-library';
import uniqueId from 'lodash/uniqueId';

const GridHeaderContainer = styled.div`
  margin-bottom: 10px;
`;

const CallbackErrorList = ({ errors }) => {
  const { t } = useTranslation();

  return (
    <>
      <h6>{t('callbacks:form.errorModal.listTitle')}</h6>
      <GridHeaderContainer>
        <Grid.Grid>
          <Grid.Header>
            <Grid.Cell size="md">
              <ColumnCheckbox label={t('callbacks:form.errorModal.date')} />
            </Grid.Cell>
            <Grid.Cell size="md">
              <ColumnCheckbox label={t('callbacks:form.errorModal.status')} />
            </Grid.Cell>
            <Grid.Cell size="md">
              <ColumnCheckbox label={t('callbacks:form.errorModal.error')} />
            </Grid.Cell>
          </Grid.Header>
        </Grid.Grid>
      </GridHeaderContainer>

      <Grid.Grid>
        {errors?.map((error) => (
          <GridRow key={uniqueId()} error={error} />
        ))}
      </Grid.Grid>
    </>
  );
};

const GridRow = ({ error }) => {
  return (
    <Grid.Row>
      <Grid.Cell size="md">{error.date_event}</Grid.Cell>
      <Grid.Cell size="md">{`${error.status_code}: ${error.status_text}`}</Grid.Cell>
      <Grid.Cell size="md">{error.message}</Grid.Cell>
    </Grid.Row>
  );
};

export { CallbackErrorList };
