import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, TextField, TitleIcon } from 'front-library';
import iconCondition from 'front-library/build/img/icon-condition.svg';

import { TitleRemove } from '../../atoms/TitleRemove/TitleRemove';
import { ConditionRelationSelector } from './ConditionRelationSelector';
import {
  ConditionContainer,
  ConditionFieldsContainer,
  TitleContainer,
} from './styled-components';
import {
  CONDITIONS,
  INTERVAL_CONDITIONS,
  POSSIBLE_MODEL_CONDITIONS_CHOICES,
} from './utils';

const Condition = ({
  condition,
  index,
  onRemove,
  onUpdate,
  conditionsOptions,
  model,
  conditions,
}) => {
  const { t } = useTranslation();

  const conditionChoices = useMemo(
    () => [
      {
        value: CONDITIONS.assetType,
        title: t(
          'automatedActions:form.relationalConditionsTypeOptions.byAssetType',
        ),
        type: 'relational',
      },
      {
        value: CONDITIONS.assetState,
        title: t(
          'automatedActions:form.relationalConditionsTypeOptions.byAssetState',
        ),
        type: 'relational',
      },
      {
        value: CONDITIONS.siteType,
        title: t(
          'automatedActions:form.relationalConditionsTypeOptions.bySiteType',
        ),
        type: 'relational',
      },
      {
        value: CONDITIONS.site,
        title: t(
          'automatedActions:form.relationalConditionsTypeOptions.bySite',
        ),
        type: 'relational',
      },

      {
        value: CONDITIONS.assetSiteEnter,
        title: t('automatedActions:form.conditionsTypeOptions.assetSiteEnter'),
        type: 'condition',
      },
      {
        value: CONDITIONS.assetSiteLeave,
        title: t('automatedActions:form.conditionsTypeOptions.assetSiteLeave'),
        type: 'condition',
      },
      {
        value: CONDITIONS.assetStateExpired,
        title: t(
          'automatedActions:form.valueConditionTypeOptions.assetStateExpired',
        ),
        type: 'value',
      },
      {
        value: CONDITIONS.assetOnSiteSince,
        title: t(
          'automatedActions:form.valueConditionTypeOptions.assetOnSiteSince',
        ),
        type: 'value',
      },

      {
        value: CONDITIONS.minAssets,
        title: t(
          'automatedActions:form.valueConditionTypeOptions.minAssetOnSite',
        ),
        type: 'value',
      },
      {
        value: CONDITIONS.maxAssets,
        title: t(
          'automatedActions:form.valueConditionTypeOptions.maxAssetOnSite',
        ),
        type: 'value',
      },
      {
        value: CONDITIONS.maxValue,
        title: t(
          'automatedActions:form.valueConditionTypeOptions.maxValueOnSite',
        ),
        type: 'value',
      },

      {
        value: CONDITIONS.shippingOrderStatus,
        title: t(
          'automatedActions:form.relationalConditionsTypeOptions.byShippingOrderStatus',
        ),
        type: 'relational',
      },
      {
        value: CONDITIONS.thereticalArrivalDateExceeded,
        title: t(
          'automatedActions:form.valueConditionTypeOptions.theoreticalArrivalDateExceeded',
        ),
        type: 'value',
      },
      {
        value: CONDITIONS.thereticalDepartureDateExceeded,
        title: t(
          'automatedActions:form.valueConditionTypeOptions.theoreticalDepartureDateExceeded',
        ),
        type: 'value',
      },
      {
        value: CONDITIONS.arrivalTimeLessThan,
        title: t(
          'automatedActions:form.valueConditionTypeOptions.arrivalTimeLessThan',
        ),
        type: 'value',
      },
    ],
    [t],
  );

  const updateConditionType = useCallback(
    (condition, value) => {
      const newType = conditionChoices.find((t) => t.value === value);

      if (newType.value === condition.type) {
        return;
      }

      if ('relational' === newType.type) {
        onUpdate({
          type: value,
          values: [],
          isRelational: true,
        });
      } else if ('value' === newType.type) {
        onUpdate({
          type: value,
          value: null,
          isValue: true,
        });
      } else {
        onUpdate({
          type: value,
          value: '0',
          isCondition: true,
        });
      }
    },
    [onUpdate, conditionChoices],
  );

  const filterCondition = useCallback(
    (condition, choice) => {
      if (!condition?.type) {
        return false;
      }

      // Needed in case a value condition should not be combined with another one
      const valueConditions = POSSIBLE_MODEL_CONDITIONS_CHOICES[model].filter(
        (condition) =>
          'relational' !==
          conditionChoices.find((choice) => choice.value === condition).type,
      );

      return (
        (valueConditions.includes(condition.type) &&
          valueConditions.includes(choice.value)) ||
        condition.type === choice.value
      );
    },
    [conditionChoices, model],
  );

  // Remove conditions already used or incompatible
  const options = useMemo(
    () =>
      conditionChoices.filter(
        (choice) =>
          POSSIBLE_MODEL_CONDITIONS_CHOICES[model].includes(choice.value) &&
          !conditions.find(
            (condition, i) => i !== index && filterCondition(condition, choice),
          ),
      ),
    [index, conditionChoices, model, conditions, filterCondition],
  );

  const unit = useMemo(() => {
    if (CONDITIONS.maxValue === condition.type) {
      return '(euros)';
    }

    if (
      CONDITIONS.minAssets === condition.type ||
      CONDITIONS.maxAssets === condition.type
    ) {
      return '';
    }

    return '(mn)';
  }, [condition.type]);

  const conditionsWithoutValue = useMemo(
    () => [
      CONDITIONS.assetStateExpired,
      CONDITIONS.assetSiteEnter,
      CONDITIONS.assetSiteLeave,
      CONDITIONS.assetOnSiteSince,
    ],
    [],
  );

  const showMutedInput = () =>
    (!condition.isValue && !condition.isRelational) || !condition.type;

  return (
    <>
      <ConditionContainer>
        <TitleContainer>
          <TitleIcon img={iconCondition} />
          <TitleRemove onClick={onRemove}>
            {`${t('automatedActions:form.condition')} ${index + 1}`}
          </TitleRemove>
        </TitleContainer>

        <ConditionFieldsContainer>
          <Select
            label={t('automatedActions:form.conditionType')}
            onChange={(value) => updateConditionType(condition, value)}
            options={options}
            required={true}
            value={condition?.type || ''}
          />
          {INTERVAL_CONDITIONS.includes(condition.type) && (
            <TextField
              type="time"
              label={t('automatedActions:form.period')}
              onChange={(value) => onUpdate({ ...condition, value })}
              value={condition.value || ''}
              timeInputProps={{
                daysLabel: t('common:timeInput.d'),
                hoursLabel: t('common:timeInput.h'),
                minutesLabel: t('common:timeInput.m'),
              }}
              required={true}
            />
          )}
          {!conditionsWithoutValue.includes(condition.type) &&
            condition.isValue && (
              <TextField
                label={`${t('automatedActions:form.value')} ${unit}`}
                onChange={(data) =>
                  onUpdate({
                    ...condition,
                    value: data,
                  })
                }
                required={true}
                type="number"
                value={condition.value || ''}
              />
            )}
          {condition.isRelational && condition.type && (
            <ConditionRelationSelector
              condition={condition}
              onChange={onUpdate}
              selectOptions={conditionsOptions}
            />
          )}
          {showMutedInput() && <TextField label="&nbsp;" disabled={true} />}
        </ConditionFieldsContainer>
      </ConditionContainer>
    </>
  );
};

export { Condition };
