import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from 'front-library';
import CheckpointIcon from 'front-library/build/img/icon-checkpoint-orange.svg';
import DepartureSiteIcon from 'front-library/build/img/icon-info-site.svg';
import ArrivalSiteIcon from 'front-library/build/img/icon-site-orange-2.svg';

import { Orders } from './Orders/Orders';
import { Timestamps } from './Timestamps/Timestamps';

import { MeContext } from '../../../../../contexts/MeContext';
import { COLORS, POINT_TYPES } from './constants';
import {
  EmptySpan,
  Pipe,
  PointContainer,
  SiteHeader,
  SiteIcon,
  SiteInfo,
  SiteInfos,
  SiteName,
} from './styled-components';
import { getDiff } from './utils';

const Point = ({ type, shippingOrder, orders, refetchOrders }) => {
  const { t } = useTranslation();
  const { me: user } = useContext(MeContext);

  const getRealTimestampColor = useCallback(
    (checkpoint = false) => {
      if (POINT_TYPES.departure === type) {
        if (!shippingOrder.real_departure_timestamp) {
          return COLORS.DARK_GRAY;
        }

        return shippingOrder.real_departure_timestamp >
          shippingOrder.departure_timestamp
          ? COLORS.RED
          : COLORS.GREEN;
      }

      if (POINT_TYPES.arrival === type || POINT_TYPES.checkpoint === type) {
        if (checkpoint) {
          if (
            !shippingOrder.checkpoint_real_departure_timestamp ||
            !shippingOrder.checkpoint_departure_timestamp
          ) {
            return COLORS.DARK_GRAY;
          }

          return shippingOrder.checkpoint_real_departure_timestamp >
            shippingOrder.checkpoint_departure_timestamp
            ? COLORS.RED
            : COLORS.GREEN;
        }

        if (!shippingOrder.real_arrival_timestamp) {
          return COLORS.DARK_GRAY;
        }

        return shippingOrder.real_arrival_timestamp >
          shippingOrder.arrival_timestamp
          ? COLORS.RED
          : COLORS.GREEN;
      }

      console.error('Unhandled type: ', type);

      return null;
    },
    [type, shippingOrder],
  );

  const dataDisplay = useMemo(() => {
    switch (type) {
      case POINT_TYPES.departure:
        return {
          siteName: shippingOrder.departure_site.name,
          siteIcon: <SiteIcon src={DepartureSiteIcon} alt="Departure site" />,
          siteInfo: t('shippingOrders:panelSections.route.departureSite'),
          departure: {
            timestamp: shippingOrder.departure_timestamp,
            realTimestamp: shippingOrder.real_departure_timestamp || '-',
            realTimestampColor: getRealTimestampColor(),
            diff: getDiff(
              shippingOrder.real_departure_timestamp,
              shippingOrder.departure_timestamp,
              user.lang,
              true,
            ),
          },
        };
      case POINT_TYPES.checkpoint:
        return {
          siteName: shippingOrder.arrival_site.name,
          siteIcon: <SiteIcon src={CheckpointIcon} alt="Checkpoint" />,
          siteInfo: t('shippingOrders:panelSections.route.checkpoint'),
          arrival: {
            timestamp: shippingOrder.arrival_timestamp || '-',
            realTimestamp: shippingOrder.real_arrival_timestamp || '-',
            realTimestampColor: getRealTimestampColor(),
            diff: getDiff(
              shippingOrder.real_arrival_timestamp,
              shippingOrder.arrival_timestamp,
              user.lang,
              true,
            ),
          },
          departure: {
            timestamp: shippingOrder.checkpoint_departure_timestamp || (
              <EmptySpan>-</EmptySpan>
            ),
            realTimestamp:
              shippingOrder.checkpoint_real_departure_timestamp || '-',
            realTimestampColor: getRealTimestampColor(true),
            diff: getDiff(
              shippingOrder.checkpoint_real_departure_timestamp,
              shippingOrder.checkpoint_departure_timestamp,
              user.lang,
              true,
            ),
          },
          timeOnSite: getDiff(
            shippingOrder.checkpoint_real_departure_timestamp,
            shippingOrder.real_arrival_timestamp,
            user.lang,
          ),
        };
      case POINT_TYPES.arrival:
        return {
          siteName: shippingOrder.arrival_site.name,
          siteIcon: <SiteIcon src={ArrivalSiteIcon} alt="Arrival site" />,
          siteInfo: t('shippingOrders:panelSections.route.arrivalSite'),
          arrival: {
            timestamp: shippingOrder.arrival_timestamp,
            realTimestamp: shippingOrder.real_arrival_timestamp || '-',
            realTimestampColor: getRealTimestampColor(),
            diff: getDiff(
              shippingOrder.real_arrival_timestamp,
              shippingOrder.arrival_timestamp,
              user.lang,
              true,
            ),
          },
        };
      default:
        console.error('Unhandled type: ', type);
    }
  }, [type, shippingOrder, t, getRealTimestampColor, user]);

  return (
    <PointContainer marginBottom={POINT_TYPES.arrival !== type}>
      <SiteHeader>
        {dataDisplay.siteIcon}
        <SiteName>{dataDisplay.siteName}</SiteName>
        <SiteInfo>{dataDisplay.siteInfo}</SiteInfo>
        {POINT_TYPES.checkpoint === type && (
          <>
            <Pipe>|</Pipe>
            <SiteInfo>Temps sur site: </SiteInfo>
            <SiteInfo>
              <Text.Bold>{dataDisplay.timeOnSite}</Text.Bold>
            </SiteInfo>
          </>
        )}
      </SiteHeader>
      <SiteInfos isDeparture={POINT_TYPES.departure === type}>
        {dataDisplay.arrival && (
          <Timestamps
            type={POINT_TYPES.arrival}
            dataDisplay={dataDisplay.arrival}
          />
        )}
        {dataDisplay.departure && (
          <Timestamps
            type={POINT_TYPES.departure}
            dataDisplay={dataDisplay.departure}
          />
        )}
      </SiteInfos>
      {POINT_TYPES.departure !== type && (
        <Orders
          shippingOrder={shippingOrder}
          orders={orders}
          isLast={POINT_TYPES.arrival === type}
          refetchOrders={refetchOrders}
        />
      )}
    </PointContainer>
  );
};

Point.propTypes = {
  type: PropTypes.oneOf(Object.values(POINT_TYPES)),
  shippingOrder: PropTypes.object,
  orders: PropTypes.array,
  refetchOrders: PropTypes.func,
};

export { Point };
