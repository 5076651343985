import styled from 'styled-components';

export const NextEstimatedStopContainer = styled.div`
    display: flex;
    align-items: center;

    & img {
        width: 14px;
        height: 14px;
    }

    & p {
        margin-left: 10px;
    }
`;

export const Hour = styled.span`
    margin-left: 5px;
    font-weight: bold;
    color: ${({ color }) => color};
`;
