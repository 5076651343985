import {
  Form as LibForm,
  GraySeparator as LibGraySeparator,
  Theme,
} from 'front-library';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Titles = styled.div`
      display: grid;
      gap: 17px;
      justify-items: center;
      text-align: center;
  
      h1 {
          color: ${Theme.palette.primary.darkest};
          font-size: 20px;
      }
  
      h2 {
          color: ${Theme.palette.grey.dark};
          font-size: 16px;
      }
  `;

export const Form = styled(LibForm)`
      min-width: 280px;
      max-width: 420px;
      display: flex;
      flex-direction: column;
      gap: 0;
  
      > div:not(:last-of-type) {
          margin-bottom: 25px;
      }
  
      > button {
          margin-top: 42px;
      }
  `;

export const GraySeparator = styled(LibGraySeparator)`
      width: 100%;
  `;

export const Links = styled.div`
      text-align: center;
      
      a {
          display: block;
          margin-bottom: 17px;
          color: ${Theme.palette.primary.darkest};
          font-size: 12px;
      }
  
      > a {
          font-family: 'Muli bold';
      }
  
      a:nth-child(2) {
          font-family: 'Muli light';
      }
  `;

export const ForgottenLink = styled(Link)`
      :hover {
          transition: 0.5s;
          color: ${Theme.palette.primary.light};
      }
  `;

export const Illustration = styled.img`
      width: 192px;
  `;

export const PasswordCriterias = styled.ul`
      font-size: 10px;
      color: ${Theme.palette.grey.dark};
      li {
          list-style: disc;
      }
  `;
