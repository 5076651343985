import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Switch } from 'front-library';
import DisabledAddIcon from 'front-library/build/img/icon-add-disabled.svg';
import AddIcon from 'front-library/build/img/icon-add-green.svg';

import uniqueId from 'lodash/uniqueId';
import * as Actions from '../../../../../stores/shippingOrder/actions';
import { Checkpoint } from './Checkpoint';
import { AddButton, HelpText, Title } from './styled-components';

const Checkpoints = ({ checkpoints, departureDate, dispatch }) => {
  const { t } = useTranslation();

  const [isToggled, setIsToggled] = useState(checkpoints.length > 0);

  const handleToggle = () => {
    setIsToggled(!isToggled);

    dispatch({
      type: Actions.SET_CHECKPOINTS_ACTION,
      payload: isToggled
        ? []
        : [
            {
              site: null,
              checkpointDepartureDate: null,
              checkpointArrivalDate: null,
              uniqueId: uniqueId(),
            },
          ],
    });
  };

  const isButtonDisabled = () =>
    checkpoints.some(
      (point) =>
        !point.site ||
        !point.checkpointDepartureDate ||
        !point.checkpointArrivalDate,
    );

  const updateCheckpoints = (index, checkpoint) => {
    const newCheckpoints = [...checkpoints];
    newCheckpoints[index] = checkpoint;

    dispatch({
      type: Actions.SET_CHECKPOINTS_ACTION,
      payload: newCheckpoints,
    });
  };

  const removeCheckpoint = (index) => {
    const newCheckpoints = [...checkpoints];
    newCheckpoints.splice(index, 1);

    dispatch({
      type: Actions.SET_CHECKPOINTS_ACTION,
      payload: newCheckpoints,
    });
  };

  return (
    <>
      <Title>
        {t('shippingOrders:modal.steps.step', { step: 4 })}
        {t('shippingOrders:modal.steps.checkpointsTitle')}
        <HelpText fontSize="12px" color="#707070">
          {t('shippingOrders:modal.steps.optional')}
        </HelpText>
      </Title>

      <Switch
        checked={isToggled}
        labelSwichtTitle={t(
          'shippingOrders:modal.steps.checkPointCheckboxLabel',
        )}
        onChange={() => handleToggle()}
      />

      {/* Checkpoints */}
      {isToggled &&
        checkpoints.map((checkpoint, index) => (
          <Checkpoint
            key={checkpoint.uniqueId}
            index={index}
            site={checkpoint.site}
            checkpointDepartureDate={checkpoint.checkpointDepartureDate}
            checkpointArrivalDate={checkpoint.checkpointArrivalDate}
            minDate={
              0 === index
                ? departureDate
                : checkpoints[index - 1].checkpointDepartureDate
            }
            onChange={(formCheckpoint) =>
              updateCheckpoints(index, {
                ...formCheckpoint,
                uniqueId: checkpoint.uniqueId,
              })
            }
            onRemove={() => removeCheckpoint(index)}
          />
        ))}

      {isToggled && (
        <AddButton
          variant="green"
          text={t('shippingOrders:modal.steps.addCheckpoint')}
          img={isButtonDisabled() ? DisabledAddIcon : AddIcon}
          imgPosition="left"
          onClick={() =>
            dispatch({
              type: Actions.SET_CHECKPOINTS_ACTION,
              payload: [
                ...checkpoints,
                {
                  checkpointArrivalDate: null,
                  checkpointDepartureDate: null,
                  site: {},
                  uniqueId: uniqueId(),
                },
              ],
            })
          }
          disabled={isButtonDisabled()}
        />
      )}
    </>
  );
};

Checkpoints.propTypes = {
  checkpoints: PropTypes.array,
  departureDate: PropTypes.object,
  dispatch: PropTypes.func,
};

export { Checkpoints };
