import React from 'react';
import { Circle, Polygon, Rectangle, Tooltip } from 'react-leaflet';
import styled from 'styled-components';

const STYLE = {
  site: {
    color: '#ef9d11',
    fillColor: '#ef9d11',
  },
  zone: {
    color: '#93AACC',
    fillColor: '#93AACC',
  },
};

const Input = styled.input`
  width: 100px;
`;

const Geofence = ({ geofence, styleType, isEdited, onChangeName }) => {
  return (
    <>
      {geofence && geofence.type === 'circle' && (
        <Circle geofenceType={styleType} {...geofence} {...STYLE[styleType]}>
          {styleType === 'zone' && (
            <Tooltip
              permanent={true}
              direction="top"
              className="leaflet-edit-tooltip"
            >
              {isEdited && (
                <Input
                  type="text"
                  value={geofence.name}
                  onChange={onChangeName}
                  onFocus={(e) => e.target.select()}
                />
              )}
              {!isEdited && geofence.name}
            </Tooltip>
          )}
        </Circle>
      )}
      {geofence && geofence.type === 'rectangle' && (
        <Rectangle geofenceType={styleType} {...geofence} {...STYLE[styleType]}>
          {styleType === 'zone' && (
            <Tooltip
              permanent={true}
              direction="top"
              className="leaflet-edit-tooltip"
            >
              {isEdited && (
                <Input
                  type="text"
                  value={geofence.name}
                  onChange={onChangeName}
                  onFocus={(e) => e.target.select()}
                />
              )}
              {!isEdited && geofence.name}
            </Tooltip>
          )}
        </Rectangle>
      )}
      {geofence && geofence.type === 'polygon' && (
        <Polygon
          geofenceType={styleType}
          {...geofence}
          positions={geofence.bounds}
          {...STYLE[styleType]}
        >
          {styleType === 'zone' && (
            <Tooltip
              permanent={true}
              direction="top"
              className="leaflet-edit-tooltip"
            >
              {isEdited && (
                <Input
                  type="text"
                  value={geofence.name}
                  onChange={onChangeName}
                  onFocus={(e) => e.target.select()}
                />
              )}
              {!isEdited && geofence.name}
            </Tooltip>
          )}
        </Polygon>
      )}
    </>
  );
};

export { Geofence };
