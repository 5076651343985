import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, TextField, Theme } from 'front-library';

import { HelpText, Sms, Title } from '../styled-components';

const Options = ({ formValues, setFormValues, errors = {} }) => {
  const { t } = useTranslation();

  const options = useMemo(
    () => [
      {
        value: 'enableTemperature',
        title: t('companies:form.options.temperature'),
      },
      { value: 'enableHumidity', title: t('companies:form.options.humidity') },
      { value: 'enableShock', title: t('companies:form.options.shock') },
      { value: 'enableBranch', title: t('companies:form.options.branch') },
      { value: 'enableApi', title: t('companies:form.options.api') },
      {
        value: 'enableShippingOrders',
        title: t('companies:form.options.shippingOrders'),
      },
      {
        value: 'enableCycleAnalytic',
        title: t('companies:form.options.cycleAnalytic'),
      },
      {
        value: 'enableCustomParameters',
        title: t('companies:form.options.customParameters'),
      },
      {
        value: 'enableAutomatedActions',
        title: t('companies:form.options.automatedActions'),
      },
      {
        value: 'enableTableau',
        title: t('companies:form.options.tableau'),
        element: ({ errors }) => (
          <TextField
            label={t('companies:form.tableauWorkbookUrl')}
            onChange={(tableauWorkbookUrl) =>
              setFormValues({ ...formValues, tableauWorkbookUrl })
            }
            value={formValues.tableauWorkbookUrl}
            errorText={
              errors['input.tableau_workbook_url']
                ? t('companies:form.urlError')
                : null
            }
          />
        ),
      },
      {
        value: 'isSmsEnabled',
        title: t('companies:form.options.sms'),
        element: () => (
          <>
            <TextField
              label={t('companies:form.smsQuota')}
              onChange={(smsQuota) =>
                setFormValues({ ...formValues, smsQuota })
              }
              type="number"
              value={formValues.smsQuota}
            />
            {null !== formValues.smsCounter && (
              <HelpText $marginTop="5px">
                {t('companies:form.sentSms')}
                <Sms
                  $color={
                    formValues.smsCounter > formValues.smsQuota
                      ? Theme.palette.error.main
                      : Theme.palette.success.main
                  }
                >
                  {formValues.smsCounter}
                </Sms>
              </HelpText>
            )}
          </>
        ),
      },
      {
        value: 'isImportErrorAlertEnabled',
        title: t('companies:form.options.isImportErrorAlertEnabled'),
        element: () => (
          <>
            <TextField
              label={t('companies:form.importErrorRecipients')}
              value={formValues.importErrorRecipients}
              onChange={(importErrorRecipients) =>
                setFormValues({ ...formValues, importErrorRecipients })
              }
              noMargin={true}
            />
            <HelpText $marginTop="5px">
              {t('companies:form.importErrorRecipientsHelpText')}
            </HelpText>
          </>
        ),
      },
    ],
    [formValues, setFormValues, t],
  );

  const handleOptionChange = (e, option) =>
    setFormValues({ ...formValues, [option]: e.target.checked });

  return (
    <>
      <Title>{t('companies:form.functionalities')}</Title>

      {options.map((option) => (
        <div key={option.value}>
          <Checkbox
            label={option.title}
            name={option.value}
            onChange={(e) => handleOptionChange(e, option.value)}
            checked={formValues[option.value]}
          />
          {/* Display optional input element if it exists */}
          {option.element &&
            formValues[option.value] &&
            option.element({ errors })}
        </div>
      ))}
    </>
  );
};

Options.propTypes = {
  errors: PropTypes.object,
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
};

export { Options };
