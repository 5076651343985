import styled from 'styled-components';

export const CarrierAndWeight = styled.div`
    padding: 5px 20px 0 38px;
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

export const Comment = styled.div`
    padding: 10px 10px 5px 38px;
`;
