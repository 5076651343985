import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { generatePath } from 'react-router-dom';

import { routes } from '../../../routes';

export const NO_BRANCH = -1;

export function redirect(selected, navigate) {
  switch (selected.type) {
    case 'site':
      navigate(
        generatePath(routes.siteMap.path, {
          id: selected.value,
          location: `@${selected.fullObject.coordinates.latitude},${selected.fullObject.coordinates.longitude}`,
        }),
      );
      break;
    case 'asset':
      navigate(
        generatePath(routes.assetMap.path, {
          id: selected.value,
          location: `@${selected.fullObject.mag.last_accepted_coordinates.latitude},${selected.fullObject.mag.last_accepted_coordinates.longitude}`,
        }),
      );
      break;
    case 'shipping_order':
      navigate(
        generatePath(
          `${routes.shippingOrders.path}?reference=${selected.value}`,
        ),
      );
      break;
    default:
      throw new Error('Unhandled type: ', selected.type);
  }
}

export function filterSites(
  sites,
  siteTypesFilter,
  branchesFilter,
  canUseBranches,
) {
  if (!sites || 0 === sites.length) {
    return null;
  }

  const typesFilters = siteTypesFilter.map(({ value }) => +value);
  const branchesFilters = branchesFilter.map(({ value }) => +value);
  const noBranch = branchesFilters.includes(NO_BRANCH);

  const filtered = typesFilters.length
    ? [...sites].filter((s) => typesFilters.includes(s.type.id))
    : [...sites];

  return canUseBranches && 0 !== branchesFilters.length
    ? filtered.filter(
        (s) =>
          (noBranch && 0 === s.branches.length) ||
          s.branches.some((b) => branchesFilters.includes(b.id)),
      )
    : filtered;
}

export function filterAssets(
  assets,
  assetTypesFilter,
  assetStatesFilter,
  branchesFilter,
  stationarySinceFilter,
  offsiteFilter,
  canUseBranches,
) {
  if (!assets) {
    return null;
  }

  const typesFilters = assetTypesFilter.map(({ value }) => +value);
  const stateFilter = assetStatesFilter.map((x) => +x);
  const branchesFilters = branchesFilter
    .map(({ value }) => +value)
    .filter((bf) => NO_BRANCH !== bf);
  const noBranch = !!branchesFilter.find((bf) => bf.value === NO_BRANCH);

  return [...assets].filter((asset) => {
    if (0 !== typesFilters.length && !typesFilters.includes(asset.type?.id)) {
      return false;
    }

    if (0 !== stateFilter.length && !stateFilter.includes(asset.state?.id)) {
      return false;
    }

    if (canUseBranches) {
      if (
        noBranch &&
        0 === branchesFilters.length &&
        0 !== asset.branches.length
      ) {
        return false;
      }

      if (
        0 !== branchesFilters.length &&
        !asset.branches.some((b) => branchesFilters.includes(b.id))
      ) {
        if (!(noBranch && 0 === asset.branches.length)) {
          return false;
        }
      }
    }

    if (
      0 !== stationarySinceFilter &&
      moment().diff(moment(asset.date_enter_site), 'days') <
        stationarySinceFilter
    ) {
      return false;
    }

    return !(
      offsiteFilter &&
      (1 !== asset.current_sites.length ||
        'OFF_SITE' !== asset.current_sites[0].type?.name)
    );
  });
}

export function filterShippingOrders(
  shippingOrders,
  branchesFilter,
  canUseBranches,
) {
  if (!shippingOrders) {
    return null;
  }

  if (!canUseBranches || 0 === branchesFilter.length) {
    return shippingOrders;
  }

  const branchesFilters = branchesFilter.map(({ value }) => value);
  const noBranch = branchesFilters.includes(NO_BRANCH);

  return [...shippingOrders].filter(
    (so) =>
      (noBranch && 0 === so.asset_group.branches.data.length) ||
      so.asset_group.branches.data.some((b) => branchesFilters.includes(b.id)),
  );
}

export function mapSearchData(sites, assets, shippingOrders) {
  let data = [];

  if (sites?.length) {
    data = [
      ...data,
      ...sites.map((s) => ({
        value: s.id,
        title: s.name,
        type: 'site',
        fullObject: s,
      })),
    ];
  }

  if (assets?.length) {
    data = [
      ...data,
      ...assets.map((a) => ({
        value: a.id,
        title: `${a.reference} - ${a?.mag?.mag_id}`,
        type: 'asset',
        fullObject: a,
      })),
    ];
  }

  if (shippingOrders?.length) {
    data = [
      ...data,
      ...shippingOrders.map((so) => ({
        value: so.reference,
        title: so.reference,
        type: 'shipping_order',
        fullObject: so,
      })),
    ];
  }

  return sortBy(data, ['title']);
}
