import { useMemo } from 'react';
import { fetchAssetCollection } from '../../http/assetsRepository';
import { useBatchHttpQueryCollection } from '../useBatchHttpQueryCollection';

function useCompanyAssets(companyId) {
  const queryParams = useMemo(() => ({ company_id: companyId }), [companyId]);

  return useBatchHttpQueryCollection(fetchAssetCollection, queryParams);
}

export { useCompanyAssets };
