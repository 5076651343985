export function mapCompanyToFormValues(company = null) {
  return {
    id: company?.id || null,
    name: company?.name || '',
    address: company?.address || '',
    phoneNumber: company?.phone_number || '',
    defaultGeofence: company?.default_geofence || 500,
    etaPeriod: company?.eta_period || 30,
    timeToDeliver: company?.time_to_deliver || 10,
    shippingOrderFirstDelayAlert:
      company?.shipping_order_first_delay_alert || 120,
    dateFormat: company?.date_format || 'd/m/Y H:i:s',
    enableTemperature: company?.enable_temperature || false,
    shippingOrderDelayFrequenceAlert:
      company?.shipping_order_delay_frequence_alert || 30,
    orderDeliveryDelay: company?.order_delivery_delay || 360,
    optimiseRealDepartureDate: company?.optimise_real_departure_date || false,
    assetBranchAssignmentByPosition:
      company?.asset_branch_assignment_by_position ?? true,
    batteryLifeLimit: company?.battery_life_limit || 3,
    accuracy: company?.accuracy || 200,
    enableShock: company?.enable_shock || false,
    enableHumidity: company?.enable_humidity || false,
    enableBranch: company?.enable_branch || false,
    enableApi: company?.enable_api || false,
    enableShippingOrders: company?.enable_shipping_orders || false,
    enableCycleAnalytic: company?.enable_cycle_analytic || false,
    enablePairSite: company?.enable_pair_site || false,
    earlyDeparture: company?.early_departure || 240,
    legalReference: company?.legal_reference || '',
    enableCustomLogo: company?.enable_custom_logo || false,
    likelyDeviatedMax: company?.likely_deviated_max || 0,
    enableCustomParameters: company?.enable_custom_parameters || false,
    enableAutomatedActions: company?.enable_automated_actions || false,
    enableTableau: company?.enable_tableau || false,
    tableauWorkbookUrl: company?.tableau_workbook_url || '',
    isSmsEnabled: company?.is_sms_enabled || false,
    smsQuota: company?.sms_quota || '',
    smsCounter: company?.sms_counter || null,
    isDepartureStartEnabled: company?.is_departure_start_enabled || false,
    autoRefreshInterval: company?.auto_refresh_interval || 60,
    degradedPositionMsgWithoutPosition:
      company?.degraded_position_msg_without_position || 3,
    degradedPositionDistanceMin:
      company?.degraded_position_distance_min || 50000,
    periodLifeBattery: company?.period_life_battery || 30,
    readjustmentLimit: company?.readjustment_limit || 180,
    logoUrl: company?.logo_url || '',
    file: company?.file || null,
    enableApproximateGeolocationInterval:
      !!company?.approximate_geolocation_interval,
    approximateGeolocationInterval:
      company?.approximate_geolocation_interval || '',
    isImportErrorAlertEnabled: company?.import_error_recipients
      ? 0 !== company.import_error_recipients.length
      : false,
    importErrorRecipients: company?.import_error_recipients || '',
    enableShippingOrderImportReplace:
      company?.enable_shipping_order_import_replace || false,
    enableShippingOrderImportPreserveAsset:
      company?.enable_shipping_order_import_preserve_asset || false,
  };
}

export function mapFormValuesToGraphql(formValues) {
  return {
    ...(formValues.id && { id: formValues.id }),
    name: formValues.name.trim(),
    address: formValues.address.trim(),
    phone_number: formValues.phoneNumber,
    default_geofence: +formValues.defaultGeofence,
    eta_period: +formValues.etaPeriod,
    time_to_deliver: +formValues.timeToDeliver,
    shipping_order_first_delay_alert: +formValues.shippingOrderFirstDelayAlert,
    date_format: formValues.dateFormat,
    enable_temperature: formValues.enableTemperature,
    shipping_order_delay_frequence_alert:
      +formValues.shippingOrderDelayFrequenceAlert,
    order_delivery_delay: +formValues.orderDeliveryDelay,
    optimise_real_departure_date: formValues.optimiseRealDepartureDate,
    asset_branch_assignment_by_position:
      formValues.assetBranchAssignmentByPosition,
    battery_life_limit: +formValues.batteryLifeLimit,
    accuracy: +formValues.accuracy,
    enable_shock: formValues.enableShock,
    enable_humidity: formValues.enableHumidity,
    enable_branch: formValues.enableBranch,
    enable_api: formValues.enableApi,
    enable_shipping_orders: formValues.enableShippingOrders,
    enable_cycle_analytic: formValues.enableCycleAnalytic,
    enable_pair_site: formValues.enablePairSite,
    early_departure: +formValues.earlyDeparture,
    legal_reference: formValues.legalReference,
    enable_custom_logo: formValues.enableCustomLogo,
    likely_deviated_max: +formValues.likelyDeviatedMax,
    enable_custom_parameters: formValues.enableCustomParameters,
    enable_automated_actions: formValues.enableAutomatedActions,
    enable_tableau: formValues.enableTableau,
    tableau_workbook_url: formValues.tableauWorkbookUrl,
    is_sms_enabled: formValues.isSmsEnabled,
    sms_quota: formValues.isSmsEnabled ? +formValues.smsQuota : 0,
    is_departure_start_enabled: formValues.isDepartureStartEnabled,
    auto_refresh_interval: +formValues.autoRefreshInterval,
    degraded_position_msg_without_position:
      +formValues.degradedPositionMsgWithoutPosition,
    degraded_position_distance_min: +formValues.degradedPositionDistanceMin,
    period_life_battery: +formValues.periodLifeBattery,
    readjustment_limit: +formValues.readjustmentLimit,
    file: formValues.file,
    approximate_geolocation_interval:
      formValues.enableApproximateGeolocationInterval &&
      formValues.approximateGeolocationInterval
        ? +formValues.approximateGeolocationInterval
        : null,
    import_error_recipients: formValues.isImportErrorAlertEnabled
      ? formValues.importErrorRecipients
      : '',
    enable_shipping_order_import_replace:
      formValues.enableShippingOrderImportReplace,
    enable_shipping_order_import_preserve_asset:
      formValues.enableShippingOrderImportPreserveAsset,
  };
}
