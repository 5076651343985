import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ProgressBar as FrontLibProgressBar, Tooltip } from 'front-library';

import { MeContext } from '../../../../contexts/MeContext';

import {
  CheckpointName,
  CheckpointNameSameBranches,
  CheckpointTimestamp,
  Container,
} from './styled-components';
import {
  buildPoints,
  getBarColor,
  getDotColor,
  getNextPoint,
  getProgression,
} from './utils';

const ProgressBar = ({ shippingOrder }) => {
  const { t } = useTranslation();
  const { me: user } = useContext(MeContext);
  const points = useMemo(() => buildPoints(shippingOrder), [shippingOrder]);

  return (
    <Container>
      <FrontLibProgressBar.ProgressBar>
        {points.map((point, index) => {
          const nextPoint = getNextPoint(points, index);
          const sameBranches =
            user.company.enable_branch &&
            0 !==
              _.intersectionWith(
                user.branches.map(({ id }) => id),
                point.siteBranches.map(({ id }) => id),
                _.isEqual,
              ).length;

          return (
            <FrontLibProgressBar.Point
              key={`${point.siteName}_${point.timestamp}`}
              barColor={nextPoint ? getBarColor(nextPoint) : null}
              dotColor={getDotColor(point)}
              progression={nextPoint ? getProgression(nextPoint) : null}
            >
              {sameBranches && (
                <CheckpointNameSameBranches>
                  <Tooltip text={t('shippingOrders:list.row.sameBranches')}>
                    {point.siteName}
                  </Tooltip>
                </CheckpointNameSameBranches>
              )}
              {!sameBranches && (
                <CheckpointName>{point.siteName}</CheckpointName>
              )}
              <CheckpointTimestamp>{point.timestamp}</CheckpointTimestamp>
            </FrontLibProgressBar.Point>
          );
        })}
      </FrontLibProgressBar.ProgressBar>
    </Container>
  );
};

ProgressBar.propTypes = {
  shippingOrder: PropTypes.object,
};

export { ProgressBar };
