import PropTypes from 'prop-types';
import React from 'react';

import { Form } from '../../atoms/Form/Form';

import { GraySeparator } from 'front-library';
import { Asset } from './Sections/Asset';
import { AutoRefreshInterval } from './Sections/AutoRefreshInterval';
import { DegradedPosition } from './Sections/DegradedPosition.js';
import { General } from './Sections/General';
import { Mag } from './Sections/Mag';
import { Options } from './Sections/Options';
import { ShippingOrder } from './Sections/ShippingOrder';

const CompanyForm = ({
  formValues,
  setFormValues,
  errors = {},
  onCancel,
  onSubmit,
}) => {
  const canSubmit =
    formValues.name &&
    formValues.address &&
    formValues.phoneNumber &&
    formValues.batteryLifeLimit &&
    formValues.timeToDeliver &&
    formValues.etaPeriod &&
    formValues.defaultGeofence &&
    formValues.shippingOrderFirstDelayAlert &&
    formValues.shippingOrderDelayFrequenceAlert &&
    formValues.dateFormat &&
    formValues.accuracy;

  return (
    <Form disableSubmit={!canSubmit} onCancel={onCancel} onSubmit={onSubmit}>
      {/* Sections */}
      <General
        formValues={formValues}
        setFormValues={setFormValues}
        errors={errors}
      />

      <GraySeparator $borderStyle="dashed" />
      <DegradedPosition formValues={formValues} setFormValues={setFormValues} />

      <GraySeparator $borderStyle="dashed" />
      <AutoRefreshInterval
        formValues={formValues}
        setFormValues={setFormValues}
      />

      <GraySeparator $borderStyle="dashed" />
      <Options
        formValues={formValues}
        setFormValues={setFormValues}
        errors={errors}
      />

      <GraySeparator $borderStyle="dashed" />
      <Asset formValues={formValues} setFormValues={setFormValues} />

      <GraySeparator $borderStyle="dashed" />
      <Mag formValues={formValues} setFormValues={setFormValues} />

      <GraySeparator $borderStyle="dashed" />
      <ShippingOrder formValues={formValues} setFormValues={setFormValues} />
    </Form>
  );
};

CompanyForm.propTypes = {
  errors: PropTypes.object,
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { CompanyForm };
