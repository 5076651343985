import L from 'leaflet';
import 'leaflet-polylinedecorator';
import React, { useEffect, useRef } from 'react';
import { Polyline, withLeaflet } from 'react-leaflet';

const PolylineDecoratorLayer = ({ patterns, ...restProps }) => {
  const polyRef = useRef(null);
  const decorator = useRef(null);

  useEffect(() => {
    const polyline = polyRef.current.leafletElement; //get native Leaflet polyline
    const { map } = polyRef.current.props.leaflet; //get native Leaflet map

    if (null === decorator.current) {
      decorator.current = L.polylineDecorator(polyline, {
        patterns: patterns,
      }).addTo(map);
    } else {
      decorator.current.setPatterns(patterns);
    }
  }, [patterns]);

  useEffect(() => () => decorator.current.remove(), []);

  return <Polyline ref={polyRef} {...restProps} />;
};

const PolylineDecorator = withLeaflet(PolylineDecoratorLayer);

export { PolylineDecorator };
