import { Button, Form as LibForm } from 'front-library';
import PropTypes from 'prop-types';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { Actions } from './styled-components';

const Form = ({
  children,
  className = '',
  cancelTranslateKey = 'common:cancel',
  disableSubmit = false,
  hideCancel = false,
  hideSubmit = false,
  onCancel = null,
  onSubmit = null,
  submitTranslateKey = 'common:save',
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <LibForm className={className} onSubmit={onSubmit} {...props}>
      {children}
      {(!hideCancel || !hideSubmit) && (
        <Actions>
          {!hideCancel && (
            <Button
              onClick={onCancel}
              text={t(cancelTranslateKey)}
              variant="red"
            />
          )}
          {!hideSubmit && (
            <Button
              disabled={disableSubmit}
              text={t(submitTranslateKey)}
              type="submit"
              variant="green"
            />
          )}
        </Actions>
      )}
    </LibForm>
  );
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  cancelTranslateKey: PropTypes.string,
  disableSubmit: PropTypes.bool,
  hideCancel: PropTypes.bool,
  hideSubmit: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  submitTranslateKey: PropTypes.string,
};

export { Form };
