import { useMutation } from '@apollo/client';
import { Loading } from 'front-library';
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { setDisconnectFormValues } from '../../../helpers';
import {
  convertStringToValue,
  mapCustomParametersValues,
} from '../../../helpers/customParameters';
import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { AssetForm } from '../../organisms/Assets/AssetForm';
import { CREATE_ASSET, GET_ASSET_STATES, UPDATE_ASSET } from './graphql';

import { Modal } from '../../molecules/Modal/Modal';
import { mapAssetFormValuesToGraphql, mapAssetToFormValues } from './utils';

const Asset = ({ asset = null, dataCustomParameters, onClose, onSave }) => {
  const { t } = useTranslation();

  const [updateAssetMutation, { loading: updateLoading }] =
    useMutation(UPDATE_ASSET);
  const [createAssetMutation, { loading: createLoading }] =
    useMutation(CREATE_ASSET);
  const [errors, setErrors] = useState({});
  const [assetFormValues, setAssetFormValues] = useState(
    mapAssetToFormValues(asset),
  );
  const [customParametersFormValues, setCustomParametersFormValues] = useState(
    {},
  );

  const handleUpdateCustomParametersFormValue = (id, value) => {
    setCustomParametersFormValues({
      ...customParametersFormValues,
      [id]: value,
    });
  };

  const { data: assetStatesData, loading: assetStatesLoading } =
    useQueryGraphQl(GET_ASSET_STATES);

  const isLoading = useMemo(
    () => assetStatesLoading || createLoading || updateLoading,
    [assetStatesLoading, createLoading, updateLoading],
  );

  /** @TODO remove this useEffect, not appropriate here */
  useEffect(() => {
    if (
      asset?.custom_parameters?.data &&
      dataCustomParameters?.custom_parameters?.data
    ) {
      const mappedCustomParameters = {};

      for (const customParam of asset.custom_parameters.data) {
        mappedCustomParameters[customParam.id] = convertStringToValue(
          customParam.pivot.value,
          dataCustomParameters.custom_parameters.data.find(
            ({ id }) => id === customParam.id,
          )?.value_type,
        );
      }

      setCustomParametersFormValues(mappedCustomParameters);

      return () => {};
    }
  }, [asset, dataCustomParameters]);

  const handleUpdate = async () => {
    try {
      const graphqlData = mapAssetFormValuesToGraphql(assetFormValues);
      setDisconnectFormValues(asset, graphqlData); // Add disconnect for relations that are no more selected

      await updateAssetMutation({
        variables: {
          id: +asset.id,
          ...graphqlData,
          custom_parameters: mapCustomParametersValues(
            customParametersFormValues,
          ),
        },
      });

      setErrors({});
      onClose();
      toast.success(
        t('assets:updated.success', { reference: graphqlData.reference }),
      );
      onSave();
    } catch (e) {
      setErrors(e?.graphQLErrors[0]?.extensions?.validation || {});
      toast.error(t('assets:updated.error'));
    }
  };

  const handleSave = async () => {
    try {
      const graphqlData = mapAssetFormValuesToGraphql(assetFormValues);
      await createAssetMutation({
        variables: {
          ...graphqlData,
          custom_parameters: mapCustomParametersValues(
            customParametersFormValues,
          ),
        },
      });

      setErrors({});
      onClose();
      toast.success(
        t('assets:created.success', { reference: graphqlData.reference }),
      );
      onSave();
    } catch (e) {
      setErrors(e?.graphQLErrors[0]?.extensions?.validation || {});
      toast.error(t('assets:created.error'));
    }
  };

  return (
    <Modal
      onClose={onClose}
      title={asset ? t('assets:update') : t('assets:create')}
    >
      {isLoading && <Loading />}
      {!isLoading && (
        <AssetForm
          asset={asset}
          assetStates={assetStatesData?.asset_states?.data}
          errors={errors}
          formValues={assetFormValues}
          setFormValues={setAssetFormValues}
          onSubmit={asset ? handleUpdate : handleSave}
          customParameters={dataCustomParameters?.custom_parameters?.data}
          onCancel={onClose}
          handleUpdateCustomParametersFormValue={
            handleUpdateCustomParametersFormValue
          }
          customParametersFormValues={customParametersFormValues}
        />
      )}
    </Modal>
  );
};

export { Asset };
