import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, TextField } from 'front-library';

import { getErrorText } from '../../../helpers';
import { Form } from '../../atoms/Form/Form';

const PasswordForm = ({
  errors = {},
  formValues,
  onCancel,
  onSubmit,
  sameUser,
  setFormValues,
}) => {
  const { t } = useTranslation();

  return (
    <Form hideCancel={!onCancel} onCancel={onCancel} onSubmit={onSubmit}>
      <TextField
        errorText={errors.admin_password || null}
        label={t('user:form.yourPassword')}
        name="admin_password"
        onChange={(adminPassword) =>
          setFormValues({ ...formValues, adminPassword })
        }
        required={true}
        type="password"
        value={formValues.adminPassword}
      />

      <TextField
        errorText={getErrorText(errors, 'password', t('user:form.password'))}
        label={t('user:form.newPassword')}
        name="password"
        onChange={(password) => setFormValues({ ...formValues, password })}
        required={true}
        type="password"
        value={formValues.password}
      />

      <TextField
        label={t('user:form.passwordConfirmation')}
        name="password_confirmation"
        onChange={(passwordConfirmation) =>
          setFormValues({ ...formValues, passwordConfirmation })
        }
        required={true}
        type="password"
        value={formValues.passwordConfirmation}
      />

      {!sameUser && (
        <Checkbox
          label={t('user:form.force')}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              isForcedPasswordUpdate: !!e.target.checked,
            })
          }
          checked={formValues.isForcedPasswordUpdate}
        />
      )}
    </Form>
  );
};

PasswordForm.propTypes = {
  errors: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  sameUser: PropTypes.bool.isRequired,
};

export { PasswordForm };
