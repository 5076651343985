import { useEffect, useState } from 'react';

function useBatchHttpQueryItem(fetcher, ids, append = false) {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (ignore) {
        return;
      }

      const promises = [];

      for (const id of ids) {
        promises.push(fetcher({ id }, controller));
      }

      const itemCollection = await Promise.all(promises);

      if (append) {
        setItems((alreadyFetched) => [
          ...alreadyFetched,
          ...itemCollection
            .filter((x) => x.content !== null)
            .flatMap((item) => item.content),
        ]);
      } else {
        setItems([
          ...itemCollection
            .filter((x) => x.content !== null)
            .flatMap((item) => item.content),
        ]);
      }

      setIsLoading(false);
    }

    const controller = new AbortController();
    let ignore = false;
    fetchData();

    return () => {
      ignore = true;
      controller.abort('Aborted due to page/view change.');
    };
  }, [fetcher, ids, append]);

  return { isLoading, items };
}

export { useBatchHttpQueryItem };
