import PropTypes from 'prop-types';
import React from 'react';

import { TitleBar } from '../molecules/TitleBar';
import { FullHeightContainer } from './styled-components';

const ListPage = ({
  className = '',
  children,
  downloadFile,
  fullHeightContainer = true,
  leftElement = null,
  onAdd,
  onImport,
  onPaginate,
  onRowsPerPageChange,
  onSearch,
  paginatorInfo,
  asyncExport = false,
  pathExportCsv,
  pathExportXls,
  title,
}) => {
  return (
    <div className={className}>
      <TitleBar
        downloadFile={downloadFile}
        leftElement={leftElement}
        onAdd={onAdd}
        onImport={onImport}
        onPaginate={onPaginate}
        onRowsPerPageChange={onRowsPerPageChange}
        onSearch={onSearch}
        paginatorInfo={paginatorInfo}
        asyncExport={asyncExport}
        pathExportCsv={pathExportCsv}
        pathExportXls={pathExportXls}
        title={title}
      />
      {fullHeightContainer && (
        <FullHeightContainer>{children}</FullHeightContainer>
      )}
      {!fullHeightContainer && <div>{children}</div>}
    </div>
  );
};

ListPage.propTypes = {
  className: PropTypes.string,
  downloadFile: PropTypes.func,
  fullHeightContainer: PropTypes.bool,
  leftElement: PropTypes.element,
  onAdd: PropTypes.func,
  onImport: PropTypes.func,
  onPaginate: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSearch: PropTypes.func,
  paginatorInfo: PropTypes.object,
  asyncExport: PropTypes.bool,
  pathExportCsv: PropTypes.string,
  pathExportXls: PropTypes.string,
  title: PropTypes.string,
};

export { ListPage };
