import L from 'leaflet';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';

import { Geofence } from '../Geofence';
import { icons } from '../icons';

const PopupOffset = new L.Point(0, 0);

const SiteMarker = ({ site, onGeofenceEdit }) => {
  const [over, setOver] = useState(false);

  const icon = useMemo(
    () => (over && onGeofenceEdit && icons.siteEdit) || icons.site,
    [over, onGeofenceEdit],
  );

  return (
    <>
      <Marker
        position={[site.latitude, site.longitude]}
        onMouseOver={(e) => e.target.openPopup() && setOver(true)}
        onMouseOut={(e) => e.target.closePopup() && setOver(false)}
        onclick={() => onGeofenceEdit?.(site.id)}
        icon={icon}
      >
        {site.name && <Popup offset={PopupOffset}>{site.name}</Popup>}
      </Marker>
      {site.geofence && <Geofence geofence={site.geofence} styleType="site" />}
    </>
  );
};

SiteMarker.propTypes = {
  site: PropTypes.object.isRequired,
  onGeofenceEdit: PropTypes.func,
};

export { SiteMarker };
