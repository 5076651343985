import { useMutation } from '@apollo/client';
import { BtnInATab, Loading } from 'front-library';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import IconAddGreen from 'front-library/build/img/icon-add-green.svg';
import { confirmAlert } from 'react-confirm-alert';
import { MeContext } from '../../../../contexts/MeContext';
import { useQueryGraphQl } from '../../../../hooks/useQueryGraphQl';
import { routes } from '../../../../routes';
import { ListPage } from '../../../templates/ListPage';
import { AssetType } from '../AssetType';
import { DELETE_ASSET_TYPE, GET_ASSET_TYPES } from '../graphql';
import { DataGrid } from './DataGrid';

const AssetTypeList = () => {
  const { me: user } = useContext(MeContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: assetTypesData,
    setPagination,
    setFirst,
    setVariable,
    setOrderBy,
    loading,
    refetch,
  } = useQueryGraphQl(GET_ASSET_TYPES, { search: '%' });
  const [deleteAssetType, { loading: deleteLoading }] =
    useMutation(DELETE_ASSET_TYPE);

  const handleDelete = (row) => {
    confirmAlert({
      title: t('common:deletion'),
      message: t('assetTypes:askDelete', { name: row.name }),
      buttons: [
        {
          label: t('common:yes'),
          onClick: () => handleDeleteConfirm(row),
        },
        {
          label: t('common:no'),
        },
      ],
    });
  };

  const handleDeleteConfirm = async ({ id, name }) => {
    try {
      await deleteAssetType({ variables: { id } });
      await refetch();
      toast.success(t('assetTypes:deleted.success', { name }));
    } catch (e) {
      console.error(e);
      toast.error(t('assetTypes:deleted.error', { name }));
    }
  };

  const handleAdd = () => navigate(routes.administrationAssetTypeCreation.path);

  return (
    <>
      <Routes>
        <Route
          path={routes.administrationAssetTypeCreation.partialPath}
          element={<AssetType refetch={refetch} />}
        />
        <Route
          path={routes.administrationAssetTypeEdition.partialPath}
          element={<AssetType refetch={refetch} />}
        />
      </Routes>
      <ListPage
        title={t('menu:assetTypeManagement')}
        paginatorInfo={assetTypesData?.asset_types?.paginatorInfo}
        onPaginate={setPagination}
        onRowsPerPageChange={setFirst}
        onSearch={(search) => setVariable('search', `%${search.trim()}%`)}
        onAdd={handleAdd}
      >
        <DataGrid
          data={assetTypesData?.asset_types?.data}
          onDelete={handleDelete}
          onOrderBy={setOrderBy}
          enableTemperature={user?.company?.enable_temperature}
        />
        {(loading || deleteLoading) && <Loading />}
        <div className="btnInATabContainer">
          <BtnInATab
            text={t('assetTypes:add')}
            onClick={handleAdd}
            imgSrc={IconAddGreen}
          />
        </div>
      </ListPage>
    </>
  );
};

export { AssetTypeList };
