import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useMemo } from 'react';

import { Button, Switch } from 'front-library';

import imgPen from 'front-library/build/img/edit-pen.svg';
import fileIcon from 'front-library/build/img/icon-apercu.svg';
import imgOrangeValid from 'front-library/build/img/orange-valid.svg';

import { urlDecode } from '../../../helpers';
import {
  convertStringToValue,
  extractFilenameFromUrl,
} from '../../../helpers/customParameters';
import { FileViewer } from '../FileViewer';
import {
  ButtonTitle,
  CPDescription,
  CPTitle,
  CPValue,
  ImgPen,
  StyledAutocomplete,
  TextFieldContainer,
} from './styled-components';

const Types = {
  NUMBER: 'number',
  STRING: 'string',
  TEXT: 'text',
  BOOLEAN: 'boolean',
  DATE: 'date',
  FILE: 'file',
  AUTOCOMPLETE: 'autocomplete',
};

const EditableField = ({
  description,
  label,
  onSave,
  query,
  type,
  value: valuePros,
}) => {
  const [fileToDisplay, setFileToDisplay] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(valuePros);

  const editable = useMemo(() => typeof onSave === 'function', [onSave]);

  const handleClick = useCallback(() => {
    if (isEditing) {
      onSave(value);
    }

    setIsEditing(!isEditing);
  }, [isEditing, value, onSave]);

  switch (type) {
    case Types.NUMBER:
    case Types.STRING:
    case Types.TEXT: {
      const inputType =
        (type === Types.TEXT && 'textarea') ||
        (type === Types.NUMBER && Types.NUMBER) ||
        Types.TEXT;

      return (
        <div>
          <CPTitle>
            <ButtonTitle
              type="button"
              onClick={handleClick}
              disabled={!editable}
            >
              {label}
              {editable && !isEditing && <ImgPen src={imgPen} />}
              {editable && isEditing && <ImgPen src={imgOrangeValid} />}
            </ButtonTitle>
          </CPTitle>
          <CPValue>
            <TextFieldContainer
              value={value}
              disabled={!isEditing}
              type={inputType}
              onChange={(v) => setValue(v)}
              noMargin={true}
            />
          </CPValue>
          {description && <CPDescription>{description}</CPDescription>}
        </div>
      );
    }
    case Types.BOOLEAN:
      return (
        <>
          <Switch
            labelSwichtTitle={label}
            checked={convertStringToValue(value, type)}
            className="switchNotification switchNotificationAssetLeave"
          />
          {description && (
            <p className="switchTitleDescription textLabelInput">
              {description}
            </p>
          )}
        </>
      );
    case Types.DATE:
      return (
        <>
          <CPTitle>{label}</CPTitle>
          <CPValue>{moment(value).format('YYYY-MM-DD')}</CPValue>
          {description && <CPDescription>{description}</CPDescription>}
        </>
      );
    case Types.FILE: {
      const filename = urlDecode(extractFilenameFromUrl(value));

      return (
        <>
          <CPTitle>{label}</CPTitle>
          <Button
            text={filename}
            img={fileIcon}
            imgPosition="right"
            onClick={() => setFileToDisplay(value)}
          />
          {description && <CPDescription>{description}</CPDescription>}
          {fileToDisplay && (
            <FileViewer
              fileType="url"
              filename={urlDecode(extractFilenameFromUrl(fileToDisplay))}
              filePath={fileToDisplay}
              onClose={() => setFileToDisplay(null)}
            />
          )}
        </>
      );
    }
    case Types.AUTOCOMPLETE:
      return (
        <div>
          <CPTitle>
            <ButtonTitle onClick={handleClick} disabled={!editable}>
              {label}
              {editable && !isEditing && (
                <ImgPen src={imgPen} onClick={() => setIsEditing(!isEditing)} />
              )}
              {editable && isEditing && <ImgPen src={imgOrangeValid} />}
            </ButtonTitle>
          </CPTitle>
          <CPValue>
            <StyledAutocomplete
              disabled={!isEditing}
              onChange={(v) => setValue(v)}
              query={query}
              value={value}
            />
          </CPValue>
        </div>
      );
    default:
      throw new Error('Type error');
  }
};

EditableField.propTypes = {
  description: PropTypes.string,
  label: PropTypes.string,
  onSave: PropTypes.func,
  query: PropTypes.object,
  type: PropTypes.oneOf(Object.values(Types)).isRequired,
  value: PropTypes.any,
};

export { EditableField };
