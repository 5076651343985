import { useMutation } from '@apollo/client';
import qs from 'qs';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { generatePathWithQueryParams, routes } from '../../../routes';
import { Loading } from '../../molecules/Loading/Loading';
import { SiteFormContainer } from './SiteFormContainer';
import { GET_SITE_FOR_FORM, UPDATE_SITE } from './graphql';

const SiteEdit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const { editGeofence = false, soReference = null } = qs.parse(
    location.search,
    { ignoreQueryPrefix: true },
  );

  const { data, loading } = useQueryGraphQl(GET_SITE_FOR_FORM, { id });
  const [updateSite, { loading: updateLoading }] = useMutation(UPDATE_SITE);

  const handleSave = async (site, onError) => {
    try {
      await updateSite({
        variables: site,
      });

      onError({});
      toast.success(t('site:updated.success', { name: site.name }));

      redirectAfterAction();
    } catch (e) {
      console.error(e);
      toast.error(t('site:updated.error', { name: site.name }));
      onError(e?.graphQLErrors[0]?.extensions?.validation || {});
    }
  };

  const redirectAfterAction = useCallback(() => {
    if (soReference) {
      navigate(
        generatePathWithQueryParams(
          routes.shippingOrders.path,
          {},
          { reference: soReference },
        ),
      );
    } else if (location.key) {
      navigate(-1);
    } else {
      navigate(routes.administrationSite.path);
    }
  }, [location, navigate, soReference]);

  if (loading) {
    return <Loading />;
  }

  if (!data || !data.site) {
    window.location = '/404';
    return;
  }

  const type = data?.site?.geofence?.__typename
    .replace('Geofence', '')
    .toLowerCase();
  const geofence = {
    id: data?.site?.id,
    radius: data?.site?.geofence?.radius,
    type: data?.site?.geofence?.is_rectangle ? 'rectangle' : type,
    center: [data?.site?.geofence?.latitude, data?.site?.geofence?.longitude],
    bounds: data?.site?.geofence?.points?.map((p) => [p.latitude, p.longitude]),
  };
  const zones = data?.site?.zones?.map((z) => {
    return {
      id: z.id,
      name: z.name,
      type: z.geofence?.is_rectangle
        ? 'rectangle'
        : z.geofence?.__typename.replace('Geofence', '').toLowerCase(),
      radius: z.geofence?.radius,
      center: [z.geofence?.latitude, z.geofence?.longitude],
      bounds: z.geofence?.points?.map((p) => [p.latitude, p.longitude]),
    };
  });

  return (
    <SiteFormContainer
      onSave={handleSave}
      onCancel={redirectAfterAction}
      site={{
        ...data.site,
        zones,
        geofence,
        bssids: data.site.bssids?.map((b) => b.BSSID),
        branches: data.site.branches,
        mags: data.site.mags,
      }}
      defaultEditGeofence={!!editGeofence}
      mutationLoading={updateLoading}
    />
  );
};

export { SiteEdit };
