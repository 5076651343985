import styled from 'styled-components';

export const Background = styled.div`
  background-color: #20676C;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 3fr 5fr;
  grid-template-rows: 1fr 1fr;
  height: 100vh;
  max-width: 1680px;
  margin: auto;
  padding: 20px 50px;
  color: white;
`;

export const Illustration = styled.div`
  grid-area: 1/2/3/3;
  justify-self: center;
  align-self: center;
  > img {
    height: 60vh;
  }
  
  @media (max-width: 730px) {
    display: none;
  }
`;

export const TextContainer = styled.div`
    align-content: center;
`;

export const Title = styled.h1`
  font-size: 30px;
  align-self: center;

  @media (max-width: 730px) {
    grid-area: 1/1/2/3;
  }
`;

export const AdditionalText = styled.p`
    margin-top: 30px;
`;

export const LinksContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 37px 37px;
  align-self: center;

  @media (max-width: 730px) {
    grid-area: 2/1/3/3;
  }
`;

/**
 * It is not possible to use the router Link component here,
 * because for 5xx error it is not wrapped in the router.
 */
export const Link = styled.a`
  color: #b9e6eb;
  transition: all 0.2s ease-out;
  border-bottom: 1px solid #20676C;

  :hover {
    border-bottom: 1px solid #ef9D11;
    color: white;
  }

  :active {
    border-bottom: 1px solid white;
    color: white;
  }
`;
