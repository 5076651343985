import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BtnInATab, TextField } from 'front-library';
import IconAddDisabled from 'front-library/build/img/icon-add-disabled.svg';
import IconAddGreen from 'front-library/build/img/icon-add-green.svg';

import { Autocomplete } from '../../molecules/Autocomplete/Autocomplete';
import { GET_SITES_WITHOUT_OFFSITE } from '../../molecules/Autocomplete/graphql';

import { getErrorText } from '../../../helpers';
import { Form } from '../../atoms/Form/Form';
import { TourModelPointForm } from './TourModelPointForm';

const TourModelForm = ({
  formValues,
  setFormValues,
  errors = {},
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const isAddButtonEnabled = useMemo(() => {
    const lastCheckpoint =
      formValues.tourModelPoints[formValues.tourModelPoints.length - 1];

    return '00:00:00' !== lastCheckpoint.duration && lastCheckpoint.site;
  }, [formValues]);

  const addPoint = () => {
    const points = formValues.tourModelPoints;
    points.push({
      site: null,
      duration: null,
      tourOrder: formValues.tourModelPoints.length + 1,
    });

    setFormValues({ ...formValues, tourModelPoints: points });
  };

  const canSubmit = useMemo(
    () =>
      formValues.name &&
      formValues.reference &&
      formValues.departureSite &&
      formValues.tourModelPoints.every((tmp) => tmp.duration && tmp.site),
    [formValues],
  );

  return (
    <Form disableSubmit={!canSubmit} onCancel={onCancel} onSubmit={onSubmit}>
      {/* Name */}
      <TextField
        errorText={getErrorText(errors, 'name', t('tourModels:form.name'))}
        label={t('tourModels:form.name')}
        onChange={(name) => setFormValues({ ...formValues, name })}
        required={true}
        value={formValues.name}
      />

      {/* Reference */}
      <TextField
        errorText={getErrorText(
          errors,
          'reference',
          t('tourModels:form.reference'),
        )}
        label={t('tourModels:form.reference')}
        onChange={(reference) => setFormValues({ ...formValues, reference })}
        required={true}
        value={formValues.reference}
      />

      {/* Departure site */}
      <Autocomplete
        errorText={getErrorText(
          errors,
          'departure_site.connect',
          t('tourModels:form.departureSite'),
        )}
        label={t('tourModels:form.departureSite')}
        onChange={(departureSite) =>
          setFormValues({ ...formValues, departureSite })
        }
        query={GET_SITES_WITHOUT_OFFSITE}
        required={true}
        value={formValues.departureSite}
      />

      {/* Checkpoints */}
      {formValues.tourModelPoints.map((point, index) => (
        <TourModelPointForm
          key={point.tourOrder}
          index={index}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      ))}

      <BtnInATab
        className={
          isAddButtonEnabled
            ? 'btn-add-special btn-condition'
            : 'btn-disabled-special'
        }
        imgSrc={isAddButtonEnabled ? IconAddGreen : IconAddDisabled}
        text={t('tourModels:form.addCheckPoint')}
        disabled={!isAddButtonEnabled}
        onClick={addPoint}
      />
    </Form>
  );
};

TourModelForm.propTypes = {
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
  errors: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { TourModelForm };
