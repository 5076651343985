import { Button } from 'front-library';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ProfileSection = styled.div`
    margin-top: 20px;
    & + div {
        margin-top: 30px;
    }
    & > div {
        margin-top: 10px;
    }
    & > .geofence {
        margin-top: 0;
    }
`;

export const AssetsTotal = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 2px 8px #00000026;
    border: 0.5px solid #B3B3B3;
    border-radius: 3px;
    opacity: 1;
    padding: 10px;
    display: flex;
    justify-content: space-between;
`;

export const TableLink = styled(Link)`
    color: #002b2b;
    font-family: 'Muli bold';
    transition: ease-out 0.2s;
`;

export const LoadMoreButton = styled(Button)`
    width: 100%;
    background-color: rgba(185, 230, 235, 0.5);
`;

export const PanelContainer = styled.div`
    z-index: 1300;
    position: fixed;
`;

export const PanelContent = styled.div`
    padding: 20px;
    max-height: 65vh;
`;

export const Head = styled.div`
    display: flex;
    align-items: center;
`;

export const Name = styled.h1`
    font-size: 20px;
    flex-grow: 1;
`;

export const Type = styled.div`
    font-size: 14px;
`;

export const ImgClose = styled.img`
    width: 12px;
    margin-top: -3px;
    margin-left: 20px;
    cursor: pointer;
`;

export const Container = styled.div`
    height: 100vh;
`;
