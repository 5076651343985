import React from 'react';
import { generatePath } from 'react-router-dom';

import { isProduction } from '../helpers/env';

import { AutoRefresh } from '../components/atoms/AutoRefresh/AutoRefresh';
import { PageTracker } from '../components/atoms/PageTracker/PageTracker';
import { Tableau } from '../components/atoms/Tableau/Tableau';
import { Alert } from '../components/pages/Alert/Alert';
import { AssetGroupList } from '../components/pages/AssetGroups/List/AssetGroupList';
import { AssetStateList } from '../components/pages/AssetStates/List/AssetStateList';
import { AssetTypeList } from '../components/pages/AssetTypes/List/AssetTypeList';
import { AssetList } from '../components/pages/Assets/List/AssetList';
import { ForgotPassword } from '../components/pages/Auth/ForgotPassword';
import { Login } from '../components/pages/Auth/Login';
import { ResetPassword } from '../components/pages/Auth/ResetPassword';
import { SsoLogin } from '../components/pages/Auth/SsoLogin';
import { AutomatedActions } from '../components/pages/AutomatedActions/AutomatedActions';
import { BranchList } from '../components/pages/Branches/List/BranchList';
import { CallbackList } from '../components/pages/Callbacks/List/CallbackList';
import { CompanyList } from '../components/pages/Companies/List/CompanyList';
import { CustomParametersList } from '../components/pages/CustomParameters/List/CustomParametersList';
import { FluxManagement } from '../components/pages/FluxManagement/FluxManagement';
import { Import } from '../components/pages/Import/Import';
import { MagDatasSender } from '../components/pages/MagDatasSender/MagDatasSender';
import { MagTypeList } from '../components/pages/MagTypes/List/MagTypeList';
import { MagList } from '../components/pages/Mags/List/MagList';
import { MapPage } from '../components/pages/MapPage/MapPage';
import { ShippingOrders } from '../components/pages/ShippingOrders/ShippingOrders';
import { SiteList } from '../components/pages/Site/List/SiteList';
import { SiteEdit } from '../components/pages/Site/SiteEdit';
import { SiteNew } from '../components/pages/Site/SiteNew';
import { SiteTypeList } from '../components/pages/SiteTypes/List/SiteTypeList';
import { SmsLinkPage } from '../components/pages/SmsLinkPage/SmsLinkPage';
import { TourModelList } from '../components/pages/TourModels/List/TourModelList';
import { UserList } from '../components/pages/Users/List/UserList';
import { Password } from '../components/pages/Users/Password';
import { Version } from '../components/pages/Version/Version';
import { PageWithSidebar } from '../components/templates/PageWithSidebar';

const categories = {
  dashboard: {
    getTitle: (t) => t('menu:cat.dashboard'),
    isMainli: true,
    allowed: (user) => user.rolesName.includes('Administrator'),
  },
  assetManagement: {
    getTitle: (t) => t('menu:cat.assetsManagement'),
    isMainli: false,
    allowed: (user) => user.rolesName.includes('Administrator'),
  },
  administration: {
    getTitle: (t) => t('menu:cat.administration'),
    isMainli: true,
    allowed: (user) => user.rolesName.includes('Administrator'),
  },
  superAdmin: {
    getTitle: (t) => t('menu:superAdmin'),
    isMainli: true,
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  sites: {
    getTitle: (t) => t('menu:cat.site'),
    isMainli: false,
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  assets: {
    getTitle: (t) => t('menu:cat.asset'),
    isMainli: false,
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  shippingOrders: {
    getTitle: (t) => t('menu:cat.shippingOrder'),
    isMainli: false,
    allowed: (user) =>
      user.company.enable_shipping_orders &&
      (user.rolesName.includes('superAdmin') ||
        (user.rolesName.includes('Administrator') &&
          user.rolesName.includes('shipping_order_option'))),
  },
  mag: {
    getTitle: (t) => t('menu:cat.mag'),
    isMainli: false,
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  api: {
    getTitle: (t) => t('menu:cat.api'),
    isMainli: false,
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      (user.rolesName.includes('ApiAdmin') && user.company.enable_api),
  },
  tools: {
    getTitle: (t) => t('menu:cat.tools'),
    isMainli: false,
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
};

const routes = {
  login: {
    getTitle: () => '',
    path: '/auth/login',
    component: <Login />,
    allowed: true,
  },
  ssoLogin: {
    getTitle: () => '',
    path: '/auth/sso/login',
    component: <SsoLogin />,
    allowed: true,
  },
  forgotPassword: {
    getTitle: () => '',
    path: '/auth/forgot-password',
    component: <ForgotPassword />,
    allowed: true,
  },
  resetPassword: {
    getTitle: () => '',
    path: '/auth/reset-password/:token',
    partialPath: '/auth/reset-password/',
    component: <ResetPassword />,
    allowed: true,
  },
  map: {
    getTitle: (t) => t('menu:map'),
    path: '/map/*',
    component: (
      <PageWithSidebar>
        <PageTracker pageName="map">
          <AutoRefresh>
            <MapPage />
          </AutoRefresh>
        </PageTracker>
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.rolesName.includes('Operator') ||
      user.rolesName.includes('Administrator'),
  },
  filtersMap: {
    getTitle: (t) => t('menu:map'),
    path: '/map/filters/:location?',
    partialPath: 'filters/:location?',
    allowed: (user) =>
      user.rolesName.includes('Operator') ||
      user.rolesName.includes('Administrator'),
    v2: true,
    isMainli: true,
    otherLinkActive: [/^\/map/],
  },
  assetMap: {
    path: '/map/asset/:id/:location?',
    partialPath: 'asset/:id/:location?',
    allowed: (user) =>
      user.rolesName.includes('Operator') ||
      user.rolesName.includes('Administrator'),
  },
  siteMap: {
    path: '/map/site/:id/:location?',
    partialPath: 'site/:id/:location?',
    allowed: (user) =>
      user.rolesName.includes('Operator') ||
      user.rolesName.includes('Administrator'),
  },
  shippingOrders: {
    getTitle: (t) => t('menu:shippingorders'),
    path: '/shippingorders/*',
    v2: true,
    otherLinkActive: [/^\/shippingorders\/create/],
    isMainli: true,
    component: (
      <PageWithSidebar>
        <PageTracker pageName="delivery">
          <AutoRefresh>
            <ShippingOrders />
          </AutoRefresh>
        </PageTracker>
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.company.enable_shipping_orders &&
      user.rolesName.includes('shipping_order_option'),
  },
  shippingOrdersCreation: {
    getTitle: (t) => t('menu:shippingorderCreation'),
    path: '/shippingorders/create',
    partialPath: 'create',
    v2: true,
    allowed: (user) =>
      user.company.enable_shipping_orders &&
      user.rolesName.includes('shipping_order_option') &&
      user.rolesName.includes('Administrator'),
  },
  dashboard: {
    getTitle: (t) => t('menu:dashboard'),
    path: '/dashboard',
    v2: true,
    isMainli: true,
    component: (
      <PageWithSidebar>
        <Tableau />
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.company.enable_tableau &&
      user.company.tableau_workbook_url &&
      !user?.rolesName?.includes('Operator'),
  },
  administrationFlux: {
    getTitle: (t) => t('menu:fluxManagement'),
    path: '/flux',
    isMainli: false,
    v2: true,
    component: (
      <PageWithSidebar>
        <FluxManagement />
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.rolesName.includes('superAdmin') &&
      user.rolesName.includes('cycle_analytic_option') &&
      user.company.enable_cycle_analytic,
  },
  administrationSite: {
    getTitle: (t) => t('menu:sitesManagment'),
    path: '/site',
    isMainli: false,
    v2: true,
    otherLinkActive: [/^\/v2\/site\/(new|edit)/],
    component: (
      <PageWithSidebar>
        <SiteList />
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator') ||
      user.rolesName.includes('Operator'),
  },
  administrationSiteType: {
    getTitle: (t) => t('menu:siteTypeManagment'),
    path: '/site_type/*',
    isMainli: false,
    v2: true,
    otherLinkActive: [/^\/site_type\/create/, /^\/site_type\/[0-9]+\/edit/],
    component: (
      <PageWithSidebar>
        <SiteTypeList />
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  administrationSiteTypeCreation: {
    getTitle: (t) => t('menu:siteTypeCreation'),
    path: '/site_type/create',
    partialPath: 'create',
    v2: true,
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  administrationSiteTypeEdition: {
    getTitle: (t) => t('menu:siteTypeEdition'),
    path: '/site_type/:id/edit',
    partialPath: ':id/edit',
    v2: true,
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  administrationAssetType: {
    getTitle: (t) => t('menu:assetTypeManagement'),
    path: '/asset_type/*',
    isMainli: false,
    otherLinkActive: [/^\/asset_type\/create/, /^\/asset_type\/[0-9]+\/edit/],
    v2: true,
    component: (
      <PageWithSidebar>
        <AssetTypeList />
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  administrationAssetTypeEdition: {
    getTitle: (t) => t('menu:assetTypeEdition'),
    path: '/asset_type/:id/edit',
    partialPath: ':id/edit',
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  administrationAssetTypeCreation: {
    getTitle: (t) => t('menu:assetTypeCreation'),
    path: '/asset_type/create',
    partialPath: 'create',
    allowed: (user) =>
      user.rolesName.includes('Operator') ||
      user.rolesName.includes('Administrator'),
  },
  administrationAsset: {
    getTitle: (t) => t('menu:assetsManagement'),
    exact: true,
    path: '/asset',
    isMainli: false,
    v2: true,
    otherLinkActive: [/^\/asset\/create/, /^\/asset\/[0-9]+\/edit/],
    component: (
      <PageWithSidebar>
        <AssetList page="view" />
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  administrationAssetEdition: {
    getTitle: (t) => t('menu:assetEdition'),
    path: '/asset/:id/edit',
    v2: true,
    isMainli: false,
    component: (
      <PageWithSidebar>
        <AssetList page="edit" />
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  administrationAssetGroup: {
    getTitle: (t) => t('menu:assetGroup'),
    path: '/asset_group/:message?',
    isMainli: false,
    v2: true,
    component: (
      <PageWithSidebar>
        <AssetGroupList />
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  administrationAssetGroupCreation: {
    path: '/asset_group/create',
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  administrationAssetGroupEdition: {
    path: '/asset_group/:id/edit',
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  administrationAssetState: {
    getTitle: (t) => t('menu:assetStateManagement'),
    path: '/asset_state/*',
    isMainli: false,
    v2: true,
    otherLinkActive: [/^\/asset_state\/create/, /^\/asset_state\/[0-9]+\/edit/],
    component: (
      <PageWithSidebar>
        <AssetStateList />
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      (user.rolesName.includes('asset_management') &&
        user.rolesName.includes('Administrator')),
  },
  administrationAssetStateCreation: {
    getTitle: (t) => t('menu:assetStateCreation'),
    path: '/asset_state/create',
    partialPath: 'create',
    v2: true,
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      (user.rolesName.includes('asset_management') &&
        user.rolesName.includes('Administrator')),
  },
  administrationAssetStateEdition: {
    getTitle: (t) => t('menu:assetStateEdition'),
    path: '/asset_state/:id/edit',
    partialPath: ':id/edit',
    v2: true,
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      (user.rolesName.includes('asset_management') &&
        user.rolesName.includes('Administrator')),
  },
  administrationCustomParameters: {
    getTitle: (t) => t('menu:customParameters'),
    path: '/custom-parameters/*',
    isMainli: false,
    v2: true,
    component: (
      <PageWithSidebar>
        <CustomParametersList />
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.company.enable_custom_parameters &&
      (user.rolesName.includes('superAdmin') ||
        user.rolesName.includes('Administrator')),
  },
  administrationCustomParameterCreation: {
    getTitle: (t) => t('menu:customParameters'),
    path: '/custom-parameters/create',
    partialPath: 'create',
    v2: true,
    allowed: (user) =>
      user.company.enable_custom_parameters &&
      (user.rolesName.includes('superAdmin') ||
        user.rolesName.includes('Administrator')),
  },
  administrationCustomParameterEdition: {
    getTitle: (t) => t('menu:customParameters'),
    path: '/custom-parameters/edit/:id',
    partialPath: 'edit/:id',
    v2: true,
    allowed: (user) =>
      user.company.enable_custom_parameters &&
      (user.rolesName.includes('superAdmin') ||
        user.rolesName.includes('Administrator')),
  },
  administrationBranch: {
    getTitle: (t) => t('menu:branchManagement'),
    path: '/branch/*',
    isMainli: false,
    v2: true,
    otherLinkActive: [/^\/branch\/create/, /^\/branch\/[0-9]+\/edit/],
    component: (
      <PageWithSidebar>
        <BranchList />
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.company.enable_branch &&
      (user.rolesName.includes('superAdmin') ||
        user.rolesName.includes('Administrator')),
  },
  administrationBranchCreation: {
    getTitle: (t) => t('menu:branchCreation'),
    path: '/branch/create',
    partialPath: 'create',
    v2: true,
    allowed: (user) =>
      user.company.enable_branch &&
      (user.rolesName.includes('superAdmin') ||
        user.rolesName.includes('Administrator')),
  },
  administrationBranchEdition: {
    getTitle: (t) => t('menu:branchEdition'),
    path: '/branch/edit/:id',
    partialPath: 'edit/:id',
    v2: true,
    allowed: (user) =>
      user.company.enable_branch &&
      (user.rolesName.includes('superAdmin') ||
        user.rolesName.includes('Administrator')),
  },
  administrationTourModel: {
    getTitle: (t) => t('menu:deliveryModel'),
    path: '/tour_model/*',
    isMainli: false,
    v2: true,
    otherLinkActive: [/^\/tour_model\/create/, /^\/tour_model\/[0-9]+\/edit/],
    component: (
      <PageWithSidebar>
        <TourModelList />
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.rolesName.includes('shipping_order_option') &&
      user.company.enable_shipping_orders &&
      (user.rolesName.includes('superAdmin') ||
        user.rolesName.includes('Administrator')),
  },
  administrationTourModelCreation: {
    getTitle: (t) => t('menu:deliveryModelCreation'),
    path: '/tour_model/create',
    partialPath: 'create',
    v2: true,
    allowed: (user) =>
      user.rolesName.includes('shipping_order_option') &&
      user.company.enable_shipping_orders &&
      (user.rolesName.includes('superAdmin') ||
        user.rolesName.includes('Administrator')),
  },
  administrationTourModelEdition: {
    getTitle: (t) => t('menu:deliveryModelEdition'),
    path: '/tour_model/:id/edit',
    partialPath: ':id/edit',
    v2: true,
    allowed: (user) =>
      user.rolesName.includes('shipping_order_option') &&
      user.company.enable_shipping_orders &&
      (user.rolesName.includes('superAdmin') ||
        user.rolesName.includes('Administrator')),
  },
  administrationAutomatedActions: {
    getTitle: (t) => t('menu:automatedActions'),
    path: '/automated_actions',
    isMainli: false,
    v2: true,
    component: (
      <PageWithSidebar>
        <AutomatedActions />
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.company.enable_automated_actions &&
      user.rolesName.includes('superAdmin'),
  },
  alert: {
    getTitle: (t) => t('menu:notification'),
    path: '/v2/alert',
    isMainli: true,
    v2: true,
    component: (
      <PageWithSidebar>
        <Alert />
      </PageWithSidebar>
    ),
    allowed: () => true,
  },
  administrationCompany: {
    getTitle: (t) => t('menu:company'),
    path: '/company/*',
    isMainli: false,
    v2: true,
    otherLinkActive: [/^\/company\/create/, /^\/company\/[0-9]+\/edit/],
    component: (
      <PageWithSidebar>
        <CompanyList />
      </PageWithSidebar>
    ),
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  administrationCompanyCreation: {
    getTitle: (t) => t('menu:companyCreation'),
    path: '/company/create',
    partialPath: 'create',
    v2: true,
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  administrationCompanyEdition: {
    getTitle: (t) => t('menu:companyEdition'),
    path: '/company/:id/edit',
    partialPath: ':id/edit',
    v2: true,
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  administrationUser: {
    getTitle: (t) => t('menu:userManagement'),
    path: '/user/:id?/:action?',
    isMainli: false,
    otherLinkActive: [/^\/user\/create/, /^\/user\/[0-9]+\/edit/],
    component: (
      <PageWithSidebar>
        <UserList />
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  administrationUserCreation: {
    getTitle: (t) => t('menu:userCreation'),
    path: '/user/create',
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  administrationUserEdition: {
    getTitle: (t) => t('menu:userEdition'),
    path: '/user/:id/edit',
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  administrationUserAlertEdition: {
    path: '/user/:id/alerts/edit',
    component: (
      <PageWithSidebar>
        <Alert />
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  administrationPasswordEdition: {
    path: '/user/:id?/password',
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  administrationPasswordReset: {
    path: '/reset/password',
    isMainli: false,
    component: (
      <PageWithSidebar displayMenu={false}>
        <Password sameUser={true} onSave={() => window.location.replace('/')} />
      </PageWithSidebar>
    ),
    allowed: () => true,
  },
  administrationMag: {
    getTitle: (t) => t('menu:magManagement'),
    path: '/mag/*',
    isMainli: false,
    v2: true,
    otherLinkActive: [/^\/mag\/create/, /^\/mag\/edit\/[0-9]+/],
    component: (
      <PageWithSidebar>
        <MagList />
      </PageWithSidebar>
    ),
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  administrationMagCreation: {
    getTitle: (t) => t('menu:magCreation'),
    path: '/mag/create',
    partialPath: 'create',
    v2: true,
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  administrationMagEdition: {
    getTitle: (t) => t('menu:magEdition'),
    path: '/mag/:id/edit',
    partialPath: ':id/edit',
    v2: true,
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  administrationMagImport: {
    path: '/mag/import',
    partialPath: 'import',
    v2: true,
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  magExportXLSX: {
    path: '/mag/exportexcel',
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  magExportCSV: {
    path: '/mag/exportcsv',
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  administrationMagData: {
    getTitle: (t) => t('menu:data'),
    path: '/mag/:id/mag-datas/*',
    partialPath: ':id/mag-datas/*',
    v2: true,
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  administrationMagDataJson: {
    getTitle: (t) => t('menu:data'),
    path: '/mag/:id/mag-datas/:magDataId/json',
    partialPath: ':magDataId/json',
    v2: true,
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  magDataExportXLSX: {
    path: '/mag_data/exportexcel/:mag_id',
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  magDataExportCSV: {
    path: '/mag_data/exportcsv/:mag_id',
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  administrationMagType: {
    getTitle: (t) => t('menu:magType'),
    path: '/mag_type/*',
    isMainli: false,
    v2: true,
    otherLinkActive: [/^\/mag_type\/create/, /^\/mag_type\/[0-9]+\/edit/],
    component: (
      <PageWithSidebar>
        <MagTypeList />
      </PageWithSidebar>
    ),
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  administrationMagTypeCreation: {
    getTitle: (t) => t('menu:magTypeCreation'),
    path: '/mag_type/create',
    partialPath: 'create',
    v2: true,
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  administrationMagTypeEdition: {
    getTitle: (t) => t('menu:magTypeEdition'),
    path: '/mag_type/:id/edit',
    partialPath: ':id/edit',
    v2: true,
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  administrationCallback: {
    getTitle: (t) => t('menu:callback'),
    path: '/callback/*',
    isMainli: false,
    v2: true,
    otherLinkActive: [/^\/callback\/create/, /^\/callback\/[0-9]+\/edit/],
    component: (
      <PageWithSidebar>
        <CallbackList />
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.company.enable_api &&
      (user.rolesName.includes('superAdmin') ||
        user.rolesName.includes('ApiAdmin')),
  },
  administrationCallbackCreation: {
    getTitle: (t) => t('menu:callbackCreation'),
    path: '/callback/create',
    partialPath: 'create',
    v2: true,
    allowed: (user) =>
      user.company.enable_api &&
      (user.rolesName.includes('superAdmin') ||
        user.rolesName.includes('ApiAdmin')),
  },
  administrationCallbackEdition: {
    getTitle: (t) => t('menu:callbackEdition'),
    path: '/callback/:id/edit',
    partialPath: ':id/edit',
    v2: true,
    allowed: (user) =>
      user.company.enable_api &&
      (user.rolesName.includes('superAdmin') ||
        user.rolesName.includes('ApiAdmin')),
  },
  administrationApiDoc: {
    getTitle: (t) => t('menu:apiDocLink'),
    path: process.env.REACT_APP_API_DOC_PATH,
    externalUrl: true,
    isMainli: false,
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      (user.rolesName.includes('ApiAdmin') && user.company.enable_api),
  },
  loggedout: {
    path: '/loggedout',
  },
  ordersAdd: {
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      (user.rolesName.includes('Administrator') &&
        user.rolesName.includes('shipping_order_option') &&
        user.company.enable_shipping_orders),
  },
  ordersDelete: {
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      (user.rolesName.includes('Administrator') &&
        user.rolesName.includes('shipping_order_option') &&
        user.company.enable_shipping_orders),
  },
  ordersUpdate: {
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      (user.rolesName.includes('Administrator') &&
        user.rolesName.includes('shipping_order_option') &&
        user.company.enable_shipping_orders),
  },
  shippingOrderImportPanel: {
    allowed: (user) =>
      user.company.enable_shipping_orders &&
      user.rolesName.includes('shipping_order_option') &&
      user.rolesName.includes('Administrator'),
  },
  panelAlertReport: {
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('asset_management'),
  },
  panelAlertAsset: {
    allowed: () => true,
  },
  panelAlertDelivery: {
    allowed: (user) => user?.company?.enable_shipping_orders,
  },
  panelCustomAlerts: {
    allowed: (user) => user?.company?.enable_automated_actions,
  },
  siteAdd: {
    path: '/v2/site/new',
    component: (
      <PageWithSidebar>
        <SiteNew />
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  siteEdit: {
    path: '/v2/site/edit/:id',
    component: (
      <PageWithSidebar>
        <PageTracker pageName="site edit">
          <SiteEdit />
        </PageTracker>
      </PageWithSidebar>
    ),
    allowed: (user) =>
      user.rolesName.includes('superAdmin') ||
      user.rolesName.includes('Administrator'),
  },
  smsLinkPage: {
    path: '/delivery/:id/:hash?',
    expiredPath: '/delivery/expired',
    isMainli: false,
    component: <SmsLinkPage />,
    allowed: true,
  },
  version: {
    path: '/v2/version',
    component: () => (
      <PageWithSidebar>
        <Version />
      </PageWithSidebar>
    ),
    allowed: () => true,
  },
  magDatasSender: {
    path: '/tools/mag-datas-sender',
    getTitle: (t) => t('menu:magDataGenerator'),
    v2: true,
    component: (
      <PageWithSidebar>
        <MagDatasSender />
      </PageWithSidebar>
    ),
    allowed: (user) => user.rolesName.includes('superAdmin') && !isProduction(),
  },
  import: {
    path: '/tools/import',
    getTitle: (t) => t('menu:import'),
    v2: true,
    component: (
      <PageWithSidebar>
        <Import />
      </PageWithSidebar>
    ),
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  graphqlPlayground: {
    path: '/graphql-playground',
    getTitle: (t) => t('menu:graphqlPlayground'),
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
  horizon: {
    path: '/horizon',
    getTitle: (t) => t('menu:horizon'),
    allowed: (user) => user.rolesName.includes('superAdmin'),
  },
};

const menuLink = [
  { ...routes.filtersMap },
  { ...routes.shippingOrders },
  { ...routes.dashboard, path: generatePath(routes.dashboard.path) },
  {
    ...categories.administration,
    subLink: [
      {
        ...categories.sites,
        subLink: [
          { ...routes.administrationSite },
          { ...routes.administrationSiteType },
        ],
      },
      {
        ...categories.assetManagement,
        subLink: [
          { ...routes.administrationAsset },
          { ...routes.administrationAssetType },
          { ...routes.administrationAssetGroup },
          { ...routes.administrationAssetState },
          { ...routes.administrationCustomParameters },
        ],
      },
      {
        ...categories.shippingOrders,
        subLink: [{ ...routes.administrationTourModel }],
      },
      { ...routes.administrationBranch },
      { ...routes.administrationUser },
      { ...routes.administrationFlux },
      {
        ...categories.api,
        subLink: [
          { ...routes.administrationCallback },
          { ...routes.administrationApiDoc },
        ],
      },
      { ...routes.administrationAutomatedActions },
    ],
  },
  { ...routes.alert },
  {
    ...categories.superAdmin,
    subLink: [
      { ...routes.administrationCompany },
      {
        ...categories.mag,
        subLink: [
          { ...routes.administrationMag },
          { ...routes.administrationMagType },
        ],
      },
      {
        ...categories.tools,
        subLink: [
          { ...routes.magDatasSender },
          { ...routes.import },
          { ...routes.graphqlPlayground },
          { ...routes.horizon },
        ],
      },
    ],
  },
];

function generatePathWithQueryParams(path, params, queryParams) {
  let url = generatePath(path, params);

  if (queryParams) {
    url += '?';
    const arrayQueryParams = Object.entries(queryParams);

    for (let i = 0; i < arrayQueryParams.length; i++) {
      const [key, value] = arrayQueryParams[i];

      if (0 !== i) {
        url += '&';
      }

      url += `${key}=${encodeURI(value)}`;
    }
  }

  return url;
}

export { routes, menuLink, generatePathWithQueryParams };
