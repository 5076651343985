import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import { ColumnCheckbox, Grid } from 'front-library';
import DataIcon from 'front-library/build/img/icon-data.svg';
import EditIcon from 'front-library/build/img/icon-modify-green-2.svg';
import DeleteIcon from 'front-library/build/img/trash.svg';

import { routes } from '../../../../routes';
import { DataGridContainer } from '../styled-components';

const DataGrid = ({ mags, onDelete, onOrderBy, onOrderByRelation }) => {
  const { t } = useTranslation();

  return (
    <DataGridContainer>
      <Grid.Grid>
        <Grid.Header>
          <Grid.Cell size="s">
            <ColumnCheckbox
              label={t('mags:form.magId')}
              onClick={(order) => onOrderBy('MAG_ID', order)}
            />
          </Grid.Cell>
          <Grid.Cell size="s">
            <ColumnCheckbox
              label={t('mags:form.company')}
              onClick={(order) =>
                onOrderByRelation('company', 'MAX', 'NAME', order)
              }
            />
          </Grid.Cell>
          <Grid.Cell size="s">
            <ColumnCheckbox
              label={t('mags:form.owner')}
              onClick={(order) =>
                onOrderByRelation('owner', 'MAX', 'NAME', order)
              }
            />
          </Grid.Cell>
          <Grid.Cell size="s">
            <ColumnCheckbox label={t('mags:form.ble')} />
          </Grid.Cell>
          <Grid.Cell size="s">
            <ColumnCheckbox
              label={t('mags:form.idProvider')}
              onClick={(order) => onOrderBy('PROVIDER_DEVICE_ID', order)}
            />
          </Grid.Cell>
          <Grid.Cell size="s">
            <ColumnCheckbox label={t('mags:form.network')} />
          </Grid.Cell>
          <Grid.Cell size="s">
            <ColumnCheckbox
              label={t('mags:form.magType')}
              onClick={(order) =>
                onOrderByRelation('mag_type', 'MAX', 'NAME', order)
              }
            />
          </Grid.Cell>
          <Grid.Cell size="s">
            <ColumnCheckbox
              label={t('mags:form.lastMessage')}
              onClick={(order) => onOrderBy('LAST_MESSAGE_DATE', order)}
            />
          </Grid.Cell>
          <Grid.Cell size="s">
            <ColumnCheckbox
              label={t('mags:form.lifetimeLeft')}
              onClick={(order) => onOrderBy('DAYS_LEFT', order)}
            />
          </Grid.Cell>
          <Grid.Cell size="s">
            <ColumnCheckbox label={t('mags:form.batteryLeft')} />
          </Grid.Cell>
          <Grid.Cell size="s">
            <ColumnCheckbox
              label={t('mags:form.state.title')}
              onClick={(order) => onOrderBy('STATE', order)}
            />
          </Grid.Cell>
          <Grid.Cell size="xs" />
          <Grid.Cell size="xs" />
          <Grid.Cell size="xs" />
        </Grid.Header>
      </Grid.Grid>

      <Grid.Grid>
        {mags?.map((mag) => (
          <GridRow key={mag.id} mag={mag} onDelete={onDelete} />
        ))}
      </Grid.Grid>
    </DataGridContainer>
  );
};

const GridRow = ({ mag, onDelete }) => {
  const { t } = useTranslation();

  return (
    <Grid.Row>
      <Grid.Cell size="s">{mag.mag_id}</Grid.Cell>
      <Grid.Cell size="s">{mag.company.title}</Grid.Cell>
      <Grid.Cell size="s">{mag.owner.title}</Grid.Cell>
      <Grid.Cell size="s">
        {mag.is_gateway
          ? t('mags:form.gateway')
          : mag.beacon
            ? t('mags:form.beacon')
            : t('mags:form.none')}
      </Grid.Cell>
      <Grid.Cell size="s">{mag.provider_device_id}</Grid.Cell>
      <Grid.Cell size="s">
        {mag.mag_type?.networks.map((network) => network.name).join(', ')}
      </Grid.Cell>
      <Grid.Cell size="s">{mag.mag_type?.name}</Grid.Cell>
      <Grid.Cell size="s">
        {mag.last_message_date ? moment(mag.last_message_date).fromNow() : ''}
      </Grid.Cell>
      <Grid.Cell size="s">
        {mag.days_left > 0
          ? `${Number.parseInt(mag.days_left)} ${t('mags:form.days')}`
          : 0}
      </Grid.Cell>
      <Grid.Cell size="s">
        {mag.mag_type?.characteristics[0].pivot.parameter > 0
          ? `${Math.max(
              0,
              100 -
                Math.round(
                  (mag.message_sent /
                    mag.mag_type.characteristics[0].pivot.parameter) *
                    100,
                ),
            )} %`
          : t('mags:form.notCalculable')}
      </Grid.Cell>
      <Grid.Cell size="s">{t(`mags:form.state.${mag.state}`)}</Grid.Cell>
      <Grid.Cell size="xs" alignment="right">
        <Link
          to={generatePath(routes.administrationMagData.path, { id: mag.id })}
        >
          <Grid.IconButton imgSrc={DataIcon} />
        </Link>
      </Grid.Cell>
      <Grid.Cell size="xs" alignment="right">
        <Link
          to={generatePath(routes.administrationMagEdition.path, {
            id: mag.id,
          })}
        >
          <Grid.IconButton imgSrc={EditIcon} />
        </Link>
      </Grid.Cell>
      <Grid.Cell size="xs" alignment="right">
        <Grid.IconButton
          imgSrc={DeleteIcon}
          onClick={() => onDelete(mag)}
          disabled={false}
        />
      </Grid.Cell>
    </Grid.Row>
  );
};

DataGrid.propTypes = {
  mags: PropTypes.array,
  onDelete: PropTypes.func,
  onOrderBy: PropTypes.func,
  onOrderByRelation: PropTypes.func,
};

GridRow.propTypes = {
  mag: PropTypes.object,
  onDelete: PropTypes.func,
};

export { DataGrid };
