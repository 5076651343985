import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useState, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Loading } from 'front-library';
import { MeContext } from '../../../contexts/MeContext';
import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { routes } from '../../../routes';
import { Modal } from '../../molecules/Modal/Modal';
import { AssetTypeForm } from '../../organisms/AssetTypes/AssetTypeForm';
import {
  CREATE_ASSET_TYPE,
  GET_ASSET_TYPE,
  GET_CUSTOM_PARAMETERS,
  UPDATE_ASSET_TYPE,
} from './graphql';
import {
  mapAssetTypeCustomParametersToFormValues,
  mapAssetTypeToFormValues,
  mapFormValuesToGraphql,
} from './utils';

const AssetType = ({ refetch }) => {
  const { t } = useTranslation();
  const { id = null } = useParams();
  const { me: user } = useContext(MeContext);
  const navigate = useNavigate();

  const { data: assetTypeData, loading: getAssetTypeLoading } = useQueryGraphQl(
    GET_ASSET_TYPE,
    { id },
    {},
    null === id,
  );
  const { data: customParametersData, loading: getCustomParametersLoading } =
    useQueryGraphQl(GET_CUSTOM_PARAMETERS);
  const [createAssetTypeMutation, { loading: createLoading }] =
    useMutation(CREATE_ASSET_TYPE);
  const [updateAssetTypeMutation, { loading: updateLoading }] =
    useMutation(UPDATE_ASSET_TYPE);

  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState({});

  if (assetTypeData?.asset_type && !formValues.id) {
    setFormValues({
      ...formValues,
      ...mapAssetTypeToFormValues(assetTypeData.asset_type),
    });
  }

  if (
    customParametersData?.custom_parameters?.data?.length &&
    assetTypeData?.asset_type?.custom_parameters?.data &&
    !formValues.customParameters
  ) {
    setFormValues({
      ...formValues,
      ...mapAssetTypeCustomParametersToFormValues(
        assetTypeData.asset_type,
        customParametersData.custom_parameters.data,
      ),
    });
  }

  const title = useMemo(
    () => (id ? t('assetTypes:update') : t('assetTypes:create')),
    [id, t],
  );

  const handleUpdateCustomParameters = (id, value) => {
    setFormValues({
      ...formValues,
      customParameters: { ...formValues.customParameters, [id]: value },
    });
  };

  const handleSave = async () => {
    try {
      await createAssetTypeMutation({
        variables: mapFormValuesToGraphql(formValues),
      });

      setErrors({});
      handleClose();
      toast.success(t('assetTypes:created.success', { name: formValues.name }));
      refetch();
    } catch (e) {
      setErrors(e?.graphQLErrors[0]?.extensions?.validation || {});
      toast.error(t('assetTypes:created.error'));
    }
  };

  const handleUpdate = async () => {
    try {
      await updateAssetTypeMutation({
        variables: mapFormValuesToGraphql(formValues),
      });

      setErrors({});
      handleClose();
      toast.success(t('assetTypes:updated.success', { name: formValues.name }));
      refetch();
    } catch (e) {
      setErrors(e?.graphQLErrors[0]?.extensions?.validation || {});
      toast.error(t('assetTypes:updated.error'));
    }
  };

  const handleClose = () =>
    navigate(generatePath(routes.administrationAssetType.path));

  return (
    <Modal onClose={handleClose} title={title}>
      {(getAssetTypeLoading ||
        getCustomParametersLoading ||
        updateLoading ||
        createLoading) && <Loading />}
      {!getAssetTypeLoading && (
        <AssetTypeForm
          enableTemperature={user?.company?.enable_temperature}
          errors={errors}
          formValues={formValues}
          setFormValues={setFormValues}
          customParameters={customParametersData?.custom_parameters?.data}
          handleUpdateCustomParametersFormValue={handleUpdateCustomParameters}
          onCancel={handleClose}
          onSubmit={id ? handleUpdate : handleSave}
        />
      )}
    </Modal>
  );
};

AssetType.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export { AssetType };
