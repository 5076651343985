import styled from 'styled-components';

export const TableauContainer = styled.div`
    width: 100%;
    margin: auto;
    height: 100vh;

    iframe {
        height: 100vh !important;
    }
`;
