import { gql } from '@apollo/client';

const GET_BRANCH = gql`
    query GetBranch($id: ID!) {
        branch(id: $id) {
            id
            name
            reference
        }
    }
`;

const GET_BRANCHES = gql`
    query GetBranches($first: Int, $page: Int, $search: Mixed, $orderBy: [QueryBranchesOrderByRelationOrderByClause!]) {
        branches(first: $first, page: $page, orderBy: $orderBy, where: {
            OR: [
                {
                    column: "id"
                    operator: EQ
                    value: $search
                }
                {
                    column: "name"
                    operator: LIKE
                    value: $search
                }
                {
                    column: "name"
                    operator: LIKE
                    value: $search
                }
            ]
        }) {
            paginatorInfo {
                total
                currentPage
                lastPage
                perPage
            }
            data {
                id
                name
                reference
                sites {
                    paginatorInfo {
                        total
                    }
                    data {
                        name
                        reference
                        address
                        site_type {
                            name
                        }
                    }
                }
                users {
                    paginatorInfo {
                        total
                    }
                }
            }
        }
    }
`;

const CREATE_BRANCH = gql`
    mutation CreateBranch($name: String, $reference: String) {
        createBranch(input: {name: $name, reference: $reference}) {
            id
        }
    }
`;

const UPDATE_BRANCH = gql`
    mutation UpdateBranch($id: ID!, $name: String!, $reference: String) {
        updateBranch(input: {id: $id, name: $name, reference: $reference}) {
            id
        }
    }
`;

const DELETE_BRANCH = gql`
    mutation DeleteBranch($id: ID!) {
        deleteBranch(id: $id) {
            id
        }
    }
`;

export {
  GET_BRANCH,
  GET_BRANCHES,
  CREATE_BRANCH,
  UPDATE_BRANCH,
  DELETE_BRANCH,
};
