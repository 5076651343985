import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel, PanelTitle, Switch } from 'front-library';
import imgHighTemp from 'front-library/build/img/high-temp.svg';
import imgLowTemp from 'front-library/build/img/low-temp.svg';
import imgShock from 'front-library/build/img/shock.svg';

import {
  AlertMaxTemperature,
  AlertMinTemperature,
  AlertShockDetected,
  Email,
  SMS,
} from './constants';
import {
  AlertTitle,
  LeftSpan,
  LeftSwitch,
  PanelContainer,
  SecondTitle,
  SecondTitleAndDescription,
  SwitchTooltip,
} from './styled-components';

const PanelAlertAsset = ({
  onChange,
  user,
  isSmsEnabled,
  isAllowed,
  isActivated,
}) => {
  const { t } = useTranslation();

  const isAssetSmsAlertAllowed = (alertId) => isAllowed('alert', alertId, SMS);

  const getTooltipText = (alertId) =>
    isAssetSmsAlertAllowed(alertId)
      ? t('alert:phoneTooltip')
      : t('alert:permissionTooltip');

  const displaySmsSwitch = (alertId) => {
    if (isAssetSmsAlertAllowed(alertId) && user.phone) {
      return (
        <LeftSwitch
          onChange={(e) => onChange('alert', alertId, SMS, e.target.checked)}
          checked={isActivated('alert', alertId, SMS)}
          className="switchIconInput styleLabelInput"
          disabled={!isAssetSmsAlertAllowed(alertId)}
        />
      );
    }

    return (
      <SwitchTooltip text={getTooltipText(alertId)} placement="left">
        <LeftSwitch
          onChange={(e) => onChange('alert', alertId, SMS, e.target.checked)}
          checked={isActivated('alert', alertId, SMS)}
          className="switchIconInput styleLabelInput"
          disabled={true}
        />
      </SwitchTooltip>
    );
  };

  return (
    <Panel>
      <PanelContainer>
        <div className="title-alert-box">
          <PanelTitle label={t('alert:asset.title')} />
        </div>
        <SecondTitleAndDescription>
          <div>{t('alert:asset.intro')}</div>
          <SecondTitle>
            {isSmsEnabled && <LeftSpan>{t('alert:sms')}</LeftSpan>}
            <span>{t('alert:email')}</span>
          </SecondTitle>
        </SecondTitleAndDescription>
        {/* Temperature */}
        {user.company.enable_temperature && (
          <>
            <div className="testgrid">
              <AlertTitle
                text={t('alert:asset.alertMinTemperature')}
                img={imgLowTemp}
              />
              {isSmsEnabled && displaySmsSwitch(AlertMinTemperature)}
              <Switch
                onChange={(e) =>
                  onChange(
                    'alert',
                    AlertMinTemperature,
                    Email,
                    e.target.checked,
                  )
                }
                checked={isActivated('alert', AlertMinTemperature, Email)}
                className="switchIconInput styleLabelInput"
              />
            </div>
            <div className="testgrid">
              <AlertTitle
                text={t('alert:asset.alertMaxTemperature')}
                img={imgHighTemp}
              />
              {isSmsEnabled && displaySmsSwitch(AlertMaxTemperature)}
              <Switch
                onChange={(e) =>
                  onChange(
                    'alert',
                    AlertMaxTemperature,
                    Email,
                    e.target.checked,
                  )
                }
                checked={isActivated('alert', AlertMaxTemperature, Email)}
                className="switchIconInput styleLabelInput"
              />
            </div>
          </>
        )}

        {user.company.enable_shock && (
          <div className="testgrid">
            <AlertTitle
              text={t('alert:asset.alertShockDetected')}
              img={imgShock}
            />
            {isSmsEnabled && displaySmsSwitch(AlertShockDetected)}
            <Switch
              onChange={(e) =>
                onChange('alert', AlertShockDetected, Email, e.target.checked)
              }
              checked={isActivated('alert', AlertShockDetected, Email)}
              className="switchIconInput styleLabelInput"
            />
          </div>
        )}
      </PanelContainer>
    </Panel>
  );
};

export { PanelAlertAsset };
