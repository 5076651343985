import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from 'front-library';

import { Title } from '../styled-components';

const DegradedPosition = ({ formValues, setFormValues }) => {
  const { t } = useTranslation();

  return (
    <>
      <Title>{t('companies:form.degradedPosition')}</Title>

      {/* Number of messages with degraded position */}
      <TextField
        label={t('companies:form.degradedPositionMsgWithoutPosition')}
        onChange={(degradedPositionMsgWithoutPosition) =>
          setFormValues({ ...formValues, degradedPositionMsgWithoutPosition })
        }
        type="number"
        value={formValues.degradedPositionMsgWithoutPosition}
      />

      {/* Meters before the degraded position is displayed on the map */}
      <TextField
        label={t('companies:form.degradedPositionDistanceMin')}
        onChange={(degradedPositionDistanceMin) =>
          setFormValues({ ...formValues, degradedPositionDistanceMin })
        }
        type="number"
        value={formValues.degradedPositionDistanceMin}
      />
    </>
  );
};

DegradedPosition.propTypes = {
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
};

export { DegradedPosition };
