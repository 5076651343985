import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Loading } from 'front-library';

import { useQueryGraphQl } from '../../../hooks/useQueryGraphQl';
import { routes } from '../../../routes';
import { Modal } from '../../molecules/Modal/Modal';
import { MagTypeForm } from '../../organisms/MagTypes/MagTypeForm';
import {
  CREATE_MAG_TYPE,
  GET_FORM_DATA,
  GET_MAG_TYPE,
  UPDATE_MAG_TYPE,
} from './graphql';
import {
  mapCharacteristicsToFormValues,
  mapFormValuesToGraphql,
  mapMagTypeToFormValues,
} from './utils';

const MagType = ({ refetch }) => {
  const { t } = useTranslation();
  const { id = null } = useParams();
  const navigate = useNavigate();

  const { data: formData, loading: getFormDataLoading } =
    useQueryGraphQl(GET_FORM_DATA);
  const { data: magTypeData, loading: getLoading } = useQueryGraphQl(
    GET_MAG_TYPE,
    { id },
    {},
    null === id,
  );
  const [createMagTypeMutation, { loading: createLoading }] =
    useMutation(CREATE_MAG_TYPE);
  const [updateMagTypeMutation, { loading: updateLoading }] =
    useMutation(UPDATE_MAG_TYPE);

  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(mapMagTypeToFormValues());

  if (magTypeData?.mag_type && !formValues.id) {
    setFormValues({
      ...formValues,
      ...mapMagTypeToFormValues(magTypeData.mag_type),
    });
  }

  if (
    formData?.characteristics?.data?.length &&
    ((id && magTypeData?.mag_type && !formValues.characteristics) ||
      (!id && !formValues.characteristics))
  ) {
    setFormValues({
      ...formValues,
      ...mapCharacteristicsToFormValues(
        formData.characteristics.data,
        magTypeData?.mag_type,
      ),
    });
  }

  const title = useMemo(
    () => (id ? t('magTypes:update') : t('magTypes:create')),
    [id, t],
  );

  const handleSave = async () => {
    try {
      await createMagTypeMutation({
        variables: mapFormValuesToGraphql(formValues),
      });

      setErrors({});
      refetch();
      handleClose();
      toast.success(t('magTypes:created.success', { name: formValues.name }));
    } catch (e) {
      setErrors(e?.graphQLErrors[0]?.extensions?.validation || {});
      toast.error(t('magTypes:created.error', { name: formValues.name }));
    }
  };

  const handleUpdate = async () => {
    try {
      await updateMagTypeMutation({
        variables: mapFormValuesToGraphql(formValues),
      });

      setErrors({});
      refetch();
      handleClose();
      toast.success(t('magTypes:updated.success', { name: formValues.name }));
    } catch (e) {
      setErrors(e?.graphQLErrors[0]?.extensions?.validation || {});
      toast.error(t('magTypes:updated.error', { name: formValues.name }));
    }
  };

  const handleClose = () =>
    navigate(generatePath(routes.administrationMagType.path));

  return (
    <Modal onClose={handleClose} title={title}>
      {(getLoading || createLoading || updateLoading || getFormDataLoading) && (
        <Loading />
      )}
      {!getLoading && !getFormDataLoading && (
        <MagTypeForm
          formData={formData}
          formValues={formValues}
          setFormValues={setFormValues}
          errors={errors}
          onCancel={handleClose}
          onSubmit={id ? handleUpdate : handleSave}
        />
      )}
    </Modal>
  );
};

MagType.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export { MagType };
