import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, generatePath, useNavigate } from 'react-router-dom';

import { ColumnCheckbox, Grid } from 'front-library';
import JsonIcon from 'front-library/build/img/icon-json.svg';

import { routes } from '../../../../routes';
import { OriginalMessage } from '../OriginalMessage';
import { GridContainer } from '../styled-components';

const DataGrid = ({ magDatas, onOrderBy, onOrderByRelation }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Routes>
        <Route
          path={routes.administrationMagDataJson.partialPath}
          element={<OriginalMessage />}
        />
      </Routes>
      <GridContainer>
        <Grid.Grid>
          <Grid.Header>
            <Grid.Cell size="s">
              <ColumnCheckbox
                label={t('mags:form.data.createdAt')}
                onClick={(order) => onOrderBy('CREATED_AT', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="s">
              <ColumnCheckbox
                label={t('mags:form.data.timestamp')}
                onClick={(order) => onOrderBy('DEVICE_TIMESTAMP', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="s">
              <ColumnCheckbox
                label={t('mags:form.data.sites')}
                onClick={(order) =>
                  onOrderByRelation('sites', 'MAX', 'NAME', order)
                }
              />
            </Grid.Cell>
            <Grid.Cell size="s">
              <ColumnCheckbox
                label={t('mags:form.data.source')}
                onClick={(order) =>
                  onOrderByRelation('geolocation_source', 'MAX', 'NAME', order)
                }
              />
            </Grid.Cell>
            <Grid.Cell size="s">
              <ColumnCheckbox
                label={t('mags:form.data.battery')}
                onClick={(order) => onOrderBy('BAT_LVL', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="s">
              <ColumnCheckbox
                label={t('mags:form.data.rssi')}
                onClick={(order) => onOrderBy('RSSI', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="s">
              <ColumnCheckbox
                label={t('mags:form.data.accuracy')}
                onClick={(order) => onOrderBy('ACCURACY', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="s">
              <ColumnCheckbox
                label={t('mags:form.data.temperature')}
                onClick={(order) => onOrderBy('TEMPERATURE', order)}
              />
            </Grid.Cell>
            <Grid.Cell size="s" alignment="center">
              {t('mags:form.data.json')}
            </Grid.Cell>
          </Grid.Header>
        </Grid.Grid>

        <Grid.Grid>
          {magDatas?.map((magData) => (
            <GridRow
              key={magData.id}
              magData={magData}
              onClick={(magId, magDataId) =>
                navigate(
                  generatePath(routes.administrationMagDataJson.path, {
                    id: magId,
                    magDataId,
                  }),
                )
              }
            />
          ))}
        </Grid.Grid>
      </GridContainer>
    </>
  );
};

const GridRow = ({ magData, onClick }) => {
  const { t } = useTranslation();

  return (
    <Grid.Row>
      <Grid.Cell size="s">{moment(magData.created_at).fromNow()}</Grid.Cell>
      <Grid.Cell size="s">{magData.timestamp}</Grid.Cell>
      <Grid.Cell size="s">
        {magData.sites.data.map((site) => site.name).join('; ')}
      </Grid.Cell>
      <Grid.Cell size="s">
        {magData.geolocation_source?.name || t('mags:form.data.unknown')}
      </Grid.Cell>
      <Grid.Cell size="s">{magData.bat_lvl}</Grid.Cell>
      <Grid.Cell size="s">{magData.rssi}</Grid.Cell>
      <Grid.Cell size="s">{magData.accuracy}</Grid.Cell>
      <Grid.Cell size="s">{magData.temperature}</Grid.Cell>
      <Grid.Cell size="s" alignment="center">
        <Grid.IconButton
          imgSrc={JsonIcon}
          onClick={() => onClick(magData.mag_id, magData.id)}
        />
      </Grid.Cell>
    </Grid.Row>
  );
};

DataGrid.propTypes = {
  magDatas: PropTypes.array,
  onOrderBy: PropTypes.func,
  onOrderByRelation: PropTypes.func,
};

GridRow.propTypes = {
  magData: PropTypes.object,
  onClick: PropTypes.func,
};

export { DataGrid };
