export function buildFilters(filters, column, operator, value) {
  let f = { ...filters };

  if (value?.length) {
    f = {
      ...f,
      [column]: {
        column,
        value,
        operator,
      },
    };
  } else {
    delete f[column];
  }

  return f;
}

export function buildVariables(variables, label, value) {
  let newVariables = {};

  if ('search' === label) {
    newVariables = { ...variables, [label]: value, page: 1 };
  } else if ('filter' === label) {
    newVariables = { ...variables, page: 1 };
  } else {
    newVariables = { ...variables, [label]: value };
  }

  return newVariables;
}
