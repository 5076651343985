import styled from 'styled-components';

export const StatusContainer = styled.div`
    display: flex;
    align-items: center;

    img {
        width: 30px;
        height: 30px;
        padding: 6px;
        margin: auto 0;
    }

    p {
        color: ${({ textColor }) => textColor};
    }
`;
