export function mapMagTypeToFormValues(magType = null) {
  return {
    id: magType?.id || null,
    name: magType?.name || null,
    key: magType?.key || null,
    provider: magType?.provider || null,
    qualification: magType?.qualification || null,
    networks: magType?.networks?.map((n) => n.value) || [],
    geolocationSources:
      magType?.geolocation_sources?.map((gs) => gs.value) || [],
  };
}

export function mapCharacteristicsToFormValues(
  characteristics = [],
  magType = null,
) {
  return {
    characteristics: characteristics.map(
      (characteristic) =>
        magType?.characteristics?.find(
          (magTypeCharacteristic) =>
            magTypeCharacteristic.value === characteristic.value,
        ) || characteristic,
    ),
  };
}

export function mapFormValuesToGraphql({
  id,
  name,
  key,
  provider,
  qualification,
  networks,
  geolocationSources,
  characteristics,
}) {
  return {
    ...(id && { id }),
    name: name.trim(),
    key: key.trim(),
    provider: provider ? { connect: provider.value } : null,
    qualification: qualification,
    networks: {
      [id ? 'sync' : 'connect']: networks,
    },
    geolocation_sources: {
      [id ? 'sync' : 'connect']: geolocationSources,
    },
    characteristics: {
      [id ? 'sync' : 'connect']: characteristics.map((characteristic) => ({
        id: characteristic.value,
        parameter: characteristic.pivot?.parameter ?? '',
      })),
    },
  };
}
