import moment from 'moment';

const getShippingOrderTranslate = (t, key) => {
  switch (key) {
    case 'new':
      return t('shippingOrderStatus:new');
    case 'loaded':
      return t('shippingOrderStatus:loaded');
    case 'delivery_in_progress':
      return t('shippingOrderStatus:deliveryInProgress');
    case 'arrived_on_delivery_site':
      return t('shippingOrderStatus:arrivedOnDeliverySite');
    case 'delivered':
      return t('shippingOrderStatus:delivered');
    case 'canceled':
      return t('shippingOrderStatus:canceled');
    case 'paired':
      return t('shippingOrderStatus:paired');
    case 'delivery_confirmed':
      return t('shippingOrderStatus:deliveryConfirmed');
    case 'on_site':
      return t('shippingOrderStatus:onSite');
    case 'closed':
      return t('shippingOrderStatus:closed');
    case 'canceled_by_automation':
      return t('shippingOrderStatus:canceledByAutomation');
    default:
      return t('shippingOrderStatus:notDefined');
  }
};

const isShippingOrderLate = (shippingOrder) => {
  if (shippingOrder.arrival_timestamp && shippingOrder.real_arrival_timestamp) {
    return (
      shippingOrder.real_arrival_timestamp > shippingOrder.arrival_timestamp
    );
  }

  if (shippingOrder.arrival_timestamp && shippingOrder.human_eta) {
    return shippingOrder.human_eta > shippingOrder.arrival_timestamp;
  }

  return false;
};

const convertStringToKey = (string) => {
  let s = string.toLowerCase();
  s = s.replace(/^[0-9_-]+|[^a-z0-9_\s-]|(\s.\s){1,}|[\s]{2,}|[_\s-]+$/gi, '');
  s = s.replace(/[-\s_]+/gi, '_');

  return s;
};

/**
 * Check if an object is a graphql relation.
 * Parameter must be an object with an id property inside to return true.
 */
const isRelation = (object) =>
  object && 'object' === typeof object && object.id;

/**
 * Compare a graphql model with some form data, and insert { disconnect: xx }
 * for each relation that is not present anymore in the form data.
 * For this values:
 *  - setDisconnectFormValues({ relation: { id: 12 }, otherProp: 'xyz' }, { otherProp: 'xyz'})
 * The second parameter will become:
 *  - { relation: { disconnect: 12 }, otherProp: 'xyz' }
 */
const setDisconnectFormValues = (originalModel, formData) => {
  for (const key of Object.keys(originalModel)) {
    if (isRelation(originalModel[key]) && !formData[key]) {
      formData[key] = { disconnect: originalModel[key].id };
    }
  }
};

/**
 * Remove empty values from an object
 *  - removeEmptyValues({ a: 'xyz', b: null })
 * Will return
 *  - { a: 'xyz' }
 */
const removeEmptyValues = (values) => {
  for (const key of Object.keys(values)) {
    if (null === values[key]) {
      delete values[key];
    }
  }

  return values;
};

const urlDecode = (str) => decodeURIComponent(str).replace(/\+/g, ' ');

const refreshPage = () => {
  window.location.reload();
};

function base64ToObject(base64) {
  return JSON.parse(Buffer.from(base64, 'base64').toString('utf-8'));
}

function objectToBase64(object) {
  return Buffer.from(JSON.stringify(object), 'utf8').toString('base64');
}

function generateUniqKey() {
  return Date.now() + Math.random();
}

const formatErrorMessage = (error, pattern, replacement) => {
  return error.replace(pattern, replacement.toLocaleLowerCase());
};

const prepareGraphqlSearch = (search) => {
  const parsed = Number.parseInt(search);

  return Number.isNaN(parsed) ? `%${search.trim()}%` : parsed;
};

function getErrorText(errors, key, replacement) {
  if (!errors[`input.${key}`]) {
    return null;
  }

  return formatErrorMessage(
    errors[`input.${key}`][0],
    `input.${key.replace('_', ' ')}`,
    replacement,
  );
}

const convertTimeToSeconds = (time) => {
  const [days, hours, minutes] = time.split(':');

  return moment
    .duration({
      days: +days,
      hours: +hours,
      minutes: +minutes,
    })
    .asSeconds();
};

const convertSecondsToTime = (seconds) =>
  moment.duration(seconds, 'seconds').format('dd:hh:mm', { trim: false });

export {
  getShippingOrderTranslate,
  convertStringToKey,
  setDisconnectFormValues,
  removeEmptyValues,
  isShippingOrderLate,
  urlDecode,
  refreshPage,
  base64ToObject,
  objectToBase64,
  generateUniqKey,
  formatErrorMessage,
  prepareGraphqlSearch,
  getErrorText,
  convertTimeToSeconds,
  convertSecondsToTime,
};
