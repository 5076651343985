import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { MeContext } from '../../../../contexts/MeContext';

import { ErrorRadius } from '../ErrorRadius';
import { MarkerMagma } from '../Markers/MarkerMagma';
import { icons } from '../icons';

const RoutingPoints = ({ routing }) => {
  const { me: user } = useContext(MeContext);
  const [errorRadiusPoint, setErrorRadiusPoint] = useState(null);

  return (
    <>
      {routing.map((r, i) => {
        if (r.accuracy > user.company.accuracy) {
          return (
            <MarkerMagma
              key={`${Date.now()}-${i}`}
              icon={icons.impreciseMagData}
              onMouseOver={() => setErrorRadiusPoint(r)}
              onMouseOut={() => setErrorRadiusPoint(null)}
              position={[r.latitude, r.longitude]}
              title={r.device_timestamp}
            />
          );
        }

        return (
          <MarkerMagma
            key={`${Date.now()}-${i}`}
            position={[r.latitude, r.longitude]}
            icon={icons.magData}
            title={r.device_timestamp}
          />
        );
      })}
      {errorRadiusPoint && (
        <ErrorRadius
          coords={[errorRadiusPoint.latitude, errorRadiusPoint.longitude]}
          accuracy={errorRadiusPoint.accuracy}
        />
      )}
    </>
  );
};

RoutingPoints.propTypes = {
  routing: PropTypes.array,
};

export { RoutingPoints };
