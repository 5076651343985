import { useMemo } from 'react';
import { fetchSite } from '../../http/sitesRepository';
import { useHttpQuery } from '../useHttpQuery';

function useSite(siteId) {
  const params = useMemo(() => ({ id: siteId }), [siteId]);

  return useHttpQuery(fetchSite, params);
}

export { useSite };
