import { gql } from '@apollo/client';

const GET_FORM_DATA = gql`
    query GetFormData {
        mag_types(first: 10000) {
            data {
                id
                name
                characteristics(where: { column: "key", operator: EQ, value: "firmware" }) {
                    pivot { parameter }
                }
                provider { name }
            }
        }
        network_operators(first: 10000) {
            data {
                value: id
                title: name
            }
        }
    }
`;

const GET_MAG = gql`
    query GetMag($id: ID!) {
        mag(id: $id) {
            id
            mag_id
            provider_device_id
            network_device_id
            last_message_date
            days_left
            state
            is_gateway
            message_sent
            company {
                value: id
                title: name
            }
            owner {
                value: id
                title: name
            }
            mag_type {
                id
            }
            network_operators {
                value: id
                title: name
            }
            beacon {
                id
                minor
                major
                mac_address
            }
        }
    }
`;

const GET_MAGS = gql`
    query GetMags($first: Int, $page: Int, $search: Mixed, $orderBy: [QueryAllMagsOrderByRelationOrderByClause!]) {
        all_mags(first: $first, page: $page, orderBy: $orderBy, where: {
            OR: [
                {
                    column: "mag_id"
                    operator: LIKE
                    value: $search
                }
                {
                    column: "provider_device_id"
                    operator: LIKE
                    value: $search
                }
                {
                    HAS: {
                        relation: "company"
                        condition: {
                            column: "name"
                            operator: LIKE
                            value: $search
                        }
                    }
                }
                {
                    HAS: {
                        relation: "owner"
                        condition: {
                            column: "name"
                            operator: LIKE
                            value: $search
                        }
                    }
                }
                {
                    HAS: {
                        relation: "mag_type"
                        condition: {
                            column: "name"
                            operator: LIKE
                            value: $search
                        }
                    }
                }
            ]
        }) {
            paginatorInfo {
                total
                currentPage
                lastPage
                perPage
            }
            data {
                id
                mag_id
                provider_device_id
                network_device_id
                last_message_date
                days_left
                state
                is_gateway
                message_sent
                company {
                    value: id
                    title: name
                }
                owner {
                    value: id
                    title: name
                }
                mag_type {
                    id
                    name
                    networks { name }
                    provider { name }
                    characteristics(where: { column: "key", operator: EQ, value: "message_capacity" }) {
                        pivot { parameter }
                    }
                }
                network_operators {
                    value: id
                    title: name
                }
                beacon {
                    id
                    minor
                    major
                    mac_address
                }
            }
        }
    }
`;

const CREATE_MAG = gql`
    mutation CreateMag(
        $company: UpdateRelation
        $owner: UpdateRelation
        $mag_type: UpdateRelation
        $mag_id: String
        $provider_device_id: String
        $network_device_id: String
        $is_gateway: Boolean
        $state: String
        $beacon: CreateBeaconInput
        $network_operators: UpdateManyRelation
    ) {
        createMag(input: {
            company: $company
            owner: $owner
            mag_type: $mag_type
            mag_id: $mag_id
            provider_device_id: $provider_device_id
            network_device_id: $network_device_id
            is_gateway: $is_gateway
            state: $state
            beacon: $beacon
            network_operators: $network_operators
        }) {
            id
        }
    }
`;

const UPDATE_MAG = gql`
    mutation UpdateMag(
        $id: ID!
        $company: UpdateRelation
        $owner: UpdateRelation
        $mag_type: UpdateRelation
        $mag_id: String
        $provider_device_id: String
        $network_device_id: String
        $is_gateway: Boolean
        $state: String
        $beacon: CreateBeaconInput
        $network_operators: UpdateManyRelation
    ) {
        updateMag(input: {
            id: $id
            company: $company
            owner: $owner
            mag_type: $mag_type
            mag_id: $mag_id
            provider_device_id: $provider_device_id
            network_device_id: $network_device_id
            is_gateway: $is_gateway
            state: $state
            beacon: $beacon
            network_operators: $network_operators
        }) {
            id
        }
    }
`;

const DELETE_MAG = gql`
    mutation DeleteMag($id: ID!) {
        deleteMag(id: $id) {
            id
        }
    }
`;

const IMPORT_MAG = gql`
    mutation ImportMag(
        $company: Int
        $owner: Int
        $mag_type: Int
        $network_operators: UpdateManyRelation
        $fakeAssets: Boolean
        $file: Upload
    ) {
        importMag(input: {
            company: $company
            owner: $owner
            mag_type: $mag_type
            network_operators: $network_operators
            fakeAssets: $fakeAssets
            file: $file
        }) {
            data {
                id
            }
            result
            errors
        }
    }
`;

export {
  GET_FORM_DATA,
  GET_MAG,
  GET_MAGS,
  CREATE_MAG,
  UPDATE_MAG,
  DELETE_MAG,
  IMPORT_MAG,
};
