import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { BtnInATab, Loading } from 'front-library';
import IconAddGreen from 'front-library/build/img/icon-add-green.svg';

import { useQueryGraphQl } from '../../../../hooks/useQueryGraphQl';
import { routes } from '../../../../routes';
import { ListPage } from '../../../templates/ListPage';
import { Company } from '../Company';
import { GET_COMPANIES } from '../graphql';
import { BtnInATabContainer } from '../styled-components';
import { DataGrid } from './DataGrid';

const CompanyList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: companyData,
    setPagination,
    setFirst,
    setVariable,
    setOrderBy,
    setOrderByRelation,
    loading,
    refetch,
  } = useQueryGraphQl(GET_COMPANIES, { first: 25, search: '%' });

  const handleAdd = () => navigate(routes.administrationCompanyCreation.path);

  return (
    <>
      <Routes>
        <Route
          path={routes.administrationCompanyCreation.partialPath}
          element={<Company refetch={refetch} />}
        />
        <Route
          path={routes.administrationCompanyEdition.partialPath}
          element={<Company refetch={refetch} />}
        />
      </Routes>
      <ListPage
        title={t('menu:company')}
        paginatorInfo={companyData?.companies?.paginatorInfo}
        onPaginate={setPagination}
        onRowsPerPageChange={setFirst}
        onSearch={(search) => setVariable('search', `%${search.trim()}%`)}
        onAdd={handleAdd}
      >
        <DataGrid
          companies={companyData?.companies?.data}
          onOrderBy={setOrderBy}
          onOrderByRelation={setOrderByRelation}
        />
        {loading && <Loading />}
        <BtnInATabContainer>
          <BtnInATab
            text={t('companies:add')}
            onClick={handleAdd}
            imgSrc={IconAddGreen}
          />
        </BtnInATabContainer>
      </ListPage>
    </>
  );
};

export { CompanyList };
