import qs from 'qs';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { base64ToObject, objectToBase64 } from '../helpers';

function stringToType(type, value) {
  switch (type) {
    case 'number':
      return +value;
    case 'string':
      return value;
    case 'boolean':
      return !!JSON.parse(value);
    case 'object':
      return JSON.parse(value);
    case 'base64Object':
      return null === value ? null : base64ToObject(value);
    default:
      throw new Error('Unhandled type: ', type);
  }
}

function typeToString(type, value) {
  switch (type) {
    case 'number':
      return ~value;
    case 'string':
      return value;
    case 'boolean':
      return value ? '1' : '0';
    case 'object':
      return JSON.stringify(value);
    case 'base64Object':
      return objectToBase64(value);
    default:
      throw new Error('Unhandled type: ', type);
  }
}

export const useQueryState = (query, defaultValue = null, type = null) => {
  const location = useLocation();
  const navigate = useNavigate();

  const setQuery = useCallback(
    (value) => {
      const existingQueries = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });

      const queryString = qs.stringify(
        {
          ...existingQueries,
          [query]: null === type ? value : typeToString(type, value),
        },
        { skipNulls: true },
      );

      navigate(`${window.location.pathname}?${queryString}`, { replace: true });
    },
    [navigate, query, type],
  );

  let value = qs.parse(location.search, { ignoreQueryPrefix: true })[query];

  if (undefined === value) {
    value = defaultValue;
  }

  if (null !== type) {
    value = stringToType(type, value);
  }

  return [value, setQuery];
};
