import PropTypes from 'prop-types';
import React from 'react';

import { Checkpoint } from './Checkpoint';

const Itinerary = ({ shippingOrder, currentCheckPoint }) => {
  let deliveryPoints = [
    {
      site: shippingOrder.departure_site,
      departure_timestamp: shippingOrder.departure_timestamp,
      real_departure_timestamp: shippingOrder.real_departure_timestamp,
      type: 'departureSite',
    },
  ];

  if (shippingOrder.tour) {
    deliveryPoints = [
      ...deliveryPoints,
      ...shippingOrder.tour.shipping_orders.data
        .filter((tourSo) => tourSo.tour_order !== null)
        .map((tourSo) => ({
          site: tourSo.arrival_site,
          departure_timestamp: tourSo.departure_timestamp,
          real_departure_timestamp: tourSo.checkpoint_real_departure_timestamp,
          arrival_timestamp: tourSo.arrival_timestamp,
          real_arrival_timestamp: tourSo.real_arrival_timestamp,
          type: 'checkpoint',
        })),
    ];
  }

  deliveryPoints.push({
    site: shippingOrder.arrival_site,
    arrival_timestamp: shippingOrder.arrival_timestamp,
    real_arrival_timestamp: shippingOrder.real_arrival_timestamp,
    type: 'arrivalSite',
  });

  // Useful when the departure and the arrival sites are the same to avoid opening both at the same time
  const isOnDepartureSite = (point) => {
    return !point.arrival_timestamp && !point.real_departure_timestamp;
  };

  const isOpen = (point) => {
    return (
      isOnDepartureSite(point) ||
      (currentCheckPoint?.id === point.site.id &&
        currentCheckPoint.arrival_timestamp === point.arrival_timestamp)
    );
  };

  return (
    <div>
      {deliveryPoints.map((point) => (
        <Checkpoint
          key={`${point.site.name}_${point.departure_timestamp}`}
          checkpoint={point}
          orders={shippingOrder.orders}
          isOpen={isOpen(point)}
        />
      ))}
    </div>
  );
};

Itinerary.propTypes = {
  shippingOrder: PropTypes.object,
  currentCheckPoint: PropTypes.object,
};

export { Itinerary };
