import dayjs from 'dayjs';

export const getDateRangePresets = (t) => [
  {
    label: `${t('shippingOrders:advancedFilters.relativeDates.d')} +1`,
    value: [
      dayjs().add(1, 'd').startOf('day'),
      dayjs().add(1, 'd').endOf('day'),
    ],
  },
  {
    label: `${t('shippingOrders:advancedFilters.relativeDates.d')} +2`,
    value: [
      dayjs().add(2, 'd').startOf('day'),
      dayjs().add(2, 'd').endOf('day'),
    ],
  },
  {
    label: `${t('shippingOrders:advancedFilters.relativeDates.d')} -1`,
    value: [
      dayjs().add(-1, 'd').startOf('day'),
      dayjs().add(-1, 'd').endOf('day'),
    ],
  },
  {
    label: `${t('shippingOrders:advancedFilters.relativeDates.d')} -2`,
    value: [
      dayjs().add(-2, 'd').startOf('day'),
      dayjs().add(-2, 'd').endOf('day'),
    ],
  },
  {
    label: t('shippingOrders:advancedFilters.relativeDates.lastDays', {
      days: 7,
    }),
    value: [dayjs().add(-7, 'd').startOf('day'), dayjs().endOf('day')],
  },
  {
    label: t('shippingOrders:advancedFilters.relativeDates.lastDays', {
      days: 30,
    }),
    value: [dayjs().add(-30, 'd').startOf('day'), dayjs().endOf('day')],
  },
  {
    label: t('shippingOrders:advancedFilters.relativeDates.today'),
    value: [dayjs().startOf('day'), dayjs().endOf('day')],
  },
];
