import { useMemo } from 'react';
import { fetchAsset } from '../../http/assetsRepository';
import { useBatchHttpQueryItem } from '../useBatchHttpQueryItem';

function useSiteAssets(assetIds) {
  const queryParams = useMemo(() => assetIds, [assetIds]);

  return useBatchHttpQueryItem(fetchAsset, queryParams, true);
}

export { useSiteAssets };
